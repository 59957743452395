import { SearchResult } from "../../shared/routers/SearchRouter";
import AppContext from "../../shared/AppContext";

export function urlFromResult(searchResult: SearchResult) {
  if (searchResult.type === "player") {
    return `/player/${searchResult.Id}`;
  }

  const league = searchResult.aux1
    ?.substring(0, searchResult.aux1.indexOf("|"))
    .toLowerCase()
    .replace(/ /g, "_");
  const ids = searchResult.aux2;

  if (ids && searchResult.score === 100) {
    return `/team/${ids}`;
  } else if (league) {
    const season = ["Utah Summer", "Vegas Summer", "Orlando Summer"].includes(
      league
    )
      ? AppContext.latestDraftYear
      : searchResult.aux1?.substring(searchResult.aux1.indexOf("|") + 1);
    return `/team/ml/${searchResult.Id}?season=${season}`;
  }

  // We should never hit this case but in case something is totally broken just
  // send people to the team browser as that is going to be the best way to find
  // what they are looking for.
  return "/teams";
}

export function getSecondaryText(searchResult: SearchResult) {
  // For players combine birth year + college (if exists).
  if (searchResult.type === "player") {
    return [searchResult.aux1, searchResult.aux2].filter((a) => !!a).join(", ");
  }
  // For teams parse the league out of aux1 and return.
  return searchResult.aux1?.substring(0, searchResult.aux1.indexOf("|"));
}
