import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { TEAM_COLORS } from "../../constants/ColorConstants";
import { TeamContext } from "../../TeamContext";

export function TeamPicker(props: {
  selectedTeamId: string;
  linkPrefix: string;
}) {
  const { selectedTeamId, linkPrefix } = props;
  const teams = useContext(TeamContext).teams;
  const teamColors = TEAM_COLORS[selectedTeamId];

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {teams.map((t) => {
        return t.teamid.toString() === selectedTeamId ? (
          <span
            key={t.teamid}
            style={{
              backgroundColor: teamColors && teamColors.primary,
              margin: 2,
              padding: 4,
              borderRadius: 5,
              fontWeight:
                t.teamid.toString() === selectedTeamId ? "bold" : "normal",
            }}
          >
            <Link
              to={`/${linkPrefix}/${t.teamid}`}
              style={{
                color: teamColors && teamColors.secondary,
              }}
            >
              {t.teamabbreviation}
            </Link>
          </span>
        ) : (
          <span
            key={t.teamid}
            style={{ margin: 2, padding: 4, borderRadius: 5 }}
          >
            <Link to={`/${linkPrefix}/${t.teamid}`}>{t.teamabbreviation}</Link>
          </span>
        );
      })}
    </div>
  );
}
