import React, { useState, useMemo } from "react";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import { Button } from "react-bootstrap";

import {
  DoubleFooter,
  Table,
  SortingState,
  createColumnHelper,
} from "../core/Table";
import { TableNote } from "../core/TableNote";
import {
  Position,
  Positions,
  measurementRanges,
} from "../../constants/AppConstants";
import {
  PlayerModeledMeasurements,
  PlayerMeasurements,
} from "../../../shared/routers/PlayerRouter";
import {
  decFormat,
  decFormat2,
  footFormat,
  poundsFormat,
  percentileFormat,
  atMostDecFormat,
  intFormat,
} from "../../util/Format";
import { average, yearDiff } from "../../util/Util";
import { groupBy } from "../../../shared/util/Collections";

const columnHelper = createColumnHelper<PlayerMeasurements>();

export function PlayerMeasurementsTable(props: {
  data: PlayerMeasurements[];
  modeled: PlayerModeledMeasurements[];
  position: number;
  age: number | null;
}) {
  const { data, modeled, age } = props;

  const [sorting, setSorting] = useState<SortingState>();
  const [position, setPosition] = useState(props.position);
  const [showAllMeasurements, setShowAllMeasurements] = useState(false);

  const isWingspanEstimated =
    modeled[0] && modeled[0].whichAreEstimated === "WingSpan";

  // Filter out rows where we only have age/source/playerId.
  const filteredData = data.filter((rowData) => {
    return (
      Object.entries(rowData).filter(([key, value]) => {
        return (
          !["measurementDate", "source", "playerId"].includes(key) && !!value
        );
      }).length > 0
    );
  });

  const measurmentsBySource = groupBy(
    filteredData,
    (d) => `${getFullYear(d.measurementDate)} ${d.source}`
  );

  // If showAllMeasurements is true, show all measurements, otherwise show just
  // the first measurement from a <season>,<source> pairing.
  const measurementsToShow = showAllMeasurements
    ? filteredData
    : (Object.values(measurmentsBySource)
        .map((v) => v[0])
        .filter((d) => d !== undefined) as PlayerMeasurements[]);

  // Calculate the percentile of the given measurements.
  let positionToCompare = position;
  if (!positionToCompare) {
    // Default comparison to PG.
    positionToCompare = 1;
  }

  const dupeMeasurementsExist = Object.values(measurmentsBySource).some(
    (m) => m.length > 1
  );

  const columns = useMemo(() => {
    let g = 0;

    const modeledRow = modeled && modeled[0];

    const toggleButton = showAllMeasurements ? (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setShowAllMeasurements(false);
        }}
        style={{
          lineHeight: 0,
          padding: 3,
          marginRight: 5,
          fontSize: ".7em",
          verticalAlign: "top",
        }}
      >
        <IoMdRemove />
      </Button>
    ) : (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setShowAllMeasurements(true);
        }}
        style={{
          lineHeight: 0,
          padding: 3,
          marginRight: 5,
          fontSize: ".7em",
          verticalAlign: "top",
        }}
      >
        <IoMdAdd />
      </Button>
    );

    return [
      columnHelper.accessor("source", {
        header: () =>
          dupeMeasurementsExist ? <div>{toggleButton}Source</div> : "Source",
        cell: (info) => {
          const val = info.getValue();
          const measurementDate = info.row.original.measurementDate;
          const measurementsForSeason =
            measurmentsBySource[`${getFullYear(measurementDate)} ${val}`];
          const multipleRows =
            measurementsForSeason && measurementsForSeason.length > 1;

          return (
            <span>
              {getFullYear(measurementDate)} {val}
              <sup>{!showAllMeasurements && multipleRows ? "†" : ""}</sup>
            </span>
          );
        },
        footer: () => (
          <DoubleFooter
            textAlign="left"
            primary={<div>Adjusted Average</div>}
            secondary={
              <div>
                <label htmlFor="percentile" style={{ marginBottom: 0 }}>
                  Percentile vs:{" "}
                </label>{" "}
                <select
                  value={position}
                  onChange={(evt) => setPosition(parseInt(evt.target.value))}
                >
                  {Object.keys(Positions).map((key) => (
                    <option value={key} key={key}>
                      {Positions[parseInt(key)]}
                    </option>
                  ))}
                </select>
              </div>
            }
          />
        ),
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("measurementDate", {
        header: "Age",
        cell: (info) => {
          const measurementDate = info.row.original.measurementDate;
          if (measurementDate) {
            return decFormat(getAgeAtDate(age, measurementDate));
          }
          return null;
        },
        footer: () => <DoubleFooter primary={""} secondary={""} />,
        meta: { group: g++ },
      }),
      columnHelper.accessor("weight", {
        header: "Weight",
        cell: (info) => poundsFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={poundsFormat(modeledRow ? modeledRow.weight : null)}
            secondary={percentileFormat(
              getPercentileVsPosition(modeled, "weight", positionToCompare)
            )}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("bodyFat", {
        header: "BF%",
        cell: (info) => decFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(modeledRow ? modeledRow.bodyFat : null)}
            secondary={percentileFormat(
              getPercentileVsPosition(modeled, "bodyFat", positionToCompare)
            )}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("heightWithShoes", {
        header: "Ht Shoes",
        cell: (info) => footFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={footFormat(modeledRow ? modeledRow.heightWithShoes : null)}
            secondary={percentileFormat(
              getPercentileVsPosition(
                modeled,
                "heightWithShoes",
                positionToCompare
              )
            )}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("heightNoShoes", {
        header: "Ht No Shoes",
        cell: (info) => footFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={footFormat(modeledRow ? modeledRow.heightNoShoes : null)}
            secondary={percentileFormat(
              getPercentileVsPosition(
                modeled,
                "heightNoShoes",
                positionToCompare
              )
            )}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("wingspan", {
        header: "Wingspan",
        cell: (info) =>
          `${isWingspanEstimated ? "≈ " : ""}${footFormat(info.getValue())}`,
        footer: () => (
          <DoubleFooter
            primary={footFormat(modeledRow ? modeledRow.wingspan : null)}
            secondary={percentileFormat(
              getPercentileVsPosition(modeled, "wingspan", positionToCompare)
            )}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("standReach", {
        header: "Reach",
        cell: (info) => footFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={footFormat(modeledRow ? modeledRow.standReach : null)}
            secondary={percentileFormat(
              getPercentileVsPosition(modeled, "standReach", positionToCompare)
            )}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("verticalJumpOneStep", {
        header: "Vert Max",
        cell: (info) => decFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(
              modeledRow ? modeledRow.verticalJumpOneStep : null
            )}
            secondary={percentileFormat(
              getPercentileVsPosition(
                modeled,
                "verticalJumpOneStep",
                positionToCompare
              )
            )}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("verticalJumpNoStep", {
        header: "Vert No Step",
        cell: (info) => decFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(
              modeledRow ? modeledRow.verticalJumpNoStep : null
            )}
            secondary={percentileFormat(
              getPercentileVsPosition(
                modeled,
                "verticalJumpNoStep",
                positionToCompare
              )
            )}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("handLength", {
        header: "Hand Length",
        cell: (info) => decFormat2(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat2(modeledRow ? modeledRow.handLength : null)}
            secondary={percentileFormat(
              getPercentileVsPosition(modeled, "handLength", positionToCompare)
            )}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("handWidth", {
        header: "Hand Width",
        cell: (info) => decFormat2(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat2(modeledRow ? modeledRow.handWidth : null)}
            secondary={percentileFormat(
              getPercentileVsPosition(modeled, "handWidth", positionToCompare)
            )}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("benchPress", {
        header: "Bench",
        cell: (info) => intFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={intFormat(modeledRow ? modeledRow.benchPress : null)}
            secondary={percentileFormat(
              getPercentileVsPosition(modeled, "benchPress", positionToCompare)
            )}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("laneAgility", {
        header: "Lane  Agi",
        cell: (info) => decFormat2(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat2(modeledRow ? modeledRow.laneAgility : null)}
            secondary={percentileFormat(
              getPercentileVsPosition(modeled, "laneAgility", positionToCompare)
            )}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("run34", {
        header: "3/4 Run",
        cell: (info) => decFormat2(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat2(modeledRow ? modeledRow.run34 : null)}
            secondary={percentileFormat(
              getPercentileVsPosition(modeled, "run34", positionToCompare)
            )}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("threeMin", {
        header: "3 Min Run",
        cell: (info) => intFormat(info.getValue()),
        footer: () => <DoubleFooter primary={"-"} secondary={"-"} />,
        meta: { group: g++ },
      }),
      columnHelper.accessor("hundredThrees", {
        header: "100 3's",
        cell: (info) => intFormat(info.getValue()),
        footer: () => <DoubleFooter primary={"-"} secondary={"-"} />,
        meta: { group: g++ },
      }),
    ];
  }, [
    modeled,
    dupeMeasurementsExist,
    measurmentsBySource,
    showAllMeasurements,
    position,
    age,
    positionToCompare,
    isWingspanEstimated,
  ]);

  const processSelected = (data: PlayerMeasurements[]) => {
    return [
      "",
      atMostDecFormat(
        average(
          (d: PlayerMeasurements) => getAgeAtDate(age, d.measurementDate),
          data
        )
      ),
      poundsFormat(average("weight", data)),
      decFormat(average("bodyFat", data)),
      footFormat(average("heightWithShoes", data)),
      footFormat(average("heightNoShoes", data)),
      footFormat(average("wingspan", data)),
      footFormat(average("standReach", data)),
      decFormat(average("verticalJumpOneStep", data)),
      decFormat(average("verticalJumpNoStep", data)),
      decFormat2(average("handLength", data)),
      decFormat2(average("handWidth", data)),
      atMostDecFormat(average("benchPress", data)),
      decFormat2(average("laneAgility", data)),
      decFormat2(average("run34", data)),
      atMostDecFormat(average("threeMin", data)),
      atMostDecFormat(average("hundredThrees", data)),
    ];
  };

  return (
    <div>
      <Table
        data={measurementsToShow}
        columns={columns}
        processSelected={processSelected}
        sorting={sorting}
        setSorting={setSorting}
        autoWidth={true}
        showColorOnHover={true}
      />
      {!!isWingspanEstimated && (
        <ul className="list-unstyled bottom table-note">
          <li>≈ indicates an approximate measurement</li>
        </ul>
      )}
      {dupeMeasurementsExist && (
        <TableNote
          note={
            <>
              <sup>†</sup> indicates there are multiple measurements from this
              source for this season, which are not currently visible. Clicking
              <IoMdAdd /> beside the Source column header reveals them.
            </>
          }
        />
      )}
    </div>
  );
}

// Takes a date number and returns the year.
// e.g. (20140618) => 2014
function getFullYear(date: number) {
  return parseInt(date.toString().slice(0, 4));
}

// Returns a player's age at a specified date.
// e.g On May 24 2022 it would return 15.7 for an input of (23.660507, 20140618)
function getAgeAtDate(currentAge: number | null, date: number) {
  if (currentAge === null) return null;
  const dateStr = date.toString();
  const measureDate = Date.parse(
    dateStr.substring(0, 4) +
      "/" +
      dateStr.substring(4, 6) +
      "/" +
      dateStr.substring(6, 8)
  );
  return currentAge + yearDiff(measureDate, new Date().getTime());
}

function getPercentileVsPosition(
  data: PlayerModeledMeasurements[],
  key: keyof PlayerModeledMeasurements,
  position: number
) {
  if (data.length === 0) return null;
  const data1 = data[0];
  if (!data1) return null;
  const value = data1[key];
  if (value === null) return null;
  const numericValue = value as number;

  const pos = ["PG", "SG", "SF", "PF", "C"][position - 1];

  // The range of values for the position and the corresponding percentiles.
  const measurementsByPos = measurementRanges[key];
  if (!measurementsByPos) return null;

  const rangeOfVals = measurementsByPos[pos as Position];
  const pxval = [0.01, 0.05, 0.25, 0.5, 0.75, 0.95, 0.99];

  const rangeMin = rangeOfVals[0];
  const rangeMax = rangeOfVals[6];

  if (rangeMin === undefined || rangeMax === undefined) return null;

  // If the value is outside the range return 0 or 100. If its in the range
  // use linear interpolation to get the value.
  if (numericValue < rangeMin) {
    return 0;
  } else if (numericValue > rangeMax) {
    return 1;
  } else {
    const output = rangeOfVals.reduce((prev: number, curr: number) =>
      curr - numericValue >= 0 && prev - numericValue < 0 ? curr : prev
    );

    const index = rangeOfVals.indexOf(output);

    const prevpxVal = pxval[index - 1];
    const pxVal = pxval[index];
    const prevVal = rangeOfVals[index - 1];
    const val = rangeOfVals[index];

    if (
      prevpxVal === undefined ||
      pxVal === undefined ||
      prevVal === undefined ||
      val === undefined
    )
      return null;

    return (
      prevpxVal +
      ((numericValue - prevVal) * (pxVal - prevpxVal)) / (val - prevVal)
    );
  }
}
