import React, { useMemo, useState } from "react";

import { TeamTableCell } from "../core/TableCell";
import { SortingState, Table, createColumnHelper } from "../core/Table";
import { TeamRating } from "../../../shared/routers/TeamRouter";
import { decFormat, decFormat2 } from "../../util/Format";

const columnHelper = createColumnHelper<TeamRating>();

export function TeamRatings(props: {
  data: TeamRating[];
  highlightTeam: number;
}) {
  const { data, highlightTeam } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("team", {
        header: "Team",
        cell: (info) => (
          <TeamTableCell
            id={info.row.original.teamID}
            name={info.getValue()}
            league={info.row.original.leagueKey}
            season={info.row.original.Season.toString()}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("adjOE", {
        header: "Adj. OE",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("adjDE", {
        header: "Adj. DE",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor((row) => row.adjOE - row.adjDE, {
        id: "adjNet",
        header: "Net",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("league_PPP", {
        header: "Lg. PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("league_ORp", {
        header: "Lg. OR%",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("lgPTS", {
        header: "Lg. Pts.",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("lgORp", {
        header: "Lg. OR% R.E.",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("lgMIN", {
        header: "Lg. MIN R.E.",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("LgAge", {
        header: "Lg. Age",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("best_guess_lgHt", {
        header: "Best Guess Lg. Ht.",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("best_guess_lgWS", {
        header: "Best Guess Lg. WS",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("adjOR", {
        header: "Adj. OR",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("adjDR", {
        header: "Adj. DR",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("TeamAge", {
        header: "Team Age",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("Team_Ht", {
        header: "Team Ht.",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("Team_WS_plus", {
        header: "Team WS+",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("adjOE_for_Age", {
        header: "Adj. OE For Age",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),

      columnHelper.accessor("adjDE_for_Age", {
        header: "Adj. DE For Age",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: g },
      }),
    ];
  }, []);

  // Make sure we only have one row for each teamID.
  const uniqueTeamIdData = data.filter(
    (d, i, self) => self.findIndex((t) => t.teamID === d.teamID) === i
  );

  const rowColorMap: Record<number, { backgroundColor: string }> = {};
  uniqueTeamIdData.forEach((d, i) => {
    const teamId = d.teamID;
    if (teamId === highlightTeam) {
      rowColorMap[i] = { backgroundColor: "#ffffe0" };
    } else if (
      [2, 1267, 2590, 50, 58, 1277, 349, 1603, 4000].includes(teamId)
    ) {
      rowColorMap[i] = { backgroundColor: "#edecff" };
    }
  });

  return (
    <Table
      data={uniqueTeamIdData}
      columns={columns}
      rowColorMap={rowColorMap}
      sorting={sorting}
      setSorting={setSorting}
      virtualScroll={true}
      showColorOnHover={true}
      autoWidth={true}
    />
  );
}
