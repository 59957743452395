import React from "react";

import {
  PlayersForLiveGame,
  LiveGameDetails,
  LiveGameLineupModelPrediction,
  SecondSpectrumChance,
} from "../../../shared/routers/LiveGameRouter";
import { SecondSpectrumPossession } from "../../../shared/routers/LiveGameRouter";
import { GameLineupsSummary } from "../games/GameLineupsSummary";
import { GameLineupBreakdown } from "../../../shared/routers/GameRouter";

// TODO(chrisbu): When live game page is moved to a multiple tab page
// refactor this and lineups by time to not dupe all this processing.
export function LiveGameLineupSummaryTable(props: {
  details: LiveGameDetails;
  possessions: SecondSpectrumPossession[];
  chances: SecondSpectrumChance[];
  playerMap: Map<string, PlayersForLiveGame>;
  lineupPredictions: LiveGameLineupModelPrediction[];
}) {
  const { details, playerMap, possessions, chances, lineupPredictions } = props;

  const firstPoss = possessions[0];

  if (!firstPoss) return null;

  const chancesByPossId = new Map<string, SecondSpectrumChance[]>();
  chances.forEach((c) => {
    const arr = chancesByPossId.get(c.possessionId) || [];
    arr.push(c);
    chancesByPossId.set(c.possessionId, arr);
  });

  const lineupPredictionMap = new Map<string, LiveGameLineupModelPrediction>();
  lineupPredictions.forEach((lp) => {
    lineupPredictionMap.set(
      [lp.p1, lp.p2, lp.p3, lp.p4, lp.p5].sort((a, b) => a - b).join("-"),
      lp
    );
  });

  const teams = [
    {
      eagleId: details.homeTeamEagleId,
      teamId: details.homeTeamId,
      teamName: details.homeTeam,
    },
    {
      eagleId: details.awayTeamEagleId,
      teamId: details.awayTeamId,
      teamName: details.awayTeam,
    },
  ];

  return (
    <>
      {teams.map((team) => {
        const data: GameLineupBreakdown[] = possessions.map((p) => {
          const isOff = p.offTeamId === team.eagleId;

          const firstChance = (chancesByPossId.get(p.id) || []).sort(
            (a, b) => a.startGameClock - b.startGameClock
          )[0];
          const offPlayers = firstChance
            ? firstChance.offPlayerIds
            : p.offPlayerIds;
          const defPlayers = firstChance
            ? firstChance.defPlayerIds
            : p.defPlayerIds;

          const players = (
            (isOff ? offPlayers : defPlayers)
              .map((id) => playerMap.get(id))
              .filter((p) => p !== undefined) as PlayersForLiveGame[]
          ).sort((a, b) => {
            if (a.pos === b.pos) return a.playerId - b.playerId;
            return a.pos - b.pos;
          });

          const lineupPred = lineupPredictionMap.get(
            [...players]
              .sort((a, b) => a.playerId - b.playerId)
              .map((p) => p.playerId)
              .join("-")
          );

          return {
            gameId: 0,
            p1: players[0] ? players[0].playerId : 0,
            p2: players[1] ? players[1].playerId : 0,
            p3: players[2] ? players[2].playerId : 0,
            p4: players[3] ? players[3].playerId : 0,
            p5: players[4] ? players[4].playerId : 0,
            p1Pos: players[0] ? players[0].pos : 0,
            p2Pos: players[1] ? players[1].pos : 0,
            p3Pos: players[2] ? players[2].pos : 0,
            p4Pos: players[3] ? players[3].pos : 0,
            p5Pos: players[4] ? players[4].pos : 0,
            player1: players[0] ? players[0].playerName : "",
            player2: players[1] ? players[1].playerName : "",
            player3: players[2] ? players[2].playerName : "",
            player4: players[3] ? players[3].playerName : "",
            player5: players[4] ? players[4].playerName : "",
            offPoints: isOff ? p.ptsScored : 0,
            defPoints: isOff ? 0 : p.ptsScored,
            timein: p.startGameClock,
            timeout: p.endGameClock,
            period: p.period,
            season: p.season + 1,
            offPossessions: isOff ? 1 : 0,
            defPossessions: isOff ? 0 : 1,
            predOE: lineupPred ? lineupPred.predPPP : null,
            predDE: lineupPred ? lineupPred.predDefPPP : null,
            xPPP: null,
            xPPPDef: null,
            teamid: team.teamId,
            offPPP: isOff ? p.ptsScored : null,
            defPPP: isOff ? null : p.ptsScored,
          };
        });

        return (
          <div key={team.eagleId}>
            <b>{team.teamName} </b>
            <GameLineupsSummary data={data} hideXPts={true} />
          </div>
        );
      })}
    </>
  );
}
