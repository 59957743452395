import React, { useRef, useState, useEffect } from "react";
import { scaleLinear, ScaleLinear } from "d3";

import { strokeDasharrayBottom } from "../../util/Util";
import { useWindowSize } from "../../util/Hooks";

export function DivergentBar(props: {
  value: number;
  domain: number[];
  height: number;
  format: (x: number | null) => string | null;
  colors: { primary: string; secondary: string };
}) {
  const windowSize = useWindowSize();
  const [width, setWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [highlight, setHighlight] = useState(false);
  const { value, domain, colors, height, format } = props;

  useEffect(() => {
    if (!containerRef.current) return;
    setWidth(containerRef.current.offsetWidth);
  }, [windowSize]);

  if (value == null) {
    return null;
  }

  const x = scaleLinear().domain(domain).range([0, width]).clamp(true);

  const rectHeight = height * 0.6;
  const rectMargin = height * 0.2;

  let rectX;
  if (x(0) < x(value)) {
    rectX = x(0);
  } else {
    rectX = x(value);
  }

  const rectWidth = Math.abs(x(value) - x(0));

  const strokeDasharray = strokeDasharrayBottom(rectWidth, rectHeight);

  const renderHighlight = (x: ScaleLinear<number, number, never>) => {
    if (!highlight || value == null) {
      return null;
    }

    const anchor = x(value) > x(0) ? "end" : "start";
    const highlightX = x(value) > x(0) ? x(0) - 8 : x(0) + 4;
    const formattedValue = format(value);

    return (
      <g transform={`translate(${highlightX} 0)`}>
        <text
          x={2}
          y={1}
          fill={"black"}
          style={{ dominantBaseline: "hanging" }}
          textAnchor={anchor}
        >
          {formattedValue}
        </text>
      </g>
    );
  };

  return (
    <div ref={containerRef}>
      <svg
        width={"100%"}
        height={height}
        onMouseEnter={() => setHighlight(true)}
        onMouseLeave={() => setHighlight(false)}
      >
        <g>
          <line x1={x(0)} x2={x(0)} y1={0} y2={height} stroke="#ccc" />
          <rect
            x={rectX}
            width={rectWidth}
            y={rectMargin}
            height={rectHeight}
            strokeWidth={2}
            strokeDasharray={strokeDasharray}
            stroke={colors.secondary}
            fill={colors.primary}
          />
        </g>
        {renderHighlight(x)}
      </svg>
    </div>
  );
}
