import React, { useMemo } from "react";
import { Table, createColumnHelper } from "../../components/core/Table";
import { TableNote } from "../core/TableNote";
import { ChanceEfficiencyPerStartType2 } from "../../../shared/routers/ChanceRouter";
import {
  orderedStartTypes,
  noTransitionTypes,
} from "../../components/games/GameChanceStartTypes";
import {
  decFormat,
  decFormat2,
  dec100Format,
  ordinalFormat,
  intFormat,
} from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { sumFromField } from "../../util/Util";

export function EfficiencyByStartType(props: {
  teamId: number;
  teamData?: ChanceEfficiencyPerStartType2[];
  leagueData?: ChanceEfficiencyPerStartType2[];
  showOffense: boolean;
  filteredToSpecificGames: boolean;
  filtered: boolean;
}) {
  const {
    teamId,
    teamData,
    leagueData,
    showOffense,
    filtered,
    filteredToSpecificGames,
  } = props;

  type TableRow = ChanceEfficiencyPerStartType2 & {
    ppsRank: number | null;
    xppsRank: number | null;
    pppRank: number | null;
    xpppRank: number | null;
    pctAll: number;
    avgTovRank: number | null;
    avgTransitionRank: number | null;
    avgShotClockFirstActionRank: number | null;
  };

  const columnHelperStarts = createColumnHelper<TableRow>();

  const higher_better_off = showOffense ? Highlights.Max : Highlights.Min;
  const lower_better_off = showOffense ? Highlights.Min : Highlights.Max;

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelperStarts.accessor("startTypeGrouped", {
        header: () => "Start Type",
        cell: (info) => info.getValue(),
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelperStarts.group({
        id: "chances",
        meta: { group: g },
        header: "Chances",
        columns: [
          columnHelperStarts.accessor("numChances", {
            header: () => "Total",
            cell: (info) => intFormat(info.getValue()),
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelperStarts.accessor("pctAll", {
            header: () => "% All",
            cell: (info) => {
              if (!info.row.original.numChances) {
                return "-";
              }
              return decFormat(info.getValue());
            },
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelperStarts.accessor(
            (row) =>
              row.numScored === null ||
              row.numChances === null ||
              row.numChances === 0
                ? null
                : row.numScored / row.numChances,
            {
              id: "pctScored",
              header: () => "% Scored",
              cell: (info) => dec100Format(info.getValue()),
              meta: { group: g++, highlights: higher_better_off },
            }
          ),
        ],
      }),
      columnHelperStarts.group({
        id: "pps",
        meta: { group: g },
        header: "Per Shot",
        columns: [
          columnHelperStarts.accessor("xpps", {
            header: () => "xPPS",
            cell: (info) => decFormat2(info.getValue()),
            meta: { highlights: higher_better_off, group: g },
          }),
          columnHelperStarts.accessor("xppsRank", {
            header: () => "Rank",
            cell: (info) => ordinalFormat(info.getValue()),
            meta: { highlights: Highlights.Min, group: g++ },
          }),
          columnHelperStarts.accessor("pps", {
            header: () => "Actual PPS",
            cell: (info) => decFormat2(info.getValue()),
            meta: { highlights: higher_better_off, group: g },
          }),
          columnHelperStarts.accessor("ppsRank", {
            header: () => "Rank",
            cell: (info) => ordinalFormat(info.getValue()),
            meta: { highlights: Highlights.Min, group: g++ },
          }),
        ],
      }),
      columnHelperStarts.group({
        id: "ppp",
        meta: { group: g },
        header: "Per Possession",
        columns: [
          columnHelperStarts.accessor("xppp", {
            header: () => "xPPP",
            cell: (info) => {
              if (info.row.original.startTypeGrouped == "OR") {
                return "-";
              }
              return decFormat2(info.getValue());
            },
            meta: { highlights: higher_better_off, group: g },
          }),
          columnHelperStarts.accessor("xpppRank", {
            header: () => "Rank",
            cell: (info) => {
              if (info.row.original.startTypeGrouped == "OR") {
                return "-";
              }
              return ordinalFormat(info.getValue());
            },
            meta: { highlights: Highlights.Min, group: g++ },
          }),
          columnHelperStarts.accessor("ppp", {
            header: () => "Actual PPP",
            cell: (info) => {
              if (info.row.original.startTypeGrouped == "OR") {
                return "-";
              }
              return decFormat2(info.getValue());
            },
            meta: { highlights: higher_better_off, group: g },
          }),
          columnHelperStarts.accessor("pppRank", {
            header: () => "Rank",
            cell: (info) => {
              if (info.row.original.startTypeGrouped == "OR") {
                return "-";
              }
              return ordinalFormat(info.getValue());
            },
            meta: { highlights: Highlights.Min, group: g++ },
          }),
        ],
      }),
      columnHelperStarts.group({
        id: "tov",
        meta: { group: g },
        header: "TOV",
        columns: [
          columnHelperStarts.accessor("avgTov", {
            header: () => "%",
            cell: (info) => dec100Format(info.getValue()),
            meta: { highlights: lower_better_off, group: g },
          }),
          columnHelperStarts.accessor("avgTovRank", {
            header: () => "Rank",
            cell: (info) => ordinalFormat(info.getValue()),
            meta: { highlights: Highlights.Min, group: g++ },
          }),
        ],
      }),
      columnHelperStarts.group({
        id: "transition",
        meta: { group: g },
        header: "Transition",
        columns: [
          columnHelperStarts.accessor("avgTransition", {
            header: () => "%",
            cell: (info) => {
              if (
                noTransitionTypes.includes(info.row.original.startTypeGrouped)
              )
                return "-";
              return dec100Format(info.getValue());
            },
            meta: { highlights: higher_better_off, group: g },
          }),
          columnHelperStarts.accessor("avgTransitionRank", {
            header: () => "Rank",
            cell: (info) => {
              if (
                noTransitionTypes.includes(info.row.original.startTypeGrouped)
              )
                return "-";
              return ordinalFormat(info.getValue());
            },
            meta: { highlights: Highlights.Min, group: g++ },
          }),
        ],
      }),
      columnHelperStarts.group({
        id: "shot_clock",
        meta: { group: g },
        header: "1st Action Clock",
        columns: [
          columnHelperStarts.accessor("avgShotClockFirstAction", {
            header: () => "Avg",
            cell: (info) => {
              if (info.row.original.startTypeGrouped == "OR") {
                return "-";
              }
              return decFormat(info.getValue());
            },
            meta: { highlights: higher_better_off, group: g },
          }),
          columnHelperStarts.accessor("avgShotClockFirstActionRank", {
            header: () => "Rank",
            cell: (info) => {
              if (info.row.original.startTypeGrouped == "OR") {
                return "-";
              }
              return ordinalFormat(info.getValue());
            },
            meta: { highlights: Highlights.Min, group: g },
          }),
        ],
      }),
    ];
  }, [columnHelperStarts, higher_better_off, lower_better_off]);

  if (!teamData || !leagueData) return null;

  const leagueDataWithoutTeam = leagueData.filter((ld) => ld.teamId !== teamId);
  const totalTeamChances = sumFromField("numChances", teamData) || 0;
  const data: TableRow[] = orderedStartTypes.map((startType) => {
    const leagueDataForStartType = leagueDataWithoutTeam.filter(
      (ld) => ld.startTypeGrouped === startType
    );
    const teamDataRow = teamData.find(
      (td) => td.startTypeGrouped === startType
    );

    const getRankForStat = (
      stat:
        | "xpps"
        | "pps"
        | "ppp"
        | "xppp"
        | "avgTov"
        | "avgTransition"
        | "avgShotClockFirstAction",
      teamData: ChanceEfficiencyPerStartType2 | undefined
    ) => {
      if (
        teamData === undefined ||
        teamData[stat] === null ||
        teamData[stat] === 0
      )
        return null;
      const leagueDataForStat = leagueDataForStartType.map((ld) => ld[stat]);
      return (
        leagueDataForStat.filter((val) => {
          const teamVal = teamData[stat];
          // Lower is better for TOV or for everything but TOV on def page.
          const lowerIsBetter =
            (stat === "avgTov" && showOffense) ||
            (!showOffense && stat !== "avgTov");
          if (val === teamVal) return lowerIsBetter ? true : false;
          if (val === null) return lowerIsBetter ? false : true;
          if (teamVal === null) return lowerIsBetter ? true : false;
          return lowerIsBetter ? teamVal > val : val > teamVal;
        }).length + 1
      );
    };

    return {
      startTypeGrouped: startType,
      teamId: teamId,
      numChances: teamDataRow ? teamDataRow.numChances : 0,
      pctAll: teamDataRow
        ? ((teamDataRow.numChances || 0) / totalTeamChances) * 100
        : 0,
      numScored: teamDataRow ? teamDataRow.numScored : null,
      pps: teamDataRow ? teamDataRow.pps : null,
      ppsRank: getRankForStat("pps", teamDataRow),
      xpps: teamDataRow ? teamDataRow.xpps : null,
      xppsRank: getRankForStat("xpps", teamDataRow),
      ppp: teamDataRow ? teamDataRow.ppp : null,
      pppRank: getRankForStat("ppp", teamDataRow),
      xppp: teamDataRow ? teamDataRow.xppp : null,
      xpppRank: getRankForStat("xppp", teamDataRow),
      numTov: teamDataRow ? teamDataRow.numTov : null,
      avgTov: teamDataRow ? teamDataRow.avgTov : null,
      avgTovRank: getRankForStat("avgTov", teamDataRow),
      numTransition: teamDataRow ? teamDataRow.numTransition : null,
      avgTransition: teamDataRow ? teamDataRow.avgTransition : null,
      avgTransitionRank: getRankForStat("avgTransition", teamDataRow),
      avgShotClockFirstAction: teamDataRow
        ? teamDataRow.avgShotClockFirstAction
        : null,
      avgShotClockFirstActionRank: getRankForStat(
        "avgShotClockFirstAction",
        teamDataRow
      ),
    };
  });

  let add_detail = (
    <li>
      <strong>Data: </strong>full ongoing season
    </li>
  );
  if (filteredToSpecificGames) {
    add_detail = (
      <li>
        <strong>Data: </strong>
        <ul>
          <li>
            <strong>for current team: </strong> selected games
          </li>
          <li>
            <strong>ranked against: </strong> full ongoing season for other
            teams
          </li>
        </ul>
      </li>
    );
  } else if (filtered) {
    add_detail = (
      <li>
        <strong>Data: </strong>all filters applied to current team and all
        otther teams for rankings
      </li>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <div
        style={{
          textAlign: "right",
        }}
      >
        <Table data={data} columns={columns} autoWidth={true} />;
      </div>
      <details open style={{ color: "#575757" }}>
        <summary>
          <strong>Table Explanations</strong>
        </summary>
        <br></br>
        <TableNote
          note={
            <ul>
              {add_detail}
              <li>
                <strong>1st Action Clock:</strong> shot clock time at the
                beginning of any action on the first chance (includes isos, PNR,
                handoffs, posts, drives, off-ball screens)
              </li>
              <li>
                <strong>Start Types:</strong>
                <ul>
                  <li>
                    Per Possession (PPP, xPPP): data for{" "}
                    <strong>possessions </strong>
                    that start with each start type
                  </li>
                  <li>
                    All other data: start type taken at{" "}
                    <strong>chance level</strong>
                  </li>
                </ul>
              </li>
              <li>
                <strong>Ref Touch:</strong> comprises any chances starting after
                a made/missed FT or defensive out of bounds.
              </li>
              <li>
                <strong>OR:</strong> FT or FG offensive rebound.
              </li>
            </ul>
          }
        />
      </details>
    </div>
  );
}
