import React, { useMemo, useState } from "react";

import { Table, createColumnHelper } from "../core/Table";
import { TableNote } from "../core/TableNote";
import {
  ChanceEfficiencyPerStartType,
  ChanceEfficiencyPerStartTypeGameOut,
  EffPerStartTypeForCompGame,
} from "../../../shared/routers/ChanceRouter";
import { groupBy } from "../../../shared/util/Collections";
import {
  decFormat,
  decFormat2,
  ordinalFormat,
  intFormat,
} from "../../util/Format";
import { sumFromField } from "../../util/Util";
import { Highlights, CELTICS_TEAM_ID } from "../../constants/AppConstants";

export const valuesRefTouches = ["FTDRB", "FTM", "DEFOB", "FTLINE"];
export const valuesOR = ["FTORB", "FGORB"];
export const orderedStartTypes = [
  "FGDRB",
  "FGM",
  "Ref Touch",
  "TO",
  "OR",
  "SLOB",
  "BLOB",
  "JMP",
];
export const noTransitionTypes = ["OR", "SLOB", "BLOB", "JMP"];

const columnHelperReduced =
  createColumnHelper<ChanceEfficiencyPerStartTypeGameOut>();

export function GameChanceStartTypes(props: {
  data?: ChanceEfficiencyPerStartType[];
  compData?: EffPerStartTypeForCompGame[];
  home: { team: string; teamid: number };
  away: { team: string; teamid: number };
}) {
  const { data, compData, home, away } = props;

  if (!data || !compData || !home || !away) return null;

  return (
    <GroupDisplayEffPerStartTypeData
      data={data}
      teams={[
        { teamName: home.team, teamId: home.teamid },
        { teamName: away.team, teamId: away.teamid },
      ]}
      comp_data={compData}
    />
  );
}

function GroupDisplayEffPerStartTypeData(props: {
  data: ChanceEfficiencyPerStartType[];
  teams: { teamId: number; teamName: string }[];
  comp_data: EffPerStartTypeForCompGame[];
}) {
  const { data, teams, comp_data } = props;
  const firstTeam = teams[0];
  const defaultTeamId = teams.some((t) => t.teamId === CELTICS_TEAM_ID)
    ? CELTICS_TEAM_ID
    : firstTeam
    ? firstTeam.teamId
    : 0;
  const [teamId, setTeamId] = useState<string>(defaultTeamId.toString());
  const [period, setPeriod] = useState<number>(0);

  const filteredData = data.filter((d) => {
    if (
      teamId !== "" &&
      d.offTeamId != null &&
      d.offTeamId.toString() !== teamId
    ) {
      return false;
    }
    if (d.quarter == null) return false;
    if (period === 0) return true;
    if (period === 5) return d.quarter > 4;
    return d.quarter === period;
  });

  const filteredCompData = comp_data.filter((d) => {
    if (d.period == null) return false;
    if (period === 5) return d.period == 100;
    return d.period === period;
  });

  filteredCompData.map((obj) => {
    obj.startTypeComplete = valuesRefTouches.some(
      (item) => obj.startTypeComplete === item
    )
      ? "Ref Touch"
      : valuesOR.some((item) => obj.startTypeComplete === item)
      ? "OR"
      : obj.startTypeComplete;
  });

  const comp_data_by_start_type = !filteredCompData
    ? {}
    : Object.fromEntries(
        filteredCompData.map((x) => [x.startTypeComplete, { ...x }])
      );

  // if multiple quarters selected, avg
  const dataByStartType = groupBy(filteredData, (d) => d.startTypeGrouped);
  const reducedData = orderedStartTypes.map((startType) => {
    const arr = dataByStartType[startType] || [];

    const totalShots = sumFromField("numShots", arr);
    const numChances = sumFromField("numChances", arr);
    const totScored = sumFromField("numScored", arr);
    const totPtsShot = sumFromField("totalPtsShots", arr);
    const totXPtsShot = sumFromField("totalXPtsShots", arr);
    const numPoss = sumFromField("numPoss", arr);
    const totPtsPoss = sumFromField("totalPtsPoss", arr);
    const totXPtsPoss = sumFromField("totalXPtsPoss", arr);
    const totTov = sumFromField("numTov", arr);
    const totTrans = sumFromField("numTransition", arr);
    const weightedSumClocks = arr.reduce((sum, d) => {
      if (d.averageShotClockFirstAction === null || d.numChances === null) {
        return sum;
      }
      return sum + d.averageShotClockFirstAction * d.numChances;
    }, 0);

    const compDataForStartType = comp_data_by_start_type[startType];

    return {
      startType: startType,
      numChances: numChances,
      pctScored:
        totScored === null || !numChances
          ? null
          : (totScored / numChances) * 100,
      pps: totPtsShot === null || !totalShots ? null : totPtsShot / totalShots,
      xpps:
        totXPtsShot === null || !totalShots ? null : totXPtsShot / totalShots,
      ppp: totPtsPoss === null || !numPoss ? null : totPtsPoss / numPoss,
      xppp: totXPtsPoss === null || !numPoss ? null : totXPtsPoss / numPoss,
      numTov: totTov === null ? null : totTov,
      pctTov:
        totTov === null || !numChances ? null : (totTov / numChances) * 100,
      numTrans: totTrans === null ? null : totTrans,
      pctTrans:
        totTrans === null || !numChances ? null : (totTrans / numChances) * 100,
      avgClockFirstAction: !numChances ? null : weightedSumClocks / numChances,
      avgOffXPPSBOS: compDataForStartType
        ? compDataForStartType["offXpps"]
        : null,
      OffXPPSRankBOS: compDataForStartType
        ? compDataForStartType["OffXPPSRank"]
        : null,
      avgDefXPPSBOS: compDataForStartType
        ? compDataForStartType["defXpps"]
        : null,
      DefXPPSRankBOS: compDataForStartType
        ? compDataForStartType["DefXPPSRank"]
        : null,
      avgOffXPPPBOS: compDataForStartType
        ? compDataForStartType["offXppp"]
        : null,
      OffXPPPRankBOS: compDataForStartType
        ? compDataForStartType["OffXPPPRank"]
        : null,
      avgDefXPPPBOS: compDataForStartType
        ? compDataForStartType["defXppp"]
        : null,
      DefXPPPRankBOS: compDataForStartType
        ? compDataForStartType["DefXPPPRank"]
        : null,
      leagueAvgOffClockFirstAction: compDataForStartType
        ? compDataForStartType["leagueAvgOffClockFirstAction"]
        : null,
      leagueAvgDefClockFirstAction: compDataForStartType
        ? compDataForStartType["leagueAvgDefClockFirstAction"]
        : null,
    };
  });

  const firstCompData = comp_data[0];
  const comp_season_display = firstCompData ? firstCompData.season % 100 : 0;

  const reducedColumns = useMemo(() => {
    if (!reducedData) return [];
    let g = 0;

    const celtics_on_off = teamId == CELTICS_TEAM_ID.toString();
    const query_xppp = celtics_on_off ? "avgOffXPPPBOS" : "avgDefXPPPBOS";
    const query_xpps = celtics_on_off ? "avgOffXPPSBOS" : "avgDefXPPSBOS";
    const query_xppp_rank = celtics_on_off
      ? "OffXPPPRankBOS"
      : "DefXPPPRankBOS";
    const query_xpps_rank = celtics_on_off
      ? "OffXPPSRankBOS"
      : "DefXPPSRankBOS";
    const query_lg_avg_clock = celtics_on_off
      ? "leagueAvgOffClockFirstAction"
      : "leagueAvgDefClockFirstAction";
    const celtics_qualif = `BOS '${comp_season_display + 1} ${
      celtics_on_off ? "Off" : "Def"
    }`;
    const higlight_order = celtics_on_off ? Highlights.Max : Highlights.Min;

    return [
      columnHelperReduced.accessor("startType", {
        header: () => "Start Type",
        cell: (info) => info.getValue(),
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelperReduced.accessor("numChances", {
        header: () => "# Chances",
        cell: (info) => intFormat(info.getValue()),
        meta: { highlights: Highlights.Max, group: g },
      }),
      columnHelperReduced.accessor("pctScored", {
        header: () => "% Scored",
        cell: (info) => decFormat(info.getValue()),
        meta: { highlights: Highlights.Max, group: g++ },
      }),
      columnHelperReduced.group({
        id: "xppp_info",
        meta: { group: g },
        header: "xPPP",
        columns: [
          columnHelperReduced.accessor("xppp", {
            header: () => "Game Avg",
            cell: (info) => decFormat2(info.getValue()),
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelperReduced.accessor(query_xppp, {
            header: () => celtics_qualif,
            cell: (info) => decFormat2(info.getValue()),
            meta: { highlights: higlight_order, group: g },
          }),
          columnHelperReduced.accessor(query_xppp_rank, {
            header: () => "Rk.",
            cell: (info) => ordinalFormat(info.getValue()),
            meta: { highlights: Highlights.Min, group: g++ },
          }),
        ],
      }),
      columnHelperReduced.group({
        id: "actual_xpp",
        meta: { group: g },
        header: "Actual",
        columns: [
          columnHelperReduced.accessor("ppp", {
            header: () => "PPP",
            cell: (info) => decFormat2(info.getValue()),
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelperReduced.accessor("pps", {
            header: () => "PPS",
            cell: (info) => decFormat2(info.getValue()),
            meta: { highlights: Highlights.Max, group: g++ },
          }),
        ],
      }),
      columnHelperReduced.group({
        id: "xpps_info",
        meta: { group: g },
        header: "xPPS",
        columns: [
          columnHelperReduced.accessor("xpps", {
            header: () => "Game Avg",
            cell: (info) => decFormat2(info.getValue()),
            meta: { highlights: Highlights.Max, group: g },
          }),
          columnHelperReduced.accessor(query_xpps, {
            header: () => celtics_qualif,
            cell: (info) => decFormat2(info.getValue()),
            meta: { highlights: higlight_order, group: g },
          }),
          columnHelperReduced.accessor(query_xpps_rank, {
            header: () => "Rk.",
            cell: (info) => ordinalFormat(info.getValue()),
            meta: { highlights: Highlights.Min, group: g++ },
          }),
        ],
      }),
      columnHelperReduced.accessor("numTov", {
        header: () => "# TOV",
        cell: (info) => intFormat(info.getValue()),
        meta: { highlights: Highlights.Min, group: g },
      }),
      columnHelperReduced.accessor("pctTov", {
        header: () => "% TOV",
        cell: (info) =>
          info.getValue() == 0 ? "-" : decFormat(info.getValue()),
        meta: { highlights: Highlights.Min, group: g++ },
      }),
      columnHelperReduced.accessor("numTrans", {
        header: () => "# Tran",
        cell: (info) => {
          const start_type = info.row.original.startType
            ? info.row.original.startType
            : "";
          if (start_type.includes("LOB")) {
            return "-";
          }
          return intFormat(info.getValue());
        },
        meta: { highlights: Highlights.Max, group: g },
      }),
      columnHelperReduced.accessor("pctTrans", {
        header: () => "% Tran",
        cell: (info) =>
          info.getValue() == 0 ? "-" : decFormat(info.getValue()),
        meta: { highlights: Highlights.Max, group: g++ },
      }),
      columnHelperReduced.accessor("avgClockFirstAction", {
        header: () => "1st Action Clock",
        cell: (info) => {
          if (info.row.original.startType == "OR") {
            return "-";
          }
          return decFormat(info.getValue());
        },
        meta: { highlights: Highlights.Max, group: g },
      }),
      columnHelperReduced.accessor(query_lg_avg_clock, {
        header: () => "Lg. Avg",
        cell: (info) => {
          if (info.row.original.startType == "OR") {
            return "-";
          }
          return decFormat(info.getValue());
        },
        meta: { group: g },
      }),
    ];
  }, [reducedData]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 8,
        }}
      >
        <select
          value={teamId}
          onChange={(evt) => {
            const newTeamId = evt.target.value;
            setTeamId(newTeamId);
          }}
        >
          {teams.map((t) => (
            <option value={t.teamId.toString()} key={t.teamId.toString()}>
              {t.teamName}
            </option>
          ))}
        </select>
        <select
          value={period}
          onChange={(evt) => setPeriod(parseInt(evt.target.value))}
        >
          {[
            { value: 0, label: "Full Game" },
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "OT" },
          ].map((po) => (
            <option value={po.value} key={po.value}>
              {po.label}
            </option>
          ))}
        </select>
      </div>
      <div
        style={{
          textAlign: "right",
        }}
      >
        <Table data={reducedData} columns={reducedColumns} autoWidth={true} />
      </div>
      <details open style={{ color: "#575757" }}>
        <summary>
          <strong>Table Explanations</strong>
        </summary>
        <br></br>
        <TableNote
          note={
            <ul>
              <li>
                <strong>Start Types:</strong>
                <ul>
                  <li>
                    Per Possession (PPP, xPPP): data for{" "}
                    <strong>possessions </strong>
                    that start with each start type
                  </li>
                  <li>
                    All other data: start type taken at{" "}
                    <strong>chance level</strong>
                  </li>
                </ul>
              </li>
              <li>
                <strong>Ref Touch:</strong> comprises any chances starting after
                a made/missed FT or defensive out of bounds.
              </li>
              <li>
                <strong>OR:</strong> FT or FG offensive rebound.
              </li>
              <li>
                <strong>1st Action Clock:</strong> shot clock time at the
                beginning of any action on the first chance (includes isos, PNR,
                handoffs, posts, drives, off-ball screens)
              </li>
              <li>
                <strong>xPPS/P Off/ Def Avg:</strong> Avg for the{" "}
                {comp_season_display}
                &apos; - {comp_season_display + 1} &apos; season
                <ul>
                  <li>
                    If Celtics selected, comparing to Celtics offense (xPPP/S
                    Avg = Celtics xPPP/S Off Avg)
                  </li>
                  <li>
                    Any other team selected, comparing to Celtics defense
                    (xPPP/S Avg = Celtics xPPP/S Def Avg)
                  </li>
                </ul>
              </li>
              <li>
                BOS season numbers, ranks, and league averages; update for
                specific quarters or OT when selected
              </li>
            </ul>
          }
        />
      </details>
    </div>
  );
}
