import React from "react";

interface CometProps {
  y: number;
  r: number;
  xTail: number;
  xHead: number;
  fillColor: string;
  outlineColor: string;
  circleStrokeWidth?: number;
}

const Comet = React.forwardRef<SVGCircleElement, CometProps>(
  (props: CometProps, ref) => {
    const { y, xTail, xHead, r, fillColor, outlineColor } = props;
    const circleStrokeWidth = props.circleStrokeWidth || 1;
    return (
      <g>
        <polygon
          points={`${xTail},${y} ${xHead},${y - r} ${xHead},${y + r}`}
          fill={fillColor}
        />
        <circle
          ref={ref}
          cx={xHead}
          cy={y}
          r={r - circleStrokeWidth / 2}
          strokeWidth={circleStrokeWidth}
          fill={fillColor}
          stroke={outlineColor}
        />
      </g>
    );
  }
);

Comet.displayName = "Comet";

export { Comet };
