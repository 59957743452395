import React, { useMemo, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

import { Modal } from "../core/Modal";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import { Spinner } from "../core/Spinner";
import { DxMaybe, MappingInsert } from "../../../shared/routers/MappingRouter";
import { trpc } from "../../util/tRPC";

export function DxMaybes(props: { maybes?: DxMaybe[] }) {
  const { maybes } = props;
  const [errMsg, setErrMsg] = useState<string>();
  const [checkedRows, setCheckedRows] = useState<Set<string>>(new Set());
  const [sorting, setSorting] = useState<SortingState>();
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);

  const utils = trpc.useContext();

  const insertMutation = trpc.mapping.postDxInsert.useMutation({
    onSuccess: () => {
      setCheckedRows(new Set());
      setErrMsg("");
      utils.mapping.getDxMaybes.invalidate();
      utils.mapping.getDxInserts.invalidate();
      utils.mapping.getDxMaybes.refetch();
      utils.mapping.getDxInserts.refetch();
    },
    onError: () => {
      setErrMsg("Unable to insert player into mapping table");
    },
  });

  const updateMutation = trpc.mapping.postDxUpdate.useMutation({
    onSuccess: () => {
      setCheckedRows(new Set());
      setErrMsg("");
      utils.mapping.getDxMaybes.invalidate();
      utils.mapping.getDxInserts.invalidate();
      utils.mapping.getDxMaybes.refetch();
      utils.mapping.getDxInserts.refetch();
    },
    onError: () => {
      setErrMsg(
        "Unable to merge player(s) into mapping entry while marking player(s) as match(es)"
      );
    },
  });

  const pending = insertMutation.isLoading || updateMutation.isLoading;

  const columnHelper = createColumnHelper<DxMaybe>();

  const handleCheck = (id: string) => {
    if (checkedRows.has(id)) {
      checkedRows.delete(id);
    } else {
      checkedRows.add(id);
    }
    setCheckedRows(new Set(checkedRows));
  };

  const handleCheckAll = (checkAll: boolean) => {
    if (!maybes) return;
    if (checkAll) {
      setCheckedRows(new Set(maybes.map((m) => m.dxId + "-" + m.celticsId)));
    } else {
      setCheckedRows(new Set());
    }
  };

  // If we have identified existing players (aka they exist in BIA) update the
  // entries in the mapping table and then remove the maybes entries.
  const mergeCheckedRows = () => {
    if (!maybes) return;

    const checkedPlayers = maybes.filter((m) =>
      checkedRows.has(m.dxId + "-" + m.celticsId)
    );

    const playersToMarkAsDupes = checkedPlayers
      .filter((p) => p.de_of_option)
      .map((p) => {
        return {
          dxId: p.dxId,
          de_of_option: parseInt(p.de_of_option || ""),
        };
      });

    const playersToUpdate = checkedPlayers
      .filter((p) => !p.de_of_option)
      .map((p) => {
        return {
          Inches: p.Inches,
          Weight: p.Weight,
          College: p.College,
          HomeTown: p.HomeTown,
          HomeState: p.HomeState,
          HighSchool: p.HighSchool,
          BirthDate: p.BirthDate,
          Nationality: p.Nationality,
          dxId: p.dxId.toString(),
          celticsId: p.celticsId,
        };
      });

    updateMutation.mutate({
      players: playersToUpdate,
      dxDupes: playersToMarkAsDupes,
    });
  };

  // If we have identified new players (aka not dupes) insert them into the
  // mapping table and then remove the maybes entries.
  const insertCheckedRows = () => {
    if (!maybes) return;

    const playersToInsert: MappingInsert[] = maybes
      .filter((m) => checkedRows.has(m.dxId + "-" + m.celticsId))
      .map((m) => {
        return {
          First: m.FirstName
            ? m.FirstName.replace("Jr.", "").replace("III", "")
            : null,
          Last: m.LastName,
          Ht: m.Inches,
          Wt: m.Weight,
          College: m.College,
          Hometown: m.HomeTown,
          State: m.HomeState,
          HSJC: m.HighSchool,
          DOB: m.BirthDate,
          Country: m.Nationality,
          DE: m.dxId.toString(),
          synergy: null,
        };
      });

    if (playersToInsert.length === 0) return;

    insertMutation.mutate({ players: playersToInsert, maybes: true });
  };

  const insertModal = (
    <Modal
      title={"Confirm DX Insert"}
      content={
        "Pressing confirm will create new player mapping entries for the selected player(s)."
      }
      show={showInsertModal}
      handleClose={() => setShowInsertModal(false)}
      handleConfirm={() => {
        setShowInsertModal(false);
        insertCheckedRows();
      }}
    />
  );

  const mergeModal = (
    <Modal
      title={"Confirm Merge Duplicate"}
      content={
        "Pressing confirm will update the existing player mapping entries for selected player(s)."
      }
      show={showMergeModal}
      handleClose={() => setShowMergeModal(false)}
      handleConfirm={() => {
        setShowMergeModal(false);
        mergeCheckedRows();
      }}
    />
  );

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "check",
        header: () =>
          maybes &&
          maybes.length > 0 && (
            <Form.Check
              checked={checkedRows.size === maybes.length}
              onChange={() =>
                handleCheckAll(checkedRows.size !== maybes.length)
              }
            />
          ),
        cell: (info) => (
          <Form.Check
            checked={checkedRows.has(
              `${info.row.original.dxId}-${info.row.original.celticsId}`
            )}
            onChange={() =>
              handleCheck(
                `${info.row.original.dxId}-${info.row.original.celticsId}`
              )
            }
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.accessor("LastName", {
        header: () => "DX Player",
        cell: (info) => (
          <a
            href={`http://www.draftexpress.com/blue/player.php?player=${info.row.original.dxId}`}
          >
            {info.row.original.FirstName} {info.getValue()}
          </a>
        ),
        meta: { group: 1 },
      }),
      columnHelper.accessor("celticsName", {
        header: () => "BIA Player",
        cell: (info) => (
          <Link to={`/player/${info.row.original.celticsId}`}>
            {info.getValue()}
          </Link>
        ),
        meta: { group: 2 },
      }),
      columnHelper.accessor("BirthDate", {
        meta: { group: 3 },
      }),
      columnHelper.accessor("DOB_option", {
        cell: (info) => {
          const val = info.getValue();
          return val ? moment(val).format("YYYY-MM-DD") : "";
        },
        meta: { group: 4 },
      }),
      columnHelper.accessor("dxLeagues", {
        cell: (info) => (
          <div
            title={info.getValue() || ""}
            style={{
              maxWidth: 200,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {info.getValue()}
          </div>
        ),
        meta: { group: 5 },
      }),
      columnHelper.accessor("leagues_option", {
        cell: (info) => (
          <div
            title={info.getValue() || ""}
            style={{
              maxWidth: 200,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {info.getValue()}
          </div>
        ),
        meta: { group: 6 },
      }),
      columnHelper.accessor("de_of_option", {
        meta: { group: 7 },
      }),
      columnHelper.accessor("College", {
        meta: { group: 8 },
      }),
      columnHelper.accessor("college_option", {
        meta: { group: 9 },
      }),
      columnHelper.accessor("Team", {
        meta: { group: 10 },
      }),
      columnHelper.accessor("Nationality", {
        meta: { group: 11 },
      }),
      columnHelper.accessor("Country_option", {
        meta: { group: 12 },
      }),
      columnHelper.accessor("Inches", {
        meta: { group: 13 },
      }),
      columnHelper.accessor("Ht_option", {
        meta: { group: 14 },
      }),
      columnHelper.accessor("Weight", {
        meta: { group: 15 },
      }),
      columnHelper.accessor("Wt_option", {
        meta: { group: 16 },
      }),
      columnHelper.accessor("HighSchool", {
        meta: { group: 17 },
      }),
      columnHelper.accessor("HSJC_option", {
        meta: { group: 18 },
      }),
      columnHelper.accessor("HomeState", {
        meta: { group: 19 },
      }),
      columnHelper.accessor("State_option", {
        meta: { group: 20 },
      }),
      columnHelper.accessor("HomeTown", {
        meta: { group: 21 },
      }),
      columnHelper.accessor("Hometown", {
        meta: { group: 22 },
      }),
      columnHelper.accessor("celticsId", {
        meta: { group: 23 },
      }),
    ],
    [checkedRows, maybes]
  );

  if (!maybes || pending) return <Spinner />;

  return (
    <div>
      {errMsg && (
        <Alert variant="danger">
          {`Something went wrong: ${errMsg}! Please contact chrisbu@celtics.com`}
        </Alert>
      )}
      <Button
        style={{ marginRight: 8 }}
        onClick={() => setShowMergeModal(true)}
        disabled={checkedRows.size == 0}
      >
        Insert Checked As Matches
      </Button>
      <Button
        onClick={() => setShowInsertModal(true)}
        disabled={checkedRows.size == 0}
      >
        Insert Checked Rows
      </Button>
      {insertModal}
      {mergeModal}
      <Table
        data={maybes}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        virtualScroll={true}
        autoWidth={true}
        showRowIndex={false}
      />
    </div>
  );
}
