import React, { useState, useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import moment from "moment";

import { GameDetails } from "../../shared/routers/GameRouter";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { VideoPlayer } from "../components/video/VideoPlayer";
import { getHoop1Urls } from "../util/VideoUtil";
import { trpc } from "../util/tRPC";

export function GamePreviewPage() {
  const { id } = useParams();

  const { data: gameDetails } = trpc.game.getGameDetails.useQuery({
    gameIds: id ? [parseInt(id)] : [],
  });

  const game = gameDetails && gameDetails[0];

  if (!game) {
    return null;
  }

  const showVideo = ["NBA", "NBA Preseason", "NCAA"].includes(game.leagueName);

  return (
    <Page
      title={`${game.awayTeam} at ${game.homeTeam} Preview}`}
      header={{ component: renderHeader(game) }}
    >
      <Row>
        <Col sm={12}>
          <Panel header="Video">
            {showVideo ? (
              <PreviewPageVideo game={game} />
            ) : (
              <div>
                Preview video is not available for {game.leagueName} games. The
                video can be found on the{" "}
                <Link
                  to={`/game/ml/${leagueToLeagueKey(game.leagueName)}/${id}`}
                >
                  game page
                </Link>{" "}
                when the game completes.
              </div>
            )}
          </Panel>
        </Col>
      </Row>
    </Page>
  );
}

function renderHeader(game: GameDetails) {
  return (
    <div>
      <h1>
        {`${game.awayTeam}`}{" "}
        <img
          width={40}
          src={`http://www.draftexpress.com/blue/graphics/logos/teams/${game.awayDE}.png`}
          alt=""
        />
        {` at ${game.homeTeam}`}{" "}
        <img
          width={40}
          src={`http://www.draftexpress.com/blue/graphics/logos/teams/${game.homeDE}.png`}
          alt=""
        />
      </h1>
      <h4>
        {` ${moment(game.gameDate, "YYYY-MM-DDTHH:mm:ss.SSSS").format(
          "MMM. D, YYYY"
        )} | ${game.gameTime}`}
      </h4>
    </div>
  );
}

function PreviewPageVideo(props: { game: GameDetails }) {
  const { game } = props;
  const [urlsExist, setUrlsExist] = useState(false);

  const { urls } = useMemo(() => getHoop1Urls(game), [game]);

  // See if any of the URLs actually exist. For simplicity just check the first.
  useEffect(() => {
    const checkUrls = async () => {
      setUrlsExist(false);

      if (urls.length === 0) return;

      try {
        const responses = await Promise.all(
          urls.map((url) => fetch(url, { method: "HEAD" }))
        );
        const anyUrlExists = responses.some(
          (response) => response.status !== 404
        );
        setUrlsExist(anyUrlExists);
      } catch (error) {
        console.error("Error checking URLs:", error);
      }
    };

    checkUrls();
  }, [urls]);

  const noVideoUrls = (
    <h6>
      Note: Game video will become available as it does on Hoop1 - typically
      around 10 minutes after the conclusion of each period. Please{" "}
      <a
        href={`mailto:chrisbu@celtics.com?subject=Video Failure&body=Failure to load hoop1 game ${game.gameId}`}
      >
        email Chris.{" "}
      </a>
      if a game is on Hoop1, but not loading here after a hard refresh ( command
      + shift + r ).
    </h6>
  );
  return (
    <div>
      {urls.length === 0 || urlsExist === false ? (
        noVideoUrls
      ) : (
        <VideoPlayer
          prefetchLength={1}
          clips={urls.map((u, i) => {
            return { url: u, label: `Period ${i + 1}` };
          })}
          upDownClipSkip={false}
          showSynergyEditor={false}
        />
      )}
    </div>
  );
}

function leagueToLeagueKey(league: string) {
  return league.toLowerCase().replaceAll(" ", "_");
}
