import React from "react";
import { Spinner as BootstrapSpinner } from "react-bootstrap";
import { createStyles, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    center: { textAlign: "center" },
    spinner: { verticalAlign: "middle", marginRight: 12 },
  })
);

export function Spinner(props: { message?: string }) {
  const classes = useStyles();
  const { message } = props;
  return (
    <div className={classes.center}>
      <BootstrapSpinner
        variant="primary"
        animation="border"
        role="status"
        className={classes.spinner}
      />
      <span>{message}</span>
    </div>
  );
}
