import React, { useMemo, useState } from "react";
import { Form, Col, Row } from "react-bootstrap";

import { Table, createColumnHelper, SortingState } from "../core/Table";
import { Spinner } from "../core/Spinner";
import { trpc } from "../../util/tRPC";
import { groupBy } from "../../../shared/util/Collections";
import { sum } from "../../util/Util";
import { GlobalImpactModelBreakdown } from "../../../shared/routers/PlayerRouter";
import { decFormat } from "../../util/Format";

export function PlayerGlobalImpactModelBreakdownTables(props: {
  playerId: number;
}) {
  const { playerId } = props;

  const { data } = trpc.player.getGlobalImpactModelBreakdown.useQuery({
    playerId,
  });

  const modelYears = data
    ? [...new Set(data.map((d) => d.Season.toString()))].sort(
        (a, b) => parseInt(b) - parseInt(a)
      )
    : [];

  const [year, setYear] = useState<string>();

  if (year === undefined && modelYears.length > 0) {
    setYear(modelYears[0]);
  }

  if (data === undefined || year === undefined) return <Spinner />;

  return (
    <div>
      <div style={{ gap: 10, display: "flex", alignItems: "center" }}>
        <Form.Label>Season</Form.Label>
        <Form.Select
          value={year}
          style={{ width: "auto", display: "inline" }}
          onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
            setYear(evt.target.value);
          }}
        >
          {modelYears.map((y) => {
            return (
              <option key={y} value={y}>
                {y}
              </option>
            );
          })}
        </Form.Select>
      </div>
      <Row>
        {["Offense", "Defense"].map((offenseDefense) => (
          <Col key={offenseDefense}>
            <div style={{ textAlign: "center" }}>
              <b>{offenseDefense}</b>
            </div>
            <BreakdownTable
              data={data.filter(
                (b) => b.Role === offenseDefense && b.Season.toString() === year
              )}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}

const columnHelper = createColumnHelper<GlobalImpactModelBreakdown>();

function BreakdownTable(props: { data: GlobalImpactModelBreakdown[] }) {
  {
    const { data } = props;
    const [sorting, setSorting] = useState<SortingState>();
    const dataBySkillGroup = groupBy(data, (b) => b.SkillGroup);

    const dataWithSubRows = Object.keys(dataBySkillGroup)
      .map((skillGroup) => {
        const skillsInGroup = dataBySkillGroup[skillGroup];
        if (skillsInGroup === undefined) return undefined;

        const firstRow = skillsInGroup[0];
        if (firstRow === undefined) return undefined;

        // If it's the only row return it.
        if (skillsInGroup.length === 1) {
          return firstRow;
        }

        return {
          celticsID: 123,
          Role: firstRow.Role,
          SkillGroup: skillGroup,
          Variable: `${skillGroup} Total`,
          Impact: sum("Impact", skillsInGroup) as number,
          Season: firstRow.Season,
          subRows: skillsInGroup,
        };
      })
      .filter((d) => d !== undefined) as GlobalImpactModelBreakdown[];

    const columns = useMemo(
      () => [
        columnHelper.accessor("SkillGroup", {
          header: () => "Skill Group",
          cell: (info) => (info.row.depth === 0 ? info.getValue() : ""),
          meta: { textAlign: "left" },
        }),
        columnHelper.accessor("Variable", {
          header: () => "Variable",
          cell: (info) => info.getValue(),
          meta: { textAlign: "left" },
        }),
        columnHelper.accessor("Impact", {
          header: () => "Impact",
          cell: (info) =>
            info.row.depth === 0 ? (
              <b>{decFormat(info.getValue() * 100)}</b>
            ) : (
              decFormat(info.getValue() * 100)
            ),
        }),
      ],
      []
    );

    return (
      <Table
        data={dataWithSubRows.sort((a, b) => b.Impact - a.Impact)}
        columns={columns}
        autoWidth={true}
        expandColumnId={"SkillGroup"}
        sorting={sorting}
        setSorting={setSorting}
        disableStickyColumn={true}
      />
    );
  }
}
