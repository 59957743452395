import React, { createContext, useContext, useMemo } from "react";
import type { CSSProperties, PropsWithChildren } from "react";
import type {
  DraggableSyntheticListeners,
  UniqueIdentifier,
} from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface Props {
  id: UniqueIdentifier;
}

interface Context {
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {},
});

export function SortableItem({ children, id }: PropsWithChildren<Props>) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  );
  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
    display: "flex",
    // justifyContent: "space-between",
    flexGrow: 1,
    alignItems: "center",
    padding: "18px 20px",
    backgroundColor: "#fff",
    boxShadow:
      "0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05), 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15)",
    borderRadius: "calc(4px / var(--scale-x, 1))",
    boxSizing: "border-box",
    listStyle: "none",
    color: "#333",
    fontWeight: 400,
    fontSize: "1rem",
    fontFamily: "sans-serif",
  };

  return (
    <SortableItemContext.Provider value={context}>
      <li ref={setNodeRef} style={style}>
        {children}
      </li>
    </SortableItemContext.Provider>
  );
}

// .DragHandle:hover {
//     background-color: rgba(0, 0, 0, 0.05);
//   }
// .DragHandle:focus-visible {
//     box-shadow: 0 0px 0px 2px #4c9ffe;
//   }
export function DragHandle() {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <button
      style={{
        display: "flex",
        width: 12,
        padding: 15,
        alignItems: "center",
        justifyContent: "center",
        flex: "0 0 auto",
        touchAction: "none",
        cursor: "var(--cursor, pointer)",
        borderRadius: 5,
        border: "none",
        outline: "none",
        appearance: "none",
        backgroundColor: "transparent",
        WebkitTapHighlightColor: "transparent",
      }}
      {...attributes}
      {...listeners}
      ref={ref}
    >
      <svg
        style={{
          flex: "0 0 auto",
          margin: "auto",
          height: "100%",
          overflow: "visible",
          fill: "#919eab",
        }}
        viewBox="0 0 20 20"
        width="12"
      >
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
      </svg>
    </button>
  );
}
