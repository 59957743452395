import React, { useContext } from "react";
import { BiPlusMedical } from "react-icons/bi";

import { PlayerStatusContext } from "../../PlayerStatusContext";
import { lineChartColors, redHigh } from "../../constants/ColorConstants";

export function PlayerInjuryMessage(props: { playerId: number }) {
  const { playerId } = props;

  const { playerInjuries, playerMissedGames } = useContext(PlayerStatusContext);

  const playerInjury = playerInjuries[playerId];
  const playerMissedGame = playerMissedGames[playerId];

  if (!playerInjury && !playerMissedGame) return null;

  if (!playerInjury && playerMissedGame) {
    // For 2-4 missed games, use yellow. For 5+ use red.
    const missedGamesColor =
      playerMissedGame.gamesMissed >= 5 ? redHigh : lineChartColors.yellow;
    return (
      <div style={{ color: missedGamesColor }}>
        <b>{`Missed last ${playerMissedGame.gamesMissed} games for ${playerMissedGame.team}`}</b>
      </div>
    );
  }

  if (!playerInjury) return null;

  // All ilnesses list "internal" as the body part and so we can just drop that
  // piece from the string as it doesn't add any info.
  let playerInjuryStr =
    [playerInjury.bodyPart, playerInjury.type]
      .filter((s) => s && s !== "internal")
      .join(" ") || "Unknown Injury";

  const timeFrameStr = [
    playerInjury.timeFrame,
    playerInjury.gamesMissed +
      ` game${playerInjury.gamesMissed === 1 ? "" : "s"} missed`,
  ]
    .filter((s) => s)
    .join(", ");
  playerInjuryStr += ` (${timeFrameStr})`;

  const playerInjuryStrFirstLetter = playerInjuryStr[0];
  if (playerInjuryStrFirstLetter !== undefined) {
    playerInjuryStr =
      playerInjuryStrFirstLetter.toLocaleUpperCase() + playerInjuryStr.slice(1);
  }

  return (
    <div style={{ color: redHigh }}>
      <b>{playerInjuryStr}</b>
      <BiPlusMedical
        style={{
          borderRadius: 2,
          backgroundColor: "white",
          color: redHigh,
          marginLeft: 4,
          verticalAlign: "top",
          width: 12,
          height: 12,
        }}
      />
    </div>
  );
}
