import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";

import AppContext from "../../shared/AppContext";
import { trpc } from "../util/tRPC";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Restrict } from "../components/core/Restrict";
import { DraftClassTable } from "../components/draft/DraftClassTable";

export function DraftClassPage() {
  const { year } = useParams();
  const navigate = useNavigate();

  const { data: draftClass } = trpc.draft.getDraftModel.useQuery({
    year: year || AppContext.currentSeason,
  });

  const years = [{ label: "All Draft Model Results", value: "all-model" }];
  const maxYear = AppContext.latestDraftYear;

  for (let y = maxYear; y >= 1997; y--) {
    years.push({ label: y.toString(), value: y.toString() });
  }

  const handleYearChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const year = evt.target.value;
    navigate(`/draft-class/${year}`);
  };

  const header = (
    <h1>
      <Form.Select
        value={year}
        onChange={handleYearChange}
        style={{
          width: "auto",
          marginRight: "1rem",
          display: "inline-block",
        }}
      >
        {years.map((y) => (
          <option key={y.value} value={y.value}>
            {y.label}
          </option>
        ))}
      </Form.Select>
      {"Draft Class"}
    </h1>
  );

  const pageTitle =
    year === "all-model" ? "All Draft Model Results" : `${year} Draft Class`;

  return (
    <Page header={{ component: header }} title={pageTitle}>
      <Row>
        <Col md={12}>
          <Panel>
            <Restrict roles={["admin", "bia", "scout"]}>
              {draftClass && (
                <DraftClassTable
                  data={draftClass}
                  autoWidth={true}
                  includeYear={year === "all-model"}
                  year={parseInt(year || AppContext.currentSeason)}
                />
              )}
            </Restrict>
          </Panel>
        </Col>
      </Row>
    </Page>
  );
}
