import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

import { PageHeader } from "./PageHeader";
import { PageTabs, Tabs } from "./PageTabs";
import { docTitle } from "../../util/Util";

export function Page(props: {
  header: { component: JSX.Element } | { text: string };
  title: string;
  children?: JSX.Element | null;
  tabs?: Tabs;
  activeTab?: string;
  onTabClick?: (newTab: string) => void;
  onSubLinkClick?: (subLink: string) => void;
}) {
  useEffect(() => {
    document.title = docTitle(props.title);
  }, [props.title]);

  const { tabs, activeTab, onTabClick, onSubLinkClick } = props;
  const showTabs = tabs && activeTab && onTabClick && onSubLinkClick;
  const activeTabTab = tabs && activeTab && tabs.tabs[activeTab];
  return (
    <div>
      <PageHeader {...props.header} />
      <Container fluid={true}>
        {showTabs && (
          <PageTabs
            tabs={tabs}
            activeTabKey={activeTab}
            onTabClick={onTabClick}
            onSubLinkClick={onSubLinkClick}
          />
        )}
        {props.children}
        {showTabs && activeTabTab && activeTabTab.content}
      </Container>
    </div>
  );
}
