import React, { useMemo, useState } from "react";

import { seasonString, decFormat, seFormat } from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { PlayerImpact } from "../../../shared/routers/ImpactRouter";
import {
  Table,
  createColumnHelper,
  SortingState,
} from "../../components/core/Table";
import { extent } from "d3";

const columnHelper = createColumnHelper<PlayerImpact>();

export function PlayerImpactSeason(props: { data: PlayerImpact[] }) {
  const { data } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    let g = 0;

    const stdExtent = extent(data, (d) => d.net_se || 0) as [number, number];

    return [
      columnHelper.accessor("Season", {
        cell: (info) => seasonString(info.getValue().toString()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("off_impact", {
        header: "Off Impact",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          colorDomain: [-4.553, 8.05],
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("def_impact", {
        header: "Def Impact",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          colorDomain: [-3.186, 6.16],
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("net_impact", {
        header: "Net Impact",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g,
          colorDomain: [-5.89, 9.25],
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("net_se", {
        header: "Net SE",
        cell: (info) => seFormat(info.getValue() * 100 * 1.97),
        meta: {
          group: g,
          neutralColorScheme: true,
          colorDomain: [stdExtent[1], stdExtent[0]],
          highlights: Highlights.Min,
        },
      }),
    ];
  }, [data]);

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
      showColorOnHover={true}
    />
  );
}
