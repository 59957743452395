import React from "react";
import { Col, Row } from "react-bootstrap";

import { Panel } from "../../components/core/Panel";
import { Shot } from "../../../shared/routers/ShotRouter";
import {
  GameBoxScore,
  GameTeamReboundModel,
  GamePlayerReboundModel,
  CrashAttempt,
  GameExpectedStats,
  GameExpectedTurnover,
  GameAttackAvoid,
} from "../../../shared/routers/GameRouter";
import {
  TeamStats,
  TeamActionBreakdown,
} from "../../../shared/routers/TeamRouter";
import { GameBoxScoresTable } from "../../components/games/GameBoxScoresTable";
import { TeamCrashing } from "../../components/crashing/TeamCrashing";
import { GamePlayerReboundingLuck } from "../../components/games/GamePlayerReboundingLuck";
import { GameTeamReboundingLuck } from "../../components/games/GameTeamReboundingLuck";
import { GameTeamShootingLuck } from "../../components/games/GameTeamShootingLuck";
import { GamePlayerShootingLuck } from "../../components/games/GamePlayerShootingLuck";
import { GameTeamShootingLuckBreakdown } from "../../components/games/GameTeamShootingLuckBreakdown";
import { GameTeamStats } from "../../components/games/GameTeamStats";
import { GameTeamActionBreakdown } from "../../components/games/GameTeamActionBreakdown";
import { GameAttackAvoidTables } from "../../components/games/GameAttackAvoidTables";
import { TeamColorBox } from "../../components/team/TeamColorBox";
import { GameXPtsBreakdownTable } from "../../components/games/GameXPtsBreakdownTable";
import { GameExpectedTurnoversTable } from "../../components/games/GameExpectedTurnoversTable";
import { GameChanceStartTypes } from "../../components/games/GameChanceStartTypes";
import { Restrict } from "../../components/core/Restrict";
import { groupBy } from "../../../shared/util/Collections";
import {
  ChanceEfficiencyPerStartType,
  EffPerStartTypeForCompGame,
} from "../../../shared/routers/ChanceRouter";

export function GameSubPagePostgame(props: {
  home?: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
    teamName: string;
  };
  away?: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
    teamName: string;
  };
  fromDate: string;
  toDate: string;
  shots?: Shot[];
  leagueStats?: TeamStats[];
  awayTeamStats?: TeamStats[];
  homeTeamStats?: TeamStats[];
  gameExpectedStats?: GameExpectedStats[];
  boxscores?: GameBoxScore[];
  gameActions?: TeamActionBreakdown[];
  seasonActions?: TeamActionBreakdown[];
  teamRebounds?: GameTeamReboundModel[];
  crashAttempts?: CrashAttempt[];
  playerRebounds?: GamePlayerReboundModel[];
  expectedTovs?: GameExpectedTurnover[];
  attackAvoid?: GameAttackAvoid[];
  chanceStartTypeData?: ChanceEfficiencyPerStartType[];
  chanceStartTypeCompData?: EffPerStartTypeForCompGame[];
}) {
  const {
    home,
    away,
    fromDate,
    toDate,
    shots,
    leagueStats,
    awayTeamStats,
    homeTeamStats,
    gameExpectedStats,
    boxscores,
    gameActions,
    seasonActions,
    teamRebounds,
    crashAttempts,
    playerRebounds,
    expectedTovs,
    attackAvoid,
    chanceStartTypeData,
    chanceStartTypeCompData,
  } = props;

  const homeStats = homeTeamStats?.[0];
  const awayStats = awayTeamStats?.[0];

  if (!home || !away) return null;

  return (
    <div>
      <Row>
        <Col md={6}>
          {shots && (
            <TeamShootingLuckPanel
              home={home}
              away={away}
              fromDate={fromDate}
              toDate={toDate}
              shots={shots}
            />
          )}
        </Col>
        <Col md={6}>
          <Panel header={"xPts Breakdown"}>
            <GameXPtsBreakdownTable data={gameExpectedStats} />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {shots && (
            <PlayerShootingLuckPanel home={home} away={away} shots={shots} />
          )}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <BoxScoresPanel data={boxscores} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <TeamStatComparisonPanel
            home={home}
            away={away}
            leagueStats={leagueStats}
            awayTeamStats={awayTeamStats}
            homeTeamStats={homeTeamStats}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <OffensiveDefensiveBreakdownPanel
            home={home}
            away={away}
            gameActions={gameActions}
            seasonActions={seasonActions}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <TeamReboundsPanel
            teamRebounds={teamRebounds}
            home={home}
            away={away}
          />
        </Col>
        <Col md={6}>
          <GameCrashingPanel
            crashAttempts={crashAttempts}
            home={home}
            away={away}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <PlayerReboundsPanel
            playerRebounds={playerRebounds}
            home={home}
            away={away}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          {shots && homeStats && awayStats && (
            <TeamShootingLuckBreakdownPanel
              shots={shots}
              home={home}
              away={away}
              homeStats={homeStats}
              awayStats={awayStats}
            />
          )}
        </Col>
        <Col md={6}>
          <Restrict roles={["bia"]}>
            <Panel header={"xTOV Breakdown - [BIA ONLY]"}>
              <GameExpectedTurnoversTable expectedTovs={expectedTovs} />
            </Panel>
          </Restrict>
        </Col>
      </Row>
      <Row>
        <Col>
          <Restrict roles={["bia"]}>
            <Panel header={"WIP: Attack Avoid - [BIA ONLY]"}>
              <GameAttackAvoidTables data={attackAvoid} />
            </Panel>
          </Restrict>
        </Col>
      </Row>
      <Row>
        <Col>
          <Panel
            header="Possession Start Type Efficiency Breakdown"
            panelID="startTypeEfficiency"
          >
            <GameChanceStartTypes
              data={chanceStartTypeData}
              compData={chanceStartTypeCompData}
              home={home}
              away={away}
            />
          </Panel>
        </Col>
      </Row>
    </div>
  );
}

function TeamShootingLuckPanel(props: {
  home: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
  };
  fromDate: string;
  toDate: string;
  shots: Shot[];
}) {
  const { home, away, shots, fromDate, toDate } = props;
  return (
    <Panel panelID="teamShotQuality" header={"Team Shot Quality"}>
      <GameTeamShootingLuck
        data={shots}
        home={home}
        away={away}
        fromDate={fromDate}
        toDate={toDate}
      />
    </Panel>
  );
}

function PlayerShootingLuckPanel(props: {
  home: {
    teamid: number;
    teamCity: string;
    team: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;
  };
  shots: Shot[];
}) {
  const { home, away, shots } = props;

  return (
    <Panel panelID="playerShotQuality" header={"Player Shot Quality"}>
      <GamePlayerShootingLuck data={shots} home={home} away={away} />
    </Panel>
  );
}

function BoxScoresPanel(props: { data?: GameBoxScore[] }) {
  const { data } = props;

  if (!data) return null;

  const dataByTeam = groupBy(data || [], (d) => d.homeAway);

  return (
    <Panel panelID="boxScores" header={"Box Scores"}>
      <>
        {/* Sort home before away. */}
        {Object.entries(dataByTeam)
          .sort((a, b) => (a[0] > b[0] ? -1 : 1))
          .map(([key, teamData]) => (
            <GameBoxScoresTable data={teamData} key={key} />
          ))}
      </>
    </Panel>
  );
}

function TeamStatComparisonPanel(props: {
  home: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
  };
  leagueStats?: TeamStats[];
  homeTeamStats?: TeamStats[];
  awayTeamStats?: TeamStats[];
}) {
  const { home, away, leagueStats, homeTeamStats, awayTeamStats } = props;

  const homeStats = homeTeamStats && homeTeamStats[0];
  const awayStats = awayTeamStats && awayTeamStats[0];

  return (
    <Panel panelID="teamStatComparison" header={"Team Stat Comparison"}>
      {homeStats && awayStats && leagueStats && (
        <GameTeamStats
          home={home}
          away={away}
          homeStats={homeStats}
          awayStats={awayStats}
          leagueStats={leagueStats}
        />
      )}
    </Panel>
  );
}

function OffensiveDefensiveBreakdownPanel(props: {
  home: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
    teamName: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;
    teamabbreviation: string;
    teamName: string;
  };
  gameActions?: TeamActionBreakdown[];
  seasonActions?: TeamActionBreakdown[];
}) {
  const { home, away, gameActions, seasonActions } = props;

  if (!gameActions || !seasonActions) return null;

  return (
    <Panel panelID="breakdown" header={"Offensive and Defensive Breakdown"}>
      <GameTeamActionBreakdown
        home={home}
        away={away}
        gameActions={gameActions}
        seasonActions={seasonActions}
      />
    </Panel>
  );
}

function TeamReboundsPanel(props: {
  teamRebounds?: GameTeamReboundModel[];
  home: {
    team: string;
    teamid: number;
    teamName: string;
    teamCity: string;
    teamabbreviation: string;
  };
  away: {
    team: string;
    teamid: number;
    teamName: string;
    teamCity: string;
    teamabbreviation: string;
  };
}) {
  const { teamRebounds, home, away } = props;

  return (
    <Panel panelID="teamRebounds" header={"Team Rebounding"}>
      {teamRebounds && (
        <div>
          <TeamColorBox teamId={home.teamid} oppTeamId={away.teamid} />
          {home.teamCity}{" "}
          <TeamColorBox teamId={away.teamid} oppTeamId={home.teamid} />
          {away.teamCity}
          <GameTeamReboundingLuck data={teamRebounds} home={home} away={away} />
        </div>
      )}
    </Panel>
  );
}

function PlayerReboundsPanel(props: {
  playerRebounds?: GamePlayerReboundModel[];
  home: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
}) {
  const { playerRebounds, home, away } = props;

  return (
    <Panel panelID="playerRebounds" header={"Player Rebounding"}>
      {playerRebounds && (
        <GamePlayerReboundingLuck
          data={playerRebounds}
          home={home}
          away={away}
        />
      )}
    </Panel>
  );
}

function TeamShootingLuckBreakdownPanel(props: {
  shots: Shot[];
  home: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
  homeStats: TeamStats;
  awayStats: TeamStats;
}) {
  const { shots, home, away, homeStats, awayStats } = props;
  return (
    <Panel
      panelID="teamShotQualityBreakdown"
      header={"Team Shot Quality Breakdown"}
    >
      <GameTeamShootingLuckBreakdown
        data={shots}
        home={home}
        away={away}
        awayFTPct={awayStats.ftPct}
        homeFTPct={homeStats.ftPct}
      />
    </Panel>
  );
}

function GameCrashingPanel(props: {
  crashAttempts?: CrashAttempt[];
  home: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;

    teamabbreviation: string;
  };
}) {
  const { crashAttempts, home, away } = props;

  if (crashAttempts === undefined || crashAttempts.length === 0) return null;

  return (
    <Panel header="Team Crashing" panelID="teamCrashing">
      <TeamCrashing
        data={crashAttempts}
        teams={[
          { teamName: home.team, teamId: home.teamid },
          { teamName: away.team, teamId: away.teamid },
        ]}
      />
    </Panel>
  );
}
