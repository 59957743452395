import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";

import { TooltipItem } from "../core/TooltipItem";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      margin: 8,
    },
    wideToolTip: {
      "& .tooltip-inner": {
        textAlign: "left",
        maxWidth: "unset",
      },
    },
    list: {
      display: "inline",
      listStyleType: "none",
      padding: 0,
    },
  })
);

export function PlayerRatingScaleToolTip(props: {
  children: JSX.Element | string;
}) {
  const classes = useStyles();
  const tooltipContent = (
    <div className={classes.container}>
      <ul className={classes.list}>
        <li>
          <b>10</b>: Best Player in the NBA / 1st Team All-NBA
        </li>
        <li>
          <b>9</b>: NBA All-Star / Top 25 Player in the NBA
        </li>
        <li>
          <b>8</b>: Fringe NBA All-Star / High Level Starter / Top 50 Player in
          the NBA
        </li>
        <li>
          <b>7</b>: NBA Starter / 3rd-4th Best Player on Average NBA Team
        </li>
        <li>
          <b>6</b>: Fringe NBA Starter or High End NBA Backup / 5th or 6th Best
          Player on Average NBA Team
        </li>
        <li>
          <b>5</b>: Solid NBA Backup / 7th-9th Best Player on Average NBA Team
        </li>
        <li>
          <b>4</b>: Low End NBA Backup / Fringe Rotation / 10th-12th Best Player
          on Average NBA Team
        </li>
        <li>
          <b>3</b>: Fringe NBA Player / 13th-15th Best Player on Average NBA
          Team
        </li>
        <li>
          <b>2</b>: Almost NBA Level / High Level GLeague / High Level Overseas
          Pro
        </li>
        <li>
          <b>1</b>: Solid GLeague Caliber / Mid Level Overseas Pro
        </li>
        <li>
          <b>0</b>: Below G-League Level / Video Intern
        </li>
      </ul>
    </div>
  );
  return (
    <TooltipItem
      tooltip={tooltipContent}
      tooltipClassName={classes.wideToolTip}
      noListItem={true}
    >
      {props.children}
    </TooltipItem>
  );
}
