import React from "react";
import { ButtonGroup, Button, Dropdown, DropdownButton } from "react-bootstrap";
import { groupBy } from "../../../shared/util/Collections";

import { trpc } from "../../util/tRPC";

export function ImpactDateSelector(props: {
  playerId: string;
  onChangeDate: (date: string) => void;
}) {
  const { playerId, onChangeDate } = props;

  const impactDataQuery = trpc.impact.getPlayerImpactOverTime.useQuery({
    playerId,
  });

  if (!impactDataQuery.data) return null;

  const data = impactDataQuery.data;

  const careerSorted = data.sort((a, b) => a.net_impact - b.net_impact);
  const dataBySeason = groupBy(data, (d) => d.Season.toString());
  const seasons = Object.keys(dataBySeason).sort((a, b) => (a > b ? -1 : 1));

  const firstCareerSorted = careerSorted[0];
  const lastCareerSorted = careerSorted[careerSorted.length - 1];

  if (firstCareerSorted === undefined || lastCareerSorted === undefined)
    return null;

  return (
    <ButtonGroup>
      <Button onClick={() => onChangeDate(firstCareerSorted.gameDate)}>
        Min
      </Button>
      <DropdownButton as={ButtonGroup} title="Season">
        {seasons.map((s, i) => (
          <>
            <Dropdown.Item
              key={"max-" + i}
              onClick={() => {
                const sorted = (dataBySeason[s] || []).sort(
                  (a, b) => a.net_impact - b.net_impact
                );
                const lastSorted = sorted[sorted.length - 1];
                if (lastSorted) onChangeDate(lastSorted.gameDate);
              }}
            >
              {s} Max
            </Dropdown.Item>
            <Dropdown.Item
              key={"min-" + i}
              onClick={() => {
                const sorted = (dataBySeason[s] || []).sort(
                  (a, b) => a.net_impact - b.net_impact
                );
                const firstSorted = sorted[0];
                if (firstSorted) onChangeDate(firstSorted.gameDate);
              }}
            >
              {s} Min
            </Dropdown.Item>
          </>
        ))}
      </DropdownButton>
      <Button onClick={() => onChangeDate(lastCareerSorted.gameDate)}>
        Max
      </Button>
    </ButtonGroup>
  );
}
