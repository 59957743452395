import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { DraftRankingChart } from "../components/draft/DraftRankingChart";
import { groupBy } from "../../shared/util/Collections";
import { ordinalFormat } from "../util/Format";
import { trpc } from "../util/tRPC";
import { ScoutDraftRanking } from "../../shared/routers/DraftRouter";
import { ParentSize } from "@visx/responsive";

export function ScoutRankingsPage() {
  const [year, setYear] = useState<string>();
  const [player, setPlayer] = useState<string>();
  const [years, setYears] = useState<number[]>();
  const [players, setPlayers] = useState<string[]>();
  const [draftYearsForRanker, setDraftYearsForRanker] =
    useState<Record<number, Set<string>>>();

  const [highestRankByPlayer, setHighestRankByPlayer] =
    useState<Record<string, { rank: number; rankers: string[] }>>();
  const [lowestRankByPlayer, setLowestRankByPlayer] =
    useState<Record<string, { rank: number; rankers: string[] }>>();

  const { data: rankings } = trpc.draft.getScoutDraftRankings.useQuery();

  useEffect(() => {
    if (!rankings) return;

    const years = Array.from(new Set(rankings.map((r) => r.Year)))
      .sort()
      .reverse();
    setYears(years);
    const firstYear = years[0];
    setYear((firstYear || 0).toString());

    // Figure out who actually made rankings for each year so that we don't
    // mark player as unranked by a ranker if that ranker didn't rank anyone
    // that year.
    const draftYearsForRanker: Record<number, Set<string>> = {};
    for (const year of years) {
      draftYearsForRanker[year] = new Set(
        rankings.filter((r) => r.Year === year).map((r) => r.Ranker)
      );
    }
    setDraftYearsForRanker(draftYearsForRanker);
  }, [rankings]);

  useEffect(() => {
    if (year === undefined || !rankings || !draftYearsForRanker) return;

    const players = Array.from(
      new Set(
        rankings
          .filter((r) => !year || r.Year === parseInt(year))
          .map((r) => r.Player)
      )
    ).sort();
    setPlayers(players);
    setPlayer(players[0]);

    const highestRankByPlayer: Record<
      string,
      { rank: number; rankers: string[] }
    > = {};
    const lowestRankByPlayer: Record<
      string,
      { rank: number; rankers: string[] }
    > = {};
    for (const player of players) {
      const playerDraftYear = rankings.find((r) => r.Player === player)?.Year;
      if (!playerDraftYear) continue;
      const rankers = Array.from(draftYearsForRanker[playerDraftYear] || []);
      const playerRanks = getAllRanksForPlayer(rankings, player, rankers);
      const hiToLow = playerRanks.sort((a, b) => a.Rank - b.Rank);
      const firstHiToLow = hiToLow[0];
      const max = firstHiToLow ? firstHiToLow.Rank : 0;
      const lowToHi = playerRanks.sort((a, b) => b.Rank - a.Rank);
      const firstLowToHi = lowToHi[0];
      const min = firstLowToHi ? firstLowToHi.Rank : 0;
      const maxRankers = playerRanks
        .filter((pr) => pr.Rank === max)
        .map((pr) => pr.Ranker)
        .sort();
      const minRankers = playerRanks
        .filter((pr) => pr.Rank === min)
        .map((pr) => pr.Ranker)
        .sort();
      highestRankByPlayer[player] = { rank: max, rankers: maxRankers };
      lowestRankByPlayer[player] = { rank: min, rankers: minRankers };
    }
    setHighestRankByPlayer(highestRankByPlayer);
    setLowestRankByPlayer(lowestRankByPlayer);
  }, [year, rankings, draftYearsForRanker]);

  if (!rankings) return null;

  const header = (
    <div>
      <h1>
        <Form.Select
          value={year}
          onChange={(evt) => setYear(evt.target.value)}
          style={{
            width: "auto",
            marginRight: "1rem",
            display: "inline-block",
          }}
        >
          {years &&
            years.map((y) => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
        </Form.Select>
        {"Draft Class"}
      </h1>
      <Form.Select
        value={player}
        onChange={(evt) => setPlayer(evt.target.value)}
        style={{ width: "auto", marginRight: "1rem", display: "inline-block" }}
      >
        {players &&
          players.map((p) => (
            <option key={p} value={p}>
              {p}
            </option>
          ))}
      </Form.Select>
      {player && highestRankByPlayer && lowestRankByPlayer && (
        <div>
          <div>
            Highest:{" "}
            {formatRank((highestRankByPlayer[player] || { rank: 0 }).rank) +
              " " +
              (highestRankByPlayer[player] || { rankers: [] }).rankers
                .map((r) => formatRanker(r))
                .join(", ")}
          </div>
          <div>
            Lowest:{" "}
            {formatRank((lowestRankByPlayer[player] || { rank: 0 }).rank) +
              " " +
              (lowestRankByPlayer[player] || { rankers: [] }).rankers
                .map((r) => formatRanker(r))
                .join(", ")}
          </div>
        </div>
      )}
    </div>
  );

  const pageTitle = `${year} Pre-Draft Scout Rankings`;

  const byRanker = groupBy(
    rankings.filter((r) => !year || r.Year === parseInt(year)),
    (r) => r.Ranker
  );

  const byPlayer = groupBy(
    rankings.filter((r) => !year || r.Year === parseInt(year)),
    (r) => r.Player
  );

  return (
    <Page header={{ component: header }} title={pageTitle}>
      <>
        <Row>
          <Col>
            <Panel header={"Collective Rankings"}>
              <ParentSize>
                {({ width }) => (
                  <DraftRankingChart
                    width={width}
                    rankers={Object.keys(byRanker)}
                    rankingsByPlayer={byPlayer}
                  />
                )}
              </ParentSize>
            </Panel>
          </Col>
        </Row>
        <Row>
          {Object.keys(byRanker)
            .sort()
            .map((ranker, i) => {
              return (
                <Col md={4} key={i}>
                  <RankerPanel
                    ranker={ranker}
                    year={year}
                    player={player}
                    ranks={byRanker[ranker] || []}
                    highestRankByPlayer={highestRankByPlayer}
                    lowestRankByPlayer={lowestRankByPlayer}
                  />
                </Col>
              );
            })}
        </Row>
      </>
    </Page>
  );
}

function RankerPanel(props: {
  ranker: string;
  year?: string;
  player?: string;
  ranks: ScoutDraftRanking[];
  highestRankByPlayer?: Record<string, { rank: number; rankers: string[] }>;
  lowestRankByPlayer?: Record<string, { rank: number; rankers: string[] }>;
}) {
  const [showAllPicks, setShowAllPicks] = useState(false);
  const {
    ranker,
    year,
    player,
    ranks,
    highestRankByPlayer,
    lowestRankByPlayer,
  } = props;

  const showAllPickseButtonClick = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setShowAllPicks(!showAllPicks);
    evt.stopPropagation();
  };

  const buttonText = showAllPicks ? "Hide All Picks" : "Show All Picks";

  const header = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>{formatRanker(ranker)}</span>
      {year && <Button onClick={showAllPickseButtonClick}>{buttonText}</Button>}
    </div>
  );

  return (
    <Panel header={header}>
      <div>
        {highestRankByPlayer && (
          <div
            style={{
              display: "inline-block",
              verticalAlign: "top",
              marginRight: 10,
              marginBottom: 10,
            }}
          >
            <b>Highest on:</b>
            {Object.entries(highestRankByPlayer)
              .filter(
                (kv) =>
                  kv[1].rankers.includes(ranker) && kv[1].rankers.length === 1
              )
              .sort((kv1, kv2) => kv1[1].rank - kv2[1].rank)
              .map((kv) => (
                <div key={kv[0]}>
                  {formatRank(kv[1].rank)} {kv[0]}
                </div>
              ))}
          </div>
        )}
        {lowestRankByPlayer && (
          <div
            style={{
              display: "inline-block",
              verticalAlign: "top",
              marginRight: 10,
              marginBottom: 10,
            }}
          >
            <b>Lowest on:</b>
            {Object.entries(lowestRankByPlayer)
              .filter(
                (kv) =>
                  kv[1].rankers.includes(ranker) && kv[1].rankers.length === 1
              )
              .sort((kv1, kv2) => kv1[1].rank - kv2[1].rank)
              .map((kv) => (
                <div key={kv[0]}>
                  {formatRank(kv[1].rank)} {kv[0]}
                </div>
              ))}
          </div>
        )}
        {showAllPicks && year && (
          <div>
            <b>Full Board</b>
            {ranks.map((r, j) => {
              return (
                <div
                  key={j}
                  style={{
                    fontWeight: r.Player === player ? "bold" : "normal",
                  }}
                >
                  {r.Rank}. {r.Player}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Panel>
  );
}

// Returns all ranks for a given player. Makes sure to include MAX_VALUE entries
// when a ranker did not rank a player.
function getAllRanksForPlayer(
  rankings: ScoutDraftRanking[],
  player: string,
  rankers: string[]
) {
  const playerRankings = rankings.filter((r) => r.Player === player);
  const firstRank = playerRankings[0];
  if (!firstRank) return [];
  const year = firstRank.Year;
  for (const ranker of rankers) {
    if (!playerRankings.find((pr) => pr.Ranker === ranker)) {
      playerRankings.push({
        Ranker: ranker,
        Year: year,
        Player: player,
        Rank: Number.MAX_VALUE,
      });
    }
  }
  return playerRankings;
}

function formatRank(rank: number) {
  if (rank === Number.MAX_VALUE) return "(unranked)";
  return ordinalFormat(rank);
}

const RANKER_MAP: Record<string, string> = {
  AA: "Austin Ainge",
  AB: "Ashley Battle",
  AL: "Adam Lewin",
  BM: "Benas Matkevicius",
  BS: "Brad Stevens",
  Bu: "Buddy Scott",
  CB: "Chris Bubernak",
  DC: "Drew Cannon",
  DL: "Dave Lewin",
  DN: "Drew Nicholas",
  DS: "David Sparks",
  GA: "Gib Arnold",
  JE: "Jake Eastman",
  MZ: "Mike Zarren",
  PS: "Parker Sexton",
  RC: "Remy Cofield",
  SF: "Shane Fenske",
};

function formatRanker(ranker: string) {
  return RANKER_MAP[ranker] || ranker;
}
