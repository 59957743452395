import React, { useMemo, useState } from "react";
import moment from "moment";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { PlayerInjury } from "../../../shared/routers/PlayerRouter";

const columnHelper = createColumnHelper<PlayerInjury>();

export function PlayerInjuryTable(props: { data: PlayerInjury[] }) {
  const { data } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("notes", {
        header: () => "Injury",
        cell: (info) => info.getValue(),
        meta: { group: 0, textAlign: "left" },
      }),
      columnHelper.accessor("bodyPartDetailed", {
        header: () => "Body Part",
        cell: (info) => info.getValue(),
        meta: { group: 1, textAlign: "left" },
      }),
      columnHelper.accessor("gamesMissed", {
        header: () => "Games Missed",
        cell: (info) => info.getValue(),
        meta: { group: 2 },
      }),
      columnHelper.accessor("date", {
        header: () => "Date",
        cell: (info) => moment(info.getValue()).format("MM/DD/YYYY"),
        meta: { group: 3 },
      }),
    ],
    []
  );
  return (
    <Table
      data={data.sort((a, b) => moment(a.date).diff(moment(b.date)))}
      columns={columns}
      autoWidth={true}
      showColorOnHover={true}
      sorting={sorting}
      setSorting={setSorting}
      disableStickyColumn={true}
    />
  );
}
