import React from "react";
import cx from "classnames";
import { createStyles, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      width: 16,
      height: 16,
      display: "inline-block",
      lineHeight: 1,
      position: "relative",
      top: 1,
      cursor: "pointer",
      backgroundImage: "url(/assets/img/sprites16_1x.png)",
      backgroundRepeat: "no-repeat",
      ["@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)"]:
        {
          backgroundImage: "url(/assets/img/sprites16_2x.png)",
          backgroundSize: "32px 16px",
        },
      "&.icon-wingspan": {
        backgroundPosition: "0 0",
      },
      "&.icon-standing-reach": {
        backgroundPosition: "-16px 0",
      },
    },
  })
);

export function Icon(props: { type: string; className?: string }) {
  const classes = useStyles();
  const className = cx(classes.icon, `icon-${props.type}`, props.className);
  return <span className={className} />;
}
