import React, { useMemo, useState } from "react";

import AppContext from "../../../shared/AppContext";
import {
  Table,
  ColumnDef,
  SortingState,
  createColumnHelper,
} from "../core/Table";
import {
  pctFormat,
  decFormat,
  decFormat2,
  seasonString,
} from "../../util/Format";
import { TableNote } from "../core/TableNote";
import { trpc } from "../../util/tRPC";
import { TABLE_EMPTY_VALUE_STR } from "../../util/Format";

interface PostupBreakdown {
  label: string;
  overall: number | null;
  left: number | null;
  right: number | null;
  defended: number | null;
  fmt: (n: number | null) => string;
}

const columnHelper = createColumnHelper<PostupBreakdown>();

export function PlayerPostUpsTable(props: { playerId: number }) {
  const { playerId } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const { data: postupsData } = trpc.player.getPlayerPostUps.useQuery({
    playerId,
  });
  const { data: postupsPerGame } = trpc.player.getPlayerPostUpsPerGame.useQuery(
    { playerId }
  );

  const postups = postupsData && postupsData[0];

  const columns = useMemo(
    () => [
      columnHelper.accessor("label", {
        header: () => "",
        cell: (info) => <b>{info.getValue()}</b>,
        meta: { group: 0, colorDomain: rowColors, textAlign: "left" },
      }),
      columnHelper.group({
        meta: { group: 1 },
        id: "offense",
        header: () => <span>Offense</span>,
        columns: [
          columnHelper.accessor("overall", {
            header: () => "Overall",
            cell: (info) => {
              return info.row.original.fmt(info.getValue());
            },
            meta: { group: 1, colorDomain: rowColors },
          }),
          columnHelper.accessor("left", {
            header: () => "Left",
            cell: (info) => {
              return info.row.original.fmt(info.getValue());
            },
            meta: { group: 1, colorDomain: rowColors },
          }),
          columnHelper.accessor("right", {
            header: () => "Right",
            cell: (info) => {
              return info.row.original.fmt(info.getValue());
            },
            meta: { group: 1, colorDomain: rowColors },
          }),
        ],
      }),
      columnHelper.accessor("defended", {
        header: () => "Defended",
        cell: (info) => {
          return info.row.original.fmt(info.getValue());
        },
        meta: { group: 2, colorDomain: rowColorsDef },
      }),
    ],
    []
  );

  if (!postups) return null;

  const data: PostupBreakdown[] = [
    {
      label: "# Post Ups",
      overall: postups.n,
      left: postups.nLeft,
      right: postups.nRight,
      defended: postups.nDef,
      fmt: (n: number | null) =>
        n === null ? TABLE_EMPTY_VALUE_STR : n.toString(),
    },
    {
      label: "PPP",
      overall:
        postups.effLeft === null || postups.effRight === null
          ? null
          : (postups.effLeft * postups.nLeft +
              postups.effRight * postups.nRight) /
            postups.n,
      left: postups.effLeft,
      right: postups.effRight,
      defended: postups.effDef,
      fmt: decFormat2,
    },
    {
      label: "Pass %",
      overall:
        postups.passLeft === null || postups.passRight === null
          ? null
          : (postups.passLeft * postups.nLeft +
              postups.passRight * postups.nRight) /
            postups.n,
      left: postups.passLeft,
      right: postups.passRight,
      defended: postups.passDef,
      fmt: pctFormat,
    },
    {
      label: "% Double Teamed",
      overall:
        postups.doubleLeft === null || postups.doubleRight === null
          ? null
          : (postups.doubleLeft * postups.nLeft +
              postups.doubleRight * postups.nRight) /
            postups.n,
      left: postups.doubleLeft,
      right: postups.doubleRight,
      defended: postups.doubleDef,
      fmt: pctFormat,
    },
    {
      label: "TO %",
      overall:
        postups.tovLeft === null || postups.tovRight === null
          ? null
          : (postups.tovLeft * postups.nLeft +
              postups.tovRight * postups.nRight) /
            postups.n,
      left: postups.tovLeft,
      right: postups.tovRight,
      defended: postups.tovDef,
      fmt: pctFormat,
    },
    {
      label: "Fouls Drawn per Post Up",
      overall:
        postups.foulLeft === null || postups.foulRight === null
          ? null
          : (postups.foulLeft * postups.nLeft +
              postups.foulRight * postups.nRight) /
            postups.n,
      left: postups.foulLeft,
      right: postups.foulRight,
      defended: postups.foulDef,
      fmt: pctFormat,
    },
  ];

  const currentSeason = parseInt(AppContext.currentSeason, 10);

  return (
    <div>
      <Table
        data={data}
        columns={columns}
        autoWidth={true}
        showColorOnHover={true}
        sorting={sorting}
        setSorting={setSorting}
      />
      {postupsPerGame && (
        <div>
          <em>Post Ups Per Game</em>
          <ul style={{ paddingLeft: 0, listStyle: "none" }}>
            {postupsPerGame
              .sort((a, b) => a.season - b.season)
              .map((s) => {
                return (
                  <li
                    key={s.season}
                    style={{ display: "inline-block", marginRight: 4 }}
                  >
                    <em>{decFormat(s.postsPerGame)}</em> in{" "}
                    {seasonString(s.season.toString())}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
      <TableNote
        note={
          <ul className="list-unstyled bottom">
            <li>{`Includes data from 2013-14 to ${
              currentSeason - 1
            }-${currentSeason.toString().substring(2, 4)}`}</li>
            <li>Values account for double teams and assume single coverage.</li>
          </ul>
        }
      />
    </div>
  );
}

// Based on 5-95% percentiles.
function rowColors(data: PostupBreakdown, colDef: ColumnDef<PostupBreakdown>) {
  let colors: number[] = [];
  switch (data.label) {
    case "PPP":
      colors = [0.957, 1.033];
      break;
    case "% Double Teamed":
      colors = [0.076, 0.139];
      break;
    case "Pass %":
      colors = [0.284, 0.472];
      break;
    case "TO %":
      colors = [0.064, 0.055];
      break;
    case "Fouls Drawn per Post Up":
      colors = [0.064, 0.08];
      break;
    case "# Post Ups":
      if ((colDef as any).accessorKey === "overall") {
        colors = [0, 976];
      } else {
        colors = [0, 488];
      }
      break;
  }

  return colors;
}

// Based on 5-95% percentiles.
function rowColorsDef(data: PostupBreakdown) {
  let colors: number[] = [];
  switch (data.label) {
    case "PPP":
      colors = [1.065, 0.988];
      break;
    case "% Double Teamed":
      colors = [0.291, 0.075];
      break;
    case "Pass %":
      colors = [0.33, 0.354];
      break;
    case "TO %":
      colors = [0.056, 0.058];
      break;
    case "Fouls Drawn per Post Up":
      colors = [0.078, 0.067];
      break;
    case "# Post Ups":
      colors = [0, 604];
      break;
  }

  return colors;
}
