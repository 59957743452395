import React, { useMemo } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import moment from "moment-timezone";

import { TopPerformanceForDate } from "../../shared/routers/PlayerRouter";
import { Table, createColumnHelper } from "../components/core/Table";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { trpc } from "../util/tRPC";
import { PlayerTableCell, TeamTableCell } from "../components/core/TableCell";
import AppContext from "../../shared/AppContext";
import { minutesFormat, pctFormat, decFormat } from "../util/Format";
import { Highlights } from "../constants/AppConstants";

const useStyles = makeStyles(() =>
  createStyles({
    actionIcon: {
      cursor: "pointer",
      opacity: 0.5,
      marginLeft: 5,
      marginRight: 5,
      "&:hover": {
        opacity: 1,
      },
    },
    teamContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    gameInfo: {
      textAlign: "right",
      padding: 10,
    },
    datePicker: {
      width: "auto",
      display: "inline-block",
    },
  })
);

export function TopPerformersPage() {
  const classes = useStyles();
  const { date } = useParams();
  const navigate = useNavigate();

  const { data } = trpc.player.getTopPerformancesForDate.useQuery({
    gameDate: date,
  });

  const handleDateChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    navigate(`/top-performers/${evt.target.value}`);
  };

  const prevDay = () => {
    const d = moment(date).subtract(1, "days");
    navigate(`/top-performers/${d.format("YYYY-MM-DD")}`);
  };

  const nextDay = () => {
    const d = moment(date).add(1, "days");
    navigate(`/top-performers/${d.format("YYYY-MM-DD")}`);
  };

  const header = (
    <span>
      <h1>{`Top Performers on ${moment(date).format("MMMM Do, YYYY")}`}</h1>
      <FaChevronLeft onClick={prevDay} className={classes.actionIcon} />
      <Form.Control
        className={classes.datePicker}
        type="date"
        value={date}
        onChange={handleDateChange}
      ></Form.Control>
      <FaChevronRight onClick={nextDay} className={classes.actionIcon} />
    </span>
  );

  const topPerformances = data || [];

  const nextDraftYear = parseInt(AppContext.currentSeason);

  const isLikelyDraftProspect = (tp: TopPerformanceForDate) =>
    tp.draftClass !== null &&
    parseInt(tp.draftClass) >= nextDraftYear &&
    tp.pNBA !== null &&
    tp.pNBA > 0.5;

  const upcomingDraft = topPerformances
    .filter(isLikelyDraftProspect)
    .slice(0, 25);

  const nba = topPerformances
    .filter((tp) => tp.leagueKey === "nba")
    .slice(0, 25);

  const ncaa = topPerformances
    .filter((tp) => tp.leagueKey === "ncaa" && !isLikelyDraftProspect(tp))
    .slice(0, 25);

  const other = topPerformances
    .filter(
      (tp) =>
        tp.leagueKey !== "nba" &&
        tp.leagueKey !== "ncaa" &&
        !isLikelyDraftProspect(tp)
    )
    .slice(0, 25);

  return (
    <Page header={{ component: header }} title="Best Performances of the Day">
      {data !== undefined ? (
        <div>
          <Row>
            <Col md={6}>
              <Panel header="Likely NBA Prospects">
                <TopPerformersTable data={upcomingDraft} showpNBA={true} />
              </Panel>
            </Col>
            <Col md={6}>
              <Panel header="Other NCAA">
                <TopPerformersTable data={ncaa} showpNBA={true} />
              </Panel>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Panel header="NBA">
                <TopPerformersTable data={nba} />
              </Panel>
            </Col>
            <Col md={6}>
              <Panel header="Everyone Else">
                <TopPerformersTable data={other} showLeague={true} />
              </Panel>
            </Col>
          </Row>
        </div>
      ) : (
        <div></div>
      )}
    </Page>
  );
}

const columnHelper = createColumnHelper<TopPerformanceForDate>();

function TopPerformersTable(props: {
  data: TopPerformanceForDate[];
  showLeague?: boolean;
  showpNBA?: boolean;
}) {
  const { data, showLeague, showpNBA } = props;

  const columns = useMemo(
    () => [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.playerId || undefined}
          />
        ),
      }),
      columnHelper.accessor("Age", {
        header: () => "Age",
        cell: (info) => decFormat(info.getValue()),
      }),
      columnHelper.accessor("team", {
        header: () => "Team",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue()}
            id={info.row.original.teamId}
            league={info.row.original.leagueKey}
            ids={info.row.original.teamIDSId || undefined}
            season={info.row.original.season.toString()}
          />
        ),
      }),
      columnHelper.accessor("league", {
        header: () => "League",
      }),
      columnHelper.accessor("gameId", {
        header: () => "Game",
        cell: (info) => {
          const gameId = info.row.original.gameId;
          const leagueKey = info.row.original.leagueKey;

          let url = `/game/ml/${leagueKey}/${gameId}`;

          if (leagueKey === "nba") {
            url = `/game/${gameId}`;
          }

          return (
            <Link to={url}>
              <b>{info.row.original.win ? "W" : "L"}</b>{" "}
              {info.row.original.ptsFor}-{info.row.original.ptsAgainst}
            </Link>
          );
        },
      }),
      columnHelper.accessor("draftClass", {
        header: () => "Draft Class",
      }),
      columnHelper.accessor("pNBA", {
        header: () => "pNBA",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("min", {
        header: () => "MIN",
        cell: (info) => minutesFormat(info.getValue() || 0),
        meta: {
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("pts", {
        header: () => "PTS",
        meta: {
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("reb", {
        header: () => "REB",
        meta: {
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("ast", {
        header: () => "AST",
        meta: {
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("stl", {
        header: () => "STL",
        meta: {
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("blk", {
        header: () => "BLK",
        meta: {
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("gameScore", {
        header: () => "GameSc.",
        meta: {
          highlights: Highlights.Max,
        },
      }),
    ],
    []
  );

  const hiddenColumns = {
    league: showLeague === true,
    pNBA: showpNBA === true,
    draftClass: showpNBA === true,
  };

  if (data.length === 0) return <div>No results found.</div>;

  return (
    <Table
      data={data}
      columns={columns}
      autoWidth={true}
      hiddenColumns={hiddenColumns}
    />
  );
}
