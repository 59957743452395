import React, { useState, useMemo } from "react";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { dec100Format } from "../../util/Format";
import { TeamFreeThrows } from "../../../shared/routers/TeamRouter";
import { PlayerTableCell } from "../core/TableCell";

const columnHelper = createColumnHelper<TeamFreeThrows>();

export function TeamFreeThrowsTable(props: { freeThrows?: TeamFreeThrows[] }) {
  const { freeThrows } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.playerId}
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.accessor("ftpct", {
        header: () => "xFT%",
        cell: (info) => dec100Format(info.getValue()),
        meta: { group: 1 },
      }),
    ],
    []
  );

  return (
    <div>
      {freeThrows && (
        <Table
          data={freeThrows}
          columns={columns}
          sorting={sorting}
          setSorting={setSorting}
          autoWidth={true}
          showColorOnHover={true}
        />
      )}
    </div>
  );
}
