// modifies an extent to make sure it includes the specified values
// can be used to make a domain fit some additional values. e.g. [0.2, 0.4], 0.3, 0.1 ==> [0.1, 0.4]
export function fitExtent(extent, ...values) {
  const newExtent = [extent[0], extent[1]];

  values.forEach((x) => {
    if (newExtent[0] > x) {
      newExtent[0] = x;
    }
    if (newExtent[1] < x) {
      newExtent[1] = x;
    }
  });

  return newExtent;
}
