import React, { useContext } from "react";

import { PlayerStatusContext } from "../../PlayerStatusContext";
import { blueLow, redHigh } from "../../constants/ColorConstants";
import { decFormat2, makePlusMinus } from "../../util/Format";
import { UserPreferenceContext } from "../../UserContext";

export function PlayerHotColdMessage(props: { playerId: number }) {
  const { playerId } = props;

  const hotColdPref = useContext(UserPreferenceContext)["Hot/Cold Shooting"];

  const { playerHotCold } = useContext(PlayerStatusContext);

  const isHotCold = playerId && playerHotCold[playerId];

  if (!isHotCold || !hotColdPref) return null;

  const isHot = isHotCold.luck > 0;

  return (
    <div style={{ color: isHot ? redHigh : blueLow }}>
      <b>{`Recent ${isHot ? "hot" : "cold"} streak shooting ${makePlusMinus(
        decFormat2
      )(isHotCold.luck)} vs expected.`}</b>
    </div>
  );
}
