import React from "react";
import cx from "classnames";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function TooltipItem(props: {
  placement?: "top" | "right" | "bottom" | "left";
  arrow?: boolean;
  tooltip: JSX.Element | string;
  tooltipClassName?: string;
  children:
    | (JSX.Element | string | boolean | undefined | null)[]
    | (JSX.Element | string | boolean | undefined | null);
  triggerClassName?: string;
  noListItem?: boolean;
}) {
  const {
    placement,
    tooltip,
    tooltipClassName,
    triggerClassName,
    arrow,
    children,
    noListItem,
  } = props;
  const overlay = (
    <Tooltip
      className={cx(tooltipClassName, arrow === false ? "no-arrow" : null)}
    >
      {tooltip}
    </Tooltip>
  );

  const content = noListItem ? (
    <span>{children}</span>
  ) : (
    <li className={triggerClassName}>{children}</li>
  );

  return (
    <OverlayTrigger placement={placement || "bottom"} overlay={overlay}>
      {content}
    </OverlayTrigger>
  );
}
