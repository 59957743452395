import React from "react";
import { Link } from "react-router-dom";

export function NotFoundPage() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2>Error (404)</h2>
          <h3 className="summary">{window.location.toString()}</h3>
          <h4>
            {"We can't find the page that you're looking for. Head to the "}
            <Link to="/">main page</Link>
            {"."}
          </h4>
        </div>
      </div>
    </div>
  );
}
