import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";

import { getContrastingTeamColor } from "../../util/Colors";

const useStyles = makeStyles(() =>
  createStyles({
    colorBox: {
      width: 18,
      height: 14,
      marginRight: 4,
      display: "inline-block",
      borderStyle: "solid",
      borderWidth: 2,
    },
  })
);

export function TeamColorBox(props: { teamId: number; oppTeamId: number }) {
  const classes = useStyles();
  const { teamId, oppTeamId } = props;

  const { primary, secondary } = getContrastingTeamColor(teamId, oppTeamId);

  return (
    <div
      className={classes.colorBox}
      style={{ backgroundColor: primary, borderColor: secondary }}
    ></div>
  );
}
