import React, { useMemo } from "react";

import { TeamColorBox } from "../team/TeamColorBox";
import { GameTeamReboundingLuck } from "../games/GameTeamReboundingLuck";
import {
  SecondSpectrumChance,
  SecondSpectrumFreeThrow,
  SecondSpectrumRebound,
  SecondSpectrumShot,
  LiveGameDetails,
  SecondSpectrumChancePlayer,
} from "../../../shared/routers/LiveGameRouter";
import { realReboundOpp } from "../../util/LivePageUtil";
import { GameTeamReboundModel } from "../../../shared/routers/GameRouter";
import {
  xRebForPlayer,
  teamOrpEstimate,
  normalizedReboundProb,
  parsePossessionCovariates,
} from "../../util/ReboundModel";
import { trpc } from "../../util/tRPC";
import { sum } from "../../util/Util";

export function LiveGameRebounds(props: {
  playerEagleIds: string[];
  rebounds: SecondSpectrumRebound[];
  freeThrows: SecondSpectrumFreeThrow[];
  details: LiveGameDetails;
  chanceMap: Map<string, SecondSpectrumChance>;
  shotMap: Map<string, SecondSpectrumShot>;
  chancePlayerMap: Map<string, SecondSpectrumChancePlayer[]>;
}) {
  const {
    playerEagleIds,
    rebounds,
    freeThrows,
    details,
    chanceMap,
    shotMap,
    chancePlayerMap,
  } = props;

  const { data: fixedEffects } =
    trpc.liveGame.getReboundModelFixedEffects.useQuery();

  const { data: secondaryFixedEffects } =
    trpc.liveGame.getSecondaryReboundModelFixedEffects.useQuery();

  const { data: leverageFixedEffects } =
    trpc.liveGame.getLeverageModelFixedEffects.useQuery();

  const eagleIds = playerEagleIds.concat([
    `${details.homeTeamEagleId} ${details.season}`,
    `${details.awayTeamEagleId} ${details.season}`,
  ]);

  const { data: randomEffects } =
    trpc.liveGame.getReboundModelRandomEffects.useQuery({ eagleIds });

  const freeThrowMap = useMemo(() => {
    return new Map(freeThrows.map((ft) => [ft.pbpId, ft]));
  }, [freeThrows]);

  const lastFreeThrowInSeqChanceMap = useMemo(() => {
    return new Map(
      freeThrows
        .filter((ft) => ft.seqTotal === ft.seqNum)
        .map((ft) => [ft.chanceId, ft])
    );
  }, [freeThrows]);

  if (
    fixedEffects === undefined ||
    randomEffects === undefined ||
    secondaryFixedEffects === undefined ||
    leverageFixedEffects === undefined
  )
    return null;

  const realRebounds = rebounds.filter((r) => realReboundOpp(r, freeThrowMap));

  const xRebProbabliities = {
    [details.homeTeamEagleId]: { off: 0, def: 0 },
    [details.awayTeamEagleId]: { off: 0, def: 0 },
  };

  for (const rebound of realRebounds) {
    const chance = chanceMap.get(rebound.chanceId);

    // Figure out some stuff about the shot/free throw that lead to this reb.
    const shot = shotMap.get(rebound.chanceId);
    const freeThrow = lastFreeThrowInSeqChanceMap.get(rebound.chanceId);

    let shooterId = "";
    let complexShotType = "";
    let closestDefId = "";
    let distance = 15;
    if (freeThrow) {
      shooterId = freeThrow.shooterId;
    } else if (shot) {
      shooterId = shot.shooterId;
      complexShotType = shot.complexShotType;
      closestDefId = shot.closestDefId;
      distance = shot.distance;
    }

    const chancePlayers = chancePlayerMap.get(rebound.chanceId);
    if (chance !== undefined && chancePlayers !== undefined) {
      const covariates = parsePossessionCovariates(
        details,
        chance,
        leverageFixedEffects
      );
      const offTeam = {
        playerTeamFactor: "offensive_team",
        eagleId: `${chance.offTeamId} ${chance.season}`,
        offense: true,
        rbPctRim: 0,
        rbPctShot: 0,
        shotLoc: null,
        rimLoc: null,
      };
      const defTeam = {
        playerTeamFactor: "defensive_team",
        eagleId: `${chance.defTeamId} ${chance.season}`,
        offense: false,
        rbPctRim: 0,
        rbPctShot: 0,
        shotLoc: null,
        rimLoc: null,
      };

      const players = chancePlayers.map((cp) => {
        return {
          eagleId: cp.playerId,
          playerTeamFactor: "player",
          offense: cp.offense,
          rimLoc: cp.rimLoc,
          shotLoc: cp.shotLoc,
          rbPctShot: cp.rbPctShot,
          rbPctRim: cp.rbPctRim,
        };
      });

      const playersRebPct = players.concat([offTeam, defTeam]).map((p) => {
        return {
          playerId: p.eagleId,
          teamId: p.offense ? chance.offTeamId : chance.defTeamId,
          offense: p.offense,
          pReb: xRebForPlayer(
            p,
            {
              shooterId,
              closestDefId,
              complexShotType,
              distance,
            },
            rebound,
            fixedEffects,
            randomEffects,
            covariates
          ),
        };
      });

      const sum_eREB_player_off = Math.max(
        0.00001,
        sum(
          "pReb",
          playersRebPct.filter((p) => p.offense)
        )
      );

      const sum_eREB_player_def = Math.max(
        0.00001,
        sum(
          "pReb",
          playersRebPct.filter((p) => !p.offense)
        )
      );

      const team_ORp_estimate = teamOrpEstimate(
        covariates,
        secondaryFixedEffects,
        randomEffects.filter((re) =>
          chancePlayers.some(
            (cp) =>
              cp.playerId === re.eagle &&
              cp.offense === (re.offDef === "off") &&
              chance.season.toString() === re.season
          )
        ),
        playersRebPct.filter((p) => p.offense).map((p) => p.pReb),
        playersRebPct.filter((p) => !p.offense).map((p) => p.pReb),
        rebound.fgReb
      );

      const normRebProbs = playersRebPct.map((p) => {
        return {
          playerId: p.playerId,
          teamId: p.teamId,
          offense: p.offense,
          pNormRebPct: normalizedReboundProb(
            p.pReb,
            p.offense,
            team_ORp_estimate,
            sum_eREB_player_off,
            sum_eREB_player_def
          ),
        };
      });

      for (const nrp of normRebProbs) {
        const teamxRebProb = xRebProbabliities[nrp.teamId];
        if (teamxRebProb) {
          teamxRebProb[nrp.offense ? "off" : "def"] += nrp.pNormRebPct;
        }
      }
    }
  }

  const xRebProbHome = xRebProbabliities[details.homeTeamEagleId];
  const xRebProbAway = xRebProbabliities[details.awayTeamEagleId];

  const teamRebounds: GameTeamReboundModel[] = [
    {
      gameId: details.gameId,
      Actual: realRebounds.filter(
        (r) => !r.defensive && r.teamId === details.homeTeamEagleId
      ).length,
      Expected: xRebProbHome ? xRebProbHome["off"] : 0,
      teamId: details.homeTeamId,
      offDef: "Off",
    },
    {
      gameId: details.gameId,
      Actual: realRebounds.filter(
        (r) => r.defensive && r.teamId === details.homeTeamEagleId
      ).length,
      Expected: xRebProbHome ? xRebProbHome["def"] : 0,
      teamId: details.homeTeamId,
      offDef: "Def",
    },
    {
      gameId: details.gameId,
      Actual: realRebounds.filter(
        (r) => !r.defensive && r.teamId === details.awayTeamEagleId
      ).length,
      Expected: xRebProbAway ? xRebProbAway["off"] : 0,
      teamId: details.awayTeamId,
      offDef: "Off",
    },
    {
      gameId: details.gameId,
      Actual: realRebounds.filter(
        (r) => r.defensive && r.teamId === details.awayTeamEagleId
      ).length,
      Expected: xRebProbAway ? xRebProbAway["def"] : 0,
      teamId: details.awayTeamId,
      offDef: "Def",
    },
  ];

  const home = {
    teamid: details.homeTeamId,
    teamabbreviation: details.homeTeamAbbr,
    teamCity: details.homeTeam,
  };
  const away = {
    teamid: details.awayTeamId,
    teamabbreviation: details.awayTeamAbbr,
    teamCity: details.awayTeam,
  };

  return (
    <div>
      <div>
        <TeamColorBox
          teamId={details.homeTeamId}
          oppTeamId={details.awayTeamId}
        />
        {details.homeTeam}{" "}
        <TeamColorBox
          teamId={details.awayTeamId}
          oppTeamId={details.homeTeamId}
        />
        {details.awayTeam}
        <GameTeamReboundingLuck data={teamRebounds} home={home} away={away} />
      </div>
    </div>
  );
}
