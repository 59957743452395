import React from "react";
import cx from "classnames";
import { createStyles, makeStyles } from "@material-ui/styles";
import { FaHome, FaAt } from "react-icons/fa";

import { TeamSchedule } from "../../../shared/routers/TeamRouter";
import { TeamLogo } from "../team/TeamLogo";

const useStyles = makeStyles(() =>
  createStyles({
    gameInput: {
      display: "inline-block",
      position: "relative",
      margin: 2,
      width: 50,
      height: 34,
      padding: 2,
      opacity: 0.5,
      cursor: "pointer",
      color: "rgba(0,0,0,0.4)",
      fontSize: 12,
      "&:hover": {
        opacity: 1,
      },
      "&.selected": {
        boxShadow: "0 1px 0 rgba(0,0,0,0.2)",
        opacity: 1,
      },
      "&.inSelection": {
        backgroundColor: "rgba(176, 237, 255, 0.5)",
      },
    },
    won: {
      backgroundColor: "#fff",
      color: "#189318",
      "&.selected:not(.inSelection)": {
        backgroundColor: "#E1F4D7",
        color: "rgba(0,0,0,0.4)",

        "&:hover": {
          filter: "brightness(1.07)",
        },
      },
    },
    loss: {
      backgroundColor: "#fff",
      color: "#C21D1D",
      "&.selected:not(.inSelection)": {
        backgroundColor: "#F9E1DE",
        color: "rgba(0,0,0,0.4)",

        "&:hover": {
          filter: "brightness(1.07)",
        },
      },
    },
    opponent: {
      display: "inline-block",
    },
    homeAway: {
      display: "inline-block",
      marginLeft: 3,
      verticalAlign: "top",
    },
    // Prevents clicking and dragging individual elements in the game input
    // (e.g. the logo).
    dragShield: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  })
);

export function GameInput(props: {
  game: TeamSchedule;
  teamId: number;
  selected: boolean;
  inSelection: boolean;
  onMouseDown: (evt: React.MouseEvent<HTMLDivElement>) => void;
  onMouseMove: (evt: React.MouseEvent<HTMLDivElement>) => void;
}) {
  const classes = useStyles();
  const { game, teamId, selected, inSelection, onMouseDown, onMouseMove } =
    props;
  const winLose = game.winningteamid === teamId;
  const homeAway = game.homeTeamId === teamId;
  const opponent = homeAway ? game.awayTeamId : game.homeTeamId;

  return (
    <div
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      className={cx(classes.gameInput, winLose ? classes.won : classes.loss, {
        selected: selected,
        inSelection: inSelection,
      })}
    >
      <div className={classes.opponent}>
        <TeamLogo idsId={opponent} size={30} />
      </div>
      <div className={classes.homeAway}>{homeAway ? <FaHome /> : <FaAt />}</div>
      <div className={classes.dragShield} />
    </div>
  );
}
