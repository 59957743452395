import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Button, Form } from "react-bootstrap";
import {
  useQueryParams,
  withDefault,
  QueryParamConfig,
  JsonParam,
} from "use-query-params";

import { Panel } from "../components/core/Panel";
import { Page } from "../components/core/Page";
import { trpc } from "../util/tRPC";

export function ISTPage() {
  const [queryParams, setQueryParams] = useQueryParams({
    finals: withDefault(
      JsonParam as QueryParamConfig<Record<string, boolean | undefined>>,
      {} as Record<string, boolean | undefined>
    ),
    scores: withDefault(
      JsonParam as QueryParamConfig<Record<number, number | undefined>>,
      {} as Record<number, number | undefined>
    ),
  });

  const { finals, scores } = queryParams;

  const { data: games } = trpc.inSeasonTournament.getGameScores.useQuery(
    {
      gameDate: "2023-11-28",
    },
    {
      refetchInterval: 60000, // 60 seconds in milliseconds
    }
  );

  if (games === undefined) return null;

  const bosChi = games.find((g) => g.gameId === "0022300053");
  const bknTor = games.find((g) => g.gameId === "0022300054");
  const atlCle = games.find((g) => g.gameId === "0022300055");
  const milMia = games.find((g) => g.gameId === "0022300056");
  const chaNyk = games.find((g) => g.gameId === "0022300057");

  if (
    bknTor === undefined ||
    bosChi === undefined ||
    milMia === undefined ||
    atlCle === undefined ||
    chaNyk === undefined
  )
    return null;

  const bosPts =
    scores[1610612738] !== undefined ? scores[1610612738] : bosChi.homeScore;
  const chiPts =
    scores[1610612741] !== undefined ? scores[1610612741] : bosChi.awayScore;
  const bknPts =
    scores[1610612751] !== undefined ? scores[1610612751] : bknTor.homeScore;
  const torPts =
    scores[1610612761] !== undefined ? scores[1610612761] : bknTor.awayScore;
  const atlPts =
    scores[1610612737] !== undefined ? scores[1610612737] : atlCle.awayScore;
  const clePts =
    scores[1610612739] !== undefined ? scores[1610612739] : atlCle.homeScore;
  const nykPts =
    scores[1610612752] !== undefined ? scores[1610612752] : chaNyk.homeScore;
  const chaPts =
    scores[1610612766] !== undefined ? scores[1610612766] : chaNyk.awayScore;
  const milPts =
    scores[1610612749] !== undefined ? scores[1610612749] : milMia.awayScore;
  const miaPts =
    scores[1610612748] !== undefined ? scores[1610612748] : milMia.homeScore;

  const bosPtDiff = bosPts - chiPts;
  const bknPtDiff = bknPts - torPts;
  const clePtDiff = clePts - atlPts;
  const nykPtDiff = nykPts - chaPts;
  const milPtDiff = milPts - miaPts;
  const miaPtDiff = miaPts - milPts;

  const bosChiFinal =
    finals["0022300053"] !== undefined
      ? finals["0022300053"]
      : bosChi.gameStatus === "Final";

  const bknTorFinal =
    finals["0022300054"] !== undefined
      ? finals["0022300054"]
      : bknTor.gameStatus === "Final";

  const atlCleFinal =
    finals["0022300055"] !== undefined
      ? finals["0022300055"]
      : atlCle.gameStatus === "Final";

  const milMiaFinal =
    finals["0022300056"] !== undefined
      ? finals["0022300056"]
      : milMia.gameStatus === "Final";

  const chaNykFinal =
    finals["0022300057"] !== undefined
      ? finals["0022300057"]
      : chaNyk.gameStatus === "Final";

  const ifNykBeatsCha =
    [nykPtDiff + 18, miaPtDiff + 11, 39 - miaPtDiff].sort((a, b) => b - a)[1] ||
    0;

  return (
    <Page
      header={{ text: "In Season Tournament" }}
      title="In Season Tournament"
    >
      <>
        <Panel header="Games">
          {games
            .filter((g) =>
              [
                "0022300053",
                "0022300054",
                "0022300055",
                "0022300056",
                "0022300057",
              ].includes(g.gameId)
            )
            .map((game) => (
              <div
                key={game.gameId}
                style={{
                  display: "flex",
                  gap: 16,
                  marginBottom: 8,
                  alignItems: "flex-start",
                }}
              >
                <div>
                  <Form.Check>
                    <Form.Check.Label>Completed</Form.Check.Label>
                    <Form.Check.Input
                      type="checkbox"
                      checked={
                        finals[game.gameId] !== undefined
                          ? finals[game.gameId]
                          : game.gameStatus === "Final"
                      }
                      onChange={(e) => {
                        setQueryParams({
                          finals: {
                            ...finals,
                            [game.gameId]: e.target.checked,
                          },
                        });
                      }}
                    />
                  </Form.Check>
                  <Button
                    onClick={() => {
                      setQueryParams({
                        finals: {
                          ...finals,
                          [game.gameId]: undefined,
                        },
                        scores: {
                          ...scores,
                          [game.homeTeamId]: undefined,
                          [game.awayTeamId]: undefined,
                        },
                      });
                    }}
                  >
                    Reset
                  </Button>
                </div>
                <div>
                  <Form.Label>{game.awayTeam}</Form.Label>
                  <Form.Control
                    style={{ width: 80 }}
                    type="number"
                    value={
                      scores[game.awayTeamId] !== undefined
                        ? scores[game.awayTeamId]
                        : game.awayScore
                    }
                    onChange={(e) => {
                      setQueryParams({
                        scores: {
                          ...scores,
                          [game.awayTeamId]: parseInt(e.target.value),
                        },
                      });
                    }}
                  />
                </div>
                @
                <div>
                  <Form.Label>{game.homeTeam}</Form.Label>
                  <Form.Control
                    style={{ width: 80 }}
                    type="number"
                    value={
                      scores[game.homeTeamId] !== undefined
                        ? scores[game.homeTeamId]
                        : game.homeScore
                    }
                    onChange={(e) => {
                      setQueryParams({
                        scores: {
                          ...scores,
                          [game.homeTeamId]: parseInt(e.target.value),
                        },
                      });
                    }}
                  />
                </div>
              </div>
            ))}
        </Panel>
        <Panel header="Paths to Win Group">
          <div>
            <b>Option 1</b>
            {printCondition(
              bknPtDiff > 0 && bknPtDiff < 14,
              "",
              "BKN beats TOR by fewer than 14.",
              bknTorFinal
            )}
            AND
            {printCondition(
              bosPtDiff >= 23,
              "BOS PT Diff Needs to be 23+",
              "BOS beat CHI by 23+.",
              bosChiFinal
            )}
          </div>
          <br />
          <div>
            <b>Option 2</b>
            {printCondition(
              bknPtDiff > 14,
              "",
              "BKN beats TOR by more than 14.",
              bknTorFinal
            )}
            AND
            {printCondition(
              bosPtDiff > 22 + Math.max(0, bknPtDiff - 14),
              `BOS PT Diff Needs to be ${
                22 + Math.max(0, bknPtDiff - 14) + 1
              }+`,
              `BOS beats CHI by 22 plus BKN's margin of victory - 14 (currently ${
                22 + Math.max(0, bknPtDiff - 14)
              }).`,
              bknTorFinal && bosChiFinal
            )}
          </div>
        </Panel>
        <Panel header="Paths to Win WildCard">
          <div>
            <b>Option 1</b>
            {printCondition(milPtDiff > 0, "", "MIL beats MIA.", milMiaFinal)}
            AND
            {printCondition(
              bosPtDiff >
                Math.max(clePtDiff + 6, bknPtDiff + 8, nykPtDiff + 18) ||
                (bosPtDiff > 0 &&
                  clePtDiff < 0 &&
                  bknPtDiff < 0 &&
                  nykPtDiff < 0),
              `BOS PT Diff Needs to be ${
                Math.max(clePtDiff + 6, bknPtDiff + 8, nykPtDiff + 18) + 1
              }+`,
              <span>
                We beat CHI by more than the greatest of the following amounts:
                <ul>
                  <li>
                    If CLE beats ATL, their margin of victory plus 6 (currently:{" "}
                    {clePtDiff + 6})
                  </li>
                  <li>
                    If BKN beats TOR, their margin of victory plus 8 (currently:{" "}
                    {bknPtDiff + 8})
                  </li>
                  <li>
                    If NYK beats CHA, their margin of victory plus 18.
                    (currently: {nykPtDiff + 18})
                  </li>
                </ul>
                Note that if none of those 3 teams win, and MIL and us both win,
                we are the wild card if we beat CHI.
              </span>,
              bosChiFinal && atlCleFinal && chaNykFinal && bknTorFinal
            )}
          </div>
          <br />
          <div>
            <b>Option 2</b>
            {printCondition(milPtDiff < 0, "", "MIA beats MIL.", milMiaFinal)}
            AND
            {printCondition(
              bosPtDiff >
                Math.max(
                  clePtDiff + 6,
                  bknPtDiff + 8,
                  nykPtDiff < 0 ? 39 - miaPtDiff : ifNykBeatsCha
                ),
              `BOS PT Diff Needs to be ${
                Math.max(
                  clePtDiff + 6,
                  bknPtDiff + 8,
                  nykPtDiff < 0 ? 39 - miaPtDiff : ifNykBeatsCha
                ) + 1
              }+`,
              <span>
                We beat CHI by more than the greatest of the following amounts:
                <ul>
                  <li>
                    If CLE beats ATL, their margin of victory plus 6 (currently:{" "}
                    {clePtDiff + 6})
                  </li>
                  <li>
                    If BKN beats TOR, their margin of victory plus 8 (currently:{" "}
                    {bknPtDiff + 8})
                  </li>
                  <li>
                    If NYK loses to CHA, 39 minus the amount by which MIL lost
                    to MIA. (currently: {39 - miaPtDiff})
                  </li>
                  <li>
                    If NYK beats CHA, the 2nd greatest of these amounts:
                    <ul>
                      <li>
                        If NYK beats CHA, their margin of victory plus 18
                        (currently {nykPtDiff + 18})
                      </li>
                      <li>
                        MIA&apos;s margin of victory over MIL plus 11 (currently{" "}
                        {miaPtDiff + 11})
                      </li>
                      <li>
                        39 minus the amount by which MIL lost to MIA. (currently{" "}
                        {39 - miaPtDiff})
                      </li>
                    </ul>
                  </li>
                </ul>
              </span>,
              bosChiFinal &&
                atlCleFinal &&
                chaNykFinal &&
                bknTorFinal &&
                milMiaFinal
            )}
          </div>
        </Panel>
      </>
    </Page>
  );
}

function printCondition(
  condition: boolean,
  ptsNeededMsg: string,
  text: string | React.ReactNode,
  isFinal: boolean
) {
  return (
    <div>
      <span style={{ color: condition ? "green" : "red" }}>
        {condition ? <FaCheck /> : <FaTimes />}
      </span>
      {text}
      {isFinal ? (
        <b> (Final)</b>
      ) : (
        <div>
          <b>{ptsNeededMsg}</b>
        </div>
      )}
    </div>
  );
}
