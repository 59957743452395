import React, { useMemo, useState } from "react";
import {
  Col,
  Form,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";

import { SortingState, Table, createColumnHelper } from "../core/Table";
import { TableNote } from "../core/TableNote";
import { Highlights } from "../../constants/AppConstants";
import { TeamActionBreakdown } from "../../../shared/routers/TeamRouter";
import { TeamColorBox } from "../team/TeamColorBox";
import { DivergentBar } from "../chart/DivergentBar";
import {
  decFormat,
  decFormat2,
  intFormat,
  plusMinusFormat,
} from "../../util/Format";
import { getContrastingTeamColor } from "../../util/Colors";

interface ActionBreakdownRow {
  type: string;
  n: number;
  avgN: number;
  xPPP: number | null;
  PPP: number | null;
  predPPP: number | null;
  pointDiff: number;
  colors: { primary: string; secondary: string };
}

const columnHelper = createColumnHelper<ActionBreakdownRow>();

export function GameTeamActionBreakdown(props: {
  home: {
    teamCity: string;
    teamName: string;
    teamabbreviation: string;
    teamid: number;
  };
  away: {
    teamCity: string;
    teamName: string;
    teamabbreviation: string;
    teamid: number;
  };
  gameActions: TeamActionBreakdown[];
  seasonActions: TeamActionBreakdown[];
}) {
  const { home, away, gameActions, seasonActions } = props;
  const [sortingAway, setSortingAway] = useState<SortingState>();
  const [sortingHome, setSortingHome] = useState<SortingState>();
  const [actionLevelToggle, setActionLevelToggle] = useState("poss");
  // Hueristically check if prediction data is available yet.
  const isPredDataAvail = gameActions.some(
    (d) =>
      d.pnr_total_pred_PPP !== null ||
      d.transition_pred_PPP !== null ||
      d.iso_pred_PPP !== null
  );
  const [comparisonToggle, setComparisonToggle] = useState(
    isPredDataAvail ? "pred" : "away"
  );

  const homeTeam = `${home.teamCity} ${home.teamName}`;
  const homeTeamAbbreviation = home.teamabbreviation;
  const homeTeamId = home.teamid;

  const awayTeam = `${away.teamCity} ${away.teamName}`;
  const awayTeamAbbreviation = away.teamabbreviation;
  const awayTeamId = away.teamid;

  const awayColors = getContrastingTeamColor(awayTeamId, homeTeamId);
  const homeColors = getContrastingTeamColor(homeTeamId, awayTeamId);

  const homeRows = prepareRows(
    actionLevelToggle,
    comparisonToggle,
    true,
    homeTeamId,
    awayTeamId,
    homeColors,
    awayColors,
    gameActions,
    seasonActions
  );
  const awayRows = prepareRows(
    actionLevelToggle,
    comparisonToggle,
    false,
    awayTeamId,
    homeTeamId,
    awayColors,
    homeColors,
    gameActions,
    seasonActions
  );

  const columns = useMemo(() => {
    let g = 0;

    let diffHeader = "xPTS vs. Predicted Pts";
    if (comparisonToggle === "away") {
      diffHeader = `xPTS vs ${awayTeamAbbreviation} Avg xPPP`;
    } else if (comparisonToggle === "home") {
      diffHeader = `xPTS vs ${homeTeamAbbreviation} Avg xPPP`;
    }

    return [
      columnHelper.accessor("type", {
        header: "Type",
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("n", {
        header: "Freq",
        cell: (info) => intFormat(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: [0, 40],
        },
      }),
      columnHelper.accessor("avgN", {
        header: "Avg Freq",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: [0, 40],
        },
      }),
      columnHelper.accessor("PPP", {
        header: "PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: [0.8, 1.2],
        },
      }),
      columnHelper.accessor("xPPP", {
        header: "xPPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: [0.8, 1.2],
        },
      }),
      columnHelper.accessor("predPPP", {
        header: "Pred. PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: [0.8, 1.2],
        },
      }),
      columnHelper.accessor((row) => row.pointDiff, {
        id: "bar",
        header: diffHeader,
        cell: (info) => (
          <DivergentBar
            value={info.getValue()}
            domain={[-7, 7]}
            height={14}
            format={plusMinusFormat}
            colors={info.row.original.colors}
          />
        ),
        meta: { group: g++ },
      }),
    ];
  }, [awayTeamAbbreviation, comparisonToggle, homeTeamAbbreviation]);

  let teamAbbreviation = "";
  let otherTeamAbbreviation = "";
  if (comparisonToggle === "away") {
    teamAbbreviation = awayTeamAbbreviation;
    otherTeamAbbreviation = homeTeamAbbreviation;
  } else if (comparisonToggle === "home") {
    teamAbbreviation = homeTeamAbbreviation;
    otherTeamAbbreviation = awayTeamAbbreviation;
  }

  return (
    <div>
      <Row>
        <Col>
          <Form.Label>Compare Against</Form.Label>
          <div>
            <ToggleButtonGroup
              name="comparison-toggle"
              type="radio"
              value={comparisonToggle}
              onChange={setComparisonToggle}
              style={{ marginRight: 8 }}
            >
              <ToggleButton
                id="comparison-toggle-pred"
                value={"pred"}
                disabled={!isPredDataAvail}
              >
                Modeled Prediction
              </ToggleButton>
              <ToggleButton id="comparison-toggle-off" value={"away"}>
                {`${awayTeamAbbreviation} Season Averages`}
              </ToggleButton>
              <ToggleButton id="comparison-toggle-def" value={"home"}>
                {`${homeTeamAbbreviation} Season Averages`}
              </ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup
              name="action-level-toggle"
              type="radio"
              value={actionLevelToggle}
              onChange={setActionLevelToggle}
            >
              <ToggleButton id="action-level-toggle-poss" value={"poss"}>
                Possession Level
              </ToggleButton>
              <ToggleButton id="action-level-toggle-action" value={"action"}>
                Action Level
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <TeamColorBox teamId={awayTeamId} oppTeamId={homeTeamId} /> {awayTeam}
          <Table
            data={awayRows}
            columns={columns}
            sorting={sortingAway}
            setSorting={setSortingAway}
            expandColumnId="type"
            autoWidth={true}
            showColorOnHover={true}
          />
        </Col>
        <Col md={6}>
          <TeamColorBox teamId={homeTeamId} oppTeamId={awayTeamId} /> {homeTeam}
          <Table
            data={homeRows}
            columns={columns}
            sorting={sortingHome}
            setSorting={setSortingHome}
            expandColumnId="type"
            autoWidth={true}
            showColorOnHover={true}
          />
        </Col>
      </Row>
      <TableNote
        note={
          comparisonToggle === "pred"
            ? `xPTS vs. Predicted: how much better or worse did the team actually 
            execute compared to what we would have predicted given who was on the 
            court and involved in the actions?`
            : `xPTS vs. ${teamAbbreviation} Avg xPPP is comparing how effectively 
            ${teamAbbreviation} executed each action compared to their offensive 
            season xPPP and how ${otherTeamAbbreviation} executed each action 
            compared to ${teamAbbreviation}'s defensive season xPPP.`
        }
      />
    </div>
  );
}

function prepareRows(
  actionLevelToggle: string,
  comparisonToggle: string,
  isHomeTeam: boolean,
  teamId: number,
  oppTeamId: number,
  teamColors: { primary: string; secondary: string },
  oppColors: { primary: string; secondary: string },
  gameData: TeamActionBreakdown[],
  seasonData: TeamActionBreakdown[]
): (ActionBreakdownRow & { subRows?: ActionBreakdownRow[] })[] {
  const isPossLevel = actionLevelToggle === "poss";

  const teamDataOff = gameData.find(
    (gd) => gd.teamId === teamId && gd.offDef === "OFF"
  );

  const seasonTeamOff = seasonData.find(
    (sd) => sd.teamId === teamId && sd.offDef === "OFF"
  );
  const seasonOppDef = seasonData.find(
    (sd) => sd.teamId === oppTeamId && sd.offDef === "DEF"
  );

  const homeTeamComp = isHomeTeam ? seasonTeamOff : seasonOppDef;
  const awayTeamComp = isHomeTeam ? seasonOppDef : seasonTeamOff;

  if (!teamDataOff || !seasonTeamOff || !homeTeamComp || !awayTeamComp)
    return [];

  const multiplier =
    seasonTeamOff.poss_n === 0 ? 0 : teamDataOff.poss_n / seasonTeamOff.poss_n;

  const comparison = (
    xPPPValue: number | null,
    n: number,
    predValue: number | null,
    awayValue: number | null,
    homeValue: number | null
  ) => {
    let compValue = predValue;
    if (comparisonToggle === "home") {
      compValue = homeValue || 0;
    } else if (comparisonToggle === "away") {
      compValue = awayValue || 0;
    }
    if (xPPPValue === null || compValue === null) return 0;

    return n * (xPPPValue - compValue);
  };

  const pnrSidexPPP = isPossLevel
    ? teamDataOff.pnr_side_xPPP
    : teamDataOff.pnr_side_xAOI;
  const pnrSidePPP = isPossLevel
    ? teamDataOff.pnr_side_PPP
    : teamDataOff.pnr_side_AOI;
  const pnrSidePredPPP = isPossLevel
    ? teamDataOff.pnr_side_pred_PPP
    : teamDataOff.pnr_side_pred_AOI;
  const pnrSidePointDiff = comparison(
    pnrSidexPPP,
    teamDataOff.pnr_side_n,
    pnrSidePredPPP,
    isPossLevel ? awayTeamComp.pnr_side_xPPP : awayTeamComp.pnr_side_xAOI,
    isPossLevel ? homeTeamComp.pnr_side_xPPP : homeTeamComp.pnr_side_xAOI
  );

  const pnrMiddlexPPP = isPossLevel
    ? teamDataOff.pnr_middle_xPPP
    : teamDataOff.pnr_middle_xAOI;
  const pnrMiddlePPP = isPossLevel
    ? teamDataOff.pnr_middle_PPP
    : teamDataOff.pnr_middle_AOI;
  const pnrMiddlePredPPP = isPossLevel
    ? teamDataOff.pnr_middle_pred_PPP
    : teamDataOff.pnr_middle_pred_AOI;
  const pnrMiddlePointDiff = comparison(
    pnrMiddlexPPP,
    teamDataOff.pnr_middle_n,
    pnrMiddlePredPPP,
    isPossLevel ? awayTeamComp.pnr_middle_xPPP : awayTeamComp.pnr_middle_xAOI,
    isPossLevel ? homeTeamComp.pnr_middle_xPPP : homeTeamComp.pnr_middle_xAOI
  );

  const pnrAnglexPPP = isPossLevel
    ? teamDataOff.pnr_angle_xPPP
    : teamDataOff.pnr_angle_xAOI;
  const pnrAnglePPP = isPossLevel
    ? teamDataOff.pnr_angle_PPP
    : teamDataOff.pnr_angle_AOI;
  const pnrAnglePredPPP = isPossLevel
    ? teamDataOff.pnr_angle_pred_PPP
    : teamDataOff.pnr_angle_pred_AOI;
  const pnrAnglePointDiff = comparison(
    pnrAnglexPPP,
    teamDataOff.pnr_angle_n,
    pnrAnglePredPPP,
    isPossLevel ? awayTeamComp.pnr_angle_xPPP : awayTeamComp.pnr_angle_xAOI,
    isPossLevel ? homeTeamComp.pnr_angle_xPPP : homeTeamComp.pnr_angle_xAOI
  );

  const pnrDoublexPPP = isPossLevel
    ? teamDataOff.pnr_double_xPPP
    : teamDataOff.pnr_double_xAOI;
  const pnrDoublePPP = isPossLevel
    ? teamDataOff.pnr_double_PPP
    : teamDataOff.pnr_double_AOI;
  const pnrDoublePredPPP = isPossLevel
    ? teamDataOff.pnr_double_pred_PPP
    : teamDataOff.pnr_double_pred_AOI;
  const pnrDoublePointDiff = comparison(
    pnrDoublexPPP,
    teamDataOff.pnr_double_n,
    pnrDoublePredPPP,
    isPossLevel ? awayTeamComp.pnr_double_xPPP : awayTeamComp.pnr_double_xAOI,
    isPossLevel ? homeTeamComp.pnr_double_xPPP : homeTeamComp.pnr_double_xAOI
  );

  const pnrLowAnglexPPP = isPossLevel
    ? teamDataOff.pnr_low_angle_xPPP
    : teamDataOff.pnr_low_angle_xAOI;
  const pnrLowAnglePPP = isPossLevel
    ? teamDataOff.pnr_low_angle_PPP
    : teamDataOff.pnr_low_angle_AOI;
  const pnrLowAnglePredPPP = isPossLevel
    ? teamDataOff.pnr_low_angle_pred_PPP
    : teamDataOff.pnr_low_angle_pred_AOI;
  const pnrLowAnglePointDiff = comparison(
    pnrLowAnglexPPP,
    teamDataOff.pnr_low_angle_n,
    pnrLowAnglePredPPP,
    isPossLevel
      ? awayTeamComp.pnr_low_angle_xPPP
      : awayTeamComp.pnr_low_angle_xAOI,
    isPossLevel
      ? homeTeamComp.pnr_low_angle_xPPP
      : homeTeamComp.pnr_low_angle_xAOI
  );

  const pnrOtherxPPP = isPossLevel
    ? teamDataOff.pnr_other_xPPP
    : teamDataOff.pnr_other_xAOI;
  const pnrOtherPPP = isPossLevel
    ? teamDataOff.pnr_other_PPP
    : teamDataOff.pnr_other_AOI;
  const pnrOtherPredPPP = isPossLevel
    ? teamDataOff.pnr_other_pred_PPP
    : teamDataOff.pnr_other_pred_AOI;
  const pnrOtherPointDiff = comparison(
    pnrOtherxPPP,
    teamDataOff.pnr_other_n,
    pnrOtherPredPPP,
    isPossLevel ? awayTeamComp.pnr_other_xPPP : awayTeamComp.pnr_other_xAOI,
    isPossLevel ? homeTeamComp.pnr_other_xPPP : homeTeamComp.pnr_other_xAOI
  );

  const pnrDhoxPPP = isPossLevel
    ? teamDataOff.pnr_dho_xPPP
    : teamDataOff.pnr_dho_xAOI;
  const pnrDhoPPP = isPossLevel
    ? teamDataOff.pnr_dho_PPP
    : teamDataOff.pnr_dho_AOI;
  const pnrDhoPredPPP = isPossLevel
    ? teamDataOff.pnr_dho_pred_PPP
    : teamDataOff.pnr_dho_pred_AOI;
  const pnrDhoPointDiff = comparison(
    pnrDhoxPPP,
    teamDataOff.pnr_dho_n,
    pnrDhoPredPPP,
    isPossLevel ? awayTeamComp.pnr_dho_xPPP : awayTeamComp.pnr_dho_xAOI,
    isPossLevel ? homeTeamComp.pnr_dho_xPPP : homeTeamComp.pnr_dho_xAOI
  );

  const pnrSubRows = [
    {
      type: "Side/Wing",
      xPPP: pnrSidexPPP,
      PPP: pnrSidePPP,
      predPPP: pnrSidePredPPP,
      n: teamDataOff.pnr_side_n,
      avgN: multiplier * seasonTeamOff.pnr_side_n,
      pointDiff: pnrSidePointDiff,
      colors: pnrSidePointDiff >= 0 ? teamColors : oppColors,
    },
    {
      type: "Middle",
      xPPP: pnrMiddlexPPP,
      PPP: pnrMiddlePPP,
      predPPP: pnrMiddlePredPPP,
      n: teamDataOff.pnr_middle_n,
      avgN: multiplier * seasonTeamOff.pnr_middle_n,
      pointDiff: pnrMiddlePointDiff,
      colors: pnrMiddlePointDiff >= 0 ? teamColors : oppColors,
    },
    {
      type: "Angle/Step-up",
      xPPP: pnrAnglexPPP,
      PPP: pnrAnglePPP,
      predPPP: pnrAnglePredPPP,
      n: teamDataOff.pnr_angle_n,
      avgN: multiplier * seasonTeamOff.pnr_angle_n,
      pointDiff: pnrAnglePointDiff,
      colors: pnrAnglePointDiff >= 0 ? teamColors : oppColors,
    },
    {
      type: "Double",
      xPPP: pnrDoublexPPP,
      PPP: pnrDoublePPP,
      predPPP: pnrDoublePredPPP,
      n: teamDataOff.pnr_double_n,
      avgN: multiplier * seasonTeamOff.pnr_double_n,
      pointDiff: pnrDoublePointDiff,
      colors: pnrDoublePointDiff >= 0 ? teamColors : oppColors,
    },
    {
      type: "Low Angle",
      xPPP: pnrLowAnglexPPP,
      PPP: pnrLowAnglePPP,
      predPPP: pnrLowAnglePredPPP,
      n: teamDataOff.pnr_low_angle_n,
      avgN: multiplier * seasonTeamOff.pnr_low_angle_n,
      pointDiff: pnrLowAnglePointDiff,
      colors: pnrLowAnglePointDiff >= 0 ? teamColors : oppColors,
    },
    {
      type: "PNR Other",
      xPPP: pnrOtherxPPP,
      PPP: pnrOtherPPP,
      predPPP: pnrOtherPredPPP,
      n: teamDataOff.pnr_other_n,
      avgN: multiplier * seasonTeamOff.pnr_other_n,
      pointDiff: pnrOtherPointDiff,
      colors: pnrOtherPointDiff >= 0 ? teamColors : oppColors,
    },
    {
      type: "DHO",
      xPPP: pnrDhoxPPP,
      PPP: pnrDhoPPP,
      predPPP: pnrDhoPredPPP,
      n: teamDataOff.pnr_dho_n,
      avgN: multiplier * seasonTeamOff.pnr_dho_n,
      pointDiff: pnrDhoPointDiff,
      colors: pnrDhoPointDiff >= 0 ? teamColors : oppColors,
    },
  ];

  const pnrTotalxPPP = isPossLevel
    ? teamDataOff.pnr_total_xPPP
    : teamDataOff.pnr_total_xAOI;
  const pnrTotalPPP = isPossLevel
    ? teamDataOff.pnr_total_PPP
    : teamDataOff.pnr_total_AOI;
  const pnrTotalPredPPP = isPossLevel
    ? teamDataOff.pnr_total_pred_PPP
    : teamDataOff.pnr_total_pred_AOI;
  const pnrTotalPointDiff = comparison(
    pnrTotalxPPP,
    teamDataOff.pnr_total_n,
    pnrTotalPredPPP,
    isPossLevel ? awayTeamComp.pnr_total_xPPP : awayTeamComp.pnr_total_xAOI,
    isPossLevel ? homeTeamComp.pnr_total_xPPP : homeTeamComp.pnr_total_xAOI
  );

  const offBallxPPP = isPossLevel
    ? teamDataOff.off_ball_xPPP
    : teamDataOff.off_ball_xAOI;
  const offBallPPP = isPossLevel
    ? teamDataOff.off_ball_PPP
    : teamDataOff.off_ball_AOI;
  const offBallPredPPP = isPossLevel
    ? teamDataOff.off_ball_pred_PPP
    : teamDataOff.off_ball_pred_AOI;
  const offBallPointDiff = comparison(
    offBallxPPP,
    teamDataOff.off_ball_n,
    offBallPredPPP,
    isPossLevel ? awayTeamComp.off_ball_xPPP : awayTeamComp.off_ball_xAOI,
    isPossLevel ? homeTeamComp.off_ball_xPPP : homeTeamComp.off_ball_xAOI
  );

  const isoxPPP = isPossLevel ? teamDataOff.iso_xPPP : teamDataOff.iso_xAOI;
  const isoPPP = isPossLevel ? teamDataOff.iso_PPP : teamDataOff.iso_AOI;
  const isoPredPPP = isPossLevel
    ? teamDataOff.iso_pred_PPP
    : teamDataOff.iso_pred_AOI;
  const isoPointDiff = comparison(
    isoxPPP,
    teamDataOff.iso_n,
    isoPredPPP,
    isPossLevel ? awayTeamComp.iso_xPPP : awayTeamComp.iso_xAOI,
    isPossLevel ? homeTeamComp.iso_xPPP : homeTeamComp.iso_xAOI
  );

  const postxPPP = isPossLevel ? teamDataOff.post_xPPP : teamDataOff.post_xAOI;
  const postPPP = isPossLevel ? teamDataOff.post_PPP : teamDataOff.post_AOI;
  const postPredPPP = isPossLevel
    ? teamDataOff.post_pred_PPP
    : teamDataOff.post_pred_AOI;
  const postPointDiff = comparison(
    postxPPP,
    teamDataOff.post_n,
    isoPredPPP,
    isPossLevel ? awayTeamComp.post_xPPP : awayTeamComp.post_xAOI,
    isPossLevel ? homeTeamComp.post_xPPP : homeTeamComp.post_xAOI
  );

  const transitionxPPP = isPossLevel
    ? teamDataOff.transition_xPPP
    : teamDataOff.transition_xAOI;
  const transitionPPP = isPossLevel
    ? teamDataOff.transition_PPP
    : teamDataOff.transition_AOI;
  const transitionPredPPP = isPossLevel
    ? teamDataOff.transition_pred_PPP
    : teamDataOff.transition_pred_AOI;
  const transitionPointDiff = comparison(
    transitionxPPP,
    teamDataOff.transition_n,
    transitionPredPPP,
    isPossLevel ? awayTeamComp.transition_xPPP : awayTeamComp.transition_xAOI,
    isPossLevel ? homeTeamComp.transition_xPPP : homeTeamComp.transition_xAOI
  );

  const catchAndGoxPPP = isPossLevel
    ? teamDataOff.catch_and_go_drive_xPPP
    : teamDataOff.catch_and_go_drive_xAOI;
  const catchAndGoPPP = isPossLevel
    ? teamDataOff.catch_and_go_drive_PPP
    : teamDataOff.catch_and_go_drive_AOI;
  const catchAndGoPredPPP = isPossLevel
    ? teamDataOff.catch_and_go_drive_pred_PPP
    : teamDataOff.catch_and_go_drive_pred_AOI;
  const catchAndGoPointDiff = comparison(
    catchAndGoxPPP,
    teamDataOff.catch_and_go_drive_n,
    catchAndGoPredPPP,
    isPossLevel
      ? awayTeamComp.catch_and_go_drive_xPPP
      : awayTeamComp.catch_and_go_drive_xAOI,
    isPossLevel
      ? homeTeamComp.catch_and_go_drive_xPPP
      : homeTeamComp.catch_and_go_drive_xAOI
  );

  const otherxPPP = isPossLevel
    ? teamDataOff.other_xPPP
    : teamDataOff.other_xAOI;
  const otherPPP = isPossLevel ? teamDataOff.other_PPP : teamDataOff.other_AOI;
  const otherPredPPP = isPossLevel
    ? teamDataOff.other_pred_PPP
    : teamDataOff.other_pred_AOI;
  const otherPointDiff = comparison(
    otherxPPP,
    teamDataOff.other_n,
    otherPredPPP,
    isPossLevel ? awayTeamComp.other_xPPP : awayTeamComp.other_xAOI,
    isPossLevel ? homeTeamComp.other_xPPP : homeTeamComp.other_xAOI
  );

  return [
    {
      type: "PNR Total",
      xPPP: pnrTotalxPPP,
      PPP: pnrTotalPPP,
      predPPP: pnrTotalPredPPP,
      n: teamDataOff.pnr_total_n,
      avgN: multiplier * seasonTeamOff.pnr_total_n,
      pointDiff: pnrTotalPointDiff,
      colors: pnrTotalPointDiff >= 0 ? teamColors : oppColors,
      subRows: pnrSubRows,
    },
    {
      type: "Off Ball",
      xPPP: offBallxPPP,
      PPP: offBallPPP,
      predPPP: offBallPredPPP,
      n: teamDataOff.off_ball_n,
      avgN: multiplier * seasonTeamOff.off_ball_n,
      pointDiff: offBallPointDiff,
      colors: offBallPointDiff >= 0 ? teamColors : oppColors,
    },
    {
      type: "Isolation",
      xPPP: isoxPPP,
      PPP: isoPPP,
      predPPP: isoPredPPP,
      n: teamDataOff.iso_n,
      avgN: multiplier * seasonTeamOff.iso_n,
      pointDiff: isoPointDiff,
      colors: isoPointDiff >= 0 ? teamColors : oppColors,
    },
    {
      type: "Post",
      xPPP: postxPPP,
      PPP: postPPP,
      predPPP: postPredPPP,
      n: teamDataOff.post_n,
      avgN: multiplier * seasonTeamOff.post_n,
      pointDiff: postPointDiff,
      colors: postPointDiff >= 0 ? teamColors : oppColors,
    },
    {
      type: "Catch-and-Go Drive",
      xPPP: catchAndGoxPPP,
      PPP: catchAndGoPPP,
      predPPP: catchAndGoPredPPP,
      n: teamDataOff.catch_and_go_drive_n,
      avgN: multiplier * seasonTeamOff.catch_and_go_drive_n,
      pointDiff: catchAndGoPointDiff,
      colors: catchAndGoPointDiff >= 0 ? teamColors : oppColors,
    },
    {
      type: "Transition",
      xPPP: transitionxPPP,
      PPP: transitionPPP,
      predPPP: transitionPredPPP,
      n: teamDataOff.transition_n,
      avgN: multiplier * seasonTeamOff.transition_n,
      pointDiff: transitionPointDiff,

      colors: transitionPointDiff >= 0 ? teamColors : oppColors,
    },
    {
      type: "Other",
      xPPP: otherxPPP,
      PPP: otherPPP,
      predPPP: otherPredPPP,
      n: teamDataOff.other_n,
      avgN: multiplier * seasonTeamOff.other_n,
      pointDiff: otherPointDiff,
      colors: otherPointDiff >= 0 ? teamColors : oppColors,
    },
  ];
}
