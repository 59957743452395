import React, { ReactNode } from "react";

import { PlayerBio } from "../../../shared/routers/PlayerRouter";

export function PlayerStatusMessage(props: { bio: PlayerBio }) {
  const bio = props.bio;

  const team = bio.teamshortname;

  const messageMap: Record<string, ReactNode> = {
    CDL: (
      <span>
        Draft rights owned by <em>{team}</em>
      </span>
    ),
    RFA: (
      <span>
        Restricted free agent for <em>{team}</em>
      </span>
    ),
    WAV: "Currently on waivers",
    VRET: "Voluntarily retired",
  };

  if (bio.playerStatus === null) return null;

  const message = messageMap[bio.playerStatus];
  if (!message) {
    return null;
  }

  return (
    <p
      style={{
        marginBottom: 4,
      }}
    >
      {message}
    </p>
  );
}
