import React from "react";
import moment from "moment";
import { ParentSize } from "@visx/responsive";

import {
  DxRankingOverTime,
  TypicalDraftModelRankingOverTime,
} from "../../../shared/routers/DraftRouter";
import { Line, LineChart } from "../chart/LineChart";
import { lineChartColors } from "../../constants/ColorConstants";
import { ordinalFormat } from "../../util/Format";
import AppContext from "../../../shared/AppContext";
import { PlayerBio } from "../../../shared/routers/PlayerRouter";

export function DxRankingOverTimeChart(props: {
  data: DxRankingOverTime[] | undefined;
  draftModel: TypicalDraftModelRankingOverTime[] | undefined;
  bio: PlayerBio;
}) {
  const { data, draftModel, bio } = props;

  if (!data || !draftModel) return null;

  const { draftPick, draftYear } = bio;

  // Get the first "season" we have DX data for - we will use this later on
  // to determine which draft model data to show on the graph.
  const seasonForFirstDXRank =
    data[0] && data[0].rankDate && data[0].rankDate.split("-")[0];

  const line: Line = {
    color: lineChartColors.red,
    segments: [
      data.map((d) => ({
        x: moment(d.rankDate).valueOf(),
        y: d.rank,
      })),
    ],
    label: "DX Rank",
  };

  const draftModelLine: Line = {
    color: lineChartColors.blue,
    segments: [
      draftModel
        // Show draft model data for at most one year before the DX data.
        .filter(
          (d) =>
            (!seasonForFirstDXRank ||
              d.season >= parseInt(seasonForFirstDXRank) - 1) &&
            (!draftYear || d.season <= draftYear)
        )
        .sort((a, b) => a.season - b.season)
        .map((d) => ({
          x: moment(AppContext.draftDates[d.season.toString()]).valueOf(),
          y: d.typicalRank > 100 ? 101 : d.typicalRank,
        })),
    ],
    label: "Draft Model (Typical Rank)",
  };

  const lines = [line, draftModelLine];

  if (draftPick) {
    lines.push({
      color: lineChartColors.yellow,
      label: "Actual Pick",
      segments: draftModelLine.segments.map((d) =>
        d.map((d) => ({ x: d.x, y: draftPick }))
      ),
    });
  }

  return (
    <div style={{ position: "relative", maxWidth: 560 }}>
      <ParentSize>
        {({ width }) => (
          <LineChart
            margin={{ right: 36 }}
            lines={lines}
            referenceLines={[
              { value: 14, label: "Lottery" },
              { value: 30, label: "1st" },
              { value: 60, label: "2nd" },
            ]}
            height={width / 2}
            width={width}
            numXTicks={5}
            xTickFormat={(d) => moment(d as number).format("MMM 'YY")}
            yDirection="asc"
            yTicks={[1, 20, 40, 60, 80, 100]}
            yDomain={[0, 105]}
            showLegend={true}
            tooltip={(tooltipData) => {
              const datas = tooltipData.data;
              if (datas.length === 0) return null;
              return (
                <div>
                  <div>
                    <b>{moment(tooltipData.xVal).format("YYYY-MM-DD")}</b>
                  </div>
                  <div>
                    {datas.map((d, i) => (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          gap: 8,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            background: d.color,
                            width: 10,
                            height: 10,
                          }}
                        ></div>
                        <div>
                          {d.y === 101 ? "Outside Top 100" : ordinalFormat(d.y)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            }}
          />
        )}
      </ParentSize>
    </div>
  );
}
