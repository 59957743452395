import React, { ReactNode, useState } from "react";
import { Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { urlFromResult, getSecondaryText } from "../util/Search";
import { SearchResult } from "../../shared/routers/SearchRouter";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Spinner } from "../components/core/Spinner";
import { trpc } from "../util/tRPC";

const pageShortCuts = new Set([
  "standings",
  "teams",
  "players",
  "games",
  "league",
  "video",
  "stats",
  "pnr",
  "possessions",
  "shots",
  "impact",
  "mapping",
]);

export function SearchPage() {
  const { query } = useParams();
  const [navToResult, setNavToResult] = useState<SearchResult>();
  const navigate = useNavigate();

  const lowerCaseQuery = query ? query.toLowerCase() : "";

  const { data } = trpc.search.getSearchResults.useQuery(
    {
      searchQuery: query,
    },
    {
      onSuccess: (data) => {
        const exactMatches = data.filter(
          (r) => r.label && r.label.toLowerCase() === lowerCaseQuery
        );
        if (data.length === 1) {
          // If we have a single match navigate there.
          setNavToResult(data[0]);
        } else if (exactMatches.length === 1) {
          // If we have a single exact match navigate there.
          setNavToResult(exactMatches[0]);
        }
      },
    }
  );

  const results =
    data === undefined
      ? undefined
      : data.sort((a, b) => {
          // First check is use result score (make sure NBA is above other stuff).
          if (a.score !== b.score) {
            return b.score - a.score;
          }
          // Then if a search is an exact match use that.
          else if (a.label && a.label.toLowerCase() === lowerCaseQuery) {
            return -1;
          }
          // Otherwise alphabetically sort.
          else if (b.label && b.label.toLowerCase() === lowerCaseQuery) {
            return 1;
          }
          return b.label > a.label ? 1 : 0;
        });

  if (query && pageShortCuts.has(query)) {
    navigate(`/${query}`);
  }

  let content: ReactNode = "";

  if (!results) {
    content = <Spinner />;
  } else if (results.length === 0) {
    content = "No results matched your query.";
  } else if (navToResult) {
    return <Navigate to={urlFromResult(navToResult)} replace={false} />;
  } else {
    const players = results.filter((r) => r.type === "player");
    const teams = results.filter((r) => r.type === "team");

    const combined = [players, teams];
    content = (
      <Row>
        {combined.map((res, i) => {
          return (
            <Col key={i}>
              <h3>{i === 0 ? "Players" : "Teams"}</h3>
              {res.map((r, j) => {
                return (
                  <div key={r.Id} style={{ marginBottom: 8 }}>
                    <b>
                      {j + 1}
                      {". "}
                      <Link to={urlFromResult(r)}>{r.label}</Link>
                    </b>
                    <div>{getSecondaryText(r) || "-"}</div>
                  </div>
                );
              })}
            </Col>
          );
        })}
      </Row>
    );
  }

  return (
    <Page title="Search" header={{ text: "Search" }}>
      <Panel header={"Search Results"}>{content}</Panel>
    </Page>
  );
}
