import React from "react";

import { createStyles, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    pageHead: {
      background: "#272831",
      color: "#fff",
      paddingLeft: 30,
      paddingRight: 30,
      boxShadow: "none",
      marginBottom: 20,
      padding: "10px 0",
    },
  })
);

export function PageHeader(props: { component?: JSX.Element; text?: string }) {
  const classes = useStyles();

  return (
    <header className={classes.pageHead}>
      {props.component ? props.component : <h1>{props.text}</h1>}
    </header>
  );
}
