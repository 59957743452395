import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Form, SplitButton, Dropdown } from "react-bootstrap";
import moment from "moment";

import { NbaDates } from "../../../shared/NbaDates";
import { dateToString, seasonString } from "../../util/Format";
import AppContext from "../../../shared/AppContext";

const useStyles = makeStyles(() =>
  createStyles({
    controlContainer: {
      display: "flex",
      alignItems: "center",
      gap: 4,
      marginBottom: "0.8em",
      flexWrap: "wrap",
    },
    datePicker: {
      width: "auto",
      display: "inline-block",
    },
    prePostChecks: {
      padding: 0,
      margin: 0,
      listStyleType: "none",
    },
    checkboxListItem: {
      display: "inline-block",
      marginRight: 5,
      marginLeft: 5,
    },
    checkbox: {
      display: "inline-block",
      paddingRight: 4,
    },
    summary: {
      fontSize: 22,
      fontWeight: 200,
      marginBottom: "0.8em",
    },
  })
);

export function NbaSeasonDateRangePicker(props: {
  start: string;
  end: string;
  dataFilterChange: (
    dates: { start: string; end: string },
    pre: boolean,
    post: boolean
  ) => void;
  groups: Record<string, string>;
  preseason: boolean;
  postseason: boolean;
  selectedGroups: string[];
  selectedGroupChange: (selection: string) => void;
}) {
  const classes = useStyles();

  const {
    start,
    end,
    dataFilterChange,
    groups,
    preseason,
    postseason,
    selectedGroups,
    selectedGroupChange,
  } = props;

  const seasonKeys = Object.keys(NbaDates).reverse();

  const setDatesToRegularSeason = (season: string | null) => {
    if (!season) return;
    const dates = NbaDates[season];
    if (!dates) return;
    dataFilterChange(
      {
        start: moment(dates.season.start).format("YYYY-MM-DD"),
        end: moment(dates.season.end).format("YYYY-MM-DD"),
      },
      false,
      false
    );
  };

  const setDatesToPostAllStar = (season: string | null) => {
    if (!season) return;
    const dates = NbaDates[season];
    if (!dates) return;
    dataFilterChange(
      {
        start: moment(dates.allstar.end).format("YYYY-MM-DD"),
        end: moment(dates.season.end).format("YYYY-MM-DD"),
      },
      false,
      false
    );
  };

  const setDatesToPostseason = (season: string | null) => {
    if (!season) return;
    const dates = NbaDates[season];
    if (!dates) return;
    dataFilterChange(
      {
        start: moment(dates.postseason.start).format("YYYY-MM-DD"),
        end: moment(dates.postseason.end).format("YYYY-MM-DD"),
      },
      false,
      true
    );
  };

  return (
    <div>
      <div className={classes.summary}>
        {summaryString(start, end, preseason, postseason)}
      </div>
      <div className={classes.controlContainer}>
        <Form.Control
          className={classes.datePicker}
          type="date"
          value={start}
          onChange={(e) =>
            dataFilterChange(
              { start: e.target.value, end },
              preseason,
              postseason
            )
          }
        ></Form.Control>
        {" to "}
        <Form.Control
          className={classes.datePicker}
          type="date"
          value={end}
          onChange={(e) =>
            dataFilterChange(
              { start, end: e.target.value },
              preseason,
              postseason
            )
          }
        ></Form.Control>
        <SplitButton
          title="Regular Season"
          onClick={() => setDatesToRegularSeason(AppContext.currentSeason)}
          onSelect={setDatesToRegularSeason}
          align="end"
        >
          {seasonKeys.map((season, i) => {
            const dates = NbaDates[season];
            if (!dates) return null;
            return dates.season ? (
              <Dropdown.Item key={i} eventKey={season}>
                {seasonString(season) + " Regular Season"}
              </Dropdown.Item>
            ) : undefined;
          })}
        </SplitButton>
        <SplitButton
          title="Post All-Star"
          onClick={() => setDatesToPostAllStar(AppContext.currentSeason)}
          onSelect={setDatesToPostAllStar}
          align="end"
        >
          {seasonKeys.map((season, i) => {
            const dates = NbaDates[season];
            if (!dates) return null;
            return dates.season ? (
              <Dropdown.Item key={i} eventKey={season}>
                {seasonString(season) + " Post All-Star"}
              </Dropdown.Item>
            ) : undefined;
          })}
        </SplitButton>
        <SplitButton
          title="Postseason"
          onClick={() => setDatesToPostseason(AppContext.currentSeason)}
          onSelect={setDatesToPostseason}
          align="end"
        >
          {seasonKeys.map((season, i) => {
            const dates = NbaDates[season];
            if (!dates) return null;
            return dates.season ? (
              <Dropdown.Item key={i} eventKey={season}>
                {seasonString(season) + " Postseason"}
              </Dropdown.Item>
            ) : undefined;
          })}
        </SplitButton>
        <ul className={classes.prePostChecks}>
          <li>
            <Form.Check
              className={classes.checkbox}
              checked={preseason}
              onChange={() =>
                dataFilterChange({ start, end }, !preseason, postseason)
              }
            />
            Preseason
          </li>
          <li>
            <Form.Check
              className={classes.checkbox}
              checked={postseason}
              onChange={() =>
                dataFilterChange({ start, end }, preseason, !postseason)
              }
            />
            PostSeason
          </li>
        </ul>
      </div>
      <ul style={{ padding: 0 }}>
        {Object.entries(groups).map(([key, value]) => {
          return (
            <li key={key} className={classes.checkboxListItem}>
              <Form.Check
                className={classes.checkbox}
                checked={selectedGroups.includes(key)}
                onChange={() => selectedGroupChange(key)}
              />
              {value}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function summaryString(
  start: string,
  end: string,
  preseason: boolean,
  postseason: boolean
) {
  const fromDate = dateToString(new Date(start + "T00:00:00"), true);
  const endDate = dateToString(new Date(end + "T00:00:00"), true);

  let suffix = "";
  if (preseason && postseason) {
    suffix += " including preseason and postseason games";
  } else if (preseason) {
    suffix += " including preseason games";
  } else if (postseason) {
    suffix += " including postseason games";
  }

  suffix += ".";

  return (
    <span>
      Showing results from <b>{fromDate}</b> to <b>{endDate}</b>
      {suffix}
    </span>
  );
}
