import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LogRocket from "logrocket";
import moment from "moment";

import { UserContext } from "../UserContext";

const ERROR_IMG_COUNT = 4;

export function ErrorPage() {
  const [errorIdx, setErrorIdx] = useState<number>();
  const user = useContext(UserContext);

  // Instead of calculating this every render (which means it would change) just
  // do it once.
  useEffect(() => {
    LogRocket.captureMessage(
      `User crash ${user ? user.email : "unknown user"} (${moment().format(
        "MMM Do YY"
      )})!`,
      {
        extra: {
          user: user ? user.email : "unknown user",
        },
      }
    );
    setErrorIdx(Math.floor(Math.random() * ERROR_IMG_COUNT) + 1);
  }, [user]);

  if (!errorIdx) return null;
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div style={{ width: "100%", textAlign: "center" }}>
            <h2>{"That's an error!"}</h2>
            <img
              style={{ width: 400 }}
              src={`/assets/img/error/error${errorIdx}.png`}
            />
          </div>
          <h4 style={{ marginTop: 16 }}>
            <p>
              It looks like something unexpectedly crashed. Logs have been sent
              to the BIA engineering team and we will look into this shortly.
            </p>
            <p>
              If you need a more immediate response please{" "}
              <a href={`mailto:chrisbu@celtics.com?subject=BIA Crash`}>
                email Chris
              </a>
              . In the mean time you can head back to the{" "}
              <Link to="/">main page</Link>.
            </p>
          </h4>
        </div>
      </div>
    </div>
  );
}
