import React, { useState, useEffect, useRef } from "react";
import { scaleLinear, rgb } from "d3";
import { createStyles, makeStyles } from "@material-ui/styles";

import { Court } from "../court/Court";
import { GradientLegend } from "../chart/GradientLegend";
import { PlayerShotChart2d } from "../../../shared/routers/PlayerRouter";
import { useWindowSize } from "../../util/Hooks";
import { generateDomain } from "../../util/Util";
import { optionB, viridis } from "../../constants/ChartConstants";

const useStyles = makeStyles(() =>
  createStyles({
    court: {
      position: "absolute",
      top: 3,
      left: 1,
    },
  })
);

export function ShotChart2D(props: {
  data: PlayerShotChart2d[];
  isSynergy: boolean;
}) {
  const { data, isSynergy } = props;
  const classes = useStyles();
  const windowSize = useWindowSize();
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [width, setWidth] = useState(0);
  const [highlight, setHighlight] = useState<PlayerShotChart2d | null>();

  useEffect(() => {
    if (!containerRef.current) return;
    setWidth(containerRef.current.offsetWidth);
  }, [windowSize]);

  const points = data.filter((d) => d.y < 30);
  const xDomain = [25, -25]; // Flip x and y to get the rim near the top.
  const yDomain = [30, -6];
  const eppsDomain = [0.6, 1.45];

  const height = width * (((yDomain[0] || 0) * 10 + 50) / 500);

  const x = scaleLinear().domain(xDomain).range([0, width]);
  const y = scaleLinear().domain(yDomain).range([height, 0]);

  const colorDomain = eppsDomain;
  const colorRange = isSynergy ? viridis : optionB;
  const color = scaleLinear<string, string>()
    .domain(
      generateDomain(
        colorDomain[0] || 0,
        colorDomain[1] || 0,
        colorRange.length
      )
    )
    .range(colorRange)
    .clamp(true);

  const pointSize = Math.abs(x(0) - x(0.5));

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      if (!points) {
        return;
      }

      for (const d of points) {
        const pointColor = rgb(color(d.xPPS));
        pointColor.opacity = d.alpha;
        ctx.beginPath();

        // Include stroke to prevent white spaces between rects.
        ctx.strokeStyle = pointColor.toString();
        ctx.lineWidth = 0.5;

        ctx.fillStyle = pointColor.toString();
        ctx.rect(x(d.x), y(d.y), pointSize, pointSize);
        ctx.fill();
        ctx.stroke();
        ctx.closePath();
      }
    }
  }, [canvasRef.current]);

  const closestPoint = (evt: React.MouseEvent) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const mX = evt.clientX - canvas.getBoundingClientRect().left;
      const mY = evt.clientY - canvas.getBoundingClientRect().top;
      const shot = data.sort((a, b) => {
        const aX = x(a.x);
        const aY = y(a.y);
        const bX = x(b.x);
        const bY = y(b.y);
        const aD = Math.abs(aX - mX) + Math.abs(aY - bY);
        const bD = Math.abs(bX - mX) + Math.abs(bY - mY);
        return aD - bD;
      })[0];
      return shot;
    }
    return null;
  };

  return (
    <div
      onMouseMove={(evt) => setHighlight(closestPoint(evt))}
      onMouseLeave={() => setHighlight(null)}
      ref={containerRef}
      style={{
        position: "relative",
        paddingBottom: 3,
        cursor: "crosshair",
      }}
    >
      <svg
        width={width}
        height={height}
        style={{ position: "absolute", left: 0, top: 0, pointerEvents: "none" }}
      >
        <g>
          <GradientLegend
            key={"legend"}
            x={x}
            valueFunc={(h: PlayerShotChart2d | null | undefined) =>
              (h && h.xPPS) || 0
            }
            color={color}
            highlight={highlight}
            label={"PTS"}
            id={new Date().getTime()} // Just need a unique #.
            width={Math.abs(x(37) - x(13))}
          />
        </g>
      </svg>
      <canvas
        key={"canvas"}
        style={{ width: width, height: height }}
        ref={canvasRef}
      />
      <Court
        className={classes.court}
        width={width}
        hideBorder={true}
        maxDistance={35}
        hideBenchLines={true}
      />
    </div>
  );
}
