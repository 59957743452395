export interface StatsColorDomain {
  gp: number[];
  gs: number[];
  min: number[];
  pts: number[];
  orb: number[];
  drb: number[];
  trb: number[];
  ast: number[];
  blk: number[];
  stl: number[];
  to: number[];
  liveBallTurn: number[];
  pf: number[];
  crashRate: number[];
  crashRateDiff: number[];
  orbpct: number[];
  drbpct: number[];
  topct: number[];
  "2pct": number[];
  "3pct": number[];
  ftpct: number[];
  "3papct": number[];
  "2pm": number[];
  "3pm": number[];
  "2pa": number[];
  "3pa": number[];
  fgm: number[];
  fga: number[];
  ftm: number[];
  fta: number[];
  ftaRate: number[];
  fgPct: number[];
  efg: number[];
  ts: number[];
  ppp: number[];
  usg: number[];
  playerPoss: number[];
  teamPoss: number[];
  xpps: number[];
  xppsLg: number[];
}

export const totals: StatsColorDomain = {
  gp: [0, 82],
  gs: [0, 82],
  min: [0, 2896],
  pts: [0, 1635],
  orb: [0, 169],
  drb: [0, 460.25],
  trb: [0, 629.25],
  ast: [0, 432],
  blk: [0, 92],
  stl: [0, 106],
  to: [187, 0],
  liveBallTurn: [104, 0],
  pf: [235, 0],
  crashRate: [0.05, 0.5],
  crashRateDiff: [-0.07, 0.11],
  orbpct: [0, 0.15],
  drbpct: [0.05, 0.3],
  topct: [0.2, 0.07],
  "2pct": [0.38, 0.63],
  "3pct": [0.25, 0.45],
  ftpct: [0.5, 0.9],
  "3papct": [0, 0.68],
  "2pm": [0, 460],
  "2pa": [0, 925],
  "3pm": [0, 174],
  "3pa": [0, 442],
  fgm: [0, 634],
  fga: [0, 1367],
  ftm: [0, 349],
  fta: [0, 428],
  ftaRate: [0.09, 0.33],
  fgPct: [0.37, 0.6],
  efg: [0.42, 0.64],
  ts: [0.45, 0.65],
  ppp: [0.7, 1.3],
  usg: [0.1, 0.3],
  playerPoss: [13, 651],
  teamPoss: [80, 2573],
  xpps: [0.93, 1.28],
  xppsLg: [0.97, 1.25],
};

export const perGame: StatsColorDomain = {
  gp: [0, 82],
  gs: [0, 82],
  min: [5, 40],
  pts: [2, 25],
  orb: [0, 2],
  drb: [0, 8],
  trb: [0, 10],
  ast: [0, 10],
  blk: [0, 3],
  stl: [0, 2.5],
  to: [4, 0],
  liveBallTurn: [2.24, 0],
  pf: [7, 1],
  crashRate: [0.05, 0.5],
  crashRateDiff: [-0.07, 0.11],
  orbpct: [0, 0.15],
  drbpct: [0.05, 0.3],
  topct: [0.2, 0.07],
  "2pct": [0.38, 0.63],
  "3pct": [0.25, 0.45],
  ftpct: [0.5, 0.9],
  "3papct": [0, 0.68],
  "2pa": [0, 12],
  "2pm": [0, 6],
  "3pa": [0, 6],
  "3pm": [0, 3],
  fgm: [0, 9],
  fga: [0, 18],
  ftm: [0, 6.5],
  fta: [0, 8],
  ftaRate: [0.09, 0.33],
  fgPct: [0.37, 0.6],
  efg: [0.42, 0.64],
  ts: [0.45, 0.65],
  ppp: [0.7, 1.3],
  usg: [0.1, 0.3],
  playerPoss: [2, 14],
  teamPoss: [12, 71],
  xpps: [0.93, 1.28],
  xppsLg: [0.97, 1.25],
};

export const per100: StatsColorDomain = {
  gp: [0, 82],
  gs: [0, 82],
  min: [5, 40],
  pts: [0, 31.35],
  orb: [0, 2.5],
  drb: [0, 10.25],
  trb: [0, 12.75],
  ast: [0, 12],
  blk: [0, 3.18],
  stl: [0, 4.52],
  to: [5, 0],
  liveBallTurn: [2.8, 0],
  pf: [10, 1],
  crashRate: [0.05, 0.5],
  crashRateDiff: [-0.07, 0.11],
  orbpct: [0, 0.15],
  drbpct: [0.5, 0.3],
  topct: [0.2, 0.07],
  "2pct": [0.38, 0.63],
  "3pct": [0.25, 0.45],
  ftpct: [0.5, 0.9],
  "3papct": [0, 0.68],
  "2pa": [0, 15.29],
  "2pm": [0, 7.5],
  "3pa": [0, 8],
  "3pm": [0, 4],
  fgm: [0, 12],
  fga: [0, 24],
  ftm: [0, 9.7],
  fta: [0, 11.94],
  ftaRate: [0.09, 0.33],
  fgPct: [0.37, 0.6],
  efg: [0.42, 0.64],
  ts: [0.45, 0.65],
  ppp: [0.7, 1.3],
  usg: [0.1, 0.3],
  playerPoss: [3, 19],
  teamPoss: [16, 95],
  xpps: [0.93, 1.28],
  xppsLg: [0.97, 1.25],
};

export const teamColorDomain = {
  pppOff: [1.1, 1.18],
  xppOff: [1.09, 1.17],
  pppDef: [1.19, 1.11],
  xppDef: [1.18, 1.1],
  xppsOff: [1.1, 1.18],
  xppsDef: [1.17, 1.13],
  ppsOff: [1.1, 1.19],
  ppsDef: [1.19, 1.11],
  xppNet: [-0.08, 0.05],
  pppNet: [-0.07, 0.04],
  predppp: [1.1, 1.16],
  predpppDef: [1.17, 1.12],
  predpppNet: [-0.07, 0.04],
  delta: [-0.1, 0.1],
  shotsPerPoss: [0.95, 0.99],
  crashersPerShots: [1.2, 1.66],
  crashersPerShotInt: [0.98, 1.31],
  crashersPerShotPer: [0.18, 0.41],
  pctLayup: [0.32, 0.4],
  pctNl2: [0.19, 0.29],
  pctThree: [0.34, 0.45],
  pctTran: [0.14, 0.18],
  pctTov: [0.14, 0.12],
  pctOrb: [0.24, 0.31],
  pctZone: [0.01, 0.05],
  shotsPerPossDef: [0.99, 0.95],
  crashersPerShotsDef: [1.5, 1.39],
  crashersPerShotIntDef: [1.22, 1.12],
  crashersPerShotPerDef: [0.3, 0.25],
  pctLayupDef: [0.34, 0.4],
  pctNl2Def: [0.22, 0.26],
  pctThreeDef: [0.36, 0.42],
  pctTranDef: [0.16, 0.14],
  pctTovDef: [0.12, 0.15],
  pctOrbDef: [0.3, 0.26],
  pctZoneDef: [0.004, 0.06],
  efgOff: [0.46, 0.53],
  ftaRate: [0.22, 0.28],
};

export const PlayerStatColorDomains: Record<
  "perGame" | "per100Poss" | "totals",
  Record<string, number[]>
> = {
  perGame: {
    gp: [0, 82],
    gs: [0, 82],
    min: [5, 40],
    pts: [2, 25],
    orb: [0, 5],
    drb: [0, 8],
    trb: [0, 13],
    ast: [0, 10],
    blk: [0, 3],
    stl: [0, 2.5],
    to: [4, 0],
    liveBallTurnover: [2.24, 0],
    pf: [7, 1],
    crashRate: [0.05, 0.5],
    crashRateDiff: [-0.07, 0.11],
    orbpct: [0, 15],
    drbpct: [5, 30],
    topct: [0.2, 0.07],
    "2pct": [0.38, 0.63],
    "3pct": [0.25, 0.45],
    ftpct: [0.5, 0.9],
    "3papct": [0, 0.68],
    "2pa": [0, 12],
    "3pa": [0, 6],
    fta: [0, 8],
    xpps: [0.93, 1.28],
    xppsLg: [0.97, 1.25],
    ts: [0.45, 0.65],
    ppp: [0.7, 1.3],
    usg: [0.1, 0.3],
  },
  per100Poss: {
    gp: [0, 82],
    gs: [0, 82],
    min: [5, 40],
    pts: [0, 31.35],
    orb: [0, 7.1],
    drb: [0, 10],
    trb: [0, 17.1],
    ast: [0, 12],
    blk: [0, 3.18],
    stl: [0, 4.52],
    to: [5, 0],
    liveBallTurnover: [2.8, 0],
    pf: [10, 1],
    crashRate: [0.05, 0.5],
    crashRateDiff: [-0.07, 0.11],
    orbpct: [0, 15],
    drbpct: [5, 30],
    topct: [0.2, 0.07],
    "2pct": [0.38, 0.63],
    "3pct": [0.25, 0.45],
    ftpct: [0.5, 0.9],
    "3papct": [0, 0.68],
    "2pa": [0, 15.29],
    "3pa": [0, 8],
    fta: [0, 11.94],
    xpps: [0.93, 1.28],
    xppsLg: [0.97, 1.25],
    ts: [0.45, 0.65],
    ppp: [0.7, 1.3],
    usg: [0.1, 0.3],
  },
  totals: {
    gp: [0, 82],
    gs: [0, 82],
    min: [0, 2896],
    pts: [0, 1635],
    orb: [0, 169],
    drb: [0, 460.25],
    trb: [0, 629.25],
    ast: [0, 432],
    blk: [0, 92],
    stl: [0, 106],
    to: [187, 0],
    liveBallTurnover: [104.72, 0],
    pf: [235, 0],
    "2pm": [0, 460],
    "2pa": [0, 925],
    "3pm": [0, 174],
    "3pa": [0, 442],
    ftm: [0, 349],
    fta: [0, 428],
  },
};
