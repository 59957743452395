import React, { useState } from "react";
import { Form } from "react-bootstrap";

const options = ["=", "≥", "≤", "In"];

export function NumberInput(props: {
  value: { gte?: string; lte?: string; eq?: string };
  onChange: (val: { gte?: string; lte?: string; eq?: string }) => void;
}) {
  const { value, onChange } = props;
  const [type, setType] = useState(initialStateFromValue(value));

  return (
    <div style={{ display: "flex", gap: 8 }}>
      <Form.Select
        value={type}
        onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
          onChange({});
          setType(evt.target.value);
        }}
        style={{ width: 64 }}
      >
        {options.map((o) => (
          <option key={o} value={o}>
            {o}
          </option>
        ))}
      </Form.Select>
      {(type === "In" || type === "≥") && (
        <input
          value={(value && value.gte) || ""}
          style={{ width: 48 }}
          onChange={(evt) => {
            onChange(Object.assign({}, value, { gte: evt.target.value }));
          }}
        />
      )}
      {(type === "In" || type === "≤") && (
        <input
          value={(value && value.lte) || ""}
          style={{ width: 48 }}
          onChange={(evt) => {
            onChange(Object.assign({}, value, { lte: evt.target.value }));
          }}
        />
      )}
      {type === "=" && (
        <input
          value={(value && value.eq) || ""}
          style={{ width: 48 }}
          onChange={(evt) => {
            onChange({ eq: evt.target.value });
          }}
        />
      )}
    </div>
  );
}

function initialStateFromValue(value: {
  gte?: string;
  lte?: string;
  eq?: string;
}) {
  if (!value) {
    return "≥";
  } else if (value.eq) {
    return "=";
  } else if (value.gte !== undefined && value.lte !== undefined) {
    return "In";
  }
  return value.gte !== undefined ? "≥" : "≤";
}
