import React, { useMemo, useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Col, Form, Row } from "react-bootstrap";

import { BooleanInput } from "../core/BooleanInput";
import { MultiSelect } from "../core/MultiSelect";
import { Periods, Positions, pnrLabels } from "../../constants/AppConstants";
import AppContext from "../../../shared/AppContext";
import { TeamContext } from "../../TeamContext";
import { SSPlayerContext } from "../../PlayerContext";
import { NbaEaglePlayer } from "../../../shared/routers/RosterRouter";
import { PnrFilters } from "../../../shared/routers/PnrRouter";

const useStyles = makeStyles(() =>
  createStyles({
    filters: {
      borderBottom: "1px solid #eee",
      paddingBottom: 15,
      marginBottom: 15,
    },
    group: {
      background: "#f4f4f4",
      borderRadius: 4,
      margin: 4,
      padding: 12,
      "& .row": {
        marginBottom: 12,
      },
    },
    datePicker: {
      width: "auto",
      display: "inline-block",
    },
  })
);

export function PnrFilterForm(props: {
  filters: PnrFilters;
  onFilterChange: (newFilterValues: PnrFilters) => void;
}) {
  const classes = useStyles();
  const { filters, onFilterChange } = props;

  // Data to populate filters.
  const teams = useContext(TeamContext).teams;
  const players = useContext(SSPlayerContext);

  const playerSeasonTeamMap = useMemo(() => {
    if (!players) return;
    const ret: Record<string, Record<string, Set<string>>> = {};
    for (const player of players) {
      ret[player.playerId.toString()] = getSeasonTeamMap(player);
    }
    return ret;
  }, [players]);

  const filterPlayers = (p: NbaEaglePlayer, offense = true) => {
    const entry =
      playerSeasonTeamMap && playerSeasonTeamMap[p.playerId.toString()];
    if (!entry) return false;

    const seasons = filters.season;
    const teams = offense ? filters.oteamId : filters.dteamId;

    if (seasons?.length && teams?.length) {
      for (const season of seasons) {
        for (const team of teams) {
          const seasonEntry = entry[season];
          if (seasonEntry && seasonEntry.has(team)) {
            return true;
          }
        }
      }
      return false;
    } else if (seasons?.length) {
      return seasons.some((season) => Object.keys(entry).includes(season));
    } else if (teams?.length) {
      const allTeams = new Set(
        (function* () {
          for (const set of Object.values(entry)) yield* set;
        })()
      );
      return teams.some((team) => allTeams.has(team));
    }

    return true;
  };

  return (
    <Form>
      <Form.Group className={classes.filters}>
        <Row>
          <Col className={classes.group}>
            <Row>
              <Col>
                <Form.Label>Season</Form.Label>
                <MultiSelect
                  values={AppContext.seasons.map((s) => {
                    return { value: s.value.toString(), label: s.label };
                  })}
                  selected={filters.season || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, season: vals })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Period</Form.Label>
                <MultiSelect
                  values={Object.keys(Periods).map((p) => {
                    return { value: p, label: Periods[parseInt(p)] || "" };
                  })}
                  selected={filters.period || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, period: vals })
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col className={classes.group}>
            <Row>
              <Col>
                <div>
                  <Form.Label>Date</Form.Label>
                </div>
                <Form.Control
                  type="date"
                  className={classes.datePicker}
                  value={filters.fromDate}
                  onChange={(evt) => {
                    onFilterChange({ ...filters, fromDate: evt.target.value });
                  }}
                />
                {" to "}
                <Form.Control
                  type="date"
                  className={classes.datePicker}
                  value={filters.toDate}
                  onChange={(evt) => {
                    onFilterChange({ ...filters, toDate: evt.target.value });
                  }}
                />
              </Col>
              <Col>
                <Form.Label>Is Playoffs</Form.Label>
                <BooleanInput
                  name="playoffs"
                  value={boolToStringFix(filters.playoffs)}
                  onChange={(v) => {
                    onFilterChange({
                      ...filters,
                      playoffs: stringToBoolFix(v),
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className={classes.group}>
            <Row>
              <Col>
                <Form.Label>Offense Team</Form.Label>
                <MultiSelect
                  values={teams.map((t) => {
                    return {
                      label: `${t.teamcity} ${t.teamname}`,
                      value: t.teamid.toString(),
                    };
                  })}
                  selected={filters.oteamId || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, oteamId: vals })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Ballhandler</Form.Label>
                <MultiSelect
                  values={players
                    .filter((p) => filterPlayers(p, true))
                    .map((p) => {
                      return {
                        label: p.player,
                        value: p.playerId.toString(),
                      };
                    })}
                  selected={filters.ballhandlerId || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, ballhandlerId: vals })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Screener</Form.Label>
                <MultiSelect
                  values={players
                    .filter((p) => filterPlayers(p, true))
                    .map((p) => {
                      return {
                        label: p.player,
                        value: p.playerId.toString(),
                      };
                    })}
                  selected={filters.screenerId || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, screenerId: vals })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Ballhandler Position</Form.Label>
                <MultiSelect
                  values={Object.keys(Positions).map((p) => {
                    return { value: p, label: Positions[parseInt(p)] || "" };
                  })}
                  selected={filters.positionBallhandler || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, positionBallhandler: vals })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Screener Position</Form.Label>
                <MultiSelect
                  values={Object.keys(Positions).map((p) => {
                    return { value: p, label: Positions[parseInt(p)] || "" };
                  })}
                  selected={filters.positionScreener || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, positionScreener: vals })
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col className={classes.group}>
            <Row>
              <Col>
                <Form.Label>Defense Team</Form.Label>
                <MultiSelect
                  values={teams.map((t) => {
                    return {
                      label: `${t.teamcity} ${t.teamname}`,
                      value: t.teamid.toString(),
                    };
                  })}
                  selected={filters.dteamId || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, dteamId: vals })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Ballhandler Defender</Form.Label>
                <MultiSelect
                  values={players
                    .filter((p) => filterPlayers(p, false))
                    .map((p) => {
                      return {
                        label: p.player,
                        value: p.playerId.toString(),
                      };
                    })}
                  selected={filters.ballhandlerDefenderId || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, ballhandlerDefenderId: vals })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Screener Defender</Form.Label>
                <MultiSelect
                  values={players
                    .filter((p) => filterPlayers(p, false))
                    .map((p) => {
                      return {
                        label: p.player,
                        value: p.playerId.toString(),
                      };
                    })}
                  selected={filters.screenerDefenderId || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, screenerDefenderId: vals })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Ballhandler Defender Position</Form.Label>
                <MultiSelect
                  values={Object.keys(Positions).map((p) => {
                    return { value: p, label: Positions[parseInt(p)] || "" };
                  })}
                  selected={filters.positionBallhandlerDefender || []}
                  onChange={(vals) =>
                    onFilterChange({
                      ...filters,
                      positionBallhandlerDefender: vals,
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Screener Defender Position</Form.Label>
                <MultiSelect
                  values={Object.keys(Positions).map((p) => {
                    return { value: p, label: Positions[parseInt(p)] || "" };
                  })}
                  selected={filters.positionScreenerDefender || []}
                  onChange={(vals) =>
                    onFilterChange({
                      ...filters,
                      positionScreenerDefender: vals,
                    })
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className={classes.group}>
            <Row>
              <Col>
                <Form.Label>Location</Form.Label>
                <MultiSelect
                  values={Object.keys(pnrLabels.location_type).map((key) => {
                    return {
                      value: key,
                      label: pnrLabels.location_type[key] || "",
                    };
                  })}
                  selected={filters.locationType || []}
                  onChange={(vals) =>
                    onFilterChange({
                      ...filters,
                      locationType: vals,
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Roll / Pop</Form.Label>
                <MultiSelect
                  values={Object.keys(pnrLabels.roll_pop).map((key) => {
                    return {
                      value: key,
                      label: pnrLabels.roll_pop[key] || "",
                    };
                  })}
                  selected={filters.rollPop || []}
                  onChange={(vals) =>
                    onFilterChange({
                      ...filters,
                      rollPop: vals,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Direction</Form.Label>
                <MultiSelect
                  values={Object.keys(pnrLabels.direction).map((key) => {
                    return {
                      value: key,
                      label: pnrLabels.direction[key] || "",
                    };
                  })}
                  selected={filters.direction || []}
                  onChange={(vals) =>
                    onFilterChange({
                      ...filters,
                      direction: vals,
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Screen Type</Form.Label>
                <MultiSelect
                  values={Object.keys(pnrLabels.scr_type).map((key) => {
                    return {
                      value: key,
                      label: pnrLabels.scr_type[key] || "",
                    };
                  })}
                  selected={filters.takeRejectSlip || []}
                  onChange={(vals) =>
                    onFilterChange({
                      ...filters,
                      takeRejectSlip: vals,
                    })
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col className={classes.group}>
            <Row>
              <Col>
                <Form.Label>Team Defense</Form.Label>
                <MultiSelect
                  values={Object.keys(pnrLabels.defense).map((key) => {
                    return {
                      value: key,
                      label: pnrLabels.defense[key] || "",
                    };
                  })}
                  selected={filters.defense || []}
                  onChange={(vals) =>
                    onFilterChange({
                      ...filters,
                      defense: vals,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Ballhandler Defense</Form.Label>
                <MultiSelect
                  values={Object.keys(pnrLabels.bhr_def_type_complex).map(
                    (key) => {
                      return {
                        value: key,
                        label: pnrLabels.bhr_def_type_complex[key] || "",
                      };
                    }
                  )}
                  selected={filters.bhrDefTypeComplex || []}
                  onChange={(vals) =>
                    onFilterChange({
                      ...filters,
                      bhrDefTypeComplex: vals,
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Screener Defense</Form.Label>
                <MultiSelect
                  values={Object.keys(pnrLabels.scr_def_type_complex).map(
                    (key) => {
                      return {
                        value: key,
                        label: pnrLabels.scr_def_type_complex[key] || "",
                      };
                    }
                  )}
                  selected={filters.scrDefTypeComplex || []}
                  onChange={(vals) =>
                    onFilterChange({
                      ...filters,
                      scrDefTypeComplex: vals,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Coverage</Form.Label>
                <MultiSelect
                  values={Object.keys(pnrLabels.coverage).map((key) => {
                    return {
                      value: key,
                      label: pnrLabels.coverage[key] || "",
                    };
                  })}
                  selected={filters.coverage || []}
                  onChange={(vals) =>
                    onFilterChange({
                      ...filters,
                      coverage: vals,
                    })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Screener Defender Level</Form.Label>
                <MultiSelect
                  values={Object.keys(pnrLabels.scrDefenderLevel).map((key) => {
                    return {
                      value: key,
                      label: pnrLabels.scrDefenderLevel[key] || "",
                    };
                  })}
                  selected={filters.scrDefenderLevel || []}
                  onChange={(vals) =>
                    onFilterChange({
                      ...filters,
                      scrDefenderLevel: vals,
                    })
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Double</Form.Label>
            <BooleanInput
              name="double"
              value={boolToStringFix(filters.double)}
              onChange={(v) =>
                onFilterChange({
                  ...filters,
                  double: stringToBoolFix(v),
                })
              }
            />
          </Col>
          <Col>
            <Form.Label>Handoff Pick</Form.Label>
            <BooleanInput
              name="handoffPick"
              value={boolToStringFix(filters.handoffPick)}
              onChange={(v) =>
                onFilterChange({
                  ...filters,
                  handoffPick: stringToBoolFix(v),
                })
              }
            />
          </Col>
          <Col>
            <Form.Label>Horns</Form.Label>
            <BooleanInput
              name="horns"
              value={boolToStringFix(filters.horns)}
              onChange={(v) =>
                onFilterChange({
                  ...filters,
                  horns: stringToBoolFix(v),
                })
              }
            />
          </Col>
          <Col>
            <Form.Label>L-Screen</Form.Label>
            <BooleanInput
              name="l"
              value={boolToStringFix(filters.l)}
              onChange={(v) =>
                onFilterChange({
                  ...filters,
                  l: stringToBoolFix(v),
                })
              }
            />
          </Col>
          <Col>
            <Form.Label>Rescreen</Form.Label>
            <BooleanInput
              name="rescreen"
              value={boolToStringFix(filters.rescreen)}
              onChange={(v) =>
                onFilterChange({
                  ...filters,
                  rescreen: stringToBoolFix(v),
                })
              }
            />
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );
}

function getSeasonTeamMap(p: NbaEaglePlayer) {
  const map: Record<string, Set<string>> = {};
  const seasonTeamArr = p.seasonTeam.split(" ");
  for (const seasonTeam of seasonTeamArr) {
    const year = seasonTeam.split("_")[0] || "";
    const team = seasonTeam.split("_")[1] || "";
    const yearObj = map[year];
    if (!yearObj) {
      map[year] = new Set([team]);
    } else {
      yearObj.add(team);
    }
  }
  return map;
}

// TODO(chrisbu): Remove these functions when the shot explorer is migrated to
// tRPC and no longer use strings for boolean values.
function boolToStringFix(val?: boolean) {
  if (val === undefined) return undefined;
  return val ? "1" : "0";
}

function stringToBoolFix(val?: string | number) {
  if (val === undefined) return undefined;
  return val === "1" || val === 1;
}
