import React from "react";
import cx from "classnames";

import AppContext from "../../../shared/AppContext";
import { PlayerLink } from "./PlayerLink";
import { RosterPlayer } from "../../../shared/routers/RosterRouter";

/**
 * Renders a list of players with clickable links (e.g. used for a team roster).
 */
export function PlayerList(props: {
  players: RosterPlayer[];
  className?: string;
}) {
  const { players } = props;
  return (
    <ul className={cx("list-unstyled", props.className)}>
      {players.map((player, i) => {
        return (
          <li
            key={i}
            className={`contract-${player.contractStatus}`}
            style={{ clear: "both" }}
          >
            <PlayerLink
              player={player}
              contractSeason={AppContext.currentSeason}
            />
          </li>
        );
      })}
    </ul>
  );
}
