import React, { useState, useContext, useCallback, useMemo } from "react";
import { scaleLinear } from "@visx/scale";
import { Circle } from "@visx/shape";
import { ParentSize } from "@visx/responsive";
import { useTooltip, TooltipWithBounds, defaultStyles } from "@visx/tooltip";
import { EventType } from "@visx/event/lib/types";
import { localPoint } from "@visx/event";
import { Button, Form } from "react-bootstrap";

import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { trpc } from "../util/tRPC";
import { colorFromPosition } from "../util/Colors";
import { TeamContext } from "../TeamContext";
import { UserPreferenceContext } from "../UserContext";
import { extent as extentNew, generateDomain } from "../util/Util";
import { PlayerPosition2d } from "../../shared/routers/PlayerRouter";
import { tableColorSchemes } from "../constants/ChartConstants";
import { plusMinusFormat } from "../util/Format";

// Refactored the extent method to return an object instead of array and this is
// just a shim to match old behavior so I can refactor later.
function extent<T>(data: T[], accessor: (d: T) => number) {
  const { min, max } = extentNew(data, accessor);
  return [min, max];
}

const CIRCLE_RADIUS = 5;
const COLOR_OPTIONS = ["Position", "Net Impact", "Off Impact", "Def Impact"];

export function PlayerSimilarityPage() {
  const [isActive, setIsActive] = useState(false);
  const [isStarter, setIsStarter] = useState(false);

  const [color, setColor] = useState("Position");
  const [team, setTeam] = useState("all");
  const allTeams = useContext(TeamContext).teams;
  const colorPref = useContext(UserPreferenceContext)["Color Scheme"] || 0;

  const { data } = trpc.player.getPlayerPosition2d.useQuery();

  const colorScale = scaleLinear<string>()
    .domain(generateDomain(-0.05, 0.05, 3))
    .range(tableColorSchemes[colorPref] || ["black", "black"]);

  function getColor(data: PlayerPosition2d) {
    if (color === "Position") {
      return colorFromPosition(data.posEstimate);
    } else if (color === "Net Impact") {
      if (data.netImpact === null) {
        return "white";
      }
      return colorScale(data.netImpact || 0);
    } else if (color === "Off Impact") {
      if (data.offImpact === null) {
        return "white";
      }
      return colorScale(data.offImpact || 0);
    } else if (color === "Def Impact") {
      if (data.defImpact === null) {
        return "white";
      }
      return colorScale(data.defImpact || 0);
    }
    return "white";
  }

  const transformedData = data
    ? data.map((d) => {
        return {
          ...d,
          color: getColor(d),
          show:
            // Hide players /w no impact when we are using impact colors.
            (color === "Position" || d.netImpact !== null) &&
            (isActive === false || d.teamId !== null) &&
            (isStarter === false || d.starter === true) &&
            (team === "all" || d.teamId === parseInt(team)),
        };
      })
    : undefined;

  const xExtent = transformedData
    ? extent(transformedData, (d) => d.x)
    : [0, 0];
  const yExtent = transformedData
    ? extent(transformedData, (d) => d.y)
    : [0, 0];
  const xDiff = (xExtent[1] || 0) - (xExtent[0] || 0);
  const yDiff = (yExtent[1] || 0) - (yExtent[0] || 0);
  const aspectRatio = Math.abs(xDiff / yDiff);

  return (
    <Page header={{ text: "Player Similarity" }} title="Player Similarity">
      <Panel header={"2D Similarity"}>
        <div style={{ display: "flex", gap: 8 }}>
          <Button onClick={() => setIsActive(!isActive)} style={{ width: 120 }}>
            {isActive ? "Show All" : "Active Only"}
          </Button>
          <Button
            onClick={() => setIsStarter(!isStarter)}
            style={{ width: 140 }}
          >
            {isStarter ? "Show All" : "Starters Only"}
          </Button>
          <Form.Select
            value={team}
            onChange={(evt) => setTeam(evt.target.value)}
            style={{ width: "auto" }}
          >
            <option value="all">All Teams</option>
            {allTeams.map((team) => (
              <option key={team.teamid} value={team.teamid.toString()}>
                {team.teamcity} {team.teamname}
              </option>
            ))}
          </Form.Select>
          <Form.Select
            value={color}
            onChange={(evt) => setColor(evt.target.value)}
            style={{ width: "auto" }}
          >
            {COLOR_OPTIONS.map((opt) => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
          </Form.Select>
        </div>
        {transformedData && (
          <ParentSize>
            {({ width }) => (
              <ScatterPlot
                data={transformedData}
                width={width * 0.5}
                height={(width * 0.5) / aspectRatio}
                margin={{ left: 8, right: 8, top: 8, bottom: 8 }}
              />
            )}
          </ParentSize>
        )}
      </Panel>
    </Page>
  );
}

function ScatterPlot(props: {
  data: Array<
    PlayerPosition2d & {
      show: boolean;
      color: string;
    }
  >;
  width: number;
  height: number;
  margin: { left: number; right: number; top: number; bottom: number };
}) {
  const { data, width, height, margin } = props;

  // Set up scales
  const xScale = scaleLinear({
    domain: [
      Math.min(...data.map((d) => d.x)),
      Math.max(...data.map((d) => d.x)),
    ],
    range: [margin.left, width - margin.right],
  });

  const yScale = scaleLinear({
    domain: [
      Math.min(...data.map((d) => d.y)),
      Math.max(...data.map((d) => d.y)),
    ],
    range: [height - margin.bottom, margin.top],
  });

  const {
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    showTooltip,
    hideTooltip,
  } = useTooltip<PlayerPosition2d>();

  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    color: "black",
  };

  const points = useMemo(() => {
    return data.map((d) => ({
      x: xScale(d.x),
      y: yScale(d.y),
      show: d.show,
      data: d,
    }));
  }, [xScale, yScale, data]);

  const handleTooltip = useCallback(
    (event: EventType) => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 };
      const active = points.filter((p) => p.show);
      for (const a of active) {
        if (
          Math.abs(a.x - x) < CIRCLE_RADIUS &&
          Math.abs(a.y - y) < CIRCLE_RADIUS
        ) {
          showTooltip({
            tooltipData: a.data,
            tooltipLeft: a.x,
            tooltipTop: a.y,
          });
          return;
        }
      }
      hideTooltip();
    },
    [hideTooltip, points, showTooltip]
  );

  return (
    <div style={{ position: "relative", width: "50%", margin: "auto" }}>
      <svg width={width} height={height}>
        {data.map((d, i) => (
          <Circle
            key={i}
            cx={xScale(d.x)}
            cy={yScale(d.y)}
            r={CIRCLE_RADIUS}
            fill={d.color}
            stroke={"black"}
            opacity={d.show ? 1 : 0.1}
          />
        ))}
        <rect
          width={width}
          height={height}
          onTouchStart={handleTooltip}
          fill={"transparent"}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
          onMouseLeave={() => hideTooltip()}
        />
      </svg>
      {tooltipData && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div>
            <div>
              <b>{tooltipData.player}</b>
            </div>
            <div>
              Net:{" "}
              {tooltipData.netImpact === null
                ? "--"
                : plusMinusFormat(100 * tooltipData.netImpact)}
            </div>
            <div>
              Off:{" "}
              {tooltipData.offImpact === null
                ? "--"
                : plusMinusFormat(100 * tooltipData.offImpact)}
            </div>
            <div>
              Def:{" "}
              {tooltipData.defImpact === null
                ? "--"
                : plusMinusFormat(100 * tooltipData.defImpact)}
            </div>
          </div>
        </TooltipWithBounds>
      )}
    </div>
  );
}
