import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import {
  PlayerImpactByPosition,
  PlayerImpactByRole,
} from "../../../shared/routers/ImpactRouter";
import { dec100Format } from "../../util/Format";
import { Highlights, Positions } from "../../constants/AppConstants";
import { PlayerTableCell } from "../core/TableCell";

export function PlayerRoleImpact(props: {
  impactByRole: PlayerImpactByRole[];
  impactByPos: PlayerImpactByPosition[];
  showCelticsRoles: boolean;
}) {
  const { impactByRole, impactByPos, showCelticsRoles } = props;

  const n = 20;

  const data = useMemo<PlayerImpactByRole[]>(() => {
    return impactByRole
      .sort(
        (a, b) => b.off_impact + b.def_impact - (a.off_impact + a.def_impact)
      )
      .slice(0, n);
  }, [impactByRole]);

  return (
    <Row>
      <Col>
        <b>Max Impact Roles</b>
        <RoleImpactTable data={data} />
      </Col>
      <Col>
        <b>Typical Positional Roles</b>
        <RoleImpactTable
          data={impactByPos
            .sort((a, b) => b.Pos_used - a.Pos_used)
            .map((i) => {
              return {
                roleName: Positions[i.Pos_used] || "",
                def_impact: i.def_impact,
                off_impact: i.off_impact,
              };
            })}
        />
      </Col>
      {showCelticsRoles && (
        <Col>
          <b>Celtics Player Roles</b>
          <RoleImpactTable
            data={impactByRole
              .filter((r) => r.onCeltics)
              .sort(
                (a, b) =>
                  b.off_impact + b.def_impact - (a.off_impact + a.def_impact)
              )}
          />
        </Col>
      )}
    </Row>
  );
}

interface RoleImpactRow {
  roleName: string;
  roleId?: number;
  def_impact: number;
  off_impact: number;
}

const columnHelper = createColumnHelper<RoleImpactRow>();

function RoleImpactTable(props: { data: RoleImpactRow[] }) {
  const [sorting, setSorting] = useState<SortingState>();
  const { data } = props;

  const columns = useMemo(
    () => [
      columnHelper.accessor("roleName", {
        header: () => "Role",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.roleId}
            name={info.getValue()}
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.accessor((row) => row.off_impact + row.def_impact, {
        id: "net_impact",
        header: () => "Net",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: 1,
          highlights: Highlights.Max,
          colorDomain: [-0.04239454, 0.04371481],
        },
      }),
      columnHelper.accessor("off_impact", {
        header: () => "OFF",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: 1,
          highlights: Highlights.Max,
          colorDomain: [-0.04239453566, 0.04371481377],
        },
      }),
      columnHelper.accessor("def_impact", {
        header: () => "DEF",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: 1,
          highlights: Highlights.Max,
          colorDomain: [-0.04067071167, 0.03291763534],
        },
      }),
    ],
    []
  );

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
      showColorOnHover={true}
    />
  );
}
