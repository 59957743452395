import React, { useMemo, useState } from "react";
import { Form } from "react-bootstrap";

import { MultiSelect } from "../core/MultiSelect";
import { Table, createColumnHelper, SortingState } from "../core/Table";
import { UnmappedRealGmPlayer } from "../../../shared/routers/MappingRouter";
import { trpc } from "../../util/tRPC";
import { useDebounce } from "../../util/Hooks";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<UnmappedRealGmPlayer>();

export function UnmappedRealGms(props: { unmapped?: UnmappedRealGmPlayer[] }) {
  const { unmapped } = props;
  const [playerFilter, setPlayerFilter] = useState("");
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("playerId", {
        header: "RealGM ID",
      }),
      columnHelper.accessor("playerName", {
        header: "Name",
        cell: (info) => {
          return (
            <a
              target="_blank"
              href={`https://basketball.realgm.com${info.row.original.playerUrl}`}
              rel="noreferrer"
            >
              {info.getValue()}
            </a>
          );
        },
      }),
      columnHelper.accessor("playerTeam", {
        header: "Team",
      }),
      columnHelper.accessor("playerPos", {
        header: "Pos",
      }),
      columnHelper.accessor("playerHeight", {
        header: "Height",
      }),
      columnHelper.accessor("playerBirthdate", {
        header: "DOB",
      }),
      columnHelper.display({
        id: "insert",
        cell: (info) => (
          <PlayerInsertWidget realGmId={info.row.original.playerId} />
        ),
      }),
    ];
  }, []);

  const playerFilterDebounced = useDebounce(playerFilter, 300);

  const filteredData = useMemo(() => {
    if (unmapped === undefined) return [];

    return unmapped.filter((u) => {
      if (playerFilterDebounced.length > 0) {
        return u.playerName
          .toLowerCase()
          .includes(playerFilterDebounced.toLowerCase());
      }
      return true;
    });
  }, [playerFilterDebounced, unmapped]);

  if (!unmapped) return null;

  return (
    <>
      <Form.Control
        style={{ display: "inline-block", width: "auto" }}
        placeholder="Search for a player..."
        value={playerFilter}
        onChange={(evt) => setPlayerFilter(evt.target.value)}
      />
      <Table
        data={filteredData}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        autoWidth={true}
        virtualScroll={true}
        showRowIndex={false}
      />
    </>
  );
}

function PlayerInsertWidget(props: { realGmId: number }) {
  const { realGmId } = props;
  const [celticsId, setCelticsId] = useState<number>();
  const [searchQuery, setSearchQuery] = useState("");

  const utils = trpc.useContext();

  const { data: autoCompletePlayers } =
    trpc.mapping.getUnMappedCelticsPlayers.useQuery({
      searchQuery,
    });

  const insertMutation = trpc.mapping.insertRealGmMapping.useMutation({
    onSuccess: () => {
      utils.mapping.getUnMappedRealGmPlayers.invalidate();
      utils.mapping.getUnMappedRealGmPlayers.refetch();
      setCelticsId(undefined);
    },
    onError: () => {
      alert("Error inserting player into mapping table!");
    },
  });

  const handleClick = () => {
    if (celticsId === undefined) return;

    insertMutation.mutate({
      realGmId,
      celticsId,
    });
  };

  return (
    <div style={{ display: "flex" }}>
      <MultiSelect
        values={(autoCompletePlayers || []).map((p) => {
          return {
            value: p.playerId.toString(),
            label: p.playerName,
            aux1: [p.college, p.birthYear].filter((a) => !!a).join(", "),
            aux2: "",
          };
        })}
        selected={celticsId ? [celticsId.toString()] : []}
        onTextChange={(text) => setSearchQuery(text)}
        onChange={(vals) => {
          if (vals.length === 0) {
            // When we remove the selection also clear the search query.
            setSearchQuery("");
            setCelticsId(undefined);
          } else {
            setCelticsId(parseInt(vals[0] || ""));
          }
        }}
        limit={1}
      />
      <button onClick={handleClick} disabled={celticsId === undefined}>
        Insert
      </button>
      <div style={{ marginLeft: 8, alignSelf: "center" }}>
        {celticsId !== undefined && (
          <Link
            to={`/player/${celticsId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            BIA Player Page
          </Link>
        )}
      </div>
    </div>
  );
}
