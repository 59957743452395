import React from "react";
import cx from "classnames";
import { createStyles, makeStyles } from "@material-ui/styles";

import { identity } from "../../../util/Util";

const useStyles = makeStyles(() =>
  createStyles({
    statRankRenderer: {
      position: "relative",
      minWidth: 75,
      textAlign: "center",
    },
    stat: {
      fontSize: "0.9em",
      display: "inline-block",
      textAlign: "left",
      paddingLeft: "1em",
      opacity: 0,
      transition: ".15s opacity",
      // TODO(chrisbu): This should apply when the column or row of the cell is
      // highlighted as well, not just the cell itself.
      "&:hover": { opacity: 1 },
      ["@media print"]: {
        fontSize: "1em",
        opacity: 1,
      },
    },
    showStat: {
      opacity: "0.7",
    },
    rank: {
      display: "inline-block",
      textAlign: "right",
    },
  })
);

/** Render to deal with cells that have a stat rank and value in one cell. */
export function StatRankRenderer(props: {
  data: any;
  tooltipKey?: string;
  config: any;
}) {
  const classes = useStyles();
  const { rendererOptions: options = {} } = props.config;
  // Backwards compatible support fmt outside of rendererOptions if not inside.
  const fmt = options.fmt || props.config.fmt;
  // Allow formatting of rank too.
  const rankFmt = options.rankFmt || identity;
  const { statTitle, rankTitle } = options;

  const statKey = options.statKey || "stat";
  const rankKey = options.rankKey || "rank";

  let tooltip;
  if (options.tooltip) {
    if (typeof options.tooltip === "function") {
      tooltip = options.tooltip(props.data, props.tooltipKey, props.config);
    } else {
      tooltip = options.tooltip;
    }
  }

  const rankValue = props.data.get
    ? props.data.get(rankKey)
    : props.data[rankKey];
  const statValue = props.data.get
    ? props.data.get(statKey)
    : props.data[statKey];

  return (
    <div className={classes.statRankRenderer} title={tooltip}>
      <span className={classes.rank} title={rankTitle}>
        {statValue == null || rankValue == null ? null : rankFmt(rankValue)}
      </span>
      <span
        className={cx(classes.stat, options.showStat ? classes.showStat : "")}
        title={statTitle}
      >
        {statValue == null ? null : fmt(statValue)}
      </span>
    </div>
  );
}
