import React from "react";
import { scaleLinear, ScaleLinear, extent } from "d3";
import cx from "classnames";
import { createStyles, makeStyles } from "@material-ui/styles";

import { LinearGradient } from "./LinearGradient";
import { decFormat2 } from "../../util/Format";

interface GradientLegendProps<H> {
  id: number;
  x: ScaleLinear<number, number>;
  color: ScaleLinear<string, string>;
  highlight: H;
  valueFunc: (h: H) => number;
  label: string;
  width: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      dominantBaseline: "hanging",
      fill: "#999",
      "&.highlight": {
        fontWeight: 600,
        fill: "#666",
      },
    },
    tick: {
      stroke: "rgba(255,255,255,.5)",
      "&.highlight": {
        stroke: "#fff",
        strokeWidth: 2,
      },
    },
  })
);

export function GradientLegend<H>(props: GradientLegendProps<H>) {
  const classes = useStyles();
  const { x, color, highlight, width, id, label, valueFunc } = props;

  const xRange = x.range();
  const xRangeMin = xRange[0] || 0;
  const xRangeMax = xRange[xRange.length - 1] || 0;
  const midpoint = (xRangeMin + xRangeMax) / 2;

  const rectHeight = 6;
  const rectWidth = width ? width : x(-12) - x(12);

  const colorDomain = color.domain();
  const colorData = color.range();

  // Create ticks.
  let numTicks = 0;
  if (rectWidth >= 125) {
    numTicks = 5;
  } else if (rectWidth >= 75) {
    numTicks = 3;
  }
  const ticks = [];

  // If no highlight, show all ticks.
  if (!highlight) {
    for (let i = 0; i < numTicks - 1; i++) {
      const index = i * (colorDomain.length / (numTicks - 1));
      ticks[i] = {
        x: index / colorDomain.length,
        label: decFormat2(colorDomain[Math.round(index)] || 0),
      };
    }
    if (numTicks > 0) {
      ticks.push({ x: 1, label: colorDomain[colorDomain.length - 1] });
    }
    // A point is highlighted, show just its value.
  } else {
    const value = valueFunc(highlight);
    ticks.push({
      x: scaleLinear()
        .domain(extent(colorDomain) as any)
        .range([0, 1])(value),
      label: decFormat2(value),
      highlight: true,
    });
  }

  return (
    <g transform={`translate(${Math.ceil(midpoint - rectWidth / 2)} 0)`}>
      <rect
        x={-10}
        y={0}
        width={rectWidth + 20}
        height={rectHeight + 17}
        fill={"#fff"} // White box to make ticks readable.
      />
      <rect
        x={0}
        y={2}
        width={rectWidth}
        height={rectHeight}
        fill={`url(#${id}-legend-gradient)`}
      />
      <text className={classes.text} x={-2} y={0} textAnchor="end">
        {label}
      </text>
      <g transform={`translate(0 ${rectHeight + 6})`}>
        {ticks.map((t, i) => {
          // Note: sometimes t.x is NaN.
          return (
            <g key={i} transform={`translate(${(t.x || 0) * rectWidth} 0)`}>
              <line
                className={cx([classes.tick], { highlight: t.highlight })}
                x1={0}
                y1={-1}
                x2={0}
                y2={t.highlight ? -rectHeight - 6 : -rectHeight - 1}
              />
              <text
                className={cx([classes.text], { highlight: t.highlight })}
                key={i}
                x={0}
                y={0}
                textAnchor="middle"
              >
                {t.label}
              </text>
            </g>
          );
        })}
      </g>
      <LinearGradient colorData={colorData} id={`${id}-legend-gradient`} />
    </g>
  );
}
