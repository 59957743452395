import React, { useMemo, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

import { Modal } from "../core/Modal";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import { Spinner } from "../core/Spinner";
import {
  SynergyMaybe,
  MappingInsert,
} from "../../../shared/routers/MappingRouter";
import { trpc } from "../../util/tRPC";

export function SynergyMaybes(props: { maybes?: SynergyMaybe[] }) {
  const { maybes } = props;
  const [errMsg, setErrMsg] = useState<string>();
  const [checkedRows, setCheckedRows] = useState<Set<string>>(new Set());
  const [sorting, setSorting] = useState<SortingState>();
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);

  const utils = trpc.useContext();

  const insertMutation = trpc.mapping.postSynergyInsert.useMutation({
    onSuccess: () => {
      setCheckedRows(new Set());
      setErrMsg("");
      utils.mapping.getSynergyMaybes.invalidate();
      utils.mapping.getSynergyInserts.invalidate();
      utils.mapping.getSynergyMaybes.refetch();
      utils.mapping.getSynergyInserts.refetch();
    },
    onError: () => {
      setErrMsg("Unable to insert player into mapping table");
    },
  });

  const updateMutation = trpc.mapping.postSynergyUpdate.useMutation({
    onSuccess: () => {
      setCheckedRows(new Set());
      setErrMsg("");
      utils.mapping.getSynergyMaybes.invalidate();
      utils.mapping.getSynergyInserts.invalidate();
      utils.mapping.getSynergyMaybes.refetch();
      utils.mapping.getSynergyInserts.refetch();
    },
    onError: () => {
      setErrMsg("Unable to update players in synergy mapping table");
    },
  });

  const pending = insertMutation.isLoading || updateMutation.isLoading;

  const columnHelper = createColumnHelper<SynergyMaybe>();

  const handleCheck = (id: string) => {
    if (checkedRows.has(id)) {
      checkedRows.delete(id);
    } else {
      checkedRows.add(id);
    }
    setCheckedRows(new Set(checkedRows));
  };

  const handleCheckAll = (checkAll: boolean) => {
    if (!maybes) return;
    if (checkAll) {
      setCheckedRows(
        new Set(maybes.map((m) => `${m.synergyId}-${m.celticsId}`))
      );
    } else {
      setCheckedRows(new Set());
    }
  };

  // If we have identified existing players (aka they exist in BIA) just add a
  // new row to the synergy mapping table and then remove the maybes entries.
  const mergeCheckedRows = () => {
    if (!maybes) return;

    const playersToUpdate = maybes
      .filter((m) => checkedRows.has(`${m.synergyId}-${m.celticsId}`))
      .map((m) => {
        return {
          celticsId: m.celticsId,
          synergyId: m.synergyId,
        };
      });

    if (playersToUpdate.length === 0) return;

    updateMutation.mutate({ players: playersToUpdate });
  };

  // If we have identified new players (aka not dupes) insert them into the
  // mapping table and then remove the maybes entries.
  const insertCheckedRows = () => {
    if (!maybes) return;

    const playersToInsert: MappingInsert[] = maybes
      .filter((m) => checkedRows.has(`${m.synergyId}-${m.celticsId}`))
      .map((m) => {
        return {
          First: m.synergyFirst
            ? m.synergyFirst.replace("Jr.", "").replace("III", "")
            : null,
          Last: m.synergyLast,
          DOB: m.PlayerBDay,
          Ht: null,
          Wt: null,
          College: null,
          Hometown: null,
          State: null,
          HSJC: null,
          Country: null,
          DE: null,
          synergy: m.synergyId,
        };
      });

    if (playersToInsert.length === 0) return;

    insertMutation.mutate({ players: playersToInsert, maybes: true });
  };

  const insertModal = (
    <Modal
      title={"Confirm Synergy Insert"}
      content={
        "Pressing confirm will create new player mapping entries for the selected player(s)."
      }
      show={showInsertModal}
      handleClose={() => setShowInsertModal(false)}
      handleConfirm={() => {
        setShowInsertModal(false);
        insertCheckedRows();
      }}
    />
  );

  const mergeModal = (
    <Modal
      title={"Confirm Merge Duplicate"}
      content={
        "Pressing confirm will update the existing player mapping entries for selected player(s)."
      }
      show={showMergeModal}
      handleClose={() => setShowMergeModal(false)}
      handleConfirm={() => {
        setShowMergeModal(false);
        mergeCheckedRows();
      }}
    />
  );

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "check",
        header: () =>
          maybes &&
          maybes.length > 0 && (
            <Form.Check
              checked={checkedRows.size === maybes.length}
              onChange={() =>
                handleCheckAll(checkedRows.size !== maybes.length)
              }
            />
          ),
        cell: (info) => (
          <Form.Check
            checked={checkedRows.has(
              `${info.row.original.synergyId}-${info.row.original.celticsId}`
            )}
            onChange={() =>
              handleCheck(
                `${info.row.original.synergyId}-${info.row.original.celticsId}`
              )
            }
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.accessor("synergyId", {
        meta: { group: 1 },
      }),
      columnHelper.accessor("synergyFirst", {
        meta: { group: 2 },
      }),
      columnHelper.accessor("synergyLast", {
        meta: { group: 3 },
      }),
      columnHelper.accessor("synergyTeamName", {
        meta: { group: 4 },
      }),
      columnHelper.accessor("celticsId", {
        header: () => "BIA Player",
        cell: (info) => (
          <Link to={`/player/${info.row.original.celticsId}`}>
            {info.row.original.celticsName}
          </Link>
        ),
        meta: { group: 5 },
      }),
      columnHelper.accessor("PlayerBDay", {
        meta: { group: 6 },
      }),
      columnHelper.accessor("celticsDOB", {
        cell: (info) => {
          const val = info.getValue();
          return val ? moment(val).format("YYYY-MM-DD") : "";
        },
        meta: { group: 7 },
      }),
      columnHelper.accessor("leagues", {
        header: () => "leagues_option",
        cell: (info) => (
          <div
            title={info.getValue() || ""}
            style={{
              maxWidth: 200,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {info.getValue()}
          </div>
        ),
        meta: { group: 8 },
      }),
      columnHelper.accessor((row) => row.celticsId, {
        id: "biaId",
        header: () => "celticsId",
        meta: { group: 9 },
      }),
    ],
    [checkedRows, maybes]
  );

  if (!maybes || pending) return <Spinner />;

  return (
    <div>
      {errMsg && (
        <Alert variant="danger">
          {`Something went wrong: ${errMsg}! Please contact chrisbu@celtics.com`}
        </Alert>
      )}
      <Button
        style={{ marginRight: 8 }}
        onClick={() => setShowMergeModal(true)}
        disabled={checkedRows.size == 0}
      >
        Insert Checked As Matches
      </Button>
      <Button
        onClick={() => setShowInsertModal(true)}
        disabled={checkedRows.size == 0}
      >
        Insert Checked Rows
      </Button>
      {insertModal}
      {mergeModal}
      <Table
        data={maybes}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        virtualScroll={true}
        autoWidth={true}
        showRowIndex={false}
      />
    </div>
  );
}
