import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  useNavigate,
  useLocation,
  Location,
} from "react-router-dom";
import LogRocket from "logrocket";
import App from "./client/App";
import reportWebVitals from "./client/reportWebVitals";
import { QueryParamProvider } from "use-query-params";

import "bootstrap/dist/css/bootstrap.min.css";
import "./client/index.css";

// Hacky check to see if we are in production and should be init-ing LogRocket.
if (!window.location.href.includes("localhost")) {
  LogRocket.init("sdwigt/bia", {
    network: {
      requestSanitizer: (request) => {
        // For login requests scrub the password.
        if (
          request.url.toLowerCase().indexOf("api/login") !== -1 &&
          request.body
        ) {
          const redactedRequest = JSON.parse(request.body);
          redactedRequest["password"] = "REDACTED";
          request.body = JSON.stringify(redactedRequest);
        }

        return request;
      },
    },
  });
}

/**
 * Adapter from the use-query-params repo to make it work with react router 6.
 */
const RouteAdapter = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location: Location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: Location) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );
  return children({ history: adaptedHistory, location });
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={RouteAdapter}>
        <App />
      </QueryParamProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
