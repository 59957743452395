import React, { useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/styles";
import cx from "classnames";

import { TeamSchedule } from "../../../shared/routers/TeamRouter";
import { timeFormat, dateFormatShort, pctFormat } from "../../util/Format";
import { trpc } from "../../util/tRPC";
import { UserPreferenceContext } from "../../UserContext";
import { WinProbBar } from "./WinProbBar";

// Number of previous / upcoming games to show.
const NUM_OF_GAMES = 5;

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      textAlign: "left",
      padding: 6,
      borderRadius: 4,
      marginRight: 8,
      marginBottom: 0,
      display: "inline-block",
      verticalAlign: "top",
    },
    nextContainer: {
      marginBottom: 0,
      marginRight: 0,
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      "& td": {
        padding: "1px 2px 0",
        fontSize: ".9em",
      },
    },
    barLabel: {
      color: "#8B8F99",
      textAlign: "center",
    },
    dateCell: {
      textAlign: "left",
      width: 40,
    },
    homeAwayCell: {
      textAlign: "center",
      width: 23,
    },
    oppCell: {
      textAlign: "left",
      width: 35,
    },
    wonLostCell: {
      textAlign: "center",
      width: 35,
    },
    teamScoreCell: {
      textAlign: "right",
      width: 28,
      "&.won": {
        fontWeight: 600,
      },
    },
    oppScoreCell: {
      textAlign: "left",
      width: 28,
      "&.won": {
        fontWeight: 600,
      },
    },
    xWinProbCell: {
      width: 40,
      textAlign: "right",
    },
    pointDifferentialCell: {
      height: 12,
      "& svg": {
        height: 12,
      },
    },
    timeCell: {
      paddingLeft: 12,
      textAlign: "right",
      width: 66,
    },
  })
);

export function TeamLastNextNba(props: {
  teamId: number;
  schedule: TeamSchedule[];
}) {
  const blur = !!useContext(UserPreferenceContext)["Logo Blur"];

  const classes = useStyles();
  const { schedule, teamId } = props;

  const { data: seriesForSeason } = trpc.series.getSeriesForSeason.useQuery({
    teamId,
  });

  // The last 5 games.
  const last = schedule
    .filter((s) => moment().isAfter(s.gameDateTime, "day"))
    .slice(-NUM_OF_GAMES);

  // The next 5 games.
  const scheduleNext = schedule
    .filter((s) => moment().isSameOrBefore(s.gameDateTime, "day"))
    .slice(0, NUM_OF_GAMES);

  const nextGames =
    scheduleNext.length === 0 ? null : (
      <div className={cx(classes.container, classes.nextContainer)}>
        <table className={classes.table}>
          <tbody style={{ whiteSpace: "nowrap" }}>
            {scheduleNext.map((game, i) => {
              const isHome = game.homeTeamId === teamId;
              const opp = isHome
                ? game.awayTeamAbbreviation
                : game.homeTeamAbbreviation;
              const oppTeamId = isHome ? game.awayTeamId : game.homeTeamId;
              return (
                <tr key={i}>
                  <td className={classes.dateCell}>
                    {dateFormatShort(moment(game.gameDateTime).toDate())}
                  </td>
                  <td className={classes.homeAwayCell}>
                    <Link to={`/preview/${game.gameid}`}>
                      {isHome ? "vs." : "@"}
                    </Link>
                  </td>
                  <td className={classes.oppCell}>
                    <Link to={`/team/${oppTeamId}`}>{opp}</Link>
                  </td>
                  <td className={classes.timeCell}>
                    {timeFormat(moment(game.gameDateTime).toDate())}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className={classes.container}>
          <table className={classes.table}>
            <tbody>
              {last.map((game, i) => {
                const gameId = game.gameid;
                const isHome = game.homeTeamId === teamId;
                const opp = isHome
                  ? game.awayTeamAbbreviation
                  : game.homeTeamAbbreviation;
                const incompleteGame = game.winningteamid === null;
                const wonGame = game.winningteamid === teamId;
                const teamScore = isHome ? game.homescore : game.awayScore;
                const oppScore = isHome ? game.awayScore : game.homescore;
                const teamWinProb = isHome
                  ? game.xWinPctHome
                  : game.xWinPctAway;
                const oppTeamId = isHome ? game.awayTeamId : game.homeTeamId;

                const chartWidth = 120;

                return (
                  <tr key={i}>
                    <td className={classes.dateCell}>
                      {dateFormatShort(moment(game.gameDateTime).toDate())}
                    </td>
                    <td className={classes.homeAwayCell}>
                      {isHome ? "vs." : "@"}
                    </td>
                    <td className={classes.oppCell}>
                      <Link to={`/team/${oppTeamId}`}>{opp}</Link>
                    </td>
                    <td className={classes.wonLostCell}>
                      <Link title={"Go to game page"} to={`/game/${gameId}`}>
                        {incompleteGame ? "-" : wonGame ? "W" : "L"}
                      </Link>
                    </td>
                    <td className={cx(classes.teamScoreCell, { won: wonGame })}>
                      {incompleteGame ? "" : teamScore}
                    </td>
                    <td>{incompleteGame ? "" : "-"}</td>
                    <td className={cx(classes.oppScoreCell, { won: !wonGame })}>
                      {incompleteGame ? "" : oppScore}
                    </td>
                    <td className={classes.xWinProbCell}>
                      {teamWinProb !== null ? pctFormat(teamWinProb, 0) : null}
                    </td>
                    <td
                      className={classes.pointDifferentialCell}
                      style={{
                        width: chartWidth,
                        filter: blur ? "url(#blur-and-scale)" : undefined,
                      }}
                    >
                      {teamWinProb !== null && (
                        <WinProbBar
                          winProb={teamWinProb}
                          chartWidth={chartWidth}
                        />
                      )}
                    </td>
                    <td style={{ width: 24, textAlign: "right" }}>|</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
                <td className={classes.barLabel}>xWin Probability</td>
                <td />
              </tr>
            </tfoot>
          </table>
        </div>
        {nextGames}
      </div>
      <div style={{ display: "flex", gap: 8 }}>
        {(seriesForSeason || [])
          .sort((a, b) => a.seriesId - b.seriesId)
          .map((s, i) => (
            <>
              <Link
                to={`/series/${s.seriesId}`}
                style={{ whiteSpace: "nowrap" }}
              >
                Round {i + 1} vs {s.oppTeamAbb}
              </Link>
              {i < (seriesForSeason || []).length - 1 && <span>•</span>}
            </>
          ))}
      </div>
    </>
  );
}
