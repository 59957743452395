import React, { ReactNode, useState } from "react";
import cx from "classnames";
import { createStyles, makeStyles } from "@material-ui/styles";
import { FaFilter } from "react-icons/fa";

import { Collapse } from "react-bootstrap";

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      border: 0,
      borderRadius: 2,
      boxShadow: "none",
      backgroundColor: "transparent",
      color: "#333",
      marginBottom: 20,
    },
    panelBody: {
      backgroundColor: "#fff",
      borderRadius: "0 0 2px 2px",
      borderBottom: 0,
    },
    fullPadding: {
      padding: 15,
    },
    noPadding: {
      padding: 0,
    },
    thinPadding: {
      padding: 4,
    },
    panelHeader: {
      backgroundColor: "#44465d",
      color: "#CAD9DE",
      fontWeight: 600,
      border: 0,
      textTransform: "uppercase",
      fontSize: ".9em",
      lineHeight: 1,
      borderRadius: 3,
      padding: "10px 15px",
    },
    hidden: {
      display: "none !important",
    },
    panelHeaderTabs: {
      margin: "-10px -15px",
    },
    panelHeaderTab: {
      display: "inline-block",
      padding: "10px 15px",
      borderRight: "1px solid rgba(0,0,0,.05)",
    },
    activeTab: {
      background: "#fff",
      cursor: "default",
      color: "#595D92",
    },
    filterIcon: {
      margin: "-10px 0 -10px -15px",
      padding: "9px 15px",
      display: "inline-block",
    },
  })
);

export interface PanelTabs {
  [key: string]: {
    label: string;
    content: JSX.Element;
    hidden?: boolean;
  };
}

export function Panel(props: {
  header?: string | JSX.Element;
  children?: ReactNode;
  tabs?: {
    onClick: (tabKey: string) => void;
    active: string;
    tabs: PanelTabs;
  };
  padding?: "full" | "none" | "thin";
  panelID?: string;
  defaultExpanded?: boolean;
  filtered?: boolean;
}) {
  const collapsedDefault =
    props.defaultExpanded === undefined ? false : !props.defaultExpanded;
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(collapsedDefault);

  const renderHeader = () => {
    const { header, tabs, filtered } = props;

    let headerInner: JSX.Element | string | undefined = header;

    // if Tabs provided, use them instead.
    if (tabs) {
      const tabClick = (key: string, evt: React.MouseEvent) => {
        evt.stopPropagation();
        tabs.onClick(key);
      };

      headerInner = (
        <div className={classes.panelHeaderTabs}>
          {Object.entries(tabs.tabs)
            .filter(([, tabValue]) => !tabValue.hidden)
            .map(([tabKey, tabValue]) => {
              const isActive = tabs.active === tabKey;

              return (
                <div
                  key={tabKey}
                  className={
                    classes.panelHeaderTab +
                    " " +
                    (isActive ? classes.activeTab : "")
                  }
                  onClick={(evt) => tabClick(tabKey, evt)}
                >
                  {tabValue.label}
                </div>
              );
            })}
        </div>
      );
    }

    const toggleCollapse = () => {
      setCollapsed(!collapsed);
    };

    return (
      <div onClick={toggleCollapse} className={classes.panelHeader}>
        {filtered && (
          <div className={classes.filterIcon}>
            <FaFilter />{" "}
          </div>
        )}
        {headerInner}
      </div>
    );
  };

  const renderBody = () => {
    const tabs = props.tabs;

    let children = props.children;
    if (props.children && typeof props.children === "string") {
      children = <div>{children}</div>;
    }

    let tabContent;
    // Render all tabs (but only have one visible) to retain state between tab
    // changes.
    if (tabs) {
      tabContent = Object.keys(tabs.tabs).map((tabKey) => {
        const isHidden = tabs.active !== tabKey;
        const tabAtKey = tabs.tabs[tabKey];
        if (!tabAtKey) return null;
        return (
          <div key={tabKey} className={isHidden ? classes.hidden : ""}>
            {tabAtKey.content}
          </div>
        );
      });
    }

    let paddingClass = classes.fullPadding;
    if (props.padding === "none") {
      paddingClass = classes.noPadding;
    } else if (props.padding === "thin") {
      paddingClass = classes.thinPadding;
    }

    return (
      <Collapse in={!collapsed}>
        <div className={cx(classes.panelBody, paddingClass)}>
          {tabContent}
          {children}
        </div>
      </Collapse>
    );
  };

  return (
    <div className={classes.panel} id={props.panelID}>
      {(props.header || props.tabs) && renderHeader()}
      {renderBody()}
    </div>
  );
}
