import React, { useContext } from "react";

import { Restrict } from "../core/Restrict";
import { PlayerStatusContext } from "../../PlayerStatusContext";
import { purple } from "../../constants/ColorConstants";
import { ordinalFormat } from "../../util/Format";
import { UserContext } from "../../UserContext";

export function PlayerTrendingMessage(props: { playerId: number }) {
  const { playerId } = props;

  const { trendingPlayers } = useContext(PlayerStatusContext);
  const user = useContext(UserContext);

  const isTrending = playerId && trendingPlayers[playerId];

  if (!isTrending) return null;

  return (
    <Restrict
      roles={[]}
      override={user && user.email === "chrisbu@celtics.com"}
    >
      <div style={{ color: purple }}>
        <b>{`${ordinalFormat(
          isTrending.rank
        )} most viewed (non Celtic) player in last 2 weeks`}</b>
      </div>
    </Restrict>
  );
}
