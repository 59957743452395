import React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/styles";
import moment from "moment";

import { TeamSchedule } from "../../../shared/routers/TeamRouter";
import { dateFormatShort, timeFormat, pctFormat } from "../../util/Format";
import { YellowHighlight } from "../../constants/ColorConstants";
import { TeamLogo } from "./TeamLogo";
import { WinProbBar } from "./WinProbBar";

const useStyles = makeStyles(() =>
  createStyles({
    scheduleTable: {
      width: "100%",
      maxWidth: "100%",
      marginBottom: 20,
    },
    dateCell: {
      textAlign: "left",
      paddingRight: 8,
      width: 40,
    },
    homeAwayCell: {
      textAlign: "center",
      width: 23,
    },
    oppCell: {
      textAlign: "left",
      width: 35,
    },
    wonLostCell: {
      textAlign: "center",
      paddingLeft: 10,
      paddingRight: 8,
      width: 35,
    },
    teamScoreCell: {
      textAlign: "right",
      paddingRight: 0,
      width: 28,
      "&.winner": {
        fontWeight: 600,
      },
    },
    prePostCell: {
      width: 45,
    },
    hyphenCell: {
      color: "#888",
      width: 6,
      paddingLeft: 3,
      paddingRight: 3,
    },
    oppScoreCell: {
      paddingLeft: 0,
      textAlign: "left",
      width: 28,
      "&.winner": {
        fontWeight: 600,
      },
    },
    pointDiffCell: {
      width: "100%",
    },
    pointDiffBar: {
      height: "0.5em",
      display: "inline-block",
      transition: "width .5s,background-color .5s",
    },
    timeCell: {
      paddingLeft: 12,
      textAlign: "right",
      width: 66,
    },
    highlight: {
      backgroundColor: `${YellowHighlight} !important`,
    },
    pointDifferentialCell: {
      height: 12,
      "& svg": {
        height: 12,
      },
      "& #endarrowloss": {
        fill: "#C73A51",
      },
      "& #endarrowwin": {
        fill: "#50BF3D",
      },
    },
    pointDifferentialBar: {
      "&.won": {
        stroke: "#50BF3D",
        backgroundColor: "#50BF3D",
      },
      "&.loss": {
        stroke: "#C73A51",
        backgroundColor: "#C73A51",
      },
    },
  })
);

export function TeamScheduleTable(props: {
  data: TeamSchedule[];
  showNext?: boolean;
  highlightGameId?: number;
  teamId: number;
}) {
  const classes = useStyles();
  const { data, showNext, highlightGameId, teamId } = props;

  const games = data;
  let next = games
    ? games.filter((game) =>
        moment(game.gameDateTime).isSameOrAfter(new Date())
      )
    : [];
  const last = games
    ? games.filter((game) => moment(game.gameDateTime).isBefore(new Date()))
    : [];

  if (showNext === false) {
    next = [];
  }

  const stripeIdx = last.length % 2 === 0 ? 0 : 1;

  const nextGames =
    next.length === 0 ? null : (
      <tbody>
        {next.map((game, i) => {
          const gameId = game.gameid;
          const isHighlight = gameId === highlightGameId;
          const isHome = game.homeTeamId === teamId;
          const oppTeamId = isHome ? game.awayTeamId : game.homeTeamId;

          return (
            <tr
              key={i}
              className={cx({ [classes.highlight]: isHighlight })}
              style={{
                backgroundColor: i % 2 === stripeIdx ? "#f5f5f5" : "white",
              }}
            >
              <td className={classes.prePostCell} style={{ padding: 7 }} />
              <td className={classes.dateCell} style={{ padding: 7 }}>
                {dateFormatShort(moment(game.gameDateTime).toDate())}
              </td>
              <td className={classes.homeAwayCell} style={{ padding: 7 }}>
                {isHome ? "vs." : "@"}
              </td>
              <td className={classes.oppCell} style={{ padding: 7 }}>
                <Link to={`/team/${oppTeamId}`}>
                  <TeamLogo idsId={oppTeamId} size={24} />
                </Link>
              </td>
              <td className={classes.oppCell} style={{ padding: 7 }}>
                <Link to={`/team/${oppTeamId}`}>
                  {isHome
                    ? game.awayTeamAbbreviation
                    : game.homeTeamAbbreviation}
                </Link>
              </td>
              <td
                colSpan={4}
                className={classes.timeCell}
                style={{ padding: 7 }}
              >
                {timeFormat(moment(game.gameDateTime).toDate())}
              </td>
              <td />
              <td />
            </tr>
          );
        })}
      </tbody>
    );

  return (
    <div style={{ overflowX: "scroll" }}>
      <table className={cx(classes.scheduleTable)} style={{ width: "auto" }}>
        <thead>
          <tr>
            <th />
            <th />
            <th />
            <th />
            <th />
            <th />
            <th />
            <th />
            <th />
            <th style={{ textAlign: "center", fontWeight: "normal" }}>
              xWin Probability
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {last.map((game, i) => {
            const gameId = game.gameid;
            const isHighlight = gameId === highlightGameId;
            const isHome = game.homeTeamId === teamId;
            const incompleteGame = game.winningteamid === null;
            const wonGame = game.winningteamid === teamId;
            const teamScore = isHome ? game.homescore : game.awayScore;
            const oppScore = isHome ? game.awayScore : game.homescore;
            const oppTeamId = isHome ? game.awayTeamId : game.homeTeamId;

            let gameType;
            if (game.gametypeid === "X") {
              gameType = "Pre";
            } else if (game.gametypeid === "P") {
              gameType = "Post";
            }

            const chartWidth = 120;

            const teamWinProb = isHome ? game.xWinPctHome : game.xWinPctAway;

            return (
              <tr
                key={i}
                className={cx({
                  [classes.highlight]: isHighlight,
                })}
                style={{ backgroundColor: i % 2 === 0 ? "#f5f5f5" : "white" }}
              >
                <td className={classes.prePostCell} style={{ padding: 7 }}>
                  {gameType}
                </td>
                <td className={classes.dateCell} style={{ padding: 7 }}>
                  {dateFormatShort(moment(game.gameDateTime).toDate())}
                </td>
                <td className={classes.homeAwayCell} style={{ padding: 7 }}>
                  {isHome ? "vs." : "@"}
                </td>
                <td className={classes.oppCell} style={{ padding: 7 }}>
                  <Link to={`/team/${oppTeamId}`}>
                    <TeamLogo idsId={oppTeamId} size={24} />
                  </Link>
                </td>
                <td className={classes.oppCell} style={{ padding: 7 }}>
                  <Link to={`/team/${oppTeamId}`}>
                    {isHome
                      ? game.awayTeamAbbreviation
                      : game.homeTeamAbbreviation}
                  </Link>
                </td>
                <td className={classes.wonLostCell} style={{ padding: 7 }}>
                  <Link title={"Go to game page"} to={`/game/${gameId}`}>
                    {incompleteGame ? "-" : wonGame ? "W" : "L"}
                  </Link>
                </td>
                <td
                  className={cx(classes.teamScoreCell, { winner: wonGame })}
                  style={{ padding: 7 }}
                >
                  {incompleteGame ? "" : teamScore}
                </td>
                <td className={classes.hyphenCell}>
                  {incompleteGame ? "" : "-"}
                </td>
                <td
                  className={cx(classes.oppScoreCell, { winner: !wonGame })}
                  style={{ padding: 7 }}
                >
                  {incompleteGame ? "" : oppScore}
                </td>
                <td
                  className={classes.pointDifferentialCell}
                  style={{ width: chartWidth }}
                >
                  {teamWinProb !== null && (
                    <WinProbBar winProb={teamWinProb} chartWidth={chartWidth} />
                  )}
                </td>
                <td style={{ textAlign: "right" }}>
                  {teamWinProb === null ? null : pctFormat(teamWinProb, 0)}
                </td>
              </tr>
            );
          })}
        </tbody>
        {nextGames}
      </table>
    </div>
  );
}
