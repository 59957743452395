import {
  SecondSpectrumFreeThrow,
  SecondSpectrumRebound,
  SecondSpectrumShot,
} from "../../shared/routers/LiveGameRouter";

// Don't count "accounting" rebounds. Only rebounds that actually have an
// ability to be rebounded should be counted.
export function realReboundOpp(
  rebound: SecondSpectrumRebound,
  freeThrowMap: Map<number, SecondSpectrumFreeThrow>
) {
  // See if there was a FT event right before this rebound event using play by
  // play id.
  const rebFromFt =
    // TODO(chrisbu): Fix when SS starts giving us pbpIds again.
    rebound.pbpId === null ? false : freeThrowMap.get(rebound.pbpId - 1);
  if (rebFromFt) {
    return (
      rebFromFt.fromFlagrant === false &&
      rebFromFt.fromTechnical === false &&
      rebFromFt.fromAwayFromPlay === false &&
      rebFromFt.seqNum === rebFromFt.seqTotal
    );
  }
  return rebound.gameClock > 0.1;
}

// Whether a shot attempt counts as an FGA.
export function isFga(shot: SecondSpectrumShot) {
  return !shot.fouled || shot.outcome;
}

// Instead of just using the pts + ft pts from a shot we want to use the
// expected free throw points from a shot to remove the FT luck from PPS
// calculations.
export function ppsForShot(shot: SecondSpectrumShot, xFTPct: number) {
  const outcomePts = shot.outcome ? (shot.three ? 3 : 2) : 0;

  if (shot.fouled) {
    if (shot.outcome) {
      return outcomePts + xFTPct;
    } else {
      return shot.three ? 3 * xFTPct : 2 * xFTPct;
    }
  }
  return outcomePts;
}
