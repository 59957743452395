import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import cx from "classnames";
import * as Format from "../../util/Format";
import {
  contractMyc,
  contractTo,
  contractPo,
  contractRfa,
  contractUfa,
} from "../../util/Colors";
import { TWO_WAY_CONTRACTS } from "../../constants/AppConstants";

const LONG_TYPES: { [key: string]: string } = {
  UFA: "Unrestricted Free Agent",
  RFA: "Restricted Free Agent",
  TO: "Team Option",
  PO: "Player Option",
  "2WY": "Two-Way Contract",
  EX10: "Exhibit 10",
  SUM: "Summer",
  MYC: "Multi-year Contract",
};

const useStyles = makeStyles(() =>
  createStyles({
    contractLabel: {
      textTransform: "uppercase",
      width: 36,
      display: "inline-block",
      padding: "0.2em 0.6em 0.3em",
      fontSize: "75%",
      fontWeight: 700,
      lineHeight: 1,
      color: "#fff",
      textAlign: "center",
      whiteSpace: "nowrap",
      verticalAlign: "baseline",
      borderRadius: "0.25em",
    },
    "contract-UFA": {
      backgroundColor: contractUfa,
    },
    "contract-RFA": {
      backgroundColor: contractRfa,
    },
    "contract-PO": {
      backgroundColor: contractPo,
    },
    "contract-TO": {
      backgroundColor: contractTo,
    },
    "contract-MYC": {
      backgroundColor: contractMyc,
      color: "#888",
    },
    "contract-EX10": {
      backgroundColor: contractMyc,
      color: "#888",
      border: "1px solid #edda07",
    },
    "contract-SUM": {
      backgroundColor: contractMyc,
      color: "#888",
      border: "1px solid #37e4d0",
    },
    "contract-2WY": {
      backgroundColor: contractMyc,
      color: "#888",
      border: "1px solid #F57D2F",
    },
    twoWay: {
      border: "1px solid #F57D2F",
    },
  })
);

/** Renders a simple inline label for a contract type. */
export function ContractLabel(props: {
  type: string;
  className?: string;
  status?: string;
}) {
  const classes = useStyles();
  const { className } = props;
  let { status, type } = props;

  if (!type) {
    return null;
  }

  status = status || "";

  // If no long type, it's a year, so treat it as a MYC and a number.
  let longType = LONG_TYPES[type];
  const label = type;
  if (!longType || TWO_WAY_CONTRACTS.includes(status)) {
    longType = `Contract ending ${Format.seasonString(type)}`;
    type = "MYC";
  }

  return (
    <span
      title={longType}
      className={cx(
        "label",
        `${TWO_WAY_CONTRACTS.includes(status) && classes.twoWay}`,
        classes.contractLabel,
        (classes as any)[`contract-${type}`],
        className
      )}
    >
      {label}
    </span>
  );
}
