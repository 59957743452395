import React, { ReactNode } from "react";
import { Col, Row } from "react-bootstrap";

import { TeamColorBox } from "../team/TeamColorBox";
import { StatComparisonTableBars } from "../table/StatComparisonTableBars";
import { GamePlayerReboundModel } from "../../../shared/routers/GameRouter";
import { decFormat, intFormat, makePlusMinus } from "../../util/Format";
import { groupBy } from "../../../shared/util/Collections";

export function GamePlayerReboundingLuck(props: {
  data: GamePlayerReboundModel[];
  home?: { teamid: number; teamCity: string };
  away?: { teamid: number; teamCity: string };
}) {
  const { data, home, away } = props;

  const homeTeamId = home ? home.teamid : 0;
  const homeTeamCity = home ? home.teamCity : "";
  const awayTeamId = away ? away.teamid : 0;
  const awayTeamCity = away ? away.teamCity : "";

  const additionalColumns = {
    expected: {
      width: 75,
      label: "Expected",
    },
    actual: {
      width: 75,
      label: "Actual",
    },
  };

  const compareColorTeam = (value: number, compare: number, key: string) => {
    const isHome = key === homeTeamId.toString();
    const team = isHome ? homeTeamId.toString() : awayTeamId.toString();
    const otherTeam = isHome ? awayTeamId.toString() : homeTeamId.toString();
    if (value >= compare) {
      return team;
    } else {
      return otherTeam;
    }
  };

  function getCompareFormat(compare: number, value: number) {
    const pmFormat = makePlusMinus(decFormat);
    return (
      <div>
        <div style={{ fontWeight: 600, lineHeight: 1, marginBottom: 2 }}>
          {pmFormat(value - compare)}
        </div>
        <div
          style={{ fontSize: "0.9em", opacity: 0.8 }}
        >{`Rebounds vs. Expected`}</div>
      </div>
    );
  }

  const compareFormat = {
    off: getCompareFormat,
    def: getCompareFormat,
  };

  const homeTeamRebounds = groupBy(
    data.filter((d) => d.team === homeTeamId),
    (d) => d.playerId.toString()
  );
  const awayTeamRebounds = groupBy(
    data.filter((d) => d.team === awayTeamId),
    (d) => d.playerId.toString()
  );
  const maxRebounds = Math.max(
    ...data.map((d) => Math.max(d.Expected, d.Actual))
  );
  const reboundDomain = [0, Math.max(12, maxRebounds)];

  interface Rebounder {
    label: string;
    values: { off: number; def: number };
    format: (val: number | null) => string;
    domain: number[];
    compare: Record<string, number>;
    compareColorTeam: (value: number, compare: number, key: string) => string;
    compareFormat?: Record<
      string,
      (compare: number, value: number) => ReactNode
    >;
    colorKey: string;
    additionalColumns: Record<
      string,
      {
        values: { off: number; def: number };
        format: (n: number | null) => string;
      }
    >;
  }

  // For each player either offense or defense will exist or else they would not
  // be returned.
  let homeGroup: Rebounder[] = [];
  Object.keys(homeTeamRebounds).map((player) => {
    const playerRebounds = homeTeamRebounds[player] || [];
    const offense = playerRebounds.filter((d) => d.offDef === "Off")[0];
    const defense = playerRebounds.filter((d) => d.offDef === "Def")[0];
    homeGroup.push({
      label: offense ? offense.player : defense ? defense.player : "",
      format: decFormat,
      domain: reboundDomain,
      values: {
        off: offense ? offense.Actual : 0,
        def: defense ? defense.Actual : 0,
      },
      compare: {
        off: offense ? offense.Expected : 0,
        def: defense ? defense.Expected : 0,
      },
      compareColorTeam,
      compareFormat,
      colorKey: homeTeamId.toString(),
      additionalColumns: {
        expected: {
          format: decFormat,
          values: {
            off: offense ? offense.Expected : 0,
            def: defense ? defense.Expected : 0,
          },
        },
        actual: {
          format: intFormat,
          values: {
            off: offense ? offense.Actual : 0,
            def: defense ? defense.Actual : 0,
          },
        },
      },
    });
  });

  let awayGroup: Rebounder[] = [];
  Object.keys(awayTeamRebounds).map((player) => {
    const playerRebounds = awayTeamRebounds[player] || [];
    const offense = playerRebounds.filter((d) => d.offDef === "Off")[0];
    const defense = playerRebounds.filter((d) => d.offDef === "Def")[0];
    awayGroup.push({
      label: offense ? offense.player : defense ? defense.player : "",
      format: decFormat,
      domain: reboundDomain,
      values: {
        off: offense ? offense.Actual : 0,
        def: defense ? defense.Actual : 0,
      },
      compare: {
        off: offense ? offense.Expected : 0,
        def: defense ? defense.Expected : 0,
      },
      compareColorTeam,
      compareFormat,
      colorKey: awayTeamId.toString(),
      additionalColumns: {
        expected: {
          format: decFormat,
          values: {
            off: offense ? offense.Expected : 0,
            def: defense ? defense.Expected : 0,
          },
        },
        actual: {
          format: intFormat,
          values: {
            off: offense ? offense.Actual : 0,
            def: defense ? defense.Actual : 0,
          },
        },
      },
    });
  });

  function compare(
    a: { values: { off: number; def: number } },
    b: { values: { off: number; def: number } }
  ) {
    if (a.values.off + a.values.def > b.values.off + b.values.def) return -1;
    if (a.values.off + a.values.def < b.values.off + b.values.def) return 1;
    return 0;
  }

  // Sort from most to least actual rebounds.
  homeGroup = homeGroup.sort(compare);
  // Sort from most to least actual rebounds.
  awayGroup = awayGroup.sort(compare);

  const colTitles = [
    <div key={homeTeamId}>
      <TeamColorBox teamId={homeTeamId} oppTeamId={awayTeamId} />
      {homeTeamCity} Rebounding
    </div>,
    <div key={awayTeamId}>
      <TeamColorBox teamId={awayTeamId} oppTeamId={homeTeamId} />
      {awayTeamCity} Rebounding
    </div>,
  ];

  return (
    <div>
      <Row>
        {[homeGroup, awayGroup].map((group, i) => {
          return (
            <Col lg={6} key={i}>
              {colTitles[i]}
              <StatComparisonTableBars
                labels={{
                  off: "Off.",
                  def: "Def.",
                }}
                data={group}
                teams={{
                  off: homeTeamId,
                  def: awayTeamId,
                }}
                groupColumnLabel={" "}
                valueColumnLabel="Actual"
                columnWidths={[100, 50, 55]}
                additionalColumns={additionalColumns}
                noBestColVal={true}
                hideValuesColumn={true}
                statColumnLabel="Player"
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
