import React, { useMemo, useState } from "react";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { PossessionAggregatePlayerStats } from "../../../shared/routers/PossessionRouter";
import {
  dec100Format,
  decFormat,
  decFormat2,
  fracDecFormat,
} from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { PlayerTableCell } from "../core/TableCell";

const columnHelper = createColumnHelper<PossessionAggregatePlayerStats>();

export function TeamWowBoxes(props: {
  data: PossessionAggregatePlayerStats[];
}) {
  const [sorting, setSorting] = useState<SortingState>();

  const data = props.data.sort((a, b) => b.min - a.min);

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.celticsId}
            name={info.getValue()}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("min", {
        header: () => "Min",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++, highlights: Highlights.Max },
      }),
      columnHelper.accessor((row) => row.pts * (48 / row.min), {
        id: "ptsPer48",
        header: () => "P/48",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g, colorDomain: [2.5, 33], highlights: Highlights.Max },
      }),
      columnHelper.accessor((row) => (row.orb + row.drb) * (48 / row.min), {
        id: "rebPer48",
        header: () => "R/48",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g, colorDomain: [0, 17], highlights: Highlights.Max },
      }),
      columnHelper.accessor((row) => row.ast * (48 / row.min), {
        id: "astPer48",
        header: () => "A/48",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g, colorDomain: [0, 13], highlights: Highlights.Max },
      }),
      columnHelper.accessor((row) => row.blk * (48 / row.min), {
        id: "blkPer48",
        header: () => "B/48",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g, colorDomain: [0, 4], highlights: Highlights.Max },
      }),
      columnHelper.accessor((row) => row.stl * (48 / row.min), {
        id: "stlPer48",
        header: () => "S/48",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g, colorDomain: [0, 3.3], highlights: Highlights.Max },
      }),
      columnHelper.accessor((row) => row.pf * (48 / row.min), {
        id: "pfPer48",
        header: () => "PF/48",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++, colorDomain: [7, 1], highlights: Highlights.Min },
      }),
      columnHelper.accessor(
        (row) => (row.orbOpp ? row.orb / row.orbOpp : null),
        {
          id: "orbPct",
          header: () => "OR%",
          cell: (info) => dec100Format(info.getValue()),
          meta: {
            group: g,
            colorDomain: [0, 0.15],
            highlights: Highlights.Max,
          },
        }
      ),
      columnHelper.accessor(
        (row) => (row.drbOpp ? row.drb / row.drbOpp : null),
        {
          id: "drbPct",
          header: () => "DR%",
          cell: (info) => dec100Format(info.getValue()),
          meta: {
            group: g,
            colorDomain: [0.05, 0.3],
            highlights: Highlights.Max,
          },
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.tov + row.fga2 + row.fga3 + row.possEndFt
            ? row.tov / (row.tov + row.fga2 + row.fga3 + row.possEndFt)
            : null,
        {
          id: "tovPct",
          header: () => "TOV%",
          cell: (info) => dec100Format(info.getValue()),
          meta: {
            group: g++,
            colorDomain: [0.2, 0.07],
            highlights: Highlights.Min,
          },
        }
      ),
      columnHelper.accessor((row) => (row.fga2 ? row.fgm2 / row.fga2 : null), {
        id: "fg2Pct",
        header: () => "2P%",
        cell: (info) =>
          fracDecFormat(45, {
            denominator: info.row.original.fga2,
            numerator: info.row.original.fgm2,
          }),
        meta: {
          group: g,
          colorDomain: [0.38, 0.63],
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor((row) => (row.fga3 ? row.fgm3 / row.fga3 : null), {
        id: "fg3Pct",
        header: () => "3P%",
        cell: (info) =>
          fracDecFormat(45, {
            denominator: info.row.original.fga3,
            numerator: info.row.original.fgm3,
          }),
        meta: {
          group: g,
          colorDomain: [0.25, 0.45],
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor((row) => row.fga3 * (48 / row.min), {
        id: "3paPer48",
        header: () => "3PA/48",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g, colorDomain: [0, 9.3], highlights: Highlights.Max },
      }),
      columnHelper.accessor((row) => (row.fta ? row.ftm / row.fta : null), {
        id: "ftPct",
        header: () => "FT%",
        cell: (info) =>
          fracDecFormat(20, {
            denominator: info.row.original.fta,
            numerator: info.row.original.ftm,
          }),
        meta: { group: g, colorDomain: [0.5, 0.9], highlights: Highlights.Max },
      }),
      columnHelper.accessor((row) => row.fta * (48 / row.min), {
        id: "ftaPer48",
        header: () => "FTA/48",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g++, colorDomain: [0, 8], highlights: Highlights.Max },
      }),
      columnHelper.accessor("xPPS", {
        header: () => "xPPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          colorDomain: [0.93, 1.28],
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("xPPSLg", {
        header: () => "xPPS Lg",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g++,
          colorDomain: [0.97, 1.25],
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor(
        (row) =>
          row.fga2 + row.fga3 + row.fta > 0
            ? row.pts / (2 * (row.fga2 + row.fga3 + 0.44 * row.fta))
            : null,
        {
          id: "tsPct",
          header: () => "TS%",
          cell: (info) =>
            info.getValue() === null ? "" : dec100Format(info.getValue()),
          meta: {
            group: g,
            colorDomain: [0.45, 0.65],
            highlights: Highlights.Max,
          },
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.fga2 + row.fga3 + row.possEndFt + row.tov
            ? row.pts / (row.fga2 + row.fga3 + row.possEndFt + row.tov)
            : null,
        {
          id: "ppp",
          header: () => "PPP",
          cell: (info) => decFormat2(info.getValue()),
          meta: {
            group: g,
            colorDomain: [0.7, 1.3],
            highlights: Highlights.Max,
          },
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.numChancesTeam === 0
            ? null
            : row.numChancesKBH / row.numChancesTeam,
        {
          id: "kbh",
          header: () => "KBH",
          cell: (info) => dec100Format(info.getValue()),
          meta: {
            group: g,
            colorDomain: [0.05, 0.25],
            highlights: Highlights.Max,
          },
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.fgaTeam + row.tovTeam + row.possEndFtTeam
            ? (100 * (row.fga2 + row.fga3 + row.tov + row.possEndFt)) /
              (row.fgaTeam + row.tovTeam + row.possEndFtTeam)
            : null,
        {
          id: "usg",
          header: () => "Usg",
          cell: (info) => decFormat(info.getValue()),
          meta: { group: g, colorDomain: [10, 30], highlights: Highlights.Max },
        }
      ),
    ];
  }, []);

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      showColorOnHover={true}
      autoWidth={true}
      rowBelowThreshold={(row) => row.min < 200}
    />
  );
}
