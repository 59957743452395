import React, { ChangeEvent, useEffect, useRef } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    container: { marginBottom: 10 },
    slider: {
      position: "relative",
      width: 200,
    },
    track: {
      position: "absolute",
      borderRadius: 3,
      height: 5,
      backgroundColor: "#ced4da",
      width: "100%",
    },
    range: {
      position: "absolute",
      borderRadius: 3,
      height: 5,
      backgroundColor: "#0c6cf9",
    },
    thumb: {
      zIndex: 1,
      pointerEvents: "none",
      position: "absolute",
      height: 0,
      width: 200,
      outline: "none",
      "&::-webkit-slider-thumb": {
        "-webkit-appearance": "none",
        "-webkit-tap-highlight-color": "transparent",
        backgroundColor: "#0c6cf9",
        border: "none",
        borderRadius: "50%",
        cursor: "pointer",
        height: 18,
        width: 18,
        marginTop: 4,
        pointerEvents: "all",
        position: "relative",
      },
      "&::-moz-range-thumb": {
        backgroundColor: "#0c6cf9",
        border: "none",
        borderRadius: "50%",
        cursor: "pointer",
        height: 18,
        width: 18,
        marginTop: 4,
        pointerEvents: "all",
        position: "relative",
      },
    },
  })
);

export function Slider(props: {
  numSteps?: number;
  value: { min: number; max: number };
  onChange: (val: { min: number; max: number }) => void;
}) {
  const classes = useStyles();
  const { onChange } = props;
  const minVal = props.value.min;
  const maxVal = props.value.max;
  // By default use 100 steps and act as just an integer % value but allow
  // overriding for steppers that need more granularity.
  const numSteps = props.numSteps || 100;
  const minValRef = useRef<HTMLInputElement>(null);
  const maxValRef = useRef<HTMLInputElement>(null);
  const range = useRef<HTMLDivElement>(null);

  // Update range when values change.
  useEffect(() => {
    if (range.current) {
      range.current.style.left = `${minVal * (100 / numSteps)}%`;
      range.current.style.width = `${(maxVal - minVal) * (100 / numSteps)}%`;
    }
  }, [minVal, maxVal, numSteps]);

  return (
    <div className={classes.container}>
      <input
        type="range"
        min={0}
        max={numSteps}
        value={minVal}
        ref={minValRef}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = Math.min(+event.target.value, maxVal);
          onChange({ min: value, max: maxVal });
        }}
        className={classes.thumb}
      />
      <input
        type="range"
        min={0}
        max={numSteps}
        value={maxVal}
        ref={maxValRef}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          // Hack to "unstick" yourself when both knobs are on the max end.
          // Normally the max slider would keep getting the focus and be unable
          // to move. To keep things simple just resest back to a good state if
          // user ever moves both to the max end and then tries to move one
          // again.
          if (minVal === maxVal && minVal === numSteps) {
            onChange({ min: 0, max: numSteps });
            return;
          }
          const value = Math.max(+event.target.value, minVal);
          onChange({ min: minVal, max: value });
        }}
        className={classes.thumb}
      />

      <div className={classes.slider}>
        <div className={classes.track}></div>
        <div ref={range} className={classes.range}></div>
      </div>
    </div>
  );
}
