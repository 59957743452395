import React, { useContext } from "react";
import moment from "moment";
import { FaBirthdayCake } from "react-icons/fa";
import { createStyles, makeStyles } from "@material-ui/styles";
import cx from "classnames";

import { Icon } from "../core/Icon";
import { PlayerPhoto } from "./PlayerPhoto";
import { PlayerStatusMessage } from "./PlayerStatusMessage";
import { PlayerInjuryMessage } from "./PlayerInjuryMessage";
import { PlayerTrendingMessage } from "./PlayerTrendingMessage";
import { PlayerHotColdMessage } from "./PlayerHotColdMessage";
import { trpc } from "../../util/tRPC";
import {
  footFormat,
  poundsFormat,
  dateFormat,
  decFormat,
  dec100Format,
  seFormat,
} from "../../util/Format";
import { Positions } from "../../constants/AppConstants";
import AppContext from "../../../shared/AppContext";
import { UserContext } from "../../UserContext";
import { groupBy } from "../../../shared/util/Collections";
import { BANNED_PLAYER_IDS } from "../../constants/BannedPlayers";
import { redHigh, green, purple } from "../../constants/ColorConstants";

const useStyles = makeStyles(() =>
  createStyles({
    pageHeader: {
      gridColumn: "1 / span 1",
      gridRow: "1 / span 1",
      marginBottom: 5,
    },
    playerBio: {
      padding: 8,
      display: "flex",
      gap: 8,
      minHeight: 160, // Photo height + 20px top/bottom margin.
    },
    playerSummary: {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      gridTemplateRows: "41px auto auto",
      gridAutoFlow: "column",
    },
    impactContainer: {
      borderRadius: 2,
      color: "white",
      gridColumn: "1 / span 1",
      gridRow: "2 / span 1",
    },
    playerBioDetails: {
      gridRow: "span 2",
    },
    noImpact: {
      padding: "0px 5px 5px 5px",
      marginottom: 0,
    },
    headerInlineList: {
      fontSize: "1.2em",
      marginBottom: 9,
      paddingLeft: 0,
      listStyle: "none",
      marginLeft: -5,
      "& li": {
        display: "inline-block",
        paddingLeft: 5,
        paddingRight: 5,
      },
      "& li::after": {
        content: "'•'",
        color: "black",
        marginLeft: 10,
      },
      "& li:last-child::after": {
        content: "''",
        margin: 0,
      },
    },
    rosterPopover: {
      padding: "9px 14px",
    },
    rosterPopoverTeamName: {
      padding: "5px 15px 7px",
      background: "#FFF",
      margin: "-5px -15px 8px",
      display: "block",
      fontWeight: 600,
      textAlign: "left",
      borderBottom: "1px solid #eee",
    },
    bioLine: {
      marginBottom: 4,
    },
    teamListIcon: {
      fontSize: ".9em",
      opacity: 0.8,
      marginRight: 2,
    },
    labelInHeader: {
      backgroundColor: "#44465d",
      color: "rgba(255,255,255,.75)",
      marginRight: 4,
      display: "inline",
      padding: "0.2em 0.6em 0.3em",
      fontSize: "75%",
      fontWeight: 700,
      lineHeight: 1,
      textAlign: "center",
      whiteSpace: "nowrap",
      verticalAlign: "baseline",
      borderRadius: "0.25em",
    },
  })
);

export function PlayerPreviewCard(props: { playerId: number }) {
  const { playerId } = props;
  const user = useContext(UserContext);
  const classes = useStyles();

  const { data: bioData } = trpc.player.getPlayerBios.useQuery({
    playerIds: [playerId.toString()],
  });

  const { data: draftModelData } = trpc.player.getPlayerDratModel.useQuery({
    playerId,
  });

  const { data: modeledMeasurementsData } =
    trpc.player.getPlayerModeledMeasurements.useQuery({
      playerIds: [playerId.toString()],
    });

  const { data: measurementsData } = trpc.player.getPlayerMeasurements.useQuery(
    {
      playerId,
    }
  );

  const { data: agents } = trpc.player.getPlayerAgents.useQuery({ playerId });

  if (
    !(bioData && draftModelData && modeledMeasurementsData && measurementsData)
  )
    return null;

  if (bioData.length === 0) return null;

  const bio = bioData[0];

  if (!bio) return null;

  const noImpact =
    (!bio.showOnTop ||
      (bio.season &&
        bio.season < Number.parseInt(AppContext.currentSeason) - 1)) &&
    bio.showOnTop !== 0;

  let position: string | number = Math.round(parseFloat(bio.position || ""));
  if (isNaN(position)) {
    position = "";
  } else {
    position = Positions[position] || "";
  }

  let birthDate;
  let isBirthday;
  if (bio.birthDate) {
    birthDate = dateFormat(moment(bio.birthDate).toDate());
    isBirthday = birthDate.slice(0, 5) === dateFormat(new Date()).slice(0, 5);
  }

  let teamItem;
  if (bio.teamid) {
    teamItem = <li>{bio.teamabbreviation}</li>;
  }

  const drafted = [];
  let showingEligibility = false;
  if (bio.draftPick == null) {
    if (bio.eligibility) {
      drafted.push(bio.eligibility);
      showingEligibility = true;
    } else if (bio.pastDraft) {
      drafted.push("Undrafted");
    }
  } else {
    drafted.push(<em key={0}>{"#" + bio.draftPick}</em>, " pick");
  }

  if (bio.draftYear) {
    drafted.push(" in ");
    drafted.push(<em key={2}>{bio.draftYear}</em>);
  }

  if (bio.school) {
    // Make the school string prettier when it has no space after comma.
    drafted.push(
      showingEligibility ? " at " : " from ",
      <em key={3}>{bio.school.split(",").join(", ")}</em>
    );
  } else if (bio.Hometown) {
    drafted.push(
      " from ",
      <em key={3}>{bio.Hometown + (bio.Country ? `, ${bio.Country}` : "")}</em>
    );
  }

  if (draftModelData && draftModelData.length) {
    const pctFormat = (d: number | null) =>
      d == null ? "--" : Math.round(d * 100) + "%";
    // Get most recent entry.
    const latestDraftModel = draftModelData.sort((a, b) => b.year - a.year)[0];
    const draftModelDetails = latestDraftModel ? (
      <span key={"draftModel"} className="draft-model-container">
        {latestDraftModel.off_pred && (
          <span className={classes.labelInHeader}>
            Off. <em>{decFormat(latestDraftModel.off_pred)}</em>
          </span>
        )}
        {latestDraftModel.def_pred && (
          <span className={classes.labelInHeader}>
            Def. <em>{decFormat(latestDraftModel.def_pred)}</em>
          </span>
        )}
        {latestDraftModel.net_pred && (
          <span className={classes.labelInHeader}>
            Net <em>{decFormat(latestDraftModel.net_pred)}</em>
          </span>
        )}
        <span className={classes.labelInHeader}>
          NBA <em>{pctFormat(latestDraftModel.pNba)}</em>
        </span>
      </span>
    ) : (
      ""
    );

    drafted.push(" ", draftModelDetails);
  }

  // Add in high school and hometown.
  const hometown = [];
  const home = [bio.Hometown, bio.State, bio.Country]
    .filter((h) => !!h)
    .join(", ");
  hometown.push("From ");
  if (bio.HSJC) {
    hometown.push(<em key={0}>{bio.HSJC}</em>);
    if (home.length) {
      hometown.push(" in ");
    }
  }
  if (home.length) {
    hometown.push(<em key={1}>{home}</em>);
  }

  let weight = bio.weight;
  if (measurementsData) {
    const weightMeasurements = measurementsData
      .filter((m) => m.weight !== null)
      .map((m) => m.weight);
    if (weightMeasurements.length) {
      weight = weightMeasurements[weightMeasurements.length - 1] || null;
    }
  }

  let height = "";
  let heightValue;
  const modeledMeasurements =
    modeledMeasurementsData && modeledMeasurementsData[0];
  if (modeledMeasurements && modeledMeasurements.heightWithShoes) {
    heightValue = Math.round(modeledMeasurements.heightWithShoes * 2) / 2;
  } else if (bio.height) {
    heightValue = bio.height;
  } else if (measurementsData) {
    // Find most recent heightWithShoes measurement that we have.
    heightValue = measurementsData
      .sort((a, b) => b.measurementDate - a.measurementDate)
      .find((m) => m.heightWithShoes && m.heightWithShoes > 0)?.heightWithShoes;
  }

  if (heightValue) {
    height = footFormat(heightValue);
  }

  let wingspan, standReach, wingspanDelta;

  if (measurementsData && modeledMeasurements) {
    const wingspanValue = modeledMeasurements.wingspan;

    wingspan = wingspanValue && footFormat(wingspanValue);

    // Use no shoes height for wingspan delta (as requested by DLew).
    let heightNoShoesValue = modeledMeasurements.heightNoShoes;

    if (!heightNoShoesValue && heightValue) {
      // As per Drew get no shoes height by minus -1.25.
      heightNoShoesValue = heightValue - 1.25;
    }

    if (heightNoShoesValue && wingspanValue) {
      // Calculate and format wingspan delta from height.
      wingspanDelta =
        Math.round(10 * (wingspanValue - heightNoShoesValue)) / 10;
      if (wingspanDelta > 0) {
        wingspanDelta = "+" + wingspanDelta;
      }
      wingspanDelta += '"';
    } else {
      wingspanDelta = "";
    }

    if (wingspanDelta !== "") {
      wingspanDelta = `(${wingspanDelta})`;
    }

    if (modeledMeasurements.standReach) {
      standReach = footFormat(modeledMeasurements.standReach);
    }
  }

  let measurementsList;
  if (wingspan || standReach) {
    let wingspanIsEstimate = false;
    if (modeledMeasurements && modeledMeasurements.whichAreEstimated) {
      wingspanIsEstimate =
        modeledMeasurements.whichAreEstimated
          .toLowerCase()
          .indexOf("wingspan") >= 0;
    }

    measurementsList = (
      <ul
        className={cx("list-inline", classes.headerInlineList)}
        style={{ marginTop: -9 }}
      >
        {wingspan && (
          <li>
            <Icon type="wingspan" />
            {`${wingspanIsEstimate ? "≈" : ""}${wingspan} ${wingspanDelta}`}
          </li>
        )}
        {standReach && (
          <li>
            <Icon type="standing-reach" />
            {standReach}
          </li>
        )}
      </ul>
    );
  }

  const agentInfo = [];
  // While data is loading show some placeholder text to prevent jumping.
  if (agents && agents.length) {
    agentInfo.push("Represented by ");

    const agentsByAgency = groupBy(agents, (a) => a.agencyName || "");
    for (let i = 0; i < Object.keys(agentsByAgency).length; i++) {
      const agencyName = Object.keys(agentsByAgency)[i];
      const agentsInAgency = (agencyName && agentsByAgency[agencyName]) || [];
      if (i > 0) {
        agentInfo.push(" and ");
      }
      for (let j = 0; j < agentsInAgency.length; j++) {
        if (j > 0) {
          if (j < agentsInAgency.length - 1) {
            agentInfo.push(", ");
          } else {
            agentInfo.push(" and ");
          }
        }
        const agent = agentsInAgency[j];
        const agentName = agent ? agent.agentName : "";
        agentInfo.push(<em key={agentName}>{agentName}</em>);
      }
      if (agencyName !== "") {
        agentInfo.push(" at ");
        agentInfo.push(<em key={agencyName}>{agencyName}</em>);
      }
    }
    // Fall back to what we get from PCMS if agent data finished loading
    // and there was nothing there.
  } else if (bio.agent || bio.agency) {
    agentInfo.push("Represented by ");
    if (bio.agent) {
      agentInfo.push(<em key={0}>{bio.agent}</em>);

      if (bio.agency) {
        agentInfo.push(" at ");
      }
    }
    if (bio.agency) {
      agentInfo.push(<em key={1}>{bio.agency}</em>);
    }
  }

  let mostRecentTeamStr = undefined;
  // Skip NBA players whose last game was for their NBA team.
  if (bio.lastGameTeamIdIds !== null && bio.lastGameTeamIdIds !== bio.teamid) {
    mostRecentTeamStr = (
      <div>
        Last played for <b>{bio.lastGameTeam}</b>
      </div>
    );
  }

  // Impact for non-NBA/G League guys is restricted to users with
  // bia/scout/executive roles.
  const userHasElevatedImpactPermissions = !!(
    user && ["bia", "executive", "scout"].some((r) => r === user.group)
  );
  const playerNeedsElevatedPermissionsToView = !bio.showOnTop;

  // Make sure we have at least one non-null impact number to use.
  const hasImpactToShow =
    bio.netImpact !== null ||
    bio.draft_model_net !== null ||
    bio.global_est_net !== null;

  const showImpact =
    hasImpactToShow &&
    (userHasElevatedImpactPermissions || !playerNeedsElevatedPermissionsToView);

  let impactDetails = undefined;
  if (showImpact) {
    // If player has played 250+ poss in NBA use their camera number.
    // Else if a player has draft eligibility OR has a draft model number for the
    // current season AND it is higher than their current global estimate use that.
    // Else if they have a global estimate use that.
    const hasNBAImpact = !!(bio.nPoss && bio.nPoss >= 250);
    const useDraftModel =
      // If still pre-draft just use draft model.
      bio.pastDraft === 0 ||
      (!hasNBAImpact &&
        bio.draft_model_net !== null &&
        bio.draft_model_net > (bio.global_est_net || 0));
    const useGlobalEstimate = !hasNBAImpact && bio.global_est_net !== null;
    let source = "Net Impact";
    let color: string | undefined = undefined;
    let netImpact = bio.netImpact;
    let offImpact = bio.offImpact;
    let defImpact = bio.defImpact;
    const netSE = (bio.netSE || 0) * 100 * 1.96;
    if (useDraftModel) {
      color = purple;
      offImpact = bio.draft_model_off;
      defImpact = bio.draft_model_def;
      netImpact = bio.draft_model_net;
      source = "Proj. Peak";
    } else if (useGlobalEstimate) {
      color = green;
      offImpact = bio.global_est_off;
      defImpact = bio.global_est_def;
      netImpact = bio.global_est_net;
      source = "Est. Impact";
    }

    impactDetails = (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 16,
            color: "white",
            backgroundColor: "#44465d",
            borderRadius: 4,
            padding: 8,
            paddingTop: 0,
            maxWidth: 180,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ fontWeight: "bold", fontSize: "large", color }}>
                {dec100Format(netImpact)}
              </span>
              {hasNBAImpact && (
                <span
                  style={{
                    fontWeight: "light",
                    fontSize: "small",
                  }}
                >
                  {seFormat(netSE)}
                </span>
              )}
            </div>
            <span
              style={{
                fontSize: "small",
                lineHeight: 0.5,
                opacity: 0.6,
              }}
            >
              {source}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ color }}>{dec100Format(offImpact)}</span>
            <span
              style={{
                fontSize: "small",
                lineHeight: 0.5,
                opacity: 0.6,
              }}
            >
              OFF
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ color }}>{dec100Format(defImpact)}</span>
            <span
              style={{
                fontSize: "small",
                lineHeight: 0.5,
                opacity: 0.6,
              }}
            >
              DEF
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.playerBio}>
      <div>
        <PlayerPhoto
          ids={bio.idIds || undefined}
          playerId={playerId}
          dxPhoto={bio.Headshot}
        />
      </div>
      <div className={classes.playerSummary}>
        <h2
          className={cx(classes.pageHeader, {
            [classes.noImpact]: noImpact,
          })}
        >
          {bio.fullName}
        </h2>
        <div
          className={cx(classes.playerBioDetails, {
            [classes.noImpact]: noImpact,
          })}
        >
          <ul className={cx("list-inline", classes.headerInlineList)}>
            {bio.position && <li>{position}</li>}
            {bio.teamid && teamItem}
            {bio.age && birthDate && (
              <li>
                {decFormat(bio.age) + "yo"} {isBirthday && <FaBirthdayCake />}
                {bio.listedAge !== null &&
                  `(Listed: ${decFormat(bio.listedAge) + "yo"})`}
              </li>
            )}
            {height && <li>{height}</li>}
            {weight && <li>{poundsFormat(weight)}</li>}
            {bio.eligibility && <li>{bio.eligibility}</li>}
          </ul>
          {measurementsList}
          {!!drafted.length && <p className={classes.bioLine}>{drafted}</p>}
          {impactDetails}
          {!!agentInfo.length && <p className={classes.bioLine}>{agentInfo}</p>}
          {hometown.length > 1 && <p className={classes.bioLine}>{hometown}</p>}
          <PlayerStatusMessage bio={bio} />
          {BANNED_PLAYER_IDS.has(playerId) ? (
            <b style={{ color: redHigh }}>Banned for life</b>
          ) : (
            <>
              {mostRecentTeamStr}
              <PlayerInjuryMessage playerId={playerId} />
              <PlayerHotColdMessage playerId={playerId} />
              <PlayerTrendingMessage playerId={playerId} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
