import { DataTypes } from "../../constants/AppConstants";
import {
  poundsFormat,
  decFormat,
  decFormat2,
  footFormat,
  plusMinusFormat,
} from "../../util/Format";

export const AllPlayersTableColumns: Record<string, any> = {
  /* Measurements ***********************************************************/
  weight: {
    type: DataTypes.Number,
    renderer: poundsFormat,
    label: "Weight",
  },

  bodyFat: {
    type: DataTypes.Number,
    renderer: decFormat,
    label: "BF%",
  },

  heightWithShoes: {
    type: DataTypes.Number,
    label: "Ht Shoes",
    renderer: footFormat,
    sortValRender: footFormat,
    className: "feet-inches-data",
  },

  heightNoShoes: {
    type: DataTypes.Number,
    label: "Ht No Shoes",
    renderer: footFormat,
    sortValRender: footFormat,
    className: "feet-inches-data",
  },

  wingspan: {
    type: DataTypes.Number,
    label: "Wingspan",
    renderer: footFormat,
    className: "feet-inches-data",
  },

  wingspanDelta: {
    type: DataTypes.Number,
    label: "∆ Wingspan",
    renderer: plusMinusFormat,
    className: "feet-inches-data",
  },

  standReach: {
    type: DataTypes.Number,
    label: "Reach",
    renderer: footFormat,
    className: "feet-inches-data",
  },

  verticalJumpOneStep: {
    type: DataTypes.Number,
    renderer: decFormat,
    label: "Vert Max",
  },

  verticalJumpNoStep: {
    type: DataTypes.Number,
    renderer: decFormat,
    label: "Vert No Step",
  },

  handLength: {
    type: DataTypes.Number,
    renderer: decFormat2,
    label: "Hand L",
  },

  handWidth: {
    type: DataTypes.Number,
    renderer: decFormat2,
    label: "Hand W",
  },

  benchPress: {
    type: DataTypes.Number,
    label: "Bench",
  },

  laneAgility: {
    type: DataTypes.Number,
    renderer: decFormat2,
    label: "Lane Agi",
  },

  run34: {
    type: DataTypes.Number,
    renderer: decFormat2,
    label: "3/4",
  },
};
