import { MutableRefObject, useEffect, useRef, useState } from "react";

/**
 * Custom event listsener hook inspired by:
 * https://usehooks.com/useEventListener/.
 */
export function useEventListener<T>(
  eventName: string,
  handler: (evt: T) => void,
  elementRef?: MutableRefObject<HTMLElement | null>
) {
  // Create a ref that stores handler
  const savedHandler = useRef<(evt: T) => void>();
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(
    () => {
      const element = elementRef ? elementRef.current : window;
      // Make sure element supports addEventListener.
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      // Create event listener that calls handler function stored in ref.
      const eventListener = (event: Event) =>
        savedHandler.current && savedHandler.current(event as T);
      // Add event listener.
      element.addEventListener(eventName, eventListener);
      // Remove event listener on cleanup.
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, elementRef] // Re-run if eventName or element changes.
  );
}

// Hook from https://usehooks.com/useWindowSize/.
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    height: number;
    width: number;
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value.
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay.
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is
      // changed within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes.
  );
  return debouncedValue;
}

export function useHover(ref: MutableRefObject<HTMLElement | null>) {
  const [value, setValue] = useState(false);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref] // Recall only if ref changes.
  );
  return value;
}
