import React, { MouseEvent } from "react";
import { FaVideo } from "react-icons/fa";
import { createStyles, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      opacity: 0.5,
      cursor: "pointer",
      "&:hover": {
        opacity: 1,
      },
    },
  })
);

export function VideoControl<T>(props: {
  onVideo: (data: T) => void;
  data: T;
}) {
  const classes = useStyles();
  const { onVideo, data } = props;

  const handleVideo = (evt: MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (onVideo) {
      onVideo(data);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <FaVideo
        className={classes.icon}
        title={`Watch Clips`}
        onClick={handleVideo}
      />
    </div>
  );
}
