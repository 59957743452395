import React, { useMemo, useState } from "react";

import { Highlights } from "../../constants/AppConstants";
import {
  minutesFormat,
  decFormat2,
  dec100Format,
  pctFormat,
  intFormat,
  fractionFormat,
} from "../../util/Format";
import { PlayerTableCell } from "../core/TableCell";
import { createColumnHelper, Table, SortingState } from "../core/Table";
import { perGame } from "../../constants/ColorDomains";

export interface LiveGamePlayerStatsRow {
  playerId: number;
  playerName: string;
  homeAway: string;
  pts: number;
  xPts: number;
  ptsWithxFt: number;
  shots: number;
  fga: number;
  "2pa": number;
  "2pm": number;
  layupA: number;
  layupM: number;
  nl2A: number;
  nl2M: number;
  "3pa": number;
  "3pm": number;
  fta: number;
  ftm: number;
  crashes: number;
  crashOpps: number;
  min: number | undefined;
}

const shotsColumnHelper = createColumnHelper<LiveGamePlayerStatsRow>();

export function LiveGameStatsTable(props: { data: LiveGamePlayerStatsRow[] }) {
  const { data } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    return [
      shotsColumnHelper.accessor("playerName", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.playerId}
          />
        ),
        footer: () => "Team Total",
      }),
      shotsColumnHelper.accessor("min", {
        header: () => "Min",
        cell: (info) => {
          const val = info.getValue();
          return val ? minutesFormat(val / 60) : null;
        },
        meta: { highlights: Highlights.Max },
      }),
      shotsColumnHelper.accessor("pts", {
        header: () => "Pts",
        meta: { highlights: Highlights.Max, colorDomain: perGame.pts },
        footer: () => data.reduce((a, b) => a + b.pts, 0),
      }),
      shotsColumnHelper.accessor("shots", {
        header: () => "Shots",
        cell: (info) => intFormat(info.getValue()),
        meta: { highlights: Highlights.Max, colorDomain: perGame.fga },
        footer: () => data.reduce((a, b) => a + b.shots, 0),
      }),
      shotsColumnHelper.accessor(
        (row) => (row.shots > 0 ? row.xPts / row.shots : null),
        {
          id: "xpps",
          header: () => "xPPS",
          cell: (info) => decFormat2(info.getValue()),
          meta: {
            highlights: Highlights.Max,
            colorDomain: perGame.ppp,
            heatmap: true,
          },
          footer: () => {
            const totalShots = data.reduce((a, b) => a + b.shots, 0);
            return totalShots > 0
              ? decFormat2(data.reduce((a, b) => a + b.xPts, 0) / totalShots)
              : null;
          },
        }
      ),
      shotsColumnHelper.accessor(
        (row) => (row.shots > 0 ? row.ptsWithxFt / row.shots : null),
        {
          id: "pps",
          header: () => "PPS",
          cell: (info) => decFormat2(info.getValue()),
          meta: { highlights: Highlights.Max, colorDomain: perGame.ppp },
          footer: () => {
            const totalShots = data.reduce((a, b) => a + b.shots, 0);
            return totalShots > 0
              ? decFormat2(
                  data.reduce((a, b) => a + b.ptsWithxFt, 0) / totalShots
                )
              : null;
          },
        }
      ),
      shotsColumnHelper.accessor(
        (row) =>
          row["fga"] > 0 ? (row["2pm"] + 1.5 * row["3pm"]) / row["fga"] : null,
        {
          id: "efgPct",
          header: () => "eFG%",
          cell: (info) => pctFormat(info.getValue()),
          meta: { highlights: Highlights.Max, colorDomain: perGame.efg },
          footer: () => {
            const fga = data.reduce((a, b) => a + b.fga, 0);
            return fga > 0
              ? dec100Format(
                  data.reduce((a, b) => a + b["2pm"] + 1.5 * b["3pm"], 0) / fga
                )
              : null;
          },
        }
      ),
      shotsColumnHelper.accessor(
        (row) => (row["layupA"] ? row["layupM"] / row["layupA"] : null),
        {
          id: "layupPct",
          header: () => "Layups",
          cell: (info) =>
            fractionFormat({
              denominator: info.row.original["layupA"],
              numerator: info.row.original["layupM"],
            }),
          footer: () => {
            const layupA = data.reduce((a, b) => a + b["layupA"], 0);
            const layupM = data.reduce((a, b) => a + b["layupM"], 0);
            return fractionFormat({
              denominator: layupA,
              numerator: layupM,
            });
          },
          meta: { highlights: Highlights.Max, colorDomain: perGame["2pct"] },
        }
      ),
      shotsColumnHelper.accessor(
        (row) => (row["nl2A"] ? row["nl2M"] / row["nl2A"] : null),
        {
          id: "nl2Pct",
          header: () => "NL2s",
          cell: (info) =>
            fractionFormat({
              denominator: info.row.original["nl2A"],
              numerator: info.row.original["nl2M"],
            }),
          footer: () => {
            const nl2A = data.reduce((a, b) => a + b["nl2A"], 0);
            const nl2M = data.reduce((a, b) => a + b["nl2M"], 0);
            return fractionFormat({
              denominator: nl2A,
              numerator: nl2M,
            });
          },
          meta: { highlights: Highlights.Max, colorDomain: perGame["2pct"] },
        }
      ),
      shotsColumnHelper.accessor(
        (row) => (row["3pa"] ? row["3pm"] / row["3pa"] : null),
        {
          id: "3ptPct",
          header: () => "Threes",
          cell: (info) =>
            fractionFormat({
              denominator: info.row.original["3pa"],
              numerator: info.row.original["3pm"],
            }),
          footer: () => {
            const a = data.reduce((a, b) => a + b["3pa"], 0);
            const m = data.reduce((a, b) => a + b["3pm"], 0);
            return fractionFormat({
              denominator: a,
              numerator: m,
            });
          },
          meta: { highlights: Highlights.Max, colorDomain: perGame["3pct"] },
        }
      ),
      shotsColumnHelper.accessor(
        (row) => (row["fta"] ? row["ftm"] / row["fta"] : null),
        {
          id: "ftPct",
          header: () => "FTs",
          cell: (info) =>
            fractionFormat({
              denominator: info.row.original["fta"],
              numerator: info.row.original["ftm"],
            }),
          footer: () => {
            const fta = data.reduce((a, b) => a + b["fta"], 0);
            const ftm = data.reduce((a, b) => a + b["ftm"], 0);
            return fractionFormat({
              denominator: fta,
              numerator: ftm,
            });
          },
          meta: { highlights: Highlights.Max, colorDomain: perGame.ftpct },
        }
      ),
      shotsColumnHelper.accessor(
        (row) => (row["crashOpps"] ? row["crashes"] / row["crashOpps"] : null),
        {
          id: "crashRate",
          header: () => "Crash%",
          cell: (info) => pctFormat(info.getValue()),
          meta: { highlights: Highlights.Max, colorDomain: perGame.crashRate },
          footer: () => {
            const crashOpps = data.reduce((a, b) => a + b["crashOpps"], 0);
            return crashOpps > 0
              ? pctFormat(
                  data.reduce((a, b) => a + b["crashes"], 0) / crashOpps
                )
              : null;
          },
        }
      ),
    ];
  }, [data]);

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
      showRowIndex={false}
      showColorOnHover={true}
    />
  );
}
