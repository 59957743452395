import React, { useMemo, useState } from "react";

import { Table, createColumnHelper, SortingState } from "../core/Table";
import {
  SecondSpectrumPossession,
  SecondSpectrumShot,
  SecondSpectrumTurnover,
  SecondSpectrumFreeThrow,
  SecondSpectrumRebound,
} from "../../../shared/routers/LiveGameRouter";
import { trpc } from "../../util/tRPC";
import {
  percentileFormat,
  decFormat2,
  pctFormat,
  dec100Format,
} from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { realReboundOpp, isFga } from "../../util/LivePageUtil";

interface FourFactorsRow {
  team: string;
  ppp: number;
  efg: number;
  tov: number;
  orb: number;
  ftr: number;
}

const fourFactorColumnHelper = createColumnHelper<FourFactorsRow>();

export function LiveGameFourFactorsTable(props: {
  homeTeamId: string;
  awayTeamId: string;
  homeTeam: string;
  awayTeam: string;
  possessions: SecondSpectrumPossession[];
  shots: SecondSpectrumShot[];
  freeThrows: SecondSpectrumFreeThrow[];
  turnovers: SecondSpectrumTurnover[];
  rebounds: SecondSpectrumRebound[];
}) {
  const {
    possessions,
    shots,
    freeThrows,
    turnovers,
    rebounds,
    homeTeamId,
    awayTeamId,
    homeTeam,
    awayTeam,
  } = props;

  const [sorting, setSorting] = useState<SortingState>();

  const { data: fourFactorsComparisonGames } =
    trpc.liveGame.getFourFactorsComparisonGames.useQuery();

  const ppps = (fourFactorsComparisonGames || [])
    .map((d) => d.ppp)
    .sort((a, b) => a - b);
  const efgs = (fourFactorsComparisonGames || [])
    .map((d) => d.efg)
    .sort((a, b) => a - b);
  const tovRates = (fourFactorsComparisonGames || [])
    .map((d) => d.tovRate)
    .sort((a, b) => b - a);
  const orbRates = (fourFactorsComparisonGames || [])
    .map((d) => d.orbRate)
    .sort((a, b) => a - b);
  const ftRates = (fourFactorsComparisonGames || [])
    .map((d) => d.ftRate)
    .sort((a, b) => a - b);

  const freeThrowMap = useMemo(() => {
    return new Map(freeThrows.map((ft) => [ft.pbpId, ft]));
  }, [freeThrows]);

  const homePossessions = useMemo(
    () => possessions.filter((p) => p.offTeamId === homeTeamId),
    [possessions, homeTeamId]
  );
  const awayPossessions = useMemo(
    () => possessions.filter((p) => p.offTeamId === awayTeamId),
    [possessions, awayTeamId]
  );

  const homeShots = useMemo(
    () => shots.filter((s) => s.offTeamId === homeTeamId),
    [shots, homeTeamId]
  );

  const awayShots = useMemo(
    () => shots.filter((s) => s.offTeamId === awayTeamId),
    [shots, awayTeamId]
  );

  const homeFts = useMemo(
    () => freeThrows.filter((ft) => ft.offTeamId === homeTeamId),
    [freeThrows, homeTeamId]
  );

  const awayFts = useMemo(
    () => freeThrows.filter((ft) => ft.offTeamId === awayTeamId),
    [freeThrows, awayTeamId]
  );

  const homeTovs = useMemo(
    () => turnovers.filter((t) => t.offTeamId === homeTeamId),
    [turnovers, homeTeamId]
  );

  const awayTovs = useMemo(
    () => turnovers.filter((t) => t.offTeamId === awayTeamId),
    [turnovers, awayTeamId]
  );

  const homeRebounds = useMemo(
    () =>
      rebounds.filter(
        (r) => r.teamId === homeTeamId && realReboundOpp(r, freeThrowMap)
      ),
    [rebounds, homeTeamId]
  );

  const awayRebounds = useMemo(
    () =>
      rebounds.filter(
        (r) => r.teamId === awayTeamId && realReboundOpp(r, freeThrowMap)
      ),
    [rebounds, awayTeamId]
  );

  const data = [
    {
      team: homeTeam,
      ppp:
        (homeShots.reduce(
          (a, b) => a + (b.outcome ? (b.three ? 3 : 2) : 0),
          0
        ) +
          homeFts.filter((ft) => ft.outcome).length) /
        homePossessions.length,
      efg:
        homeShots.reduce(
          (a, b) => a + (b.outcome ? (b.three ? 1.5 : 1) : 0),
          0
        ) / homeShots.filter(isFga).length,
      tov: homeTovs.length / homePossessions.length,
      orb:
        homeRebounds.filter((r) => !r.defensive).length /
        (homeRebounds.filter((r) => !r.defensive).length +
          awayRebounds.filter((r) => r.defensive).length),
      ftr: homeFts.length / homeShots.filter(isFga).length,
    },
    {
      team: awayTeam,
      ppp:
        (awayShots.reduce(
          (a, b) => a + (b.outcome ? (b.three ? 3 : 2) : 0),
          0
        ) +
          awayFts.filter((ft) => ft.outcome).length) /
        awayPossessions.length,
      efg:
        awayShots.reduce(
          (a, b) => a + (b.outcome ? (b.three ? 1.5 : 1) : 0),
          0
        ) / awayShots.filter(isFga).length,
      tov: awayTovs.length / awayPossessions.length,
      orb:
        awayRebounds.filter((r) => !r.defensive).length /
        (awayRebounds.filter((r) => !r.defensive).length +
          homeRebounds.filter((r) => r.defensive).length),
      ftr: awayFts.length / awayShots.filter(isFga).length,
    },
  ];

  const columns = useMemo(() => {
    return [
      fourFactorColumnHelper.accessor("team", {
        header: () => "Team",
      }),
      fourFactorColumnHelper.accessor((row) => getPercentile(row.ppp, ppps), {
        id: "pppPercentile",
        header: () => "",
        cell: (info) => percentileFormat(info.getValue()),
        meta: {
          colorDomain: [0, 1],
          heatmap: true,
        },
      }),
      fourFactorColumnHelper.accessor("ppp", {
        header: () => "PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          highlights: Highlights.Max,
        },
      }),
      fourFactorColumnHelper.accessor((row) => getPercentile(row.efg, efgs), {
        id: "efgPercentile",
        header: () => "",
        cell: (info) => percentileFormat(info.getValue()),
        meta: {
          colorDomain: [0, 1],
          heatmap: true,
        },
      }),
      fourFactorColumnHelper.accessor("efg", {
        header: () => "eFG%",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          highlights: Highlights.Max,
        },
      }),
      fourFactorColumnHelper.accessor(
        (row) => getPercentile(row.tov, tovRates, true),
        {
          id: "tovPercentile",
          header: () => "",
          cell: (info) => percentileFormat(info.getValue()),
          meta: {
            colorDomain: [0, 1],
            heatmap: true,
          },
        }
      ),
      fourFactorColumnHelper.accessor("tov", {
        header: () => "TOV%",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          highlights: Highlights.Min,
        },
      }),
      fourFactorColumnHelper.accessor(
        (row) => getPercentile(row.orb, orbRates),
        {
          id: "orbPercentile",
          header: () => "",
          cell: (info) => percentileFormat(info.getValue()),
          meta: {
            colorDomain: [0, 1],
            heatmap: true,
          },
        }
      ),
      fourFactorColumnHelper.accessor("orb", {
        header: () => "OREB%",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          highlights: Highlights.Max,
        },
      }),
      fourFactorColumnHelper.accessor(
        (row) => getPercentile(row.ftr, ftRates),
        {
          id: "ftrPercentile",
          header: () => "",
          cell: (info) => percentileFormat(info.getValue()),
          meta: {
            colorDomain: [0, 1],
            heatmap: true,
          },
        }
      ),
      fourFactorColumnHelper.accessor("ftr", {
        header: () => "FT Rate",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
        },
      }),
    ];
  }, [efgs, ftRates, orbRates, tovRates]);

  return (
    <Table
      data={data}
      columns={columns}
      autoWidth={true}
      showRowIndex={false}
      sorting={sorting}
      setSorting={setSorting}
    />
  );
}

function getPercentile(value: number, values: number[], reverse = false) {
  const index = reverse
    ? values.findIndex((v) => v <= value)
    : values.findIndex((v) => v >= value);
  if (index === -1) {
    return 1;
  }
  return index / values.length;
}
