import { createContext } from "react";

import {
  AllPlayerInjury,
  PlayerMissedGames,
  PlayerHotCold,
} from "../shared/routers/PlayerRouter";
import { TrendingPlayer } from "../shared/routers/AdminRouter";

// A globally shared reference to current player injuries, missed games, and
// and other status info so that we can use it across the site (like in our
// PlayerTableCell component) without having to fetch each player's status
// individually.
export const PlayerStatusContext = createContext<{
  trendingPlayers: Record<string, TrendingPlayer>;
  playerInjuries: Record<string, AllPlayerInjury>;
  playerMissedGames: Record<string, PlayerMissedGames>;
  playerHotCold: Record<string, PlayerHotCold>;
}>({
  trendingPlayers: {},
  playerInjuries: {},
  playerMissedGames: {},
  playerHotCold: {},
});
