import React from "react";
import { Col, Row } from "react-bootstrap";

import { GameTeamReboundModel } from "../../../shared/routers/GameRouter";
import { StatComparisonTableBars } from "../table/StatComparisonTableBars";
import { decFormat, makePlusMinus, intFormat } from "../../util/Format";
import { sumFromField } from "../../util/Util";

export function GameTeamReboundingLuck(props: {
  data: GameTeamReboundModel[];
  home: {
    teamid: number;
    teamabbreviation: string;
    teamCity: string;
  };
  away: {
    teamid: number;
    teamabbreviation: string;
    teamCity: string;
  };
}) {
  const { home, away, data } = props;
  const homeTeamId = home.teamid;
  const homeTeamAbbreviation = home.teamabbreviation;
  const awayTeamId = away.teamid;
  const awayTeamAbbreviation = away.teamabbreviation;

  const homeReb =
    sumFromField(
      "Actual",
      data.filter((d) => d.teamId === homeTeamId)
    ) || 50;
  const awayReb =
    sumFromField(
      "Actual",
      data.filter((d) => d.teamId !== awayTeamId)
    ) || 50;
  const reboundsDomain = [0, Math.max(homeReb, awayReb)];

  const compareColorTeam = (value: number, compare: number, key: string) => {
    const isHome = key === "home";
    const team = isHome ? homeTeamId.toString() : awayTeamId.toString();
    const otherTeam = isHome ? awayTeamId.toString() : homeTeamId.toString();

    if (value >= compare) {
      return team;
    } else {
      return otherTeam;
    }
  };

  const compareAwayFormat = (
    compare: number,
    value: number,
    format?: (x: number | null) => string
  ) => {
    const pmFormat = makePlusMinus(format || toString);
    return (
      <div>
        <div style={{ fontWeight: 600, lineHeight: 1, marginBottom: 2 }}>
          {`${awayTeamAbbreviation}`} {pmFormat(value - compare)} Rebounds vs.
          Expected
        </div>
      </div>
    );
  };
  const compareHomeFormat = (
    compare: number,
    value: number,
    format?: (x: number | null) => string
  ) => {
    const pmFormat = makePlusMinus(format || toString);
    return (
      <div>
        <div style={{ fontWeight: 600, lineHeight: 1, marginBottom: 2 }}>
          {`${homeTeamAbbreviation}`} {pmFormat(value - compare)} Rebounds vs.
          Expected
        </div>
      </div>
    );
  };

  const compareFormat = {
    home: compareHomeFormat,
    away: compareAwayFormat,
  };

  const homeData = data.filter((d) => d.teamId === homeTeamId);
  const awayData = data.filter((d) => d.teamId === awayTeamId);

  const homeOffActual = homeData.find((d) => d.offDef === "Off")?.Actual || 0;
  const homeDefActual = homeData.find((d) => d.offDef === "Def")?.Actual || 0;
  const homeOffExpected =
    homeData.find((d) => d.offDef === "Off")?.Expected || 0;
  const homeDefExpected =
    homeData.find((d) => d.offDef === "Def")?.Expected || 0;
  const awayOffActual = awayData.find((d) => d.offDef === "Off")?.Actual || 0;
  const awayDefActual = awayData.find((d) => d.offDef === "Def")?.Actual || 0;
  const awayOffExpected =
    awayData.find((d) => d.offDef === "Off")?.Expected || 0;
  const awayDefExpected =
    awayData.find((d) => d.offDef === "Def")?.Expected || 0;

  const groups = [
    [
      {
        label: "Total Rebounds",
        format: decFormat,
        domain: reboundsDomain,
        values: {
          home: homeOffActual + homeDefActual,
          away: awayOffActual + awayDefActual,
        },
        compare: {
          home: homeOffExpected + homeDefExpected,
          away: awayOffExpected + awayDefExpected,
        },
        compareColorTeam,
        compareFormat,
        additionalColumns: {
          expected: {
            format: decFormat,
            values: {
              home: homeOffExpected + homeDefExpected,
              away: awayOffExpected + awayDefExpected,
            },
          },
          actual: {
            format: intFormat,
            values: {
              home: homeOffActual + homeDefActual,
              away: awayOffActual + awayDefActual,
            },
          },
          diff: {
            format: makePlusMinus(decFormat),
            values: {
              home:
                homeOffActual +
                homeDefActual -
                (homeOffExpected + homeDefExpected),
              away:
                awayOffActual +
                awayDefActual -
                (awayOffExpected + awayDefExpected),
            },
          },
        },
      },
      {
        label: "Offensive Rebounds",
        format: decFormat,
        domain: reboundsDomain,
        values: {
          home: homeOffActual,
          away: awayOffActual,
        },
        compare: {
          home: homeOffExpected,
          away: awayOffExpected,
        },
        compareColorTeam,
        compareFormat,
        additionalColumns: {
          expected: {
            format: decFormat,
            values: {
              home: homeOffExpected,
              away: awayOffExpected,
            },
          },
          actual: {
            format: intFormat,
            values: {
              home: homeOffActual,
              away: awayOffActual,
            },
          },
          diff: {
            format: makePlusMinus(decFormat),
            values: {
              home: homeOffActual - homeOffExpected,
              away: awayOffActual - awayOffExpected,
            },
          },
        },
      },
      {
        label: "Defensive Rebounds",
        format: decFormat,
        domain: reboundsDomain,
        values: {
          home: homeDefActual,
          away: awayDefActual,
        },
        compare: {
          home: homeDefExpected,
          away: awayDefExpected,
        },
        compareColorTeam,
        compareFormat,
        additionalColumns: {
          expected: {
            format: decFormat,
            values: {
              home: homeDefExpected,
              away: awayDefExpected,
            },
          },
          actual: {
            format: intFormat,
            values: {
              home: homeDefActual,
              away: awayDefActual,
            },
          },
          diff: {
            format: makePlusMinus(decFormat),
            values: {
              home: homeDefActual - homeDefExpected,
              away: awayDefActual - awayDefExpected,
            },
          },
        },
      },
    ],
  ];

  return (
    <div>
      <Row>
        {groups.map((group, i) => {
          return (
            <Col key={i} md={12}>
              <StatComparisonTableBars
                fitExtent={false}
                labels={{
                  home: homeTeamAbbreviation,
                  away: awayTeamAbbreviation,
                }}
                data={group}
                additionalColumns={{
                  expected: {
                    width: 75,
                    label: "Expected",
                  },
                  actual: {
                    width: 75,
                    label: "Actual",
                  },
                  diff: {
                    width: 75,
                    label: "Diff.",
                  },
                }}
                teams={{
                  home: homeTeamId,
                  away: awayTeamId,
                }}
                groupColumnLabel={"Team"}
                valueColumnLabel={"Points"}
                hideValuesColumn={true}
                columnWidths={[150, 50, 55]}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
