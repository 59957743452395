import React from "react";
import { Link } from "react-router-dom";
import { Form, ToggleButton } from "react-bootstrap";
import { rgb } from "d3";

import { DeleteButton } from "../core/DeleteButton";
import { MultiSelect } from "../core/MultiSelect";
import { PlayerPhoto } from "../player/PlayerPhoto";
import { ImpactDateSelector } from "./ImpactDateSelector";
import { dec100Format, seFormat } from "../../util/Format";
import { trpc } from "../../util/tRPC";
import { Positions } from "../../constants/AppConstants";

export function ImpactPlayerSelector(props: {
  label: string;
  color: string;
  players: Record<string, string>;
  selectedPlayerId: string | undefined;
  setSelectedPlayerId: (val: string | undefined) => void;
  date: string | undefined;
  setSelectedDate: (val: string | undefined) => void;
  role: string | undefined;
  setSelectedRole: (val: string | undefined) => void;
  showDelete: boolean;
  onDeleteClick: () => void;
  showLeftRightButtons: boolean;
  isPlayerA: boolean;
  isPlayerB: boolean;
  onPlayerAClick: () => void;
  onPlayerBClick: () => void;
}) {
  const {
    label,
    color,
    players,
    selectedPlayerId,
    setSelectedPlayerId,
    date,
    setSelectedDate,
    role,
    setSelectedRole,
    showDelete,
    onDeleteClick,
    showLeftRightButtons,
    isPlayerA,
    isPlayerB,
    onPlayerAClick,
    onPlayerBClick,
  } = props;
  const useBioData = trpc.player.getPlayerBios.useQuery(
    {
      playerIds: selectedPlayerId ? [selectedPlayerId] : [],
    },
    { enabled: selectedPlayerId !== undefined }
  );

  const bio = useBioData.data ? useBioData.data[0] : undefined;

  const { data } = trpc.impact.getPlayerImpactAtDate.useQuery(
    {
      playerId: selectedPlayerId || "",
      date: date || "",
    },
    { enabled: selectedPlayerId !== undefined && date !== undefined }
  );

  const impactAtDate =
    data && date ? data.find((d) => d.pos === null) : undefined;

  const impactByPos = data && data.filter((d) => d.pos !== null);

  const backgroundColor = rgb(color);
  backgroundColor.opacity = 0.1;

  return (
    <div style={{ maxWidth: 336, marginTop: 8 }}>
      <div style={{ display: "flex", gap: 8 }}>
        <div>{showDelete && <DeleteButton onClick={onDeleteClick} />}</div>
        <h4>{label}</h4>
        {showLeftRightButtons && (
          <ToggleButton
            style={{
              fontSize: "0.7em",
              height: 18,
              width: 18,
              padding: 2,
              lineHeight: 1.2,
            }}
            disabled={selectedPlayerId === undefined || date === undefined}
            checked={isPlayerA}
            type="checkbox"
            value={"1"}
            variant="outline-primary"
            onClick={onPlayerAClick}
          >
            L
          </ToggleButton>
        )}
        {showLeftRightButtons && (
          <ToggleButton
            style={{
              fontSize: "0.7em",
              height: 18,
              width: 18,
              padding: 2,
              lineHeight: 1.2,
            }}
            disabled={selectedPlayerId === undefined || date === undefined}
            checked={isPlayerB}
            type="checkbox"
            value={"1"}
            variant="outline-primary"
            onClick={onPlayerBClick}
          >
            R
          </ToggleButton>
        )}
      </div>
      <div style={{ display: "flex", gap: 8, marginTop: 8, marginBottom: 8 }}>
        <MultiSelect
          values={Object.keys(players)
            .map((p) => {
              return { label: players[p] || "Unknown", value: p };
            })
            .sort((a, b) => (a.label > b.label ? 1 : -1))}
          selected={selectedPlayerId ? [selectedPlayerId] : []}
          onChange={(vals) =>
            setSelectedPlayerId(vals.length ? vals[0] : undefined)
          }
          limit={1}
        />
        <Form.Select
          style={{ width: "auto" }}
          value={role || undefined}
          onChange={(evt) => {
            setSelectedRole(
              evt.target.value === "Actual Role" ? undefined : evt.target.value
            );
          }}
        >
          <option value={undefined}>Actual Role</option>
          {["1", "2", "3", "4", "5"].map((r, i) => (
            <option key={i} value={r}>
              {`Typical ${r}`}
            </option>
          ))}
        </Form.Select>
      </div>
      <div style={{ display: "flex", gap: 8 }}>
        <Form.Control
          style={{ width: "auto" }}
          type="date"
          value={date || undefined}
          onChange={(evt) => {
            setSelectedDate(evt.target.value);
          }}
        />
        {selectedPlayerId && (
          <ImpactDateSelector
            playerId={selectedPlayerId}
            onChangeDate={(d) => setSelectedDate(d)}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          gap: 8,
          marginTop: 8,
          backgroundColor: backgroundColor.toString(),
          borderRadius: 4,
          padding: 8,
        }}
      >
        <Link to={bio ? `/player/${bio.playerId}` : "/player"}>
          <PlayerPhoto
            ids={(bio && bio.idIds) || undefined}
            playerId={bio && bio.playerId}
          />
        </Link>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
            width: "100%",
          }}
        >
          {impactAtDate && (
            <div>
              <b>Net:</b> {dec100Format(impactAtDate.net_impact)}{" "}
              {seFormat(100 * 1.96 * (impactAtDate.net_se || 0))}
              <br />
              <b>Off:</b> {dec100Format(impactAtDate.off_impact)}
              <br />
              <b>Def:</b> {dec100Format(impactAtDate.def_impact)}
            </div>
          )}
          <div>
            {bio && impactByPos && (
              <table style={{ width: "100%" }}>
                <thead></thead>
                <tbody>
                  <tr>
                    {Object.keys(Positions).map((p) => (
                      <td
                        key={p}
                        style={{
                          textAlign: "center",
                          fontWeight:
                            p === bio.position?.toString() ? "bold" : "normal",
                        }}
                      >
                        {Positions[parseInt(p)]}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {impactByPos
                      .sort((a, b) => (a.pos || 0) - (b.pos || 0))
                      .map((impact, i) => (
                        <td
                          key={i}
                          style={{
                            textAlign: "center",
                            fontWeight:
                              impact.pos === parseInt(bio.position || "")
                                ? "bold"
                                : "normal",
                          }}
                        >
                          {dec100Format(impact.off_impact + impact.def_impact)}
                        </td>
                      ))}
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
