import React, { useMemo, useState } from "react";

import { SortingState, Table, createColumnHelper } from "../core/Table";
import { PlayerTableCell } from "../core/TableCell";
import { dec100Format, decFormat } from "../../util/Format";
import { groupBy } from "../../../shared/util/Collections";
import { VideoControl } from "../query/VideoControl";
import { VideoModal } from "../video/VideoModal";
import { sum } from "lodash";
import { Highlights } from "../../constants/AppConstants";
import { GameExpectedTurnover } from "../../../shared/routers/GameRouter";

const NUM_OF_CLIPS = 5;

interface GameExpectedTurnoverPlayerRow {
  playerId: number;
  player: string;
  numTouches: number;
  numPasses: number;
  passTov: number;
  touchTov: number;
  passXTov: number;
  touchXTov: number;
  highXTovTouches: { url: string; xTov: number }[];
  highXTovPasses: { url: string; xTov: number }[];
  actualTovTouches: { url: string; xTov: number }[];
  actualTovPasses: { url: string; xTov: number }[];
}

export function GameExpectedTurnoversTable(props: {
  expectedTovs?: GameExpectedTurnover[];
}) {
  const { expectedTovs } = props;

  const data = useMemo(() => {
    if (!expectedTovs) return [];
    const dataByPlayer = groupBy(expectedTovs, (row) =>
      row.playerId.toString()
    );
    return Object.values(dataByPlayer).map((rows) => {
      const firstRow = rows[0];
      const player = firstRow ? firstRow.player : "Unknown";
      const playerId = firstRow ? firstRow.playerId : -1;
      const teamId = firstRow ? firstRow.teamId : -1;
      const passes = rows
        .filter((row) => row.type === "pass")
        .sort((a, b) => b.xTov - a.xTov);
      const touches = rows
        .filter((row) => row.type === "touch")
        .sort((a, b) => b.xTov - a.xTov);

      const passTov = passes.reduce((acc, row) => acc + row.tov, 0);
      const touchTov = touches.reduce((acc, row) => acc + row.tov, 0);
      const passXTov = passes.reduce((acc, row) => acc + row.xTov, 0);
      const touchXTov = touches.reduce((acc, row) => acc + row.xTov, 0);

      const highXTovTouches = touches.slice(0, NUM_OF_CLIPS).map((row) => {
        return {
          url: row.url,
          xTov: row.xTov,
        };
      });
      const highXTovPasses = passes.slice(0, NUM_OF_CLIPS).map((row) => {
        return {
          url: row.url,
          xTov: row.xTov,
        };
      });

      const actualTovTouches = touches
        .filter((row) => row.tov === 1)
        .map((row) => {
          return {
            url: row.url,
            xTov: row.xTov,
          };
        });

      const actualTovPasses = passes
        .filter((row) => row.tov === 1)
        .map((row) => {
          return {
            url: row.url,
            xTov: row.xTov,
          };
        });

      return {
        teamId,
        playerId,
        player,
        numPasses: passes.length,
        passTov,
        touchTov,
        passXTov,
        numTouches: touches.length,
        touchXTov,
        highXTovTouches,
        highXTovPasses,
        actualTovTouches,
        actualTovPasses,
      };
    });
  }, [expectedTovs]);

  const firstData = data[0];

  if (!firstData) return null;

  const team1 = data
    .filter((d) => firstData.teamId === d.teamId)
    .sort((a, b) => b.numTouches - a.numTouches);
  const team2 = data
    .filter((d) => firstData.teamId !== d.teamId)
    .sort((a, b) => b.numTouches - a.numTouches);

  return (
    <>
      <TeamTable data={team1} />
      <TeamTable data={team2} />
    </>
  );
}

const columnHelper = createColumnHelper<GameExpectedTurnoverPlayerRow>();

function TeamTable(props: { data: GameExpectedTurnoverPlayerRow[] }) {
  const { data } = props;

  const [clips, setClips] = useState<{ url: string; label: string }[]>();
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.playerId}
          />
        ),
        footer: () => "Total",
        meta: { group: 0 },
      }),
      columnHelper.group({
        id: "touches",
        meta: { group: 1 },
        header: "Touches",
        columns: [
          columnHelper.accessor("numTouches", {
            header: () => "#",
            footer: () => sum(data.map((d) => d.numTouches)),
            meta: {
              group: 1,
              highlights: Highlights.Max,
            },
          }),
          columnHelper.accessor("touchTov", {
            header: () => "TOV",
            footer: () => sum(data.map((d) => d.touchTov)),
            meta: {
              group: 1,
              highlights: Highlights.Max,
            },
          }),
          columnHelper.accessor("touchXTov", {
            header: () => "xTOV",
            cell: (info) => decFormat(info.getValue()),
            footer: () => decFormat(sum(data.map((d) => d.touchXTov))),
            meta: {
              group: 1,
              highlights: Highlights.Max,
            },
          }),
          columnHelper.accessor("actualTovTouches", {
            header: () => "Actual",
            cell: (info) =>
              info.getValue().length ? (
                <VideoControl
                  data={info.row.original.actualTovTouches.map((u) => {
                    return {
                      url: u.url,
                      label: `${
                        info.row.original.player
                      } actual TOV touch (${dec100Format(u.xTov)}%)`,
                    };
                  })}
                  onVideo={setClips}
                />
              ) : null,
            meta: {
              group: 1,
            },
          }),
          columnHelper.accessor("highXTovTouches", {
            header: () => "High",
            cell: (info) => (
              <VideoControl
                data={info.row.original.highXTovTouches.map((u) => {
                  return {
                    url: u.url,
                    label: `${
                      info.row.original.player
                    } high xTOV touch (${dec100Format(u.xTov)}%)`,
                  };
                })}
                onVideo={setClips}
              />
            ),
            meta: {
              group: 1,
            },
          }),
        ],
      }),
      columnHelper.group({
        id: "passes",
        meta: { group: 2 },
        header: "Passes",
        columns: [
          columnHelper.accessor("numPasses", {
            header: () => "#",
            footer: () => sum(data.map((d) => d.numPasses)),
            meta: {
              group: 2,
              highlights: Highlights.Max,
            },
          }),
          columnHelper.accessor("passTov", {
            header: () => "TOV",
            footer: () => sum(data.map((d) => d.passTov)),
            meta: {
              group: 2,
              highlights: Highlights.Max,
            },
          }),
          columnHelper.accessor("passXTov", {
            header: () => "xTOV",
            footer: () => decFormat(sum(data.map((d) => d.passXTov))),
            cell: (info) => decFormat(info.getValue()),
            meta: {
              group: 2,
              highlights: Highlights.Max,
            },
          }),
          columnHelper.accessor("actualTovPasses", {
            header: () => "Actual",
            cell: (info) =>
              info.getValue().length ? (
                <VideoControl
                  data={info.row.original.actualTovPasses.map((u) => {
                    return {
                      url: u.url,
                      label: `${
                        info.row.original.player
                      } actual TOV pass (${dec100Format(u.xTov)}%)`,
                    };
                  })}
                  onVideo={setClips}
                />
              ) : null,
            meta: {
              group: 2,
            },
          }),
          columnHelper.accessor("highXTovPasses", {
            header: () => "High",
            cell: (info) => (
              <VideoControl
                data={info.row.original.highXTovPasses.map((u) => {
                  return {
                    url: u.url,
                    label: `${
                      info.row.original.player
                    } high xTOV pass (${dec100Format(u.xTov)}%)`,
                  };
                })}
                onVideo={setClips}
              />
            ),
            meta: {
              group: 2,
            },
          }),
        ],
      }),
    ];
  }, [data]);

  const firstClip = clips && clips[0];

  return (
    <>
      <Table
        data={data || []}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        autoWidth={true}
        showRowIndex={false}
      />
      {clips && (
        <VideoModal
          clips={clips}
          title={firstClip ? firstClip.label : ""}
          show={clips !== undefined}
          handleClose={() => setClips(undefined)}
          upDownClipSkip={true}
          showSynergyEditor={false}
        />
      )}
    </>
  );
}
