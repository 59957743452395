import React, { useMemo, useState } from "react";
import { useQueryParams, withDefault, StringParam } from "use-query-params";

import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import {
  Table,
  SortingState,
  createColumnHelper,
} from "../components/core/Table";
import { Spinner } from "../components/core/Spinner";
import { trpc } from "../util/tRPC";
import { Highlights } from "../constants/AppConstants";
import { seasonString } from "../util/Format";
import AppContext from "../../shared/AppContext";
import { Form } from "react-bootstrap";
import { BiaSpm } from "../../shared/routers/MiscRouter";
import { PlayerTableCell } from "../components/core/TableCell";

const columnHelper = createColumnHelper<BiaSpm>();

export function BiaSpmPage() {
  const [queryParams, setQueryParams] = useQueryParams({
    season: withDefault(StringParam, AppContext.currentSeason),
  });

  const { season } = queryParams;

  // Our historical data starts in 2022-23 season so only go back that far.
  const seasons = AppContext.seasons
    .filter((s) => s.value >= 2023)
    .map((s) => s.value.toString());

  const [sorting, setSorting] = useState<SortingState>();
  const biaSpm = trpc.misc.getBiaSpm.useQuery({ season });

  const columns = useMemo(
    () => [
      columnHelper.accessor("GM", {
        header: () => "Contestant",
        meta: { group: 0 },
      }),
      columnHelper.accessor("score", {
        header: () => "Score",
        meta: { group: 1, highlights: Highlights.Max },
      }),
      columnHelper.group({
        id: "positive",
        meta: { group: 2 },
        header: "Largest Positive",
        columns: [
          columnHelper.accessor("largestPositiveId", {
            header: () => "Player",
            cell: (info) => (
              <PlayerTableCell
                id={info.getValue()}
                name={info.row.original.largestPositive}
              />
            ),
            meta: { group: 2 },
          }),
          columnHelper.accessor("largestPositivePlusMinus", {
            header: () => "+/-",
            meta: { group: 2, highlights: Highlights.Max },
          }),
        ],
      }),
      columnHelper.group({
        id: "negative",
        meta: { group: 3 },
        header: "Largest Negative",
        columns: [
          columnHelper.accessor("largestNegativeId", {
            header: () => "Player",
            cell: (info) => (
              <PlayerTableCell
                id={info.getValue()}
                name={info.row.original.largestNegative}
              />
            ),
            meta: { group: 3 },
          }),
          columnHelper.accessor("largestNegativePlusMinus", {
            header: () => "+/-",
            meta: { group: 3, highlights: Highlights.Min },
          }),
        ],
      }),
      columnHelper.group({
        id: "positiveRookie",
        meta: { group: 4 },
        header: "Largest Positive Rookie",
        columns: [
          columnHelper.accessor("largestPositiveRookieId", {
            header: () => "Player",
            cell: (info) => (
              <PlayerTableCell
                id={info.getValue()}
                name={info.row.original.largestPositiveRookie}
              />
            ),
            meta: { group: 4 },
          }),
          columnHelper.accessor("largestPositiveRookiePlusMinus", {
            header: () => "+/-",
            meta: { group: 4, highlights: Highlights.Max },
          }),
        ],
      }),
      columnHelper.group({
        id: "negativeRookie",
        meta: { group: 5 },
        header: "Largest Negative Rookie",
        columns: [
          columnHelper.accessor("largestNegativeRookieId", {
            header: () => "Player",
            cell: (info) => (
              <PlayerTableCell
                id={info.getValue()}
                name={info.row.original.largestNegativeRookie}
              />
            ),
            meta: { group: 5 },
          }),
          columnHelper.accessor("largestNegativeRookiePlusMinus", {
            header: () => "+/-",
            meta: { group: 5, highlights: Highlights.Min },
          }),
        ],
      }),
      columnHelper.group({
        id: "greatestIncrease",
        meta: { group: 6 },
        header: "Largest Positive Change",
        columns: [
          columnHelper.accessor("greatestIncreaseId", {
            header: () => "Player",
            cell: (info) => (
              <PlayerTableCell
                id={info.getValue()}
                name={info.row.original.greatestIncrease}
              />
            ),
            meta: { group: 6 },
          }),
          columnHelper.accessor("greatestIncreaseCurPlusMinus", {
            header: () => "+/- Curr",
            meta: { group: 6, highlights: Highlights.Max },
          }),
          columnHelper.accessor("greatestIncreasePrevPlusMinus", {
            header: () => "+/- Prev",
            meta: { group: 6, highlights: Highlights.Min },
          }),
          columnHelper.accessor("greatestIncreaseNetPlusMinus", {
            header: () => "Net",
            meta: { group: 6, highlights: Highlights.Max },
          }),
        ],
      }),
      columnHelper.group({
        id: "greatestDecrease",
        meta: { group: 7 },
        header: "Largest Negative Change",
        columns: [
          columnHelper.accessor("greatestDecreaseId", {
            header: () => "Player",
            cell: (info) => (
              <PlayerTableCell
                id={info.getValue()}
                name={info.row.original.greatestDecrease}
              />
            ),
            meta: { group: 7 },
          }),
          columnHelper.accessor("greatestDecreaseCurPlusMinus", {
            header: () => "+/- Curr",
            meta: { group: 7, highlights: Highlights.Min },
          }),
          columnHelper.accessor("greatestDecreasePrevPlusMinus", {
            header: () => "+/- Prev",
            meta: { group: 7, highlights: Highlights.Max },
          }),
          columnHelper.accessor("greatestDecreaseNetPlusMinus", {
            header: () => "Net",
            meta: { group: 7, highlights: Highlights.Min },
          }),
        ],
      }),
    ],
    []
  );

  return (
    <Page header={{ text: "BIA Simple +/- Contest" }} title="BIA SPM">
      <Panel header={"Scores"}>
        <div>
          <Form.Select
            style={{ width: "auto" }}
            value={season}
            onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
              setQueryParams({ season: evt.target.value });
            }}
          >
            {seasons.map((s) => (
              <option key={s} value={s}>
                {seasonString(s)}
              </option>
            ))}
          </Form.Select>
          {biaSpm.status === "loading" ? (
            <Spinner />
          ) : (
            <Table
              data={biaSpm.data || []}
              columns={columns}
              autoWidth={true}
              showRowIndex={false}
              sorting={sorting}
              setSorting={setSorting}
            />
          )}
        </div>
      </Panel>
    </Page>
  );
}
