import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useQueryParams, StringParam, withDefault } from "use-query-params";

import { TeamMultiLeagueSchedule } from "../components/team/TeamMultiLeagueSchedule";
import { Page } from "../components/core/Page";
import { Tabs } from "../components/core/PageTabs";
import { GameSubPageShooting } from "../subpages/game/GameSubPageShooting";
import { GameSubPageVideo } from "../subpages/game/GameSubPageVideo";
import { Panel } from "../components/core/Panel";
import { GameMultiLeagueBoxscoreTeam } from "../../shared/routers/GameRouter";
import { GameMultiLeagueBoxScoresTable } from "../components/games/GameMultiLeagueBoxScoresTable";
import { GameTeamShootingLuck } from "../components/games/GameTeamShootingLuck";
import { GamePlayerShootingLuck } from "../components/games/GamePlayerShootingLuck";
import { trpc } from "../util/tRPC";
import { TeamCrashing } from "../components/crashing/TeamCrashing";
import { DebugInfo } from "../components/core/DebugInfo";
import { GameMultiLeaguePageHeader } from "../components/games/GameMultiLeaguePageHeader";
import { GameTeamReboundingLuck } from "../components/games/GameTeamReboundingLuck";
import { GamePlayerReboundingLuck } from "../components/games/GamePlayerReboundingLuck";
import { TeamColorBox } from "../components/team/TeamColorBox";

export function GameGLeaguePage() {
  const { id } = useParams();
  const league = "g-league";

  // Page state query params.
  const [queryParams, setQueryParams] = useQueryParams({
    tab: withDefault(StringParam, "postgame"),
    scrollTo: StringParam,
  });
  const { tab, scrollTo } = queryParams;

  useEffect(() => {
    const el = document.querySelector(`#${scrollTo}`);
    if (!el) return;
    const yOffset = -76; // The navbar + team selector.
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }, [scrollTo]);

  const { data: detailsRows } = trpc.game.getGameDetails.useQuery({
    gameIds: id ? [parseInt(id)] : [],
  });

  const { data: teamBoxes } = trpc.game.getGameMultiLeagueBoxScoreTeam.useQuery(
    { gameId: id, league }
  );

  const details = detailsRows && detailsRows[0];

  const onSubLinkClick = (subLink: string) => {
    const tab = Object.keys(pageTabs.tabs).find((pt) => {
      const tarTab = pageTabs.tabs[pt];
      if (!tarTab) return;
      return tarTab.sublinks.find((sl) => sl.refId === subLink);
    });
    if (!tab) return;
    setQueryParams({ tab, scrollTo: subLink });
  };

  if (!id) return null;

  const homeBox = teamBoxes && teamBoxes.find((tb) => tb.homeAway === "home");
  const awayBox = teamBoxes && teamBoxes.find((tb) => tb.homeAway === "away");

  const home =
    details && details.homeTeamID
      ? {
          season: details.season,
          teamid: details.homeTeamID,
          team: details.homeTeam,
          teamCity: homeBox ? homeBox.TEAMLONG : "",
          teamabbreviation: homeBox ? homeBox.team : "",
          teamIds: homeBox ? homeBox.teamIDSId : 0,
        }
      : undefined;
  const away =
    details && details.awayTeamID
      ? {
          season: details.season,
          teamid: details.awayTeamID,
          team: details.awayTeam,
          teamCity: awayBox ? awayBox.TEAMLONG : "",
          teamabbreviation: awayBox ? awayBox.team : "",
          teamIds: awayBox ? awayBox.teamIDSId : 0,
        }
      : undefined;
  const pageTabs = getPageTabs(parseInt(id), home, away, teamBoxes || []);

  if (!homeBox || !awayBox || !details) return null;

  return (
    <Page
      header={{
        component: (
          <GameMultiLeaguePageHeader
            homeTeamName={homeBox.TEAMLONG}
            awayTeamName={awayBox.TEAMLONG}
            homeTeamId={(home && home.teamid) || 0}
            awayTeamId={(away && away.teamid) || 0}
            homePts={homeBox.pts}
            awayPts={awayBox.pts}
            homeTeamIdsId={homeBox.teamIDSId}
            awayTeamIdsId={awayBox.teamIDSId}
            gameDateTimeStr={details.gameDate}
            league={league}
            season={details.season}
          />
        ),
      }}
      title={"G-League Game"}
      tabs={pageTabs}
      activeTab={tab}
      onTabClick={(newTab) => setQueryParams({ tab: newTab })}
      onSubLinkClick={(subLink) => onSubLinkClick(subLink)}
    />
  );
}

function getPageTabs(
  gameId: number,
  home:
    | {
        teamid: number;
        team: string;
        teamabbreviation: string;
        teamCity: string;
        season: number;
      }
    | undefined,
  away:
    | {
        teamid: number;
        team: string;
        teamabbreviation: string;
        teamCity: string;
        season: number;
      }
    | undefined,
  teamBoxes: GameMultiLeagueBoxscoreTeam[]
): Tabs {
  // Overview
  const { data: playerBoxes } =
    trpc.game.getGameMultiLeagueBoxscorePlayer.useQuery({
      gameId,
      league: "g-league",
    });

  const { data: teamRebounds } = trpc.game.getGameTeamReboundModel.useQuery({
    gameIds: [gameId],
    isGLeague: true,
  });

  const { data: crashAttempts } = trpc.game.getCrashAttempts.useQuery({
    gameIds: gameId ? [gameId] : [],
    isGLeague: true,
  });

  const { data: playerRebounds } = trpc.game.getGamePlayerReboundModel.useQuery(
    {
      gameIds: [gameId],
      isGLeague: true,
    }
  );

  // Shooting
  const { data: shots } = trpc.shot.getShots.useQuery({
    isGLeague: true,
    filters: {
      gameIds: gameId ? [gameId.toString()] : [],
    },
  });

  // Schedule
  const { data: homeSchedule } =
    trpc.league.getLeagueSeasonTeamSchedule.useQuery({
      leagueKey: "g-league",
      season: home ? home.season.toString() : undefined,
      teamId: home ? home.teamid.toString() : undefined,
    });

  const { data: awaySchedule } =
    trpc.league.getLeagueSeasonTeamSchedule.useQuery({
      leagueKey: "g-league",
      season: away ? away.season.toString() : undefined,
      teamId: away ? away.teamid.toString() : undefined,
    });

  // Video
  const { data: gameSegments } = trpc.synergy.getSynergyGameSegments.useQuery({
    gameIds: [gameId],
  });
  const { data: gameClips } = trpc.synergy.getSynergyGameClips.useQuery({
    gameIds: [gameId],
  });
  const { data: gameDetails } = trpc.game.getGameDetails.useQuery({
    gameIds: [gameId],
  });

  const pageTabs = {
    tabs: {
      postgame: {
        label: "Overview",
        sublinks: [
          { label: "Team Shot Quality", refId: "teamShotQuality" },
          { label: "Player Shot Quality", refId: "playerShotQuality" },
          { label: "Away Box Scores", refId: "awayBoxScores" },
          { label: "Home Box Scores", refId: "homeBoxScores" },
          { label: "Team Rebounding", refId: "teamRebounds" },
          { label: "Team Crashing", refId: "teamCrashing" },
          { label: "Player Rebounding", refId: "playerRebounds" },
        ],
        content: (
          <>
            <Row>
              <Col md={6}>
                <Panel panelID="teamShotQuality" header={"Team Shot Quality"}>
                  {shots && home && away && (
                    <GameTeamShootingLuck
                      data={shots}
                      home={home}
                      away={away}
                      fromDate={""}
                      toDate={""}
                    />
                  )}
                </Panel>
              </Col>
            </Row>
            <Row>
              <Col>
                <Panel
                  panelID="playerShotQuality"
                  header={"Player Shot Quality"}
                >
                  {shots && home && away && (
                    <GamePlayerShootingLuck
                      data={shots}
                      home={home}
                      away={away}
                    />
                  )}
                </Panel>
              </Col>
            </Row>
            <Row>
              {["away", "home"].map((homeAway) => {
                const teamBox = teamBoxes.find(
                  (d) => d.homeAway === homeAway
                ) as GameMultiLeagueBoxscoreTeam;

                if (!teamBox) return null;

                const team = teamBox.TEAMLONG;
                return (
                  <Col key={homeAway}>
                    <Panel
                      header={`${team} Box Scores`}
                      panelID={`${homeAway}BoxScores`}
                    >
                      {playerBoxes && (
                        <GameMultiLeagueBoxScoresTable
                          data={playerBoxes.filter(
                            (p) => p.homeAway === homeAway
                          )}
                        />
                      )}
                    </Panel>
                  </Col>
                );
              })}
            </Row>
            <Row>
              <Col md={6}>
                <Panel header="Team Rebounding" panelID="teamRebounds">
                  {teamRebounds && home && away && (
                    <div>
                      <TeamColorBox
                        teamId={home.teamid}
                        oppTeamId={away.teamid}
                      />
                      {home.teamCity}{" "}
                      <TeamColorBox
                        teamId={away.teamid}
                        oppTeamId={home.teamid}
                      />
                      {away.teamCity}
                      <GameTeamReboundingLuck
                        data={teamRebounds}
                        home={home}
                        away={away}
                      />
                    </div>
                  )}
                </Panel>
              </Col>
              <Col md={6}>
                <Panel header="Team Crashing" panelID="teamCrashing">
                  {crashAttempts && (
                    <TeamCrashing
                      data={crashAttempts}
                      teams={teamBoxes.map((t) => {
                        return {
                          teamId: t.teamIDSId || 0,
                          teamName: t.TEAMLONG,
                        };
                      })}
                    />
                  )}
                </Panel>
              </Col>
            </Row>
            <Row>
              <Panel panelID="playerRebounds" header={"Player Rebounding"}>
                {playerRebounds && home && away && (
                  <GamePlayerReboundingLuck
                    data={playerRebounds}
                    home={home}
                    away={away}
                  />
                )}
              </Panel>
            </Row>
            <DebugInfo gameId={gameId} />
          </>
        ),
      },
      shooting: {
        label: "Shooting",
        content: (
          <div>
            <GameSubPageShooting home={home} away={away} shots={shots} />
            <DebugInfo gameId={gameId} />
          </div>
        ),
        sublinks: [
          {
            label: `Shot Quality · ${away ? away.team : ""}`,
            refId: "shotQualityAway",
          },
          {
            label: `Shot Quality · ${home ? home.team : ""}`,
            refId: "shotQualityHome",
          },
          {
            label: `Shot Charts · ${away ? away.team : ""}`,
            refId: "shotChartsAway",
          },
          {
            label: `Shot Charts · ${home ? home.team : ""}`,
            refId: "shotChartsHome",
          },
        ],
      },
      schedules: {
        label: "Schedules",
        content: (
          <>
            <Row>
              {[awaySchedule, homeSchedule].map((schedule, i) => {
                const firstGame = schedule && schedule[0];
                const team = (firstGame && firstGame.team) || "";
                return (
                  <Col key={i}>
                    <Panel header={team}>
                      <TeamMultiLeagueSchedule
                        gameId={gameId.toString()}
                        data={schedule || []}
                      />
                    </Panel>
                  </Col>
                );
              })}
            </Row>
            <DebugInfo gameId={gameId} />
          </>
        ),
        sublinks: [
          { label: "Away Schedule", refId: "awaySchedule" },
          { label: "Home Schedule", refId: "homeSchedule" },
        ],
      },
      video: {
        label: "Video",
        content: (
          <div>
            <GameSubPageVideo
              home={home}
              away={away}
              gameSegments={gameSegments}
              gameClips={gameClips}
              gameDetails={gameDetails}
            />{" "}
            <DebugInfo gameId={gameId} />
          </div>
        ),
        sublinks: [{ label: "Video", refId: "video" }],
      },
    },
  };

  return pageTabs;
}
