import React, { useContext, useEffect, useState, useCallback } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import moment from "moment";

import { TeamSchedule } from "../../../shared/routers/TeamRouter";
import { GameInput } from "./GameInput";
import { TooltipItem } from "../core/TooltipItem";
import { dateFormat } from "../../util/Format";
import { pluralCheck } from "../../util/Util";
import { TeamContext } from "../../TeamContext";

const useStyles = makeStyles(() =>
  createStyles({
    inline: { display: "inline" },
    gamesContainer: { marginBottom: 10 },
    quickSelectButtons: {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
      marginBottom: 10,
    },
    teamDropdown: {
      "& .dropdown-menu": {
        maxHeight: 250,
        overflowY: "scroll",
        overflowX: "hidden",
      },
    },
  })
);

interface GameSelectorSelection {
  initial: number;
  mouseX: number;
  mouseY: number;
  start?: number;
  end?: number;
}

export function GameSelector(props: {
  teamId: number;
  games: TeamSchedule[];
  selectedGames: string[];
  onToggleGame: (gameId: string) => void;
  onSetSelection: (gameIds: string[]) => void;
  hideStatusMessage?: boolean;
  hideGameSelectorCategories?: boolean;
}) {
  const teams = useContext(TeamContext).teams;
  const classes = useStyles();
  const {
    teamId,
    games,
    selectedGames,
    onToggleGame,
    onSetSelection,
    hideStatusMessage,
    hideGameSelectorCategories,
  } = props;
  const [selection, setSelection] = useState<GameSelectorSelection>();
  const [keepSelection, setKeepSelection] = useState(false);
  const [removeSelection, setRemoveSelectin] = useState(false);

  const handleMouseMove = (
    evt: React.MouseEvent<HTMLDivElement>,
    game: TeamSchedule
  ) => {
    // If we aren't currently selecting do nothing.
    if (!selection) {
      return;
    }
    const { keepSelection, removeSelection } = keyState(evt);
    // Handle click behaviour by waiting for mouse to be epsilon away from
    // original position.
    if (
      withinEpsilon(evt.pageX, selection.mouseX, 15) &&
      withinEpsilon(evt.pageY, selection.mouseY, 15)
    ) {
      // Removing start and end removes selection highlighting to indicate it
      // will be treated as a click.
      setSelection(
        Object.assign({}, selection, { start: undefined, end: undefined })
      );
      setKeepSelection(keepSelection);
      setRemoveSelectin(removeSelection);
      return;
    }
    const gameIndex = games.findIndex((g) => g.gameid === game.gameid);
    let start, end;
    // Comes before initial game, so we update start.
    if (gameIndex < selection.initial) {
      start = gameIndex;
      end = selection.initial;
    } else {
      start = selection.initial;
      end = gameIndex;
    }

    setKeepSelection(keepSelection);
    setRemoveSelectin(removeSelection);
    setSelection(Object.assign({}, selection, { start: start, end: end }));
  };

  const handleMouseDown = (
    evt: React.MouseEvent<HTMLDivElement>,
    game: TeamSchedule
  ) => {
    const gameIndex = games.findIndex((g) => g.gameid === game.gameid);
    const { keepSelection, removeSelection } = keyState(evt);
    setKeepSelection(keepSelection);
    setRemoveSelectin(removeSelection);
    setSelection({
      initial: gameIndex,
      mouseX: evt.pageX,
      mouseY: evt.pageY,
    });
  };

  const handleMouseUp = useCallback(
    (evt: MouseEvent) => {
      // If we aren't currently selecting do nothing.
      if (!selection) {
        return;
      }
      const { keepSelection, removeSelection } = keyState(
        evt as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>
      );
      const start = selection.start;
      const end = selection.end;
      // Handle click.
      if (start === undefined || end === undefined) {
        // Get game id from initial click and toggle its selection.
        const game = games[selection.initial];
        if (game) {
          onToggleGame(game.gameid.toString());
        }
      } else {
        const newSelection = games
          .filter((g, i) => i >= start && i <= end)
          .map((g) => g.gameid.toString());
        // Handle drag end.
        if (removeSelection) {
          const curSelection = new Set(selectedGames);
          for (const game of newSelection) {
            curSelection.delete(game);
          }
          onSetSelection(Array.from(curSelection));
        } else if (keepSelection) {
          const curSelection = new Set(selectedGames);
          for (const game of newSelection) {
            curSelection.add(game);
          }
          onSetSelection(Array.from(curSelection));
        } else {
          onSetSelection(newSelection);
        }
      }
      // Update the state of selected games and reset the selection.
      setSelection(undefined);
      setRemoveSelectin(removeSelection);
      setKeepSelection(keepSelection);
      // Prevent click propagation.
      evt.stopPropagation();
      evt.preventDefault();
    },
    [selection, removeSelection, keepSelection]
  );

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    return () => document.removeEventListener("mouseup", handleMouseUp);
  }, [handleMouseUp]);

  const quickSelect = (
    selection: "all" | "none" | "home" | "away" | "wins" | "losses"
  ) => {
    switch (selection) {
      case "all": {
        onSetSelection(
          games.filter(noPreseason).map((g) => g.gameid.toString())
        );
        break;
      }
      case "none": {
        onSetSelection([]);
        break;
      }
      case "home": {
        onSetSelection(
          games
            .filter(noPreseason)
            .filter((g) => g.homeTeamId === teamId)
            .map((g) => g.gameid.toString())
        );
        break;
      }
      case "away": {
        onSetSelection(
          games
            .filter(noPreseason)
            .filter((g) => g.awayTeamId === teamId)
            .map((g) => g.gameid.toString())
        );
        break;
      }
      case "wins": {
        onSetSelection(
          games
            .filter(noPreseason)
            .filter((g) => g.winningteamid === teamId)
            .map((g) => g.gameid.toString())
        );
        break;
      }
      case "losses": {
        onSetSelection(
          games
            .filter(noPreseason)
            .filter((g) => g.winningteamid !== teamId)
            .map((g) => g.gameid.toString())
        );
        break;
      }
    }
  };

  const handleTeamSelect = (teamId: string | null) => {
    if (teamId === null) return;
    onSetSelection(
      games
        .filter(noPreseason)
        .filter(
          (g) =>
            g.awayTeamId.toString() === teamId ||
            g.homeTeamId.toString() === teamId
        )
        .map((g) => g.gameid.toString())
    );
  };

  return (
    <div>
      {!hideGameSelectorCategories && (
        <div className={classes.quickSelectButtons}>
          <Button onClick={() => quickSelect("all")}>All</Button>
          <Button onClick={() => quickSelect("none")}>None</Button>
          <Button onClick={() => quickSelect("home")}>Home</Button>
          <Button onClick={() => quickSelect("away")}>Away</Button>
          <Button onClick={() => quickSelect("wins")}>Wins</Button>
          <Button onClick={() => quickSelect("losses")}>Losses</Button>
          <DropdownButton
            title="Team"
            onSelect={handleTeamSelect}
            className={classes.teamDropdown}
          >
            {teams.map((team, i) => (
              <Dropdown.Item key={i} eventKey={team.teamid}>
                {team.teamshortname}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
      )}
      <div className={classes.gamesContainer}>
        {games.map((game, i) => (
          <TooltipItem
            key={i}
            arrow={false}
            tooltip={makeHoverMessage(teamId, game)}
            triggerClassName={classes.inline}
          >
            <GameInput
              key={i}
              game={game}
              teamId={teamId}
              selected={selectedGames.includes(game.gameid.toString())}
              inSelection={
                !!(
                  selection &&
                  selection.start &&
                  selection.end &&
                  selection.start <= i &&
                  selection.end >= i
                )
              }
              onMouseDown={(evt: React.MouseEvent<HTMLDivElement>) =>
                handleMouseDown(evt, game)
              }
              onMouseMove={(evt: React.MouseEvent<HTMLDivElement>) =>
                handleMouseMove(evt, game)
              }
            />
          </TooltipItem>
        ))}
        {!hideStatusMessage && (
          <div>
            {makeStatusMessage(
              games.filter((g) => selectedGames.includes(g.gameid.toString())),
              teamId
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function makeHoverMessage(teamId: number, game: TeamSchedule) {
  let hoverMessage = "";
  const isHomeGame = game.homeTeamId === teamId;
  const date = dateFormat(moment(game.gameDateTime).toDate());

  if (isHomeGame) {
    hoverMessage = `${game.awayScore}-${game.homescore} ${game.awayTeamAbbreviation} vs ${game.homeTeamAbbreviation} on ${date}`;
  } else {
    hoverMessage = `${game.homescore}-${game.awayScore} ${game.homeTeamAbbreviation} @ ${game.awayTeamAbbreviation} on ${date}`;
  }

  return hoverMessage;
}

function makeStatusMessage(games: TeamSchedule[], teamId: number) {
  const selected = `${games.length} selected.`;

  const wins = games.filter((g: TeamSchedule) => g.winningteamid === teamId);
  const losses = games.filter((g: TeamSchedule) => g.winningteamid !== teamId);

  const winsLosses = `${wins.length} ${pluralCheck("win", wins.length)}, ${
    losses.length
  } ${pluralCheck("loss", losses.length, "es")}.`;

  const numHomeWin = wins.filter((g) => g.homeTeamId === teamId).length;
  const numHomeLoss = losses.filter((g) => g.homeTeamId === teamId).length;
  const numAwayWin = wins.filter((g) => g.awayTeamId === teamId).length;
  const numAwayLoss = losses.filter((g) => g.awayTeamId === teamId).length;

  const homeAway = `${numHomeWin}-${numHomeLoss} home, ${numAwayWin}-${numAwayLoss} away.`;

  return selected + " " + winsLosses + " " + homeAway;
}

function noPreseason(game: TeamSchedule) {
  return game.gametypeid !== "X";
}

function keyState(evt: React.MouseEvent<HTMLDivElement>) {
  const keepSelection = !!(evt.shiftKey || evt.metaKey);
  const removeSelection = !!evt.altKey;
  return { keepSelection, removeSelection };
}

// Returns true if x is within epsilon of y. a 'loose' equals.
function withinEpsilon(x: number, y: number, epsilon: number) {
  return x < y + epsilon && x > y - epsilon;
}
