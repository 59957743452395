import React, { ReactNode } from "react";
import { Modal as BootstrapModal, Button } from "react-bootstrap";

export function Modal(props: {
  title: string;
  content: ReactNode;
  show: boolean;
  disableConfirm?: boolean;
  disableBackgroundClickClose?: boolean;
  handleClose: () => void;
  handleConfirm?: () => void;
}) {
  const {
    title,
    content,
    show,
    disableConfirm,
    disableBackgroundClickClose,
    handleClose,
    handleConfirm,
  } = props;
  return (
    <BootstrapModal
      show={show}
      onHide={handleClose}
      backdrop={disableBackgroundClickClose ? "static" : undefined}
    >
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>{title}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>{content}</BootstrapModal.Body>
      {handleConfirm && (
        <BootstrapModal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirm}
            disabled={disableConfirm}
          >
            Confirm
          </Button>
        </BootstrapModal.Footer>
      )}
    </BootstrapModal>
  );
}
