import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { decFormat } from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";

interface PlayerSpeed {
  TYPE: string;
  Season: number | null;
  celticsID: number;
  norm_max_speed: number | null;
  norm_max_power: number | null;
  norm_min_ke: number | null;
  norm_max_jump: number | null;
}

const columnHelper = createColumnHelper<PlayerSpeed>();

export function PlayerSpeedTable(props: { data: PlayerSpeed[] }) {
  const { data } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("Season", {
        header: () => "Season",
        cell: (info) =>
          info.row.original.TYPE === "Draft Model"
            ? "Draft Model"
            : info.getValue(),
      }),
      columnHelper.accessor("norm_max_speed", {
        header: () => <Link to={`/stats/norm_max_speed`}>Top Speed</Link>,
        cell: (info) => decFormat(info.getValue()),
        meta: { highlights: Highlights.Max },
      }),
      columnHelper.accessor("norm_min_ke", {
        header: () => <Link to={`/stats/norm_min_ke`}>Decel</Link>,
        cell: (info) => decFormat(info.getValue()),
        meta: { highlights: Highlights.Min },
      }),
      columnHelper.accessor("norm_max_power", {
        header: () => <Link to={`/stats/norm_max_power`}>Burst</Link>,
        cell: (info) => decFormat(info.getValue()),
        meta: { highlights: Highlights.Max },
      }),
      columnHelper.accessor("norm_max_jump", {
        header: () => <Link to={`/stats/norm_max_jump`}>Jump</Link>,
        cell: (info) => decFormat(info.getValue()),
        meta: { highlights: Highlights.Max },
      }),
    ],
    []
  );

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
    />
  );
}
