import React, { useState, useMemo } from "react";
import {
  withDefault,
  useQueryParams,
  QueryParamConfig,
  DelimitedArrayParam,
  JsonParam,
  NumberParam,
} from "use-query-params";
import { Alert, Form, Col, Row, Collapse } from "react-bootstrap";

import { SortingState } from "../components/core/Table";
import { ExpandCollapseButton } from "../components/core/ExpandCollapseButton";
import { PnrAggregate, PnrFilters } from "../../shared/routers/PnrRouter";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Spinner } from "../components/core/Spinner";
import { MultiSelect } from "../components/core/MultiSelect";
import { VideoModal } from "../components/video/VideoModal";
import { PnrQueryResultsTable } from "../components/pnr/PnrQueryResultsTable";
import { PnrFilterForm } from "../components/pnr/PnrFilterForm";
import AppContext from "../../shared/AppContext";
import { pnrLabels } from "../constants/AppConstants";
import { pnrToSynergyEditorClip } from "../components/video/utilities";
import { trpc } from "../util/tRPC";
import { groupBy as arrGroupBy } from "../../shared/util/Collections";

const RESULT_LIMIT = 10_000;

const RESULT_LIMIT_MSG = `Your query produced a large return set. To improve
performance we've limited the return data to ${RESULT_LIMIT.toLocaleString()}
rows.`;

const pnrGroupBys: { value: keyof PnrAggregate; label: string }[] = [
  { value: "season", label: "Season" },
  { value: "gameId", label: "Game" },
  { value: "oteamId", label: "Offense Team" },
  { value: "dteamId", label: "Defense Team" },
  { value: "ballhandlerId", label: "Ballhandler" },
  { value: "screenerId", label: "Screener" },
  { value: "ballhandlerDefenderId", label: "Ballhandler Defender" },
  { value: "screenerDefenderId", label: "Screener Defender" },
  { value: "positionBallhandler", label: "Ballhandler Position" },
  { value: "positionScreener", label: "Screener Position" },
  {
    value: "positionBallhandlerDefender",
    label: "Ballhandler Defender Position",
  },
  {
    value: "positionScreenerDefender",
    label: "Screener Defender Position",
  },
  {
    value: "period",
    label: "Period",
  },
  { value: "coverage", label: "Coverage" },
  { value: "scrDefenderLevel", label: "Screener Defender Level" },
  {
    value: "defense",
    label: "Team Defense",
  },
  {
    value: "bhrDefTypeComplex",
    label: "Ballhandler Defense",
  },
  {
    value: "scrDefTypeComplex",
    label: "Screener Defense",
  },
  {
    value: "locationType",
    label: "Location",
  },
  {
    value: "rollPop",
    label: "Roll / Pop",
  },
  {
    value: "direction",
    label: "Direction",
  },
  {
    value: "takeRejectSlip",
    label: "Screen Type",
  },
  {
    value: "double",
    label: "Double",
  },
  {
    value: "handoffPick",
    label: "Handoff Pick",
  },
  {
    value: "horns",
    label: "Horns",
  },
  {
    value: "l",
    label: "L-Screen",
  },
  {
    value: "rescreen",
    label: "Rescreen",
  },
  {
    value: "gameTypeId",
    label: "Game Type",
  },
];

const SELECTABLE_COLUMNS: {
  value: keyof PnrAggregate;
  label: string;
  category: string;
}[] = [
  { value: "numPicks", label: "# Picks", category: "General" },
  { value: "xppp", label: "xPPP", category: "General" },
  { value: "stderr", label: "SE", category: "General" },
  { value: "xpps", label: "xPPS", category: "General" },
  { value: "layupPct", label: "Layup %", category: "Shot Selection" },
  { value: "threePct", label: "3PA %", category: "Shot Selection" },
  { value: "nonLayupTwoPct", label: "NL2 %", category: "Shot Selection" },
  { value: "turnoverPct", label: "TO %", category: "Possession Outcome" },
  { value: "orbPct", label: "ORB %", category: "Possession Outcome" },
  { value: "pnrPassPct", label: "Pass %", category: "Ballhandler Outcome" },
  {
    value: "pnrPassScrPct",
    label: "Pass Scr %",
    category: "Ballhandler Outcome",
  },
  {
    value: "pnrAstOppPct",
    label: "Ast Opp %",
    category: "Ballhandler Outcome",
  },
  {
    value: "pnrAstScrPct",
    label: "Ast Scr %",
    category: "Ballhandler Outcome",
  },
  { value: "pnrToPct", label: "TO %", category: "Ballhandler Outcome" },
  { value: "ppp", label: "PPP", category: "Actual" },
  { value: "pps", label: "PPS", category: "Actual" },
  { value: "layupFgPct", label: "Layup FG%", category: "Actual" },
  { value: "threeFgPct", label: "3P FG%", category: "Actual" },
  { value: "nonLayupTwoFgPct", label: "NL2 FG%", category: "Actual" },
];

export function PnrExplorerPage() {
  const [queryParams, setQueryParams] = useQueryParams({
    sorting: JsonParam as QueryParamConfig<SortingState>,
    groupBy: withDefault(DelimitedArrayParam, ["oteamId"]) as QueryParamConfig<
      (keyof PnrAggregate)[]
    >,
    filters: withDefault(JsonParam, {
      season: [AppContext.currentSeason],
      defense: Object.keys(pnrLabels.defense).filter((k) => k !== "NT"),
    }) as QueryParamConfig<PnrFilters>,
    pnrThreshold: withDefault(NumberParam, 200),
    columns: withDefault(DelimitedArrayParam, [
      "numPicks",
      "xppp",
      "stderr",
    ]) as QueryParamConfig<(keyof PnrAggregate)[]>,
  });
  const [showColumnChooser, setShowColumnChooser] = useState(false);
  const [video, setVideo] = useState<PnrAggregate>();

  const { sorting, groupBy, filters, columns, pnrThreshold } = queryParams;

  const { data: pnrs } = trpc.pnr.getPnrAggregate.useQuery(
    {
      limit: RESULT_LIMIT,
      groupBy: groupBy.join(","),
      filters,
    },
    { enabled: groupBy.length > 0 }
  );

  function pnrToFilters(pnr: PnrAggregate) {
    const retObj: Record<string, unknown> = {};
    if (pnr) {
      for (const [key, value] of Object.entries(pnr)) {
        if (
          [
            "season",
            "period",
            "oteamId",
            "dteamId",
            "gameId",
            "ballhandlerId",
            "screenerId",
            "ballhandlerDefenderId",
            "screenerDefenderId",
            "positionBallhandler",
            "positionScreener",
            "positionBallhandlerDefender",
            "positionScreenerDefender",
            "defense",
            "bhrDefTypeComplex",
            "scrDefTypeComplex",
            "locationType",
            "rollPop",
            "direction",
            "takeRejectSlip",
            "coverage",
            "scrDefenderLevel",
          ].includes(key)
        ) {
          retObj[key] = [value + ""];
        } else if (
          ["horns", "double", "l", "handoffPick", "rescreen"].includes(key)
        ) {
          retObj[key] = value;
        } else if (key === "gameTypeId") {
          retObj["playoffs"] = value === "P" ? true : false;
        }
      }
    }
    return retObj;
  }

  const videoFilters = Object.assign(
    {},
    filters,
    video ? pnrToFilters(video) : {}
  );

  const { data: videoData } = trpc.pnr.getPnrVideo.useQuery(
    {
      limit: RESULT_LIMIT,
      filters: { ...videoFilters },
    },
    { enabled: !!video }
  );

  const clips = videoData
    ? videoData.filter((vd) => vd.synergyUrl).map(pnrToSynergyEditorClip)
    : [];

  const filteredPnrs = useMemo<PnrAggregate[]>(() => {
    return pnrs ? pnrs.filter((s) => s.numPicks >= pnrThreshold) : [];
  }, [pnrs, pnrThreshold]);

  const allData = pnrs ? pnrs : [];

  const msg = `Minimum PNRs: ${pnrThreshold.toLocaleString()} 
  (showing ${filteredPnrs.length.toLocaleString()} of
  ${allData.length.toLocaleString()} total rows)`;

  return (
    <Page title="PNR Explorer" header={{ text: "PNR Explorer" }}>
      <div>
        <Panel header={"Filters"}>
          <div>
            <PnrFilterForm
              filters={filters}
              onFilterChange={(f) => setQueryParams({ filters: f })}
            />
            <Form.Group>
              <Form.Label>Group By</Form.Label>
              <MultiSelect
                values={pnrGroupBys}
                selected={groupBy}
                onChange={(g) =>
                  setQueryParams({ groupBy: g as (keyof PnrAggregate)[] })
                }
              />
            </Form.Group>
            <Form style={{ marginTop: 12 }}>
              <div>
                <Form.Label>Select Columns</Form.Label>{" "}
                <ExpandCollapseButton
                  onClick={() => setShowColumnChooser(!showColumnChooser)}
                  expanded={showColumnChooser}
                />
              </div>
              <Collapse in={showColumnChooser}>
                <Row>
                  {Object.entries(
                    arrGroupBy(SELECTABLE_COLUMNS, (x) => x.category)
                  ).map(([cat, cols]) => {
                    return (
                      <Col key={cat} md={3}>
                        <div>
                          <Form.Label>{cat}</Form.Label>
                        </div>
                        {cols.map((sc) => {
                          return (
                            <Form.Check
                              type="checkbox"
                              key={sc.value}
                              inline
                              onChange={() => {
                                const idx = columns.findIndex(
                                  (c) => c === sc.value
                                );
                                const newColumns = [...columns];
                                if (idx === -1) {
                                  newColumns.push(sc.value);
                                } else {
                                  // Else remove the item from the array.
                                  newColumns.splice(idx, 1);
                                }
                                setQueryParams({ columns: newColumns });
                              }}
                              checked={columns.includes(sc.value)}
                              label={sc.label}
                            />
                          );
                        })}
                      </Col>
                    );
                  })}
                </Row>
              </Collapse>
            </Form>
          </div>
        </Panel>
        <Panel header={"Results"}>
          {pnrs ? (
            <div>
              {pnrs.length === RESULT_LIMIT
                ? showWarning(RESULT_LIMIT_MSG)
                : showSuccess(
                    `${pnrs.length.toLocaleString()} matching rows found.`
                  )}
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>{msg}</Form.Label>
                    <Form.Range
                      min={0}
                      max={1000}
                      value={pnrThreshold}
                      onChange={(evt) => {
                        setQueryParams({
                          pnrThreshold: parseInt(evt.target.value),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <PnrQueryResultsTable
                data={filteredPnrs}
                groupBy={groupBy}
                sorting={sorting}
                setSorting={(s) => setQueryParams({ sorting: s })}
                onVideo={(data: PnrAggregate) => setVideo(data)}
                columns={columns}
              />
              <VideoModal
                title={"PNR Explorer"}
                show={!!video && !!clips.length}
                clips={clips}
                handleClose={() => setVideo(undefined)}
                upDownClipSkip={true}
                showSynergyEditor={true}
              />
            </div>
          ) : (
            <div>
              {groupBy.length === 0 ? (
                <Alert variant="danger">
                  At least one group by must be selected.
                </Alert>
              ) : (
                <Spinner />
              )}
            </div>
          )}
        </Panel>
      </div>
    </Page>
  );
}

function showWarning(msg: string) {
  return <Alert variant="warning">{msg}</Alert>;
}

function showSuccess(msg: string) {
  return <Alert variant="success">{msg}</Alert>;
}
