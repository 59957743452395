import React, { ReactNode, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { trpc } from "../util/tRPC";
import {
  PlayersForLiveGame,
  CumulativeShotCount,
  ShotModelCoeffecient,
  ShotModelPlayerIntercept,
  SecondSpectrumChance,
  SecondSpectrumChancePlayer,
  SecondSpectrumFreeThrow,
  SecondSpectrumPossession,
  SecondSpectrumRebound,
  SecondSpectrumShot,
  SecondSpectrumTurnover,
  LiveGameDetails,
  LiveGameLineupModelPrediction,
} from "../../shared/routers/LiveGameRouter";
import { GameTeamShootingLuck } from "../components/games/GameTeamShootingLuck";
import { GamePageHeader } from "../components/games/GamePageHeader";
import { period, gameClockFormat } from "../util/Format";
import { ShotChartMakeMiss } from "../components/shots/ShotChartMakeMiss";
import { LiveGameCrashingViz } from "../components/live/LiveGameCrashingViz";
import { LiveGameFourFactorsTable } from "../components/live/LiveGameFourFactorsTable";
import { LiveGameRebounds } from "../components/live/LiveGameRebounds";
import { LiveGameLineupSummaryTable } from "../components/live/LiveGameLineupSummaryTable";
import { LiveGameLineupByTimeTable } from "../components/live/LiveGameLineupByTimeTable";
import {
  LiveGameStatsTable,
  LiveGamePlayerStatsRow,
} from "../components/live/LiveGameStatsTable";
import { Shot } from "../../shared/routers/ShotRouter";
import {
  xPtsFromShot,
  simplifyComplexShotType,
  isJumper,
} from "../util/ShotModel";
import { isFga, ppsForShot } from "../util/LivePageUtil";

export function GameLivePage() {
  const { id } = useParams();

  const { data: gameDetails } = trpc.liveGame.getLiveGameDetails.useQuery({
    gameId: id,
  });

  let eagleId = undefined;

  if (gameDetails && gameDetails[0] && gameDetails[0].eagleGameId) {
    eagleId = gameDetails[0].eagleGameId;
  }

  const { data: ssData } = trpc.liveGame.getLiveGameData.useQuery(
    {
      eagleGameId: eagleId,
    },
    {
      refetchInterval: 60000, // 60 seconds in milliseconds
    }
  );

  let homeEagle: string | undefined = undefined;
  if (gameDetails) {
    const gameDetailsData = gameDetails[0];
    if (gameDetailsData) {
      homeEagle = gameDetailsData.homeTeamEagleId;
    }
  }

  const playersByHomeAway = useMemo(() => {
    if (!gameDetails || gameDetails.length === 0 || !ssData)
      return {
        homePlayers: [],
        awayPlayers: [],
      };
    const homePlayerSet = new Set<string>();
    const awayPlayerSet = new Set<string>();

    const possessions = (ssData && ssData.ok && ssData.possessions) || [];
    for (const poss of possessions) {
      const homeOnOff = poss.offTeamId === homeEagle;
      if (homeOnOff) {
        poss.offPlayerIds.forEach((p) => homePlayerSet.add(p));
        poss.defPlayerIds.forEach((p) => awayPlayerSet.add(p));
      } else {
        poss.offPlayerIds.forEach((p) => awayPlayerSet.add(p));
        poss.defPlayerIds.forEach((p) => homePlayerSet.add(p));
      }
    }
    return { homePlayers: [...homePlayerSet], awayPlayers: [...awayPlayerSet] };
  }, [gameDetails, ssData, homeEagle]);

  const { data: players } = trpc.liveGame.getPlayersForLiveGame.useQuery({
    gameId: id,
    awayPlayerIds: playersByHomeAway.awayPlayers,
    homePlayerIds: playersByHomeAway.homePlayers,
  });

  const { data: shotCounts } = trpc.liveGame.getCumulativeShotCounts.useQuery({
    gameId: id,
  });

  const { data: shotModelCoefficients } =
    trpc.liveGame.getShotModelCoeffecients.useQuery();

  const { data: playerIntercepts } =
    trpc.liveGame.getShotModelPlayerIntercepts.useQuery({
      playerIds: players?.map((p) => p.eagleId) || [],
    });

  const { data: lineupPredictions } =
    trpc.liveGame.getLiveGameLineupModelPrediction.useQuery({
      teamIds:
        gameDetails && gameDetails[0]
          ? [gameDetails[0].homeTeamId, gameDetails[0].awayTeamId]
          : [],
    });

  const game = gameDetails && gameDetails[0];

  if (!game) {
    return null;
  }

  let content: ReactNode = null;

  if (
    ssData === undefined ||
    players === undefined ||
    shotModelCoefficients === undefined ||
    playerIntercepts === undefined ||
    shotCounts === undefined ||
    lineupPredictions === undefined
  ) {
    content = null;
  } else if (ssData.ok === false) {
    content = ssData.error;
  } else if (ssData.ok === true) {
    content = (
      <PageContent
        shots={ssData.shots}
        freeThrows={ssData.free_throws}
        possessions={ssData.possessions}
        turnovers={ssData.turnovers}
        rebounds={ssData.rebounds}
        chancePlayers={ssData.chance_players}
        chances={ssData.chances}
        players={players}
        shotModelCoefficients={shotModelCoefficients}
        playerIntercepts={playerIntercepts}
        shotCounts={shotCounts}
        details={game}
        lineupPredictions={lineupPredictions}
      />
    );
  }

  const ptsByQuarter: Record<
    number,
    {
      homePts: number;
      awayPts: number;
    }
  > = {
    1: {
      homePts: 0,
      awayPts: 0,
    },
    2: {
      homePts: 0,
      awayPts: 0,
    },
    3: {
      homePts: 0,
      awayPts: 0,
    },
    4: {
      homePts: 0,
      awayPts: 0,
    },
  };

  if (ssData && ssData.ok === true) {
    const shots = ssData.shots;
    const freeThrows = ssData.free_throws;

    for (const s of shots) {
      const period = s.period;
      const pts = ptsByQuarter[period];
      if (!ptsByQuarter[period]) {
        ptsByQuarter[period] = {
          homePts: 0,
          awayPts: 0,
        };
      }
      if (s.outcome && pts) {
        if (s.offTeamId === homeEagle) {
          pts.homePts += s.three ? 3 : 2;
        } else {
          pts.awayPts += s.three ? 3 : 2;
        }
      }
    }

    for (const ft of freeThrows) {
      const period = ft.period;
      const pts = ptsByQuarter[period];
      if (!ptsByQuarter[period]) {
        ptsByQuarter[period] = {
          homePts: 0,
          awayPts: 0,
        };
      }
      if (ft.outcome && pts) {
        if (ft.offTeamId === homeEagle) {
          pts.homePts += 1;
        } else {
          pts.awayPts += 1;
        }
      }
    }
  }

  const homeTeamId = game.homeTeamId;
  const awayTeamId = game.awayTeamId;

  const xPPPHome = null;
  const xPPPAway = null;
  const xPtsHome = null;
  const xPtsAway = null;

  let lastUpdatedStr: string | undefined = undefined;
  if (ssData && ssData.ok === true) {
    const lastChance = ssData.chances
      .filter((c) => c.outcomePbpId !== null)
      .sort((a, b) => (b.outcomePbpId || 0) - (a.outcomePbpId || 0))[0];
    lastUpdatedStr = `Last update: ${period(
      lastChance ? lastChance.period : 0
    )} ${gameClockFormat(lastChance ? lastChance.endGameClock : 0)}`;
  }

  return (
    <Page
      title={`${game.awayTeam} at ${game.homeTeam} • Live`}
      header={{
        component: (
          <GamePageHeader
            gameId={id}
            homeTeamId={homeTeamId}
            awayTeamId={awayTeamId}
            homeAbbr={game.homeTeamAbbr}
            awayAbbr={game.awayTeamAbbr}
            ptsByQuarter={ptsByQuarter}
            xPPPHome={xPPPHome}
            xPPPAway={xPPPAway}
            xPtsHome={xPtsHome}
            xPtsAway={xPtsAway}
            xWinPctHome={null}
            xWinPctAway={null}
            gameDateTimeStr={game.gametime}
            arenaname={game.arenaname}
            location={game.location}
            lastUpdatedStr={lastUpdatedStr}
          />
        ),
      }}
    >
      <div>{content}</div>
    </Page>
  );
}

function PageContent(props: {
  shots: SecondSpectrumShot[];
  freeThrows: SecondSpectrumFreeThrow[];
  possessions: SecondSpectrumPossession[];
  turnovers: SecondSpectrumTurnover[];
  rebounds: SecondSpectrumRebound[];
  chancePlayers: SecondSpectrumChancePlayer[];
  chances: SecondSpectrumChance[];
  shotModelCoefficients: ShotModelCoeffecient[];
  playerIntercepts: ShotModelPlayerIntercept[];
  players: PlayersForLiveGame[];
  shotCounts: CumulativeShotCount[];
  details: LiveGameDetails;
  lineupPredictions: LiveGameLineupModelPrediction[];
}) {
  const {
    shots,
    freeThrows,
    players,
    possessions,
    turnovers,
    rebounds,
    chancePlayers,
    chances,
    shotModelCoefficients,
    playerIntercepts,
    shotCounts,
    details,
    lineupPredictions,
  } = props;

  const {
    homeTeam,
    awayTeam,
    homeTeamAbbr,
    awayTeamAbbr,
    homeTeamId,
    awayTeamId,
    homeTeamEagleId,
    awayTeamEagleId,
    gameDate,
  } = details;

  const chanceMap = useMemo(() => {
    return new Map(chances.map((c) => [c.id, c]));
  }, [chances]);

  const chancePlayerMap = useMemo(() => {
    const map = new Map<string, SecondSpectrumChancePlayer[]>();
    for (const cp of chancePlayers) {
      const arr = map.get(cp.chanceId);
      if (arr) {
        arr.push(cp);
      } else {
        map.set(cp.chanceId, [cp]);
      }
    }
    return map;
  }, [chancePlayers]);

  const shotMap = useMemo(() => {
    return new Map(shots.map((s) => [s.chanceId, s]));
  }, [shots]);

  const reboundMap = useMemo(() => {
    return new Map(rebounds.map((r) => [r.chanceId, r]));
  }, [rebounds]);

  const playerMap = useMemo(() => {
    return new Map(players.map((p) => [p.eagleId, p]));
  }, [players]);

  const playerTimePlayMap = useMemo(() => {
    const timeMap = new Map(players.map((p) => [p.eagleId, 0]));
    for (const possession of possessions) {
      const possPlayers = possession.offPlayerIds.concat(
        possession.defPlayerIds
      );
      for (const possPlayer of possPlayers) {
        const time = timeMap.get(possPlayer);
        if (time !== undefined) {
          timeMap.set(
            possPlayer,
            time + (possession.startGameClock - possession.endGameClock)
          );
        }
      }
    }
    return timeMap;
  }, [players, possessions]);

  // TODO(chrisbu): Should we put this in a toggle for BIA-only?
  const enableFoulblending = true;

  const shotToXptsMap = useMemo(() => {
    const map = new Map<
      string,
      { xPts: number; xMake: number; xFoul: number; xPtsLg: number }
    >();
    for (const shot of shots) {
      const chance = chanceMap.get(shot.chanceId);
      if (!chance) continue;

      const defChancePlayers = chancePlayers.filter(
        (cp) => cp.chanceId === shot.chanceId && cp.offense === false
      );

      const defPlayerIntercepts = playerIntercepts.filter(
        (pi) => pi.playerId === shot.closestDefId && pi.offDef === "def"
      );

      const offPlayerIntercepts = playerIntercepts.filter(
        (pi) => pi.playerId === shot.shooterId && pi.offDef === "off"
      );

      const offPlayerLgIntercepts = playerIntercepts.filter(
        (pi) => pi.playerId === "lg_median" && pi.offDef === "off"
      );

      const defPlayerLgIntercepts = playerIntercepts.filter(
        (pi) => pi.playerId === "lg_median" && pi.offDef === "def"
      );

      const isShot = isJumper(shot);

      const lgMakeIntercept =
        offPlayerLgIntercepts.find(
          (pi) =>
            pi.outcome === "make" &&
            pi.type === (isShot ? "shot" : "finish") &&
            pi.complexShotType === shot.complexShotType
        )?.intercept || 0;
      const lgFoulIntercept =
        offPlayerLgIntercepts.find(
          (pi) =>
            pi.outcome === "foul" &&
            pi.type === (isShot ? "shot" : "finish") &&
            pi.complexShotType === shot.complexShotType
        )?.intercept || 0;

      const defPlayerMakeIntercept = defPlayerIntercepts.find(
        (pi) =>
          pi.outcome === "make" &&
          pi.type === (isShot ? "shot" : "finish") &&
          pi.complexShotType === shot.complexShotType
      ) ||
        defPlayerLgIntercepts.find(
          (pi) =>
            pi.outcome === "make" && pi.type === (isShot ? "shot" : "finish")
        ) || { intercept: 0, def_sr: 0 };

      const defPlayerFoulIntercept = defPlayerIntercepts.find(
        (pi) =>
          pi.outcome === "foul" &&
          pi.type === (isShot ? "shot" : "finish") &&
          pi.complexShotType === shot.complexShotType
      ) ||
        defPlayerLgIntercepts.find(
          (pi) =>
            pi.outcome === "foul" && pi.type === (isShot ? "shot" : "finish")
        ) || { intercept: 0, def_sr: 0 };

      const shooterMakeIntercept =
        offPlayerIntercepts.find(
          (pi) =>
            pi.outcome === "make" &&
            pi.type === (isShot ? "shot" : "finish") &&
            pi.complexShotType === shot.complexShotType
        )?.intercept || lgMakeIntercept;

      const shooterFoulIntercept =
        offPlayerIntercepts.find(
          (pi) =>
            pi.outcome === "foul" &&
            pi.type === (isShot ? "shot" : "finish") &&
            pi.complexShotType === shot.complexShotType
        )?.intercept || lgFoulIntercept;

      const shooterFtPct = playerMap.get(shot.shooterId)?.ftPct || 0.75;

      const shotCountRow = shotCounts.find(
        (sc) =>
          sc.shooterId === shot.shooterId &&
          sc.complexShotType === simplifyComplexShotType(shot.complexShotType)
      );

      const { xPts, xMake, xFoul } = xPtsFromShot(
        shot,
        chance,
        defChancePlayers,
        defPlayerMakeIntercept,
        defPlayerFoulIntercept,
        shooterMakeIntercept,
        shooterFoulIntercept,
        shotModelCoefficients,
        shooterFtPct,
        // For players that haven't shot a shot of this type yet just use 1.
        // We have to take the log of this value so 0 is probelmatic.
        shotCountRow ? shotCountRow.count : 1,
        enableFoulblending
      );

      map.set(shot.id, {
        xPts,
        xMake,
        xFoul,
        xPtsLg: xPtsFromShot(
          shot,
          chance,
          defChancePlayers,
          defPlayerMakeIntercept,
          defPlayerFoulIntercept,
          lgMakeIntercept,
          lgFoulIntercept,
          shotModelCoefficients,
          0.756,
          // For players that haven't shot a shot of this type yet just use 1.
          // We have to take the log of this value so 0 is probelmatic.
          shotCountRow ? shotCountRow.count : 1,
          enableFoulblending
        ).xPts,
      });
    }
    return map;
  }, [
    shots,
    chanceMap,
    chancePlayers,
    playerIntercepts,
    playerMap,
    shotCounts,
    shotModelCoefficients,
    enableFoulblending,
  ]);

  const playerShotRows: LiveGamePlayerStatsRow[] = useMemo(() => {
    return players
      .map((p) => {
        const playerShots = shots.filter((s) => s.shooterId === p.eagleId);
        const playerFts = freeThrows.filter((ft) => ft.shooterId === p.eagleId);
        const playerChancePlayers = chancePlayers.filter((cp) => {
          const chance = chanceMap.get(cp.chanceId);
          const chanceShot = shotMap.get(cp.chanceId);
          if (!chance || !chanceShot) return false;

          return (
            cp.playerId === p.eagleId &&
            cp.crashed !== null &&
            !(
              chance.startType === "FGORB" &&
              chance.startGameClock - chance.endGameClock < 4
            ) &&
            chanceShot.blocked === false
          );
        });

        return {
          eagleId: p.eagleId,
          playerId: p.playerId,
          playerName: p.playerName,
          homeAway: p.homeAway,
          min: playerTimePlayMap.get(p.eagleId),
          pts:
            playerFts.filter((ft) => ft.outcome).length +
            playerShots.filter((s) => s.outcome).length * 2 +
            playerShots.filter((s) => s.outcome && s.three).length,
          xPts: playerShots.reduce(
            (a, b) => a + (shotToXptsMap.get(b.id)?.xPts || 0),
            0
          ),
          xPtsLg: playerShots.reduce(
            (a, b) => a + (shotToXptsMap.get(b.id)?.xPtsLg || 0),
            0
          ),
          ptsWithxFt: playerShots.reduce(
            (a, b) =>
              a + ppsForShot(b, playerMap.get(b.shooterId)?.ftPct || 0.75),
            0
          ),
          shots: playerShots.length,
          fga: playerShots.filter(isFga).length,
          "2pa": playerShots.filter((s) => !s.three).length,
          "2pm": playerShots.filter((s) => !s.three && s.outcome).length,
          layupA: playerShots.filter((s) => s.shotType === "layup").length,
          layupM: playerShots.filter((s) => s.shotType === "layup" && s.outcome)
            .length,
          nl2A: playerShots.filter((s) => s.shotType !== "layup" && !s.three)
            .length,
          nl2M: playerShots.filter(
            (s) => s.shotType !== "layup" && !s.three && s.outcome
          ).length,
          "3pa": playerShots.filter((s) => s.three).length,
          "3pm": playerShots.filter((s) => s.three && s.outcome).length,
          fta: playerFts.length,
          ftm: playerFts.filter((ft) => ft.outcome).length,
          crashes: playerChancePlayers.filter((cp) => cp.crashed).length,
          crashOpps: playerChancePlayers.filter((cp) => cp.crashed !== null)
            .length,
        };
      })
      .sort((a, b) => (b.min || 0) - (a.min || 0))
      .filter((p) => playerTimePlayMap.get(p.eagleId));
  }, [
    chanceMap,
    chancePlayers,
    freeThrows,
    playerMap,
    playerTimePlayMap,
    players,
    shotMap,
    shotToXptsMap,
    shots,
  ]);

  const homePlayerShotRows = playerShotRows.filter(
    (p) => p.homeAway === "home"
  );
  const awayPlayerShotRows = playerShotRows.filter(
    (p) => p.homeAway === "away"
  );

  const homePlayerShots = useMemo(() => {
    const homePlayers = players.filter((p) => p.homeAway === "home");

    const playerEagleIds = homePlayers.map((h) => h.eagleId);
    return shots
      .filter((s) => playerEagleIds.includes(s.shooterId))
      .map((s) => {
        const shooter = playerMap.get(s.shooterId);
        const defender = playerMap.get(s.closestDefId);

        const shooterName = shooter ? shooter.playerName : "Unknown";
        const defenderName = defender ? defender.playerName : "Unknown";

        const xPts = shotToXptsMap.get(s.id)?.xPts || 0;
        const xPtsLg = shotToXptsMap.get(s.id)?.xPtsLg || 0;
        const xMake = shotToXptsMap.get(s.id)?.xMake || 0;
        const xFoul = shotToXptsMap.get(s.id)?.xFoul || 0;

        return transformSecondSpectrumShot(
          s,
          shooterName,
          defenderName,
          xPts,
          xPtsLg,
          xFoul,
          xMake,
          homeTeamId,
          playerMap.get(s.shooterId)?.ftPct || 0.75
        );
      });
  }, [players, shots, playerMap, shotToXptsMap, homeTeamId]);

  const awayPlayerShots = useMemo(() => {
    const awayPlayers = players.filter((p) => p.homeAway === "away");

    const playerEagleIds = awayPlayers.map((h) => h.eagleId);
    return shots
      .filter((s) => playerEagleIds.includes(s.shooterId))
      .map((s) => {
        const shooter = playerMap.get(s.shooterId);
        const defender = playerMap.get(s.closestDefId);

        const shooterName = shooter ? shooter.playerName : "Unknown";
        const defenderName = defender ? defender.playerName : "Unknown";

        const xPts = shotToXptsMap.get(s.id)?.xPts || 0;
        const xPtsLg = shotToXptsMap.get(s.id)?.xPtsLg || 0;
        const xMake = shotToXptsMap.get(s.id)?.xMake || 0;
        const xFoul = shotToXptsMap.get(s.id)?.xFoul || 0;

        return transformSecondSpectrumShot(
          s,
          shooterName,
          defenderName,
          xPts,
          xPtsLg,
          xFoul,
          xMake,
          awayTeamId,
          playerMap.get(s.shooterId)?.ftPct || 0.75
        );
      });
  }, [players, shots, playerMap, shotToXptsMap, awayTeamId]);

  const home = {
    teamid: homeTeamId,
    teamabbreviation: homeTeamAbbr,
    team: homeTeamAbbr,
    gameDateTimeStr: gameDate,
    teamCity: homeTeam,
    teamEagleId: homeTeamEagleId,
  };

  const away = {
    teamid: awayTeamId,
    teamabbreviation: awayTeamAbbr,
    team: awayTeamAbbr,
    gameDateTimeStr: gameDate,
    teamCity: awayTeam,
    teamEagleId: awayTeamEagleId,
  };

  return (
    <>
      <Row>
        <Col sm={6}>
          <Panel header="Team Shot Quality">
            <GameTeamShootingLuck
              data={homePlayerShots.concat(awayPlayerShots)}
              home={home}
              away={away}
              fromDate={home.gameDateTimeStr}
              toDate={home.gameDateTimeStr}
            />
          </Panel>
        </Col>
        <Col sm={6}>
          <Panel header="Four Factors">
            <LiveGameFourFactorsTable
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              homeTeamId={homeTeamEagleId}
              awayTeamId={awayTeamEagleId}
              possessions={possessions}
              turnovers={turnovers}
              shots={shots}
              freeThrows={freeThrows}
              rebounds={rebounds}
            />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Panel header={`${homeTeam} Boxscore`}>
            <LiveGameStatsTable data={homePlayerShotRows} />
          </Panel>
        </Col>
        <Col sm={6}>
          <Panel header={`${awayTeam} Boxscore`}>
            <LiveGameStatsTable data={awayPlayerShotRows} />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Panel header={`${homeTeam} Shots`}>
            <ShotChartPanel shots={homePlayerShots} />
          </Panel>
        </Col>
        <Col sm={6}>
          <Panel header={`${awayTeam} Shots`}>
            <ShotChartPanel shots={awayPlayerShots} />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Panel header={"Rebounds"}>
            <LiveGameRebounds
              playerEagleIds={players.map((p) => p.eagleId)}
              rebounds={rebounds}
              freeThrows={freeThrows}
              details={details}
              chanceMap={chanceMap}
              shotMap={shotMap}
              chancePlayerMap={chancePlayerMap}
            />
          </Panel>
        </Col>
        <Col sm={6}>
          <Panel header="Team Crashing">
            <LiveGameCrashingViz
              teams={[
                { teamId: homeTeamId, teamName: homeTeam },
                { teamId: awayTeamId, teamName: awayTeam },
              ]}
              players={players.map((p) => {
                return {
                  ...p,
                  teamId: p.homeAway === "home" ? homeTeamId : awayTeamId,
                };
              })}
              chancePlayers={chancePlayers}
              chances={chanceMap}
              rebounds={reboundMap}
              shots={shotMap}
            />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col>
          <Panel header="Lineup Summary">
            <LiveGameLineupSummaryTable
              details={details}
              playerMap={playerMap}
              possessions={possessions}
              chances={chances}
              lineupPredictions={lineupPredictions}
            />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col>
          <Panel header="Lineups By Time">
            <LiveGameLineupByTimeTable
              details={details}
              playerMap={playerMap}
              possessions={possessions}
              chances={chances}
              lineupPredictions={lineupPredictions}
            />
          </Panel>
        </Col>
      </Row>
    </>
  );
}

function transformSecondSpectrumShot(
  shot: SecondSpectrumShot,
  name: string,
  defName: string,
  xPts: number,
  xPtsLg: number,
  xFoul: number,
  xMake: number,
  offTeamId: number,
  xFTPct: number
) {
  return {
    shooter: name,
    defender: defName,
    locationY: (shot.location[1] || 0) * -1,
    locationX: shot.location[0] || 0,
    made: shot.outcome,
    fouled: shot.fouled,
    pps: ppsForShot(shot, xFTPct),
    epps: xPts,
    eppsLeague: xPtsLg,
    typeDefenderPlayerFoul: xFoul,
    typeDefenderPlayerMake: xMake,
    eppsActualFoul: xPts,
    isThree: shot.three,
    period: shot.period,
    gameClock: shot.endGameClock,
    generalShotType: shot.shotType,
    complexShotType: shot.complexShotType,
    shotDist: shot.distance,
    url: "",
    oteamId: offTeamId,
  } as Shot;
}
const periods = { "Full Game": 0, Q1: 1, Q2: 2, Q3: 3, Q4: 4, OT: 5 };

function ShotChartPanel(props: { shots: Shot[] }) {
  const { shots } = props;
  const [period, setPeriod] = useState(0);
  const [player, setPlayer] = useState<string>("All Players");

  const filteredShots = shots
    .filter((s) => {
      if (player === "All Players") {
        return true;
      }
      return s.shooter === player;
    })
    .filter((s) => {
      if (period === 0) {
        return true;
      } else if (period === 5) {
        return s.period >= 5;
      }
      return s.period === period;
    });

  const gameWentToOvertime = shots.some((s) => s.period >= 5);

  const shooters = [...new Set(shots.map((s) => s.shooter))];

  return (
    <>
      <div style={{ display: "flex", gap: 8, marginBottom: 8 }}>
        <select
          value={period}
          onChange={(e) => setPeriod(parseInt(e.currentTarget.value))}
        >
          {Object.entries(periods)
            .filter(([, value]) => gameWentToOvertime || value < 5)
            .map(([key, value]) => (
              <option key={value} value={value}>
                {key}
              </option>
            ))}
        </select>
        <select
          value={player}
          onChange={(e) => setPlayer(e.currentTarget.value)}
        >
          <option value={"All Players"}>All Players</option>
          {shooters.map((s) => (
            <option key={s} value={s}>
              {s}
            </option>
          ))}
        </select>
      </div>
      <ShotChartMakeMiss
        shooterName={true}
        maxMarkWidth={12}
        shots={filteredShots}
      />
    </>
  );
}
