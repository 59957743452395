import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";

import { TooltipItem } from "../core/TooltipItem";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      margin: 8,
    },
    wideToolTip: {
      "& .tooltip-inner": {
        textAlign: "left",
        maxWidth: "unset",
      },
    },
    list: {
      display: "inline",
      listStyleType: "none",
      padding: 0,
    },
  })
);

export function PlayerRatingSpeedToolTip(props: {
  children: JSX.Element | string;
}) {
  const classes = useStyles();
  const tooltipContent = (
    <div className={classes.container}>
      <ul className={classes.list}>
        <li>
          <b>0</b>: fastest guy in the world
        </li>
        <li>
          <b>1</b>: average NBA 1 speed
        </li>
        <li>
          <b>2</b>: average NBA 2 speed
        </li>
        <li>
          <b>3</b>: average NBA 3 speed
        </li>
        <li>
          <b>4</b>: average NBA 4 speed
        </li>
        <li>
          <b>5</b>: average NBA 5 speed
        </li>
        <li>
          <b>6</b>: slowest guy in the world
        </li>
      </ul>
    </div>
  );
  return (
    <TooltipItem
      tooltip={tooltipContent}
      tooltipClassName={classes.wideToolTip}
      noListItem={true}
    >
      {props.children}
    </TooltipItem>
  );
}
