import * as math from "mathjs";

import {
  SecondSpectrumChance,
  SecondSpectrumRebound,
  ReboundModelFixedEffects,
  ReboundModelRandomEffects,
  PossessionCovariates,
  LiveGameDetails,
} from "../../shared/routers/LiveGameRouter";

export function normalizedReboundProb(
  pReb: number,
  isOffense: boolean,
  team_ORp_estimate: number,
  sum_eREB_player_off: number,
  sum_eREB_player_def: number
) {
  return isOffense
    ? pReb * (team_ORp_estimate / sum_eREB_player_off)
    : pReb * ((1 - team_ORp_estimate) / sum_eREB_player_def);
}

export function teamOrpEstimate(
  possessionCovariates: PossessionCovariates,
  secondaryFixedEffects: ReboundModelFixedEffects[],
  playerRandomEffects: ReboundModelRandomEffects[],
  eREB_off: number[],
  eREB_def: number[],
  fgReb: boolean
) {
  const fixedEffectsMap = new Map<string, number>();
  secondaryFixedEffects.forEach((fe) => {
    fixedEffectsMap.set(fe.variable, fe.coefficient);
  });

  const getCoeff = (variable: string) => {
    return fixedEffectsMap.get(variable) || 0;
  };

  const sum_eREB_player_off = eREB_off.reduce((a, b) => a + b, 0);
  const sum_eREB_player_off_log = eREB_off.reduce(
    (a, b) => a + Math.log(b || 0.00001),
    0
  );

  const sum_eREB_player_def = eREB_def.reduce((a, b) => a + b, 0);
  const sum_eREB_player_def_log = eREB_def.reduce(
    (a, b) => a + Math.log(b || 0.00001),
    0
  );

  const size_of_OR_space = sum_eREB_player_off + sum_eREB_player_def;
  const implied_ORp =
    sum_eREB_player_off / (sum_eREB_player_off + sum_eREB_player_def);

  const ORp_gap_mr_players_season_effect = playerRandomEffects.reduce(
    (a, b) => a + b.sum_ORp_gap / (b.n_ORAvail + 2000),
    0
  );

  const val =
    getCoeff(`I(as.factor(season))${possessionCovariates.season}`) +
    possessionCovariates.OLead * getCoeff("OLead") +
    possessionCovariates.game_clock_over_570 * getCoeff("game_clock_over_570") +
    possessionCovariates.game_clock_under_90 * getCoeff("game_clock_under_90") +
    possessionCovariates.game_clock_under_8 * getCoeff("game_clock_under_8") +
    (possessionCovariates.offhome ? 1 : 0) * getCoeff("offhomeTRUE") +
    (possessionCovariates.period === 1 ? 1 : 0) *
      getCoeff(`I(quarter_factor == "Q1")TRUE`) +
    sum_eREB_player_off_log * getCoeff("sum_eREB_player_off_log") +
    sum_eREB_player_def_log * getCoeff("sum_eREB_player_def_log") +
    ORp_gap_mr_players_season_effect *
      getCoeff("ORp_gap_mr_players_season_effect") +
    (size_of_OR_space < 0.9 ? 0.9 : size_of_OR_space) *
      (fgReb ? 1 : 0) *
      getCoeff(
        "I(ifelse(size_of_OR_space < 0.9, 0.9, size_of_OR_space) * fgReb)"
      ) +
    qlogis(implied_ORp) * getCoeff("I(qlogis(implied_ORp))") +
    (implied_ORp > 0.35 ? 0.35 : implied_ORp) *
      getCoeff("I(ifelse(implied_ORp > 0.35, 0.35, implied_ORp))") +
    (implied_ORp > 0.25 ? 0.25 : implied_ORp) *
      (fgReb ? 1 : 0) *
      getCoeff("I(ifelse(implied_ORp > 0.25, 0.25, implied_ORp) * fgReb)") +
    getCoeff("(Intercept)");

  return 1 - 1 / (1 + Math.exp(-val));
}

/** Run the rebound model on demand and get the p(reb) for a player and a shot attempt. */
export function xRebForPlayer(
  player: {
    eagleId: string;
    playerTeamFactor: string;
    offense: boolean;
    rimLoc: number[] | null;
    shotLoc: number[] | null;
    rbPctShot: number | null;
    rbPctRim: number | null;
  },
  shot: {
    shooterId: string;
    closestDefId: string;
    complexShotType: string;
    distance: number;
  },
  rebound: SecondSpectrumRebound,
  fixedEffects: ReboundModelFixedEffects[],
  randomEffects: ReboundModelRandomEffects[],
  possessionCovariates: PossessionCovariates
) {
  // For now just give the offensive team 12.26%, the def team 87.74% and all
  // players 0 for simplicity.
  if (!shot || rebound.fgReb === false) {
    if (player.playerTeamFactor === "player") {
      return 0;
    } else if (player.playerTeamFactor === "offensive_team") {
      return 0.1226;
    } else if (player.playerTeamFactor === "defensive_team") {
      return 0.8774;
    }
  }

  if (fixedEffects.length === 0 || randomEffects.length === 0) return 0;

  const getCoeff = (variable: string) => {
    if (fixedEffects === undefined) return 0;

    const coeff = fixedEffects.find((fe) => fe.variable === variable);
    return coeff ? coeff.coefficient : 0;
  };

  const getPlayerCoeff = (playerId: string, offDef: string) => {
    if (randomEffects === undefined) return [0, 0];

    const coeff = randomEffects.find(
      (fe) =>
        fe.eagle === playerId &&
        fe.offDef === offDef &&
        fe.season === possessionCovariates.season.toString()
    );
    return coeff
      ? [coeff.playerOffDef_season_effect, coeff.playerOffDef_effect]
      : [0, 0];
  };

  const { period, startGameClock, OLead, day_of_season, season } =
    possessionCovariates;

  const eagleId = player.eagleId;
  const isPeriod1 = period === 1 ? 1 : 0;
  const isFgReb = rebound.fgReb ? 1 : 0;
  const offDef = player.offense ? 1 : 0;
  const playerTeamFactor = player.playerTeamFactor;
  const isClosestDefender = shot.closestDefId === eagleId ? 1 : 0;
  const isShooter = shot.shooterId === eagleId ? 1 : 0;
  const isDrivingLayup = shot.complexShotType === "drivingLayup" ? 1 : 0;
  const isCutLayup = shot.complexShotType === "cutLayup" ? 1 : 0;
  const rbPctShot = (player.rbPctShot || 0) / 100;
  const rbPctRim = (player.rbPctRim || 0) / 100;
  const inverseSquareOfRebPct = 1 / (rbPctShot + 0.1) ** 2;
  const rimRimDistance = distanceToRim(player.rimLoc);
  const shotRimDistance = distanceToRim(player.shotLoc);
  const distToRimAfterShot = shotRimDistance - rimRimDistance;
  const transformedCrash01 = crashTransform(rbPctRim, rbPctShot) || 0;
  const distanceOfShot = shot.distance;
  const rimLocX = player.rimLoc ? player.rimLoc[0] || 0 : 0;

  const playerCoeff = getPlayerCoeff(eagleId, offDef == 1 ? "off" : "def");

  const unscaledVal =
    isPeriod1 * getCoeff(`I(quarter_factor == "Q1")TRUE`) +
    startGameClock * getCoeff("startGameClock") +
    OLead * getCoeff("OLead") +
    day_of_season * getCoeff("day_of_season") +
    (playerTeamFactor === "defensive_team"
      ? 0
      : getCoeff(`player_team_factor${playerTeamFactor}`)) +
    isFgReb * getCoeff("fgRebTRUE") +
    (playerTeamFactor !== "defensive_team" ? 1 : 0) *
      isFgReb *
      getCoeff(`player_team_factor${playerTeamFactor}:fgRebTRUE`) +
    offDef * getCoeff("offDefoff") +
    offDef * isFgReb * getCoeff(`fgRebTRUE:offDefoff`) +
    isClosestDefender * getCoeff("wasClosestDef") +
    isShooter * getCoeff("wasShooter") +
    isDrivingLayup * getCoeff("drivingLayup") +
    isShooter * isDrivingLayup * getCoeff("wasShooter:drivingLayup") +
    isCutLayup * getCoeff("cutLayup") +
    isShooter * isCutLayup * getCoeff("wasShooter:cutLayup") +
    rbPctShot * getCoeff("rbPctShot") +
    rbPctShot * isFgReb * getCoeff("fgRebTRUE:rbPctShot") +
    rbPctShot * offDef * getCoeff("offDefoff:rbPctShot") +
    inverseSquareOfRebPct * getCoeff("I(1/(rbPctShot + 0.1)^2)") +
    inverseSquareOfRebPct *
      offDef *
      getCoeff("offDefoff:I(1/(rbPctShot + 0.1)^2)") +
    distToRimAfterShot * getCoeff("dist_to_rim_after_shot") +
    (distToRimAfterShot > 4 ? 4 : distToRimAfterShot) *
      offDef *
      getCoeff(
        `I(ifelse(dist_to_rim_after_shot > 4, 4, dist_to_rim_after_shot) * ifelse(offDef == "off", 1, 0))`
      ) +
    (rimRimDistance > 30 ? 30 : rimRimDistance) *
      offDef *
      getCoeff(
        `I(ifelse(rim_rim_distance > 30, 30, rim_rim_distance) * ifelse(offDef == "off", 1, 0))`
      ) +
    (rimRimDistance > 30 ? 30 : rimRimDistance) *
      isFgReb *
      getCoeff(
        `I(ifelse(rim_rim_distance > 30, 30, rim_rim_distance) * fgReb)`
      ) +
    (rimRimDistance < 7 ? 7 : rimRimDistance) *
      (rebound.fgReb ? 1 : 0) *
      getCoeff(`I(ifelse(rim_rim_distance < 7, 7, rim_rim_distance) * fgReb)`) +
    rimRimDistance * getCoeff("rim_rim_distance") +
    rimRimDistance *
      (rebound.fgReb ? 1 : 0) *
      getCoeff("fgRebTRUE:rim_rim_distance") +
    distanceOfShot * getCoeff("distance_of_shot") +
    distanceOfShot * offDef * getCoeff("offDefoff:distance_of_shot") +
    distanceOfShot *
      rimRimDistance *
      getCoeff("rim_rim_distance:distance_of_shot") +
    transformedCrash01 * getCoeff("transformedCrash01") +
    transformedCrash01 * offDef * getCoeff("offDefoff:transformedCrash01") +
    getCoeff(`I(as.factor(season))${season + 1}`) +
    Math.log(-(rimLocX > -43 ? -43 : rimLocX)) *
      getCoeff("I(log(-ifelse(rimLocX > (-43), -43, rimLocX)))") +
    (playerCoeff ? playerCoeff[0] || 0 : 0) +
    (playerCoeff ? playerCoeff[1] || 0 : 0) +
    getCoeff("(Intercept)");
  return 1 / (1 + Math.exp(-unscaledVal));
}

export function distanceToRim(loc: number[] | null) {
  if (loc === null || loc.length !== 2) return 0;
  const x = loc[0] || 0;
  const y = loc[1] || 0;
  const hoopX = -41.75;
  const hoopY = 0;
  return Math.sqrt((x - hoopX) * (x - hoopX) + (y - hoopY) * (y - hoopY));
}

function pnorm(x: number) {
  return 0.5 * (1 + math.erf(x / Math.sqrt(2)));
}

function qnorm(x: number) {
  return Math.sqrt(2) * erfinv(2 * x - 1);
}

function erfinv(x: number) {
  let z;
  const a = 0.147;
  let the_sign_of_x;
  if (0 == x) {
    the_sign_of_x = 0;
  } else if (x > 0) {
    the_sign_of_x = 1;
  } else {
    the_sign_of_x = -1;
  }

  if (0 != x) {
    const ln_1minus_x_sqrd = Math.log(1 - x * x);
    const ln_1minusxx_by_a = ln_1minus_x_sqrd / a;
    const ln_1minusxx_by_2 = ln_1minus_x_sqrd / 2;
    const ln_etc_by2_plus2 = ln_1minusxx_by_2 + 2 / (Math.PI * a);
    const first_sqrt = Math.sqrt(
      ln_etc_by2_plus2 * ln_etc_by2_plus2 - ln_1minusxx_by_a
    );
    const second_sqrt = Math.sqrt(first_sqrt - ln_etc_by2_plus2);
    z = second_sqrt * the_sign_of_x;
  } else {
    // x is zero
    z = 0;
  }
  return z;
}

function qlogis(p: number) {
  return Math.log(p / (1 - p));
}

export function crashTransform(rbPctRim: number, rbPctShot: number) {
  return pnorm(qnorm(rbPctRim) - qnorm(rbPctShot));
}

// Given a bunch of information about the game, chance, etc create the
// possession covariates that will be used in the model.
export function parsePossessionCovariates(
  details: LiveGameDetails,
  chance: SecondSpectrumChance,
  leverageModelEffects: ReboundModelFixedEffects[]
): PossessionCovariates {
  const offHome = details.homeTeamEagleId === chance.offTeamId;
  const offLead = offHome
    ? chance.homeStartScore - chance.awayStartScore
    : chance.awayStartScore - chance.homeStartScore;
  const oLeadTruncated = offLead < -20 ? 20 : Math.abs(offLead);

  const dayOfSeason = details.dayOfSeason;
  const backToBackOff = offHome
    ? details.homeDaysRest === 0
    : details.awayDaysRest === 0;

  const leverageFixedEffectsMap = new Map<string, number>();
  leverageModelEffects.forEach((fe) => {
    leverageFixedEffectsMap.set(fe.variable, fe.coefficient);
  });

  const getCoeff = (variable: string) => {
    return leverageFixedEffectsMap.get(variable) || 0;
  };

  const secondsLeft =
    chance.period > 3
      ? chance.startGameClock
      : chance.startGameClock + (4 - chance.period) * 720;

  const unscaledLeverageVal =
    offLead * offLead * getCoeff("I(OLead^2)") +
    Math.log(secondsLeft + 100) * getCoeff("I(log(SecondsLeft + 100))") +
    offLead *
      offLead *
      Math.log(secondsLeft + 100) *
      getCoeff("I(OLead^2 * log(SecondsLeft + 100))") +
    (offLead / (secondsLeft + 10)) * getCoeff("I(OLead/(SecondsLeft + 10))") +
    ((offLead * offLead) / (secondsLeft + 10)) *
      getCoeff("I(OLead^2/(SecondsLeft + 10))") +
    getCoeff("(Intercept)");

  const leverage = 1 / (1 + Math.exp(-unscaledLeverageVal));

  return {
    offhome: offHome,
    offTeamId: chance.offTeamId,
    defTeamId: chance.defTeamId,
    period: chance.period,
    season: chance.season,
    startGameClock: chance.startGameClock,
    OLead: offLead,
    abs_OLead_truncated: oLeadTruncated,
    day_of_season: dayOfSeason || 0,
    leverage,
    game_clock_over_570:
      chance.startGameClock < 570 ? 570 : chance.startGameClock,
    game_clock_under_90:
      chance.startGameClock > 90 ? 90 : chance.startGameClock,
    game_clock_under_8: chance.startGameClock > 8 ? 8 : chance.startGameClock,
    back_to_back_off: backToBackOff,
  };
}
