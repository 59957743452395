import React, { useMemo, useState } from "react";

import { decFormat, decFormat2, dec100Format } from "../../util/Format";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import { Highlights } from "../../constants/AppConstants";
import { trpc } from "../../util/tRPC";
import { PlayerDrives } from "../../../shared/routers/PlayerRouter";

const columnHelper = createColumnHelper<PlayerDrives>();

export function PlayerDrivesTable(props: { playerId: number }) {
  const { playerId } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const { data: drives } = trpc.player.getPlayerDrives.useQuery({ playerId });

  const columns = useMemo(
    () => [
      columnHelper.accessor("season", {
        header: () => "Season",
        cell: (info) => info.getValue(),
        meta: { group: 0 },
      }),
      columnHelper.accessor("drives", {
        header: () => "Drives",
        cell: (info) => info.getValue(),
        meta: {
          highlights: Highlights.Max,
          group: 1,
          colorDomain: [3, 820],
          neutralColorScheme: true,
        },
      }),
      columnHelper.accessor("drivesPerGame", {
        header: () => "Drives / G",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: 1,
          colorDomain: [1, 11.53],
          neutralColorScheme: true,
        },
      }),
      columnHelper.accessor("PPP", {
        header: () => "PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: 2,
          colorDomain: [0.8, 1.2],
        },
      }),
      columnHelper.accessor("pctFromPnr", {
        header: () => "% from PNR",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: 3,
          colorDomain: [0, 0.693],
          neutralColorScheme: true,
        },
      }),
      columnHelper.accessor("pctRim", {
        header: () => "Rim %",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: 4,
          colorDomain: [0.195, 0.767],
        },
      }),
      columnHelper.accessor("pctPullup", {
        header: () => "Pullup %",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: 4,
          colorDomain: [0, 0.167],
          neutralColorScheme: true,
        },
      }),
      columnHelper.accessor("pctDumpoff", {
        header: () => "Dumpoff %",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: 4,
          colorDomain: [0, 0.223],
          neutralColorScheme: true,
        },
      }),
      columnHelper.accessor("pctKickout", {
        header: () => "Kickout %",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          group: 4,
          colorDomain: [0, 0.451],
          neutralColorScheme: true,
        },
      }),
      columnHelper.accessor("pctTurnover", {
        header: () => "TO %",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          highlights: Highlights.Min,
          group: 5,
          colorDomain: [0.167, 0],
        },
      }),
    ],
    []
  );

  if (!drives) return null;

  return (
    <Table
      data={drives.sort((a, b) => a.season - b.season)}
      columns={columns}
      autoWidth={true}
      showColorOnHover={true}
      sorting={sorting}
      setSorting={setSorting}
    />
  );
}
