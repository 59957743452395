import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { Table, createColumnHelper } from "../core/Table";

import {
  minutesFormat,
  fractionFormat,
  plusMinusIntFormat,
} from "../../util/Format";
import { SynergyPlayerMinutesGame } from "../../../shared/routers/SynergyRouter";
import { TeamTableCell } from "../core/TableCell";

const columnHelper = createColumnHelper<SynergyPlayerMinutesGame>();

export function PlayerMinutesGameBoxScores(props: {
  data: SynergyPlayerMinutesGame[];
}) {
  const { data } = props;

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("league", {
        header: "League",
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("team", {
        header: "Team",
        cell: (info) => (
          <TeamTableCell
            name={info.row.original.team}
            ids={info.row.original.teamIdsId || undefined}
            id={info.row.original.teamId}
            league={info.row.original.leagueKey}
            season={info.row.original.Season.toString()}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("gameDate", {
        header: "Date",
        cell: (info) => moment(info.getValue()).format("MM/DD/YYYY"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("opponent", {
        header: "Opp",
        cell: (info) => (
          <TeamTableCell
            name={info.row.original.opponent}
            ids={info.row.original.opponentIdsId || undefined}
            id={info.row.original.opponentId}
            league={info.row.original.leagueKey}
            season={info.row.original.Season.toString()}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("wonGame", {
        header: "W/L",
        cell: (info) => {
          const val = info.getValue();
          const wonLossStr = val === 1 ? "W" : "L";
          const leagueKey = info.row.original.leagueKey;
          const gameId = info.row.original.gameID;

          if (leagueKey === "nba" || leagueKey === "nba_preseason") {
            return (
              <Link title={"Go to game page"} to={`/game/${gameId}`}>
                {wonLossStr}
              </Link>
            );
          }

          return (
            <Link
              title={"Go to game page"}
              to={`/game/ml/${leagueKey}/${gameId}`}
            >
              {wonLossStr}
            </Link>
          );
        },
        meta: { group: g++ },
      }),
      columnHelper.accessor("minutes", {
        header: "Min",
        cell: (info) => minutesFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("PTS", {
        header: "PTS",
        meta: { group: g },
      }),
      columnHelper.accessor("rtot", {
        header: "REB",
        meta: { group: g },
      }),
      columnHelper.accessor("AST", {
        header: "AST",
        meta: { group: g++ },
      }),
      columnHelper.accessor(
        (row) => (row.fg2a === 0 ? null : row.fg2m / row.fg2a),
        {
          header: "2PM/A",
          cell: (info) =>
            info.getValue() === null
              ? null
              : fractionFormat({
                  numerator: info.row.original.fg2m,
                  denominator: info.row.original.fg2a,
                }),
          meta: { group: g },
        }
      ),
      columnHelper.accessor(
        (row) => (row.fg3a === 0 ? null : row.fg3m / row.fg3a),
        {
          header: "3PM/A",
          cell: (info) =>
            info.getValue() === null
              ? null
              : fractionFormat({
                  numerator: info.row.original.fg3m,
                  denominator: info.row.original.fg3a,
                }),
          meta: { group: g },
        }
      ),
      columnHelper.accessor(
        (row) => (row.fg3a === 0 ? null : row.FTM / row.FTA),
        {
          header: "FTM/A",
          cell: (info) =>
            info.getValue() === null
              ? null
              : fractionFormat({
                  numerator: info.row.original.FTM,
                  denominator: info.row.original.FTA,
                }),
          meta: { group: g++ },
        }
      ),
      columnHelper.accessor("roff", {
        header: "ORB",
        meta: { group: g },
      }),
      columnHelper.accessor("rdef", {
        header: "DRB",
        meta: { group: g++ },
      }),
      columnHelper.accessor("BLK", {
        header: "BLK",
        meta: { group: g },
      }),
      columnHelper.accessor("STL", {
        header: "STL",
        meta: { group: g++ },
      }),
      columnHelper.accessor("turn", {
        header: "TOV",
        meta: { group: g },
      }),
      columnHelper.accessor("PF", {
        header: "PF",
        meta: { group: g++ },
      }),
      columnHelper.accessor("plusminus", {
        header: "+/-",
        cell: (info) => plusMinusIntFormat(info.getValue()),
        meta: { group: g },
      }),
    ];
  }, []);

  return <Table data={data} columns={columns} autoWidth={true} />;
}
