import React, { useState } from "react";
import cx from "classnames";
import { createStyles, makeStyles } from "@material-ui/styles";

export type Tabs = { tabs: Record<string, Tab> };

interface Tab {
  label: string;
  sublinks: { label: string; refId: string }[];
  content: JSX.Element;
}

const useStyles = makeStyles(() =>
  createStyles({
    pageTabsContainer: {
      margin: "-20px -10px 0",
    },
    pageTabs: {
      backgroundColor: "#272831",
      fontSize: 12,
      textTransform: "uppercase",
      listStyleType: "none",
      marginBottom: 15,
      paddingLeft: 15,
      overflowX: "scroll",
      whiteSpace: "nowrap",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "& li": {
        display: "inline-block",
        cursor: "pointer",
        marginRight: 10,
        padding: "5px 10px",
        color: "rgba(255,255,255,.6)",
        textDecoration: "none",
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: "rgba(0,0,0,.2)",
      },
      "& li.active": {
        color: "rgba(255,255,255,.9)",
        backgroundColor: "#14151b",
      },
    },
    pageTabSubLinks: {
      fontSize: 12,
      margin: "-10px 5px 5px",
      paddingBottom: 10,
      listStyleType: "none",
      paddingLeft: 15,
      marginBottom: 0,
      overflowX: "scroll",
      whiteSpace: "nowrap",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "& li": {
        display: "inline-block",
        marginRight: 15,
        color: "#3796E8",
        cursor: "pointer",
      },
    },
  })
);

export function PageTabs(props: {
  activeTabKey: string;
  tabs: Tabs;
  onTabClick: (tabKey: string) => void;
  onSubLinkClick: (subLinkRef: string) => void;
}) {
  const classes = useStyles();
  const { tabs, activeTabKey, onTabClick, onSubLinkClick } = props;
  const [previewTabKey, setPreviewTabKey] = useState<string | null>();

  const activeTab = tabs.tabs[activeTabKey];

  let previewTab: Tab | undefined;
  if (tabs && previewTabKey && activeTabKey !== previewTabKey) {
    previewTab = tabs.tabs[previewTabKey];
  }

  let pageTabsLinks, pageTabsSubLinks;
  if (tabs) {
    pageTabsLinks = (
      <ul className={classes.pageTabs}>
        {Object.keys(tabs.tabs).map((tabKey) => {
          const tab = tabs.tabs[tabKey];
          const isActive = tab === activeTab;
          const isPreviewed = tab === previewTab;
          if (!tab) return null;
          return (
            <li
              key={tabKey}
              className={cx({ active: isActive, previewed: isPreviewed })}
              onClick={() => onTabClick(tabKey)}
              onMouseEnter={() => setPreviewTabKey(tabKey)}
            >
              {tab.label}
            </li>
          );
        })}
      </ul>
    );

    const linksTab = previewTab || activeTab;

    if (linksTab && linksTab.sublinks.length) {
      pageTabsSubLinks = (
        <ul className={classes.pageTabSubLinks}>
          {linksTab.sublinks.map((sublink, i) => {
            return (
              <li key={i} onClick={() => onSubLinkClick(sublink.refId)}>
                {sublink.label}
              </li>
            );
          })}
        </ul>
      );
    }
  }

  return (
    <div
      className={classes.pageTabsContainer}
      onMouseLeave={() => setPreviewTabKey(null)}
    >
      {pageTabsLinks}
      {pageTabsSubLinks}
    </div>
  );
}
