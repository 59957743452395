import React, { useMemo } from "react";
import { ParentSize } from "@visx/responsive";
import { LinePath, Polygon } from "@visx/shape";
import { curveStepAfter } from "@visx/curve";
import { scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import { AxisBottom, AxisLeft } from "@visx/axis";

import { TeamSubstitutionPattern } from "../../../shared/routers/TeamRouter";
import { colorFromPosition } from "../../util/Colors";
import { groupBy } from "../../../shared/util/Collections";

export interface PlayerSubstitutionData {
  playerId: number;
  gp: number;
  maxCount: number;
  firstName: string;
  lastName: string;
  position: number;
  quarterData: Record<number, { time: number; count: number }[]>;
}

export function TeamSubstitutionPatternChart(props: {
  numGames: number;
  data: TeamSubstitutionPattern[];
}) {
  const { numGames, data } = props;

  const playerData = useMemo(() => {
    const playerIds = [...new Set(data.map((d) => d.playerId))];
    return playerIds
      .map((id) => {
        const playerRows = data.filter((g) => g.playerId === id);
        const firstPlayerRow = playerRows[0];
        const byQuarter = groupBy(playerRows, (r) => r.period.toString());

        let maxCount = 0;
        const quarterData: Record<number, { time: number; count: number }[]> =
          {};

        for (let quarter = 1; quarter <= 4; quarter++) {
          const points = prepareCumulativeQuarterData(byQuarter[quarter] || []);
          quarterData[quarter] = points;

          // Update max count.
          maxCount = Math.max(...points.map((p) => p.count), maxCount);
        }

        return {
          playerId: id,
          gp: [...new Set(playerRows.map((d) => d.gameId))].length,
          maxCount,
          firstName: firstPlayerRow ? firstPlayerRow.firstName : "",
          lastName: firstPlayerRow ? firstPlayerRow.lastName : "",
          position: firstPlayerRow ? firstPlayerRow.position : 0,
          quarterData: quarterData,
        };
      })
      .sort((a, b) => a.position - b.position);
  }, [data]);

  return (
    <ParentSize>
      {(parent) => (
        <div style={{ marginBottom: 50 }}>
          {playerData.map((player, i) => (
            <PlayerSubstitutionPatternChart
              data={player}
              width={parent.width}
              numGames={numGames}
              key={i}
              timeAxis={i === playerData.length - 1}
            />
          ))}
        </div>
      )}
    </ParentSize>
  );
}

function PlayerSubstitutionPatternChart(props: {
  data: PlayerSubstitutionData;
  width: number;
  numGames: number;
  timeAxis: boolean;
}) {
  const { data, numGames, timeAxis, width } = props;

  const timeAxisSize = timeAxis ? 36 : 0;
  const height = Math.max(25, Math.min(numGames < 2 ? 25 : data.gp * 5, 40));

  const xScale = scaleLinear<number>({
    range: [0, width / 4],
    domain: [12, 0],
  });

  const yScale = scaleLinear<number>({
    range: [height, 0],
    domain: [0, 1],
  });

  return (
    <div
      style={{
        borderBottom: "1px solid rgba(0,0,0,.15)",
        height,
        marginTop: 4,
        marginBottom: 4,
      }}
    >
      <div
        style={{
          position: "absolute",
        }}
      >{`${data.firstName} ${data.lastName}`}</div>
      <svg
        width={width}
        height={height + timeAxisSize}
        style={{ overflow: "visible" }}
      >
        {Object.values(data.quarterData).map((points, i) => (
          <Group key={i} left={i * (width / 4)}>
            <LinePath
              data={points}
              x={(d) => xScale(d.time / 60)}
              y={(d) => yScale(d.count / data.maxCount)}
              curve={curveStepAfter}
              stroke={"rgba(0,0,0,.15)"}
            />
            {points.map((p, j) => {
              const nextPoint = points[j + 1];

              const x1 = xScale(p.time / 60);
              const y1 = yScale(1);
              const y2 = yScale(0);
              const x2 = xScale(
                j === points.length - 1
                  ? 0
                  : (nextPoint ? nextPoint.time : 0) / 60
              );
              return (
                <Polygon
                  key={j}
                  points={[
                    [x1, y1],
                    [x1, y2],
                    [x2, y2],
                    [x2, y1],
                  ]}
                  fill={colorFromPosition(data.position)}
                  opacity={p.count / data.maxCount}
                />
              );
            })}
            {i !== 0 && (
              <AxisLeft
                scale={yScale}
                tickValues={[]}
                stroke={"rgba(0,0,0,.15)"}
              />
            )}
            {timeAxis && (
              <AxisBottom
                stroke={"rgba(0,0,0,.15)"}
                scale={xScale}
                label={`Q${i + 1}`}
                top={height}
                tickValues={
                  i === 3 ? [12, 10, 8, 6, 4, 2, 0] : [12, 10, 8, 6, 4, 2]
                }
              />
            )}
          </Group>
        ))}
      </svg>
    </div>
  );
}

// Convert time in/time out data into cumulative count at a given time.
function prepareCumulativeQuarterData(
  quarterTimes: { timeIn: number; timeOut: number }[]
) {
  let points = [{ time: 720, count: 0 }];

  if (quarterTimes) {
    // Get increment points.
    const increments: { time: number; increment: number }[] = [];
    quarterTimes.forEach((q) => {
      increments.push({ time: q.timeIn, increment: 1 });
      increments.push({ time: q.timeOut, increment: -1 });
    });

    // Intrepret the increments. Sort by time then increase/decrease count
    // value.
    let count = 0;
    increments.sort((a, b) => b.time - a.time);
    increments.forEach((inc) => {
      count += inc.increment;
      points.push({ time: inc.time, count: count });
    });

    // Consolidate points with same time.
    const condensedPoints = [];
    let lastTime = 720;
    points.push({ time: 0, count: 0 });
    for (let p = 1; p < points.length; p++) {
      const prevPoint = points[p - 1];
      const currPoint = points[p];
      if (currPoint && prevPoint) {
        // We got a new time point, so add in the last one.
        if (currPoint.time !== lastTime) {
          condensedPoints.push(prevPoint);
        }
        lastTime = currPoint.time;
      }
    }

    // Add in end point.
    condensedPoints.push({ time: 0, count: 0 });
    points = condensedPoints;
  } else {
    points.push({ time: 0, count: 0 });
  }

  return points;
}
