import React, { useRef, useContext } from "react";
import { Group } from "@visx/group";
import { BoxPlot } from "@visx/stats";
import { scaleLinear } from "@visx/scale";
import { Circle, Polygon } from "@visx/shape";
import { Text } from "@visx/text";

import { tableColorSchemes } from "../../constants/ChartConstants";
import { decFormat2 } from "../../util/Format";
import { UserPreferenceContext } from "../../UserContext";

const tooltipOffset = 4;
const tooltipWidth = 75;
const radius = 2.8;
const epsilon = 0.015;

export function PlayerShootingBoxPlot(props: {
  data: {
    player: number;
    league: number;
    pct05: number;
    pct25: number;
    pct50: number;
    pct75: number;
    pct95: number;
    type: string;
  };
  domain: number[];
  height: number;
  width: number;
  name: string;
  isHovered: boolean;
}) {
  const { data, domain, height, width, isHovered } = props;
  const containerRef = useRef(null);

  const colorPref = useContext(UserPreferenceContext)["Color Scheme"] || 0;
  const colors = tableColorSchemes[colorPref] || [];

  const xScale = scaleLinear<number>({
    range: [0, width],
    domain,
  });

  const valuePoint = data.player;
  const leagueAvgPoint = data.league;

  let color = "";
  if (Math.abs(valuePoint - leagueAvgPoint) <= epsilon) {
    color = "#838383";
  } else if (valuePoint > leagueAvgPoint) {
    color = colors[2] || "black";
  } else {
    color = colors[0] || "black";
  }

  let leftTooltipLabel = "";
  let rightTooltipLael = "";

  if (leagueAvgPoint > valuePoint) {
    leftTooltipLabel = `Player: ${decFormat2(valuePoint)}`;
    rightTooltipLael = `League: ${decFormat2(leagueAvgPoint)}`;
  } else {
    leftTooltipLabel = `League: ${decFormat2(leagueAvgPoint)}`;
    rightTooltipLael = `Player: ${decFormat2(valuePoint)}`;
  }

  return (
    <div>
      <svg width={width} height={height} ref={containerRef}>
        <BoxPlot
          boxWidth={height}
          valueScale={xScale}
          horizontal={true}
          min={data.pct05}
          firstQuartile={data.pct25}
          median={data.pct50}
          thirdQuartile={data.pct75}
          max={data.pct95}
          fill={"#DCDCDC"}
          stroke={"#BEBEBE"}
          strokeWidth={1}
          minProps={{
            y1: 0,
            y2: height,
          }}
          maxProps={{
            y1: 0,
            y2: height,
          }}
          boxProps={{
            rx: 4,
            ry: 4,
            stroke: "none",
          }}
        />
        <Circle
          r={radius}
          fill={color}
          cy={height / 2}
          cx={xScale(valuePoint)}
        />
        <Polygon
          fill={color}
          points={[
            [xScale(leagueAvgPoint), height / 2],
            [xScale(valuePoint), height / 2 + radius],
            [xScale(valuePoint), height / 2 - radius],
          ]}
        />
        {isHovered && (
          <Group
            left={
              xScale(Math.min(valuePoint, leagueAvgPoint)) -
              tooltipOffset -
              tooltipWidth
            }
          >
            <Polygon
              fill="white"
              points={[
                [0, 0],
                [0, height],
                [tooltipWidth, height],
                [tooltipWidth, 0],
              ]}
            />
            <Text
              style={{ fontWeight: 100, fontSize: 10 }}
              stroke={color}
              y={height - 3}
              x={4}
            >
              {leftTooltipLabel}
            </Text>
          </Group>
        )}
        {isHovered && (
          <Group
            left={xScale(Math.max(valuePoint, leagueAvgPoint)) + tooltipOffset}
          >
            <Polygon
              fill="white"
              points={[
                [0, 0],
                [0, height],
                [tooltipWidth, height],
                [tooltipWidth, 0],
              ]}
            />
            <Text
              style={{ fontWeight: 100, fontSize: 10 }}
              stroke={color}
              y={height - 3}
              x={4}
            >
              {rightTooltipLael}
            </Text>
          </Group>
        )}
      </svg>
    </div>
  );
}
