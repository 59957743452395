import React, { useMemo, useState } from "react";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { GameLineupBreakdown } from "../../../shared/routers/GameRouter";
import {
  decFormat2,
  minutesFormat,
  plusMinusFormat2,
  plusMinusIntFormat,
  makePlusMinus,
  gameClockFormat,
} from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import AppContext from "../../../shared/AppContext";
import { PlayerTableCell } from "../core/TableCell";

const columnHelper = createColumnHelper<GameLineupBreakdown>();

export function GameLineupsByTime(props: {
  data: GameLineupBreakdown[];
  hideXPts?: boolean;
}) {
  const { data, hideXPts } = props;
  const [sorting, setSorting] = useState<SortingState>();
  const firstData = data[0];
  const shouldShowPredColumns = firstData
    ? firstData.season === parseInt(AppContext.currentSeason)
    : true;

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("period", {
        header: () => "Quarter",
        meta: { group: g },
      }),
      columnHelper.accessor("timein", {
        header: () => "In",
        cell: (info) => gameClockFormat(info.getValue()),
        meta: { group: g },
      }),
      columnHelper.accessor("timeout", {
        header: () => "Out",
        cell: (info) => gameClockFormat(info.getValue()),
        meta: { group: g++ },
      }),
      columnHelper.accessor("p1", {
        header: () => "Player 1",
        cell: (info) => (
          <PlayerTableCell
            id={info.getValue()}
            name={info.row.original.player1}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("p2", {
        header: () => "Player 2",
        cell: (info) => (
          <PlayerTableCell
            id={info.getValue()}
            name={info.row.original.player2}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("p3", {
        header: () => "Player 3",
        cell: (info) => (
          <PlayerTableCell
            id={info.getValue()}
            name={info.row.original.player3}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("p4", {
        header: () => "Player 4",
        cell: (info) => (
          <PlayerTableCell
            id={info.getValue()}
            name={info.row.original.player4}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("p5", {
        header: () => "Player 5",
        cell: (info) => (
          <PlayerTableCell
            id={info.getValue()}
            name={info.row.original.player5}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor(
        (row) => ((row.offPossessions || 0) + (row.defPossessions || 0)) / 2,
        {
          id: "numPossessions",
          header: () => "# Poss",
          meta: {
            group: g,
            highlights: Highlights.Max,
            colorDomain: [0, 20],
            neutralColorScheme: true,
          },
        }
      ),
      columnHelper.accessor((row) => (row.timein - row.timeout) / 60, {
        id: "minutes",
        header: () => "Min",
        cell: (info) => minutesFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [0, 12],
          neutralColorScheme: true,
        },
      }),
      columnHelper.accessor(
        (row) => (row.offPoints || 0) - (row.defPoints || 0),
        {
          id: "plusMinus",
          header: () => "+/-",
          cell: (info) => (
            <span
              title={plusMinusFormat2(
                (info.row.original.offPPP || 0) -
                  (info.row.original.defPPP || 0)
              )}
            >
              {plusMinusIntFormat(info.getValue())}
            </span>
          ),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: [-10, 10],
          },
        }
      ),
      columnHelper.group({
        id: "ppp",
        meta: { group: g },
        header: "PPP",
        columns: [
          columnHelper.accessor("offPPP", {
            header: () => "Off",
            cell: (info) => decFormat2(info.getValue()),
            meta: { group: g, highlights: Highlights.Max, colorDomain: [0, 2] },
          }),
          columnHelper.accessor("defPPP", {
            header: () => "Def",
            cell: (info) => decFormat2(info.getValue()),
            meta: { group: g, highlights: Highlights.Min, colorDomain: [2, 0] },
          }),
          columnHelper.accessor(
            (row) =>
              row.offPPP === null || row.defPPP === null
                ? null
                : row.offPPP - row.defPPP,
            {
              id: "PPPNet",
              header: () => "Net",
              cell: (info) => makePlusMinus(decFormat2)(info.getValue()),
              meta: {
                group: g++,
                highlights: Highlights.Max,
                colorDomain: [-0.1, 0.1],
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "xPPP",
        meta: { group: g },
        header: "xPPP",
        columns: [
          columnHelper.accessor("xPPP", {
            header: () => "Off",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: [1.04, 1.2],
            },
          }),
          columnHelper.accessor("xPPPDef", {
            header: () => "Def",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: [1.2, 1.04],
            },
          }),
          columnHelper.accessor(
            (row) =>
              row.xPPP === null || row.xPPPDef === null
                ? null
                : row.xPPP - row.xPPPDef,
            {
              id: "xPPPNet",
              header: () => "Net",
              cell: (info) => makePlusMinus(decFormat2)(info.getValue()),
              meta: {
                group: g++,
                highlights: Highlights.Max,
                colorDomain: [-0.1, 0.1],
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "predPPP",
        meta: { group: g },
        header: "Predicted",
        columns: [
          columnHelper.accessor("predOE", {
            header: () => "Off",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: [1.04, 1.2],
            },
          }),
          columnHelper.accessor("predDE", {
            header: () => "Def",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: [1.2, 1.04],
            },
          }),
          columnHelper.accessor(
            (row) =>
              row.predOE === null || row.predDE === null
                ? null
                : row.predOE - row.predDE,
            {
              id: "predNet",
              header: () => "Net",
              cell: (info) => makePlusMinus(decFormat2)(info.getValue()),
              meta: {
                group: g,
                highlights: Highlights.Max,
                colorDomain: [-0.1, 0.1],
              },
            }
          ),
        ],
      }),
    ];
  }, []);

  const hiddenColumns = {
    predOE: shouldShowPredColumns,
    predDE: shouldShowPredColumns,
    predNet: shouldShowPredColumns,
    xPPP: !hideXPts,
    xPPPDef: !hideXPts,
    xPPPNet: !hideXPts,
  };

  return (
    <Table
      data={data}
      columns={columns}
      hiddenColumns={hiddenColumns}
      autoWidth={true}
      showColorOnHover={true}
      sorting={sorting}
      setSorting={setSorting}
      disableStickyColumn={true}
    />
  );
}
