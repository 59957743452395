import React from "react";

import {
  PlayersForLiveGame,
  LiveGameDetails,
  LiveGameLineupModelPrediction,
  SecondSpectrumChance,
  SecondSpectrumPossession,
} from "../../../shared/routers/LiveGameRouter";
import { GameLineupsByTime } from "../games/GameLineupsByTime";
import { GameLineupBreakdown } from "../../../shared/routers/GameRouter";
import { sumFromField } from "../../util/Util";

// TODO(chrisbu): When live game page is moved to a multiple tab page
// refactor this and lineups summary to not dupe all this processing.
export function LiveGameLineupByTimeTable(props: {
  details: LiveGameDetails;
  possessions: SecondSpectrumPossession[];
  chances: SecondSpectrumChance[];
  playerMap: Map<string, PlayersForLiveGame>;
  lineupPredictions: LiveGameLineupModelPrediction[];
}) {
  const { details, playerMap, possessions, chances, lineupPredictions } = props;

  const firstPoss = possessions[0];

  if (!firstPoss) return null;

  const chancesByPossId = new Map<string, SecondSpectrumChance[]>();
  chances.forEach((c) => {
    const arr = chancesByPossId.get(c.possessionId) || [];
    arr.push(c);
    chancesByPossId.set(c.possessionId, arr);
  });

  const lineupPredictionMap = new Map<string, LiveGameLineupModelPrediction>();
  lineupPredictions.forEach((lp) => {
    lineupPredictionMap.set(
      [lp.p1, lp.p2, lp.p3, lp.p4, lp.p5].sort((a, b) => a - b).join("-"),
      lp
    );
  });

  const teams = [
    {
      eagleId: details.homeTeamEagleId,
      teamId: details.homeTeamId,
      teamName: details.homeTeam,
    },
    {
      eagleId: details.awayTeamEagleId,
      teamId: details.awayTeamId,
      teamName: details.awayTeam,
    },
  ];

  return (
    <>
      {teams.map((team) => {
        const lineupBreakdowns: GameLineupBreakdown[] = possessions.map((p) => {
          const isOff = p.offTeamId === team.eagleId;

          const firstChance = (chancesByPossId.get(p.id) || []).sort(
            (a, b) => a.startGameClock - b.startGameClock
          )[0];
          const offPlayers = firstChance
            ? firstChance.offPlayerIds
            : p.offPlayerIds;
          const defPlayers = firstChance
            ? firstChance.defPlayerIds
            : p.defPlayerIds;

          const players = (
            (isOff ? offPlayers : defPlayers)
              .map((id) => playerMap.get(id))
              .filter((p) => p !== undefined) as PlayersForLiveGame[]
          ).sort((a, b) => {
            if (a.pos === b.pos) return a.playerId - b.playerId;
            return a.pos - b.pos;
          });

          const lineupPred = lineupPredictionMap.get(
            [...players]
              .sort((a, b) => a.playerId - b.playerId)
              .map((p) => p.playerId)
              .join("-")
          );

          return {
            gameId: 0,
            p1: players[0] ? players[0].playerId : 0,
            p2: players[1] ? players[1].playerId : 0,
            p3: players[2] ? players[2].playerId : 0,
            p4: players[3] ? players[3].playerId : 0,
            p5: players[4] ? players[4].playerId : 0,
            p1Pos: players[0] ? players[0].pos : 0,
            p2Pos: players[1] ? players[1].pos : 0,
            p3Pos: players[2] ? players[2].pos : 0,
            p4Pos: players[3] ? players[3].pos : 0,
            p5Pos: players[4] ? players[4].pos : 0,
            player1: players[0] ? players[0].playerName : "",
            player2: players[1] ? players[1].playerName : "",
            player3: players[2] ? players[2].playerName : "",
            player4: players[3] ? players[3].playerName : "",
            player5: players[4] ? players[4].playerName : "",
            offPoints: isOff ? p.ptsScored : 0,
            defPoints: isOff ? 0 : p.ptsScored,
            timein: p.startGameClock,
            timeout: p.endGameClock,
            period: p.period,
            season: p.season + 1,
            offPossessions: isOff ? 1 : 0,
            defPossessions: isOff ? 0 : 1,
            predOE: lineupPred ? lineupPred.predPPP : null,
            predDE: lineupPred ? lineupPred.predDefPPP : null,
            xPPP: null,
            xPPPDef: null,
            teamid: team.teamId,
            offPPP: isOff ? p.ptsScored : null,
            defPPP: isOff ? null : p.ptsScored,
          };
        });

        const sortedLineupBreakdowns = lineupBreakdowns.sort((a, b) => {
          if (a.period === b.period) {
            return b.timein - a.timein;
          }
          return a.period - b.period;
        });

        const groupedLineupBreakdowns: GameLineupBreakdown[][] = [];
        let prevLineup =
          sortedLineupBreakdowns[0] &&
          `${sortedLineupBreakdowns[0].p1}-${sortedLineupBreakdowns[0].p2}-${sortedLineupBreakdowns[0].p3}-${sortedLineupBreakdowns[0].p4}-${sortedLineupBreakdowns[0].p5}`;
        let prevData: GameLineupBreakdown[] = [];
        let prevPeriod = 0;
        for (const l of sortedLineupBreakdowns) {
          const curLineup = `${l.p1}-${l.p2}-${l.p3}-${l.p4}-${l.p5}`;
          const curPeriod = l.period;
          if (curLineup === prevLineup && curPeriod === prevPeriod) {
            prevData.push(l);
          } else {
            if (prevData.length > 0) {
              groupedLineupBreakdowns.push(prevData);
            }
            if (curPeriod !== prevPeriod) {
              // If its a new period set the start time to start of period.
              prevData = [{ ...l, timein: curPeriod < 5 ? 720 : 300 }];
            } else {
              prevData = [l];
            }
            prevLineup = curLineup;
            prevPeriod = curPeriod;
          }
        }
        groupedLineupBreakdowns.push(prevData);

        const data = groupedLineupBreakdowns.map((gd) => {
          const lastEl = gd[gd.length - 1];

          const offPoints = sumFromField("offPoints", gd);
          const defPoints = sumFromField("defPoints", gd);
          const offPoss = sumFromField("offPossessions", gd) || 0;
          const defPoss = sumFromField("defPossessions", gd) || 0;

          return {
            gameId: gd[0] ? gd[0].gameId : 0,
            p1: gd[0] ? gd[0].p1 : 0,
            p2: gd[0] ? gd[0].p2 : 0,
            p3: gd[0] ? gd[0].p3 : 0,
            p4: gd[0] ? gd[0].p4 : 0,
            p5: gd[0] ? gd[0].p5 : 0,
            p1Pos: gd[0] ? gd[0].p1Pos : 0,
            p2Pos: gd[0] ? gd[0].p2Pos : 0,
            p3Pos: gd[0] ? gd[0].p3Pos : 0,
            p4Pos: gd[0] ? gd[0].p4Pos : 0,
            p5Pos: gd[0] ? gd[0].p5Pos : 0,
            player1: gd[0] ? gd[0].player1 : "",
            player2: gd[0] ? gd[0].player2 : "",
            player3: gd[0] ? gd[0].player3 : "",
            player4: gd[0] ? gd[0].player4 : "",
            player5: gd[0] ? gd[0].player5 : "",
            offPoints: sumFromField("offPoints", gd),
            defPoints: sumFromField("defPoints", gd),
            timein: gd[0] ? gd[0].timein : 0,
            timeout: lastEl ? lastEl.timeout : 0,
            period: gd[0] ? gd[0].period : 0,
            season: gd[0] ? gd[0].season : 0,
            offPossessions: offPoss,
            defPossessions: defPoss,
            predOE: gd[0] ? gd[0].predOE : null,
            predDE: gd[0] ? gd[0].predDE : null,
            xPPP: null,
            xPPPDef: null,
            teamid: gd[0] ? gd[0].teamid : 0,
            offPPP:
              offPoss === 0 || offPoints === null ? null : offPoints / offPoss,
            defPPP:
              defPoss === 0 || defPoints === null ? null : defPoints / defPoss,
          };
        });
        return (
          <div key={team.eagleId}>
            <b>{team.teamName} </b>
            <GameLineupsByTime data={data} hideXPts={true} />
          </div>
        );
      })}
    </>
  );
}
