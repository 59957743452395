import React, { useState, useMemo } from "react";

import { Highlights } from "../../constants/AppConstants";
import {
  Table,
  createColumnHelper,
  SortingState,
} from "../../components/core/Table";
import { SkillOverviewCombined } from "../../../shared/routers/TeamRouter";
import { decFormat, makePercent } from "../../util/Format";
import { PlayerTableCell } from "../core/TableCell";

const columnHelper = createColumnHelper<SkillOverviewCombined>();

export function TeamRosterSkills(props: {
  data: SkillOverviewCombined[];
  type: "Season" | "peak";
}) {
  const { data, type } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const hiddenColumns = { Season: type !== "Season", pNBA: type !== "Season" };

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("player", {
        header: "Player",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.celticsID}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("Season", {
        meta: { group: g++ },
      }),
      columnHelper.accessor("pNBA", {
        cell: (info) => makePercent(decFormat)(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          colorDomain: [0.0183, 0.6265],
          group: g,
        },
      }),
      columnHelper.accessor("oskill", {
        header: "Offense",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          colorDomain: [-0.0359 * 100, 0.0599 * 100],
          group: g,
        },
      }),
      columnHelper.accessor("dskill", {
        header: "Defense",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          highlights: Highlights.Max,
          colorDomain: [-0.0403 * 100, 0.0555 * 100],
          group: g++,
        },
      }),
      columnHelper.accessor("skill", {
        header: "Projected Impact",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          colorDomain: [-0.0592 * 100, 0.1106 * 100],
          highlights: Highlights.Max,
          group: g,
        },
      }),
    ];
  }, []);

  return (
    <Table
      data={removeDupes(data)}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
      showColorOnHover={true}
      hiddenColumns={hiddenColumns}
    />
  );
}

function removeDupes(data: SkillOverviewCombined[]) {
  const seen = new Set();
  return data.filter((item) => {
    const duplicate = seen.has(item.celticsID);
    seen.add(item.celticsID);
    return !duplicate;
  });
}
