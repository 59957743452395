import React from "react";
import { Button } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export function ExpandCollapseButton(props: {
  onClick: () => void;
  expanded: boolean;
}) {
  const { onClick, expanded } = props;

  return (
    <Button
      onClick={() => onClick()}
      variant="outline-primary"
      style={{
        lineHeight: 0,
        padding: 3,
        fontSize: "0.7em",
      }}
    >
      {expanded ? <FaChevronUp /> : <FaChevronDown />}
    </Button>
  );
}
