import React from "react";
import {
  useQueryParams,
  StringParam,
  withDefault,
  JsonParam,
  QueryParamConfig,
  NumberParam,
  ArrayParam,
  encodeQueryParams,
} from "use-query-params";
import { Col, Row, Form, Button } from "react-bootstrap";
import moment from "moment";
import { stringify } from "query-string";
import { Link } from "react-router-dom";

import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { ImpactPlayerSelector } from "../components/impact/ImpactPlayerSelector";
import { ImpactComparisonChart } from "../components/impact/ImpactComparisonChart";
import { ImpactComparisonTable } from "../components/impact/ImpactComparisonTable";
import { lineChartColorArray } from "../constants/ColorConstants";
import { trpc } from "../util/tRPC";

interface ImpactComparisonPlayers {
  playerId?: string;
  date?: string;
  role?: string;
}

export function ImpactExplorerPage() {
  const [queryParams, setQueryParams] = useQueryParams({
    players: withDefault(JsonParam, [
      {
        playerId: undefined,
        date: moment().format("yyyy-MM-DD"),
        role: undefined,
      },
    ]) as QueryParamConfig<ImpactComparisonPlayers[]>,
    playerA: NumberParam,
    playerB: NumberParam,
    offDef: withDefault(StringParam, "Net"),
    variable: withDefault(StringParam, "Total"),
    metric: withDefault(StringParam, "Impact"),
    xAxis: withDefault(StringParam, "date"),
    dates: withDefault(ArrayParam, ["", ""]),
  });

  const { players, offDef, variable, playerA, playerB, metric, xAxis, dates } =
    queryParams;

  const useAllPlayers = trpc.impact.getPlayersWithImpact.useQuery();
  const allPlayers = useAllPlayers.data;

  if (!allPlayers) return null;

  let playerAObj:
    | {
        playerId: string;
        playerName: string;
        date: string;
        role?: string;
        color: string;
      }
    | undefined = undefined;
  if (playerA !== undefined && playerA !== null) {
    const player = players[playerA];
    if (player && player.date && player.playerId) {
      playerAObj = {
        playerId: player.playerId,
        playerName: allPlayers[player.playerId] || "Unknown",
        date: player.date,
        role: player.role,
        color: lineChartColorArray[playerA] || "black",
      };
    }
  }

  let playerBObj:
    | {
        playerId: string;
        playerName: string;
        date: string;
        role?: string;
        color: string;
      }
    | undefined = undefined;
  if (playerB !== undefined && playerB !== null) {
    const player = players[playerB];
    if (player && player.date && player.playerId) {
      playerBObj = {
        playerId: player.playerId,
        playerName: allPlayers[player.playerId] || "Unknown",
        date: player.date,
        role: player.role,
        color: lineChartColorArray[playerB] || "black",
      };
    }
  }

  return (
    <Page header={{ text: "Impact Explorer" }} title="Impact Explorer">
      <div>
        <Panel header="Select Players">
          <ImpactCompare
            allPlayers={allPlayers}
            players={players}
            playerA={playerA}
            playerB={playerB}
            offDef={offDef}
            metric={metric}
            xAxis={xAxis}
            variable={variable}
            dates={dates}
            update={setQueryParams}
          />
        </Panel>
        {playerAObj && playerBObj && (
          <Panel header="Impact Comparison">
            <Row>
              <Col>
                <ImpactComparisonTable
                  playerA={playerAObj}
                  playerB={playerBObj}
                  offDef={offDef}
                  actionRole={variable}
                  setActionRole={(actionRole: string) => {
                    const updates: { variable: string; metric?: string } = {
                      variable: actionRole,
                    };
                    if (impactOnlyActionRole(actionRole)) {
                      updates.metric = "Impact";
                    }
                    setQueryParams(updates);
                  }}
                />
              </Col>
            </Row>
          </Panel>
        )}
      </div>
    </Page>
  );
}

function ImpactCompare(props: {
  offDef: string;
  variable: string;
  metric: string;
  xAxis: string;
  allPlayers: Record<string, string>;
  players: ImpactComparisonPlayers[];
  playerA: number | undefined | null;
  playerB: number | undefined | null;
  dates: (string | null)[];
  update: (updates: {
    offDef?: string;
    variable?: string;
    metric?: string;
    xAxis?: string;
    playerA?: number;
    playerB?: number;
    dates?: (string | null)[];
    players?: ImpactComparisonPlayers[];
  }) => void;
}) {
  const {
    offDef,
    variable,
    metric,
    xAxis,
    allPlayers,
    players,
    playerA,
    playerB,
    dates,
    update,
  } = props;

  const addPlayer = () => {
    const updates: {
      players?: ImpactComparisonPlayers[];
      playerA?: number;
      playerB?: number;
    } = {};
    const newPlayers = [...players];
    newPlayers.push({
      date: moment().format("yyy-MM-DD"),
    });
    updates.players = newPlayers;
    // When we are *increasing* to 2 players set playerA and playerB.
    if (newPlayers.length === 2) {
      updates.playerA = 0;
      updates.playerB = 1;
    }
    update(updates);
  };

  const deletePlayer = (i: number) => {
    const updates: {
      players?: ImpactComparisonPlayers[];
      playerA?: number;
      playerB?: number;
    } = {};
    if (playerA !== undefined && playerA !== null && i < playerA) {
      updates.playerA = playerA - 1;
    } else if (i === playerA) {
      updates.playerA = undefined;
    }
    if (playerB !== undefined && playerB !== null && i < playerB) {
      updates.playerB = playerB - 1;
    } else if (i === playerB) {
      updates.playerB = undefined;
    }
    const newPlayers = [...players];
    newPlayers.splice(i, 1);
    updates.players = newPlayers;
    update(updates);
  };

  const compareUrl = `/compare-players?${stringify(
    encodeQueryParams(
      {
        players: JsonParam,
      },
      {
        players: players
          .filter((p) => p.playerId !== undefined)
          .map((p) => {
            return { playerId: p.playerId || "", league: "nba" };
          }),
      }
    )
  )}`;

  return (
    <div>
      <Row style={{ marginBottom: 8 }}>
        <Col>
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "end",
              flexWrap: "wrap",
            }}
          >
            <div>
              <Form.Label>Net/Off/Def</Form.Label>
              <Form.Select
                value={offDef}
                style={{ width: "auto" }}
                onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                  const sar = simpleActionRoles[evt.target.value];
                  update({
                    metric: "Impact",
                    offDef: evt.target.value,
                    variable: sar ? sar[0] : "",
                  });
                }}
              >
                {["Net", "Offense", "Defense"].map((o) => {
                  return (
                    <option key={o} value={o}>
                      {o}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div>
              <Form.Label>Action Role</Form.Label>
              <Form.Select
                value={variable}
                style={{ width: "auto", maxWidth: 300 }}
                onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                  const updates: { variable: string; metric?: string } = {
                    variable: evt.target.value,
                  };
                  if (impactOnlyActionRole(evt.target.value)) {
                    updates.metric = "Impact";
                  }
                  update(updates);
                }}
              >
                {(simpleActionRoles[offDef] || []).map((o) => {
                  return (
                    <option key={o} value={o}>
                      {o}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div>
              <Form.Label>Metric</Form.Label>
              <Form.Select
                disabled={impactOnlyActionRole(variable)}
                value={metric}
                style={{ width: "auto" }}
                onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                  update({ metric: evt.target.value });
                }}
              >
                {["Impact", "Ability", "Rate"].map((o) => {
                  return (
                    <option key={o} value={o}>
                      {o}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div>
              <Form.Label>X Axis</Form.Label>
              <Form.Select
                value={xAxis}
                style={{ width: "auto" }}
                onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                  update({ xAxis: evt.target.value });
                }}
              >
                <option value={"date"}>By Date</option>
                <option value={"age"}>By Age</option>
              </Form.Select>
            </div>
            <div>
              <Form.Label>Date Range</Form.Label>
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <Form.Control
                  type="date"
                  value={dates[0] || undefined}
                  onChange={(evt) => {
                    const d = dates[1] || null;
                    update({
                      dates: [evt.target.value, d],
                    });
                  }}
                />
                {" to "}
                <Form.Control
                  type="date"
                  value={dates[1] || undefined}
                  onChange={(evt) => {
                    const d = dates[0] || null;
                    update({
                      dates: [d, evt.target.value],
                    });
                  }}
                />
              </div>
            </div>
            {players.length < lineChartColorArray.length && (
              <Col>
                <Button onClick={addPlayer}>Add Player</Button>
              </Col>
            )}
          </div>
        </Col>
      </Row>
      <Row style={{ marginBottom: 36 }}>
        <Col>
          <Row>
            {players.map((p, i) => (
              <Col key={i} style={{ marginBottom: 8 }}>
                <ImpactPlayerSelector
                  label={`Player ${i + 1}`}
                  color={lineChartColorArray[i] || "black"}
                  players={allPlayers}
                  selectedPlayerId={p.playerId}
                  setSelectedPlayerId={(val: string | undefined) => {
                    const newPlayers = [...players];
                    const playerAtI = newPlayers[i];
                    if (playerAtI) {
                      playerAtI.playerId = val;
                      update({ players: newPlayers });
                    }
                  }}
                  date={p.date}
                  setSelectedDate={(val: string | undefined) => {
                    const newPlayers = [...players];
                    const playerAtI = newPlayers[i];
                    if (playerAtI) {
                      playerAtI.date = val;
                      update({ players: newPlayers });
                    }
                  }}
                  role={p.role}
                  setSelectedRole={(val: string | undefined) => {
                    const newPlayers = [...players];
                    const playerAtI = newPlayers[i];
                    if (playerAtI) {
                      playerAtI.role = val;
                      update({ players: newPlayers });
                    }
                  }}
                  showDelete={players.length > 1}
                  onDeleteClick={() => deletePlayer(i)}
                  showLeftRightButtons={players.length > 1}
                  isPlayerA={i === playerA}
                  isPlayerB={i === playerB}
                  onPlayerAClick={() => update({ playerA: i })}
                  onPlayerBClick={() => update({ playerB: i })}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col>
          <ImpactComparisonChart
            players={
              players
                .map((p, i) => {
                  return {
                    playerId: p.playerId,
                    date: p.date,
                    playerName: allPlayers[p.playerId || ""],
                    color: lineChartColorArray[i],
                    role: p.role,
                  };
                })
                // The cast is needed b/c TS doesn't understand the filter will
                // remove all undefineds for us.
                .filter(
                  (p) => p.playerId !== undefined && p.date !== undefined
                ) as {
                playerId: string;
                date: string;
                playerName: string;
                color: string;
              }[]
            }
            variable={variable}
            offDef={offDef}
            metric={metric}
            xAxis={xAxis}
            startDate={dates[0] || undefined}
            endDate={dates[1] || undefined}
            setPlayerDate={(playerId: string, date: string) => {
              const playerToChange = players.find(
                (p) => p.playerId === playerId
              );
              if (!playerToChange) {
                return;
              }
              const newPlayers = [...players];

              playerToChange.date = date;
              update({ players: newPlayers });
            }}
          />
        </Col>
      </Row>
      {players.filter((p) => p.playerId !== undefined).length > 1 && (
        <Row>
          <Col>
            <Link to={compareUrl}>Compare boxscore stats</Link>
          </Col>
        </Row>
      )}
    </div>
  );
}

function impactOnlyActionRole(actionRole: string) {
  return (
    actionRole === "Total" ||
    actionRole === "APM  Adjustment (Defense)" ||
    actionRole === "APM  Adjustment (Offense)"
  );
}

const simpleActionRoles: Record<string, string[]> = {
  Net: [
    "Total",
    "APM  Adjustment (Defense)",
    "APM  Adjustment (Offense)",
    "Catch and Go Drive Perimeter Spacer Defender",
    "Catch and Go Drive Perimeter Spacer",
    "Catch and Go Drive Rim Spacer Defender",
    "Catch and Go Drive Rim Spacer",
    "Catch and Go Driver Defender",
    "Catch and Go Driver",
    "Cut Ballhandler Defender",
    "Cut Ballhandler",
    "Cut Perimeter Spacer Defender",
    "Cut Perimeter Spacer",
    "Cut Rim Spacer Defender",
    "Cut Rim Spacer",
    "Cut Screener Defender",
    "Cut Screener",
    "Cutter Defender",
    "Cutter",
    "Iso Ballhandler",
    "Iso Defender",
    "Iso Perimeter Spacer Defender",
    "Iso Perimeter Spacer",
    "Iso Rim Spacer Defender",
    "Iso Rim Spacer",
    "Non-Action Ballhandler Defender",
    "Non-Action Ballhandler",
    "Non-Action Spacer Defender",
    "Non-Action Spacer",
    "PNR Ballhandler Defender",
    "PNR Ballhandler",
    "PNR Perimeter Spacer Defender",
    "PNR Perimeter Spacer",
    "PNR Popper",
    "PNR Rim Spacer Defender",
    "PNR Rim Spacer",
    "PNR Roller",
    "PNR Screener Defender High",
    "PNR Screener Defender Switch",
    "PNR Screener Defender Traditional",
    "Post Ballhandler",
    "Post Defender",
    "Post Perimeter Spacer Defender",
    "Post Perimeter Spacer",
    "Post Rim Spacer Defender",
    "Post Rim Spacer",
    "Run Off Screen Ballhandler Defender",
    "Run Off Screen Ballhandler",
    "Run Off Screen Defender",
    "Run Off Screen Perimeter Spacer Defender",
    "Run Off Screen Perimeter Spacer",
    "Run Off Screen Rim Spacer Defender",
    "Run Off Screen Rim Spacer",
    "Run Off Screen Screener Defender",
    "Run Off Screen Screener",
    "Run Off Screen",
    "Transition Ballhandler",
    "Transition Defense (Behind Play)",
    "Transition Defense (In Play)",
    "Transition Offense (Behind Play)",
    "Transition Offense (In Play)",
  ],
  Offense: [
    "Total",
    "APM  Adjustment (Offense)",
    "Catch and Go Drive Perimeter Spacer",
    "Catch and Go Drive Rim Spacer",
    "Catch and Go Driver",
    "Cut Ballhandler",
    "Cut Perimeter Spacer",
    "Cut Rim Spacer",
    "Cut Screener",
    "Cutter",
    "Iso Ballhandler",
    "Iso Perimeter Spacer",
    "Iso Rim Spacer",
    "Non-Action Ballhandler",
    "Non-Action Spacer",
    "PNR Ballhandler",
    "PNR Perimeter Spacer",
    "PNR Popper",
    "PNR Rim Spacer",
    "PNR Roller",
    "Post Ballhandler",
    "Post Perimeter Spacer",
    "Post Rim Spacer",
    "Run Off Screen",
    "Run Off Screen Ballhandler",
    "Run Off Screen Perimeter Spacer",
    "Run Off Screen Rim Spacer",
    "Run Off Screen Screener",
    "Transition Ballhandler",
    "Transition Offense (Behind Play)",
    "Transition Offense (In Play)",
  ],
  Defense: [
    "Total",
    "APM  Adjustment (Defense)",
    "Catch and Go Drive Perimeter Spacer Defender",
    "Catch and Go Drive Rim Spacer Defender",
    "Catch and Go Driver Defender",
    "Cut Ballhandler Defender",
    "Cut Perimeter Spacer Defender",
    "Cut Rim Spacer Defender",
    "Cut Screener Defender",
    "Cutter Defender",
    "Iso Defender",
    "Iso Perimeter Spacer Defender",
    "Iso Rim Spacer Defender",
    "Non-Action Ballhandler Defender",
    "Non-Action Spacer Defender",
    "PNR Ballhandler Defender",
    "PNR Perimeter Spacer Defender",
    "PNR Rim Spacer Defender",
    "PNR Screener Defender High",
    "PNR Screener Defender Switch",
    "PNR Screener Defender Traditional",
    "Post Defender",
    "Post Perimeter Spacer Defender",
    "Post Rim Spacer Defender",
    "Run Off Screen Ballhandler Defender",
    "Run Off Screen Defender",
    "Run Off Screen Perimeter Spacer Defender",
    "Run Off Screen Rim Spacer Defender",
    "Run Off Screen Screener Defender",
    "Transition Defense (Behind Play)",
    "Transition Defense (In Play)",
  ],
};
