import React, { useMemo, useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";

import { SSPlayerContext } from "../PlayerContext";
import {
  SecondSpectrumChancePlayer,
  SecondSpectrumRebound,
  SecondSpectrumShot,
  PossessionCovariates,
  ReboundModelRandomEffects,
  ReboundModelFixedEffects,
} from "../../shared/routers/LiveGameRouter";
import { Table, createColumnHelper } from "../components/core/Table";
import { Page } from "../components/core/Page";
import {
  teamOrpEstimate,
  normalizedReboundProb,
  xRebForPlayer,
  distanceToRim,
  crashTransform,
} from "../util/ReboundModel";
import { pctFormat, seasonString } from "../util/Format";
import { sum } from "../util/Util";
import { Panel } from "../components/core/Panel";
import { trpc } from "../util/tRPC";

export function ReboundDetailsPage() {
  const { id } = useParams();

  const eaglePlayers = useContext(SSPlayerContext);

  const playerMap = useMemo(() => {
    const map = new Map<string, string>();
    eaglePlayers.forEach((p) => {
      if (p.eagleId !== null) {
        map.set(p.eagleId, p.player);
      }
    });
    return map;
  }, [eaglePlayers]);

  const { data: possessionCovariatesData } =
    trpc.liveGame.getPossessionCovariatesForRebound.useQuery({
      reboundId: id,
    });

  const possessionCovariates = possessionCovariatesData
    ? possessionCovariatesData[0]
    : undefined;

  const { data: shotData } = trpc.liveGame.getShotForRebound.useQuery({
    reboundId: id,
  });

  const shot = shotData ? shotData[0] : undefined;

  const { data: reboundData } = trpc.liveGame.getRebound.useQuery({
    reboundId: id,
  });

  const rebound = reboundData ? reboundData[0] : undefined;

  const { data: reboundModel } = trpc.liveGame.getReboundModel.useQuery({
    reboundId: id,
  });

  const { data: chancePlayers } =
    trpc.liveGame.getChancePlayersForRebound.useQuery({
      reboundId: id,
    });

  const { data: fixedEffects } =
    trpc.liveGame.getReboundModelFixedEffects.useQuery();

  const { data: secondaryFixedEffects } =
    trpc.liveGame.getSecondaryReboundModelFixedEffects.useQuery();

  const eagleIds =
    chancePlayers && reboundModel
      ? chancePlayers
          .map((p) => p.playerId)
          .concat(
            reboundModel
              .filter((rm) => rm.player_team_factor !== "player")
              .map((rm) => rm.playerId)
          )
      : [];

  const { data: randomEffects } =
    trpc.liveGame.getReboundModelRandomEffects.useQuery({
      eagleIds,
    });

  if (
    possessionCovariates === undefined ||
    shot === undefined ||
    rebound === undefined ||
    chancePlayers === undefined ||
    chancePlayers.length === 0 ||
    fixedEffects === undefined ||
    fixedEffects.length === 0 ||
    secondaryFixedEffects === undefined ||
    secondaryFixedEffects.length === 0 ||
    randomEffects === undefined ||
    randomEffects.length === 0 ||
    reboundModel === undefined ||
    reboundModel.length === 0
  )
    return null;

  const offTeam = reboundModel.find(
    (rm) => rm.player_team_factor === "offensive_team"
  );
  const defTeam = reboundModel.find(
    (rm) => rm.player_team_factor === "defensive_team"
  );

  const offTeamRebPct = offTeam
    ? xRebForPlayer(
        {
          playerTeamFactor: "offensive_team",
          eagleId: offTeam.playerId,
          offense: true,
          rbPctRim: 0,
          rbPctShot: 0,
          shotLoc: null,
          rimLoc: null,
        },
        shot,
        rebound,
        fixedEffects || [],
        randomEffects || [],
        possessionCovariates
      )
    : 0;

  const defTeamRebPct = defTeam
    ? xRebForPlayer(
        {
          playerTeamFactor: "defensive_team",
          eagleId: defTeam.playerId,
          offense: false,
          rbPctRim: 0,
          rbPctShot: 0,
          shotLoc: null,
          rimLoc: null,
        },
        shot,
        rebound,
        fixedEffects || [],
        randomEffects || [],
        possessionCovariates
      )
    : 0;

  const playersRebPct = chancePlayers
    .map((cp) => {
      return {
        offense: cp.offense,
        player: playerMap.get(cp.playerId) || "",
        pReb: xRebForPlayer(
          {
            ...cp,
            playerTeamFactor: "player",
            eagleId: cp.playerId,
          },
          shot,
          rebound,
          fixedEffects || [],
          randomEffects || [],
          possessionCovariates
        ),
      };
    })
    .sort((a, b) => (a.player > b.player ? 1 : -1));

  const sum_eREB_player_off = Math.max(
    0.00001,
    offTeamRebPct +
      sum(
        "pReb",
        playersRebPct.filter((p) => p.offense)
      )
  );

  const sum_eREB_player_def = Math.max(
    0.00001,
    defTeamRebPct +
      sum(
        "pReb",
        playersRebPct.filter((p) => !p.offense)
      )
  );

  const team_ORp_estimate = teamOrpEstimate(
    possessionCovariates,
    secondaryFixedEffects,
    randomEffects.filter((re) =>
      chancePlayers.some(
        (cp) =>
          cp.playerId === re.eagle &&
          cp.offense === (re.offDef === "off") &&
          possessionCovariates.season.toString() === re.season
      )
    ),
    playersRebPct
      .filter((p) => p.offense)
      .map((p) => p.pReb)
      .concat(offTeamRebPct),
    playersRebPct
      .filter((p) => !p.offense)
      .map((p) => p.pReb)
      .concat(defTeamRebPct),
    rebound.fgReb
  );

  return (
    <Page header={{ text: "Rebound Details" }} title={"Rebound Details"}>
      <div>
        <Panel header={"Results"}>
          <Row>
            <Col lg={6}>
              <b>Web Model:</b>
              <div>
                Offensive Team: {pctFormat(offTeamRebPct)}(
                {pctFormat(
                  normalizedReboundProb(
                    offTeamRebPct,
                    true,
                    team_ORp_estimate,
                    sum_eREB_player_off,
                    sum_eREB_player_def
                  )
                )}
                )
              </div>
              <div>
                Defensive Team: {pctFormat(defTeamRebPct)}(
                {pctFormat(
                  normalizedReboundProb(
                    defTeamRebPct,
                    false,
                    team_ORp_estimate,
                    sum_eREB_player_off,
                    sum_eREB_player_def
                  )
                )}
                )
              </div>
              {playersRebPct.map((p, i) => (
                <div key={i}>
                  {p.player}
                  {": "}
                  {pctFormat(p.pReb)}(
                  {pctFormat(
                    normalizedReboundProb(
                      p.pReb,
                      p.offense,
                      team_ORp_estimate,
                      sum_eREB_player_off,
                      sum_eREB_player_def
                    )
                  )}
                  )
                </div>
              ))}
            </Col>
            <Col lg={6}>
              <b>Actual Model:</b>
              {offTeam && (
                <div>
                  Offensive Team: {pctFormat(offTeam.eREB_player)} (
                  {pctFormat(offTeam.eREB_player_normTo1)})
                </div>
              )}
              {defTeam && (
                <div>
                  Defensive Team: {pctFormat(defTeam.eREB_player)} (
                  {pctFormat(defTeam.eREB_player_normTo1)})
                </div>
              )}
              {reboundModel
                .filter((rm) => rm.player_team_factor === "player")
                .sort((a, b) =>
                  (playerMap.get(a.playerId) || "") >
                  (playerMap.get(b.playerId) || " ")
                    ? 1
                    : -1
                )
                .map((rm) => (
                  <div key={rm.playerId}>
                    {rm.player_team_factor === "player"
                      ? playerMap.get(rm.playerId)
                      : rm.player_team_factor}
                    {": "}
                    {pctFormat(rm.eREB_player)}(
                    {pctFormat(rm.eREB_player_normTo1)})
                  </div>
                ))}
            </Col>
          </Row>
        </Panel>
        <Panel header={"Breakdown"}>
          <Row>
            <Col>
              <BreakdownTable
                allPlayers={chancePlayers}
                shot={shot}
                rebound={rebound}
                possessionCovariates={possessionCovariates}
                fixedEffects={fixedEffects}
                randomEffects={randomEffects}
                playerMap={playerMap}
              />
            </Col>
          </Row>
        </Panel>
      </div>
    </Page>
  );
}

interface ReboundDetailsRow {
  factor: string;
  value: number | string | null;
  effectOnRebound: number | null;
}

const columnHelper = createColumnHelper<ReboundDetailsRow>();

function BreakdownTable(props: {
  allPlayers: SecondSpectrumChancePlayer[];
  shot: SecondSpectrumShot;
  rebound: SecondSpectrumRebound;
  possessionCovariates: PossessionCovariates;
  randomEffects: ReboundModelRandomEffects[];
  fixedEffects: ReboundModelFixedEffects[];
  playerMap: Map<string, string>;
}) {
  const { allPlayers, shot, playerMap } = props;
  const firstPlayer = allPlayers[0];

  const [playerId, setPlayerId] = useState<string>(
    firstPlayer ? firstPlayer.playerId : ""
  );

  const player = allPlayers.find((cp) => cp.playerId === playerId);

  if (!shot) return <div>FT WIP</div>;

  if (!player) return null;

  return (
    <div>
      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
        <Form.Select
          value={playerId}
          onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
            setPlayerId(evt.target.value);
          }}
          style={{ width: "auto" }}
        >
          {allPlayers.map((cp) => (
            <option key={cp.playerId} value={cp.playerId}>
              {playerMap.get(cp.playerId)}
            </option>
          ))}
        </Form.Select>
      </div>
      <BreakdownTableInner {...props} player={player} />
    </div>
  );
}

function BreakdownTableInner(props: {
  player: SecondSpectrumChancePlayer;
  shot: SecondSpectrumShot;
  rebound: SecondSpectrumRebound;
  possessionCovariates: PossessionCovariates;
  randomEffects: ReboundModelRandomEffects[];
  fixedEffects: ReboundModelFixedEffects[];
  playerMap: Map<string, string>;
}) {
  const {
    player,
    shot,
    rebound,
    possessionCovariates,
    randomEffects,
    fixedEffects,
    playerMap,
  } = props;

  const getCoeff = useCallback(
    (variable: string) => {
      if (fixedEffects === undefined) return 0;

      const coeff = fixedEffects.find((fe) => fe.variable === variable);
      return coeff ? coeff.coefficient : 0;
    },
    [fixedEffects]
  );

  const getPlayerCoeff = useCallback(
    (playerId: string, offDef: string) => {
      if (randomEffects === undefined) return [0, 0];

      const coeff = randomEffects.find(
        (fe) =>
          fe.eagle === playerId &&
          fe.offDef === offDef &&
          fe.season === possessionCovariates.season.toString()
      );
      return coeff
        ? [coeff.playerOffDef_season_effect, coeff.playerOffDef_effect]
        : [0, 0];
    },
    [possessionCovariates.season, randomEffects]
  );

  const { day_of_season, OLead, season, startGameClock } = possessionCovariates;

  const playerTeamFactor = "player" as
    | "player"
    | "offensive_team"
    | "defensive_team";
  const offDef = player.offense ? 1 : 0;
  const isClosestDefender = shot.closestDefId === player.playerId;
  const isShooter = shot.shooterId === player.playerId;
  const isDrivingLayup = shot.complexShotType === "drivingLayup";
  const isCutLayup = shot.complexShotType === "cutLayup";
  const rbPctShot = (player.rbPctShot || 0) / 100;
  const rbPctRim = (player.rbPctRim || 0) / 100;
  const inverseSquareOfRebPct = 1 / (rbPctShot + 0.1) ** 2;
  const distToRimAfterShot =
    distanceToRim(player.shotLoc) - distanceToRim(player.rimLoc);
  const rimRimDistance = distanceToRim(player.rimLoc);
  const transformedCrash01 = crashTransform(rbPctRim, rbPctShot);
  const distanceOfShot = shot.distance;
  const rimLocX = player.rimLoc ? player.rimLoc[0] || 0 : 0;

  const data = useMemo(
    () => [
      {
        factor: "Is 1st Qtr",
        value: shot.period === 1 ? "Yes" : "No",
        effectOnRebound:
          (shot.period === 1 ? 1 : 0) *
          getCoeff(`I(quarter_factor == "Q1")TRUE`),
      },
      {
        factor: "Start Game Clock",
        value: startGameClock,
        effectOnRebound: startGameClock * getCoeff("startGameClock"),
      },
      {
        factor: "Offense Lead",
        value: OLead,
        effectOnRebound: OLead * getCoeff("OLead"),
      },
      {
        factor: "Day of Season",
        value: day_of_season,
        effectOnRebound: day_of_season * getCoeff("day_of_season"),
      },
      {
        factor: "Player Team Factor",
        value: playerTeamFactor,
        effectOnRebound:
          playerTeamFactor === "defensive_team"
            ? 0
            : getCoeff(`player_team_factor${playerTeamFactor}`),
      },
      {
        factor: "Is FG Reb",
        value: rebound.fgReb ? "Yes" : "No",
        effectOnRebound: (rebound.fgReb ? 1 : 0) * getCoeff("fgRebTRUE"),
      },
      {
        factor: "Player Team Factor x Is FG Reb",
        value: null,
        effectOnRebound:
          (playerTeamFactor !== "defensive_team" && rebound.fgReb ? 1 : 0) *
          getCoeff(`player_team_factor${playerTeamFactor}:fgRebTRUE`),
      },
      {
        factor: "Is Offense",
        value: offDef === 1 ? "Yes" : "No",
        effectOnRebound: offDef * getCoeff("offDefoff"),
      },
      {
        factor: "Is FG Reb x Is Offense",
        value: null,
        effectOnRebound:
          offDef * (rebound.fgReb ? 1 : 0) * getCoeff(`fgRebTRUE:offDefoff`),
      },
      {
        factor: "Was Closest Defender",
        value: isClosestDefender ? "Yes" : "No",
        effectOnRebound:
          (isClosestDefender ? 1 : 0) * getCoeff("wasClosestDef"),
      },
      {
        factor: "Was Shooter",
        value: isShooter ? "Yes" : "No",
        effectOnRebound: (isShooter ? 1 : 0) * getCoeff("wasShooter"),
      },
      {
        factor: "Is Driving Layup",
        value: isDrivingLayup ? "Yes" : "No",
        effectOnRebound: (isDrivingLayup ? 1 : 0) * getCoeff("drivingLayup"),
      },
      {
        factor: "Was Shooter x Is Driving Layup",
        value: null,
        effectOnRebound:
          (isShooter && isDrivingLayup ? 1 : 0) *
          getCoeff("wasShooter:drivingLayup"),
      },
      {
        factor: "Is Cut Layup",
        value: isCutLayup ? "Yes" : "No",
        effectOnRebound: (isCutLayup ? 1 : 0) * getCoeff("cutLayup"),
      },
      {
        factor: "Was Shooter x Is Cut Layup",
        value: null,
        effectOnRebound:
          (isShooter && isCutLayup ? 1 : 0) * getCoeff("wasShooter:cutLayup"),
      },
      {
        factor: "p(reb) at Time of Shot",
        value: pctFormat(rbPctShot),
        effectOnRebound: rbPctShot * getCoeff("rbPctShot"),
      },
      {
        factor: "p(reb) at Time of Shot x Is FG Reb",
        value: null,
        effectOnRebound:
          rbPctShot * (rebound.fgReb ? 1 : 0) * getCoeff("fgRebTRUE:rbPctShot"),
      },
      {
        factor: "p(reb) at Time of Shot x Is Offense",
        value: null,
        effectOnRebound: rbPctShot * offDef * getCoeff("offDefoff:rbPctShot"),
      },
      {
        factor: "Inverse Square of p(reb) at Time of Shot",
        value: inverseSquareOfRebPct,
        effectOnRebound:
          inverseSquareOfRebPct * getCoeff("I(1/(rbPctShot + 0.1)^2)"),
      },
      {
        factor: "Inverse Square of p(reb) at Time of Shot x Is Offense",
        value: null,
        effectOnRebound:
          inverseSquareOfRebPct *
          offDef *
          getCoeff("offDefoff:I(1/(rbPctShot + 0.1)^2)"),
      },
      {
        factor: "Change in Player Dist from Rim",
        value: distToRimAfterShot,
        effectOnRebound:
          distToRimAfterShot * getCoeff("dist_to_rim_after_shot"),
      },
      {
        factor: `ifelse(dist_to_rim_after_shot>4,4,dist_to_rim_after_shot) x Is Offense`,
        value: null,
        effectOnRebound:
          (distToRimAfterShot > 4 ? 4 : distToRimAfterShot) *
          offDef *
          getCoeff(
            `I(ifelse(dist_to_rim_after_shot > 4, 4, dist_to_rim_after_shot) * ifelse(offDef == "off", 1, 0))`
          ),
      },
      {
        factor: "Player Dist from Rim when Shot at Rim",
        value: rimRimDistance,
        effectOnRebound: rimRimDistance * getCoeff(`rim_rim_distance`),
      },
      {
        factor: "Player Dist from Rim when Shot at Rim x Is FG Reb",
        value: rimRimDistance,
        effectOnRebound:
          rimRimDistance *
          (rebound.fgReb ? 1 : 0) *
          getCoeff(`fgRebTRUE:rim_rim_distance`),
      },
      {
        factor:
          "ifelse(rim_rim_distance > 30, 30, rim_rim_distance) x Is Offense",
        value: null,
        effectOnRebound:
          (rimRimDistance > 30 ? 30 : rimRimDistance) *
          offDef *
          getCoeff(
            `I(ifelse(rim_rim_distance > 30, 30, rim_rim_distance) * ifelse(offDef == "off", 1, 0))`
          ),
      },
      {
        factor:
          "ifelse(rim_rim_distance > 30, 30, rim_rim_distance) x Is FG Reb",
        value: null,
        effectOnRebound:
          (rimRimDistance > 30 ? 30 : rimRimDistance) *
          (rebound.fgReb ? 1 : 0) *
          getCoeff(
            `I(ifelse(rim_rim_distance > 30, 30, rim_rim_distance) * fgReb)`
          ),
      },
      {
        factor: "ifelse(rim_rim_distance<7,7,rim_rim_distance) x Is FG Reb",
        value: null,
        effectOnRebound:
          (rimRimDistance < 7 ? 7 : rimRimDistance) *
          (rebound.fgReb ? 1 : 0) *
          getCoeff(
            `I(ifelse(rim_rim_distance < 7, 7, rim_rim_distance) * fgReb)`
          ),
      },
      {
        factor: "Distance of Shot",
        value: distanceOfShot,
        effectOnRebound: distanceOfShot * getCoeff("distance_of_shot"),
      },
      {
        factor: "Distance of Shot x Is Offense",
        value: null,
        effectOnRebound:
          distanceOfShot * offDef * getCoeff("offDefoff:distance_of_shot"),
      },
      {
        factor: "Player Dist from Rim when Shot at Rim x Distance of Shot",
        value: null,
        effectOnRebound:
          distanceOfShot *
          rimRimDistance *
          getCoeff("rim_rim_distance:distance_of_shot"),
      },
      {
        factor: "I(log(-ifelse(rimLocX > (-43), -43, rimLocX)))",
        value: Math.log(-(rimLocX > -43 ? -43 : rimLocX)),
        effectOnRebound:
          Math.log(-(rimLocX > -43 ? -43 : rimLocX)) *
          getCoeff("I(log(-ifelse(rimLocX > (-43), -43, rimLocX)))"),
      },
      {
        factor: "transformedCrash01",
        value: transformedCrash01,
        effectOnRebound: transformedCrash01 * getCoeff("transformedCrash01"),
      },
      {
        factor: "transformedCrash01 x Is Offense",
        value: null,
        effectOnRebound:
          transformedCrash01 *
          offDef *
          getCoeff("offDefoff:transformedCrash01"),
      },
      {
        factor: "Season",
        value: seasonString(season.toString()),
        effectOnRebound: getCoeff(`I(as.factor(season))${season + 1}`),
      },
      {
        factor: "Player Season Effect (Intercept)",
        value: `${playerMap.get(player.playerId)} (${
          offDef == 1 ? "off" : "def"
        })`,
        effectOnRebound:
          getPlayerCoeff(player.playerId, offDef == 1 ? "off" : "def")[0] || 0,
      },
      {
        factor: "Player Effect (Intercept)",
        value: `${playerMap.get(player.playerId)} (${
          offDef == 1 ? "off" : "def"
        })`,
        effectOnRebound:
          getPlayerCoeff(player.playerId, offDef == 1 ? "off" : "def")[1] || 0,
      },
      {
        factor: "Intercept",
        value: null,
        effectOnRebound: getCoeff("(Intercept)"),
      },
    ],
    [
      OLead,
      day_of_season,
      distToRimAfterShot,
      distanceOfShot,
      getCoeff,
      getPlayerCoeff,
      inverseSquareOfRebPct,
      isClosestDefender,
      isCutLayup,
      isDrivingLayup,
      isShooter,
      offDef,
      player.playerId,
      playerMap,
      rbPctShot,
      rebound.fgReb,
      rimLocX,
      rimRimDistance,
      season,
      shot.period,
      startGameClock,
      transformedCrash01,
    ]
  );

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("factor", {
        header: "Factor",
        meta: { textAlign: "left" },
      }),
      columnHelper.accessor("value", {
        header: "Value",
        meta: { textAlign: "left" },
      }),
      columnHelper.accessor("effectOnRebound", {
        header: "Effect on p(Reb)",
        footer: () => {
          const unscaled = sum("effectOnRebound", data);
          const p = 1 / (1 + Math.exp(-unscaled));
          return `${unscaled} (${pctFormat(p)})`;
        },
      }),
    ];
  }, [data]);

  const probReb = 1 / (1 + Math.exp(-sum("effectOnRebound", data)));

  return (
    <div>
      <div>p(reb): {pctFormat(probReb)}</div>
      <Table
        data={data}
        columns={columns}
        autoWidth={true}
        showRowIndex={false}
      />
    </div>
  );
}
