import React, { useCallback, useRef } from "react";
import { Line } from "@visx/shape";
import { scaleLinear } from "@visx/scale";
import { localPoint } from "@visx/event";

import { generateDomain } from "../../util/Util";
import { divergentDarkMidColorScheme } from "../../constants/ChartConstants";

const width = 300;
const height = 16;
const barHeight = height / 2;

const highlightColor = "#26D3C7";

export function RugPlot(props: {
  data: { value: number | null; teamId: number }[];
  teamId: number;
  domain: number[];
  colorDomain: number[];
  hoveredTeamId?: number;
  setHoveredTeamId: (teamId?: number) => void;
}) {
  const { teamId, data, colorDomain, domain, hoveredTeamId, setHoveredTeamId } =
    props;

  const dataNoNulls = data.filter((d) => d.value !== null) as {
    value: number;
    teamId: number;
  }[];

  const svgRef = useRef<SVGSVGElement>(null);

  const xScale = scaleLinear<number>({
    range: [0, width],
    domain: domain,
  });

  const colorRange = divergentDarkMidColorScheme;
  const color = scaleLinear<string>({
    range: divergentDarkMidColorScheme,
    domain: generateDomain(
      colorDomain[0] || 0,
      colorDomain[1] || 0,
      colorRange.length
    ),
  });

  const handleMouseMove = useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      if (!svgRef.current) return;

      // find the nearest polygon to the current mouse position
      const point = localPoint(svgRef.current, event);
      if (!point) return;
      const newHoveredTeam = dataNoNulls.sort(
        (a, b) =>
          Math.abs(xScale(a.value) - point.x) -
          Math.abs(xScale(b.value) - point.x)
      )[0];
      if (newHoveredTeam === undefined) return;
      const newHoveredTeamId = newHoveredTeam.teamId;
      if (hoveredTeamId !== newHoveredTeamId) {
        setHoveredTeamId(newHoveredTeamId);
      }
    },
    [data]
  );

  const handleMouseLeave = useCallback(() => {
    setHoveredTeamId();
  }, []);

  return (
    <svg
      width={width}
      height={height}
      ref={svgRef}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      {dataNoNulls.map((d, i) => {
        const hovered = d.teamId === hoveredTeamId;
        const multiplier = d.teamId === teamId || hovered ? 2 : 1;
        const y1 = height / 2 + multiplier * (barHeight / 2);
        const y2 = height / 2 - multiplier * (barHeight / 2);
        return (
          <Line
            key={i}
            strokeWidth={multiplier}
            from={{ x: xScale(d.value), y: y1 }}
            to={{ x: xScale(d.value), y: y2 }}
            stroke={hovered ? highlightColor : color(d.value)}
          />
        );
      })}
    </svg>
  );
}
