import React, { ReactNode, useState, useRef, useContext, useMemo } from "react";
import { Form, Overlay, InputGroup } from "react-bootstrap";

import { MultiSelect } from "../core/MultiSelect";
import { SSPlayerContext } from "../../PlayerContext";
import { NbaEaglePlayer } from "../../../shared/routers/RosterRouter";
import { itemsAsList } from "../../util/Format";
import { Spinner } from "../core/Spinner";

export interface WowyControlValue {
  withPlayers?: string[];
  withoutPlayers?: string[];
  oneOfPlayers?: string[];
}

export function WowyLineupInput(props: {
  disabled?: boolean;
  seasons: number[] | undefined;
  teamId: number | undefined;
  value: WowyControlValue | undefined;
  setValue: (value: WowyControlValue | undefined) => void;
}) {
  const { disabled, seasons, setValue, teamId } = props;
  const value = props.value || {};
  const [show, setShow] = useState(false);
  const target = useRef<HTMLDivElement>(null);

  // Data to populate filters.
  const players = useContext(SSPlayerContext);

  const playerNameForId = (id: string) => {
    if (!players) return "";
    const player = players.find((p) => p.playerId.toString() === id);
    if (player) return player.player;
    return "";
  };

  const playerSeasonTeamMap = useMemo(() => {
    if (!players) return;
    const ret: Record<string, Record<string, Set<string>>> = {};
    for (const player of players) {
      ret[player.playerId.toString()] = getSeasonTeamMap(player);
    }
    return ret;
  }, [players]);

  const filterPlayers = (p: NbaEaglePlayer) => {
    const entry =
      playerSeasonTeamMap && playerSeasonTeamMap[p.playerId.toString()];
    if (!entry) return false;

    const selectedTeam = teamId;

    if (!selectedTeam) return false;

    if (seasons?.length && selectedTeam) {
      for (const season of seasons) {
        const seasonEntry = entry[season];
        if (seasonEntry && seasonEntry.has(selectedTeam.toString())) {
          return true;
        }
      }
      return false;
    } else if (seasons?.length) {
      return seasons.some((season) =>
        Object.keys(entry).includes(season.toString())
      );
    } else if (selectedTeam) {
      const allTeams = new Set(
        (function* () {
          for (const set of Object.values(entry)) yield* set;
        })()
      );
      return allTeams.has(selectedTeam.toString());
    }

    return true;
  };

  const handleChange = (val: string | string[] | undefined, field: string) => {
    const newVal = { ...value, [field]: val };
    if (Object.values(newVal).some((v) => v && v.length)) {
      setValue(newVal);
    } else {
      setValue(undefined);
    }
  };

  const displayValue = () => {
    const { withPlayers, withoutPlayers, oneOfPlayers } = value;
    const displayNodeArr: ReactNode[] = [];
    if (withPlayers && withPlayers.length)
      displayNodeArr.push(
        <span>
          <b>{" With: "}</b>
          {itemsAsList(withPlayers.map((p) => playerNameForId(p)))}
        </span>
      );
    if (withoutPlayers && withoutPlayers.length)
      displayNodeArr.push(
        <span>
          <b>{" Without: "}</b>
          {itemsAsList(withoutPlayers.map((p) => playerNameForId(p)))}
        </span>
      );
    if (oneOfPlayers && oneOfPlayers.length)
      displayNodeArr.push(
        <span>
          <b>{" With one of: "}</b>
          {itemsAsList(
            oneOfPlayers.map((p) => playerNameForId(p)),
            true
          )}
        </span>
      );
    return displayNodeArr.length ? (
      <>{displayNodeArr.map((node) => node)}</>
    ) : (
      <span>&nbsp;</span>
    );
  };

  if (!players.length) return <Spinner />;

  return (
    <>
      <InputGroup>
        <div
          className="form-control"
          style={{
            backgroundColor: disabled ? "#eee" : "",
            cursor: disabled ? "not-allowed" : "pointer",
          }}
          onClick={() => setShow(!disabled)}
          ref={target}
        >
          {displayValue() || <span>&nbsp;</span>}
        </div>
      </InputGroup>
      <Overlay
        target={target.current}
        show={show}
        placement="bottom-start"
        onHide={() => setShow(false)}
        rootClose={true}
      >
        <Form
          className="p-2 bg-white border rounded"
          style={{ zIndex: 2, width: target.current?.offsetWidth || 200 }}
        >
          <Form.Group>
            <Form.Label>With</Form.Label>
            <MultiSelect
              values={players
                .filter((p) => filterPlayers(p))
                .map((p) => {
                  return {
                    label: p.player,
                    value: p.playerId.toString(),
                  };
                })}
              selected={value.withPlayers || []}
              onChange={(vals) =>
                handleChange(vals.length ? vals : undefined, "withPlayers")
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Without</Form.Label>
            <MultiSelect
              values={players
                .filter((p) => filterPlayers(p))
                .map((p) => {
                  return {
                    label: p.player,
                    value: p.playerId.toString(),
                  };
                })}
              selected={value.withoutPlayers || []}
              onChange={(vals) =>
                handleChange(vals.length ? vals : undefined, "withoutPlayers")
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>With One</Form.Label>
            <MultiSelect
              values={players
                .filter((p) => filterPlayers(p))
                .map((p) => {
                  return {
                    label: p.player,
                    value: p.playerId.toString(),
                  };
                })}
              selected={value.oneOfPlayers || []}
              onChange={(vals) =>
                handleChange(vals.length ? vals : undefined, "oneOfPlayers")
              }
            />
          </Form.Group>
        </Form>
      </Overlay>
    </>
  );
}

function getSeasonTeamMap(p: NbaEaglePlayer) {
  const map: Record<string, Set<string>> = {};
  const seasonTeamArr = p.seasonTeam.split(" ");
  for (const seasonTeam of seasonTeamArr) {
    const year = seasonTeam.split("_")[0] || "";
    const team = seasonTeam.split("_")[1] || "";
    const yearObj = map[year];

    if (!yearObj) {
      map[year] = new Set([team]);
    } else {
      yearObj.add(team);
    }
  }
  return map;
}
