import React, { useContext } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Link, useLocation } from "react-router-dom";

import { TeamContext } from "../../TeamContext";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: "#111",
      fontSize: ".8em",
      fontFamily: "proxima-nova-condensed,sans-serif",
      textAlign: "left",
      whiteSpace: "nowrap",
      overflowX: "scroll",
      height: 20,
      position: "fixed",
      right: 0,
      left: 0,
      /* Height of the navbar. */
      top: 56,
      /* Higher than most normal content (0-2), lower than tooltips (1000). */
      zIndex: 3,
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    list: {
      paddingLeft: 3,
      marginBottom: 0,
      listStyle: "none",
      marginLeft: -5,
    },
    listItem: {
      display: "inline-block",
      marginLeft: 0,
      paddingLeft: 3,
      paddingRight: 3,
    },
    team: {
      padding: "0 2px",
      display: "inline-block",
      margin: 2,
      borderRadius: 5,
      color: "#8B8F99",
      width: 30,
      textAlign: "center",
      "&.active,&:hover": {
        backgroundColor: "#333",
        color: "#D6D7DA",
        textDecoration: "none",
      },
    },
  })
);

export function TeamQuickSwitch() {
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const teams = useContext(TeamContext).teams;

  let teamId = 0;
  const part1 = pathParts[1];
  const part2 = pathParts[2];

  if (part1 === "team" && part2 !== undefined) {
    teamId = parseInt(part2);
  }

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ul className={classes.list}>
        {teams.map((team, i) => {
          return (
            <li key={i} className={classes.listItem}>
              {team.teamid === teamId ? (
                <span className={classes.team + " active"}>
                  {team.teamabbreviation}
                </span>
              ) : (
                <Link to={`/team/${team.teamid}`} className={classes.team}>
                  {team.teamabbreviation}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
