import React, { useCallback } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { scaleLinear, rgb } from "d3";
import { Alert } from "react-bootstrap";
import { ParentSize } from "@visx/responsive";
import { localPoint } from "@visx/event";
import { useTooltip, TooltipWithBounds, defaultStyles } from "@visx/tooltip";
import { EventType } from "@visx/event/lib/types";

import { GradientLegend } from "../chart/GradientLegend";
import { Shot } from "../../../shared/routers/ShotRouter";
import { Court } from "../court/Court";
import { complexShotTypeMap } from "../../constants/AppConstants";
import { optionB } from "../../constants/ChartConstants";
import { generateDomain } from "../../util/Util";
import { gameClockFormat, pctFormat } from "../../util/Format";
import { Restrict } from "../core/Restrict";

const MAX_SHOTS = 1_500;

const useStyles = makeStyles(() =>
  createStyles({
    court: {
      pointerEvents: "none",
      top: 15,
      left: 0,
      position: "absolute",
      border: "none",
    },
  })
);

export function ShotChartMakeMiss(props: {
  shots: Shot[];
  maxMarkWidth?: number;
  shooterName?: boolean;
}) {
  const { shots, maxMarkWidth, shooterName } = props;

  return (
    <ParentSize parentSizeStyles={{ width: "100%" }}>
      {({ width }) =>
        width > 0 && (
          <ShotChartMakeMissInner
            width={width}
            shots={shots}
            maxMarkWidth={maxMarkWidth}
            shooterName={shooterName}
          />
        )
      }
    </ParentSize>
  );
}

export function ShotChartMakeMissInner(props: {
  width: number;
  shots: Shot[];
  maxMarkWidth: number | undefined;
  shooterName: boolean | undefined;
}) {
  const classes = useStyles();
  const { width, shots, maxMarkWidth, shooterName } = props;

  const {
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    showTooltip,
    hideTooltip,
  } = useTooltip<Shot>();

  // Distance from basket.
  const maxDistance = 35;
  // Flip x and y to get the rim near the top.
  const xDomain = [-25, 25];
  // 5.25 is distance from middle of basket to baseline.
  const yDomain = [-47.25 + maxDistance + 5.25, -47.25];
  const eppsDomain = [0.6, 1.45];
  const margin = { top: 15, right: 0, bottom: 10, left: 0 };

  const height =
    width * ((maxDistance * 10 + 52.5) / 500) + margin.top + margin.bottom;

  const glyphSize =
    maxMarkWidth == null ? width / 30 : Math.min(width / 30, maxMarkWidth);
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const xMin = 0,
    xMax = innerWidth,
    yMin = innerHeight,
    yMax = 0;

  const xScale = scaleLinear().domain(xDomain).range([xMin, xMax]).clamp(true);
  const yScale = scaleLinear().domain(yDomain).range([yMin, yMax]).clamp(true);

  const colorDomain = eppsDomain;
  const color = scaleLinear<string, string, never>()
    .domain(
      generateDomain(colorDomain[0] || 0, colorDomain[1] || 0, optionB.length)
    )
    .range(optionB)
    .clamp(true);

  // Wrap calculation in a max with 0 so that when width is initially 0 we don't
  // start with negative values.
  const radius = Math.max(glyphSize / 2 - 0.5, 0);
  const outerRadius = radius + 2.5;
  const lineWidth = glyphSize / 4;

  const tooltip = (shot: Shot) => {
    if (!shot) {
      return null;
    }

    const shooter = shooterName ? ` by ${shot.shooter}` : "";
    const madeMissed = shot.made ? `Made${shooter}` : `Missed${shooter}`;
    const value = shot.isThree ? "3pt" : "2pt";
    const time = `Q${shot.period || "?"} ${gameClockFormat(shot.gameClock)}`;
    const cst = complexShotTypeMap[shot.complexShotType];
    const shotType = cst ? cst.label : "Unknown";
    const defender = shot.defender ? `Defended by ${shot.defender}` : "";
    const distance = `${Math.round(shot.shotDist)}ft`;

    const fouled = shot.fouled ? "Fouled" : "";

    return (
      <div>
        <div
          style={{
            fontSize: "0.9em",
            fontWeight: 600,
          }}
        >
          {value + " " + madeMissed}
        </div>
        <div
          style={{
            marginTop: 2,
            fontSize: "0.9em",
          }}
        >
          {shotType}
        </div>
        <div
          style={{
            marginTop: 2,
            fontSize: "0.9em",
          }}
        >
          {defender}
        </div>
        <div style={{ fontSize: "0.8em", marginTop: 2, fontStyle: "italic" }}>
          {fouled}
        </div>
        <Restrict roles={["bia"]}>
          <div
            style={{
              opacity: 0.8,
              fontSize: "0.8em",
              marginTop: 4,
            }}
          >
            <div style={{ float: "right" }}>
              xFoul: {pctFormat(shot.typeDefenderPlayerFoul)}
            </div>
            <div>xMake: {pctFormat(shot.typeDefenderPlayerMake)}</div>
          </div>
        </Restrict>
        <div
          style={{
            opacity: 0.8,
            fontSize: "0.8em",
            marginTop: 4,
          }}
        >
          <div style={{ float: "right" }}>{time}</div>
          <div>{distance}</div>
        </div>
      </div>
    );
  };

  const renderMadeShot = (d: Point, pointColor: string) => {
    return [
      d.fouled ? (
        <circle
          key={0}
          cx={xScale(d.x)}
          cy={yScale(d.y)}
          r={outerRadius}
          fill={"rgba(255,255,255,0.5)"}
          stroke={pointColor}
        />
      ) : null,
      <circle
        key={1}
        cx={xScale(d.x)}
        cy={yScale(d.y)}
        r={radius}
        fill={pointColor}
        stroke={rgb(pointColor).darker(0.5).toString()}
        strokeWidth={1}
      />,
    ];
  };

  const renderMissedShot = (d: Point, pointColor: string) => {
    const cx = xScale(d.x),
      cy = yScale(d.y);

    if (d.fouled) {
      return [
        <circle
          key={1}
          cx={xScale(d.x)}
          cy={yScale(d.y)}
          r={outerRadius}
          fill={pointColor}
          stroke={rgb(pointColor).darker(0.5).toString()}
          strokeWidth={0.5}
        />,
        <circle
          key={2}
          cx={xScale(d.x)}
          cy={yScale(d.y)}
          r={radius}
          fill={"rgba(255,255,255,1)"}
          stroke={rgb(pointColor).darker(0.5).toString()}
          strokeWidth={0.5}
        />,
      ];
    }
    return (
      <g transform={`translate(${cx} ${cy}) rotate(45 0 0)`}>
        <circle
          cx={cx}
          cy={cy}
          r={radius}
          fill={"rgba(0,0,0,0)"}
          stroke={"none"} // For ease of clicking.
        />
        <rect
          x={-glyphSize / 2}
          y={-lineWidth / 2}
          width={glyphSize}
          height={lineWidth}
          fill={pointColor}
          stroke={rgb(pointColor).darker(0.5).toString()}
          strokeWidth={1.5}
        />
        <rect
          x={-lineWidth / 2}
          y={-glyphSize / 2}
          width={lineWidth}
          height={glyphSize}
          fill={pointColor}
          stroke={rgb(pointColor).darker(0.5).toString()}
          strokeWidth={1.5}
        />
        <rect
          x={-glyphSize / 2}
          y={-lineWidth / 2}
          width={glyphSize}
          height={lineWidth} // Draw again to hide outlines overlap.
          fill={pointColor}
        />
        <rect
          x={-lineWidth / 2}
          y={-glyphSize / 2}
          width={lineWidth}
          height={glyphSize}
          fill={pointColor}
        />
      </g>
    );
  };

  const handleClick = () => {
    if (tooltipData && tooltipData.synergyUrl) {
      window.open(tooltipData.synergyUrl, "_blank");
    }
  };

  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    color: "black",
  };

  const handleTooltip = useCallback(
    (event: EventType) => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 };
      for (const shot of shots) {
        const point = toPoint(shot);
        const shotX = xScale(point.x) + margin.left;
        const shotY = yScale(point.y) + margin.top;
        if (Math.abs(shotX - x) < radius && Math.abs(shotY - y) < radius) {
          showTooltip({
            tooltipData: shot,
            tooltipLeft: shotX,
            tooltipTop: shotY,
          });
          return;
        }
      }
      hideTooltip();
    },
    [
      hideTooltip,
      margin.left,
      margin.top,
      radius,
      shots,
      showTooltip,
      xScale,
      yScale,
    ]
  );

  return (
    <div>
      {shots.length >= MAX_SHOTS && (
        <Alert variant="warning">{`Showing ${MAX_SHOTS.toLocaleString()} of ${shots.length.toLocaleString()} total shots.`}</Alert>
      )}
      <div style={{ position: "relative" }}>
        <Court
          width={width}
          hideBorder={true}
          className={classes.court}
          maxDistance={maxDistance}
          hideBenchLines={true}
        />
        <svg width={width} height={height}>
          <g
            transform={`translate(${margin.left} ${margin.top})`}
            onTouchStart={handleTooltip}
            fill={"transparent"}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
            onClick={() => handleClick()}
          >
            <g transform={`translate(0 ${-margin.top})`}>
              <GradientLegend
                key={"legend"}
                x={xScale}
                valueFunc={(h: Point | null | undefined) => (h && h.epps) || 0}
                color={color}
                label={"xPPS"}
                highlight={
                  tooltipData === undefined ? null : toPoint(tooltipData)
                }
                id={new Date().getTime()} // Just need a unique #.
                width={Math.abs(xScale(37) - xScale(13))}
              />
            </g>
            {shots.slice(0, MAX_SHOTS).map((d, i) => {
              const point = toPoint(d);
              const pointColor = color(d.epps);
              return (
                <g key={i} style={{ cursor: "pointer" }}>
                  {point.made
                    ? renderMadeShot(point, pointColor)
                    : renderMissedShot(point, pointColor)}
                </g>
              );
            })}
          </g>
        </svg>
        {tooltipData && (
          <TooltipWithBounds
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyles}
          >
            {tooltip(tooltipData)}
          </TooltipWithBounds>
        )}
      </div>
    </div>
  );
}

interface Point {
  x: number;
  y: number;
  dist: number;
  made: boolean;
  fouled: boolean;
  epps: number;
  isThree: boolean;
  d: Shot;
}

function toPoint(d: Shot): Point {
  const dist = d.distanceFromHoopCenter || 0;
  const otherSideY = 94 - d.locationX;
  const flipShot = d.locationX > 40 && dist < 30;

  return {
    x: flipShot ? 50 - d.locationY : d.locationY,
    y: flipShot ? otherSideY : d.locationX,
    dist,
    made: d.made,
    fouled: d.fouled,
    epps: d.epps,
    isThree: d.isThree,
    d: d,
  };
}
