import React from "react";
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import {
  useQueryParams,
  StringParam,
  withDefault,
  ObjectParam,
} from "use-query-params";

import { RosterPlayer } from "../../shared/routers/RosterRouter";
import { PlayerList } from "../components/player/PlayerList";
import { ContractLabel } from "../components/player/ContractLabel";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { TeamDepthChart } from "../components/team/TeamDepthChart";
import { TeamLogo } from "../components/team/TeamLogo";
import { groupBy as arrGroupBy } from "../../shared/util/Collections";
import { trpc } from "../util/tRPC";
import { TWO_WAY_CONTRACTS } from "../constants/AppConstants";

const useStyles = makeStyles(() =>
  createStyles({
    listInline: {
      paddingLeft: 0,
      listStyle: "none",
      marginLeft: -5,
    },
    listItem: {
      display: "inline-block",
      paddingLeft: 4,
      paddingRight: 5,
    },
    grouping1: {
      marginBottom: 20,
      borderBottom: "1px solid #e4e7ea",
    },
    grouping2: {
      marginBottom: 30,
    },
    headerLink: {
      color: "#333",
    },
    playerList: {
      columnCount: 5,
      ["@media (max-width:992px)"]: {
        columnCount: 3,
      },
      ["@media (max-width:768px)"]: {
        columnCount: 2,
      },
      ["@media (max-width:480px)"]: {
        columnCount: 1,
      },
    },
  })
);

const CONTRACT_TYPES = [
  "UFA",
  "RFA",
  "TO",
  "PO",
  "MYC",
  "2WY",
  "EX10",
  "SUM",
] as const;
type Contract = typeof CONTRACT_TYPES[number];
const DEFAULT_FILTERS = CONTRACT_TYPES.reduce(
  (obj, contract) => Object.assign(obj, { [contract]: "1" }),
  {} as Record<Contract, "1" | "0">
);

export function PlayersPage() {
  const classes = useStyles();
  const [queryParams, setQueryParams] = useQueryParams({
    groupBy: withDefault(StringParam, "team"),
    filters: withDefault(ObjectParam, DEFAULT_FILTERS),
  });
  const { groupBy, filters } = queryParams;

  const { data: players } = trpc.roster.getSeasonRosters.useQuery();
  const { data: agents } = trpc.misc.getAgents.useQuery();

  let playersElement = null;

  const filteredPlayers = filterPlayers(players || [], filters);

  if (groupBy === "agent") {
    // If we don't have the agents data yet return null.
    if (!agents || !filteredPlayers) return null;

    // Using the agents create mapping of agentIds to agent names and a mapping
    // of agency name to array of all agent in that agency.
    const agentIdToName: Record<string, string> = {};
    const agencyToAgents: Record<string, string[]> = {};
    agents.forEach((agent) => {
      agentIdToName[agent.agentId] = agent.agentName;
      const agency = agent.agencyName || "Unknown";
      const agentsArray = agencyToAgents[agency];
      if (!agentsArray) {
        agencyToAgents[agency] = [agent.agentName];
      } else {
        agentsArray.push(agent.agentName);
      }
    });
    const unknownAgencyAgents = agencyToAgents["Unknown"];
    if (unknownAgencyAgents) {
      unknownAgencyAgents.push(". Represented Himself");
    }

    const playersForAgents: Record<string, RosterPlayer[]> = {};
    filteredPlayers.forEach((player) => {
      const agentIds = player.agentIds;
      if (agentIds) {
        agentIds.split(",").forEach((agentId) => {
          const agentName = agentIdToName[agentId] || "Unknown";
          const playersForAgentsArr = playersForAgents[agentName];
          if (!playersForAgentsArr) {
            playersForAgents[agentName] = [player];
          } else {
            playersForAgentsArr.push(player);
          }
        });
      } else {
        const agentName = player.agent || "";
        const playersForAgentArr = playersForAgents[agentName];
        if (!playersForAgentArr) {
          playersForAgents[agentName] = [player];
        } else {
          playersForAgentArr.push(player);
        }
      }
    });

    playersElement = Object.keys(agencyToAgents)
      .sort()
      .map((agencyName, i) => {
        const agentNames = agencyToAgents[agencyName];
        const doAnyAgentsHavePlayers = (agentNames || []).some(
          (ag) => playersForAgents[ag]
        );

        if (!doAnyAgentsHavePlayers) return null;

        return (
          <div key={i} className={classes.grouping1}>
            <h3>{agencyName}</h3>
            {(agentNames || []).sort().map((agentName, j) => {
              const players = playersForAgents[agentName];
              if (!players) return null;

              // I personally don't like the period in front so just remove it.
              const prettyAgentName =
                agentName === ". Represented Himself"
                  ? "Self Represented"
                  : agentName;

              return (
                <div key={j} className={classes.grouping2}>
                  <h4>{prettyAgentName}</h4>
                  <PlayerList
                    players={players.sort((a, b) =>
                      b.firstName + " " + b.lastName >
                      a.firstName + " " + a.lastName
                        ? -1
                        : 1
                    )}
                    className={classes.playerList}
                  />
                </div>
              );
            })}
          </div>
        );
      });
  } else if (!groupBy || groupBy === "team") {
    const groupedByTeam = arrGroupBy(filteredPlayers, (p) => p.team);
    playersElement = Object.keys(groupedByTeam)
      .sort()
      .map((teamName, i) => {
        const targetTeam = groupedByTeam[teamName] || [];
        const firstRow = targetTeam[0];
        const teamId = firstRow ? firstRow.teamid : 0;
        const depthChart = arrGroupBy(targetTeam, (p) => p.Role?.toString());
        return (
          <div key={i}>
            <h4>
              <Link to={`/team/${teamId}`} className={classes.headerLink}>
                <TeamLogo size={15} idsId={teamId} inline />
                {teamName}
              </Link>
            </h4>
            <TeamDepthChart
              orientation="wide"
              data={depthChart}
              showHeaders={false}
            />
          </div>
        );
      });
  } else if (groupBy === "name") {
    playersElement = (
      <PlayerList
        className={classes.playerList}
        players={filteredPlayers.sort((a, b) => {
          return a.lastName > b.lastName ? 1 : -1;
        })}
      />
    );
  }

  const handleGroupByClick = (value: string) => {
    setQueryParams({ groupBy: value });
  };

  const handleFilterIncludeCheck = (value: Contract) => {
    const curVal = filters[value];
    const newFilters = Object.assign({}, filters);
    newFilters[value] = curVal === "1" ? "0" : "1";
    setQueryParams({ filters: newFilters });
  };

  const filtersPanel = (
    <Col sm={6}>
      <Panel header="Filters">
        <ul className={classes.listInline}>
          {Object.keys(filters).map((key) => {
            const currFilter = filters[key as Contract];
            return (
              <li key={key} className={classes.listItem}>
                <label>
                  <input
                    type="checkbox"
                    checked={currFilter === "1"}
                    onChange={() => handleFilterIncludeCheck(key as Contract)}
                  />{" "}
                  <ContractLabel type={key} />
                </label>
              </li>
            );
          })}
        </ul>
      </Panel>
    </Col>
  );

  return (
    <Page header={{ text: "Players" }} title="Players">
      <Row>
        {filtersPanel}
        <Col sm={6}>
          <Panel header="Group By">
            <ButtonGroup>
              <Button
                className="btn-toggleable"
                active={groupBy === "name"}
                onClick={() => handleGroupByClick("name")}
              >
                Name
              </Button>
              <Button
                className="btn-toggleable"
                active={!groupBy || groupBy === "team"}
                onClick={() => handleGroupByClick("team")}
              >
                Team
              </Button>
              <Button
                className="btn-toggleable"
                active={groupBy === "agent"}
                onClick={() => handleGroupByClick("agent")}
              >
                Agent
              </Button>
            </ButtonGroup>
          </Panel>
        </Col>
        <Col xs={12}>
          <Panel header="Players">
            <div>{playersElement}</div>
          </Panel>
        </Col>
      </Row>
    </Page>
  );
}

function filterPlayers(
  players: RosterPlayer[],
  filters: Record<string, string | undefined>
) {
  return players.filter((player) => {
    const status = getFilterableContractStatus(player);
    return filters[status] === "1";
  });
}
function getFilterableContractStatus(player: RosterPlayer): string {
  const status = player.contractStatus;
  if (TWO_WAY_CONTRACTS.includes(player.playerStatusLk)) {
    return "2WY";
  } else if (
    status === "EX10" ||
    status === "UFA" ||
    status === "RFA" ||
    status === "TO" ||
    status === "PO" ||
    status === "SUM"
  ) {
    return status;
  }
  return "MYC";
}
