import React, { useContext, useMemo } from "react";
import { QueryParamConfig, DecodedValueMap, SetQuery } from "use-query-params";
import {
  Form,
  Row,
  Col,
  ToggleButtonGroup,
  ToggleButton,
  Alert,
} from "react-bootstrap";
import { FaCheck, FaTimes } from "react-icons/fa";

import { TeamContext } from "../../TeamContext";
import { NbaDates } from "../../../shared/NbaDates";
import { seasonString } from "../../util/Format";
import { NbaEaglePlayer } from "../../../shared/routers/RosterRouter";

export function TeamWowFilters(props: {
  allPlayers: NbaEaglePlayer[];
  queryParams: DecodedValueMap<{
    team: QueryParamConfig<string | null | undefined, string>;
    season: QueryParamConfig<string | null | undefined, string>;
    withPlayer: QueryParamConfig<string[]>;
    withoutPlayer: QueryParamConfig<string[]>;
    withOnePlayer: QueryParamConfig<string[]>;
    gameDate: QueryParamConfig<string[]>;
    leverage: QueryParamConfig<string | undefined>;
  }>;
  setQueryParams: SetQuery<{
    team: QueryParamConfig<string | null | undefined, string>;
    season: QueryParamConfig<string | null | undefined, string>;
    withPlayer: QueryParamConfig<string[]>;
    withoutPlayer: QueryParamConfig<string[]>;
    withOnePlayer: QueryParamConfig<string[]>;
    gameDate: QueryParamConfig<string[]>;
    leverage: QueryParamConfig<string | undefined>;
  }>;
  showSeasonAndDateControls: boolean;
  showLeverageControls: boolean;
}) {
  const {
    allPlayers,
    queryParams,
    setQueryParams,
    showSeasonAndDateControls,
    showLeverageControls,
  } = props;
  const {
    team,
    season,
    withPlayer,
    withoutPlayer,
    withOnePlayer,
    gameDate,
    leverage,
  } = queryParams;

  const teams = useContext(TeamContext).teams;

  const players = useMemo(() => {
    return allPlayers
      .filter((p) => {
        const entries = p.seasonTeam.split(" ").map((st) => {
          const season = st.split("_")[0];
          const teamId = st.split("_")[1];
          return { season, teamId };
        });
        return entries.some((e) => e.season === season && e.teamId === team);
      })
      .map((p) => {
        return { id: p.playerId.toString(), player: p.player };
      });
  }, [team, season, allPlayers]);

  const seasons = Object.keys(NbaDates)
    .reverse()
    .slice(0, 15)
    .map((season) => {
      return { value: season, label: seasonString(season) };
    });

  const setWithValue = (
    playerId: string,
    w: "with" | "without" | "withone"
  ) => {
    setQueryParams({ withPlayer: withPlayer.filter((w) => w !== playerId) });
    setQueryParams({
      withoutPlayer: withoutPlayer.filter((w) => w !== playerId),
    });
    setQueryParams({
      withOnePlayer: withOnePlayer.filter((w) => w !== playerId),
    });

    if (w === "with") {
      if (!withPlayer.includes(playerId)) {
        setQueryParams({ withPlayer: [...withPlayer, playerId] });
      }
    } else if (w === "without") {
      if (!withoutPlayer.includes(playerId)) {
        setQueryParams({
          withoutPlayer: [...withoutPlayer, playerId],
        });
      }
    } else if (w === "withone") {
      if (!withOnePlayer.includes(playerId)) {
        setQueryParams({
          withOnePlayer: [...withOnePlayer, playerId],
        });
      }
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
      <div style={{ display: "flex", gap: 12, flexWrap: "wrap" }}>
        <div>
          <Form.Label>Team</Form.Label>
          <Form.Select
            style={{ width: "auto" }}
            value={team}
            onChange={(evt) =>
              setQueryParams({
                team: evt.target.value,
                withPlayer: [],
                withoutPlayer: [],
                withOnePlayer: [],
              })
            }
          >
            {teams.map((t) => (
              <option key={t.teamid} value={t.teamid}>
                {t.teamcity} {t.teamname}
              </option>
            ))}
          </Form.Select>
        </div>
        {showSeasonAndDateControls && (
          <div>
            <Form.Label>Season</Form.Label>
            <Form.Select
              style={{ width: "auto" }}
              value={season}
              onChange={(evt) =>
                setQueryParams({
                  season: evt.target.value,
                  withPlayer: [],
                  withoutPlayer: [],
                  withOnePlayer: [],
                })
              }
            >
              {seasons.map((s) => (
                <option key={s.value} value={s.value}>
                  {s.label}
                </option>
              ))}
            </Form.Select>
          </div>
        )}
        {showSeasonAndDateControls && (
          <div>
            <Form.Label>Date Range</Form.Label>
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <Form.Control
                type="date"
                value={gameDate[0]}
                onChange={(evt) => {
                  setQueryParams({
                    gameDate: [evt.target.value, gameDate[1] || ""],
                  });
                }}
              />
              {" to "}
              <Form.Control
                type="date"
                value={gameDate[1]}
                onChange={(evt) => {
                  setQueryParams({
                    gameDate: [gameDate[0] || "", evt.target.value],
                  });
                }}
              />
            </div>
          </div>
        )}
        {showLeverageControls && (
          <div>
            <Form.Label>Leverage</Form.Label>
            <div>
              <Form.Select
                value={leverage}
                onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                  setQueryParams({ leverage: evt.target.value });
                }}
              >
                {[
                  { value: "none", label: "All Possessions" },
                  { value: "low", label: "No Garbage Time" },
                  { value: "med", label: "Close Game" },
                  { value: "high", label: "High Leverage" },
                ].map((o) => (
                  <option key={o.value} value={o.value}>
                    {o.label}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
        )}
      </div>
      {players.length === 0 ? (
        <Alert variant="warning">
          No players found for the currently selected season.
        </Alert>
      ) : (
        <div>
          <Form.Label>Roster</Form.Label>
          <Row>
            {players.map((p) => (
              <Col xs={12} sm={4} key={p.id}>
                <div
                  style={{
                    display: "flex",
                    height: 30,
                    marginBottom: 4,
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: 154 }}>{p.player}</div>
                  <ToggleButtonGroup type="checkbox">
                    <ToggleButton
                      checked={withPlayer.includes(p.id)}
                      variant={
                        withPlayer.includes(p.id) ? "primary" : "secondary"
                      }
                      onClick={() => setWithValue(p.id, "with")}
                      className="d-flex"
                      value={"with"}
                      style={{ height: 30 }}
                    >
                      <FaCheck />
                    </ToggleButton>
                    <ToggleButton
                      checked={withoutPlayer.includes(p.id)}
                      variant={
                        withoutPlayer.includes(p.id) ? "danger" : "secondary"
                      }
                      onClick={() => setWithValue(p.id, "without")}
                      className="d-flex"
                      value={"without"}
                      style={{ height: 30 }}
                    >
                      <FaTimes />
                    </ToggleButton>
                    <ToggleButton
                      checked={withOnePlayer.includes(p.id)}
                      onClick={() => setWithValue(p.id, "withone")}
                      variant={
                        withOnePlayer.includes(p.id) ? "info" : "secondary"
                      }
                      value={"withOne"}
                      style={{ height: 30, paddingTop: 2 }}
                    >
                      <em>1</em>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
      <div>
        <table style={{ color: "#888" }}>
          <tbody>
            <tr>
              <td style={{ textAlign: "center", padding: 0 }}>
                <FaCheck />
              </td>
              <td style={{ padding: 0 }}>
                With - All lineups will have this player if activated
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center", padding: 0 }}>
                <FaTimes />
              </td>
              <td style={{ padding: 0 }}>
                Without - All lineups will not have this player if activated
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center", padding: 0 }}>
                <em>1</em>
              </td>
              <td style={{ padding: 0 }}>
                Exactly 1 - All lineups will have exactly one of the players
                marked by this option if activated
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
