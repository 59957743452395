import React, { useState, useRef, ReactNode } from "react";
import ReactPlayer from "react-player";
import { createStyles, makeStyles } from "@material-ui/styles";
import cx from "classnames";
import { Button, Spinner, Overlay, Tooltip } from "react-bootstrap";
import {
  BsFillCheckCircleFill,
  BsFillQuestionCircleFill,
} from "react-icons/bs";
import moment from "moment";
import copy from "copy-to-clipboard";

import { VideoDownloader } from "./VideoDownloader";
import { Modal } from "../core/Modal";
import { useEventListener } from "../../util/Hooks";
import { intToSortableCharacterString } from "../../util/Format";

const useStyles = makeStyles(() =>
  createStyles({
    videoPlayerContainer: {
      display: "flex",
      backgroundColor: "black",
      height: "65vh",
      justifyContent: "center",
    },
    clipButtons: {
      overflowY: "scroll",
      flex: "1 1 30%",
    },
    clipButton: {
      margin: "4px 0px",
      display: "block",
      width: "100%",
      textAlign: "left",
      whiteSpace: "nowrap",
    },
    selected: {
      border: "1px solid white",
      background: "#44465d",
      "&:hover": {
        border: "1px solid white",
        background: "#44465d",
      },
    },
  })
);

// Set of urls we've attempted to prefetch.
const prefetchRequestedCache = new Set<string>();
// Set of urls we've successfully prefetched.
const prefetchFinishedCache = new Set<string>();
// Amount of clips past the current clip to prefetch.
const PREFETCH_LOOKAHEAD = 5;

export function VideoPlayer(props: {
  clips: { url: string; label: string; auxData?: Record<string, string> }[];
  upDownClipSkip: boolean;
  prefetchLength?: number;
  title?: string;
  showSynergyEditor: boolean;
  hideClipButtons?: boolean;
}) {
  const classes = useStyles();
  const playerContainerRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReactPlayer | null>(null);
  const {
    clips,
    upDownClipSkip,
    prefetchLength,
    title,
    showSynergyEditor,
    hideClipButtons,
  } = props;
  const [clipIdx, setClipIdx] = useState(0);
  const [elapsedSeconds, setElapsedSeconds] = useState(0);
  const [muted, setMuted] = useState(false);
  const [slowMotion, setSlowMotion] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [showDownloader, setShowDownloader] = useState<string | undefined>(
    undefined
  );
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const copyButton = useRef<HTMLButtonElement | null>(null);

  if (clipIdx !== 0 && clipIdx >= clips.length) {
    setClipIdx(0);
  }

  if (prefetchLength !== 0) {
    // On every render make sure we have cached the next N clips.
    clips
      .slice(clipIdx, clipIdx + (prefetchLength || PREFETCH_LOOKAHEAD) + 1)
      .forEach((clip) => {
        const url = clip.url;
        // If we haven't tried to fetch this yet fetch it.
        if (!prefetchRequestedCache.has(url)) {
          const xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.responseType = "blob";
          xhr.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
              // Just download the first 20% for prefetching.
              if (event.loaded / event.total > 0.2) {
                xhr.abort();
                prefetchFinishedCache.add(url);
              }
            }
          });
          xhr.send();
          // Add this to the attempted set.
          prefetchRequestedCache.add(url);
        }
      });
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!playerRef.current) return;

    let newTime = 0;
    switch (event.key) {
      case "f":
        if (!document.fullscreenElement) {
          const player = document.querySelector("video");
          if (player) {
            player.requestFullscreen();
          }
        } else {
          document.exitFullscreen();
        }
        break;
      case "m":
        setMuted(!muted);
        break;
      case "s":
        setSlowMotion(!slowMotion);
        break;
      case "r":
        playerRef.current.seekTo(0, "seconds");
        setElapsedSeconds(0);
        break;
      case "Down": // IE/Edge specific value.
      case "ArrowDown":
        if (upDownClipSkip) {
          nextClip();
        }
        // Stop arrow keys from scrolling.
        event.preventDefault();
        break;
      case "Up": // IE/Edge specific value.
      case "ArrowUp":
        if (upDownClipSkip) {
          prevClip();
        }
        // Stop arrow keys from scrolling.
        event.preventDefault();
        break;
      case "Left": // IE/Edge specific value.
      case "ArrowLeft":
        newTime = Math.max(0, elapsedSeconds - (event.shiftKey ? 15 : 5));
        playerRef.current.seekTo(newTime, "seconds");
        setElapsedSeconds(newTime);
        break;
      case "Right": // IE/Edge specific value.
      case "ArrowRight":
        newTime = elapsedSeconds + (event.shiftKey ? 15 : 5);
        playerRef.current.seekTo(newTime, "seconds");
        setElapsedSeconds(newTime);
        break;
      case " ":
        // Just let the video player handle the spacebar if it is already
        // focused.
        if (document.activeElement?.tagName !== "VIDEO") {
          setPlaying(!playing);
        }
        // Stop arrow keys from scrolling.
        event.preventDefault();
        break;
    }
  };

  const nextClip = () => {
    // If we are on the last clip stop playing and don't advance the clip.
    if (clipIdx + 1 >= clips.length) {
      setPlaying(false);
      return;
    }
    const newIdx = clipIdx + 1;
    setElapsedSeconds(0);
    setClipIdx(newIdx);
    // Make sure this is true.
    setPlaying(true);
  };

  const prevClip = () => {
    // If we are on the first clip stop playing and don't go back a clip.
    if (clipIdx - 1 < 0) {
      setPlaying(false);
      return;
    }
    const newIdx = clipIdx - 1;
    setElapsedSeconds(0);
    setClipIdx(newIdx);
    // Make sure this is true.
    setPlaying(true);
  };

  const seekClip = (idx: number) => {
    setClipIdx(idx);
    // Make sure this is true.
    setPlaying(true);
  };

  useEventListener("keydown", handleKeyDown, playerContainerRef);

  const handleProgress = (state: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => {
    setElapsedSeconds(state.playedSeconds);
  };

  const getStatusIcon = (url: string) => {
    let content: ReactNode = "";

    if (prefetchFinishedCache.has(url)) {
      content = <BsFillCheckCircleFill />;
    } else if (prefetchRequestedCache.has(url)) {
      content = <Spinner animation="border" size="sm" />;
    }
    return (
      <span
        style={{ opacity: 0.1, float: "right", maxHeight: 16, maxWidth: 16 }}
      >
        {content}
      </span>
    );
  };

  if (!clips || clips.length === 0) {
    return null;
  }

  if (clipIdx >= clips.length) {
    return null;
  }

  const clip = clips[clipIdx];

  if (!clip) return null;

  return (
    <div>
      <div
        className={classes.videoPlayerContainer}
        ref={playerContainerRef}
        tabIndex={0}
      >
        <ReactPlayer
          style={{ flex: "3 1 70%" }}
          ref={playerRef}
          url={clip.url}
          controls={true}
          height="100%"
          width="100%"
          muted={muted}
          playing={playing}
          onReady={() => playerRef.current?.getInternalPlayer().focus()}
          onEnded={() => nextClip()}
          onProgress={handleProgress}
          onSeek={(sec) => {
            // Make sure we are synced. This onSeek can fire on arrow keys (where)
            // we seek with code or when a user clicks. On user click we are
            // temporarily out of sync b/c our react component doesn't know the
            // elapsed time yet. So handle that case here.
            if (sec !== elapsedSeconds) {
              setElapsedSeconds(sec);
            }
          }}
          playbackRate={slowMotion ? 0.5 : 1}
        />
        {!hideClipButtons && (
          <div className={classes.clipButtons}>
            {clips.map((clip, i) => {
              return (
                <Button
                  key={i}
                  className={cx(classes.clipButton, {
                    [classes.selected]: i === clipIdx,
                  })}
                  onClick={(evt) => {
                    (evt.target as HTMLButtonElement).blur();
                    seekClip(i);
                    playerRef.current?.getInternalPlayer().focus();
                  }}
                  variant="secondary"
                >
                  {intToSortableCharacterString(i, clips.length)} - {clip.label}
                  {getStatusIcon(clip.url)}
                </Button>
              );
            })}
          </div>
        )}
      </div>
      {showHelp && (
        <Modal
          title={"Video Hot Keys"}
          content={<VideoHotKeys upDownClipSkip={upDownClipSkip} />}
          show={showHelp}
          handleClose={() => setShowHelp(false)}
        ></Modal>
      )}
      {showDownloader && (
        <Modal
          title={
            showDownloader === "synergy"
              ? "Download Video  - Synergy Editor"
              : "Download Video"
          }
          content={
            <VideoDownloader
              synergyEditor={showDownloader === "synergy"}
              defaultName={title || `${moment().format("MM/DD/YYY")} - Clips`}
              clips={clips}
              clipChunkSize={prefetchLength}
            />
          }
          show={showDownloader !== undefined}
          handleClose={() => setShowDownloader(undefined)}
          disableBackgroundClickClose={true}
        ></Modal>
      )}
      <div style={{ display: "flex", gap: 8, marginTop: 8 }}>
        <Button variant="secondary" onClick={() => setShowHelp(true)}>
          <BsFillQuestionCircleFill />
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setPlaying(false);
            setShowDownloader("download");
          }}
        >
          Download
        </Button>
        {showSynergyEditor && (
          <Button
            variant="secondary"
            onClick={() => {
              setPlaying(false);
              setShowDownloader("synergy");
            }}
          >
            Synergy Editor
          </Button>
        )}
        <Button
          ref={copyButton}
          variant="secondary"
          onClick={() => {
            copy(clip.url);
            setShowCopyTooltip(true);
            setTimeout(() => setShowCopyTooltip(false), 1500);
          }}
        >
          Copy
        </Button>
        <Overlay
          target={copyButton.current}
          show={showCopyTooltip}
          placement="top"
        >
          <Tooltip>Video Copied to Clipboard!</Tooltip>
        </Overlay>
      </div>
    </div>
  );
}

function HotKeySymbol(props: { children: ReactNode }) {
  return (
    <kbd
      style={{
        marginRight: 4,
        color: "#fff",
        backgroundColor: "#333",
        borderRadius: 3,
        boxShadow: "inset 0 -1px 0 rgb(0 0 0 / 25%)",
      }}
    >
      {props.children}
    </kbd>
  );
}

function VideoHotKeys(props: { upDownClipSkip: boolean }) {
  const { upDownClipSkip } = props;
  const rows = [
    {
      symbol: <HotKeySymbol>&#x2192;</HotKeySymbol>,
      desc: "forward 5 seconds",
    },
    {
      symbol: (
        <span>
          <HotKeySymbol>&#x2192;</HotKeySymbol>
          <HotKeySymbol>shift</HotKeySymbol>
        </span>
      ),
      desc: "forward 15 seconds",
    },
    {
      symbol: <HotKeySymbol>&#x2192;</HotKeySymbol>,
      desc: "backward 5 seconds",
    },
    {
      symbol: (
        <span>
          <HotKeySymbol>&#x2192;</HotKeySymbol>{" "}
          <HotKeySymbol>shift</HotKeySymbol>
        </span>
      ),
      desc: "backward 15 seconds",
    },
    { symbol: <HotKeySymbol>&#x2191;</HotKeySymbol>, desc: "previous clip" },
    { symbol: <HotKeySymbol>&#x2193;</HotKeySymbol>, desc: "next clip" },
    { symbol: <HotKeySymbol>r</HotKeySymbol>, desc: "restart clip" },
    { symbol: <HotKeySymbol>space</HotKeySymbol>, desc: "pause clip" },
    { symbol: <HotKeySymbol>s</HotKeySymbol>, desc: "toggle slow motion" },
    { symbol: <HotKeySymbol>f</HotKeySymbol>, desc: "toggle fullscreen" },
    { symbol: <HotKeySymbol>m</HotKeySymbol>, desc: "mute / unmute" },
  ];
  return (
    <div>
      <p>(click on video to enable hot keys)</p>
      <table>
        {rows
          .filter(
            (r) =>
              upDownClipSkip || !["previous clip", "next clip"].includes(r.desc)
          )
          .map((r, i) => (
            <tr key={i}>
              <td>{r.symbol}</td>
              {r.desc}
            </tr>
          ))}
      </table>
    </div>
  );
}
