import React from "react";
import { Modal } from "react-bootstrap";
import { createStyles, makeStyles } from "@material-ui/styles";

import { VideoPlayer } from "./VideoPlayer";

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      "& div.modal-content": {
        backgroundColor: "black",
      },
    },
    header: {
      "& .btn-close": {
        opacity: 1,
        color: "white",
        filter: "invert(1)",
      },
    },
    title: {
      color: "white",
    },
  })
);

export function VideoModal(props: {
  title: string;
  show: boolean;
  clips: { label: string; url: string }[];
  handleClose: () => void;
  upDownClipSkip: boolean;
  showSynergyEditor: boolean;
}) {
  const classes = useStyles();
  const { title, show, clips, handleClose, upDownClipSkip, showSynergyEditor } =
    props;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      fullscreen={true}
      className={classes.modal}
      disableBackgroundClickClose={true}
    >
      <Modal.Header className={classes.header} closeButton>
        <Modal.Title className={classes.title}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VideoPlayer
          title={title}
          clips={clips}
          upDownClipSkip={upDownClipSkip}
          showSynergyEditor={showSynergyEditor}
        />
      </Modal.Body>
    </Modal>
  );
}
