import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";

import { moneyFormat, seasonString } from "../../util/Format";
import { ContractLabel } from "./ContractLabel";
import AppContext from "../../../shared/AppContext";
import { trpc } from "../../util/tRPC";

const useStyles = makeStyles(() =>
  createStyles({
    contractSummary: {
      padding: 4,
      textAlign: "left",
      borderRadius: 4,
      display: "inline-block",
      color: "rgba(255,255,255,.4)",
      fontSize: ".9em",
      verticalAlign: "top",
      backgroundColor: "rgba(255,255,255,.03)",
      "& td": {
        padding: "1px 4px",
        whiteSpace: "nowrap",
      },
      "& tr:first-child td": {
        fontWeight: 600,
        color: "rgba(255,255,255,.7)",
      },
    },
    contractExtras: {
      padding: "1px 4px",
    },
  })
);

export function PlayerContractSummary(props: { playerId: number }) {
  const { playerId } = props;
  const classes = useStyles();
  const extrasLegend =
    "B = Bonus  \nSB = Signing Bonus  \nPO = Player Option  \nTO = Team Option  \nETO = Early Termination Option  \nTK = Trade Kicker  \nNG = Non-guaranteed";

  const { data: contract } = trpc.player.getPlayerContract.useQuery({
    playerId,
  });

  const { data: contractBadgeData } =
    trpc.player.getPlayerContractBadge.useQuery({
      playerId,
    });

  const contractBadge = contractBadgeData && contractBadgeData[0];

  const { data: playerSalary } = trpc.player.getPlayerSalaryByYear.useQuery({
    playerId,
  });

  if (
    !(
      contract &&
      contract.length &&
      contractBadge &&
      playerSalary &&
      playerSalary.length
    )
  )
    return null;

  /*
      optionEto: 'OPT-P-15' -- player option in 2015-16 year (D Wade)
      optionEto: 'OPT-T-16, 17' -- team option in 2016-17, 2017-18 year (Shabazz Napier)
      optionEto: 'ETO-15' (Thaddeus Young)
      miscBon:  (Shabazz Napier)
      partial: 'Part./Cond.' (Phil Pressey -- normally is 'Full')
      tradeBonus: 'Yes' (DeAndre Jordan)
    */

  const status = contractBadge.status;
  const playerStatusLk = contractBadge.playerStatusLk;

  // Filter out years before now.
  const relevantData = contract
    .filter((c) => c.season >= AppContext.latestDraftYear)
    .sort((a, b) => a.season - b.season);

  const extras: string[] = [];
  if (relevantData.find((d) => !!d.hasTradeBonus)) {
    extras.push("TK");
  }

  let contractLabel;

  if (
    status &&
    ["PO", "TO", "RFA", "UFA", "SUM", "EX10"].indexOf(status) !== -1
  ) {
    contractLabel = <ContractLabel type={status} status={playerStatusLk} />;
  }

  let contractExtras;
  if (contractLabel || extras.length) {
    contractExtras = (
      <span className={classes.contractExtras} title={extrasLegend}>
        {contractLabel}
        {" " + extras.join(", ")}
      </span>
    );
  }

  return (
    <div className={classes.contractSummary}>
      <table>
        <tbody>
          {relevantData.map((d, i) => {
            const extras = [];
            if (
              (d.option === "PLYR" || d.option === "PLYTF") &&
              d.optionDecision !== "POE"
            ) {
              // POE = Player Option Exercised.
              extras.push("PO");
            } else if (d.option === "TEAM" && d.optionDecision !== "TOE") {
              // TOE = Team Option Exercised.
              extras.push("TO");
            } else if (d.option === "ETO") {
              extras.push("ETO");
            }

            if (d.offseasonBonus > 0 || d.likelyUnlikelyBonusAmount > 0) {
              extras.push("B");
            }

            if (d.contractType !== "SUM" && d.protection !== "FULL") {
              extras.push("NG");
            }

            const money =
              (d.exhibit10 && d.exhibit10BonusAmount !== null
                ? moneyFormat(d.exhibit10BonusAmount) + " / "
                : "") + moneyFormat(d.salary);
            return (
              <tr key={i}>
                <td>{seasonString(d.season.toString())}</td>
                <td title={money}>{money}</td>
                <td className={classes.contractExtras} title={extrasLegend}>
                  {extras.join(", ")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {contractExtras}
      {/* TODO(chrusbu): Uncomment when this is calculated correct. */}
      {/* <div>
        <br />
        Career Earnings:{" "}
        {moneyFormat(
          playerSalary
            .filter((ps) => ps.season < parseInt(AppContext.currentSeason))
            .reduce((acc, cur) => acc + cur.salary, 0)
        )}
      </div> */}
    </div>
  );
}
