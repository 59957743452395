import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { BsQuestionCircleFill, BsStar } from "react-icons/bs";
import { Link } from "react-router-dom";

import { PlayerBio } from "../../../shared/routers/PlayerRouter";
import { TooltipItem } from "../core/TooltipItem";
import { dec100Format, seFormat } from "../../util/Format";
import { Positions } from "../../constants/AppConstants";
import { trpc } from "../../util/tRPC";
import { purple, green } from "../../constants/ColorConstants";

const useStyles = makeStyles(() =>
  createStyles({
    impactLink: {
      textDecoration: "none",
      color: "unset",
      cursor: "pointer",
      ["@media (max-width:768px)"]: {
        margin: "0 auto",
        width: "fit-content",
      },
      "&:hover": {
        cursor: "pointer",
        color: "unset",
      },
    },
    playerImpactByPos: {
      backgroundColor: "rgba(255, 255, 255, 0.11)",
      padding: 3,
      borderRadius: 4,
      marginTop: 8,
    },
    playerImpactForecast: {
      display: "grid",
      gridTemplateColumns: "auto auto auto auto",
      backgroundColor: "rgba(255, 255, 255, 0.11)",
      padding: 3,
      borderRadius: 4,
    },
    netLine: {
      display: "flex",
      alignItems: "center",
    },
    netImpact: {
      margin: 0,
      fontWeight: 500,
      fontSize: 40,
    },
    netSE: {
      marginLeft: 6,
      fontSize: "1.1em",
    },
    forecastValueNet: {
      textAlign: "left",
      paddingLeft: 4,
      gridColumnStart: "span 1",
      fontWeight: 200,
      marginTop: "auto",
      marginBottom: "auto",
      padding: ".2em .6em .45em",
    },
    forecastValue: {
      gridColumnStart: "span 1",
      textAlign: "center",
      fontWeight: 200,
      marginTop: "auto",
      marginBottom: "auto",
      padding: ".2em .6em .45em",
    },
    forecastValueNumber: {
      margin: "0 0 3px",
      fontWeight: 400,
      fontSize: 20,
    },
    forecastLabel: {
      margin: 0,
      opacity: 0.6,
      fontSize: "1.0em",
    },
  })
);

export function PlayerImpact(props: {
  bio: PlayerBio;
  handleGlobalImpactEstimateClick: (tab: string) => void;
  handleImpactBreakdownClick: () => void;
}) {
  const classes = useStyles();
  const { bio, handleImpactBreakdownClick, handleGlobalImpactEstimateClick } =
    props;
  const playerId = bio.playerId;

  const { data: impactByPos } = trpc.impact.getPlayerImpactByPosition.useQuery({
    playerId,
  });

  const { data: impactByRole } = trpc.impact.getPlayerImpactByRole.useQuery({
    playerId,
  });

  const sortedImpactByRole = (impactByRole || []).sort(
    (a, b) => b.off_impact + b.def_impact - (a.off_impact + a.def_impact)
  );

  const firstSortedImpactByRole = sortedImpactByRole[0];

  const maxImpact = firstSortedImpactByRole
    ? firstSortedImpactByRole.off_impact + firstSortedImpactByRole.def_impact
    : undefined;

  // If player has played 250+ poss in NBA use their camera number.
  // Else if a player has draft eligibility OR has a draft model number for the
  // current season AND it is higher than their current global estimate use that.
  // Else if they have a global estimate use that.
  const hasNBAImpact = !!(bio.nPoss && bio.nPoss >= 250);
  const useDraftModel =
    // If still pre-draft just use draft model.
    bio.pastDraft === 0 ||
    (!hasNBAImpact &&
      bio.draft_model_net !== null &&
      bio.draft_model_net > (bio.global_est_net || 0));
  const useGlobalEstimate =
    !hasNBAImpact && !useDraftModel && bio.global_est_net;
  let source = "Net Impact";
  let color: string | undefined = undefined;
  let netImpact = bio.netImpact;
  let offImpact = bio.offImpact;
  let defImpact = bio.defImpact;
  const netSE = (bio.netSE || 0) * 100 * 1.96;
  if (useDraftModel) {
    color = purple;
    offImpact = bio.draft_model_off;
    defImpact = bio.draft_model_def;
    netImpact = bio.draft_model_net;
    source = "Projected Peak";
  } else if (useGlobalEstimate) {
    color = green;
    offImpact = bio.global_est_off;
    defImpact = bio.global_est_def;
    netImpact = bio.global_est_net;
    source = "Estimated Impact";
  } else if (!hasNBAImpact) {
    return null;
  }

  const impactContent = (
    <div className={classes.playerImpactForecast}>
      <div className={classes.forecastValueNet}>
        <div className={classes.netLine}>
          <h1 className={classes.netImpact} style={{ color }}>
            {dec100Format(netImpact)}
          </h1>
          {source === "Net Impact" && (
            <span className={classes.netSE}>{seFormat(netSE)}</span>
          )}
        </div>
        <h6 className={classes.forecastLabel}>{source}</h6>
        {source !== "Net Impact" && (bio.nPoss || 0) > 0 && (
          <div className={classes.forecastLabel}>
            {Math.round(bio.nPoss || 0)}/250 recent NBA Poss
          </div>
        )}
      </div>
      <div className={classes.forecastValue}>
        <h3 className={classes.forecastValueNumber} style={{ color }}>
          {dec100Format(offImpact)}
        </h3>
        <h6 className={classes.forecastLabel}>OFF</h6>
      </div>
      <div className={classes.forecastValue}>
        <h3 className={classes.forecastValueNumber} style={{ color }}>
          {dec100Format(defImpact)}
        </h3>
        <h6 className={classes.forecastLabel}>DEF</h6>
      </div>
    </div>
  );

  return (
    <div style={{ width: "max-content" }}>
      {hasNBAImpact && (
        <Link className={classes.impactLink} to={`/stats/netImpact`}>
          {impactContent}
        </Link>
      )}
      {!hasNBAImpact && (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            handleGlobalImpactEstimateClick(
              useDraftModel
                ? "skillModelCombinedPeak"
                : "skillModelCombinedSeason"
            )
          }
        >
          {impactContent}
        </div>
      )}
      {impactByPos && maxImpact && (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleImpactBreakdownClick()}
        >
          <div
            style={{ display: "flex", padding: 4 }}
            className={classes.playerImpactByPos}
          >
            <table style={{ width: "100%" }}>
              <thead></thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 0,
                    }}
                  >
                    <BsStar />
                  </td>
                  {Object.keys(Positions).map((p) => (
                    <td
                      key={p}
                      style={{
                        textAlign: "center",
                        padding: 0,
                        fontWeight: p === bio.position ? "bold" : "normal",
                      }}
                    >
                      {Positions[parseInt(p)]}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      padding: 0,
                    }}
                  >
                    {dec100Format(maxImpact)}
                  </td>
                  {impactByPos
                    .sort((a, b) => a.Pos_used - b.Pos_used)
                    .map((impact, i) => (
                      <td
                        key={i}
                        style={{
                          textAlign: "center",
                          padding: 0,
                          fontWeight:
                            impact.Pos_used === parseInt(bio.position || "")
                              ? "bold"
                              : "normal",
                        }}
                      >
                        {dec100Format(impact.off_impact + impact.def_impact)}
                      </td>
                    ))}
                </tr>
              </tbody>
            </table>
            <TooltipItem
              tooltip={
                "The player's predicted net impact given the typical role for that position as well as in the role of the other NBA player that would best maximize this player's impact."
              }
              noListItem={true}
              placement={"top"}
            >
              <BsQuestionCircleFill
                style={{ verticalAlign: "top", marginTop: 2 }}
              />
            </TooltipItem>
          </div>
        </div>
      )}
    </div>
  );
}
