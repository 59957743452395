import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { Highlights } from "../../constants/AppConstants";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import { TeamTableCell } from "../core/TableCell";
import { LeagueSeasonTeamSchedule } from "../../../shared/routers/LeagueRouter";
import {
  makePlusMinus,
  atMostDecFormat,
  TABLE_EMPTY_VALUE_STR,
} from "../../util/Format";

const columnHelper = createColumnHelper<LeagueSeasonTeamSchedule>();

export function TeamMultiLeagueSchedule(props: {
  gameId?: string;
  data: LeagueSeasonTeamSchedule[];
}) {
  const { data, gameId } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("league", {
        header: () => "League",
        cell: (info) => info.getValue(),
        meta: { group: 0, textAlign: "left" },
      }),
      columnHelper.accessor("team", {
        header: () => "Team",
        cell: (info) => (
          <TeamTableCell
            id={info.row.original.teamId}
            ids={info.row.original.teamIdsId || undefined}
            name={info.getValue()}
            league={info.row.original.leagueKey
              .replaceAll(" ", "_")
              .toLocaleLowerCase()}
            season={info.row.original.season.toString()}
          />
        ),
        meta: { group: 1 },
      }),
      columnHelper.accessor("gameDate", {
        header: () => "Date",
        cell: (info) => {
          return moment(info.getValue().replaceAll("-", "/")).format(
            "MM/DD/YYYY"
          );
        },
        meta: { group: 2 },
      }),
      columnHelper.accessor("gameTime", {
        header: () => "Time",
        cell: (info) => info.getValue(),
        meta: { group: 3 },
      }),
      columnHelper.accessor("teamId", {
        header: () => "",
        cell: (info) =>
          info.getValue() === info.row.original.homeTeamId.toString()
            ? "vs"
            : "@",
        meta: { group: 4 },
      }),
      columnHelper.accessor("opponent", {
        header: () => "Opp",
        cell: (info) => (
          <TeamTableCell
            id={info.row.original.opponentId}
            ids={info.row.original.opponentIdsId || undefined}
            name={info.getValue()}
            league={info.row.original.leagueKey
              .replaceAll(" ", "_")
              .toLocaleLowerCase()}
            season={info.row.original.season.toString()}
          />
        ),
        meta: { group: 5 },
      }),
      columnHelper.accessor("score", {
        header: () => "Result",
        cell: (info) => {
          const d = info.row.original;
          if (d.pointDifferential) {
            const wl = d.pointDifferential > 0 ? "W" : "L";
            const isNBA = d.leagueKey.toLocaleLowerCase() === "nba";
            if (isNBA) {
              return (
                <Link to={`/game/${d.gameId}`}>
                  {`${wl} ${info.getValue()}`}
                </Link>
              );
            }
            return (
              <Link
                to={`/game/ml/${d.leagueKey.replaceAll(" ", "_")}/${d.gameId}`}
              >
                {`${wl} ${info.getValue()}`}
              </Link>
            );
          }
          return <Link to={`/preview/${d.gameId}`}>Preview</Link>;
        },
        meta: { group: 6 },
      }),
      columnHelper.accessor("pointDifferential", {
        header: () => "+/-",
        cell: (info) =>
          info.getValue()
            ? makePlusMinus(atMostDecFormat)(info.getValue())
            : TABLE_EMPTY_VALUE_STR,
        meta: {
          highlights: Highlights.Max,
          colorDomain: [-10, 10],
          group: 6,
        },
      }),
    ],
    []
  );

  const highlightIdx = data.findIndex((d) => d.gameId === gameId);

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
      showColorOnHover={true}
      rowColorMap={
        gameId
          ? {
              [highlightIdx]: { backgroundColor: "#ffffe0 !important" },
            }
          : undefined
      }
      disableStickyColumn={true}
    />
  );
}
