import React from "react";

export function LinearGradient(props: {
  id: string;
  colorData: string[];
  stopData?: string[];
  orientation?: "horizontal" | "vertical";
  reverse?: boolean;
}) {
  const { id, colorData, stopData, reverse } = props;
  const orientation = props.orientation || "horizontal";

  const numColors = colorData.length;

  const xyProps = { y1: "", y2: "", x1: "", x2: "" };

  if (orientation === "vertical") {
    xyProps.y1 = reverse ? "100%" : "0";
    xyProps.y2 = reverse ? "0" : "100%";
    xyProps.x1 = xyProps.x2 = "0";
  } else {
    xyProps.x1 = reverse ? "100%" : "0";
    xyProps.x2 = reverse ? "0" : "100%";
    xyProps.y1 = xyProps.y2 = "0";
  }

  return (
    <defs>
      <linearGradient id={id} gradientUnits="objectBoundingBox" {...xyProps}>
        {colorData.map((stop, j) => {
          return (
            <stop
              key={j}
              offset={stopData ? stopData[j] : (100 * j) / numColors + "%"}
              style={{ stopColor: stop }}
            />
          );
        })}
      </linearGradient>
    </defs>
  );
}
