import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { PlayerTableCell } from "../core/TableCell";
import { GameMultiLeagueBoxscorePlayer } from "../../../shared/routers/GameRouter";
import { sum } from "../../util/Util";
import { minutesFormat, fractionFormat } from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";

const columnHelper = createColumnHelper<GameMultiLeagueBoxscorePlayer>();

export function GameMultiLeagueBoxScoresTable(props: {
  data: GameMultiLeagueBoxscorePlayer[];
}) {
  const { data } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.playerId || undefined}
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.accessor("jerseyNumber", {
        header: () => "#",
        cell: (info) => info.getValue(),
        meta: { group: 1 },
      }),
      columnHelper.accessor("min", {
        header: () => "Min",
        cell: (info) => {
          if (info.row.original.playerMinutesExist) {
            return (
              <Link
                to={`/player-minutes/${info.row.original.gameId}/${info.row.original.playerId}`}
              >
                {minutesFormat(info.getValue())}
              </Link>
            );
          } else {
            return minutesFormat(info.getValue());
          }
        },
        footer: () => minutesFormat(sum("min", data)),
        meta: { highlights: Highlights.Max, group: 2 },
      }),
      columnHelper.accessor("PTS", {
        header: () => "PTS",
        cell: (info) => info.getValue(),
        footer: () => sum("PTS", data),
        meta: { highlights: Highlights.Max, group: 3 },
      }),
      columnHelper.accessor("rtot", {
        header: () => "REB",
        cell: (info) => info.getValue(),
        footer: () => sum("rtot", data),
        meta: { highlights: Highlights.Max, group: 3 },
      }),
      columnHelper.accessor("ast", {
        header: () => "AST",
        cell: (info) => info.getValue(),
        footer: () => sum("ast", data),
        meta: { highlights: Highlights.Max, group: 3 },
      }),
      columnHelper.accessor((row) => (row.fg2a ? row.fg2m / row.fg2a : ""), {
        id: "fg2",
        header: () => "2PM/A",
        cell: (info) =>
          fractionFormat({
            denominator: info.row.original["fg2a"],
            numerator: info.row.original["fg2m"],
          }),
        footer: () =>
          fractionFormat({
            denominator: sum("fg2a", data),
            numerator: sum("fg2m", data),
          }),
        meta: { highlights: Highlights.Max, group: 4 },
      }),
      columnHelper.accessor((row) => (row.fg3a ? row.fg3m / row.fg3a : ""), {
        id: "fg3",
        header: () => "3PM/A",
        cell: (info) =>
          fractionFormat({
            denominator: info.row.original["fg3a"],
            numerator: info.row.original["fg3m"],
          }),
        footer: () =>
          fractionFormat({
            denominator: sum("fg3a", data),
            numerator: sum("fg3m", data),
          }),
        meta: { highlights: Highlights.Max, group: 4 },
      }),
      columnHelper.accessor((row) => (row.fta ? row.ftm / row.fta : ""), {
        id: "ft",
        header: () => "FTM/A",
        cell: (info) =>
          fractionFormat({
            denominator: info.row.original["fta"],
            numerator: info.row.original["ftm"],
          }),
        footer: () =>
          fractionFormat({
            denominator: sum("fta", data),
            numerator: sum("ftm", data),
          }),
        meta: { highlights: Highlights.Max, group: 4 },
      }),
      columnHelper.accessor("roff", {
        header: () => "OR",
        cell: (info) => info.getValue(),
        footer: () => sum("roff", data),
        meta: { highlights: Highlights.Max, group: 5 },
      }),
      columnHelper.accessor("rdef", {
        header: () => "DR",
        cell: (info) => info.getValue(),
        footer: () => sum("rdef", data),
        meta: { highlights: Highlights.Max, group: 5 },
      }),
      columnHelper.accessor("blk", {
        header: () => "BLK",
        cell: (info) => info.getValue(),
        footer: () => sum("blk", data),
        meta: { highlights: Highlights.Max, group: 6 },
      }),
      columnHelper.accessor("stl", {
        header: () => "STL",
        cell: (info) => info.getValue(),
        footer: () => sum("stl", data),
        meta: { highlights: Highlights.Max, group: 6 },
      }),
      columnHelper.accessor("turn", {
        header: () => "TO",
        cell: (info) => info.getValue(),
        footer: () => sum("turn", data),
        meta: { highlights: Highlights.Min, group: 7 },
      }),
      columnHelper.accessor("pf", {
        header: () => "PF",
        cell: (info) => info.getValue(),
        footer: () => sum("pf", data),
        meta: { highlights: Highlights.Min, group: 7 },
      }),
    ],
    [data]
  );

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
      showColorOnHover={true}
    />
  );
}
