import React, { useState, useEffect } from "react";
import cx from "classnames";

import { createStyles, makeStyles } from "@material-ui/styles";

// List of player IDs with custom photos.
const CUSTOM_PHOTOS = [
  1, // Danny Ainge
  2, // Austin Ainge
  3, // Jake Eastman
  4, // Remy Cofield
  6, // Jay Larranaga
  8922, // Luiz Lemes
  38452, // Alex Barlow
  38510, // Marshall Plumlee
  65608, // Benas Matkevicius
  142071, // Bernie Sanders
  167950, // Steponas Zilakauskis
  176081, // Robbie Avila
];

const useStyles = makeStyles(() =>
  createStyles({
    playerPhoto: {
      width: 120,
      height: 120,
      borderRadius: "50%",
      display: "inline-block",
      backgroundPosition: "center bottom",
      backgroundSize: 150,
      backgroundRepeat: "no-repeat",
      backgroundColor: "rgba(0,0,0,0.1)",
      boxShadow: "0 1px 1px rgba(0,0,0,0.2) inset",
    },
    dxAdjust: {
      backgroundPosition: "center",
      backgroundSize: "contain",
    },
  })
);

export function PlayerPhoto(props: {
  ids?: number;
  playerId?: number;
  dxPhoto?: string | null;
}) {
  const [badIdsImageUrl, setBadIdsImageUrl] = useState(false);
  const classes = useStyles();
  const { playerId, ids, dxPhoto } = props;
  let url;
  let dxAdjust = false;

  // See if the ids photo actually exists. If we can't load it we will fall back
  // to the dx photo (or unknown).
  useEffect(() => {
    if (!ids) return;
    const potentialUrl =
      "https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/" +
      ids +
      ".png";
    load(potentialUrl).then(
      () => setBadIdsImageUrl(false),
      () => setBadIdsImageUrl(true)
    );
  }, [ids]);

  if (playerId && CUSTOM_PHOTOS.indexOf(playerId) !== -1) {
    url = "/assets/img/players/" + playerId + ".png";
  } else if (ids && !badIdsImageUrl) {
    url =
      "https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/" +
      ids +
      ".png";
  } else if (dxPhoto) {
    url = dxPhoto;
    dxAdjust = true;
  } else {
    url = "/assets/img/player_default.png";
  }

  return (
    <div
      className={cx(classes.playerPhoto, { [classes.dxAdjust]: dxAdjust })}
      style={{
        backgroundImage: `url(${url})`,
      }}
    />
  );
}

function load(src: string) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", resolve);
    image.addEventListener("error", reject);
    image.src = src;
  });
}
