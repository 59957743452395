import React, { useMemo, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import {
  Table,
  DoubleFooter,
  SortingState,
  createColumnHelper,
} from "../core/Table";
import { VideoControl } from "../query/VideoControl";
import {
  dec100Format,
  decFormat,
  decFormat2,
  decFormat3,
  makePlusMinus,
  TABLE_EMPTY_VALUE_STR,
} from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { blueLow, darkGrayMid, redHigh } from "../../constants/ColorConstants";
import { normdist } from "../../util/Util";
import { TooltipItem } from "../core/TooltipItem";
import {
  PossessionAggregate,
  PossessionFilters,
} from "../../../shared/routers/PossessionRouter";
import { trpc } from "../../util/tRPC";
import { VideoModal } from "../video/VideoModal";

interface TeamWowSummaryRow {
  split: string;
  lineup: boolean | undefined;
  minutes: number;
  pace: number | null;
  numPoss: number;
  plusMinus: number;
  xPppOff: number | null;
  xPppDef: number | null;
  xPppNet: number | null;
  pppOff: number | null;
  pppDef: number | null;
  pppNet: number | null;
  xPpsOff: number | null;
  xPpsDef: number | null;
  ppsOff: number | null;
  ppsDef: number | null;
  efgOff: number | null;
  efgDef: number | null;
  toPctOff: number | null;
  toPctDef: number | null;
  orbPctOff: number | null;
  orbPctDef: number | null;
  ftaRateOff: number | null;
  ftaRateDef: number | null;
  threePctOff: number | null;
  threePctDef: number | null;
  ftPctOff: number | null;
  ftPctDef: number | null;
}

const columnHelper = createColumnHelper<TeamWowSummaryRow>();

export function TeamWowSummary(props: {
  lineup: PossessionAggregate;
  complement: PossessionAggregate;
  filters: PossessionFilters;
}) {
  const { lineup, complement, filters } = props;

  const [clips, setClips] = useState<{ label: string; url: string }[]>();
  const [sorting, setSorting] = useState<SortingState>();

  const { data: lineupVideos } = trpc.possesion.getPossessionVideos.useQuery({
    filters: { ...filters, lineup: true },
    limit: 5_000,
  });

  const { data: complementVideos } =
    trpc.possesion.getPossessionVideos.useQuery({
      filters: { ...filters, lineup: false },
      limit: 5_000,
    });

  const prepareData = (label: string, data: PossessionAggregate) => {
    return {
      split: label,
      lineup: data.lineup,
      minutes: (data.min || 0) + (data.minDef || 0),
      numPoss: (data.numPos + data.numPosDef) / 2,
      pace:
        data.min && data.minDef
          ? (data.numPos + data.numPosDef) / ((data.min + data.minDef) / 24)
          : null,
      plusMinus: data.pts - data.ptsDef,
      xPppOff: data.xppp,
      xPppDef: data.xpppDef,
      xPppNet:
        data.xppp === null || data.xpppDef === null
          ? null
          : data.xppp - data.xpppDef,
      pppOff: data.ppp,
      pppDef: data.pppDef,
      pppNet:
        data.ppp === null || data.pppDef === null
          ? null
          : data.ppp - data.pppDef,
      xPpsOff: data.xpps,
      xPpsDef: data.xppsDef,
      ppsOff: data.pps,
      ppsDef: data.ppsDef,
      efgOff: data.efg,
      efgDef: data.efgDef,
      toPctOff: data.toPct,
      toPctDef: data.toPctDef,
      orbPctOff: data.orbPct,
      orbPctDef: data.orbPctDef,
      ftaRateOff: data.ftRate,
      ftaRateDef: data.ftRateDef,
      threePctOff: data.pct3,
      threePctDef: data.pct3Def,
      ftPctOff: data.pctFt,
      ftPctDef: data.pctFtDef,
    };
  };

  const data = [
    prepareData("Filtered Results", lineup),
    prepareData("Everything Else", complement),
  ];

  const columns = useMemo(() => {
    if (data.length === 0) return [];

    const lineupOffClips = lineupVideos
      ? lineupVideos
          .filter((v) => v.url && v.offDef === "Off")
          .map((v) => {
            return {
              label: "Filtered Results - Offense",
              url: v.url,
            };
          })
      : [];

    const lineupDefClips = lineupVideos
      ? lineupVideos
          .filter((v) => v.url && v.offDef === "Def")
          .map((v) => {
            return {
              label: "Filtered Results - Defense",
              url: v.url,
            };
          })
      : [];

    const complementOffClips = complementVideos
      ? complementVideos
          .filter((v) => v.url && v.offDef === "Off")
          .map((v) => {
            return {
              label: "Everything Else - Offense",
              url: v.url,
            };
          })
      : [];

    const complementDefClips = complementVideos
      ? complementVideos
          .filter((v) => v.url && v.offDef === "Def")
          .map((v) => {
            return {
              label: "Everything Else - Defense",
              url: v.url,
            };
          })
      : [];

    let g = 0;

    const data0 = data[0];
    const data1 = data[1];

    if (data0 === undefined || data1 === undefined) return [];

    return [
      columnHelper.accessor("split", {
        header: () => "Split",
        meta: { group: g++, textAlign: "left" },
        footer: () => (
          <DoubleFooter primary={"Difference"} secondary={"Confidence"} />
        ),
      }),
      columnHelper.accessor("minutes", {
        header: () => "Min",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: g },
        footer: () => (
          <DoubleFooter
            primary={decFormat(data0.minutes - data1.minutes)}
            secondary={""}
          />
        ),
      }),
      columnHelper.accessor("numPoss", {
        header: () => "# Poss",
        meta: { group: g },
        footer: () => (
          <DoubleFooter
            primary={data0.numPoss - data1.numPoss}
            secondary={""}
          />
        ),
      }),
      columnHelper.accessor("pace", {
        header: () => "Pace",
        meta: { group: g },
        cell: (info) => decFormat(info.getValue()),
        footer: () => (
          <DoubleFooter
            primary={decFormat(
              data0.pace === null || data1.pace === null
                ? null
                : data0.pace - data1.pace
            )}
            secondary={""}
          />
        ),
      }),
      columnHelper.accessor("plusMinus", {
        header: () => "+/-",
        meta: { group: g++ },
        footer: () => (
          <DoubleFooter
            primary={data0.plusMinus - data1.plusMinus}
            secondary={""}
          />
        ),
      }),
      columnHelper.group({
        meta: { group: g },
        id: "xPPP",
        header: () => <span>xPPP</span>,
        columns: [
          columnHelper.accessor("xPppOff", {
            header: () => "OFF",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: 2,
              highlights: Highlights.Max,
              colorDomain: [1.01, 1.13],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(decFormat2)(
                  (data0.xPppOff || 0) - (data1.xPppOff || 0)
                )}
                secondary={confidence(
                  pValue("xPppOff", data0, data1),
                  "xPppOff"
                )}
              />
            ),
          }),
          columnHelper.accessor("xPppDef", {
            header: () => "DEF",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: [1.12, 1.03],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(decFormat2)(
                  (data0.xPppDef || 0) - (data1.xPppDef || 0)
                )}
                secondary={confidence(
                  pValue("xPppDef", data0, data1),
                  "xPppDef"
                )}
              />
            ),
          }),
          columnHelper.accessor("xPppNet", {
            header: () => "Net",
            cell: (info) => (
              <TooltipItem
                tooltip={`${decFormat(
                  pppNetToWins(
                    info.row.original.xPppOff || 0,
                    info.row.original.xPppDef || 0
                  )
                )} wins`}
                noListItem={true}
              >
                {makePlusMinus(decFormat2)(info.getValue())}
              </TooltipItem>
            ),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: [-0.1, 0.1],
            },
            footer: () => (
              <DoubleFooter
                primary={
                  <TooltipItem
                    tooltip={`${makePlusMinus(decFormat)(
                      pppNetToWins(data0.xPppOff || 0, data0.xPppDef || 0) -
                        pppNetToWins(data1.xPppOff || 0, data1.xPppDef || 0)
                    )} wins`}
                    noListItem={true}
                  >
                    {makePlusMinus(decFormat2)(
                      (data0.xPppNet || 0) - (data1.xPppNet || 0)
                    )}
                  </TooltipItem>
                }
                secondary={confidence(
                  pValue("xPppNet", data0, data1),
                  "xPppNet"
                )}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        meta: { group: g },
        id: "PPP",
        header: () => <span>PPP</span>,
        columns: [
          columnHelper.accessor("pppOff", {
            header: () => "OFF",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: [1.01, 1.13],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(decFormat2)(
                  (data0.pppOff || 0) - (data1.pppOff || 0)
                )}
                secondary={confidence(pValue("pppOff", data0, data1), "pppOff")}
              />
            ),
          }),
          columnHelper.accessor("pppDef", {
            header: () => "DEF",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: [1.12, 1.03],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(decFormat2)(
                  (data0.pppDef || 0) - (data1.pppDef || 0)
                )}
                secondary={confidence(pValue("pppDef", data0, data1), "pppDef")}
              />
            ),
          }),
          columnHelper.accessor("pppNet", {
            header: () => "Net",
            cell: (info) => (
              <TooltipItem
                tooltip={`${decFormat(
                  pppNetToWins(
                    info.row.original.pppOff || 0,
                    info.row.original.pppDef || 0
                  )
                )} wins`}
                noListItem={true}
              >
                {makePlusMinus(decFormat2)(info.getValue())}
              </TooltipItem>
            ),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: [-0.1, 0.1],
            },
            footer: () => (
              <DoubleFooter
                primary={
                  <TooltipItem
                    tooltip={`${makePlusMinus(decFormat)(
                      pppNetToWins(data0.pppOff || 0, data0.pppDef || 0) -
                        pppNetToWins(data1.pppOff || 0, data1.pppDef || 0)
                    )} wins`}
                    noListItem={true}
                  >
                    {makePlusMinus(decFormat2)(
                      (data0.pppNet || 0) - (data1.pppNet || 0)
                    )}
                  </TooltipItem>
                }
                secondary={confidence(pValue("pppNet", data0, data1), "pppNet")}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        meta: { group: g },
        id: "xPPS",
        header: () => <span>xPPS</span>,
        columns: [
          columnHelper.accessor("xPpsOff", {
            header: () => "OFF",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: [1.01, 1.13],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(decFormat2)(
                  (data0.xPpsOff || 0) - (data1.xPpsOff || 0)
                )}
                secondary={confidence(
                  pValue("xPpsOff", data0, data1),
                  "xPpsOff"
                )}
              />
            ),
          }),
          columnHelper.accessor("xPpsDef", {
            header: () => "DEF",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: [1.12, 1.03],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(decFormat2)(
                  (data0.xPpsDef || 0) - (data1.xPpsDef || 0)
                )}
                secondary={confidence(
                  pValue("xPpsDef", data0, data1),
                  "xPpsDef"
                )}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        meta: { group: g },
        id: "PPS",
        header: () => <span>PPS</span>,
        columns: [
          columnHelper.accessor("ppsOff", {
            header: () => "OFF",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: [1.01, 1.13],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(decFormat2)(
                  (data0.ppsOff || 0) - (data1.ppsOff || 0)
                )}
                secondary={confidence(pValue("ppsOff", data0, data1), "ppsOff")}
              />
            ),
          }),
          columnHelper.accessor("ppsDef", {
            header: () => "DEF",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: [1.12, 1.03],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(decFormat2)(
                  (data0.ppsDef || 0) - (data1.ppsDef || 0)
                )}
                secondary={confidence(pValue("ppsDef", data0, data1), "ppsDef")}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        meta: { group: g },
        id: "efg",
        header: () => <span>EFG%</span>,
        columns: [
          columnHelper.accessor("efgOff", {
            header: () => "OFF",
            cell: (info) => dec100Format(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: [0.46, 0.53],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(dec100Format)(
                  (data0.efgOff || 0) - (data1.efgOff || 0)
                )}
                secondary={confidence(pValue("efgOff", data0, data1), "efgOff")}
              />
            ),
          }),
          columnHelper.accessor("efgDef", {
            header: () => "DEF",
            cell: (info) => dec100Format(info.getValue()),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: [0.52, 0.47],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(dec100Format)(
                  (data0.efgDef || 0) - (data1.efgDef || 0)
                )}
                secondary={confidence(pValue("efgDef", data0, data1), "efgDef")}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        meta: { group: g },
        id: "to",
        header: () => <span>TO%</span>,
        columns: [
          columnHelper.accessor("toPctOff", {
            header: () => "OFF",
            cell: (info) => dec100Format(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: [0.162, 0.12],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(dec100Format)(
                  (data0.toPctOff || 0) - (data1.toPctOff || 0)
                )}
                secondary={confidence(
                  pValue("toPctOff", data0, data1),
                  "toPctOff"
                )}
              />
            ),
          }),
          columnHelper.accessor("toPctDef", {
            header: () => "DEF",
            cell: (info) => dec100Format(info.getValue()),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: [0.119, 0.159],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(dec100Format)(
                  (data0.toPctDef || 0) - (data1.toPctDef || 0)
                )}
                secondary={confidence(
                  pValue("toPctDef", data0, data1),
                  "toPctDef"
                )}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        meta: { group: g },
        id: "or",
        header: () => <span>OR%</span>,
        columns: [
          columnHelper.accessor("orbPctOff", {
            header: () => "OFF",
            cell: (info) => dec100Format(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: [0.22, 0.29],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(dec100Format)(
                  (data0.orbPctOff || 0) - (data1.orbPctOff || 0)
                )}
                secondary={confidence(
                  pValue("orbPctOff", data0, data1),
                  "orbPctOff"
                )}
              />
            ),
          }),
          columnHelper.accessor("orbPctDef", {
            header: () => "DEF",
            cell: (info) => dec100Format(info.getValue()),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: [0.278, 0.221],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(dec100Format)(
                  (data0.orbPctDef || 0) - (data1.orbPctDef || 0)
                )}
                secondary={confidence(
                  pValue("orbPctDef", data0, data1),
                  "orbPctDef"
                )}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        meta: { group: g },
        id: "fta",
        header: () => <span>FTA Rate</span>,
        columns: [
          columnHelper.accessor("ftaRateOff", {
            header: () => "OFF",
            cell: (info) => dec100Format(info.getValue()),
            meta: {
              group: g++,
              highlights: Highlights.Max,
              colorDomain: [0.188, 0.247],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(dec100Format)(
                  (data0.ftaRateOff || 0) - (data1.ftaRateOff || 0)
                )}
                secondary={confidence(
                  pValue("ftaRateOff", data0, data1),
                  "ftaRateOff"
                )}
              />
            ),
          }),
          columnHelper.accessor("ftaRateDef", {
            header: () => "DEF",
            cell: (info) => dec100Format(info.getValue()),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: [0.249, 0.197],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(dec100Format)(
                  (data0.ftaRateDef || 0) - (data1.ftaRateDef || 0)
                )}
                secondary={confidence(
                  pValue("ftaRateDef", data0, data1),
                  "ftaRateDef"
                )}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        meta: { group: g },
        id: "3p",
        header: () => <span>3P%</span>,
        columns: [
          columnHelper.accessor("threePctOff", {
            header: () => "OFF",
            cell: (info) => dec100Format(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: [0.25, 0.45],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(dec100Format)(
                  (data0.threePctOff || 0) - (data1.threePctOff || 0)
                )}
                secondary={confidence(
                  pValue("threePctOff", data0, data1),
                  "threePctOff"
                )}
              />
            ),
          }),
          columnHelper.accessor("threePctDef", {
            header: () => "DEF",
            cell: (info) => dec100Format(info.getValue()),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: [0.25, 0.45],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(dec100Format)(
                  (data0.threePctDef || 0) - (data1.threePctDef || 0)
                )}
                secondary={confidence(
                  pValue("threePctDef", data0, data1),
                  "threePctDef"
                )}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        meta: { group: g },
        id: "ft",
        header: () => <span>FT%</span>,
        columns: [
          columnHelper.accessor("ftPctOff", {
            header: () => "OFF",
            cell: (info) => dec100Format(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: [0.5, 0.9],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(dec100Format)(
                  (data0.ftPctOff || 0) - (data1.ftPctOff || 0)
                )}
                secondary={confidence(
                  pValue("ftPctOff", data0, data1),
                  "ftPctOff"
                )}
              />
            ),
          }),
          columnHelper.accessor("ftPctDef", {
            header: () => "DEF",
            cell: (info) => dec100Format(info.getValue()),
            meta: {
              group: g++,
              highlights: Highlights.Min,
              colorDomain: [0.5, 0.9],
            },
            footer: () => (
              <DoubleFooter
                primary={makePlusMinus(dec100Format)(
                  (data0.ftPctDef || 0) - (data1.ftPctDef || 0)
                )}
                secondary={confidence(
                  pValue("ftPctDef", data0, data1),
                  "ftPctDef"
                )}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        meta: { group: g },
        id: "video",
        header: () => <span>Video</span>,
        columns: [
          columnHelper.display({
            id: "offVideo",
            header: () => "OFF",
            cell: (info) => (
              <VideoControl
                data={null}
                onVideo={() =>
                  setClips(
                    info.row.original.lineup
                      ? lineupOffClips
                      : complementOffClips
                  )
                }
              />
            ),
            footer: () => <DoubleFooter primary={""} secondary={""} />,
            meta: { group: g },
          }),
          columnHelper.display({
            id: "defVideo",
            header: () => "DEF",
            cell: (info) => (
              <VideoControl
                data={null}
                onVideo={() =>
                  setClips(
                    info.row.original.lineup
                      ? lineupDefClips
                      : complementDefClips
                  )
                }
              />
            ),
            footer: () => <DoubleFooter primary={""} secondary={""} />,
            meta: { group: g++ },
          }),
        ],
      }),
    ];
  }, [data, lineupVideos, complementVideos]);

  return (
    <>
      <Table
        data={data}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        showColorOnHover={true}
        autoWidth={true}
        showRowIndex={false}
      />
      <div style={{ display: "flex", gap: 12 }}>
        <div>
          <FaChevronUp style={{ color: redHigh }} />
          {" Better "}
        </div>
        <div>
          <FaChevronDown style={{ color: blueLow }} />
          {" Worse "}
        </div>
        <div>
          <span
            style={{
              color: darkGrayMid,
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            ≈
          </span>
          {" Not significantly different "}
        </div>
      </div>
      {clips && (
        <VideoModal
          title={"With or Without Clips"}
          clips={clips}
          show={clips && clips.length > 0}
          handleClose={() => setClips(undefined)}
          upDownClipSkip={true}
          showSynergyEditor={false}
        />
      )}
    </>
  );
}

function pppNetToWins(ppp: number, pppDef: number) {
  return (82 * Math.pow(ppp, 14)) / (Math.pow(ppp, 14) + Math.pow(pppDef, 14));
}

function Better() {
  return <FaChevronUp style={{ color: redHigh, width: "100%" }} />;
}

function Worse() {
  return <FaChevronDown style={{ color: blueLow, width: "100%" }} />;
}

function NoDiff() {
  return (
    <div
      style={{
        color: darkGrayMid,
        fontWeight: 700,
        textAlign: "center",
        width: "100%",
      }}
    >
      ≈
    </div>
  );
}

function pValue(
  stat: keyof TeamWowSummaryRow,
  results: TeamWowSummaryRow,
  complement: TeamWowSummaryRow
) {
  const values = getValuesForStat(stat);

  if (!values) {
    return null;
  }

  const multiplier =
    stat === "xPppNet" ||
    stat === "xPppOff" ||
    stat === "xPppDef" ||
    stat === "pppNet" ||
    stat === "pppOff" ||
    stat === "pppDef" ||
    stat === "xPpsOff" ||
    stat === "xPpsDef" ||
    stat === "ppsOff" ||
    stat === "ppsDef"
      ? 1
      : 100;

  const resultStat = multiplier * (results[stat] as number);
  const complementStat = multiplier * (complement[stat] as number);

  const possResults = results.numPoss;
  const possComplement = complement.numPoss;
  let sdResults =
    values.sdMultiplier *
    Math.exp(values.intercept + Math.log(possResults) * values.multiplier);
  let sdComplement =
    values.sdMultiplier *
    Math.exp(values.intercept + Math.log(possComplement) * values.multiplier);

  // As per David's request.
  if (stat === "pppNet" || stat === "xPppNet") {
    sdResults = Math.sqrt(2 * Math.pow(sdResults, 2));
    sdComplement = Math.sqrt(2 * Math.pow(sdComplement, 2));
  }

  const sdDiff = Math.sqrt(Math.pow(sdResults, 2) + Math.pow(sdComplement, 2));
  const zScore = (resultStat - complementStat) / sdDiff;
  const p = normdist(zScore, 0, 1, true);
  // Can be NaN on net PPP row if no data.
  return isNaN(p) ? null : p;
}

function getValuesForStat(stat: keyof TeamWowSummaryRow) {
  // Values from David Sparks.
  switch (stat) {
    case "xPppNet":
    case "xPppOff":
    case "xPppDef":
    case "pppNet":
    case "pppOff":
    case "pppDef":
    case "xPpsOff":
    case "xPpsDef":
    case "ppsOff":
    case "ppsDef":
      return {
        leagueMean: 1.0623995,
        intercept: 0.117393263,
        multiplier: -0.4982923,
        sdMultiplier: 1,
      };
    case "efgOff":
    case "efgDef":
      return {
        leagueMean: 0.4991407,
        intercept: -0.480474102,
        multiplier: -0.5043603,
        sdMultiplier: 100,
      };
    case "toPctOff":
    case "toPctDef":
      return {
        leagueMean: 0.1537848,
        intercept: -1.002030351,
        multiplier: -0.5026037,
        sdMultiplier: 100,
      };
    case "orbPctOff":
    case "orbPctDef":
      return {
        leagueMean: 0.2886453,
        intercept: -0.429238957,
        multiplier: -0.5012065,
        sdMultiplier: 100,
      };
    case "ftaRateOff":
    case "ftaRateDef":
      return {
        leagueMean: 0.2406939,
        intercept: -0.484091689,
        multiplier: -0.4931078,
        sdMultiplier: 100,
      };
    case "threePctOff":
    case "threePctDef":
      return {
        leagueMean: 0.3587045,
        intercept: 0.072903706,
        multiplier: -0.5113177,
        sdMultiplier: 100,
      };
    case "ftPctOff":
    case "ftPctDef":
      return {
        leagueMean: 0.7510001,
        intercept: -0.008767394,
        multiplier: -0.5116842,
        sdMultiplier: 100,
      };
  }
  return;
}

const pLower = 0.05;
const pUpper = 0.95;

function confidence(p: number | null, key: string) {
  if (p === null) return TABLE_EMPTY_VALUE_STR;
  const better = isBetter(p, key);
  const worse = isWorse(p, key);
  const title = `p = ${decFormat3(p)}`;
  if (better)
    return (
      <div title={title}>
        <Better />
      </div>
    );
  if (worse)
    return (
      <div title={title}>
        <Worse />
      </div>
    );
  return (
    <div title={title}>
      <NoDiff />
    </div>
  );
}

function isBetter(p: number, stat: string) {
  switch (stat) {
    case "xPppDef":
    case "pppDef":
    case "xPpsDef":
    case "ppsDef":
    case "efgDef":
    case "toPctOff":
    case "orbPctDef":
    case "ftaRateDef":
    case "threePctDef":
    case "ftPctDef":
      return p <= pLower;
    default:
      return p >= pUpper;
  }
}

function isWorse(p: number, stat: string) {
  switch (stat) {
    case "xPppDef":
    case "pppDef":
    case "xPpsDef":
    case "ppsDef":
    case "efgDef":
    case "toPctOff":
    case "orbPctDef":
    case "ftaRateDef":
    case "threePctDef":
    case "ftPctDef":
      return p >= pUpper;
    default:
      return p <= pLower;
  }
}
