import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export function Footer() {
  return (
    <footer
      style={{
        color: "rgba(255,255,255,.4)",
        textAlign: "left",
        paddingTop: "1em",
        marginTop: "1em",
        borderTop: "1px solid rgba(255,255,255,.1)",
        fontSize: 14,
      }}
    >
      <Container fluid={true}>
        <Row>
          <Col xs={12}>
            <p style={{ float: "right" }}>
              <i>In Red we trust</i>
            </p>
            <p>BIA: Basketball Intelligence Agency</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
