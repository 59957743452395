import React, { useMemo } from "react";
import moment from "moment";
import { ParentSize } from "@visx/responsive";
import { Button, Col, Row, Form } from "react-bootstrap";
import {
  useQueryParams,
  withDefault,
  StringParam,
  QueryParamConfig,
  NumberParam,
  ArrayParam,
} from "use-query-params";

import { SkillPlayerSelector } from "../components/skill/SkillPlayerSelector";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Table, createColumnHelper } from "../components/core/Table";
import { LineChart, Line } from "../components/chart/LineChart";
import { trpc } from "../util/tRPC";
import {
  SkillValuesOverTime,
  LeagueSkillValue,
  ShotCountByDate,
  AggShotData,
} from "../../shared/routers/SkillRouter";
import { lineChartColorArray } from "../constants/ColorConstants";
import {
  decFormat,
  decFormat2,
  makePlusMinus,
  percentileFormat,
  seasonString,
} from "../util/Format";
import { NbaDates } from "../../shared/NbaDates";
import AppContext from "../../shared/AppContext";
import { groupBy, reduceArrayToObject } from "../../shared/util/Collections";
import { SwarmChart } from "../components/chart/SwarmChart";
import { colorFromPosition } from "../util/Colors";
import { lineChartColors } from "../constants/ColorConstants";
import { PlayerTableCell } from "../components/core/TableCell";
import { Positions } from "../constants/AppConstants";
import { useWindowSize } from "../util/Hooks";
import { sumFromField, weightedAverage } from "../util/Util";
import { Highlights } from "../constants/AppConstants";

type SkillData = SkillValuesOverTime & AggShotData;

export function SkillDevelopmentPage() {
  const [queryParams, setQueryParams] = useQueryParams({
    playerIds: withDefault(ArrayParam, [null]),
    xAxis: withDefault(StringParam, "age"),
    timeFrame: withDefault(NumberParam, 0),
    category: withDefault(StringParam, "skillOffense"),
    metric: withDefault(StringParam, getDefaultSkill()) as QueryParamConfig<
      keyof SkillData
    >,
    reference: withDefault(StringParam, "all"),
  });
  const { xAxis, timeFrame, category, metric, reference } = queryParams;

  // If we are in the preseason, we want to use last season, else use current.
  const adjustment = AppContext.inPreseason ? 1 : 0;

  const dates =
    NbaDates[parseInt(AppContext.currentSeason) - (timeFrame - 1 + adjustment)];

  // Target date reprseents the first datapoint to be included.
  // If we are in the preseason subtract 1 from current season.
  // If timeframe is 0, we want all data and this is ignored so just set to 0.
  const targetDate =
    timeFrame === 0 || dates === undefined ? 0 : moment(dates.preseason.start);

  const playerIds = queryParams.playerIds || [];

  const queries = trpc.useQueries((t) =>
    playerIds
      .filter((pi) => pi !== null)
      .map((p) =>
        t.skill.getSkillValuesOverTime({
          playerId: p as string,
        })
      )
  );

  // Get shot data that has not yet been aggregated. We will aggregate it on the
  // front end into season and career data.
  const shotCountQueries = trpc.useQueries((t) =>
    playerIds
      .filter((pi) => pi !== null)
      .map((p) =>
        t.skill.getShotCountsByDate({
          playerId: parseInt(p as string),
        })
      )
  );

  // Get shot data that has been aggregated into moving averages (for graphs).
  const aggShotQueries = trpc.useQueries((t) =>
    playerIds
      .filter((pi) => pi !== null)
      .map((p) =>
        t.skill.getAggShotData({
          playerId: parseInt(p as string),
        })
      )
  );

  const aggShotData: Record<
    string,
    Record<string, AggShotData>
  > = Object.fromEntries(
    aggShotQueries
      .filter((q) => q.data && q.data.length)
      .map((q) => {
        const firstRow = q.data && q.data[0];
        if (firstRow) {
          const playerId = firstRow.playerId;
          return [
            playerId.toString(),
            reduceArrayToObject(q.data, (d) => d.gameDate),
          ];
        }
        return [];
      })
  );

  const shotData: Record<string, ShotCountByDate[]> = Object.fromEntries(
    shotCountQueries
      .filter((q) => q.data && q.data.length)
      .map((q) => {
        const firstRow = q.data && q.data[0];
        if (firstRow) {
          const playerId = firstRow.playerId;
          return [playerId.toString(), q.data];
        }
        return [];
      })
  );

  const skillData: Record<string, SkillData[]> = useMemo(
    () =>
      Object.fromEntries(
        queries
          .filter((q) => q.data && q.data.length)
          .map((q) => {
            const firstRow = q.data && q.data[0];
            if (firstRow) {
              const playerId = firstRow.playerId;
              const filteredData =
                timeFrame === 0
                  ? q.data
                  : q.data.filter((d) => moment(d.gameDate) >= targetDate);

              // Combine the shooting data with the skill data.
              const combinedData = filteredData.map((d) => {
                const aggShotsAtDate = (aggShotData[playerId.toString()] || {})[
                  d.gameDate
                ];
                return {
                  skill: d,
                  aggShots: aggShotsAtDate,
                };
              });

              // Keep track of the last game date with data we saw, start with
              // just an empty data point.
              let lastData = emptyAggShots(
                playerId,
                combinedData[0]!.skill.gameDate
              );
              const fullData = combinedData.map((val) => {
                const shotDataForDate = val.aggShots || lastData;
                // If we have shot data for this date, update the last seen data
                // point.
                if (shotDataForDate) {
                  lastData = shotDataForDate;
                }
                const aggShotData = val.aggShots || lastData;
                // Agg shot data has a bogus gameDate so put it in the object
                // first so the correct date, from the skill data, is used.
                return { ...aggShotData, ...val.skill };
              });
              return [playerId.toString(), fullData];
            }
            return [];
          })
      ),
    [aggShotData, queries, targetDate, timeFrame]
  );

  const { data: percentiles } = trpc.skill.getSkillPercentiles.useQuery({
    metric: metric,
    position: reference,
  });

  const { data: allPlayers } = trpc.skill.getPlayersWithSkillValues.useQuery();

  const { data: leagueSkillValues } =
    trpc.skill.getLeagueValuesForSkill.useQuery({
      skill: metric,
    });

  const getSeasonFromDate = (x: number) => {
    const date = moment(x);
    const season = Object.entries(NbaDates).find(([, dates]) => {
      return (
        date >= moment(dates.preseason.start) &&
        date <= moment(dates.postseason.end)
      );
    });
    return season ? season[0] : "";
  };

  const xTickFormat = (x: number) => {
    if (xAxis === "date") return moment(x).format("MM-DD-yy");
    else if (xAxis === "age") return decFormat(x);
    else return Math.round(x).toString();
  };
  const xAxisLabel = () => {
    if (xAxis === "date") return "Date";
    else if (xAxis === "age") return "Age";
    else return "Game";
  };

  const referenceLines =
    percentiles && playerIds.some((p) => p !== null)
      ? [
          { value: percentiles.value_05, label: "5th" },
          { value: percentiles.value_25, label: "25th" },
          { value: percentiles.value_50, label: "50th" },
          { value: percentiles.value_75, label: "75th" },
          { value: percentiles.value_95, label: "95th" },
        ]
      : [];

  const lines = useMemo(() => {
    const yValFunc = (d: SkillData) => {
      const val = d[metric];
      if (typeof val === "number") return val;
      return null;
    };

    const xValFunc = (d: SkillData) => {
      if (xAxis === "date") return moment(d.gameDate).valueOf();
      else if (xAxis === "age") return d.age;
      else return d.gameNumber;
    };

    const lines: Line[] = [];

    if (!skillData || !queryParams.playerIds) return lines;

    for (let i = 0; i < queryParams.playerIds.length; i++) {
      const pi = queryParams.playerIds[i];
      if (pi !== null && pi !== undefined) {
        const skills = skillData[pi];
        if (skills && skills.length) {
          const skill1 = skills[0];
          const skillsByYears = groupBy(skillData[pi] || [], (s) =>
            s.season.toString()
          );
          const years = Object.keys(skillsByYears).sort();
          lines.push({
            color: lineChartColorArray[i] || "black",
            segments: years.map((y) => {
              const dataForYear = skillsByYears[y];
              return (dataForYear || [])
                .map((d) => {
                  return { x: xValFunc(d), y: yValFunc(d) };
                })
                .filter((d) => d.y !== null) as { x: number; y: number }[];
            }),
            label: skill1 ? skill1.name : "",
          });
        }
      }
    }
    return lines;
  }, [skillData, metric, xAxis, queryParams.playerIds]);

  const cat = SKILL_OBJECT[category];

  const metricParentObj = cat
    ? Object.values(cat.subcategories).find((sc) =>
        sc.options.some((o) => o.value === metric)
      )
    : undefined;

  const metricObj = metricParentObj
    ? metricParentObj.options.find((o) => o.value === metric)
    : undefined;

  const headerText = [metricParentObj, metricObj]
    .map((o) => (o ? o.label : ""))
    .join(" • ");

  const seasonsForData = useMemo(() => {
    if (!skillData) return new Set();
    const seasons = Object.values(skillData)
      .flat()
      .map((d) => d.season.toString());
    return new Set(seasons);
  }, [skillData]);

  const seasonMidpoints =
    xAxis !== "date"
      ? undefined
      : Object.entries(NbaDates)
          .filter(([season]) => {
            return (
              seasonsForData.has(season) &&
              parseInt(season) <= parseInt(AppContext.currentSeason)
            );
          })
          .map(([, d]) => {
            const a = d.postseason.end;
            const b = d.preseason.start;
            const diff = Math.abs(moment(a).diff(b));
            return (
              diff / 2 + Math.min(moment(a).valueOf(), moment(b).valueOf())
            );
          });

  if (!allPlayers) return null;

  const headerComponent = (
    <div>
      <h1>{headerText}</h1>
      <div>{metricObj && metricObj.desc}</div>
    </div>
  );

  const categories = Object.keys(SKILL_OBJECT).map((k) => {
    const skill = SKILL_OBJECT[k];
    return {
      value: k,
      label: skill ? skill.label : "",
    };
  });

  const selectedCategory = SKILL_OBJECT[category];
  const subCategories = selectedCategory ? selectedCategory.subcategories : {};

  return (
    <Page header={{ component: headerComponent }} title="Skill Development">
      <div>
        <Panel header="Select Players">
          <Row>
            <Col>
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  alignItems: "end",
                  flexWrap: "wrap",
                }}
              >
                <div>
                  <Form.Label>Category</Form.Label>
                  <Form.Select
                    value={category}
                    style={{ width: "auto" }}
                    onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                      const newCategory = evt.target.value;
                      const newCat = SKILL_OBJECT[newCategory];
                      if (!newCat) return;

                      const newSubCats = Object.values(newCat.subcategories);

                      const newSubCat1 = newSubCats[0];
                      if (!newSubCat1) return;
                      const newOption1 = newSubCat1.options[0];
                      if (!newOption1) return;
                      const firstMetric = newOption1.value;

                      setQueryParams({
                        category: newCategory,
                        metric: firstMetric,
                      });
                    }}
                  >
                    {categories.map((c) => (
                      <option key={c.value} value={c.value}>
                        {c.label}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div>
                  <Form.Label>Skill</Form.Label>
                  <Form.Select
                    value={metric}
                    style={{ width: "auto" }}
                    onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                      setQueryParams({
                        metric: evt.target.value as keyof SkillValuesOverTime,
                      });
                    }}
                  >
                    {Object.keys(subCategories).map((k) => {
                      const subCategory = subCategories[k] || {
                        label: "",
                        options: [],
                      };
                      return (
                        <optgroup key={k} label={subCategory.label}>
                          {subCategory.options.map((o) => (
                            <option key={o.value} value={o.value}>
                              {o.label}
                            </option>
                          ))}
                        </optgroup>
                      );
                    })}
                  </Form.Select>
                </div>
                <div>
                  <Form.Label>X Axis</Form.Label>
                  <Form.Select
                    value={xAxis}
                    style={{ width: "auto" }}
                    onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                      setQueryParams({ xAxis: evt.target.value });
                    }}
                  >
                    <option value={"date"}>By Date</option>
                    <option value={"game"}>By Game</option>
                    <option value={"age"}>By Age</option>
                  </Form.Select>
                </div>
                <div>
                  <Form.Label>Time Frame</Form.Label>
                  <Form.Select
                    value={timeFrame}
                    style={{ width: "auto" }}
                    onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                      setQueryParams({
                        timeFrame: parseInt(evt.target.value) || 0,
                      });
                    }}
                  >
                    <option value={0}>All Time</option>
                    <option value={1}>Current Season</option>
                    <option value={2}>Last Two Seasons</option>
                    <option value={3}>Last Three Seasons</option>
                    <option value={4}>Last Four Seasons</option>
                    <option value={5}>Last Five Seasons</option>
                  </Form.Select>
                </div>
                <div>
                  <Form.Label>Compare To</Form.Label>
                  <Form.Select
                    value={reference}
                    style={{ width: "auto" }}
                    onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                      setQueryParams({ reference: evt.target.value });
                    }}
                  >
                    <option value={"all"}>All Players</option>
                    <option value={""}>No Reference Lines</option>
                    <optgroup label="-----------"></optgroup>
                    <option value={"guard"}>Guards</option>
                    <option value={"wing"}>Wings</option>
                    <option value={"big"}>Bigs</option>
                    <optgroup label="-----------"></optgroup>
                    <option value={"1"}>PGs</option>
                    <option value={"2"}>SGs</option>
                    <option value={"3"}>SFs</option>
                    <option value={"4"}>PFs</option>
                    <option value={"5"}>Cs</option>
                    <optgroup label="-----------"></optgroup>
                    <option value={""}>No Reference Lines</option>
                  </Form.Select>
                </div>
                {playerIds.length < lineChartColorArray.length && (
                  <Col>
                    <Button
                      onClick={() => {
                        const newPlayerIds = [...playerIds];
                        newPlayerIds.push(null);
                        setQueryParams({ playerIds: newPlayerIds });
                      }}
                    >
                      Add Player
                    </Button>
                  </Col>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                {playerIds.map((p, i) => (
                  // Max width comes from the 184 width of input + 8px gap on
                  // left and right.
                  <Col key={i} style={{ marginBottom: 8, maxWidth: 200 }}>
                    <SkillPlayerSelector
                      label={`Player ${i + 1}`}
                      color={lineChartColorArray[i] || "black"}
                      players={allPlayers}
                      selectedPlayerId={p}
                      setSelectedPlayerId={(val: string | null) => {
                        const newPlayerIds = [...playerIds];
                        newPlayerIds[i] = val;
                        setQueryParams({ playerIds: newPlayerIds });
                      }}
                      showDelete={playerIds.length > 1}
                      onDeleteClick={() => {
                        const newPlayerIds = [...playerIds];
                        newPlayerIds.splice(i, 1);
                        setQueryParams({ playerIds: newPlayerIds });
                      }}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col>
              {lines.length > 0 && (
                <div style={{ position: "relative" }}>
                  <ParentSize>
                    {({ width }) => (
                      <LineChart
                        margin={{ right: 24 }}
                        height={Math.min(width, 440)}
                        width={width}
                        lines={lines}
                        showLegend={true}
                        numXTicks={xAxis === "date" ? undefined : 5}
                        xTicks={seasonMidpoints}
                        xTickFormat={(x) =>
                          xAxis === "date"
                            ? getSeasonFromDate(x as number)
                            : xTickFormat(x as number)
                        }
                        referenceLines={reference === "" ? [] : referenceLines}
                        yTickPadding={0.05}
                        xAxisLabel={xAxisLabel()}
                        dragTooltip={(dragTooltipData) => {
                          const xVal1 = dragTooltipData.xVal;
                          const xVal2 = dragTooltipData.xVal2;
                          const data1 = dragTooltipData.data;
                          const data2 = dragTooltipData.data2;

                          const colors = [
                            ...new Set(
                              [data1, data2].flatMap((d) =>
                                d.map((dd) => dd.color)
                              )
                            ),
                          ];

                          const rows = colors
                            .map((color) => {
                              const val1 = data1.find(
                                (d) => d.color === color
                              )?.y;
                              const val2 = data2.find(
                                (d) => d.color === color
                              )?.y;
                              const label =
                                data1.find((d) => d.color === color)?.label ||
                                data2.find((d) => d.color === color)?.label ||
                                "";
                              return {
                                label,
                                color,
                                val1,
                                val2,
                                diff:
                                  val1 !== undefined && val2 !== undefined
                                    ? val2 - val1
                                    : undefined,
                              };
                            })
                            .sort((a, b) => {
                              const aDiff = a.diff;
                              const bDiff = b.diff;
                              if (aDiff === bDiff) return 0;
                              else if (aDiff === undefined) return 1;
                              else if (bDiff === undefined) return -1;
                              else return bDiff - aDiff;
                            });

                          return (
                            <div>
                              <b>{`${xAxisLabel()}: ${xTickFormat(
                                xVal1
                              )} - ${xTickFormat(xVal2)}`}</b>
                              {rows.map((row, i) => {
                                return (
                                  <div
                                    key={i}
                                    style={{
                                      display: "flex",
                                      gap: 8,
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        background: row.color,
                                        width: 10,
                                        height: 10,
                                      }}
                                    ></div>
                                    <div>
                                      {row.val1 === undefined
                                        ? "--"
                                        : decFormat2(row.val1)}
                                    </div>
                                    <div>→</div>
                                    <div>
                                      {row.val2 === undefined
                                        ? "--"
                                        : decFormat2(row.val2)}
                                    </div>
                                    {row.diff !== undefined && (
                                      <b
                                        style={{
                                          color:
                                            row.diff < 0
                                              ? lineChartColors.red
                                              : lineChartColors.green,
                                        }}
                                      >
                                        {makePlusMinus(decFormat2)(row.diff)}
                                      </b>
                                    )}
                                    <div>{row.label}</div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        }}
                        tooltip={(tooltipData) => {
                          const data1 = tooltipData.data[0];

                          if (!data1) return null;

                          const xVal = data1.x;

                          return (
                            <div>
                              <b>{`${xAxisLabel()}: ${xTickFormat(xVal)}`}</b>
                              {tooltipData.data
                                .sort((a, b) => b.y - a.y)
                                .map((d, i) => {
                                  return (
                                    <div
                                      key={i}
                                      style={{
                                        display: "flex",
                                        gap: 8,
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          background: d.color,
                                          width: 10,
                                          height: 10,
                                        }}
                                      ></div>
                                      <div>{decFormat2(d.y)}</div>
                                      <div>{d.label}</div>
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        }}
                      />
                    )}
                  </ParentSize>
                </div>
              )}
            </Col>
          </Row>
        </Panel>
        {["rimFinishingStats", "shootingStats"].includes(category) ? (
          <CareerComparisonPanel
            playerIds={playerIds}
            shotData={shotData}
            metric={metric as Extract<keyof AggShotData, number>}
            leagueDataForMetric={leagueSkillValues || []}
          />
        ) : (
          <LeagueComparisonPanel
            data={leagueSkillValues || []}
            metric={metric}
            percentiles={percentiles}
            playerIds={playerIds}
          />
        )}
      </div>
    </Page>
  );
}

function CareerComparisonPanel(props: {
  playerIds: (string | null)[];
  shotData: Record<string, ShotCountByDate[]>;
  metric: Extract<keyof AggShotData, number>;
  leagueDataForMetric: LeagueSkillValue[];
}) {
  const { shotData, metric, playerIds, leagueDataForMetric } = props;

  const nonNullPlayerIds = playerIds.filter((p) => !!p) as string[];

  const byPlayerBySeason = Object.fromEntries(
    Object.entries(shotData).map(([playerId, data]) => {
      const dataBySeason = groupBy(data, (d) => d.season.toString());
      return [playerId, dataBySeason];
    })
  );

  const playerNames = nonNullPlayerIds.map((p) => {
    const bySeason = byPlayerBySeason[p];
    if (!bySeason) return "Unknown";
    const firstDataArr = Object.values(bySeason)[0];
    if (!firstDataArr) return "Unknown";
    const firstDataPoint = firstDataArr[0];
    if (!firstDataPoint) return "Unknown";
    return firstDataPoint.name;
  });

  const seasons = [
    ...new Set(
      Object.values(byPlayerBySeason)
        .map((d) => Object.keys(d))
        .flat()
    ),
  ].sort();

  const metricVals = leagueDataForMetric.map((d) => d.value).sort();

  const data = [...seasons, "Career"].map((season) => {
    const pd = nonNullPlayerIds.map((playerId) => {
      if (season === "Career") {
        return calculateAverage(
          Object.values(byPlayerBySeason[playerId] || {}).flat()
        )[metric];
      }
      const pds = calculateAverage(
        (byPlayerBySeason[playerId] || {})[season] || []
      );
      return pds[metric];
    });
    return {
      season,
      p1: pd[0] === undefined ? null : pd[0],
      p1Pct: pd[0] == null ? null : getPercentile(pd[0], metricVals),
      p2: pd[1] === undefined ? null : pd[1],
      p2Pct: pd[1] == null ? null : getPercentile(pd[1], metricVals),
      p3: pd[2] === undefined ? null : pd[2],
      p3Pct: pd[2] == null ? null : getPercentile(pd[2], metricVals),
      p4: pd[3] === undefined ? null : pd[3],
      p4Pct: pd[3] == null ? null : getPercentile(pd[3], metricVals),
      p5: pd[4] === undefined ? null : pd[4],
      p5Pct: pd[4] == null ? null : getPercentile(pd[4], metricVals),
      p6: pd[5] === undefined ? null : pd[5],
      p6Pct: pd[5] == null ? null : getPercentile(pd[5], metricVals),
    };
  });

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<{
      season: string;
      p1: number | null;
      p1Pct: number | null;
      p2: number | null;
      p2Pct: number | null;
      p3: number | null;
      p3Pct: number | null;
      p4: number | null;
      p4Pct: number | null;
      p5: number | null;
      p5Pct: number | null;
      p6: number | null;
      p6Pct: number | null;
    }>();

    let g = 0;

    const footer = data.find((d) => d.season === "Career")!;

    return [
      columnHelper.accessor("season", {
        header: () => "Season",
        cell: (info) => {
          return seasonString(info.getValue());
        },
        footer: () => "Career",
        meta: { highlights: Highlights.Max, group: g++ },
      }),
      columnHelper.accessor("p1", {
        header: () => playerNames[0],
        cell: (info) => decFormat2(info.getValue()),
        footer: () => decFormat2(footer.p1),
        meta: { highlights: Highlights.Max, group: g },
      }),
      columnHelper.accessor("p1Pct", {
        header: () => "",
        cell: (info) => percentileFormat(info.getValue()),
        footer: () => percentileFormat(footer.p1Pct),
        meta: { highlights: Highlights.Max, group: g++ },
      }),
      columnHelper.accessor("p2", {
        header: () => playerNames[1],
        cell: (info) => decFormat2(info.getValue()),
        footer: () => decFormat2(footer.p2),
        meta: { highlights: Highlights.Max, group: g },
      }),
      columnHelper.accessor("p2Pct", {
        header: () => "",
        cell: (info) => percentileFormat(info.getValue()),
        footer: () => percentileFormat(footer.p2Pct),
        meta: { highlights: Highlights.Max, group: g++ },
      }),
      columnHelper.accessor("p3", {
        header: () => playerNames[2],
        cell: (info) => decFormat2(info.getValue()),
        footer: () => decFormat2(footer.p3),
        meta: { highlights: Highlights.Max, group: g },
      }),
      columnHelper.accessor("p3Pct", {
        header: () => "",
        cell: (info) => percentileFormat(info.getValue()),
        footer: () => percentileFormat(footer.p3Pct),
        meta: { highlights: Highlights.Max, group: g++ },
      }),
      columnHelper.accessor("p4", {
        header: () => playerNames[3],
        cell: (info) => decFormat2(info.getValue()),
        footer: () => decFormat2(footer.p4),
        meta: { highlights: Highlights.Max, group: g },
      }),
      columnHelper.accessor("p4Pct", {
        header: () => "",
        cell: (info) => percentileFormat(info.getValue()),
        footer: () => percentileFormat(footer.p4Pct),
        meta: { highlights: Highlights.Max, group: g++ },
      }),
      columnHelper.accessor("p5", {
        header: () => playerNames[4],
        cell: (info) => decFormat2(info.getValue()),
        footer: () => decFormat2(footer.p5),
        meta: { highlights: Highlights.Max, group: g },
      }),
      columnHelper.accessor("p5Pct", {
        header: () => "",
        cell: (info) => percentileFormat(info.getValue()),
        footer: () => percentileFormat(footer.p5Pct),
        meta: { highlights: Highlights.Max, group: g++ },
      }),
      columnHelper.accessor("p6", {
        header: () => playerNames[5],
        cell: (info) => decFormat2(info.getValue()),
        footer: () => decFormat2(footer.p6),
        meta: { highlights: Highlights.Max, group: g },
      }),
      columnHelper.accessor("p6Pct", {
        header: () => "",
        cell: (info) => percentileFormat(info.getValue()),
        footer: () => percentileFormat(footer.p6Pct),
        meta: { highlights: Highlights.Max, group: g++ },
      }),
    ];
  }, [data, playerNames]);

  const hiddenColumns = {
    p1: nonNullPlayerIds.length > 0,
    p1Pct: nonNullPlayerIds.length > 0,
    p2: nonNullPlayerIds.length > 1,
    p2Pct: nonNullPlayerIds.length > 1,
    p3: nonNullPlayerIds.length > 2,
    p3Pct: nonNullPlayerIds.length > 2,
    p4: nonNullPlayerIds.length > 3,
    p4Pct: nonNullPlayerIds.length > 3,
    p5: nonNullPlayerIds.length > 4,
    p5Pct: nonNullPlayerIds.length > 4,
    p6: nonNullPlayerIds.length > 5,
    p6Pct: nonNullPlayerIds.length > 5,
  };

  return (
    <Panel header="Career Comparison">
      <Table
        data={data.filter((d) => d.season !== "Career")}
        columns={columns}
        hiddenColumns={hiddenColumns}
        autoWidth={true}
      />
    </Panel>
  );
}

const leagueComparisonColumnHelper = createColumnHelper<LeagueSkillValue>();

function LeagueComparisonPanel(props: {
  data: LeagueSkillValue[];
  metric: string;
  playerIds: (string | null)[];
  percentiles?: {
    value_05: number;
    value_25: number;
    value_50: number;
    value_75: number;
    value_95: number;
  };
}) {
  const { data, metric, playerIds, percentiles } = props;

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;

  const lowerIsBetter = isLowerBetter(metric);

  const tooltipFn = (data: {
    value: number;
    stdErr?: number;
    label: string;
  }) => {
    return (
      <div>
        <b>{data.label}</b>
        <div>{decFormat2(data.value)}</div>
      </div>
    );
  };

  const dataByPosition = useMemo(() => {
    if (data === undefined) return {};
    return groupBy(data, (d) => d.position.toString());
  }, [data]);

  const columns = useMemo(() => {
    const selectedPlayerSet = new Set<string>(
      playerIds.filter((p) => p !== null) as string[]
    );

    const colorDomain = percentiles
      ? [
          lowerIsBetter ? percentiles.value_95 : percentiles.value_05,
          lowerIsBetter ? percentiles.value_05 : percentiles.value_95,
        ]
      : undefined;

    return [
      leagueComparisonColumnHelper.accessor("name", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            bold={selectedPlayerSet.has(info.row.original.playerId.toString())}
            id={info.row.original.playerId}
            name={info.getValue()}
          />
        ),
      }),
      leagueComparisonColumnHelper.accessor("value", {
        header: () => "Value",
        cell: (info) => decFormat2(info.getValue()),
        meta: { colorDomain, heatmap: true },
      }),
    ];
  }, [lowerIsBetter, percentiles, playerIds]);

  return (
    <Panel header="League Comparison">
      {data && (
        <Row>
          <Col>
            {lowerIsBetter && (
              <p>
                {`Lower numbers indicate increased ability for this ${
                  lowerIsBetterOffMetrics.has(metric)
                    ? "offensive"
                    : "defensive"
                } skill.`}
              </p>
            )}
            <ParentSize parentSizeStyles={{ width: "100%" }}>
              {({ width }) =>
                width > 0 && (
                  <SwarmChart
                    height={Math.min(width / 2, 350)}
                    width={width}
                    label={""}
                    format={decFormat2}
                    data={data
                      .sort((a, b) => a.position - b.position)
                      .map((d) => {
                        return {
                          label: d.name,
                          value: d.value,
                          color: colorFromPosition(d.position),
                          stdErr: 0,
                        };
                      })}
                    tooltip={tooltipFn}
                  />
                )
              }
            </ParentSize>
          </Col>
        </Row>
      )}
      {Object.keys(dataByPosition).length > 0 && (
        <Row>
          {["1", "2", "3", "4", "5"].map((pos) => {
            return (
              <Col key={pos}>
                <b>{Positions[parseInt(pos)]}</b>
                <Table
                  data={(dataByPosition[pos] || [])
                    .sort(
                      (a, b) => (b.value - a.value) * (lowerIsBetter ? -1 : 1)
                    )
                    // Only show top 10 on mobile
                    .slice(0, isMobile ? 10 : undefined)}
                  columns={columns}
                  autoWidth={true}
                />
              </Col>
            );
          })}
        </Row>
      )}
    </Panel>
  );
}

const lowerIsBetterOffMetrics = new Set([
  "turnover_rate_PNR_ballhandler",
  "TOVr_decay",
  "pnrScrTurnoverRate",
  "pnrScrRollerTurnoverRate",
  "pnrScrPopperTurnoverRate",
  "isoBH_turnover_rate",
  "ros_turnover_rate",
  "closeout_TOV_rate",
]);

const lowerIsBetterDefMetrics = new Set([
  "def_makeAbility_finish",
  "def_foulAbility_finish",
  "rimMakeFoulDef",
  "ptsSavedPer100",
  "mAOI_PNR_ballhandler_defender",
  "mxAOI_PNR_ballhandler_defender",
  "defPnrScrAoi",
  "defPnrScrXAoi",
  "mAOI_PNR_screener_defender_high",
  "mxAOI_PNR_screener_defender_high",
  "mAOI_PNR_screener_defender_switch",
  "mxAOI_PNR_screener_defender_switch",
  "mAOI_PNR_screener_defender_traditional",
  "mxAOI_PNR_screener_defender_traditional",
  "mAOI_iso_iso_defender",
  "mxAOI_iso_iso_defender",
  "iso_def_shot_pps",
  "iso_def_shot_pps_adj",
  "mAOI_post_post_defender",
  "mxAOI_post_post_defender",
  "mAOI_run_off_screen_run_off_screen_defender",
  "mxAOI_run_off_screen_run_off_screen_defender",
  "closeout_def_blowby_rate",
]);

function isLowerBetter(metric: string) {
  return (
    lowerIsBetterDefMetrics.has(metric) || lowerIsBetterOffMetrics.has(metric)
  );
}

function getDefaultSkill() {
  const off = SKILL_OBJECT.skillOffense;
  if (off) {
    const subCats = off.subcategories;
    if (subCats) {
      const first = Object.values(subCats)[0];
      if (first) {
        const firstOpt = first.options[0];
        if (firstOpt) {
          return firstOpt.value;
        }
      }
    }
  }
  return "";
}

const SKILL_OBJECT: Record<
  string,
  {
    label: string;
    subcategories: Record<
      string,
      {
        label: string;
        options: {
          value: keyof SkillData;
          label: string;
          desc?: string;
        }[];
      }
    >;
  }
> = {
  skillOffense: {
    label: "Offense Skills",
    subcategories: {
      threePtShooting: {
        label: "Shooting (3pt)",
        options: [
          {
            value: "F3p_decay",
            label: "All Threes",
            desc: "3pt%, but mean-regressed to properly balance career 3pt% with recent form.",
          },
          {
            value: "makeAbility_catchAndShoot",
            label: "Spot-up",
            desc: "PPS above/below NBA average on spot-up 3pt attempts, controlling for degree of difficulty.",
          },
          {
            value: "cs3_rate",
            label: "Spot-up (Freq)",
            desc: "Spot-up 3s per possession on the floor.",
          },
          {
            value: "offTheDribble",
            label: "Off the dribble",
            desc: "PPS above/below NBA average on off the dribble 3pt attempts, controlling for degree of difficulty.",
          },
          {
            value: "makeAbility_catchAndShootOnMove",
            label: "On the move",
            desc: "PPS above/below NBA average on movement 3pt attempts, controlling for degree of difficulty.",
          },
          {
            value: "csm3_rate",
            label: "On the move (Freq)",
            desc: "Movement 3s per possession on the floor.",
          },
        ],
      },
      rimShooting: {
        label: "Shooting (Rim)",
        options: [
          {
            value: "overallAbility_drivingLayup",
            label: "Driving Layup (Overall)",
            desc: "PPS above/below NBA average on driving layup attempts, controlling for degree of difficulty.",
          },
          {
            value: "overallAbility_cutLayup",
            label: "Cut Layup (Overall)",
            desc: "PPS above/below NBA average on cut layup attempts, controlling for degree of difficulty.",
          },
          {
            value: "makeAbility_drivingLayup",
            label: "Driving Layup (Make)",
            desc: "Percentage of made shots above/below NBA average on driving layup attempts, controlling for degree of difficulty.",
          },
          {
            value: "makeAbility_cutLayup",
            label: "Cut Layup (Make)",
            desc: "Percentage of made shots above/below NBA average on cut layup attempts, controlling for degree of difficulty.",
          },
          // { value: "", label: "Contested finishes" },
          {
            value: "foulAbility_drivingLayup",
            label: "Driving Layup (Foul)",
            desc: "Foul drawing rate above/below NBA average on driving layup attempts, controlling for average foul rate of defender.",
          },
          {
            value: "foulAbility_cutLayup",
            label: "Cut Layup (Foul)",
            desc: "Foul drawing rate above/below NBA average on cut layup attempts, controlling for average foul rate of defender.",
          },
          // { value: "", label: "Non-dunks" },
        ],
      },
      otherShooting: {
        label: "Shooting (Other)",
        options: [
          {
            value: "overallAbility_floater",
            label: "Floater",
            desc: "PPS above/below NBA average on floater attempts, controlling for degree of difficulty.",
          },
          {
            value: "drb_jumper2_pct",
            label: "Dribble 2 Jumper %",
            desc: "Percentage of a player's dribble jumpers that came from inside the arc.",
          },
          {
            value: "isoBH_NL2_rate",
            label: "ISO Ballhandler NL2 (Freq)",
            desc: "Percentage of NL2 attempts as an Iso Ballhandler.",
          },
          {
            value: "isoNL2_shots_makeAbility",
            label: "ISO NL2 (Make)",
            desc: "Percentage of shots made above/below NBA average on Iso NL2 attempts, controlling for degree of difficulty.",
          },
          {
            value: "isoNL2_shots_foulAbility",
            label: "ISO NL2 (Foul)",
            desc: "Foul-drawing rate above/below NBA average on Iso NL2 attempts, controlling for average foul rate of defender.",
          },
          // { value: "", label: "Midrange" },
          {
            value: "FTp_decay",
            label: "Free Throw",
            desc: "FT%, but mean-regressed to properly balance career FT% with recent form.",
          },
        ],
      },
      playerMaking: {
        label: "Playmaking",
        options: [
          {
            value: "ASTr_decay",
            label: "Ast%",
            desc: "Ast%, mean-regressed to properly balance career Ast% with recent form.",
          },
          {
            value: "TOVr_decay",
            label: "TO%",
            desc: "TO%, mean-regressed to properly balance career TO% with recent form.",
          },
          {
            value: "ASTTOV_decay",
            label: "Ast/TO",
            desc: "Ast/(Ast+TO), mean-regressed to properly balance career Ast/TO with recent form.",
          },
          {
            value: "pass_cs_lyp_TOV",
            label: "AstOpp/TO",
            desc: "Career AstOpp/(AstOpp+TO).",
          },
          {
            value: "Usage_decay",
            label: "Usage",
            desc: "Usage, mean-regressed to properly balance career Usage with recent form.",
          },
        ],
      },
      transition: {
        label: "Transition",
        options: [
          {
            value: "trans_involvement_rateO",
            label: "In Play (Freq)",
            desc: "Percentage of offensive transition instances in which the player is considered in the play.",
          },
          // {value: "", label: "Get Back over Expected" },
          {
            value: "seconds_over_half_taken_per_poss",
            label: "Ballhandler Pace",
            desc: "Amount of time (in seconds) on average that the player takes to cross halfcourt.",
          },
        ],
      },
      rebounding: {
        label: "Rebounding",
        options: [
          {
            value: "crash_rate",
            label: "Crashing",
            desc: "Career crash rate.",
          },
          {
            value: "crash_diff",
            label: "Crashing vs Expected",
            desc: "Difference in Career crash rate vs expected crash rate.",
          },
          {
            value: "crashORp",
            label: "Crash Conversion",
            desc: "Career percentage of crashes on which the player secures an offensive rebound.",
          },
          {
            value: "ORp_decay",
            label: "OR%",
            desc: "OR%, mean-regressed to properly balance career OR% with recent form.",
          },
        ],
      },
    },
  },
  skillDefense: {
    label: "Defense Skills",
    subcategories: {
      rimProtection: {
        label: "Rim Protection",
        options: [
          {
            value: "def_makeAbility_finish",
            label: "Rim Make% Def",
            desc: "Opponent make rate allowed above/below NBA average on rim attempts on which the player is defending (controlling for offensive player ability).",
          },
          {
            value: "def_foulAbility_finish",
            label: "Rim Foul% Def",
            desc: "Opponent foul rate above/below NBA average on rim attempts on which the player is defending (controlling for offensive player ability).",
          },
          {
            value: "rimMakeFoulDef",
            label: "Rim Make/Foul Def",
            desc: "Opponent PPS above/below NBA average on rim attempts on which the player is defending (controlling for offensive player ability).",
          },
          // { value: "", label: `Contests per "closest defender"` },
          {
            value: "ptsSavedPer100",
            label: "Points saved per 100 (rim pps diff x contest rate)",
            desc: "Points saved per 100 on finish contests (through higher finish contest rate or finish contest impact).",
          },
        ],
      },
      activity: {
        label: "Activity",
        options: [
          {
            value: "STLr_decay",
            label: "Steals",
            desc: "Steal Rate, mean-regressed to properly balance career Steal Rate with recent form.",
          },
          // {value: "", label: "Deflections/100" },
          {
            value: "pick_up_35_rate",
            label: "Pick-up point 35ft above",
            desc: "Rate of picking up ballhandlers outside of 35ft.",
          },
        ],
      },
      transition: {
        label: "Transition",
        options: [
          {
            value: "trans_involvement_rateD",
            label: "In Play (Freq)",
            desc: "Percentage of defensive transition instances in which the player is considered in the play.",
          },
        ],
      },
    },
  },
  actionOffense: {
    label: "Offense Actions",
    subcategories: {
      pnrBhr: {
        label: "PNR BHR",
        options: [
          {
            value: "mAOI_PNR_ballhandler",
            label: "AOI",
            desc: "Average points per possession added/subtracted to the efficiency of a given PNR as a PNR Ballhandler.",
          },
          {
            value: "mxAOI_PNR_ballhandler",
            label: "xAOI",
            desc: "Average expected points per possession added/subtracted to the efficiency of a given PNR as a PNR Ballhandler.",
          },
          // { value: "", label: "xPPS (Team)" },
          // { value: "", label: "xPPS (Bhr)" },
          {
            value: "passer_rate_PNR_ballhandler",
            label: "Assist Rate",
            desc: "Assist rate as a PNR Ballhandler.",
          },
          {
            value: "turnover_rate_PNR_ballhandler",
            label: "Turnover Rate",
            desc: "Turnover rate as a PNR Ballhandler",
          },
          {
            value: "assistPerTurnover",
            label: "Ast/TO",
            desc: "Average AST/TO as a PNR Ballhandler.",
          },
          {
            value: "pnr_shoot_jmp3_rate_x_overScreen",
            label: "Pull-up 3pt Accuracy/Freq (PNR only)",
            desc: "Pullup 3pt attempt rate as a PNR Ballhandler",
          },
          // { value: "", label: "No Advantage Created PNR (SS's Indirect)" },
        ],
      },
      pnrScr: {
        label: "PNR SCR",
        options: [
          {
            value: "pnrScrAoi",
            label: "AOI",
            desc: "Average points per possession added/subtracted to the efficiency of a given PNR as a PNR Screener.",
          },
          {
            value: "pnrScrXAoi",
            label: "xAOI",
            desc: "Average expected points per possession added/subtracted to the efficiency of a given PNR as a PNR Screener.",
          },
          // {value: "", label: "Shooting Ability" },
          // {value: "", label: "xPPS (not team)" },
          // {value: "", label: "Popper C&S 3pt%" },
          // {value: "", label: "Popper xPPS" },
          // {value: "", label: "Roller FG%" },
          // {value: "", label: "Roller Foul Drawing" },
          // {value: "", label: "Roller xPPS" },
          {
            value: "roll_team_cs_rate",
            label: "Roll Team C&S Rate",
            desc: "Team C&S frequency on instances in which the player rolls after setting in PNR.",
          },

          {
            value: "pnrScrAssistRate",
            label: "Assist Rate",
            desc: "Assist opportunity rate as a PNR Screener.",
          },
          {
            value: "pnrScrTurnoverRate",
            label: "Turnover Rate",
            desc: "Turnover rate as a PNR Screener.",
          },
          {
            value: "pnrScrAstPerTo",
            label: "Ast/TO",
            desc: "AstOpp/TO as a PNR Screener.",
          },
          {
            value: "pnrScrRollerAssistRate",
            label: "Roller Assist Rate",
            desc: "Assist opportunity rate as a roller in PNR.",
          },
          {
            value: "pnrScrRollerTurnoverRate",
            label: "Roller Turnover Rate",
            desc: "Turnover rate as a roller in PNR.",
          },
          {
            value: "pnrScrRollerAstPerTo",
            label: "Roller Ast/TO",
            desc: "AstOpp/TO as a roller in PNR.",
          },

          {
            value: "pnrScrPopperAssistRate",
            label: "Popper Assist Rate",
            desc: "Assist opportunity rate as a popper in PNR.",
          },
          {
            value: "pnrScrPopperTurnoverRate",
            label: "Popper Turnover Rate",
            desc: "Turnover rate as a popper in PNR.",
          },
          {
            value: "pnrScrPopperAstPerTo",
            label: "Popper Ast/TO",
            desc: "AstOpp/TO as a popper in PNR.",
          },
        ],
      },
      post: {
        label: "Post",
        options: [
          {
            value: "mAOI_post_post",
            label: "AOI",
            desc: "Average points per possession added/subtracted to the efficiency of a given post-up as a Post Ballhandler.",
          },
          {
            value: "mxAOI_post_post",
            label: "xAOI",
            desc: "Average expected points per possession added/subtracted to the efficiency of a given post-up as a Post Ballhandler.",
          },
          // { value: "", label: "Shooting" },
          // { value: "", label: "FG%" },
          // { value: "", label: "Foul Drawing" },
          // { value: "", label: "PPS" },
          // { value: "", label: "xPPS" },
          {
            value: "mean_post_off_shot_dist",
            label: "Shot Dist",
            desc: "Average FG attempt distance from the rim.",
          },
          // { value: "", label: "Assist Rate" },
          {
            value: "post_post_passer_per_turnover",
            label: "Post Pass Completion Rate",
            desc: "Percentage of Passes that are completed as a Post Ballhandler.",
          },
          // { value: "", label: "Ast/TO" },
        ],
      },
      iso: {
        label: "ISO",
        options: [
          {
            value: "mAOI_iso_iso",
            label: "AOI",
            desc: "Average points per possession added/subtracted to the efficiency of a given iso as a Iso Ballhandler.",
          },
          {
            value: "mxAOI_iso_iso",
            label: "xAOI",
            desc: "Average expected points per possession added/subtracted to the efficiency of a given iso as a Iso Ballhandler.",
          },
          {
            value: "isoBH_shot_pps",
            label: "Shooting",
            desc: "PPS on Iso FG attempts (including shooting fouls).",
          },
          {
            value: "isoBH_shot_rate",
            label: "Shooting (Freq)",
            desc: "Percentage of Iso instances in which the player shoots.",
          },
          {
            value: "isoBH_rim_rate",
            label: "Rim",
            desc: "Layup rate as an Iso Ballhandler.",
          },
          {
            value: "isoBH_NL2_rate",
            label: "NL2",
            desc: "NL2 rate as an Iso Ballhandler.",
          },
          {
            value: "isoBH_three_rate",
            label: "3pt",
            desc: "3pt rate as an Iso Ballhandler.",
          },
          // { value: "iso3_shots_foulAbility + isoNL2_shots_foul + ?? (Rim)", label: "Foul Drawing" },
          {
            value: "isoBH_pass_cs_rate",
            label: "Assist Rate",
            desc: "Assist rate as an Iso Ballhandler.",
          },
          {
            value: "isoBH_turnover_rate",
            label: "Turnover Rate",
            desc: "Turnover rate as an Iso Ballhandler.",
          },
          {
            value: "isoBH_pass_cs_lyp_Int",
            label: "Ast/TO",
            desc: "Rate of spot-ups and layups generated from player passes out of Iso.",
          },
        ],
      },
      offScreen: {
        label: "Off Screen",
        options: [
          {
            value: "mAOI_run_off_screen_run_off_screen",
            label: "AOI",
            desc: "Average points per possession added/subtracted to the efficiency of a given off-ball action as the off-ball player.",
          },
          {
            value: "mxAOI_run_off_screen_run_off_screen",
            label: "xAOI",
            desc: "Average expected points per possession added/subtracted to the efficiency of a given off-ball action as the off-ball player.",
          },
          {
            value: "ros_jumper_pps",
            label: "Shooting Ability",
            desc: "PPS on FG attempts from off-ball action (jumpers only).",
          },
          // { value: "", label: "3pt%" },
          // { value: "", label: "eFG%" },
          {
            value: "ros_3_rate",
            label: "Get 3 Rate",
            desc: "Percentage of off-ball actions on which the player gets a 3pt attempt.",
          },
          // { value: "", label: "xPPS/PPS at certain speed on shot" },
          // { value: "", label: "Assist Rate" },
          {
            value: "ros_turnover_rate",
            label: "Turnover Rate",
            desc: "Turnover rate as an off-ball movement player.",
          },
          // { value: "", label: "Ast/TO" },
        ],
      },
      closeouts: {
        label: "Closeouts",
        options: [
          {
            value: "closeout_AOI",
            label: "AOI",
            desc: "Average points per possession added/subtracted to the efficiency of a given closeout as the closeout ballhandler.",
          },
          // { value: "", label: "xAOI" },
          // { value: "", label: "Shooting Ability" },
          // { value: "", label: "xPPS" },
          // { value: "", label: "eFG%" },
          // { value: "", label: "Assist Rate" },
          {
            value: "closeout_TOV_rate",
            label: "Turnover Rate",
            desc: "Turnover rate as a closeout ballhandler.",
          },
          // { value: "", label: "Ast/TO" },
          {
            value: "closeout_keep_advantage_rate",
            label: "Keep Advantage",
            desc: "Percentage of closeouts on which the player gets a shot or draws a help defender.",
          },
          // { value: "", label: "Controlling for defender distance/speed on catch" },
          // { value: "", label: " Drive/Shoot decision (comparing xPPS on drives of similar closeout type and xPPS on the closeout type)" },
        ],
      },
    },
  },
  actionDefense: {
    label: "Defense Actions",
    subcategories: {
      pnrBhr: {
        label: "PNR BHR",
        options: [
          {
            value: "mAOI_PNR_ballhandler_defender",
            label: "AOI",
            desc: "Average points per possession added/subtracted to the efficiency of a given PNR as the PNR Ballhandler Defender.",
          },
          {
            value: "mxAOI_PNR_ballhandler_defender",
            label: "xAOI",
            desc: "Average expected points per possession added/subtracted to the efficiency of a given PNR as the PNR Ballhandler Defender.",
          },
          {
            value: "turnover_forced_rate_PNR_ballhandler_defender",
            label: "Turnover/Off Foul Rate",
            desc: "Steal rate as a PNR Ballhandler Defender.",
          },
          // { value: "", label: "Switch Rate" },
        ],
      },
      pnrScr: {
        label: "PNR SCR",
        options: [
          {
            value: "defPnrScrAoi",
            label: "AOI",
            desc: "Average points per possession added/subtracted to the efficiency of a given PNR as the PNR Screener Defender.",
          },
          {
            value: "defPnrScrXAoi",
            label: "xAOI",
            desc: "Average expected points per possession added/subtracted to the efficiency of a given PNR as the PNR Screener Defender.",
          },
          {
            value: "mAOI_PNR_screener_defender_high",
            label: "AOI (High)",
            desc: "Average points per possession added/subtracted to the efficiency of a given PNR as the PNR Screener Defender with high coverage.",
          },
          {
            value: "mxAOI_PNR_screener_defender_high",
            label: "xAOI (High)",
            desc: "Average expected points per possession added/subtracted to the efficiency of a given PNR as the PNR Screener Defender with high coverage.",
          },
          {
            value: "mAOI_PNR_screener_defender_switch",
            label: "AOI (Switch)",
            desc: "Average points per possession added/subtracted to the efficiency of a given PNR as the PNR Screener Defender with switch coverage.",
          },
          {
            value: "mxAOI_PNR_screener_defender_switch",
            label: "xAOI (Switch)",
            desc: "Average expected points per possession added/subtracted to the efficiency of a given PNR as the PNR Screener Defender with switch coverage.",
          },
          {
            value: "mAOI_PNR_screener_defender_traditional",
            label: "AOI (Traditional)",
            desc: "Average points per possession added/subtracted to the efficiency of a given PNR as the PNR Screener Defender with traditional coverage.",
          },
          {
            value: "mxAOI_PNR_screener_defender_traditional",
            label: "xAOI (Traditional)",
            desc: "Average expected points per possession added/subtracted to the efficiency of a given PNR as the PNR Screener Defender with traditional coverage.",
          },
          {
            value: "defPnrScrBhr3Rate",
            label: "Bhr 3 Rate",
            desc: "Rate at which the PNR Ballhandler attempts a 3pt shot with the player in coverage.",
          },
          {
            value: "defPnrScrBhrLayupRate",
            label: "Bhr Layup Rate",
            desc: "Rate at which the PNR Ballhandler attempts a layup with the player in coverage.",
          },
          {
            value: "defPnrScrScrLayupRate",
            label: "Scr Layup Rate",
            desc: "Rate at which the PNR Screener attempts a layup with the player in coverage.",
          },
          {
            value: "high_def_bh3_rate",
            label: "Bhr 3 Rate (High)",
            desc: "Rate at which the PNR Ballhandler attempts a 3pt shot with the player in high coverage.",
          },
          {
            value: "high_def_bhr_lyp_rate",
            label: "Bhr Layup Rate (High)",
            desc: "Rate at which the PNR Ballhandler attempts a layup with the player in high coverage.",
          },
          {
            value: "high_def_scr_lyp_rate",
            label: "Scr Layup Rate (High)",
            desc: "Rate at which the PNR Screener attempts a layup after popping with the player in high coverage.",
          },
          {
            value: "switch_def_bh3_rate",
            label: "Bhr 3 Rate (Switch)",
            desc: "Rate at which the PNR Ballhandler attempts a 3pt shot with the player in switch coverage.",
          },
          {
            value: "switch_def_bhr_lyp_rate",
            label: "Bhr Layup Rate (Switch)",
            desc: "Rate at which the PNR Ballhandler attempts a layup with the player in switch coverage.",
          },
          {
            value: "switch_def_scr_lyp_rate",
            label: "Scr Layup Rate (Switch)",
            desc: "Rate at which the PNR Screener attempts a layup after popping with the player in switch coverage.",
          },
          {
            value: "traditional_def_bh3_rate",
            label: "Bhr 3 Rate (Traditional)",
            desc: "Rate at which the PNR Ballhandler attempts a 3pt shot with the player in traditional coverage.",
          },
          {
            value: "traditional_def_bhr_lyp_rate",
            label: "Bhr Layup Rate (Traditional)",
            desc: "Rate at which the PNR Ballhandler attempts a layup with the player in traditional coverage.",
          },
          {
            value: "traditional_def_scr_lyp_rate",
            label: "Scr Layup Rate (Traditional)",
            desc: "Rate at which the PNR Screener attempts a layup after popping with the player in traditional coverage.",
          },
          {
            value: "defPnrScrToForcedRate",
            label: "Turnover/Off Foul Rate",
            desc: "Rate at which a turnover is forced as a PNR Screener Defender.",
          },
          {
            value: "turnover_forced_rate_PNR_screener_defender_high",
            label: "Steal Rate (High)",
            desc: "Steal rate at which a turnover is forced as a PNR Screener Defender in high coverage.",
          },
          {
            value: "turnover_forced_rate_PNR_screener_defender_switch",
            label: "Steal Rate (Switch)",
            desc: "Steal rate as a PNR Screener Defender in switch coverage.",
          },
          {
            value: "turnover_forced_rate_PNR_screener_defender_traditional",
            label: "Steal Rate (Traditional)",
            desc: "Steal rate as a PNR Screener Defender in traditional coverage.",
          },
        ],
      },
      iso: {
        label: "ISO",
        options: [
          {
            value: "mAOI_iso_iso_defender",
            label: "AOI",
            desc: "Average points per possession added/subtracted to the efficiency of a given Iso as the Iso Ballhandler Defender.",
          },
          {
            value: "mxAOI_iso_iso_defender",
            label: "xAOI",
            desc: "Average expected points per possession added/subtracted to the efficiency of a given Iso as the Iso Ballhandler Defender.",
          },
          {
            value: "iso_def_shot_pps",
            label: "xPPS",
            desc: "Ballhandler xPPS allowed as an Iso Ballhandler Defender.",
          },
          {
            value: "iso_def_shot_pps_adj",
            label: "xPPS Diff",
            desc: "Difference between ballhandler xPPS allowed as an Iso Ballhandler Defender and the ballhandler's average xPPS.",
          },
          // { value: "", label: "Foul Rate" },
          {
            value: "iso_def_blowby_rate",
            label: "Blowby",
            desc: "Ballhandler blowby rate as an Iso Ballhandler Defender.",
          },
          {
            value: "turnover_forced_rate_iso_iso_defender",
            label: "Steal Rate",
            desc: "Ballhandler steal rate as an Iso Ballhandler Defender.",
          },
        ],
      },
      post: {
        label: "Post",
        options: [
          {
            value: "mAOI_post_post_defender",
            label: "AOI",
            desc: "Average points per possession added/subtracted to the efficiency of a given post-up as the Post Ballhandler Defender.",
          },
          {
            value: "mxAOI_post_post_defender",
            label: "xAOI",
            desc: "Average expected points per possession added/subtracted to the efficiency of a given post-up as the Post  Ballhandler Defender.",
          },
          // { value: "", label: "xPPS Diff" },
          // {value: "", label: "Foul Rate" },
          {
            value: "turnover_forced_rate_post_post_defender",
            label: "Steal Rate",
            desc: "Ballhandler steal rate as an Post Ballhandler Defender.",
          },
          {
            value: "mean_post_def_shot_dist",
            label: "Shot Distance",
            desc: "Average Post Ballhandler FG attempt distance as a Post Ballhandler Defender, adjusted for Post Ballhandler ability.",
          },
        ],
      },
      offScreen: {
        label: "Off Screen",
        options: [
          {
            value: "mAOI_run_off_screen_run_off_screen_defender",
            label: "AOI",
            desc: "Average points per possession added/subtracted to the efficiency of a given off-ball action as the Off-Ball Defender.",
          },
          {
            value: "mxAOI_run_off_screen_run_off_screen_defender",
            label: "xAOI",
            desc: "Average expected points per possession added/subtracted to the efficiency of a given off-ball action as the Off-Ball Defender.",
          },
          // { value: "", label: "xPPS Diff" },
          {
            value:
              "turnover_forced_rate_run_off_screen_run_off_screen_defender",
            label: "Turnover/Off Foul Rate",
          },
        ],
      },
      closeouts: {
        label: "Closeouts",
        options: [
          // { value: "", label: "AOI" },
          //{ value: "", label: "xAOI" },
          {
            value: "closeout_def_success_rate",
            label: "Success Rate",
            desc: "Percentage of closeouts defended where the offensive player doesn't drive or shoot.",
          },
          {
            value: "closeout_def_blowby_rate",
            label: "Blowby Rate",
            desc: "Percentage of closeouts defended where the ballhandler blows by the defender.",
          },
          // { value: "", label: "Negate Advantage" },
          // { value: "", label: "Turnover Rate" },
          {
            value: "closeout_def_drv_rate",
            label: "Drive Rate",
            desc: "Percentage of closeouts defended in which the ballhandler drives.",
          },
          {
            value: "closeout_def_shot_rate",
            label: "Shot Rate",
            desc: "Percentage of closeouts defended in which the ballhandler shoots.",
          },
          {
            value: "closeout_def_force_help_rate",
            label: "Force Help Rate",
            desc: "Percentage of closeouts defended where help is drawn.",
          },
        ],
      },
    },
  },
  rimFinishingStats: {
    label: "Rim Finishing Stats",
    subcategories: {
      layup: createEntryForShotType("Layup"),
      contestedLayup: createEntryForShotType("Contested Layup"),
      floater: createEntryForShotType("Floater"),
    },
  },
  shootingStats: {
    label: "Shooting Stats",
    subcategories: {
      twoPointJumper: createEntryForShotType("Two Point Jumper"),
      three: createEntryForShotType("Three"),
      spotUpThree: createEntryForShotType("Spot Up Three"),
      relocatingThree: createEntryForShotType("Relocating Three"),
      movementThree: createEntryForShotType("Movement Three"),
      offTheDribbleThree: createEntryForShotType("Off The Dribble Three"),
    },
  },
};

function calculateAverage(data: ShotCountByDate[]): AggShotData {
  const nShots = sumFromField("shotsN", data) || 0;

  const nLayups = sumFromField("layupN", data) || 0;
  const fgmLayups = sumFromField("layupFGM", data) || 0;
  const fgaLayups = sumFromField("layupFGA", data) || 0;
  const ftaLayups = sumFromField("layupFTA", data) || 0;

  const nContestedLayups = sumFromField("contestedLayupN", data) || 0;
  const fgmContestedLayups = sumFromField("contestedLayupFGM", data) || 0;
  const fgaContestedLayups = sumFromField("contestedLayupFGA", data) || 0;
  const ftaContestedLayups = sumFromField("contestedLayupFTA", data) || 0;

  const nFloaters = sumFromField("floaterN", data) || 0;
  const fgmFloaters = sumFromField("floaterFGM", data) || 0;
  const fgaFloaters = sumFromField("floaterFGA", data) || 0;
  const ftaFloaters = sumFromField("floaterFTA", data) || 0;

  const nTwoPointJumpers = sumFromField("twoPointJumperN", data) || 0;
  const fgmTwoPointJumpers = sumFromField("twoPointJumperFGM", data) || 0;
  const fgaTwoPointJumpers = sumFromField("twoPointJumperFGA", data) || 0;
  const ftaTwoPointJumpers = sumFromField("twoPointJumperFTA", data) || 0;

  const nThrees = sumFromField("threeN", data) || 0;
  const fgmThrees = sumFromField("threeFGM", data) || 0;
  const fgaThrees = sumFromField("threeFGA", data) || 0;
  const ftaThrees = sumFromField("threeFTA", data) || 0;

  const nSpotUpThrees = sumFromField("spotUpThreeN", data) || 0;
  const fgmSpotUpThrees = sumFromField("spotUpThreeFGM", data) || 0;
  const fgaSpotUpThrees = sumFromField("spotUpThreeFGA", data) || 0;
  const ftaSpotUpThrees = sumFromField("spotUpThreeFTA", data) || 0;

  const nRelocatingThrees = sumFromField("relocatingThreeN", data) || 0;
  const fgmRelocatingThrees = sumFromField("relocatingThreeFGM", data) || 0;
  const fgaRelocatingThrees = sumFromField("relocatingThreeFGA", data) || 0;
  const ftaRelocatingThrees = sumFromField("relocatingThreeFTA", data) || 0;

  const nMovementThrees = sumFromField("movementThreeN", data) || 0;
  const fgmMovementThrees = sumFromField("movementThreeFGM", data) || 0;
  const fgaMovementThrees = sumFromField("movementThreeFGA", data) || 0;
  const ftaMovementThrees = sumFromField("movementThreeFTA", data) || 0;

  const nOffTheDribbleThrees = sumFromField("offTheDribbleThreeN", data) || 0;
  const fgmOffTheDribbleThrees =
    sumFromField("offTheDribbleThreeFGM", data) || 0;
  const fgaOffTheDribbleThrees =
    sumFromField("offTheDribbleThreeFGA", data) || 0;
  const ftaOffTheDribbleThrees =
    sumFromField("offTheDribbleThreeFTA", data) || 0;

  return {
    // Don't actually need these fields but put them in to make the type happy.
    playerId: 0,
    gameDate: "",
    layupAttemptPct: nShots === 0 ? null : nLayups / nShots,
    layupMakePct: fgaLayups === 0 ? null : fgmLayups / fgaLayups,
    layupFtaRate: fgaLayups === 0 ? null : ftaLayups / fgaLayups,
    layupxPPS: weightedAverage("layupN", "layupxPPS", data),
    layupxPPSLg: weightedAverage("layupN", "layupxPPSLg", data),
    contestedLayupAttemptPct: nShots === 0 ? null : nContestedLayups / nShots,
    contestedLayupMakePct:
      fgaContestedLayups === 0 ? null : fgmContestedLayups / fgaContestedLayups,
    contestedLayupFtaRate:
      fgaContestedLayups === 0 ? null : ftaContestedLayups / fgaContestedLayups,
    contestedLayupxPPS: weightedAverage(
      "contestedLayupN",
      "contestedLayupxPPS",
      data
    ),
    contestedLayupxPPSLg: weightedAverage(
      "contestedLayupN",
      "contestedLayupxPPSLg",
      data
    ),
    floaterAttemptPct: nShots === 0 ? null : nFloaters / nShots,
    floaterMakePct: fgaFloaters === 0 ? null : fgmFloaters / fgaFloaters,
    floaterFtaRate: fgaFloaters === 0 ? null : ftaFloaters / fgaFloaters,
    floaterxPPS: weightedAverage("floaterN", "floaterxPPS", data),
    floaterxPPSLg: weightedAverage("floaterN", "floaterxPPSLg", data),
    twoPointJumperAttemptPct: nShots === 0 ? null : nTwoPointJumpers / nShots,
    twoPointJumperMakePct:
      fgaTwoPointJumpers === 0 ? null : fgmTwoPointJumpers / fgaTwoPointJumpers,
    twoPointJumperFtaRate:
      fgaTwoPointJumpers === 0 ? null : ftaTwoPointJumpers / fgaTwoPointJumpers,
    twoPointJumperxPPS: weightedAverage(
      "twoPointJumperN",
      "twoPointJumperxPPS",
      data
    ),
    twoPointJumperxPPSLg: weightedAverage(
      "twoPointJumperN",
      "twoPointJumperxPPSLg",
      data
    ),
    threeAttemptPct: nShots === 0 ? null : nThrees / nShots,
    threeMakePct: fgaThrees === 0 ? null : fgmThrees / fgaThrees,
    threeFtaRate: fgaThrees === 0 ? null : ftaThrees / fgaThrees,
    threexPPS: weightedAverage("threeN", "threexPPS", data),
    threexPPSLg: weightedAverage("threeN", "threexPPSLg", data),
    spotUpThreeAttemptPct: nShots === 0 ? null : nSpotUpThrees / nShots,
    spotUpThreeMakePct:
      fgaSpotUpThrees === 0 ? null : fgmSpotUpThrees / fgaSpotUpThrees,
    spotUpThreeFtaRate:
      fgaSpotUpThrees === 0 ? null : ftaSpotUpThrees / fgaSpotUpThrees,
    spotUpThreexPPS: weightedAverage("spotUpThreeN", "spotUpThreexPPS", data),
    spotUpThreexPPSLg: weightedAverage(
      "spotUpThreeN",
      "spotUpThreexPPSLg",
      data
    ),
    relocatingThreeAttemptPct: nShots === 0 ? null : nRelocatingThrees / nShots,
    relocatingThreeMakePct:
      fgaRelocatingThrees === 0
        ? null
        : fgmRelocatingThrees / fgaRelocatingThrees,
    relocatingThreeFtaRate:
      fgaRelocatingThrees === 0
        ? null
        : ftaRelocatingThrees / fgaRelocatingThrees,
    relocatingThreexPPS: weightedAverage(
      "relocatingThreeN",
      "relocatingThreexPPS",
      data
    ),
    relocatingThreexPPSLg: weightedAverage(
      "relocatingThreeN",
      "relocatingThreexPPSLg",
      data
    ),
    movementThreeAttemptPct: nShots === 0 ? null : nMovementThrees / nShots,
    movementThreeMakePct:
      fgaMovementThrees === 0 ? null : fgmMovementThrees / fgaMovementThrees,
    movementThreeFtaRate:
      fgaMovementThrees === 0 ? null : ftaMovementThrees / fgaMovementThrees,
    movementThreexPPS: weightedAverage(
      "movementThreeN",
      "movementThreexPPS",
      data
    ),
    movementThreexPPSLg: weightedAverage(
      "movementThreeN",
      "movementThreexPPSLg",
      data
    ),
    offTheDribbleThreeAttemptPct:
      nShots === 0 ? null : nOffTheDribbleThrees / nShots,
    offTheDribbleThreeMakePct:
      fgaOffTheDribbleThrees === 0
        ? null
        : fgmOffTheDribbleThrees / fgaOffTheDribbleThrees,
    offTheDribbleThreeFtaRate:
      fgaOffTheDribbleThrees === 0
        ? null
        : ftaOffTheDribbleThrees / fgaOffTheDribbleThrees,
    offTheDribbleThreexPPS: weightedAverage(
      "offTheDribbleThreeN",
      "offTheDribbleThreexPPS",
      data
    ),
    offTheDribbleThreexPPSLg: weightedAverage(
      "offTheDribbleThreeN",
      "offTheDribbleThreexPPSLg",
      data
    ),
  };
}

function emptyAggShots(playerId: number, gameDate: string): AggShotData {
  return {
    playerId,
    gameDate,
    layupAttemptPct: null,
    layupMakePct: null,
    layupFtaRate: null,
    layupxPPS: null,
    layupxPPSLg: null,
    contestedLayupAttemptPct: null,
    contestedLayupMakePct: null,
    contestedLayupFtaRate: null,
    contestedLayupxPPS: null,
    contestedLayupxPPSLg: null,
    floaterAttemptPct: null,
    floaterMakePct: null,
    floaterFtaRate: null,
    floaterxPPS: null,
    floaterxPPSLg: null,
    twoPointJumperAttemptPct: null,
    twoPointJumperMakePct: null,
    twoPointJumperFtaRate: null,
    twoPointJumperxPPS: null,
    twoPointJumperxPPSLg: null,
    threeAttemptPct: null,
    threeMakePct: null,
    threeFtaRate: null,
    threexPPS: null,
    threexPPSLg: null,
    spotUpThreeAttemptPct: null,
    spotUpThreeMakePct: null,
    spotUpThreeFtaRate: null,
    spotUpThreexPPS: null,
    spotUpThreexPPSLg: null,
    relocatingThreeAttemptPct: null,
    relocatingThreeMakePct: null,
    relocatingThreeFtaRate: null,
    relocatingThreexPPS: null,
    relocatingThreexPPSLg: null,
    movementThreeAttemptPct: null,
    movementThreeMakePct: null,
    movementThreeFtaRate: null,
    movementThreexPPS: null,
    movementThreexPPSLg: null,
    offTheDribbleThreeAttemptPct: null,
    offTheDribbleThreeMakePct: null,
    offTheDribbleThreeFtaRate: null,
    offTheDribbleThreexPPS: null,
    offTheDribbleThreexPPSLg: null,
  };
}

function createEntryForShotType(shotType: string): {
  label: string;
  options: {
    value: keyof SkillData;
    label: string;
    desc?: string;
  }[];
} {
  const camelCased = shotType
    .split(" ")
    .map((word, i) => {
      if (i === 0) {
        return word.toLowerCase();
      }
      return word[0]?.toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
  const lowerCased = shotType.toLowerCase();

  return {
    label: shotType,
    options: [
      {
        value: `${camelCased}AttemptPct` as keyof SkillData,
        label: "Attempt %",
        desc: `Percentage of shot attempts that are ${lowerCased}s.`,
      },
      {
        value: `${camelCased}MakePct` as keyof SkillData,
        label: "Make %",
        desc: `FG% on ${lowerCased}s.`,
      },
      {
        value: `${camelCased}FtaRate` as keyof SkillData,
        label: "FTA Rate",
        desc: `FTA per ${lowerCased} FGA.`,
      },
      {
        value: `${camelCased}xPPS` as keyof SkillData,
        label: "xPPS",
        desc: `Expected points per shot on ${lowerCased}s.`,
      },
      {
        value: `${camelCased}xPPSLg` as keyof SkillData,
        label: "xPPS Lg",
        desc: `Expected points per shot on ${lowerCased}s if taken by a league average player.`,
      },
    ],
  };
}

function getPercentile(value: number, values: number[], reverse = false) {
  if (values.length === 0) return null;

  const index = reverse
    ? values.findIndex((v) => v <= value)
    : values.findIndex((v) => v >= value);
  if (index === -1) {
    return 1;
  }
  return index / values.length;
}
