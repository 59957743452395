import { createContext } from "react";

import { Team, TeamSchedule } from "../shared/routers/TeamRouter";

// A globally shared reference to the 30 teams in the league so that we don't
// have to fetch it in every page.
export const TeamContext = createContext<{
  teams: Team[];
  schedule: Record<string, TeamSchedule[]>;
}>({ teams: [], schedule: {} });
