import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";

import AppContext from "../../../shared/AppContext";
import { Panel } from "../../components/core/Panel";
import { TeamShootingTable } from "../../components/team/TeamShootingTable";
import { TeamChargesTable } from "../../components/team/TeamChargesTable";
import { GamePnrTable } from "../../components/games/GamePnrTable";
import { EfficiencyByStartType } from "../../components/team/TeamEffPerStartType";
import { Spinner } from "../../components/core/Spinner";
import {
  TeamCharges,
  TeamPnrDefense,
  TeamShooting,
  TeamActionBreakdown,
} from "../../../shared/routers/TeamRouter";
import { LeagueShooting } from "../../../shared/routers/LeagueRouter";
import { ChanceEfficiencyPerStartType2 } from "../../../shared/routers/ChanceRouter";
import { TeamActionsBreakdownTable } from "../../components/team/TeamActionsBreakdownTable";

export function TeamSubPageDefense(props: {
  teamId: number;
  pnrDef?: TeamPnrDefense[];
  charges?: TeamCharges[];
  teamShooting?: TeamShooting[];
  leagueShooting?: LeagueShooting[];
  teamActions?: TeamActionBreakdown[];
  leagueActions?: TeamActionBreakdown[];
  teamStartTypeData?: ChanceEfficiencyPerStartType2[];
  leagueStartTypeData?: ChanceEfficiencyPerStartType2[];
  filteredToSpecificGames: boolean;
  filtered: boolean;
}) {
  const {
    teamId,
    charges,
    pnrDef,
    teamShooting,
    leagueShooting,
    teamActions,
    leagueActions,
    teamStartTypeData,
    leagueStartTypeData,
    filteredToSpecificGames,
    filtered,
  } = props;
  const [minPnrsDefended, setMinPnrsDefended] = useState(100);

  return (
    <div>
      {AppContext.inPreseason /* Preseason-warning-message. */ && (
        <Row>
          <Col sm={12}>
            <Panel>
              <div
                style={{ fontSize: 22, fontWeight: 200, marginBottom: "0.8em" }}
              >
                Note: During preseason this data comes from the previous season.
              </div>
            </Panel>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={12} md={12} lg={12}>
          <Panel
            header="Shooting"
            panelID="shootingDefense"
            filtered={filtered}
          >
            <TeamShootingTable
              teamId={teamId}
              statType="DEF"
              teamShooting={teamShooting}
              leagueShooting={leagueShooting}
            />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12}>
          <Panel
            header="Defensive Breakdown"
            panelID="defensiveBreakdown"
            filtered={filtered}
          >
            <TeamActionsBreakdownTable
              teamId={teamId}
              teamActions={teamActions}
              leagueActions={leagueActions}
              statType="DEF"
            />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={12}>
          <Panel header="PNR Defense" panelID="pnrDefense" filtered={filtered}>
            <Form.Label>Minimum PNRs Defended</Form.Label>
            <div style={{ width: 240, display: "flex", gap: 8 }}>
              <Form.Range
                value={minPnrsDefended}
                min={0}
                max={200}
                onChange={(evt) => {
                  setMinPnrsDefended(parseInt(evt.target.value));
                }}
              />
              <div>{minPnrsDefended}</div>
            </div>
            {pnrDef ? (
              <div>
                <GamePnrTable
                  data={pnrDef
                    .filter((p) => p.type === "ballhandler_defender")
                    .sort((a, b) => b.totalPicks - a.totalPicks)}
                  offDef="defense"
                  type="ballhandler"
                  useColorDomain={true}
                  heatmapAlways={true}
                  usePercentage={true}
                  threshold={minPnrsDefended}
                />
                <GamePnrTable
                  data={pnrDef
                    .filter((p) => p.type === "screener_defender")
                    .sort((a, b) => b.totalPicks - a.totalPicks)}
                  offDef="defense"
                  type="screener"
                  useColorDomain={true}
                  heatmapAlways={true}
                  usePercentage={true}
                  threshold={minPnrsDefended}
                />
              </div>
            ) : (
              <Spinner />
            )}
          </Panel>
        </Col>
      </Row>
      <Row>
        <Col md={4} sm={6} xs={12}>
          <Panel header="Charges" panelID="charges">
            <TeamChargesTable charges={charges} />
          </Panel>
        </Col>
      </Row>
      <Row>
        <Panel header="Efficiency By Start Type" panelID="effByStartType">
          <EfficiencyByStartType
            teamId={teamId}
            teamData={teamStartTypeData}
            leagueData={leagueStartTypeData}
            showOffense={false}
            filteredToSpecificGames={filteredToSpecificGames}
            filtered={filtered}
          />
        </Panel>
      </Row>
    </div>
  );
}
