import React, { useMemo, useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { createStyles, makeStyles } from "@material-ui/styles";
import { IoMdSearch } from "react-icons/io";

import {
  Periods,
  complexShotTypeMap,
  simpleShotTypeLabelMap,
  Positions,
  shotLabels,
  secondSpectrumContestLevels,
} from "../../constants/AppConstants";
import { MultiSelect } from "../core/MultiSelect";
import { Slider } from "../core/Slider";
import { gameClockFormat } from "../../util/Format";
import { BooleanInput } from "../core/BooleanInput";
import AppContext from "../../../shared/AppContext";
import { TeamContext } from "../../TeamContext";
import { SSPlayerContext } from "../../PlayerContext";
import { NbaEaglePlayer } from "../../../shared/routers/RosterRouter";
import { ShotFilters } from "../../../shared/routers/ShotRouter";
import { trpc } from "../../util/tRPC";
import { UserPreferenceContext } from "../../UserContext";

const PERIOD_TIME_MAX = 720;
const DEF_DIST_MAX = 85;
const XPPS_MAX = 3;
const SHOT_DISTANCE_MAX = 94;
const SHOT_CLOCK_MAX = 24;
const DRIBBLES_BEFORE_MAX = 50;
const DISTANCE_FROM_HOOP_MAX = 60;

const useStyles = makeStyles(() =>
  createStyles({
    filters: {
      borderBottom: "1px solid #eee",
      paddingBottom: 15,
      marginBottom: 15,
    },
    group: {
      background: "#f4f4f4",
      borderRadius: 4,
      margin: 4,
      padding: 12,
      "& .row": {
        marginBottom: 12,
      },
    },
    datePicker: {
      width: "auto",
      display: "inline-block",
    },
  })
);

export function ShotFilterForm(props: {
  filters: ShotFilters;
  onFilterChange: (newFilterValues: ShotFilters) => void;
}) {
  const classes = useStyles();
  const [query, setQuery] = useState("");

  const { filters, onFilterChange } = props;

  const shotFilterAssistant = useContext(UserPreferenceContext)[
    "Shot Filters Assistant"
  ];

  const { data: result, isLoading: assistantThinking } =
    trpc.misc.getShotQuery.useQuery(
      { query },
      {
        onSuccess: (data) => {
          if (data && Object.keys(data).length > 0) {
            onFilterChange({ ...data });
          }
        },
      }
    );

  // Data to populate filters.
  const teams = useContext(TeamContext).teams;
  const players = useContext(SSPlayerContext);

  const playerSeasonTeamMap = useMemo(() => {
    if (!players) return;
    const ret: Record<string, Record<string, Set<string>>> = {};
    for (const player of players) {
      ret[player.playerId.toString()] = getSeasonTeamMap(player);
    }
    return ret;
  }, [players]);

  const filterPlayers = (p: NbaEaglePlayer, offense = true) => {
    const entry =
      playerSeasonTeamMap && playerSeasonTeamMap[p.playerId.toString()];
    if (!entry) return false;

    const seasons = filters.seasons;
    const teams = offense ? filters.offTeamIds : filters.defTeamIds;

    if (seasons?.length && teams?.length) {
      for (const season of seasons) {
        for (const team of teams) {
          const entryForSeason = entry[season];
          if (entryForSeason && entryForSeason.has(team)) {
            return true;
          }
        }
      }
      return false;
    } else if (seasons?.length) {
      return seasons.some((season) => Object.keys(entry).includes(season));
    } else if (teams?.length) {
      const allTeams = new Set(
        (function* () {
          for (const set of Object.values(entry)) yield* set;
        })()
      );
      return teams.some((team) => allTeams.has(team));
    }

    return true;
  };

  return (
    <Form>
      <Form.Group className={classes.filters}>
        {shotFilterAssistant === 1 && (
          <Row>
            <Col>
              <div
                style={{ display: "flex", alignItems: "flex-start", gap: 8 }}
              >
                <Form.Control
                  id="smartSearch"
                  placeholder="Generate shot filters with natural language (highly experimental!)"
                />
                <Button
                  disabled={assistantThinking}
                  type="button"
                  onClick={() => {
                    const input =
                      document.querySelector<HTMLInputElement>(
                        "input#smartSearch"
                      );
                    if (!input) return;
                    setQuery(input.value);
                  }}
                >
                  <IoMdSearch />
                </Button>
              </div>
            </Col>
            <Col>
              <div>{result ? JSON.stringify(result) : ""}</div>
            </Col>
          </Row>
        )}
        <Row>
          <Col className={classes.group}>
            <Row>
              <Col>
                <Form.Label>Season</Form.Label>
                <MultiSelect
                  values={AppContext.seasons.map((s) => {
                    return { value: s.value.toString(), label: s.label };
                  })}
                  selected={filters.seasons || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, seasons: vals })
                  }
                />
              </Col>
              <Col>
                <div>
                  <Form.Label>Date</Form.Label>
                </div>
                <Form.Control
                  type="date"
                  className={classes.datePicker}
                  value={filters.fromDate}
                  onChange={(evt) => {
                    onFilterChange({ ...filters, fromDate: evt.target.value });
                  }}
                />
                {" to "}
                <Form.Control
                  type="date"
                  className={classes.datePicker}
                  value={filters.toDate}
                  onChange={(evt) => {
                    onFilterChange({ ...filters, toDate: evt.target.value });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Period</Form.Label>
                <MultiSelect
                  values={Object.keys(Periods).map((p) => {
                    return { value: p, label: Periods[parseInt(p)] || "" };
                  })}
                  selected={filters.periods || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, periods: vals })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Period Time Remaining</Form.Label>
                <Slider
                  numSteps={PERIOD_TIME_MAX}
                  value={{
                    min: filters.fromPeriodTime || 0,
                    max: filters.toPeriodTime || PERIOD_TIME_MAX,
                  }}
                  onChange={(v: { min: number; max: number }) =>
                    onFilterChange({
                      ...filters,
                      fromPeriodTime: v.min,
                      toPeriodTime: v.max,
                    })
                  }
                />
                <div>{`${gameClockFormat(
                  filters.fromPeriodTime || 0
                )} - ${gameClockFormat(
                  filters.toPeriodTime || PERIOD_TIME_MAX
                )}`}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Is Playoffs</Form.Label>
                <BooleanInput
                  name="isPlayoffs"
                  value={boolToStringFix(filters.isPlayoffs)}
                  onChange={(v) => {
                    onFilterChange({
                      ...filters,
                      isPlayoffs: stringToBoolFix(v),
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col className={classes.group}>
            <Row>
              <Col>
                <Form.Label>General Shot Type</Form.Label>
                <MultiSelect
                  values={Object.keys(simpleShotTypeLabelMap).map((key) => {
                    return {
                      value: key,
                      label: simpleShotTypeLabelMap[key] || "Unknown",
                    };
                  })}
                  selected={filters.generalShotTypes || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, generalShotTypes: vals })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Specific Shot Type</Form.Label>
                <MultiSelect
                  values={Object.keys(complexShotTypeMap)
                    .filter((key) => {
                      const cst = complexShotTypeMap[key];
                      return cst && cst.parent === undefined;
                    })
                    .map((key) => {
                      const cst = complexShotTypeMap[key];
                      return {
                        value: key,
                        label: cst ? cst.label : "Unknown",
                      };
                    })}
                  selected={filters.specificShotTypes || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, specificShotTypes: vals })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>{"C&S Direction"}</Form.Label>
                <MultiSelect
                  values={Object.entries(shotLabels.direction).map(
                    ([key, val]) => {
                      return {
                        label: val,
                        value: key,
                      };
                    }
                  )}
                  selected={filters.directions || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, directions: vals })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Drive Direction</Form.Label>
                <MultiSelect
                  values={Object.entries(shotLabels.drive_direction).map(
                    ([key, val]) => {
                      return {
                        label: val,
                        value: key,
                      };
                    }
                  )}
                  selected={filters.driveDirections || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, driveDirections: vals })
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className={classes.group}>
            <Row>
              <Form.Label>Off Team</Form.Label>
              <MultiSelect
                values={teams.map((t) => {
                  return {
                    label: `${t.teamcity} ${t.teamname}`,
                    value: t.teamid.toString(),
                  };
                })}
                selected={filters.offTeamIds || []}
                onChange={(vals) =>
                  onFilterChange({ ...filters, offTeamIds: vals })
                }
              />
            </Row>
            <Row>
              <Col>
                <Form.Label>Shooter</Form.Label>
                <MultiSelect
                  values={players
                    .filter((p) => filterPlayers(p, true))
                    .map((p) => {
                      return {
                        label: p.player,
                        value: p.playerId.toString(),
                      };
                    })}
                  selected={filters.shooterIds || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, shooterIds: vals })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Passer</Form.Label>
                <MultiSelect
                  values={players
                    .filter((p) => filterPlayers(p, true))
                    .map((p) => {
                      return {
                        label: p.player,
                        value: p.playerId.toString(),
                      };
                    })}
                  selected={filters.passerIds || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, passerIds: vals })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Position (At Time)</Form.Label>
                <MultiSelect
                  values={Object.keys(Positions).map((p) => {
                    return { value: p, label: Positions[parseInt(p)] || "" };
                  })}
                  selected={filters.shooterPositions || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, shooterPositions: vals })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Position (Typical)</Form.Label>
                <MultiSelect
                  values={Object.keys(Positions).map((p) => {
                    return { value: p, label: Positions[parseInt(p)] || "" };
                  })}
                  selected={filters.shooterTypicalPositions || []}
                  onChange={(vals) =>
                    onFilterChange({
                      ...filters,
                      shooterTypicalPositions: vals,
                    })
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col className={classes.group}>
            <Row>
              <Form.Label>Def Team</Form.Label>
              <MultiSelect
                values={teams.map((t) => {
                  return {
                    label: `${t.teamcity} ${t.teamname}`,
                    value: t.teamid.toString(),
                  };
                })}
                selected={filters.defTeamIds || []}
                onChange={(vals) =>
                  onFilterChange({ ...filters, defTeamIds: vals })
                }
              />
            </Row>
            <Row>
              <Col>
                <Form.Label>Defender</Form.Label>
                <MultiSelect
                  values={players
                    .filter((p) => filterPlayers(p, false))
                    .map((p) => {
                      return {
                        label: p.player,
                        value: p.playerId.toString(),
                      };
                    })}
                  selected={filters.defenderIds || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, defenderIds: vals })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Defender Distance</Form.Label>
                <Slider
                  numSteps={DEF_DIST_MAX * 10}
                  value={{
                    min: (filters.fromDefDist || 0) * 10,
                    max: (filters.toDefDist || DEF_DIST_MAX) * 10,
                  }}
                  onChange={(v: { min: number; max: number }) =>
                    onFilterChange({
                      ...filters,
                      fromDefDist: v.min / 10,
                      toDefDist: v.max / 10,
                    })
                  }
                />
                <div>{`${filters.fromDefDist || 0}ft - ${
                  filters.toDefDist || DEF_DIST_MAX
                }ft`}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Defender Position (At Time)</Form.Label>
                <MultiSelect
                  values={Object.keys(Positions).map((p) => {
                    return { value: p, label: Positions[parseInt(p)] || "" };
                  })}
                  selected={filters.defenderPositions || []}
                  onChange={(vals) =>
                    onFilterChange({ ...filters, defenderPositions: vals })
                  }
                />
              </Col>
              <Col>
                <Form.Label>Defender Position (Typical)</Form.Label>
                <MultiSelect
                  values={Object.keys(Positions).map((p) => {
                    return { value: p, label: Positions[parseInt(p)] || "" };
                  })}
                  selected={filters.defenderTypicalPositions || []}
                  onChange={(vals) =>
                    onFilterChange({
                      ...filters,
                      defenderTypicalPositions: vals,
                    })
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>xPPS</Form.Label>
            <Slider
              numSteps={100 * XPPS_MAX}
              value={{
                min: (filters.fromxpps || 0) * 100,
                max: (filters.toxpps || XPPS_MAX) * 100,
              }}
              onChange={(v: { min: number; max: number }) =>
                onFilterChange({
                  ...filters,
                  fromxpps: v.min / 100,
                  toxpps: v.max / 100,
                })
              }
            />
            <div>{`${filters.fromxpps || 0} - ${
              filters.toxpps || XPPS_MAX
            }`}</div>
          </Col>
          <Col>
            <Form.Label>Shot Distance</Form.Label>
            <Slider
              numSteps={10 * SHOT_DISTANCE_MAX}
              value={{
                min: (filters.fromShotDistance || 0) * 10,
                max: (filters.toShotDistance || SHOT_DISTANCE_MAX) * 10,
              }}
              onChange={(v: { min: number; max: number }) =>
                onFilterChange({
                  ...filters,
                  fromShotDistance: v.min / 10,
                  toShotDistance: v.max / 10,
                })
              }
            />
            <div>{`${filters.fromShotDistance || 0}ft - ${
              filters.toShotDistance || SHOT_DISTANCE_MAX
            }ft`}</div>
          </Col>
          <Col>
            <Form.Label>Shot Clock</Form.Label>
            <Slider
              numSteps={SHOT_CLOCK_MAX * 10}
              value={{
                min: (filters.fromShotClock || 0) * 10,
                max: (filters.toShotClock || SHOT_CLOCK_MAX) * 10,
              }}
              onChange={(v: { min: number; max: number }) =>
                onFilterChange({
                  ...filters,
                  fromShotClock: v.min / 10,
                  toShotClock: v.max / 10,
                })
              }
            />
            <div>{`${filters.fromShotClock || 0}s - ${
              filters.toShotClock || SHOT_CLOCK_MAX
            }s`}</div>
          </Col>
          <Col>
            <Form.Label>Dribbles Before</Form.Label>
            <Slider
              numSteps={DRIBBLES_BEFORE_MAX}
              value={{
                min: filters.fromDribbles || 0,
                max: filters.toDribbles || DRIBBLES_BEFORE_MAX,
              }}
              onChange={(v: { min: number; max: number }) =>
                onFilterChange({
                  ...filters,
                  fromDribbles: v.min,
                  toDribbles: v.max,
                })
              }
            />
            <div>{`${filters.fromDribbles || 0} - ${
              filters.toDribbles || DRIBBLES_BEFORE_MAX
            }`}</div>
          </Col>
          <Col>
            <Form.Label>Inches From Hoop Center </Form.Label>
            <Slider
              numSteps={DISTANCE_FROM_HOOP_MAX}
              value={{
                min: filters.fromDistanceFromHoopCenter || 0,
                max: filters.toDistanceFromHoopCenter || DISTANCE_FROM_HOOP_MAX,
              }}
              onChange={(v: { min: number; max: number }) =>
                onFilterChange({
                  ...filters,
                  fromDistanceFromHoopCenter: v.min,
                  // Set toDistance to undefined if it's the max value otherwise this
                  // will in advertantly filter out shots with NULL distanceFromHoopCenter
                  // shots (notably dunks and banks).
                  toDistanceFromHoopCenter:
                    v.max === DISTANCE_FROM_HOOP_MAX ? undefined : v.max,
                })
              }
            />
            <div>{`${filters.fromDistanceFromHoopCenter || 0} - ${
              filters.toDistanceFromHoopCenter || DISTANCE_FROM_HOOP_MAX
            }`}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>3PT Shot</Form.Label>
            <BooleanInput
              name="isThree"
              value={boolToStringFix(filters.isThree)}
              onChange={(v) => {
                onFilterChange({ ...filters, isThree: stringToBoolFix(v) });
              }}
            />
          </Col>
          <Col>
            <Form.Label>Above the Break</Form.Label>
            <BooleanInput
              name="aboveTheBreak"
              value={boolToStringFix(filters.aboveTheBreak)}
              onChange={(v) => {
                onFilterChange({
                  ...filters,
                  aboveTheBreak: stringToBoolFix(v),
                });
              }}
            />
          </Col>
          <Col>
            <Form.Label>Corner 3</Form.Label>
            <BooleanInput
              name="corner"
              value={boolToStringFix(filters.corner)}
              onChange={(v) => {
                onFilterChange({ ...filters, corner: stringToBoolFix(v) });
              }}
            />
          </Col>
          <Col>
            <Form.Label>Transition</Form.Label>
            <BooleanInput
              name="transition"
              value={boolToStringFix(filters.transition)}
              onChange={(v) => {
                onFilterChange({ ...filters, transition: stringToBoolFix(v) });
              }}
            />
          </Col>
          <Col>
            <Form.Label>Left Side</Form.Label>
            <BooleanInput
              name="leftSide"
              value={boolToStringFix(filters.leftSide)}
              onChange={(v) => {
                onFilterChange({ ...filters, leftSide: stringToBoolFix(v) });
              }}
            />
          </Col>
          <Col>
            <Form.Label>After Oreb 20-24</Form.Label>
            <BooleanInput
              name="oreb2024"
              value={boolToStringFix(filters.oreb2024)}
              onChange={(v) => {
                onFilterChange({ ...filters, oreb2024: stringToBoolFix(v) });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Putback</Form.Label>
            <BooleanInput
              name="putback"
              value={boolToStringFix(filters.putback)}
              onChange={(v) => {
                onFilterChange({ ...filters, putback: stringToBoolFix(v) });
              }}
            />
          </Col>
          <Col>
            <Form.Label>Blocked Shot</Form.Label>
            <BooleanInput
              name="blocked"
              value={boolToStringFix(filters.blocked)}
              onChange={(v) => {
                onFilterChange({ ...filters, blocked: stringToBoolFix(v) });
              }}
            />
          </Col>
          <Col>
            <Form.Label>Fouled</Form.Label>
            <BooleanInput
              name="fouled"
              value={boolToStringFix(filters.fouled)}
              onChange={(v) => {
                onFilterChange({ ...filters, fouled: stringToBoolFix(v) });
              }}
            />
          </Col>
          <Col>
            <Form.Label>Made</Form.Label>
            <BooleanInput
              name="made"
              value={boolToStringFix(filters.made)}
              onChange={(v) => {
                onFilterChange({ ...filters, made: stringToBoolFix(v) });
              }}
            />
          </Col>
          <Col>
            <Form.Label>After Timeout</Form.Label>
            <BooleanInput
              name="afterTimeout"
              value={boolToStringFix(filters.afterTimeout)}
              onChange={(v) => {
                onFilterChange({
                  ...filters,
                  afterTimeout: stringToBoolFix(v),
                });
              }}
            />
          </Col>
          <Col>
            <Form.Label>Contest Level</Form.Label>
            <MultiSelect
              values={Object.keys(secondSpectrumContestLevels).map((c) => {
                return {
                  value: c,
                  label:
                    secondSpectrumContestLevels[
                      c as keyof typeof secondSpectrumContestLevels
                    ],
                };
              })}
              selected={filters.contestLevel || []}
              onChange={(vals) =>
                onFilterChange({ ...filters, contestLevel: vals })
              }
            />
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );
}

function getSeasonTeamMap(p: NbaEaglePlayer) {
  const map: Record<string, Set<string>> = {};
  const seasonTeamArr = p.seasonTeam.split(" ");
  for (const seasonTeam of seasonTeamArr) {
    const year = seasonTeam.split("_")[0] || "";
    const team = seasonTeam.split("_")[1] || "";
    const dataForYear = map[year];
    if (!dataForYear) {
      map[year] = new Set([team]);
    } else {
      dataForYear.add(team);
    }
  }
  return map;
}

// TODO(chrisbu): Remove these functions when the shot explorer is migrated to
// tRPC and no longer use strings for boolean values.
function boolToStringFix(val?: boolean) {
  if (val === undefined) return undefined;
  return val ? "1" : "0";
}

function stringToBoolFix(val?: string | number) {
  if (val === undefined) return undefined;
  return val === "1" || val === 1;
}
