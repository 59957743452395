import React, { ReactNode } from "react";
import { Col, Row } from "react-bootstrap";

import { TeamColorBox } from "../team/TeamColorBox";
import { StatComparisonTableBars } from "../table/StatComparisonTableBars";
import { Shot } from "../../../shared/routers/ShotRouter";
import {
  decFormat,
  decFormat2,
  makePlusMinus,
  intFormat,
} from "../../util/Format";
import { groupBy } from "../../../shared/util/Collections";

export function GamePlayerShootingLuck(props: {
  data: Shot[];
  home: {
    teamid: number;
    teamCity: string;
    team: string;
  };
  away: {
    teamid: number;
    teamCity: string;
    team: string;
  };
}) {
  const { data, home, away } = props;

  const homeTeamId = home.teamid;
  const awayTeamId = away.teamid;

  const compareColorTeam = (value: number, compare: number, key: string) => {
    const isHome = key === homeTeamId.toString();
    const team = isHome ? homeTeamId.toString() : awayTeamId.toString();
    const otherTeam = isHome ? awayTeamId.toString() : homeTeamId.toString();
    if (value >= compare) {
      return team;
    } else {
      return otherTeam;
    }
  };

  function getCompareFormat(compare: number, value: number) {
    return (
      <div>
        <div
          style={{
            fontWeight: 600,
            lineHeight: 1,
            marginBottom: 2,
          }}
        >
          Actual Points:{" "}
          <span style={{ fontWeight: 100 }}>{Math.round(value)}</span>
        </div>
        <div
          style={{
            fontWeight: 600,
            lineHeight: 1,
            marginBottom: 2,
          }}
        >
          Expected Points:{" "}
          <span style={{ fontWeight: 100 }}>{Math.round(compare)}</span>
        </div>
      </div>
    );
  }

  const compareFormat = {
    off: getCompareFormat,
    def: getCompareFormat,
  };

  const homeShots = data.filter((s) => s.oteamId === homeTeamId);
  const homeShotsByPlayer = groupBy(homeShots, (s) => s.shooter);
  const awayShots = data.filter((s) => s.oteamId === awayTeamId);
  const awayShotsByPlayer = groupBy(awayShots, (s) => s.shooter);

  interface Shooter {
    label: string;
    values: { off: number };
    format: (val: number | null) => string;
    domain: number[];
    compare: Record<string, number>;
    compareColorTeam: (value: number, compare: number, key: string) => string;
    compareFormat?: Record<
      string,
      (compare: number, value: number) => ReactNode
    >;
    colorKey: string;
    additionalColumns: {
      numShots: {
        values: { off: number };
        format: (n: number | null) => string;
      };
      diff: {
        values: { off: number };
        format: (n: number | null) => string;
      };
      ePPSLg: {
        values: { off: number };
        format: (n: number | null) => string;
      };
      ePPS: {
        values: { off: number };
        format: (n: number | null) => string;
      };
      PPS: {
        values: { off: number };
        format: (n: number | null) => string;
      };
    };
  }

  let maxPts = 35;
  for (const homeShooter of Object.values(homeShotsByPlayer)) {
    const pts = homeShooter.reduce((cur, prev) => cur + prev.pps, 0);
    if (pts > maxPts) {
      maxPts = pts;
    }
  }
  for (const awayShooter of Object.values(awayShotsByPlayer)) {
    const pts = awayShooter.reduce((cur, prev) => cur + prev.pps, 0);
    if (pts > maxPts) {
      maxPts = pts;
    }
  }

  const pointsDomain = [0, maxPts];

  let homeGroup: Shooter[] = [];
  Object.keys(homeShotsByPlayer).map((player: string) => {
    const playerShots = homeShotsByPlayer[player] || [];
    homeGroup.push({
      label: player,
      format: decFormat,
      domain: pointsDomain,
      values: { off: playerShots.reduce((prev, cur) => prev + cur.pps, 0) },
      compare: { off: playerShots.reduce((prev, cur) => prev + cur.epps, 0) },
      compareColorTeam,
      compareFormat,
      colorKey: homeTeamId.toString(),
      additionalColumns: {
        numShots: {
          format: intFormat,
          values: { off: playerShots.length },
        },
        diff: {
          format: makePlusMinus(decFormat),
          values: {
            off:
              playerShots.reduce((prev, cur) => prev + cur.pps, 0) -
              playerShots.reduce((prev, cur) => prev + cur.epps, 0),
          },
        },
        ePPSLg: {
          format: decFormat2,
          values: {
            off:
              playerShots.reduce((prev, cur) => prev + cur.eppsLeague, 0) /
              playerShots.length,
          },
        },
        ePPS: {
          format: decFormat2,
          values: {
            off:
              playerShots.reduce((prev, cur) => prev + cur.epps, 0) /
              playerShots.length,
          },
        },
        PPS: {
          format: decFormat2,
          values: {
            off:
              playerShots.reduce((prev, cur) => prev + cur.pps, 0) /
              playerShots.length,
          },
        },
      },
    });
  });

  let awayGroup: Shooter[] = [];
  Object.keys(awayShotsByPlayer).map((player: string) => {
    const playerShots = awayShotsByPlayer[player] || [];
    awayGroup.push({
      label: player,
      format: decFormat,
      domain: pointsDomain,
      values: { off: playerShots.reduce((prev, cur) => prev + cur.pps, 0) },
      compare: { off: playerShots.reduce((prev, cur) => prev + cur.epps, 0) },
      compareColorTeam,
      compareFormat,
      colorKey: awayTeamId.toString(),
      additionalColumns: {
        numShots: {
          format: intFormat,
          values: { off: playerShots.length },
        },
        diff: {
          format: makePlusMinus(decFormat),
          values: {
            off:
              playerShots.reduce((prev, cur) => prev + cur.pps, 0) -
              playerShots.reduce((prev, cur) => prev + cur.epps, 0),
          },
        },
        ePPSLg: {
          format: decFormat2,
          values: {
            off:
              playerShots.reduce((prev, cur) => prev + cur.eppsLeague, 0) /
              playerShots.length,
          },
        },
        ePPS: {
          format: decFormat2,
          values: {
            off:
              playerShots.reduce((prev, cur) => prev + cur.epps, 0) /
              playerShots.length,
          },
        },
        PPS: {
          format: decFormat2,
          values: {
            off:
              playerShots.reduce((prev, cur) => prev + cur.pps, 0) /
              playerShots.length,
          },
        },
      },
    });
  });

  function compare(
    a: { additionalColumns: { numShots: { values: { off: number } } } },
    b: { additionalColumns: { numShots: { values: { off: number } } } }
  ) {
    if (
      a.additionalColumns.numShots.values.off >
      b.additionalColumns.numShots.values.off
    )
      return -1;
    if (
      a.additionalColumns.numShots.values.off <
      b.additionalColumns.numShots.values.off
    )
      return 1;
    return 0;
  }

  // Sort by number of shot attempts.
  homeGroup = homeGroup.sort(compare);
  awayGroup = awayGroup.sort(compare);

  const colTitles = [
    <div key={home.teamid}>
      <TeamColorBox teamId={home.teamid} oppTeamId={away.teamid} />
      {home.teamCity} Shooting
    </div>,
    <div key={away.teamid}>
      <TeamColorBox teamId={away.teamid} oppTeamId={home.teamid} />
      {away.teamCity} Shooting
    </div>,
  ];

  return (
    <Row>
      {[homeGroup, awayGroup].map((group, i) => {
        return (
          <Col key={i} md={6}>
            {colTitles[i]}
            <StatComparisonTableBars
              labels={{
                off: "Off.",
              }}
              data={group}
              teams={{
                home: homeTeamId,
                away: awayTeamId,
              }}
              groupColumnLabel={" "}
              valueColumnLabel="Actual"
              columnWidths={[110]}
              additionalColumns={{
                numShots: {
                  width: 95,
                  label: "# of Shots",
                },
                ePPSLg: {
                  width: 75,
                  label: "xPPS Lg",
                },
                ePPS: {
                  width: 70,
                  label: "xPPS",
                },
                PPS: {
                  width: 70,
                  label: "PPS",
                },
                diff: {
                  width: 70,
                  label: "Diff.",
                },
              }}
              noBestColVal={true}
              hideValuesColumn={true}
              hideLabelsColumn={true}
              statColumnLabel="Player"
            />
          </Col>
        );
      })}
    </Row>
  );
}
