import React, { useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Col, Row } from "react-bootstrap";
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
  DelimitedArrayParam,
  QueryParamConfig,
} from "use-query-params";
import { FaFilter } from "react-icons/fa";
import moment from "moment";

import { CELTICS_TEAM_ID } from "../constants/AppConstants";
import AppContext from "../../shared/AppContext";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Tabs } from "../components/core/PageTabs";
import { TooltipItem } from "../components/core/TooltipItem";
import { TeamLogo } from "../components/team/TeamLogo";
import { TeamLastNextNba } from "../components/team/TeamLastNextNba";
import {
  GameFilters,
  GameFiltersInterface,
} from "../components/games/GameFilters";
import { ordinalFormat, plusMinusFormat } from "../util/Format";
import { deepEqual } from "../util/Util";
import { TeamSubPageOverview } from "../subpages/team/TeamSubPageOverview";
import { TeamSubPageOffense } from "../subpages/team/TeamSubPageOffense";
import { TeamSubPageDefense } from "../subpages/team/TeamSubPageDefense";
import { TeamSubPageSchedule } from "../subpages/team/TeamSubPageSchedule";
import { TeamSubPagePersonnel } from "../subpages/team/TeamSubPagePersonnel";
import { TeamScoutingReports } from "../components/team/TeamScoutingReports";
import { DebugInfo } from "../components/core/DebugInfo";
import { trpc } from "../util/tRPC";
import { UserPreferenceContext } from "../UserContext";
import { TeamContext } from "../TeamContext";

const useStyles = makeStyles(() =>
  createStyles({
    headerContainer: {
      minHeight: 115,
    },
    teamPageHeader: {
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      ["@media (max-width:768px)"]: {
        flexDirection: "column",
      },
    },
    teamLogo: {
      position: "absolute",
      top: 10,
      left: -10,
    },
    teamSummary: {
      marginLeft: 80,
      display: "inline-block",
    },
    teamName: {
      marginTop: 18,
      marginBottom: 10,
    },
    headerInlineList: {
      fontSize: "1.2em",
      marginBottom: 9,
      paddingLeft: 0,
      listStyle: "none",
      marginLeft: -5,
      "& li": {
        display: "inline-block",
        paddingLeft: 5,
        paddingRight: 5,
      },
      "& li::after": {
        content: "'•'",
        color: "rgba(255,255,255,.15)",
        marginLeft: 10,
      },
      "& li:last-child::after": {
        content: "''",
        margin: 0,
      },
    },
    scheduleContainer: {
      overflowX: "scroll",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  })
);

export function TeamPage() {
  const { id } = useParams();

  // Page state query params.
  const [queryParams, setQueryParams] = useQueryParams({
    tab: withDefault(StringParam, "overview"),
    scrollTo: StringParam,
  });
  const { tab, scrollTo } = queryParams;

  // Game filter query params.
  const [gameFilters, setGameFilters] = useQueryParams({
    seasonStart: withDefault(StringParam, AppContext.currentSeason),
    seasonEnd: withDefault(StringParam, AppContext.currentSeason),
    preseason: withDefault(NumberParam, AppContext.inPreseason ? 1 : 0),
    regularSeason: withDefault(NumberParam, 1),
    postseason: withDefault(NumberParam, 1),
    home: withDefault(NumberParam, 1),
    away: withDefault(NumberParam, 1),
    wins: withDefault(NumberParam, 1),
    losses: withDefault(NumberParam, 1),
    quarters: withDefault(DelimitedArrayParam, [
      "1",
      "2",
      "3",
      "4",
      "OT",
    ]) as QueryParamConfig<string[]>,
    opponent: withDefault(StringParam, ""),
    games: withDefault(DelimitedArrayParam, []) as QueryParamConfig<string[]>,
  });

  const teamDetails = useContext(TeamContext).teams.find(
    (t) => t.teamid.toString() === id
  );

  const { data: allGames } = trpc.team.getTeamFilteredSchedule.useQuery({
    teamId: id,
  });

  const games = allGames
    ? allGames.filter(
        (g) =>
          g.season >= parseInt(gameFilters.seasonStart) &&
          g.season <= parseInt(gameFilters.seasonEnd)
      )
    : undefined;

  useEffect(() => {
    if (scrollTo) {
      document.querySelector(`#${scrollTo}`)?.scrollIntoView();
    }
  }, [scrollTo]);

  if (!id) return null;

  const celtTeamId = teamDetails && teamDetails.celtTeamId;
  const teamName = teamDetails
    ? `${teamDetails.teamcity} ${teamDetails.teamname}`
    : "";
  const gLeagueName = (teamDetails && teamDetails.gLeagueTeam) || undefined;
  const gLeagueId = (teamDetails && teamDetails.gLeagueTeamId) || undefined;

  const onSubLinkClick = (subLink: string) => {
    const tab = Object.keys(pageTabs.tabs).find((pt) => {
      const tarTab = pageTabs.tabs[pt];
      if (tarTab) {
        return tarTab.sublinks.find((sl) => sl.refId === subLink);
      }
    });
    if (!tab) return;
    setQueryParams({ tab, scrollTo: subLink });
  };

  const filtered = !deepEqual(gameFilters, DEFAULT_FILTERS);
  const pageTabs = getPageTabs(parseInt(id), gameFilters, filtered);

  const isScheduleTab = tab === "schedule";
  // Expand the panel (by default) if there are changes made (from url)
  // show selected games if we are filtering by selected games
  const gameFiltersVisible = filtered;
  const gameSelectorVisible = !!gameFilters.games.length;

  const onChange = (
    key: string,
    newVal: string | number | null | string[] | number[]
  ) => {
    // Make sure we are never creating bad season start/end pairs.
    if (key === "seasonStart" || key === "seasonEnd") {
      const seasonEnd =
        key === "seasonStart"
          ? parseInt(gameFilters.seasonEnd)
          : parseInt(newVal as string);
      const seasonStart =
        key === "seasonStart"
          ? parseInt(newVal as string)
          : parseInt(gameFilters.seasonStart);
      if (seasonStart > seasonEnd || seasonEnd < seasonStart) {
        setGameFilters({
          seasonStart: newVal as string,
          seasonEnd: newVal as string,
        });
      } else {
        setGameFilters({ [key]: newVal });
      }
    } else {
      // For other updates just do the update.
      setGameFilters({ [key]: newVal });
    }
  };

  return (
    <Page
      header={{
        component: (
          <TeamPageHeader
            teamId={id}
            teamName={teamName}
            celtTeamId={celtTeamId}
            gLeagueName={gLeagueName}
            gLeagueId={gLeagueId}
            gameFilters={gameFilters}
          />
        ),
      }}
      title={teamName}
      tabs={pageTabs}
      activeTab={tab}
      onTabClick={(newTab) => setQueryParams({ tab: newTab })}
      onSubLinkClick={(subLink) => onSubLinkClick(subLink)}
    >
      <Row>
        <Col md={12}>
          {tab !== "personnel" && (
            <Panel
              header={
                <div>
                  Game Filters <FaFilter />
                </div>
              }
              defaultExpanded={gameFiltersVisible}
            >
              {games && (
                <GameFilters
                  teamId={parseInt(id)}
                  onChange={onChange}
                  onReset={() => setGameFilters(DEFAULT_FILTERS)}
                  filters={gameFilters}
                  hideQuartersFilter={isScheduleTab}
                  hideGameSelectorToggle={isScheduleTab}
                  games={games.filter((ts) =>
                    moment(ts.gameDateTime).isSameOrBefore(new Date())
                  )}
                  defaultGameSelectorVisible={gameSelectorVisible}
                />
              )}
            </Panel>
          )}
        </Col>
      </Row>
    </Page>
  );
}

function getPageTabs(
  teamId: number,
  gameFilters: GameFiltersInterface,
  filtered: boolean
): Tabs {
  // Some components compare team data against the league. When comparing to the
  // rest of the league these filters are used for the "league" data. Additional
  // filters that are team-only may be applied later on.
  const leagueFilters = {
    seasonStart: gameFilters.seasonStart,
    seasonEnd: gameFilters.seasonEnd,
    preseason: gameFilters.preseason,
    regularSeason: gameFilters.regularSeason,
    postseason: gameFilters.postseason,
    quarters: gameFilters.quarters.join(","),
  };

  const { data: leagueShooting } =
    trpc.league.getLeagueShooting.useQuery(leagueFilters);

  // These are the filters that are team only and should not apply to the league
  // comparisons.
  const teamOnlyFilters =
    gameFilters.games.length > 0
      ? {
          teamId: teamId.toString(),
          gameIds: gameFilters.games.join(","),
        }
      : {
          teamId: teamId.toString(),
          opponent: gameFilters.opponent,
          home: gameFilters.home,
          away: gameFilters.away,
          wins: gameFilters.wins,
          losses: gameFilters.losses,
        };

  const teamFilters = { ...leagueFilters, ...teamOnlyFilters };

  const { data: teamShooting } =
    trpc.team.getTeamShooting.useQuery(teamFilters);

  // Overview.
  const { data: leagueTeamStats } =
    trpc.team.getTeamStats.useQuery(leagueFilters);

  const { data: teamStats } = trpc.team.getTeamStats.useQuery(teamFilters);

  const { data: rosteredPlayers } = trpc.roster.getSeasonRosters.useQuery();

  const { data: playerBoxes } =
    trpc.team.getTeamRosterGameBoxesPlayer.useQuery(teamFilters);

  const { data: impact } = trpc.impact.getTeamImpact.useQuery({
    teamId: teamId,
  });

  const { data: teamActions } =
    trpc.team.getTeamActionsBreakdown.useQuery(teamFilters);

  const { data: leagueActions } =
    trpc.team.getTeamActionsBreakdown.useQuery(leagueFilters);

  // Offense.
  const offfShootingLeague = leagueShooting
    ? leagueShooting.filter((ls) => ls.statType === "OFF")
    : undefined;
  const offShootingTeam = teamShooting
    ? teamShooting.filter((ts) => ts.statType === "OFF")
    : undefined;
  const offTeamActions = teamActions
    ? teamActions.filter((ta) => ta.offDef === "OFF")
    : undefined;
  const offLeagueActions = leagueActions
    ? leagueActions.filter((la) => la.offDef === "OFF")
    : undefined;

  const { data: offTeamStartTypeData } =
    trpc.chance.getChanceEfficiencyPerStartType2.useQuery({
      isOffense: true,
      filters: teamFilters,
    });

  const { data: offLeagueStartTypeData } =
    trpc.chance.getChanceEfficiencyPerStartType2.useQuery({
      isOffense: true,
      filters: leagueFilters,
    });

  const { data: passesToGreatShots } =
    trpc.team.getTeamPassesToGreatShots.useQuery(teamFilters);

  const { data: runs } = trpc.team.getTeamRunningTheFloor.useQuery({
    teamId,
  });

  const { data: touches } = trpc.team.getTeamTouches.useQuery({ teamId });

  const { data: freeThrows } = trpc.team.getTeamFreeThrows.useQuery({
    teamId: teamId,
  });

  const { data: threePointShooting } =
    trpc.team.getTeamThreePointShooting.useQuery({
      teamId: teamId.toString(),
      seasonStart: leagueFilters.seasonStart,
      seasonEnd: leagueFilters.seasonEnd,
    });

  // Defense.
  const defShootingLeague = leagueShooting
    ? leagueShooting.filter((ls) => ls.statType === "DEF")
    : undefined;
  const defShootingTeam = teamShooting
    ? teamShooting.filter((ts) => ts.statType === "DEF")
    : undefined;

  const defTeamActions = teamActions
    ? teamActions.filter((ta) => ta.offDef === "DEF")
    : undefined;
  const defLeagueActions = leagueActions
    ? leagueActions.filter((la) => la.offDef === "DEF")
    : undefined;

  const { data: charges } = trpc.team.getTeamCharges.useQuery({
    teamId: teamId,
  });

  const { data: defTeamStartTypeData } =
    trpc.chance.getChanceEfficiencyPerStartType2.useQuery({
      isOffense: false,
      filters: teamFilters,
    });

  const { data: defLeagueStartTypeData } =
    trpc.chance.getChanceEfficiencyPerStartType2.useQuery({
      isOffense: false,
      filters: leagueFilters,
    });

  const { data: pnrDef } = trpc.team.getTeamPnrDefense.useQuery(teamFilters);

  // Schedule.
  const { data: schedule } = trpc.team.getTeamFilteredSchedule.useQuery({
    // Need to use some custom filters here, we want league filters and team
    // filters but we don't want the team version that is using gameIds.
    ...leagueFilters,
    teamId: teamId.toString(),
    opponent: gameFilters.opponent,
    home: gameFilters.home,
    away: gameFilters.away,
    wins: gameFilters.wins,
    losses: gameFilters.losses,
  });

  // Personnel.
  const { data: personnel } = trpc.team.getTeamPersonnel.useQuery({ teamId });

  const pageTabs = {
    tabs: {
      overview: {
        label: "Overview",
        sublinks: [
          { label: "Stat Rankings", refId: "statRankings" },
          { label: "Depth Chart", refId: "depthChart" },
          { label: "Box Scores", refId: "boxScores" },
          { label: "Impact", refId: "impact" },
          { label: "Scout Ratings", refId: "scoutRatings" },
        ],
        content: (
          <div>
            <TeamSubPageOverview
              teamId={teamId}
              teamStats={teamStats}
              leagueStats={leagueTeamStats}
              rosteredPlayers={rosteredPlayers}
              playerBoxes={playerBoxes}
              impact={impact}
              shouldShowFilterMsg={
                !!(
                  gameFilters.games.length > 0 ||
                  gameFilters.opponent ||
                  gameFilters.home !== gameFilters.away ||
                  gameFilters.wins !== gameFilters.losses
                )
              }
              filtered={filtered}
            />
            <DebugInfo teamId={teamId} />
          </div>
        ),
      },
      offense: {
        label: "Offense",
        sublinks: [
          { label: "Shooting", refId: "shootingOffense" },
          { label: "Offensive Breakdown", refId: "offensiveBreakdown" },
          { label: "3Pt Shooting", refId: "threePointShooting" },
          { label: "Whom to Foul", refId: "whomToFoul" },
          { label: "Touches", refId: "touches" },
          { label: "Passes To Great Shots", refId: "passesToGreatShots" },
          { label: "Running the Floor", refId: "runningFloor" },
          { label: "Efficiency By Start Type", refId: "effByStartType" },
        ],
        content: (
          <div>
            <TeamSubPageOffense
              teamId={teamId}
              leagueShooting={offfShootingLeague}
              teamShooting={offShootingTeam}
              teamActions={offTeamActions}
              leagueActions={offLeagueActions}
              teamStartTypeData={offTeamStartTypeData}
              leagueStartTypeData={offLeagueStartTypeData}
              threePointShooting={threePointShooting}
              passesToGreatShots={passesToGreatShots}
              freeThrows={freeThrows}
              touches={touches}
              runs={runs}
              filteredToSpecificGames={gameFilters.games.length > 0}
              filtered={filtered}
            />
            <DebugInfo teamId={teamId} />
          </div>
        ),
      },

      defense: {
        label: "Defense",
        sublinks: [
          { label: "Shooting", refId: "shootingDefense" },
          { label: "Defensive Breakdown", refId: "defensiveBreakdown" },
          { label: "PNR Defense", refId: "pnrDefense" },
          { label: "Charges", refId: "charges" },
          { label: "Efficiency By Start Type", refId: "effByStartType" },
        ],
        content: (
          <div>
            <TeamSubPageDefense
              teamId={teamId}
              teamShooting={defShootingTeam}
              leagueShooting={defShootingLeague}
              teamActions={defTeamActions}
              leagueActions={defLeagueActions}
              charges={charges}
              pnrDef={pnrDef}
              teamStartTypeData={defTeamStartTypeData}
              leagueStartTypeData={defLeagueStartTypeData}
              filteredToSpecificGames={gameFilters.games.length > 0}
              filtered={filtered}
            />
            <DebugInfo teamId={teamId} />
          </div>
        ),
      },

      schedule: {
        label: "Schedule",
        sublinks: [{ label: "Team Schedule", refId: "teamSchedule" }],
        content: (
          <div>
            <TeamSubPageSchedule
              teamId={teamId}
              schedule={schedule}
              filtered={filtered}
            />
            <DebugInfo teamId={teamId} />
          </div>
        ),
      },

      personnel: {
        label: "Personnel",
        sublinks: [{ label: "Personnel Reports", refId: "personnel" }],
        content: (
          <div>
            <TeamSubPagePersonnel personnel={personnel} />
            <DebugInfo teamId={teamId} />
          </div>
        ),
      },
    },
  };

  return pageTabs;
}

function TeamPageHeader(props: {
  teamId?: string;
  teamName?: string;
  celtTeamId?: number;
  gLeagueName?: string;
  gLeagueId?: number;
  gameFilters: GameFiltersInterface;
}) {
  const logoBlur = !!useContext(UserPreferenceContext)["Logo Blur"];
  const teamId = props.teamId ? parseInt(props.teamId) : 0;

  const teams = useContext(TeamContext).teams;
  const schedule = (
    useContext(TeamContext).schedule[props.teamId || ""] || []
  ).sort((a, b) => a.gameDateTime.localeCompare(b.gameDateTime));

  const classes = useStyles();
  const teamName = props.teamName || "";

  let winLoss;
  let conferenceRank;
  const teamStats = teams.find((t) => t.teamid === teamId);
  if (teamStats) {
    winLoss = `${teamStats.Wins}-${teamStats.Losses}`;
    conferenceRank = `${ordinalFormat(
      teamStats.playoffRank
    )} ${teamStats.Conference.substr(0, 4)}`;
  }

  let pointDifferential;

  if (teamStats && teamStats.netppp !== null) {
    pointDifferential = plusMinusFormat(100 * teamStats.netppp);
  }

  const header = (
    <div className={classes.headerContainer}>
      <div className={classes.teamPageHeader}>
        <TeamLogo idsId={teamId} className={classes.teamLogo} blur={logoBlur} />
        <div className={classes.teamSummary}>
          <h1 className={classes.teamName}>{teamName}</h1>
          <ul className={classes.headerInlineList}>
            <li>{conferenceRank}</li>
            <li>{winLoss}</li>
            <TooltipItem arrow={false} tooltip="Net Point Differential">
              {pointDifferential}
            </TooltipItem>
          </ul>
          <ul className={classes.headerInlineList}>
            <li>
              <TeamScoutingReports teamName={teamName} teamId={teamId} />
            </li>
            <li>
              <Link
                to={`/team/ml/${props.celtTeamId}?league=nba&season=${
                  parseInt(AppContext.currentSeason) - 1
                }`}
              >
                Historical
              </Link>
            </li>
            {props.gLeagueId && (
              <li>
                <Link to={`/team/ml/${props.gLeagueId}`}>
                  {props.gLeagueName}
                </Link>
              </li>
            )}
            {teamId === CELTICS_TEAM_ID ? (
              <li>
                <a
                  href={
                    "https://app.powerbi.com/Redirect?action=OpenApp&appId=33ddcc05-29a7-4876-8f00-6d0a783ebe16&ctid=15b7f6f7-79dd-4767-a7a9-98e07f481438"
                  }
                >
                  Performance Dashboard
                </a>
              </li>
            ) : null}
          </ul>
        </div>
        {schedule && (
          <div className={classes.scheduleContainer}>
            <TeamLastNextNba teamId={teamId} schedule={schedule} />
          </div>
        )}
      </div>
    </div>
  );

  return header;
}

const DEFAULT_FILTERS: GameFiltersInterface = {
  seasonStart: AppContext.currentSeason,
  seasonEnd: AppContext.currentSeason,
  preseason: AppContext.inPreseason ? 1 : 0,
  regularSeason: 1,
  postseason: 1,
  home: 1,
  away: 1,
  wins: 1,
  losses: 1,
  quarters: ["1", "2", "3", "4", "OT"],
  opponent: "",
  games: [],
};
