import React from "react";
import { ParentSize } from "@visx/responsive";
import { Group } from "@visx/group";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleLinear, scaleBand } from "@visx/scale";
import { Text } from "@visx/text";
import { Line } from "@visx/shape";

import { AllPlayersTableColumns } from "./AllPlayersTableColumns";
import {
  Position,
  Positions,
  measurementRangeLabels,
} from "../../constants/AppConstants";
import { lineChartColorArray } from "../../constants/ColorConstants";

const BOX_AND_WHISKER_COLOR = "#606060";
const Y_AXIS_COLOR = "rgba(0,0,0,.4)";

export function BoxPlotByPosition(props: {
  highlightedIndex: number | null;
  mapKey: string;
  values: number[];
  measurementRangesByPos: Record<Position, number[]>;
}) {
  const { highlightedIndex, mapKey, values, measurementRangesByPos } = props;

  return (
    <ParentSize parentSizeStyles={{ width: "100%" }}>
      {({ width }) =>
        width > 0 && (
          <BoxPlotByPositionInner
            highlightedIndex={highlightedIndex}
            mapKey={mapKey}
            values={values}
            measurementRangesByPos={measurementRangesByPos}
            width={width}
            height={width}
          />
        )
      }
    </ParentSize>
  );
}

function BoxPlotByPositionInner(props: {
  highlightedIndex: number | null;
  mapKey: string;
  values: number[];
  measurementRangesByPos: Record<Position, number[]>;
  width: number;
  height: number;
}) {
  const {
    highlightedIndex,
    mapKey,
    values,
    measurementRangesByPos,
    width,
    height,
  } = props;

  const margin = { top: 30, right: 5, bottom: 30, left: 40 };

  const innerHeight = height - (margin.top + margin.bottom);
  const innerWidth = width - (margin.left + margin.right);

  const data = Object.values(Positions).map((pos: Position) => {
    const vals = measurementRangesByPos[pos];
    const fifthPercentile = vals[1];
    const twentyFifthPercentile = vals[2];
    const seventyFifthPercentile = vals[4];
    const ninetyFifthPercentile = vals[5];

    return {
      pos,
      min: fifthPercentile,
      max: ninetyFifthPercentile,
      boxMin: twentyFifthPercentile,
      boxMax: seventyFifthPercentile,
    };
  });

  const min = Math.min(
    ...values.filter((v) => !!v),
    ...data.map((d) => d.min || 0)
  );
  const max = Math.max(...values, ...data.map((d) => d.max || 0));

  const xScale = scaleBand({
    range: [0, innerWidth],
    domain: Object.values(Positions),
  });

  const yScale = scaleLinear({
    range: [innerHeight, 0],
    domain: [min, max],
    nice: true,
  });

  return (
    <div style={{ position: "relative" }}>
      <svg width={width} height={height}>
        <Text
          fill={Y_AXIS_COLOR}
          dx={innerWidth / 2}
          dy={margin.top / 2}
          verticalAnchor="middle"
        >
          {measurementRangeLabels[mapKey]}
        </Text>
        {highlightedIndex !== null && values[highlightedIndex] ? (
          <Text
            fill={Y_AXIS_COLOR}
            dx={innerWidth / 2}
            dy={margin.top}
            verticalAnchor="middle"
          >
            {AllPlayersTableColumns[mapKey].renderer(values[highlightedIndex])}
          </Text>
        ) : (
          ""
        )}
        <Group left={margin.left} top={margin.top}>
          <AxisLeft
            scale={yScale}
            numTicks={4}
            tickLabelProps={() => ({
              fill: Y_AXIS_COLOR,
              textAnchor: "end",
              dy: ".25em",
            })}
            hideTicks={true}
            hideAxisLine={true}
          />
          <AxisBottom
            scale={xScale}
            top={innerHeight}
            tickLabelProps={() => ({
              fill: BOX_AND_WHISKER_COLOR,
              dy: ".25em",
              textAnchor: "middle",
            })}
            hideTicks={true}
            hideAxisLine={true}
          />
          {data.map((d, i) => {
            return (
              <Group key={i}>
                {/* The Whisker */}
                <Line
                  stroke={BOX_AND_WHISKER_COLOR}
                  from={{
                    x: (xScale(d.pos) || 0) + xScale.bandwidth() / 2,
                    y: yScale(d.min || 0),
                  }}
                  to={{
                    x: (xScale(d.pos) || 0) + +xScale.bandwidth() / 2,
                    y: yScale(d.max || 0),
                  }}
                  strokeWidth={2}
                />
                {/* The Box */}
                <Line
                  stroke={BOX_AND_WHISKER_COLOR}
                  from={{
                    x: (xScale(d.pos) || 0) + xScale.bandwidth() / 2,
                    y: yScale(d.boxMin || 0),
                  }}
                  to={{
                    x: (xScale(d.pos) || 0) + xScale.bandwidth() / 2,
                    y: yScale(d.boxMax || 0),
                  }}
                  strokeWidth={6}
                />
              </Group>
            );
          })}
          {values.map((v, i) => {
            const val = yScale(v);
            if (!val) return null;
            return (
              <Line
                key={i}
                from={{ x: 0, y: val }}
                to={{ x: innerWidth, y: val }}
                stroke={lineChartColorArray[i]}
                strokeWidth={highlightedIndex === i ? 2 : 1}
              />
            );
          })}
        </Group>
      </svg>
    </div>
  );
}
