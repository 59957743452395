import React, { useCallback, useMemo } from "react";
import { ParentSize } from "@visx/responsive";
import { scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import { Circle, Line } from "@visx/shape";
import { Text } from "@visx/text";
import { extent } from "d3";
import { useNavigate } from "react-router-dom";
import { localPoint } from "@visx/event";
import { useTooltip, TooltipWithBounds, defaultStyles } from "@visx/tooltip";
import { EventType } from "@visx/event/lib/types";

import { LeagueImpact } from "../../../shared/routers/ImpactRouter";
import { dec100Format, seFormat } from "../../util/Format";

const POINT_COLOR = "rgba(0,0,0,.1)";
const HOVER_POINT_COLOR = "rgba(0,0,0,.7)";
const FOCUS_POINT_COLOR = "#139B91";
const FOCUS_95_COLOR = "#A6E2DD";
const FOCUS_50_COLOR = "#6AC5BD";
const TEXT_COLOR = "#999999";

export function ImpactScatterPlot(props: {
  focus: number;
  data: LeagueImpact[];
}) {
  return (
    <ParentSize>
      {({ width }) => <InnerChart width={width} {...props} />}
    </ParentSize>
  );
}

function InnerChart(props: {
  focus: number;
  data: LeagueImpact[];
  width: number;
}) {
  const navigate = useNavigate();
  const { width, focus, data } = props;
  const margin = { top: 10, right: 10, bottom: 10, left: 10 };
  const height = width;
  const innerHeight = height - (margin.top + margin.bottom);
  const innerWidth = width - (margin.left + margin.right);

  const circleRadius = Math.max(1, 0.01 * width);

  const xScale = scaleLinear<number>({
    domain: extent(data, (d) => d.offImpact) as number[],
    range: [margin.left, innerWidth],
  });

  const yScale = scaleLinear<number>({
    domain: extent(data, (d) => d.defImpact) as number[],
    range: [innerHeight, margin.top],
  });

  const getMousePoint = (event: EventType) => {
    const { x, y } = localPoint(event) || { x: 0, y: 0 };
    return { x: x - margin.left, y: y - margin.top };
  };

  const points = useMemo(() => {
    return data.map((d) => ({
      x: xScale(d.offImpact),
      y: yScale(d.defImpact),
      data: d,
    }));
  }, [xScale, yScale, data]);

  const {
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    showTooltip,
    hideTooltip,
  } = useTooltip<LeagueImpact>();

  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    color: "black",
  };

  const handleClick = useCallback(
    (event: EventType) => {
      const { x, y } = getMousePoint(event);
      for (const point of points) {
        if (
          Math.abs(point.x - x) < circleRadius &&
          Math.abs(point.y - y) < circleRadius
        ) {
          navigate(`/player/${point.data.playerId}`);
          return;
        }
      }
    },
    [points]
  );

  const handleTooltip = useCallback(
    (event: EventType) => {
      const { x, y } = getMousePoint(event);
      for (const point of points) {
        if (
          Math.abs(point.x - x) < circleRadius &&
          Math.abs(point.y - y) < circleRadius
        ) {
          showTooltip({
            tooltipData: point.data,
            tooltipLeft: point.x,
            tooltipTop: point.y,
          });
          return;
        }
      }
      hideTooltip();
    },
    [points]
  );

  const getPointColor = (p: { x: number; y: number; data: LeagueImpact }) => {
    if (p.data.playerId === focus) return FOCUS_POINT_COLOR;
    else if (tooltipData && tooltipData.playerId === p.data.playerId) {
      return HOVER_POINT_COLOR;
    } else {
      return POINT_COLOR;
    }
  };

  if (width === 0 || height === 0) return null;

  return (
    <div style={{ position: "relative" }}>
      <svg width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          <Text dx={0} dy={yScale(0) - 4} fill={TEXT_COLOR}>
            OFF
          </Text>
          <Line
            from={{ x: 0, y: yScale(0) }}
            to={{ x: innerWidth, y: yScale(0) }}
            stroke={"black"}
            opacity={0.15}
          />
          <Text dx={xScale(0) - 32} dy={innerHeight} fill={TEXT_COLOR}>
            DEF
          </Text>
          <Line
            from={{ x: xScale(0), y: 0 }}
            to={{ x: xScale(0), y: innerHeight }}
            stroke={"black"}
            opacity={0.15}
          />
          {points.map((p, i) => {
            if (p.data.playerId === focus) {
              // Draw the SE circles (inner circle = 1.177*se 50% CI, outer
              // circle = 2.448*se 95% CI). These calculations come from BIA1.
              const rx95 = Math.abs(
                xScale(p.data.offImpact + p.data.offSE * 2.448) - p.x
              );
              const ry95 = Math.abs(
                yScale(p.data.defImpact + p.data.defSE * 2.448) - p.y
              );
              const rx50 = Math.abs(
                xScale(p.data.offImpact + p.data.offSE * 1.177) - p.x
              );
              const ry50 = Math.abs(
                yScale(p.data.defImpact + p.data.defSE * 1.177) - p.y
              );
              return (
                <Group key={i}>
                  <ellipse
                    cx={p.x}
                    cy={p.y}
                    rx={rx95}
                    ry={ry95}
                    fill={FOCUS_95_COLOR}
                    opacity={0.8}
                  />
                  <ellipse
                    cx={p.x}
                    cy={p.y}
                    rx={rx50}
                    ry={ry50}
                    fill={FOCUS_50_COLOR}
                    opacity={0.8}
                  />

                  <Circle
                    cx={p.x}
                    cy={p.y}
                    r={circleRadius}
                    fill={getPointColor(p)}
                  />
                </Group>
              );
            }

            return (
              <Circle
                key={i}
                cx={p.x}
                cy={p.y}
                r={circleRadius}
                fill={getPointColor(p)}
              />
            );
          })}

          <rect
            width={innerWidth}
            height={innerHeight}
            onTouchStart={handleTooltip}
            fill={"transparent"}
            onClick={handleClick}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
        </Group>
      </svg>
      {tooltipData && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <div>
            <div>
              <b>
                {tooltipData.player} {dec100Format(tooltipData.netImpact)}
              </b>
            </div>
            <div>
              {`O: ${dec100Format(tooltipData.offImpact)} D: ${dec100Format(
                tooltipData.defImpact
              )} SE: ${seFormat(tooltipData.netSE * 100 * 1.96)}`}
            </div>
          </div>
        </TooltipWithBounds>
      )}
    </div>
  );
}
