import React from "react";
import { Col, Row } from "react-bootstrap";

import { Panel } from "../../components/core/Panel";
import { TeamSchedule } from "../../../shared/routers/TeamRouter";
import { TeamScheduleTable } from "../../components/team/TeamScheduleTable";

export function GameSubPageSchedule(props: {
  gameId: number;
  home?: { teamid: number; team: string; teamName: string; teamCity: string };
  away?: { teamid: number; team: string; teamName: string; teamCity: string };
  homeSchedule?: TeamSchedule[];
  awaySchedule?: TeamSchedule[];
}) {
  const { gameId, home, away, homeSchedule, awaySchedule } = props;

  const homeCity = home ? home.teamCity : "Unknown";
  const homeTeamId = home ? home.teamid : 0;
  const awayCity = away ? away.teamCity : "Unknown";
  const awayTeamId = away ? away.teamid : 0;

  return (
    <div>
      <Row>
        <Col sm={6}>
          <Panel header={`${awayCity} Games`} panelID={"awaySchedule"}>
            {awaySchedule && (
              <TeamScheduleTable
                data={awaySchedule}
                teamId={awayTeamId}
                showNext={false}
                highlightGameId={gameId}
              />
            )}
          </Panel>
        </Col>
        <Col sm={6}>
          <Panel header={`${homeCity} Games`} panelID={"homeSchedule"}>
            {homeSchedule && (
              <TeamScheduleTable
                data={homeSchedule}
                teamId={homeTeamId}
                showNext={false}
                highlightGameId={gameId}
              />
            )}
          </Panel>
        </Col>
      </Row>
    </div>
  );
}
