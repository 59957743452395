import React, { useState } from "react";
import { scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import { GridColumns } from "@visx/grid";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { localPoint } from "@visx/event";
import { Circle, Line } from "@visx/shape";
import { Text } from "@visx/text";
import { EventType } from "@visx/event/lib/types";

import { ScoutDraftRanking } from "../../../shared/routers/DraftRouter";
import { optionB } from "../../constants/ChartConstants";
import { generateDomain } from "../../util/Util";
import { groupBy } from "../../../shared/util/Collections";

export function DraftRankingChart(props: {
  width: number;
  rankers: string[];
  rankingsByPlayer: Record<string, ScoutDraftRanking[]>;
}) {
  const { width, rankers, rankingsByPlayer: rankingsByPlayer } = props;
  const [hover, setHover] = useState<number | null>(null);

  function byAvgRanking(
    a: [string, ScoutDraftRanking[]],
    b: [string, ScoutDraftRanking[]]
  ) {
    return avgRanking(a[1]) - avgRanking(b[1]);
  }

  function avgRanking(rankings: ScoutDraftRanking[]) {
    const numUnRanked = rankers.length - rankings.length;
    return (
      (rankings.reduce((sum, r) => sum + r.Rank, 0) + 61 * numUnRanked) /
      rankers.length
    );
  }

  function highestRank(rankings: ScoutDraftRanking[]) {
    return Math.min(...rankings.map((r) => r.Rank));
  }

  function lowestRank(rankings: ScoutDraftRanking[]) {
    if (rankings.length < rankers.length) return 60;
    return Math.max(...rankings.map((r) => r.Rank));
  }

  const sortedData = Object.entries(rankingsByPlayer).sort(byAvgRanking);

  const colorScale = scaleLinear<string>()
    .domain(generateDomain(61, 0, optionB.length))
    .range(optionB);

  const height = 1000;
  const margin = { left: 150, right: 20, top: 20, bottom: 50 };

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const xScale = scaleLinear<number>({
    domain: [1, 61],
    range: [0, innerWidth],
  });

  const yScale = scaleLinear<number>({
    domain: [sortedData.length - 1, 0],
    range: [innerHeight, 0],
  });

  // For hovered row:
  const hoveredRow = hover === null ? null : sortedData[hover];
  const color = hoveredRow ? colorScale(avgRanking(hoveredRow[1])) : "#EDF2F7";
  const uniqueRanks = hoveredRow
    ? groupBy(hoveredRow[1], (r) => r.Rank.toString())
    : {};
  // Add the NRs.
  if (hoveredRow && Object.keys(uniqueRanks).length > 0) {
    const noRanks = rankers
      .filter((r) => hoveredRow[1].find((h) => h.Ranker === r) === undefined)
      .map((r) => ({ Ranker: r, Rank: 61, Year: 0 } as ScoutDraftRanking));
    if (noRanks.length) {
      uniqueRanks["61"] = noRanks;
    }
  }
  const aboveBelowMulti =
    hover === null || hover < sortedData.length / 2 ? 1 : -1;

  const handleMouseMove = (e: EventType) => {
    const local = localPoint(e);
    if (local) {
      const val = Math.round(yScale.invert(local.y - margin.top));
      if (val >= 0 && val <= sortedData.length - 1) {
        setHover(val);
      } else {
        setHover(null);
      }
    } else {
      setHover(null);
    }
  };

  return (
    <div>
      <div>
        <svg width={width} height={height}>
          <Group left={margin.left} top={margin.top}>
            <GridColumns
              scale={xScale}
              width={innerWidth}
              height={innerHeight}
              stroke="#EDF2F7"
              strokeOpacity={1}
            />
            <AxisLeft
              scale={yScale}
              tickFormat={(i) => {
                const idx = i as number;
                const dataAtIdx = sortedData[idx];
                const playerName = dataAtIdx ? dataAtIdx[0] : "Unknown";
                return `${playerName} #${idx + 1}`;
              }}
              numTicks={sortedData.length}
              tickLabelProps={(d) => ({
                opacity: d === hover || hover === null ? 1 : 0.2,
                textAnchor: "end",
                fontFamily: "Arial",
                fontSize: 10,
                dx: "-0.25em",
                dy: "0.25em",
              })}
              hideTicks={true}
              hideAxisLine={true}
            />
            <AxisBottom
              scale={xScale}
              top={innerHeight}
              label="Average Rank"
              hideTicks={true}
              hideAxisLine={true}
            />
            <AxisBottom
              scale={xScale}
              top={-margin.top * 1.5}
              hideTicks={true}
              hideAxisLine={true}
            />
            {sortedData.map((p, i) => {
              const color = colorScale(avgRanking(p[1]));
              const opacity = hover === null || hover === i ? 1 : 0.2;
              return (
                <>
                  <Line
                    from={{ x: xScale(highestRank(p[1])), y: yScale(i) }}
                    to={{ x: xScale(lowestRank(p[1])), y: yScale(i) }}
                    stroke={color}
                    opacity={opacity}
                  />
                  <Circle
                    r={5}
                    cx={xScale(avgRanking(p[1]))}
                    cy={yScale(i)}
                    fill={color}
                    opacity={opacity}
                  />
                </>
              );
            })}
            {hoveredRow !== null && hover !== null && (
              <>
                <Line
                  from={{
                    x: xScale(highestRank(hoveredRow ? hoveredRow[1] : [])),
                    y: yScale(hover),
                  }}
                  to={{
                    x: xScale(lowestRank(hoveredRow ? hoveredRow[1] : [])),
                    y: yScale(hover),
                  }}
                  stroke={color}
                />
                <Circle
                  r={5}
                  cx={xScale(avgRanking(hoveredRow ? hoveredRow[1] : []))}
                  cy={yScale(hover)}
                  fill={color}
                  stroke="black"
                />
                {Object.keys(uniqueRanks).map((rank, j) => (
                  <>
                    {rank !== "61" && (
                      <Circle
                        key={j}
                        r={3}
                        cx={xScale(parseInt(rank))}
                        cy={yScale(hover)}
                        fill={color}
                      />
                    )}
                    {(uniqueRanks[rank] || []).map((r, k) => (
                      <Text
                        key={k}
                        dx={xScale(parseInt(rank)) - 12}
                        dy={yScale(hover) + aboveBelowMulti * 12 * (k + 2)}
                      >
                        {r.Ranker}
                      </Text>
                    ))}
                    <Text
                      dx={xScale(parseInt(rank)) - 12}
                      dy={yScale(hover) + aboveBelowMulti * 12}
                    >
                      {rank === "61" ? "--" : `#${rank}`}
                    </Text>
                  </>
                ))}
              </>
            )}
          </Group>
          {/* Put this outside so we can get mouse movement over Y axis. */}
          <rect
            x={0}
            y={margin.top}
            width={width}
            height={innerHeight}
            fill={"transparent"}
            onMouseMove={handleMouseMove}
            onTouchMove={handleMouseMove}
            onMouseLeave={() => setHover(null)}
          />
        </svg>
      </div>
    </div>
  );
}
