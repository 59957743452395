import React from "react";
import { Button } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";

export function DeleteButton(props: { onClick: () => void }) {
  const { onClick } = props;

  return (
    <Button
      onClick={() => onClick()}
      variant="danger"
      style={{
        lineHeight: 0,
        padding: 3,
        fontSize: "0.7em",
        verticalAlign: "top",
      }}
    >
      <IoMdClose />
    </Button>
  );
}
