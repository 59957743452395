import React, { ReactNode, useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import moment from "moment";

import { ScoutRating } from "../../../shared/routers/ScoutRatingRouter";
import {
  PlayerBio,
  PlayerMeasurements,
} from "../../../shared/routers/PlayerRouter";
import { UserContext } from "../../UserContext";
import { PlayerRatingScaleToolTip } from "./PlayerRatingScaleTooltip";
import { PlayerRatingSpeedToolTip } from "./PlayerRatingSpeedTooltip";
import { Spinner } from "../core/Spinner";
import { trpc } from "../../util/tRPC";

interface ScoutRatingRow {
  playerId: string;
  player: string;
  height: number;
  wingspan: number;
  position: number;
  weight: number;
  latAth: number;
  straightLine: number;
  shiftiness: number;
  vertAth: number;
  stroke: number;
  touch: number;
  feel: number;
  defEff: number;
  defIq: number;
  toughness: number;
  low: number;
  medium: number;
  high: number;
  great: number;
  good: number;
  greyArea: number;
  stayAway: number;
  normal: number;
  fat: number;
  skinny: number;
  wellMuscled: number;
  likely: number;
  floor: number;
  ceiling: number;
}

export function PlayerScoutRatings(props: {
  data: ScoutRating[];
  bio: PlayerBio;
  measurements: PlayerMeasurements[];
}) {
  const utils = trpc.useContext();
  const user = useContext(UserContext);
  const { data, bio, measurements } = props;

  const mutation = trpc.scoutRating.setScoutRatings.useMutation({
    onSettled: () => {
      utils.scoutRating.invalidate();
      utils.scoutRating.getScoutRatings.refetch();
    },
  });

  mutation.isLoading;

  const initialData = useMemo(() => {
    const retObj: ScoutRatingRow = {
      playerId: bio.playerId.toString(),
      player: bio.fullName,
      height: getInitialHeight(bio, measurements) || 0,
      weight: getInitialWeight(bio, measurements) || 0,
      position: bio.position !== null ? parseFloat(bio.position) : -1,
      wingspan: getInitialWingspan(bio, measurements) || 0,
      latAth: -1,
      straightLine: -1,
      shiftiness: -1,
      vertAth: -1,
      stroke: -1,
      touch: -1,
      feel: -1,
      defEff: -1,
      defIq: -1,
      toughness: -1,
      low: 0,
      medium: 0,
      high: 0,
      great: 0,
      good: 0,
      greyArea: 0,
      stayAway: 0,
      normal: 0,
      fat: 0,
      skinny: 0,
      wellMuscled: 0,
      likely: -1,
      floor: -1,
      ceiling: -1,
    };
    const scoutRatingsForPlayer = data.filter(
      (r) => r.PlayerId === bio.playerId
    );
    for (const rating of scoutRatingsForPlayer) {
      // If user supplied a custom height/weight/wingspan/pos value but then
      // decided to remove it at some point we should ignore that value and use
      // bio info.
      const isBioField = ["weight", "height", "wingspan", "position"].includes(
        ScoutRatingDimensionMap[rating.DimensionId] || ""
      );
      const value = parseFloat(rating.value as unknown as string);
      const isEmptyValue = value === -1;
      if (!(isBioField && isEmptyValue)) {
        const dimensionKey = ScoutRatingDimensionMap[rating.DimensionId] || "";
        // TODO(chrisbu): Why do we need this cast?
        (retObj as any)[dimensionKey] = value;
      }
    }
    return retObj;
  }, [data]);

  const [rows, setRows] = useState<ScoutRatingRow[]>([initialData]);

  const pendingRatingChanges = useMemo(() => {
    const pending: ScoutRating[] = [];
    for (const row of rows) {
      for (const dimension of Object.keys(row)) {
        const curVal = row[dimension as keyof ScoutRatingRow];
        if (curVal !== initialData[dimension as keyof ScoutRatingRow]) {
          const dimensionId = Object.keys(ScoutRatingDimensionMap).find(
            (d) => ScoutRatingDimensionMap[parseInt(d)] === dimension
          );
          if (dimensionId && user) {
            pending.push({
              UserName: user.email.split("@")[0] || "Unknown",
              PlayerId: parseInt(row.playerId),
              DimensionId: parseInt(dimensionId),
              ratingdate: moment(Date()).format("YYYY-MM-DD hh:mm:ss"),
              value: curVal.toString(),
            });
          }
        }
      }
    }
    return pending;
  }, [initialData, rows]);

  function handleChange(key: string, value: string, playerId: string) {
    const row = rows.find((r) => r.playerId === playerId);
    if (!row) return;

    let obj: Record<string, number> = {
      [key]: parseFloat(value === "" ? "-1" : value),
    };
    if (key === "injuryRisk") {
      obj = {
        low: value === "low" ? 1 : 0,
        medium: value === "medium" ? 1 : 0,
        high: value === "high" ? 1 : 0,
      };
    } else if (key === "character") {
      obj = {
        great: value === "great" ? 1 : 0,
        good: value === "good" ? 1 : 0,
        greyArea: value === "greyArea" ? 1 : 0,
        stayAway: value === "stayAway" ? 1 : 0,
      };
    } else if (key === "frame") {
      obj = {
        normal: value === "normal" ? 1 : 0,
        fat: value === "fat" ? 1 : 0,
        skinny: value === "skinny" ? 1 : 0,
        wellMuscled: value === "wellMuscled" ? 1 : 0,
      };
    }
    setRows([Object.assign({}, row, obj)]);
  }

  const playerId = bio.playerId.toString();

  const getInjuryRiskValue = (row: ScoutRatingRow) => {
    if (row.low === 1) return "low";
    else if (row.medium === 1) return "medium";
    else if (row.high === 1) return "high";
    return "";
  };

  const getFrameValue = (row: ScoutRatingRow) => {
    if (row.normal === 1) return "normal";
    else if (row.fat === 1) return "fat";
    else if (row.skinny === 1) return "skinny";
    else if (row.wellMuscled === 1) return "wellMuscled";
    return "";
  };

  const getCharacterValue = (row: ScoutRatingRow) => {
    if (row.great === 1) return "great";
    else if (row.good === 1) return "good";
    else if (row.greyArea === 1) return "greyArea";
    else if (row.stayAway === 1) return "stayAway";
    return "";
  };

  const columns: {
    header: ReactNode;
    cell: (row: ScoutRatingRow) => ReactNode;
    footer: string;
  }[] = [
    {
      header: "Player",
      cell: (row: ScoutRatingRow) => (
        <Link to={`/player/${row.playerId}`}>{row.player}</Link>
      ),
      footer: "Player",
    },
    {
      header: "Height",
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="height"
          value={row.height === -1 ? "" : row.height.toString()}
          opts={{}}
          handleChange={handleChange}
        />
      ),
      footer: "Height",
    },
    {
      header: "Wingspan",
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="wingspan"
          value={row.wingspan === -1 ? "" : row.wingspan.toString()}
          opts={{}}
          handleChange={handleChange}
        />
      ),
      footer: "Wingspan",
    },
    {
      header: "Position",
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="position"
          value={row.position === -1 ? "" : row.position.toString()}
          opts={{
            min: 0,
            max: 10,
            step: 0.1,
          }}
          handleChange={handleChange}
        />
      ),
      footer: "[1-5]",
    },
    {
      header: "Weight",
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="weight"
          value={row.weight === -1 ? "" : row.weight.toString()}
          opts={{
            min: 0,
            step: 0.1,
          }}
          handleChange={handleChange}
        />
      ),
      footer: "Weight",
    },
    {
      header: (
        <PlayerRatingSpeedToolTip>{"Lat. Ath."}</PlayerRatingSpeedToolTip>
      ),
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="latAth"
          value={row.latAth === -1 ? "" : row.latAth.toString()}
          opts={{
            min: 0,
            max: 6,
            step: 0.1,
          }}
          handleChange={handleChange}
        />
      ),
      footer: "[0-6]",
    },
    {
      header: (
        <PlayerRatingSpeedToolTip>{"Str. Line."}</PlayerRatingSpeedToolTip>
      ),
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="straightLine"
          value={row.straightLine === -1 ? "" : row.straightLine.toString()}
          opts={{
            min: 0,
            max: 6,
            step: 0.1,
          }}
          handleChange={handleChange}
        />
      ),
      footer: "[0-6]",
    },
    {
      header: "Shiftiness",
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="shiftiness"
          value={row.shiftiness === -1 ? "" : row.shiftiness.toString()}
          opts={{
            min: 0,
            max: 10,
            step: 0.1,
          }}
          handleChange={handleChange}
        />
      ),
      footer: "[0-10]",
    },
    {
      header: "Vert. Ath.",
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="vertAth"
          value={row.vertAth === -1 ? "" : row.vertAth.toString()}
          opts={{
            min: 0,
            max: 10,
            step: 0.1,
          }}
          handleChange={handleChange}
        />
      ),
      footer: "[0-10]",
    },
    {
      header: "Stroke",
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="stroke"
          value={row.stroke === -1 ? "" : row.stroke.toString()}
          opts={{
            min: 0,
            max: 10,
            step: 0.1,
          }}
          handleChange={handleChange}
        />
      ),
      footer: "[0-10]",
    },
    {
      header: "Touch",
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="touch"
          value={row.touch === -1 ? "" : row.touch.toString()}
          opts={{
            min: 0,
            max: 10,
            step: 0.1,
          }}
          handleChange={handleChange}
        />
      ),
      footer: "[0-10]",
    },
    {
      header: "Feel",
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="feel"
          value={row.feel === -1 ? "" : row.feel.toString()}
          opts={{
            min: 0,
            max: 10,
            step: 0.1,
          }}
          handleChange={handleChange}
        />
      ),
      footer: "[0-10]",
    },
    {
      header: "Def. Eff.",
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="defEff"
          value={row.defEff === -1 ? "" : row.defEff.toString()}
          opts={{
            min: 0,
            max: 10,
            step: 0.1,
          }}
          handleChange={handleChange}
        />
      ),
      footer: "[0-10]",
    },
    {
      header: "Def. IQ",
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="defIq"
          value={row.defIq === -1 ? "" : row.defIq.toString()}
          opts={{
            min: 0,
            max: 10,
            step: 0.1,
          }}
          handleChange={handleChange}
        />
      ),
      footer: "[0-10]",
    },
    {
      header: "Tough",
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="toughness"
          value={row.toughness === -1 ? "" : row.toughness.toString()}
          opts={{
            min: 0,
            max: 10,
            step: 0.1,
          }}
          handleChange={handleChange}
        />
      ),
      footer: "[0-10]",
    },
    {
      header: "Injury Risk",
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="injuryRisk"
          value={getInjuryRiskValue(row)}
          handleChange={handleChange}
          opts={{
            options: [
              { label: "No Selection", value: "" },
              { label: "Low", value: "low" },
              { label: "Medium", value: "medium" },
              { label: "High", value: "high" },
            ],
          }}
        />
      ),
      footer: "Injury Risk",
    },
    {
      header: "Character",
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="character"
          value={getCharacterValue(row)}
          handleChange={handleChange}
          opts={{
            options: [
              { label: "No Selection", value: "" },
              { label: "Great", value: "great" },
              { label: "Good", value: "good" },
              { label: "Gray Area", value: "greyArea" },
              { label: "Stay Away", value: "stayAway" },
            ],
          }}
        />
      ),
      footer: "Character",
    },
    {
      header: "Frame",
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="frame"
          value={getFrameValue(row)}
          handleChange={handleChange}
          opts={{
            options: [
              { label: "No Selection", value: "" },
              { label: "Normal", value: "normal" },
              { label: "Fat", value: "fat" },
              { label: "Skinny", value: "skinny" },
              { label: "Well Muscled", value: "wellMuscled" },
            ],
          }}
        />
      ),
      footer: "Frame",
    },
    {
      header: <PlayerRatingScaleToolTip>{"Floor"}</PlayerRatingScaleToolTip>,
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="floor"
          value={row.floor === -1 ? "" : row.floor.toString()}
          opts={{
            min: 0,
            max: 10,
            step: 0.1,
          }}
          handleChange={handleChange}
        />
      ),
      footer: "[0-10]",
    },
    {
      header: <PlayerRatingScaleToolTip>{"Likely"}</PlayerRatingScaleToolTip>,
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="likely"
          value={row.likely === -1 ? "" : row.likely.toString()}
          opts={{
            min: 0,
            max: 10,
            step: 0.1,
          }}
          handleChange={handleChange}
        />
      ),
      footer: "[0-10]",
    },
    {
      header: <PlayerRatingScaleToolTip>{"Ceil"}</PlayerRatingScaleToolTip>,
      cell: (row: ScoutRatingRow) => (
        <ScoutRowCell
          playerId={playerId}
          dimension="ceiling"
          value={row.ceiling === -1 ? "" : row.ceiling.toString()}
          opts={{
            min: 0,
            max: 10,
            step: 0.1,
          }}
          handleChange={handleChange}
        />
      ),
      footer: "[0-10]",
    },
  ];

  return (
    <div style={{ overflowX: "auto" }}>
      <Table striped style={{ width: "auto" }}>
        <thead>
          <tr>
            {columns.map((c, i) => (
              <th key={i} style={{ whiteSpace: "nowrap" }}>
                {c.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((r) => (
            <tr key={r.playerId}>
              {columns.map((c, i) => {
                return (
                  <td key={i} style={{ whiteSpace: "nowrap" }}>
                    {c.cell(r)}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            {columns.map((c, i) => (
              <th key={i} style={{ whiteSpace: "nowrap" }}>
                {c.footer}
              </th>
            ))}
          </tr>
        </tfoot>
      </Table>
      {pendingRatingChanges.length > 0 && (
        <div style={{ display: "flex", gap: 10 }}>
          <Button onClick={() => mutation.mutate(pendingRatingChanges)}>
            {mutation.isLoading ? (
              <Spinner message={"Saving"} />
            ) : (
              "Save Ratings"
            )}
          </Button>
          <Button variant={"secondary"} onClick={() => setRows([initialData])}>
            Reset
          </Button>
        </div>
      )}
    </div>
  );
}

function ScoutRowCell(props: {
  playerId: string;
  dimension: keyof ScoutRatingRow | "injuryRisk" | "character" | "frame";
  value: string;
  opts: {
    min?: number;
    max?: number;
    step?: number;
    options?: { label: string; value: string }[];
  };
  handleChange: (dimension: string, value: string, playerId: string) => void;
}) {
  const { playerId, dimension, value, opts, handleChange } = props;

  if (dimension === "height" || dimension === "wingspan") {
    const valNum = parseFloat(value) || 0;
    const calculateLengthValue = (
      val: string,
      feetInches: "feet" | "inches"
    ) => {
      // On empty string just empty out the value.
      if (val === "") return val;
      const oldFeet = Math.floor(valNum / 12);
      const oldInches = valNum - 12 * feet;
      if (feetInches === "feet") {
        return (oldInches + parseInt(val) * 12).toString();
      }
      return Math.min(120, parseFloat(val) + oldFeet * 12).toString();
    };
    const feet = Math.floor(valNum / 12);
    const inches = valNum - 12 * feet;
    return (
      <div style={{ display: "flex" }}>
        <input
          min={0}
          max={10}
          type="number"
          value={value ? feet : ""}
          style={{ width: "4em", height: "22px" }}
          onChange={(evt) =>
            handleChange(
              dimension,
              calculateLengthValue(evt.currentTarget.value, "feet"),
              playerId
            )
          }
        />
        {"'"}
        <input
          type="number"
          step={0.25}
          value={value ? inches : ""}
          style={{ width: "4em", height: "22px" }}
          onChange={(evt) =>
            handleChange(
              dimension,
              calculateLengthValue(evt.currentTarget.value, "inches"),
              playerId
            )
          }
        />
        {'"'}
      </div>
    );
  }

  if (
    dimension === "injuryRisk" ||
    dimension === "character" ||
    dimension === "frame"
  ) {
    return (
      <select
        value={value || ""}
        style={{ height: "22px" }}
        onChange={(evt) =>
          handleChange(dimension, evt.currentTarget.value, playerId)
        }
      >
        {opts.options &&
          opts.options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
      </select>
    );
  }

  return (
    <input
      type="number"
      onChange={(e) => {
        handleChange(dimension, e.currentTarget.value, playerId);
      }}
      step={opts.step || ".1"}
      style={{ width: "4em", height: "22px" }}
      max={opts.max}
      min={opts.min}
      value={value}
    />
  );
}

const ScoutRatingDimensionMap: Record<number, keyof ScoutRatingRow> = {
  1: "latAth",
  2: "straightLine",
  3: "shiftiness",
  4: "vertAth",
  5: "stroke",
  6: "touch",
  7: "feel",
  8: "defEff",
  9: "defIq",
  10: "toughness",
  11: "low",
  12: "medium",
  13: "high",
  14: "great",
  15: "good",
  16: "greyArea",
  17: "stayAway",
  18: "normal",
  19: "fat",
  20: "skinny",
  21: "wellMuscled",
  22: "likely",
  23: "ceiling",
  24: "height",
  25: "weight",
  26: "wingspan",
  27: "position",
  28: "floor",
};

function getInitialHeight(bio: PlayerBio, measurements: PlayerMeasurements[]) {
  const noShoesHeights = measurements
    .filter((m) => m.heightNoShoes !== null)
    .map((m) => m.heightNoShoes) as number[];
  if (noShoesHeights.length > 0) {
    return noShoesHeights[noShoesHeights.length - 1];
  }
  const withShoesHeights = measurements
    .filter((m) => m.heightWithShoes !== null)
    .map((m) => m.heightWithShoes) as number[];
  const lastWithShoes = withShoesHeights[withShoesHeights.length - 1];
  if (lastWithShoes) {
    return lastWithShoes - 1.25;
  }

  return bio.height || -1;
}

function getInitialWeight(bio: PlayerBio, measurements: PlayerMeasurements[]) {
  const weights = measurements
    .filter((m) => m.weight !== null)
    .map((m) => m.weight) as number[];
  if (weights.length > 0) {
    return weights[weights.length - 1];
  }

  return bio.weight || -1;
}

function getInitialWingspan(
  bio: PlayerBio,
  measurements: PlayerMeasurements[]
) {
  const wingspans = measurements
    .filter((m) => m.wingspan !== null)
    .map((m) => m.wingspan) as number[];

  if (wingspans.length > 0) {
    return wingspans[wingspans.length - 1];
  }

  return -1;
}
