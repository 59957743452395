import React, { ReactNode, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";

import { UserPreferenceContext, UserContext } from "../../UserContext";
import { minutesFormat } from "../../util/Format";
import { LineChart } from "../chart/LineChart";
import { PlayerPreviewCard } from "../player/PlayerPreviewCard";
import { PlayerStatusContext } from "../../PlayerStatusContext";
import { BiPlusMedical, BiError, BiStar } from "react-icons/bi";
import { BsRadioactive } from "react-icons/bs";
import {
  blueLow,
  Green1,
  lineChartColors,
  purple,
  redHigh,
} from "../../constants/ColorConstants";
import { Restrict } from "./Restrict";
import AppContext from "../../../shared/AppContext";
import { BANNED_PLAYER_IDS } from "../../constants/BannedPlayers";
import { FaFire, FaSnowflake } from "react-icons/fa";

export function PlayerTableCell(props: {
  id?: string | number;
  name?: string | JSX.Element;
  bold?: boolean;
  useSpans?: boolean;
}) {
  const { id, name, bold, useSpans } = props;
  const divRef = useRef<HTMLDivElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);

  const user = useContext(UserContext);

  const hotColdPref = useContext(UserPreferenceContext)["Hot/Cold Shooting"];

  const shouldShowPreviewCard =
    useContext(UserPreferenceContext)["Player Preview Cards"] || 0;

  const { playerInjuries, playerMissedGames, trendingPlayers, playerHotCold } =
    useContext(PlayerStatusContext);

  const isInjured = id && playerInjuries[parseInt(id.toString())];
  const missedgames = id && playerMissedGames[parseInt(id.toString())];
  const isTrending = id && trendingPlayers[parseInt(id.toString())];
  const hotCold = hotColdPref && id && playerHotCold[parseInt(id.toString())];
  const isHot = !!(hotCold && hotCold.luck > 0);
  const isCold = !!(hotCold && hotCold.luck < 0);

  let innerContent = id ? (
    <Link to={`/player/${id}`}>
      {name}
      {isInjured && (
        <BiPlusMedical
          style={{
            borderRadius: 2,
            backgroundColor: "white",
            color: redHigh,
            marginTop: 2,
            marginLeft: 4,
            verticalAlign: "top",
            width: 8,
            height: 8,
          }}
        />
      )}
      {!isInjured && missedgames && (
        <BiError
          style={{
            color:
              missedgames.gamesMissed >= 5 ? redHigh : lineChartColors.yellow,
            marginLeft: 4,
            verticalAlign: "top",
            width: 12,
            height: 12,
          }}
        />
      )}
      {isHot && (
        <FaFire
          style={{
            color: redHigh,
            marginLeft: 4,
            marginTop: 1,
            width: 10,
            height: 10,
            verticalAlign: "top",
          }}
        />
      )}
      {isCold && (
        <FaSnowflake
          style={{
            color: blueLow,
            marginLeft: 4,
            marginTop: 1,
            width: 10,
            height: 10,
            verticalAlign: "top",
          }}
        />
      )}
      {isTrending && (
        <Restrict
          roles={[]}
          override={user && user.email === "chrisbu@celtics.com"}
        >
          <BiStar
            style={{
              color: purple,
              marginLeft: 4,
              verticalAlign: "top",
              width: 12,
              height: 12,
            }}
          />
        </Restrict>
      )}
    </Link>
  ) : (
    name
  );

  // Override inner content for banned players.
  if (id && BANNED_PLAYER_IDS.has(id as number)) {
    innerContent = (
      <Link to={`/player/${id}`}>
        {name}
        <BsRadioactive
          style={{
            color: Green1,
            marginLeft: 4,
            verticalAlign: "top",
            width: 12,
            height: 12,
          }}
        />
      </Link>
    );
  }

  const wrappedContent = useSpans ? (
    <span
      ref={spanRef}
      style={{ textAlign: "left", fontWeight: bold ? "bold" : "normal" }}
    >
      {innerContent}
    </span>
  ) : (
    <div
      ref={divRef}
      style={{ textAlign: "left", fontWeight: bold ? "bold" : "normal" }}
    >
      {innerContent}
    </div>
  );

  if (!id || !shouldShowPreviewCard) return wrappedContent;

  let placement: "left" | "right" = "right";

  if (
    spanRef.current &&
    spanRef.current.getBoundingClientRect().x > window.innerWidth / 2
  ) {
    placement = "left";
  } else if (
    divRef.current &&
    divRef.current.getBoundingClientRect().x > window.innerWidth / 2
  ) {
    placement = "left";
  }

  return (
    <OverlayTrigger
      delay={{ show: 200, hide: 0 }}
      placement={placement}
      overlay={
        <Popover style={{ maxWidth: "none" }}>
          <PlayerPreviewCard playerId={parseInt(id.toString())} />
        </Popover>
      }
    >
      {wrappedContent}
    </OverlayTrigger>
  );
}

export function TeamTableCell(props: {
  id?: string | number;
  name?: string;
  ids?: number;
  season?: string;
  league?: string;
  useSpan?: boolean;
}) {
  const { id, name, ids, league, season, useSpan } = props;

  if (!name) return null;

  const isOldSeason = season && season !== AppContext.currentSeason;

  const isNBA = ids && ids >= 1610612737 && ids <= 1610612766;
  // Most things are passing in leagueKey but just to be safe convert everything
  // from league format to leagueKey.
  const leagueKey = league
    ? league.replaceAll(" ", "_").toLocaleLowerCase()
    : undefined;
  const leagueStr = leagueKey ? `league=${leagueKey}` : "";
  const seasonStr = season ? `season=${season}` : "";

  const innerContent =
    isNBA && !isOldSeason ? (
      <Link to={`/team/${ids}`}>{name}</Link>
    ) : id ? (
      <Link
        to={[
          `/team/ml/${id}`,
          [leagueStr, seasonStr].filter((x) => x).join("&"),
        ].join("?")}
      >
        {name}
      </Link>
    ) : (
      name
    );

  if (useSpan) {
    return <span style={{ textAlign: "left" }}>{innerContent}</span>;
  }

  return <div style={{ textAlign: "left" }}>{innerContent}</div>;
}

export function PlayerMinutesTableCell(props: {
  min: number;
  gameId: number;
  playerId: number | null;
  playerMinutesExist: boolean;
}) {
  const { min, gameId, playerId, playerMinutesExist } = props;

  if (playerMinutesExist && playerId !== null) {
    return (
      <Link
        title={"Go to player minutes"}
        to={`/player-minutes/${gameId}/${playerId}`}
      >
        {minutesFormat(min)}
      </Link>
    );
  }
  return <span>{minutesFormat(min)}</span>;
}

export function SparkLineTableCell(props: { data: number[]; color: string }) {
  const { data, color } = props;

  const line = {
    color,
    label: "",
    segments: [
      data.map((d, i) => {
        return {
          x: i,
          y: d,
        };
      }),
    ],
  };

  return (
    <LineChart
      height={16}
      width={16}
      lines={[line]}
      showLegend={false}
      hideAxis={true}
      hideGrid={true}
      margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
    />
  );
}

export function TruncatedTableCell(props: {
  children: ReactNode;
  width?: number;
}) {
  const maxWidth = props.width || 100;

  return (
    <div
      style={{
        maxWidth,
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {props.children}
    </div>
  );
}
