import { blueLow, grayMid, darkGrayMid, redHigh } from "./ColorConstants";

/** Blue low, light gray mid, red high. Aka Bold Decisive Leadership  */
const divergentColorScheme = [blueLow, grayMid, redHigh];

/** Merg's favorite color scheme. */
const purpleToGreenScheme = ["#9970AB", grayMid, "#5AAE61"];

/** Cleaning the glass style. */
const ctgTheme = ["#3388ff", grayMid, "#f59b00"];

export const tableColorSchemes = [
  divergentColorScheme,
  purpleToGreenScheme,
  ctgTheme,
];

export const winProbColorScheme = ["#C73A51", "#FFD633", "#50BF3D"];

export const divergentDarkMidColorScheme = [blueLow, darkGrayMid, redHigh];

export const sequentialNeutralColorScheme = ["#fff", "#CABFB8"];

export const optionB = [
  "#000004",
  "#010005",
  "#010106",
  "#010108",
  "#02010A",
  "#02020C",
  "#02020E",
  "#030210",
  "#040312",
  "#040314",
  "#050417",
  "#060419",
  "#07051B",
  "#08051D",
  "#09061F",
  "#0A0722",
  "#0B0724",
  "#0C0826",
  "#0D0829",
  "#0E092B",
  "#10092D",
  "#110A30",
  "#120A32",
  "#140B34",
  "#150B37",
  "#160B39",
  "#180C3C",
  "#190C3E",
  "#1B0C41",
  "#1C0C43",
  "#1E0C45",
  "#1F0C48",
  "#210C4A",
  "#230C4C",
  "#240C4F",
  "#260C51",
  "#280B53",
  "#290B55",
  "#2B0B57",
  "#2D0B59",
  "#2F0A5B",
  "#310A5C",
  "#320A5E",
  "#340A5F",
  "#360961",
  "#380962",
  "#390963",
  "#3B0964",
  "#3D0965",
  "#3E0966",
  "#400A67",
  "#420A68",
  "#440A68",
  "#450A69",
  "#470B6A",
  "#490B6A",
  "#4A0C6B",
  "#4C0C6B",
  "#4D0D6C",
  "#4F0D6C",
  "#510E6C",
  "#520E6D",
  "#540F6D",
  "#550F6D",
  "#57106E",
  "#59106E",
  "#5A116E",
  "#5C126E",
  "#5D126E",
  "#5F136E",
  "#61136E",
  "#62146E",
  "#64156E",
  "#65156E",
  "#67166E",
  "#69166E",
  "#6A176E",
  "#6C186E",
  "#6D186E",
  "#6F196E",
  "#71196E",
  "#721A6E",
  "#741A6E",
  "#751B6E",
  "#771C6D",
  "#781C6D",
  "#7A1D6D",
  "#7C1D6D",
  "#7D1E6D",
  "#7F1E6C",
  "#801F6C",
  "#82206C",
  "#84206B",
  "#85216B",
  "#87216B",
  "#88226A",
  "#8A226A",
  "#8C2369",
  "#8D2369",
  "#8F2469",
  "#902568",
  "#922568",
  "#932667",
  "#952667",
  "#972766",
  "#982766",
  "#9A2865",
  "#9B2964",
  "#9D2964",
  "#9F2A63",
  "#A02A63",
  "#A22B62",
  "#A32C61",
  "#A52C60",
  "#A62D60",
  "#A82E5F",
  "#A92E5E",
  "#AB2F5E",
  "#AD305D",
  "#AE305C",
  "#B0315B",
  "#B1325A",
  "#B3325A",
  "#B43359",
  "#B63458",
  "#B73557",
  "#B93556",
  "#BA3655",
  "#BC3754",
  "#BD3853",
  "#BF3952",
  "#C03A51",
  "#C13A50",
  "#C33B4F",
  "#C43C4E",
  "#C63D4D",
  "#C73E4C",
  "#C83F4B",
  "#CA404A",
  "#CB4149",
  "#CC4248",
  "#CE4347",
  "#CF4446",
  "#D04545",
  "#D24644",
  "#D34743",
  "#D44842",
  "#D54A41",
  "#D74B3F",
  "#D84C3E",
  "#D94D3D",
  "#DA4E3C",
  "#DB503B",
  "#DD513A",
  "#DE5238",
  "#DF5337",
  "#E05536",
  "#E15635",
  "#E25734",
  "#E35933",
  "#E45A31",
  "#E55C30",
  "#E65D2F",
  "#E75E2E",
  "#E8602D",
  "#E9612B",
  "#EA632A",
  "#EB6429",
  "#EB6628",
  "#EC6726",
  "#ED6925",
  "#EE6A24",
  "#EF6C23",
  "#EF6E21",
  "#F06F20",
  "#F1711F",
  "#F1731D",
  "#F2741C",
  "#F3761B",
  "#F37819",
  "#F47918",
  "#F57B17",
  "#F57D15",
  "#F67E14",
  "#F68013",
  "#F78212",
  "#F78410",
  "#F8850F",
  "#F8870E",
  "#F8890C",
  "#F98B0B",
  "#F98C0A",
  "#F98E09",
  "#FA9008",
  "#FA9207",
  "#FA9407",
  "#FB9606",
  "#FB9706",
  "#FB9906",
  "#FB9B06",
  "#FB9D07",
  "#FC9F07",
  "#FCA108",
  "#FCA309",
  "#FCA50A",
  "#FCA60C",
  "#FCA80D",
  "#FCAA0F",
  "#FCAC11",
  "#FCAE12",
  "#FCB014",
  "#FCB216",
  "#FCB418",
  "#FBB61A",
  "#FBB81D",
  "#FBBA1F",
  "#FBBC21",
  "#FBBE23",
  "#FAC026",
  "#FAC228",
  "#FAC42A",
  "#FAC62D",
  "#F9C72F",
  "#F9C932",
  "#F9CB35",
  "#F8CD37",
  "#F8CF3A",
  "#F7D13D",
  "#F7D340",
  "#F6D543",
  "#F6D746",
  "#F5D949",
  "#F5DB4C",
  "#F4DD4F",
  "#F4DF53",
  "#F4E156",
  "#F3E35A",
  "#F3E55D",
  "#F2E661",
  "#F2E865",
  "#F2EA69",
  "#F1EC6D",
  "#F1ED71",
  "#F1EF75",
  "#F1F179",
  "#F2F27D",
  "#F2F482",
  "#F3F586",
  "#F3F68A",
  "#F4F88E",
  "#F5F992",
  "#F6FA96",
  "#F8FB9A",
  "#F9FC9D",
  "#FAFDA1",
  "#FCFFA4",
];

export const viridis = [
  "#44089E",
  "#440C9F",
  "#4310A0",
  "#4213A1",
  "#4215A1",
  "#4118A2",
  "#401AA3",
  "#3F1DA3",
  "#3E1FA4",
  "#3D22A4",
  "#3D24A4",
  "#3C26A4",
  "#3A28A4",
  "#392AA4",
  "#382CA4",
  "#372EA4",
  "#3630A4",
  "#3532A3",
  "#3434A3",
  "#3236A2",
  "#3138A1",
  "#3039A1",
  "#2F3BA0",
  "#2E3D9F",
  "#2D3E9E",
  "#2C409D",
  "#2B419C",
  "#2A439B",
  "#29449B",
  "#28469A",
  "#284799",
  "#274898",
  "#264A97",
  "#264B96",
  "#254C95",
  "#254D94",
  "#244E93",
  "#245092",
  "#245191",
  "#245291",
  "#235390",
  "#23548F",
  "#23558E",
  "#23568D",
  "#23578D",
  "#24588C",
  "#24598B",
  "#245A8B",
  "#245B8A",
  "#245C89",
  "#255D89",
  "#255D88",
  "#255E87",
  "#265F87",
  "#266086",
  "#276186",
  "#276285",
  "#276385",
  "#286484",
  "#286484",
  "#296584",
  "#296683",
  "#2A6783",
  "#2A6882",
  "#2B6982",
  "#2B6982",
  "#2B6A81",
  "#2C6B81",
  "#2C6C81",
  "#2D6D80",
  "#2D6E80",
  "#2D6E80",
  "#2E6F7F",
  "#2E707F",
  "#2F717F",
  "#2F727F",
  "#2F737E",
  "#2F737E",
  "#30747E",
  "#30757E",
  "#30767D",
  "#30777D",
  "#31787D",
  "#31787D",
  "#31797D",
  "#317A7C",
  "#317B7C",
  "#317C7C",
  "#317D7C",
  "#317E7B",
  "#317E7B",
  "#317F7B",
  "#31807B",
  "#31817A",
  "#31827A",
  "#31837A",
  "#318479",
  "#318579",
  "#318579",
  "#308678",
  "#308778",
  "#308878",
  "#308977",
  "#2F8A77",
  "#2F8B76",
  "#2F8C76",
  "#2F8D75",
  "#2E8E75",
  "#2E8F74",
  "#2E8F74",
  "#2D9073",
  "#2D9173",
  "#2D9272",
  "#2C9371",
  "#2C9471",
  "#2C9570",
  "#2B966F",
  "#2B976F",
  "#2B986E",
  "#2B996D",
  "#2A9A6C",
  "#2A9B6B",
  "#2A9C6A",
  "#2A9C6A",
  "#2A9D69",
  "#2A9E68",
  "#2A9F67",
  "#2AA066",
  "#2AA165",
  "#2AA264",
  "#2AA362",
  "#2BA461",
  "#2BA560",
  "#2CA55F",
  "#2CA65E",
  "#2DA75C",
  "#2EA85B",
  "#2EA95A",
  "#2FAA58",
  "#31AB57",
  "#32AC55",
  "#33AC54",
  "#34AD52",
  "#36AE51",
  "#37AF4F",
  "#39B04D",
  "#3BB04B",
  "#3DB14A",
  "#3FB248",
  "#41B246",
  "#43B344",
  "#46B442",
  "#48B540",
  "#4BB53E",
  "#4DB63C",
  "#50B63A",
  "#53B738",
  "#55B736",
  "#58B834",
  "#5BB832",
  "#5EB930",
  "#62B92D",
  "#65BA2B",
  "#68BA29",
  "#6BBB27",
  "#6EBB26",
  "#72BB24",
  "#75BC22",
  "#78BC21",
  "#7BBC20",
  "#7EBC1E",
  "#81BD1E",
  "#84BD1D",
  "#87BD1C",
  "#8ABD1C",
  "#8DBD1C",
  "#90BE1C",
  "#93BE1C",
  "#96BE1C",
  "#99BE1D",
  "#9BBF1D",
  "#9EBF1E",
  "#A1BF1F",
  "#A3BF20",
  "#A6BF21",
  "#A8C022",
  "#ABC023",
  "#ADC025",
  "#B0C026",
  "#B2C027",
  "#B5C128",
  "#B7C12A",
  "#B9C12B",
  "#BCC12D",
  "#BEC12E",
  "#C0C22F",
  "#C3C231",
  "#C5C232",
  "#C7C233",
  "#C9C335",
  "#CBC336",
  "#CEC338",
  "#D0C339",
  "#D2C33B",
  "#D4C43C",
  "#D6C43D",
  "#D9C43F",
  "#DBC440",
  "#DDC441",
  "#DFC543",
  "#E1C544",
  "#E3C546",
  "#E5C547",
  "#E7C648",
  "#E9C64A",
  "#ECC64B",
  "#EEC64C",
  "#F0C64E",
  "#F2C74F",
  "#F4C750",
  "#F6C751",
  "#F8C752",
  "#FAC753",
  "#FDC754",
  "#FEC852",
  "#FECA51",
  "#FECB50",
  "#FECC4F",
  "#FECE4E",
  "#FECF4D",
  "#FED04C",
  "#FED24B",
  "#FED34A",
  "#FED449",
  "#FED648",
  "#FED747",
  "#FED945",
  "#FEDA44",
  "#FEDB43",
  "#FEDD42",
  "#FEDE40",
  "#FEE03F",
  "#FEE13E",
  "#FEE33C",
  "#FEE43B",
  "#FEE539",
  "#FDE737",
  "#FDE835",
  "#FDEA33",
  "#FDEB31",
  "#FDED2F",
  "#FDEE2D",
  "#FCF02A",
  "#FCF127",
  "#FCF324",
  "#FCF420",
];
