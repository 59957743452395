import React, { useState } from "react";
import { Form } from "react-bootstrap";

import { CrashingTable } from "./CrashingTable";
import { CrashingViz } from "./CrashingViz";
import { CrashAttempt } from "../../../shared/routers/GameRouter";

export function TeamCrashing(props: {
  data: CrashAttempt[];
  teams: { teamId: number; teamName: string }[];
}) {
  const { data, teams } = props;
  const firstTeam = teams[0];
  const [teamId, setTeamId] = useState<string>(
    firstTeam ? firstTeam.teamId.toString() : ""
  );
  const [player, setPlayer] = useState<string>("");
  const [period, setPeriod] = useState(0);
  const [location, setLocation] = useState("All Locations");
  const [noLayups, setNoLayups] = useState(true);

  const playerOptions: Array<{ value: string; label: string }> = [];
  data
    .filter((d) => d.teamId.toString() === teamId)
    .sort((a, b) => (a.player > b.player ? 1 : -1))
    .forEach((d) => {
      if (playerOptions.find((p) => p.value === d.playerId.toString())) return;
      playerOptions.push({ value: d.playerId.toString(), label: d.player });
    });

  const filteredData = data.filter((d) => {
    if (noLayups && d.shotType === "layup") return false;
    if (location !== "All Locations" && d.location !== location) return false;
    if (teamId !== "" && d.teamId.toString() !== teamId) return false;
    if (player !== "" && d.playerId.toString() !== player) return false;
    if (period === 0) return true;
    if (period === 5) return d.period > 4;
    return d.period === period;
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 8,
        }}
      >
        <select
          value={teamId}
          onChange={(evt) => {
            const newTeamId = evt.target.value;
            setTeamId(newTeamId);
            // Reset the player as well so we don't have a team/player mismatch.
            setPlayer("");
          }}
        >
          {teams.map((t) => (
            <option value={t.teamId.toString()} key={t.teamId.toString()}>
              {t.teamName}
            </option>
          ))}
        </select>
        <select value={player} onChange={(evt) => setPlayer(evt.target.value)}>
          <option value="">All Players</option>
          {playerOptions.map((po) => (
            <option value={po.value} key={po.value}>
              {po.label}
            </option>
          ))}
        </select>
        <select
          value={period}
          onChange={(evt) => setPeriod(parseInt(evt.target.value))}
        >
          {[
            { value: 0, label: "Full Game" },
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "OT" },
          ].map((po) => (
            <option value={po.value} key={po.value}>
              {po.label}
            </option>
          ))}
        </select>
        <select
          value={location}
          onChange={(evt) => setLocation(evt.target.value)}
        >
          {["All Locations", "Corner", "Inside the Arc", "Above the Break"].map(
            (l) => (
              <option value={l} key={l}>
                {l}
              </option>
            )
          )}
        </select>
        <Form.Check
          type="checkbox"
          checked={noLayups}
          label="Hide Layups"
          onChange={() => setNoLayups(!noLayups)}
        />
      </div>
      <CrashingViz data={filteredData} disableHover={player === ""} />
      <CrashingTable
        data={filteredData}
        showPredCols={data.some((d) => d.crashPrediction)}
      />
    </div>
  );
}
