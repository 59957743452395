import React, { useState, useMemo } from "react";
import { Alert, Button, Form } from "react-bootstrap";

import { Modal } from "../core/Modal";
import { Spinner } from "../core/Spinner";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import {
  MappingInsert,
  SynergyInsert,
} from "../../../shared/routers/MappingRouter";
import { trpc } from "../../util/tRPC";

export function SynergyInserts(props: { inserts?: SynergyInsert[] }) {
  const { inserts } = props;
  const [checkedRows, setCheckedRows] = useState<Set<string>>(new Set());
  const [sorting, setSorting] = useState<SortingState>();
  const [errMsg, setErrMsg] = useState<string>();
  const [showInsertModal, setShowInsertModal] = useState(false);

  const utils = trpc.useContext();

  const insertMutation = trpc.mapping.postSynergyInsert.useMutation({
    onSuccess: () => {
      setCheckedRows(new Set());
      setErrMsg("");
      utils.mapping.getSynergyInserts.invalidate();
      utils.mapping.getSynergyInserts.refetch();
    },
    onError: () => {
      setErrMsg("Unable to insert player into mapping table");
    },
  });

  const pending = insertMutation.isLoading;

  const columnHelper = createColumnHelper<SynergyInsert>();

  const handleCheck = (synergyId: string) => {
    if (checkedRows.has(synergyId)) {
      checkedRows.delete(synergyId);
    } else {
      checkedRows.add(synergyId);
    }
    setCheckedRows(new Set(checkedRows));
  };

  const handleCheckAll = (checkAll: boolean) => {
    if (!inserts) return;
    if (checkAll) {
      setCheckedRows(new Set(inserts.map((i) => i.synergyPlayerId)));
    } else {
      setCheckedRows(new Set());
    }
  };

  const handleInserts = () => {
    if (!inserts) return;

    const playersToInsert: MappingInsert[] = inserts
      .filter((i) => checkedRows.has(i.synergyPlayerId))
      .map((i) => {
        return {
          First: i.synergyFirstName
            ? i.synergyFirstName.replace("Jr.", "").replace("III", "")
            : null,
          Last: i.synergyLastName,
          DOB: i.synergyBirthDate,
          Ht: null,
          Wt: null,
          College: null,
          Hometown: null,
          State: null,
          HSJC: null,
          Country: null,
          DE: null,
          synergy: i.synergyPlayerId,
        };
      });

    if (playersToInsert.length === 0) return;

    insertMutation.mutate({ players: playersToInsert, maybes: false });
  };

  const insertModal = (
    <Modal
      title={"Confirm Synergy Insert"}
      content={
        "Pressing confirm will create new player mapping entries for the selected player(s)."
      }
      show={showInsertModal}
      handleClose={() => setShowInsertModal(false)}
      handleConfirm={() => {
        setShowInsertModal(false);
        handleInserts();
      }}
    />
  );

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "check",
        header: () =>
          inserts &&
          inserts.length > 0 && (
            <Form.Check
              checked={checkedRows.size === inserts.length}
              onChange={() =>
                handleCheckAll(checkedRows.size !== inserts.length)
              }
            />
          ),
        cell: (info) => (
          <Form.Check
            checked={checkedRows.has(info.row.original.synergyPlayerId)}
            onChange={() => handleCheck(info.row.original.synergyPlayerId)}
          />
        ),
      }),
      columnHelper.accessor("synergyPlayerId", {
        header: () => "synergyId",
        meta: { group: 1 },
      }),
      columnHelper.accessor("synergyFirstName", {
        header: () => "First Name",
        meta: { group: 2 },
      }),
      columnHelper.accessor("synergyLastName", {
        header: () => "Last Name",
        meta: { group: 3 },
      }),
      columnHelper.accessor("synergyTeamName", {
        header: () => "Team Name",
        meta: { group: 4 },
      }),
      columnHelper.accessor("synergyBirthDate", {
        header: () => "Birth Date",
        meta: { group: 5 },
      }),
    ],
    [checkedRows, inserts]
  );

  if (!inserts || pending) return <Spinner />;

  return (
    <div>
      {errMsg && (
        <Alert variant="danger">
          {`Something went wrong: ${errMsg}! Please contact chrisbu@celtics.com`}
        </Alert>
      )}
      <Button
        onClick={() => setShowInsertModal(true)}
        disabled={checkedRows.size == 0}
      >
        Insert Checked Rows
      </Button>
      {insertModal}
      <Table
        data={inserts}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        virtualScroll={true}
        autoWidth={true}
        showRowIndex={false}
      />
    </div>
  );
}
