import React from "react";
import cx from "classnames";
import { createStyles, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    court: {
      lineHeight: 0,
      "&.border": {
        border: "1px solid #ccc",
      },

      "& line, polyline, path, rect, circle": {
        stroke: "#bbb",
        strokeLinecap: "square",
        strokeLinejoin: "bevel",
        strokeMiterlimit: 10,
        fill: "none",
      },
    },
  })
);

export function Court(props: {
  width: number;
  maxDistance?: number;
  isSynergy?: boolean;
  hideBorder?: boolean;
  className?: string;
  hideBenchLines?: boolean;
  absolute?: boolean;
  pointerEventsNone?: boolean;
}) {
  const classes = useStyles();
  const {
    width,
    isSynergy,
    hideBorder,
    hideBenchLines,
    className,
    absolute,
    pointerEventsNone,
  } = props;

  // Half court = 47ft.
  const maxDistance = props.maxDistance || 47;

  // Svg court dimensions are 500 x 470 (10px = 1ft).
  const scale = width / 500;

  const height = (width * (maxDistance * 10)) / 500;

  const strokeWidth = 0.75 / scale;

  return (
    <div
      className={cx(classes.court, className, { border: !hideBorder })}
      style={{
        position: absolute ? "absolute" : undefined,
        pointerEvents: pointerEventsNone ? "none" : undefined,
      }}
    >
      <svg width={width} height={height}>
        <g transform={`scale(${scale})`}>
          <line
            strokeWidth={strokeWidth}
            x1="470.4"
            y1="137.1"
            x2="470.4"
            y2="0"
          />
          <line
            strokeWidth={strokeWidth}
            x1="30.1"
            y1="137.1"
            x2="30.1"
            y2="0"
          />
          <rect
            className="paint-outer"
            strokeWidth={strokeWidth}
            x="170.2"
            y="0"
            width="160"
            height="189.9"
          />

          <line
            strokeWidth={strokeWidth}
            x1="310.3"
            y1="189.9"
            x2="310.3"
            y2="0"
          />
          <line
            strokeWidth={strokeWidth}
            x1="190.1"
            y1="189.9"
            x2="190.1"
            y2="0"
          />
          <path
            strokeWidth={strokeWidth}
            d="M190.5,189.9 c0,32.9,26.7,59.7,59.7,59.7c33,0,59.7-26.7,59.7-59.7"
          />

          <line
            className="backboard"
            strokeWidth={strokeWidth}
            x1="280.3"
            y1="40.2"
            x2="220.2"
            y2="40.2"
          />
          <circle cx="250.2" cy="47.8" r={7.5} />

          <path
            strokeWidth={strokeWidth}
            d="M210.7,52.8 c2.7,21.8,22.7,37.2,44.5,34.5c18-2.3,32.2-16.5,34.5-34.5"
          />

          <path
            strokeWidth={strokeWidth}
            strokeDasharray="13.33,11.67"
            d="M309.9,189.9c0-32.9-26.7-59.6-59.7-59.6c-33,0-59.7,26.7-59.7,59.6"
          />
          <line
            strokeWidth={strokeWidth}
            x1="340.4"
            y1="146"
            x2="330.2"
            y2="146"
          />
          <line
            strokeWidth={strokeWidth}
            x1="340.4"
            y1="114.2"
            x2="330.2"
            y2="114.2"
          />
          <line
            strokeWidth={strokeWidth}
            x1="340.4"
            y1="82.5"
            x2="330.2"
            y2="82.5"
          />
          <line
            strokeWidth={strokeWidth}
            x1="340.4"
            y1="71.1"
            x2="330.2"
            y2="71.1"
          />
          <line
            strokeWidth={strokeWidth}
            x1="160"
            y1="146"
            x2="170.2"
            y2="146"
          />
          <line
            strokeWidth={strokeWidth}
            x1="160"
            y1="114.2"
            x2="170.2"
            y2="114.2"
          />
          <line
            strokeWidth={strokeWidth}
            x1="160"
            y1="82.5"
            x2="170.2"
            y2="82.5"
          />
          <line
            strokeWidth={strokeWidth}
            x1="160"
            y1="71.1"
            x2="170.2"
            y2="71.1"
          />
          <path
            strokeWidth={strokeWidth}
            d="M30.2,137.1 c49.4,121.2,188,179.5,309.5,130.2c59.3-24.1,106.4-71,130.5-130.2"
          />

          <path
            strokeWidth={strokeWidth}
            d="M309.9,470 c0-32.9-26.7-59.7-59.7-59.7c-33,0-59.7,26.7-59.7,59.7"
          />
          {isSynergy && (
            <path
              strokeWidth={strokeWidth}
              d="M42.6,47.5 c0,131.2,98,209.5,207.6,211.6 c109.6,-2.1,207.7,-80.4,207.7,-211.6"
            />
          )}
          {isSynergy && (
            <line
              strokeWidth={strokeWidth}
              x1="457.9"
              y1="47.3"
              x2="457.9"
              y2="0"
            />
          )}
          {isSynergy && (
            <line
              strokeWidth={strokeWidth}
              x1="42.6"
              y1="47.3"
              x2="42.6"
              y2="0"
            />
          )}
          {!hideBenchLines && (
            <line
              className="bench-line"
              strokeWidth={strokeWidth}
              x1="0"
              y1="280.1"
              x2="30.1"
              y2="280.1"
            />
          )}
          {!hideBenchLines && (
            <line
              className="bench-line"
              strokeWidth={strokeWidth}
              x1="500"
              y1="280.1"
              x2="470.4"
              y2="280.1"
            />
          )}
        </g>
      </svg>
    </div>
  );
}
