import React from "react";

import { Positions } from "../../constants/AppConstants";
import AppContext from "../../../shared/AppContext";
import { RosterPlayer } from "../../../shared/routers/RosterRouter";
import { PlayerLink } from "../player/PlayerLink";
import { playerByStatus } from "../../util/Sort";

export function TeamDepthChart(props: {
  data: Record<string, RosterPlayer[]>;
  orientation: "tall" | "wide";
  showHeaders?: boolean;
  showBottomBorder?: boolean;
}) {
  const { data, orientation, showHeaders, showBottomBorder } = props;

  if (orientation === "tall") {
    return renderTall(data);
  }
  return renderWide(
    data,
    showHeaders == null ? true : showHeaders,
    showBottomBorder == null ? true : showBottomBorder
  );
}

function renderWide(
  data: Record<string, RosterPlayer[]>,
  showHeaders: boolean,
  showBottomBorder: boolean
) {
  const positions = [1, 2, 3, 4, 5];

  return (
    <table
      style={{
        tableLayout: "fixed",
        width: "100%",
        marginBottom: "1rem",
        borderBottom: showBottomBorder ? "1px solid #ddd" : undefined,
      }}
    >
      {showHeaders ? (
        <thead>
          <tr>
            {[1, 2, 3, 4, 5].map((pos) => {
              return (
                <th key={pos} style={{ borderBottom: "2px solid #ddd" }}>
                  {Positions[pos]}
                </th>
              );
            })}
          </tr>
        </thead>
      ) : null}
      <tbody style={{ verticalAlign: "inherit" }}>
        <tr>
          {positions.map((position, i) => {
            if (!data[position]) {
              return <td key={i} />;
            }

            return (
              <td key={i}>
                <ul className="list-unstyled">
                  {(data[position] || [])
                    .sort(playerByStatus)
                    .map((player, j) => (
                      <li key={j}>
                        <PlayerLink
                          player={player}
                          contractSeason={AppContext.currentSeason}
                          showInactive={player.Status === "INACTIVE"}
                        />
                      </li>
                    ))}
                </ul>
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
}

function renderTall(data: Record<string, RosterPlayer[]>) {
  const positions = [1, 2, 3, 4, 5];

  return (
    <div style={{ tableLayout: "fixed", width: "100%", marginBottom: "1rem" }}>
      {positions.map((position, i) => {
        if (!data[position]) {
          return null;
        }

        return (
          <div key={i} style={{ marginBottom: "1em" }}>
            <h5 style={{ marginBottom: 4, borderBottom: "1px solid #eee" }}>
              {Positions[position]}
            </h5>
            <ul className="list-unstyled" key={i}>
              {(data[position] || []).sort(playerByStatus).map((player, j) => (
                <li key={j} className={player.Status.toLowerCase()}>
                  <PlayerLink
                    player={player}
                    contractSeason={AppContext.currentSeason}
                    showInactive={player.Status === "INACTIVE"}
                  />
                </li>
              ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
}
