import React from "react";
import moment from "moment";
import { ParentSize } from "@visx/responsive";
import { scaleLinear } from "d3";

import { LineChart } from "../chart/LineChart";
import { groupBy } from "../../../shared/util/Collections";
import {
  decFormat,
  decFormat2,
  makePlusMinus,
  pctFormat,
} from "../../util/Format";
import { getSeasonFromDate } from "../../util/Util";
import { trpc } from "../../util/tRPC";
import { PlayerActionRoleDataOverTime } from "../../../shared/routers/ImpactRouter";
import { lineChartColors } from "../../constants/ColorConstants";

export function ImpactComparisonChart(props: {
  offDef: string;
  variable: string;
  setPlayerDate: (playerId: string, date: string) => void;
  players: {
    playerName: string;
    playerId: string;
    date: string;
    role?: string;
    color: string;
  }[];
  metric: string;
  xAxis: string;
  startDate?: string;
  endDate?: string;
}) {
  const {
    players,
    offDef,
    variable,
    setPlayerDate,
    metric,
    xAxis,
    startDate,
    endDate,
  } = props;

  const byAge = xAxis === "age";

  let actionRole = "";
  if (variable === "Total") {
    if (offDef === "Net") {
      actionRole = "net_impact";
    } else if (offDef === "Offense") {
      actionRole = "off_impact";
    } else {
      actionRole = "def_impact";
    }
  } else if (variable === "APM  Adjustment (Defense)") {
    actionRole = "def_apm";
  } else if (variable === "APM  Adjustment (Offense)") {
    actionRole = "off_apm";
  } else {
    actionRole = variable.toLocaleLowerCase().replaceAll(" ", "_");
  }

  const queries = trpc.useQueries((t) =>
    players.map((p) =>
      t.impact.getPlayerActionRoleDataOverTime({
        playerId: parseInt(p.playerId),
        actionRole: actionRole,
        startDate,
        endDate,
        position: p.role,
      })
    )
  );

  const data: Record<string, PlayerActionRoleDataOverTime[]> =
    Object.fromEntries(
      queries
        .filter((q) => q.data && q.data.length)
        .map((q) => {
          const firstRow = q.data && q.data[0];
          if (firstRow) {
            const playerId = firstRow.playerId;
            const role = firstRow.targetRole;
            const playerDataKey = role ? playerId + "-" + role : playerId;
            return [playerDataKey, q.data];
          }
          return [];
        })
    );

  const lines: {
    label: string;
    color: string;
    marker: number;
    segments: { x: number; y: number }[][];
  }[] = [];

  // Give us a way to translate from a player age to the date.
  const ageToDateScales: any = {};

  for (const player of players) {
    const playerId = player.playerId;
    const role = player.role;
    const playerDataKey = role ? playerId + "-" + role : playerId;
    const playerData = data[playerDataKey];
    if (playerData) {
      // Find the closest point to the selected date.
      const points = [...playerData].sort(
        (a, b) =>
          Math.abs(moment(a.gameDate).diff(moment(player.date))) -
          Math.abs(moment(b.gameDate).diff(moment(player.date)))
      );
      const firstPoints = points[0];
      const firstPlayerData = playerData[0];
      const lastPlayerData = playerData[playerData.length - 1];

      if (
        firstPoints === undefined ||
        firstPlayerData === undefined ||
        lastPlayerData === undefined
      )
        continue;

      const marker = byAge ? firstPoints.age : firstPoints.gameDate;

      ageToDateScales[playerId] = scaleLinear()
        .domain([firstPlayerData.age, lastPlayerData.age])
        .range([
          moment(firstPlayerData.gameDate).valueOf(),
          moment(lastPlayerData.gameDate).valueOf(),
        ]);

      // If comparing by age use a single line, if by date break by season.
      const line = {
        label: player.playerId,
        color: player.color,
        marker: byAge ? (marker as number) : moment(marker).valueOf(),
        segments: Object.values(
          groupBy(
            playerData,
            (d) => getSeasonFromDate(d.gameDate.toString()) || ""
          )
        ).map((dataBySeason) =>
          dataBySeason.map((d) => {
            let val = d.impact;
            if (metric === "Rate") {
              val = d.rate || 0;
            } else if (metric === "Ability") {
              val = d.ability || 0;
            }
            return {
              x: byAge ? d.age : moment(d.gameDate).valueOf(),
              y: metric === "Rate" ? val : val * 100,
            };
          })
        ),
      };
      lines.push(line);
    }
  }

  if (lines.length === 0) return null;

  return (
    <div style={{ position: "relative", maxWidth: 440 }}>
      <ParentSize>
        {({ width }) => (
          <LineChart
            width={width}
            height={width}
            showLegend={false}
            dragTooltip={(dragToolTipData) => {
              const xVal1 = dragToolTipData.xVal;
              const xVal2 = dragToolTipData.xVal2;
              const data1 = dragToolTipData.data;
              const data2 = dragToolTipData.data2;

              const colors = [
                ...new Set(
                  [data1, data2].flatMap((d) => d.map((dd) => dd.color))
                ),
              ];

              const rows = colors
                .map((color) => {
                  const val1 = data1.find((d) => d.color === color)?.y;
                  const val2 = data2.find((d) => d.color === color)?.y;
                  return {
                    color,
                    val1,
                    val2,
                    diff:
                      val1 !== undefined && val2 !== undefined
                        ? val2 - val1
                        : undefined,
                  };
                })
                .sort((a, b) => {
                  const aDiff = a.diff;
                  const bDiff = b.diff;
                  if (aDiff === bDiff) return 0;
                  else if (aDiff === undefined) return 1;
                  else if (bDiff === undefined) return -1;
                  else return bDiff - aDiff;
                });

              return (
                <div>
                  <b>
                    {byAge
                      ? `Age: ${decFormat(xVal1)} - ${decFormat(xVal2)}`
                      : `${moment(xVal1).format("MMM Do YYYY")} - ${moment(
                          xVal2
                        ).format("MMM Do YYYY")}`}
                  </b>
                  {rows.map((row, i) => {
                    const { color, val1, val2, diff } = row;
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          gap: 8,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            background: color,
                            width: 10,
                            height: 10,
                          }}
                        ></div>
                        <div>
                          {val1
                            ? metric === "Rate"
                              ? pctFormat(val1)
                              : decFormat2(val1)
                            : "--"}
                        </div>
                        <div>→</div>
                        <div>
                          {val2
                            ? metric === "Rate"
                              ? pctFormat(val2)
                              : decFormat2(val2)
                            : "--"}
                        </div>
                        {diff !== undefined && (
                          <b
                            style={{
                              color:
                                diff < 0
                                  ? lineChartColors.red
                                  : lineChartColors.green,
                            }}
                          >
                            {metric === "Rate"
                              ? makePlusMinus(pctFormat)(diff)
                              : makePlusMinus(decFormat2)(diff)}
                          </b>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            }}
            tooltip={(tooltipData) => {
              const firstTooltipData = tooltipData.data[0];
              if (!firstTooltipData) return null;
              const xVal = firstTooltipData.x;

              return (
                <div>
                  <b>
                    {byAge
                      ? "Age: " + decFormat(xVal)
                      : moment(xVal).format("MMM Do YYYY")}
                  </b>
                  {tooltipData.data
                    .sort((a, b) => b.y - a.y)
                    .map((d, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            gap: 8,
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              background: d.color,
                              width: 10,
                              height: 10,
                            }}
                          ></div>
                          <div>
                            {metric === "Rate"
                              ? pctFormat(d.y)
                              : decFormat2(d.y)}
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            }}
            title={
              variable === "Total"
                ? `Total ${offDef} Impact`
                : `${variable} ${metric}`
            }
            lines={lines}
            yTickPadding={0.05}
            yTickFormat={(t) =>
              metric === "Rate"
                ? pctFormat(t as number)
                : decFormat2(t as number)
            }
            xTickFormat={(t) =>
              byAge
                ? decFormat(t as number)
                : moment(t as number).format("MM/DD/YYYY")
            }
            numXTicks={5}
            xAxisLabel={byAge ? "Age" : "Date"}
            handleClick={(playerId: string, xVal: number) => {
              if (byAge) {
                const playerScale = ageToDateScales[playerId];
                const xValAsDate = playerScale(xVal);
                setPlayerDate(
                  playerId,
                  moment(xValAsDate).format("yyyy-MM-DD")
                );
              } else {
                setPlayerDate(playerId, moment(xVal).format("yyyy-MM-DD"));
              }
            }}
          />
        )}
      </ParentSize>
    </div>
  );
}
