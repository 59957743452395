import React, { useMemo, useState } from "react";
import { Row, Col } from "react-bootstrap";

import {
  PlayerSkillModelActionSimpleBreakdown,
  PlayerSkillModelActionBreakdown,
} from "../../../shared/routers/PlayerRouter";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import { groupBy } from "../../../shared/util/Collections";
import { pctFormat, decFormat, percentileFormat } from "../../util/Format";

interface SkillModelActionBreakdownRow {
  group: string;
  label: string;
  ability: number | null;
  abilityPercentile: number | null;
  rate: number | null;
  ratePercentile: number | null;
  impact: number | null;
  impactPercentile: number | null;
  roleAllocation: boolean;
  subRows?: SkillModelActionBreakdownRow[];
}

const columnHelper = createColumnHelper<SkillModelActionBreakdownRow>();

export function PlayerSkillModelActionBreakdownGrouped(
  props:
    | {
        data: PlayerSkillModelActionSimpleBreakdown[];
        showPercentile: boolean;
        isSimple: true;
      }
    | {
        data: PlayerSkillModelActionBreakdown[];
        showPercentile: boolean;
        isSimple: false;
      }
) {
  const { data, isSimple, showPercentile } = props;
  const [oSorting, oSetSorting] = useState<SortingState>();
  const [dSorting, dSetSorting] = useState<SortingState>();
  const roles = ["Offense", "Defense"];

  function prepareSimple(
    data: PlayerSkillModelActionSimpleBreakdown[]
  ): Record<string, SkillModelActionBreakdownRow[]> {
    const pd: Record<string, SkillModelActionBreakdownRow[]> = {};
    for (const role of roles) {
      const roleData = data.filter((d) => d.role === role);
      const bySkillGroup = groupBy(roleData, (d) => d.SkillGroup);
      pd[role] = Object.keys(bySkillGroup)
        .map((d) => {
          const skillGroupRows = bySkillGroup[d];
          if (skillGroupRows === undefined) return undefined;
          const firstRow = skillGroupRows[0];
          if (!firstRow) return undefined;
          if (skillGroupRows.length === 1) {
            return {
              group: firstRow.longlabel || "",
              label: firstRow.longlabel,
              ability: firstRow.impact,
              abilityPercentile: firstRow.tall_action_impact_percentile,
              rate: firstRow.tall_role_rate,
              ratePercentile: firstRow.tall_role_rate_percentile,
              impact: firstRow.tall_possession_impact,
              impactPercentile: firstRow.tall_possession_impact_percentile,
              roleAllocation: firstRow.variable === "role_allocation",
            };
          }
          return {
            group: skillGroupMap[d] || d,
            label: `Total ${skillGroupMap[d] || d}`,
            ability: firstRow.action_impact,
            abilityPercentile: firstRow.action_impact_percentile,
            rate: firstRow.role_rate,
            ratePercentile: firstRow.role_rate_percentile,
            impact: firstRow.possession_impact,
            impactPercentile: firstRow.possession_impact_percentile,
            roleAllocation: firstRow.variable === "role_allocation",
            subRows: skillGroupRows
              .map((r) => {
                return {
                  group: firstRow.longlabel || "",
                  label: r.longlabel || "Unknown",
                  ability: r.impact,
                  abilityPercentile: r.tall_action_impact_percentile,
                  rate: r.tall_role_rate,
                  ratePercentile: r.tall_role_rate_percentile,
                  impact: r.tall_possession_impact,
                  impactPercentile: r.tall_possession_impact_percentile,
                  roleAllocation: r.variable === "role_allocation",
                };
              })
              .sort(byAbility),
          };
        })
        .filter((d) => d !== undefined) as SkillModelActionBreakdownRow[];
    }
    return pd;
  }

  function prepareGrouped(
    data: PlayerSkillModelActionBreakdown[]
  ): Record<string, SkillModelActionBreakdownRow[]> {
    const pd: Record<string, SkillModelActionBreakdownRow[]> = {};
    for (const role of roles) {
      const roleData = data.filter((d) => d.role === role);
      const bySkillGroup = groupBy(roleData, (d) => d.SkillGroup);
      pd[role] = Object.keys(bySkillGroup)
        .map((d) => {
          const skillGroupRows = bySkillGroup[d];
          if (skillGroupRows === undefined) return undefined;
          const firstRow = skillGroupRows[0];
          if (!firstRow) return undefined;
          return {
            group: skillGroupMap[d] || d,
            label: `Total ${skillGroupMap[d] || d}`,
            ability: firstRow.action_impact,
            abilityPercentile: firstRow.action_impact_percentile,
            rate: firstRow.role_rate,
            ratePercentile: firstRow.role_rate_percentile,
            impact: firstRow.possession_impact,
            impactPercentile: firstRow.possession_impact_percentile,
            roleAllocation: firstRow.variable === "role_allocation",
            subRows: skillGroupRows
              .map((r) => {
                return {
                  group: firstRow.longlabel || "",
                  label: r.longlabel || r.variable,
                  ability: r.impact,
                  abilityPercentile: null,
                  rate: null,
                  ratePercentile: null,
                  impact:
                    r.variable === "role_allocation"
                      ? r.role_rate_allocation
                      : null,
                  impactPercentile: null,
                  roleAllocation: r.variable === "role_allocation",
                };
              })
              .sort(byAbility),
          };
        })
        .filter((d) => d !== undefined) as SkillModelActionBreakdownRow[];
    }
    return pd;
  }

  const preparedData = isSimple ? prepareSimple(data) : prepareGrouped(data);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("group", {
        header: isSimple ? "Role Group" : "Role",
        cell: (info) => (info.row.depth === 0 ? info.getValue() : ""),
        meta: { textAlign: "left" },
      }),
      columnHelper.accessor("label", {
        header: isSimple ? "Role" : "Role Factor(s)",
        cell: (info) => info.getValue(),
        meta: { textAlign: "left" },
      }),
      columnHelper.accessor("ability", {
        header: "Ability",
        cell: (info) => {
          const value = decFormat(info.getValue());
          return info.row.depth === 0 ? <b>{value}</b> : value;
        },
      }),
      columnHelper.accessor("abilityPercentile", {
        header: "Ability",
        cell: (info) => {
          const value = percentileFormat(info.getValue());
          return info.row.depth === 0 ? <b>{value}</b> : value;
        },
      }),
      columnHelper.accessor("rate", {
        header: "Rate",
        cell: (info) => {
          const value = pctFormat(info.getValue());
          return info.row.depth === 0 ? <b>{value}</b> : value;
        },
      }),
      columnHelper.accessor("ratePercentile", {
        header: "Rate",
        cell: (info) => {
          const value = percentileFormat(info.getValue());
          return info.row.depth === 0 ? <b>{value}</b> : value;
        },
      }),
      columnHelper.accessor("impact", {
        header: "Impact",
        cell: (info) => {
          const value = decFormat(info.getValue());
          return info.row.depth === 0 ? <b>{value}</b> : value;
        },
      }),
      columnHelper.accessor("impactPercentile", {
        header: "Impact",
        cell: (info) => {
          const value = percentileFormat(info.getValue());
          return info.row.depth === 0 ? <b>{value}</b> : value;
        },
      }),
    ];
  }, [isSimple]);

  const hiddenColumns = {
    ability: !showPercentile,
    abilityPercentile: showPercentile,
    rate: !showPercentile,
    ratePercentile: showPercentile,
    impact: !showPercentile,
    impactPercentile: showPercentile,
  };

  return (
    <div>
      <Row>
        {roles.map((role) => {
          const dataForRole = preparedData[role];
          if (!dataForRole) return null;
          return (
            <Col key={role}>
              <div style={{ textAlign: "center" }}>
                <b>{role}</b>
              </div>
              <Table
                data={dataForRole.sort(byImpact)}
                columns={columns}
                hiddenColumns={hiddenColumns}
                autoWidth={true}
                showRowIndex={false}
                sorting={role === "Offense" ? oSorting : dSorting}
                setSorting={role === "Offense" ? oSetSorting : dSetSorting}
                expandColumnId="group"
                disableStickyColumn={true}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

const skillGroupMap: Record<string, string> = {
  // //offensive_rebounding: "Offensive Rebounding",
  // catch_and_go_drive_driver: "Catch and Go Driver",
  // PNR_popper: "PNR Popper",
  rim_spacer: "Rim Spacer",
  rim_spacer_defender: "Rim Spacer Defender",
  // non_action_spacer: "Non-Action Spacer",
  perimeter_spacer: "Perimeter Spacer",
  perimeter_spacer_defender: "Perimeter Spacer Defender",
  misc_offense: "Misc. Offense",
  misc_defense: "Misc. Defense",
  // PNR_roller: "PNR Roller",
  // PNR_ballhandler: "PNR Ballhandler",
  transition_offense: "Transition Offense",
  // cut_cutter: "Cutter",
  // run_off_screen_run_off_screen: "Run Off Screen",
  // // advantage_drive_driver: "Advantage Driver",
  // post_post: "Post Ballhandler",
  // non_action_ballhandler: "Non-Action Ballhandler",
  // iso_iso: "Iso Ballhandler",
  // rim_spacer_defender: "Rim Spacer Defender",
  // // defensive_rebounding: "Defensive Rebounding",
  transition_defense: "Transition Defense",
  // // PNR_roller_defender: "PNR Roller Defender",
  PNR_screener_defender: "PNR Screener Defender",
  // PNR_ballhandler_defender: "PNR Ballhandler Defender",
  // post_post_defender: "Post Defender",
  // iso_iso_defender: "Iso Defender",
  // non_action_spacer_defender: "Non-Action Spacer Defender",
  // cut_cutter_defender: "Cutter Defender",
  // // PNR_popper_defender: "PNR Popper Defender",
  // run_off_screen_run_off_screen_defender: "Run Off Screen Defender",
  // // advantage_drive_drive_defender: "Advantage Drive Defender",
  // perimeter_spacer_defender: "Perimeter Spacer Defender",
  // non_action_ballhandler_defender: "Non-Action Ballhandler Defender",
  // catch_and_go_drive_drive_defender: "Catch and Go Driver Defender",
};

// Compares impact of two entries for sorting
function byImpact(
  a: SkillModelActionBreakdownRow,
  b: SkillModelActionBreakdownRow
) {
  return (a.impact || 0) > (b.impact || 0) ? -1 : 1;
}

// Compares ability of two entries for sorting. Also ensures that role_allocation
// entiries appear at the end.
function byAbility(
  a: SkillModelActionBreakdownRow,
  b: SkillModelActionBreakdownRow
) {
  if (a.roleAllocation && b.roleAllocation) return 0;
  if (a.roleAllocation) return 1;
  if (b.roleAllocation) return -1;
  return (a.ability || 0) > (b.ability || 0) ? -1 : 1;
}
