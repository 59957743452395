import React, { useCallback, useMemo } from "react";
import { Circle, Line } from "@visx/shape";
import { MarkerArrow, MarkerCircle } from "@visx/marker";
import { Group } from "@visx/group";
import { scaleLinear, scaleLog } from "@visx/scale";
import { useTooltip, TooltipWithBounds, defaultStyles } from "@visx/tooltip";
import { ParentSize } from "@visx/responsive";
import { EventType } from "@visx/event/lib/types";
import { localPoint } from "@visx/event";

import { Court } from "../court/Court";
import { CrashAttempt } from "../../../shared/routers/GameRouter";
import { gameClockFormat, period } from "../../util/Format";
import { generateDomain } from "../../util/Util";
import { pctFormat } from "../../util/Format";
import { lineChartColors } from "../../constants/ColorConstants";
import { Restrict } from "../core/Restrict";

const NOT_HIGHLIGHTED_OPACITY = 0.3;
const BASKETBALL_RADIUS = 6;
const TOUCH_HOVER_RADIUS = 5;
const STUCK_SIZE = 4;

export function CrashingViz(props: {
  data: CrashAttempt[];
  disableHover?: boolean;
}) {
  const { data, disableHover } = props;

  return (
    <ParentSize>
      {({ width: parentWidth }) => (
        <CrashingVizInner
          width={parentWidth}
          crashAttempts={data}
          disableHover={disableHover}
        />
      )}
    </ParentSize>
  );
}

function CrashingVizInner(props: {
  width: number;
  crashAttempts: CrashAttempt[];
  disableHover?: boolean;
}) {
  const { crashAttempts, disableHover } = props;
  // Returns a color based on rebound probability.
  const crashColorScale = scaleLog<string>()
    .domain([0.5, 100])
    .range([lineChartColors.yellow, lineChartColors.green])
    .clamp(true);

  // Returns a color based on distance moved in the "get back" direction.
  const getBackColorScale = scaleLinear<string>()
    .domain([0, 30])
    .range([lineChartColors.turqoiuse, lineChartColors.blue]);

  const {
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    showTooltip,
    hideTooltip,
  } = useTooltip<{
    data: CrashAttempt;
  }>();

  const width = Math.min(props.width, 440);
  const maxDistance = 45;
  // Flip x and y to get the rim near the top.
  const xDomain = [-25, 25];
  const yDomain = [-47 + maxDistance, -47];
  // Height calculation to match <Court /> component.
  const height = (width * (maxDistance * 10)) / 500;

  const xMin = 0,
    xMax = width,
    yMin = height,
    yMax = 0;

  const xScale = scaleLinear<number>()
    .domain(xDomain)
    .range([xMin, xMax])
    .clamp(true);
  const yScale = scaleLinear<number>()
    .domain(yDomain)
    .range([yMin, yMax])
    .clamp(true);

  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    color: "black",
  };

  const lineSegments = useMemo(() => {
    return crashAttempts.map((ca) => {
      // Starting loc for player.
      const playerX = xScale(ca.shotLocY);
      const playerY = yScale(ca.shotLocX);

      // Loc when ball hits rim. If its null we have bad data and lets just
      // re-use the shot loc.
      const midX = xScale(ca.rimLocY === null ? ca.shotLocY : ca.rimLocY);
      const midY = yScale(ca.rimLocX === null ? ca.shotLocX : ca.rimLocX);

      // Don't plot paths for stuck, just the location at time of shot.
      if (ca.action === "Stuck") {
        return {
          crashAttempt: ca,
          line: [{ x: playerX, y: playerY, color: lineChartColors.red }],
        };
      }

      // Don't plot paths for no crash shooter, just the location at time
      // of shot.
      if (ca.isShooter) {
        return {
          crashAttempt: ca,
          line: [{ x: playerX, y: playerY, color: "black" }],
        };
      }

      const points = [
        { x: playerX, y: playerY },
        { x: midX, y: midY },
      ];

      if (ca.reboundLocY !== null && ca.reboundLocX !== null) {
        // Loc when ball is rebounded.
        const finalX = xScale(ca.reboundLocY);
        const finalY = yScale(ca.reboundLocX);
        points.push({ x: finalX, y: finalY });
      }

      const lastPoint = points[points.length - 1];
      const numInterpolationPts = Math.round(
        Math.sqrt(
          Math.abs(lastPoint ? lastPoint.x - playerX : 0) +
            Math.abs(lastPoint ? lastPoint.y - playerY : 0)
        ) * 4
      );

      // If 3 points then use cubic bezier, otherwise use linear interpolation.
      const interpolatedPts =
        points.length === 3
          ? interpolateCubicBezier(points, numInterpolationPts)
          : interpolateLinear(points, numInterpolationPts);

      if (ca.action === "Got Back") {
        const interpolatedLine = interpolatedPts.map((pt) => {
          return {
            x: pt.x,
            y: pt.y,
            color: getBackColorScale(Math.abs(pt.y - playerY)),
          };
        });

        return {
          crashAttempt: ca,
          line: interpolatedLine,
        };
      } else {
        const interpolatedPcts = generateDomain(
          ca.rbPctShot,
          (ca.rbPctRim + (ca.isRebounder ? 100 : 0)) / 2,
          numInterpolationPts
        );

        // TODO(chrisbu): Figure out the off by 1 error and remove this hack
        // Until then just duplicate the last value when it is missing.
        if (interpolatedPcts.length < interpolatedPts.length) {
          const lastInterpolatedPct =
            interpolatedPcts[interpolatedPcts.length - 1];
          if (lastInterpolatedPct) {
            interpolatedPcts.push(lastInterpolatedPct);
          }
        }

        const interpolatedLine = interpolatedPts.map((pt, i) => {
          return {
            x: pt.x,
            y: pt.y,
            color: crashColorScale(interpolatedPcts[i] || 0),
          };
        });

        return {
          crashAttempt: ca,
          line: interpolatedLine,
        };
      }
    });
  }, [crashAttempts, crashColorScale, getBackColorScale, xScale, yScale]);

  const handleClickFn = useCallback(() => {
    if (tooltipData && tooltipData.data.url) {
      window.open(tooltipData.data.url, "_blank");
    }
  }, [tooltipData]);

  const handleTooltip = useCallback(
    (event: EventType) => {
      if (disableHover) return;

      const { x, y } = localPoint(event) || { x: 0, y: 0 };

      for (const ls of lineSegments) {
        const currentCrashAttempt = ls.crashAttempt;
        for (const pt of ls.line) {
          if (
            Math.abs(x - pt.x) < TOUCH_HOVER_RADIUS &&
            Math.abs(y - pt.y) < TOUCH_HOVER_RADIUS
          ) {
            showTooltip({
              tooltipData: { data: currentCrashAttempt },
              tooltipLeft: x,
              tooltipTop: y,
            });
            return;
          }
        }
      }

      hideTooltip();
    },
    [disableHover, hideTooltip, lineSegments, showTooltip]
  );

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: width,
          margin: "auto",
        }}
      >
        <Court
          pointerEventsNone={true}
          width={width}
          maxDistance={maxDistance}
          hideBenchLines={true}
          absolute={true}
          hideBorder={true}
        />
        <svg width={width} height={height}>
          <Group>
            {lineSegments.map((ls) => {
              const ca = ls.crashAttempt;

              // Something is highlighted but not this.
              const shouldHide = !!(
                tooltipData && tooltipData.data.id !== ca.id
              );

              const highlighted = tooltipData && tooltipData.data.id === ca.id;

              const uniqueId = ca.id;

              if (ca.action === "Stuck") {
                return (
                  <Group key={uniqueId}>
                    <Line
                      opacity={shouldHide ? NOT_HIGHLIGHTED_OPACITY : 1}
                      from={{
                        x: xScale(ca.shotLocY) - STUCK_SIZE,
                        y: yScale(ca.shotLocX) - STUCK_SIZE,
                      }}
                      to={{
                        x: xScale(ca.shotLocY) + STUCK_SIZE,
                        y: yScale(ca.shotLocX) + STUCK_SIZE,
                      }}
                      strokeWidth={STUCK_SIZE}
                      stroke={lineChartColors.red}
                    />
                    <Line
                      opacity={shouldHide ? NOT_HIGHLIGHTED_OPACITY : 1}
                      from={{
                        x: xScale(ca.shotLocY) - STUCK_SIZE,
                        y: yScale(ca.shotLocX) + STUCK_SIZE,
                      }}
                      to={{
                        x: xScale(ca.shotLocY) + STUCK_SIZE,
                        y: yScale(ca.shotLocX) - STUCK_SIZE,
                      }}
                      strokeWidth={STUCK_SIZE}
                      stroke={lineChartColors.red}
                    />
                    {highlighted &&
                      ca.reboundBallLocX &&
                      ca.reboundBallLocY && (
                        <circle
                          stroke="black"
                          cx={xScale(ca.reboundBallLocY) as number}
                          cy={yScale(ca.reboundBallLocX) as number}
                          r={BASKETBALL_RADIUS}
                          fill={"orange"}
                          opacity={
                            tooltipData && !highlighted
                              ? NOT_HIGHLIGHTED_OPACITY
                              : 1
                          }
                        />
                      )}
                  </Group>
                );
              }

              const markerStart = `url(#${uniqueId}-circle)`;
              const markerEnd = `url(#${uniqueId}-arrow)`;

              if (ca.isShooter) {
                return (
                  <Group key={uniqueId}>
                    <Circle
                      cx={xScale(ca.shotLocY)}
                      cy={yScale(ca.shotLocX)}
                      r={STUCK_SIZE}
                      fill={"white"}
                      stroke={"gray"}
                      strokeWidth={STUCK_SIZE}
                      opacity={shouldHide ? NOT_HIGHLIGHTED_OPACITY : 1}
                    />
                    {highlighted &&
                      ca.reboundBallLocX &&
                      ca.reboundBallLocY && (
                        <circle
                          stroke="black"
                          cx={xScale(ca.reboundBallLocY) as number}
                          cy={yScale(ca.reboundBallLocX) as number}
                          r={BASKETBALL_RADIUS}
                          fill={"orange"}
                          opacity={
                            tooltipData && !highlighted
                              ? NOT_HIGHLIGHTED_OPACITY
                              : 1
                          }
                        />
                      )}
                  </Group>
                );
              }

              const interpolatedLine = ls.line;
              const firstInterpolatedLine = interpolatedLine[0];
              const firstColor = firstInterpolatedLine
                ? firstInterpolatedLine.color
                : "black";
              const lastInterpolatedLine =
                interpolatedLine[interpolatedLine.length - 1];
              const lastColor = lastInterpolatedLine
                ? lastInterpolatedLine.color
                : "black";

              return (
                <Group key={uniqueId}>
                  <MarkerArrow
                    id={`${uniqueId}-arrow`}
                    stroke={lastColor}
                    size={3}
                    orient="auto-start-reverse"
                  />
                  <MarkerCircle
                    id={`${uniqueId}-circle`}
                    fill={"white"}
                    stroke={firstColor}
                    size={1}
                  />
                  <ColoredPath
                    data={interpolatedLine}
                    markerEnd={markerEnd}
                    markerStart={markerStart}
                    opacity={shouldHide ? NOT_HIGHLIGHTED_OPACITY : 1}
                  />
                  {highlighted && ca.reboundBallLocX && ca.reboundBallLocY && (
                    <circle
                      stroke="black"
                      cx={xScale(ca.reboundBallLocY) as number}
                      cy={yScale(ca.reboundBallLocX) as number}
                      r={BASKETBALL_RADIUS}
                      fill={"orange"}
                      opacity={
                        tooltipData && !highlighted
                          ? NOT_HIGHLIGHTED_OPACITY
                          : 1
                      }
                    />
                  )}
                </Group>
              );
            })}
            <rect
              style={{ cursor: tooltipData ? "pointer" : undefined }}
              x={0}
              y={0}
              width={width}
              height={height}
              onClick={handleClickFn}
              onTouchStart={handleTooltip}
              fill={"transparent"}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => hideTooltip()}
            />
          </Group>
        </svg>
        {tooltipData && (
          <TooltipWithBounds
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyles}
          >
            <div>
              {gameClockFormat(tooltipData.data.gameClock)}{" "}
              {period(tooltipData.data.period)}
            </div>
            <div>
              {tooltipData.data.player} - {tooltipData.data.action}
            </div>
            <Restrict roles={["admin"]}>
              <div>
                <div>
                  <b>[BIA ADMIN ONLY]:</b>
                </div>
                <div>{`p(ORB) (Shot): ${pctFormat(
                  tooltipData.data.rbPctShot / 100
                )}`}</div>
                <div>{`p(ORB) (Rim): ${pctFormat(
                  tooltipData.data.rbPctRim / 100
                )}`}</div>
                <div>{`p(ORB) (Rebound): ${pctFormat(
                  (tooltipData.data.rbPctRim +
                    (tooltipData.data.isRebounder ? 100 : 0)) /
                    2 /
                    100
                )}`}</div>
                <div>{`${
                  tooltipData.data.isRebounder ? "Got Rebound" : ""
                }`}</div>
              </div>
            </Restrict>
          </TooltipWithBounds>
        )}
      </div>
    </div>
  );
}

function ColoredPath(props: {
  data: { x: number; y: number; color: string }[];
  markerEnd?: string;
  markerStart?: string;
  opacity: number;
}) {
  const { data, markerEnd, markerStart, opacity } = props;

  if (data.length < 2) {
    return null; // Return null if there are not enough points
  }

  const pathSegments = [];

  for (let i = 1; i < data.length; i++) {
    const prevPoint = data[i - 1];
    const currentPoint = data[i];

    if (prevPoint === undefined || currentPoint === undefined) continue;

    // Create a path segment from the previous point to the current point
    const segment = `M ${prevPoint.x} ${prevPoint.y} L ${currentPoint.x} ${currentPoint.y}`;

    // Add the segment to the pathSegments array with the color of the previous point
    pathSegments.push(
      <path
        strokeLinejoin="bevel"
        opacity={opacity}
        key={i}
        d={segment}
        stroke={prevPoint.color}
        strokeWidth={4}
        fill="none"
        markerStart={markerStart && i === 1 ? markerStart : undefined}
        markerEnd={markerEnd && i === data.length - 1 ? markerEnd : undefined}
      />
    );
  }

  return <svg>{pathSegments}</svg>;
}

function interpolateLinear(
  points: { x: number; y: number }[],
  numPoints: number
) {
  if (numPoints === 0) return points;

  const interpolatedPoints = [];
  for (let i = 0; i < points.length - 1; i++) {
    const start = points[i];
    const end = points[i + 1];
    if (start === undefined || end === undefined) continue;
    const xDiff = end.x - start.x;
    const yDiff = end.y - start.y;
    const xStep = xDiff / numPoints;
    const yStep = yDiff / numPoints;

    for (let j = 0; j < numPoints; j++) {
      interpolatedPoints.push({
        x: start.x + j * xStep,
        y: start.y + j * yStep,
      });
    }
  }

  return interpolatedPoints;
}

function interpolateCubicBezier(
  points: { x: number; y: number }[],
  numPoints: number
) {
  const interpolatedPoints = [];
  const start = points[0];
  const control = points[1];
  const end = points[2];

  if (start === undefined || control === undefined || end === undefined) {
    return [];
  }

  for (let t = 0; t <= 1; t += 1 / numPoints) {
    const x =
      Math.pow(1 - t, 3) * start.x +
      3 * Math.pow(1 - t, 2) * t * control.x +
      3 * (1 - t) * t * t * control.x +
      Math.pow(t, 3) * end.x;
    const y =
      Math.pow(1 - t, 3) * start.y +
      3 * Math.pow(1 - t, 2) * t * control.y +
      3 * (1 - t) * t * t * control.y +
      Math.pow(t, 3) * end.y;

    interpolatedPoints.push({ x, y });
  }

  return interpolatedPoints;
}
