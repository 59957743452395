import React, { useState, useRef, useEffect } from "react";
import { scaleLinear } from "d3";
import { Overlay } from "react-bootstrap";

import { Comet } from "./Comet";
import { getContrastingTeamColor } from "../../util/Colors";
import { useWindowSize } from "../../util/Hooks";

export function CompareBar(props: any) {
  const {
    height,
    value,
    compare,
    scale,
    compareTeam,
    onMouseEnter,
    onMouseLeave,
    format,
    compareFormat,
    teams,
  } = props;
  const windowSize = useWindowSize();
  const [width, setWidth] = useState(0);
  const [highlight, setHighlight] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const target = useRef(null);

  useEffect(() => {
    if (!containerRef.current) return;
    setWidth(containerRef.current.offsetWidth);
  }, [windowSize]);

  const middle = height / 2;
  const radius = height / 3.5;

  const widthScale = scaleLinear()
    .domain([0, 100])
    .range([0, width - radius]);
  const val = widthScale(scale(value));
  const comp = widthScale(scale(compare));

  const handleMouseEnter = () => {
    setHighlight(true);

    if (onMouseEnter) {
      onMouseEnter();
    }
  };

  const handleMouseLeave = () => {
    setHighlight(false);

    if (onMouseLeave) {
      onMouseLeave();
    }
  };

  const renderHighlight = (val: number, comp: number) => {
    const formattedCompare = compareFormat(compare, value, format);

    const top = 0;

    let left, right;
    if (comp < val) {
      right = width - comp + 5;
    } else {
      left = comp + 5;
    }

    return (
      <div
        style={{
          left,
          right,
          top,
          whiteSpace: "nowrap",
          fontSize: "0.9em",
          lineHeight: 0.5,
          pointerEvents: "none",
          position: "absolute",
          padding: 4,
          background: "#eee",
          border: "1px solid #ddd",
          borderRadius: 2,
        }}
      >
        {formattedCompare}
      </div>
    );
  };

  // TODO(chrisbu): There is definitely a better way to do this but for now
  // just pass both teams into this component and then use this logic to figure
  // out what the other team is so we can make sure colors are contrasting.
  const otherTeam =
    teams.find((t: any) => t.toString() !== compareTeam.toString()) ||
    compareTeam;
  const colors = getContrastingTeamColor(compareTeam, otherTeam);

  return (
    <div ref={containerRef} style={{ position: "relative" }}>
      <svg
        height={height}
        width={"100%"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Comet
          ref={target}
          y={middle}
          xHead={val}
          xTail={comp}
          r={radius}
          fillColor={colors.primary}
          outlineColor={colors.secondary}
          circleStrokeWidth={1.5}
        />
      </svg>
      <Overlay target={target.current} show={highlight}>
        {renderHighlight(val, comp)}
      </Overlay>
    </div>
  );
}
