import React, { useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { createStyles, makeStyles } from "@material-ui/styles";

import AppContext from "../../../shared/AppContext";
import { GameSelector } from "./GameSelector";
import { TeamSchedule } from "../../../shared/routers/TeamRouter";
import { NbaDates } from "../../../shared/NbaDates";
import { TeamContext } from "../../TeamContext";

export interface GameFiltersInterface {
  seasonStart: string;
  seasonEnd: string;
  preseason: number;
  regularSeason: number;
  postseason: number;
  home: number;
  away: number;
  wins: number;
  losses: number;
  quarters: string[];
  opponent?: string;
  games: string[];
}

const useStyles = makeStyles(() =>
  createStyles({
    controlGroup: {
      marginBottom: 15,
    },
    inlineSelect: {
      width: "auto",
      display: "inline-block",
    },
    controlContainer: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      gap: 8,
    },
    checkBoxList: {
      padding: 0,
      margin: 0,
      listStyleType: "none",
    },
    checkBoxListItem: {
      display: "inline-block",
      marginRight: 5,
      marginLeft: 5,
    },
    checkbox: {
      display: "inline-block",
      paddingRight: 4,
    },
    quartersContainer: { display: "flex", justifyContent: "space-between" },
  })
);

export function GameFilters(props: {
  teamId: number;
  onChange: (
    key: string,
    val: string | number | null | string[] | number[]
  ) => void;
  onReset?: () => void;
  filters: GameFiltersInterface;
  games: TeamSchedule[];
  defaultGameSelectorVisible?: boolean;
  hideResetButton?: boolean;
  hideSeasonFilter?: boolean;
  hideQuartersFilter?: boolean;
  hideResetFilter?: boolean;
  hideGameSelectorToggle?: boolean;
  hideGameSelectorCategories?: boolean;
  hideStatusMessage?: boolean;
}) {
  const classes = useStyles();
  const {
    teamId,
    onChange,
    onReset,
    filters,
    games,
    defaultGameSelectorVisible,
    hideQuartersFilter,
    hideResetButton,
    hideSeasonFilter,
    hideGameSelectorToggle,
    hideGameSelectorCategories,
    hideStatusMessage,
  } = props;
  const teams = useContext(TeamContext).teams;
  const [gameSelectorVisible, setGameSelectorVisible] = useState(
    !!defaultGameSelectorVisible
  );

  const seasons = Object.keys(NbaDates)
    .reverse()
    .slice(0, 15)
    .map((season) => {
      const label = parseInt(season, 10) - 1 + "-" + season.substr(2, 4);
      return { value: season, label };
    });

  const seasonStart = filters.seasonStart || AppContext.currentSeason;
  const seasonEnd = filters.seasonEnd || AppContext.currentSeason;
  const opponent = filters.opponent || "any";

  const handleQuarterClick = (quarter: string) => {
    const quarterSet = new Set<string>(filters.quarters);
    if (quarterSet.has(quarter)) {
      quarterSet.delete(quarter);
    } else {
      quarterSet.add(quarter);
    }
    onChange("quarters", Array.from(quarterSet).sort());
  };

  const shouldShowGameSelectorToggle = !hideGameSelectorToggle;

  const toggleGameSelectorVisibility = () => {
    const newVal = !gameSelectorVisible;
    // When we hide the game selector remove all games from the filter.
    if (!newVal) {
      onChange("games", []);
    } else {
      // When we show the game selector make the initial selection match the
      // other filters.
      const filteredGames = games
        .filter((g) => {
          const showHomeGames = !!filters.home;
          const showAwayGames = !!filters.away;
          const showWins = !!filters.wins;
          const showLosses = !!filters.losses;

          const isHomeGame = g.homeTeamId === teamId;
          const isWin = g.winningteamid === teamId;

          const homeAway =
            (isHomeGame && showHomeGames) || (!isHomeGame && showAwayGames);

          const winLoss = (isWin && showWins) || (!isWin && showLosses);

          const showPre = !!filters.preseason;
          const showPost = !!filters.postseason;
          const showReg = !!filters.regularSeason;

          const isPre = g.gametypeid === "X";
          const isReg = g.gametypeid === "R";
          const isPost = g.gametypeid === "P";

          const gameType =
            (isPre && showPre) || (isReg && showReg) || (isPost && showPost);

          const opp =
            !filters.opponent ||
            filters.opponent === g.awayTeamId.toString() ||
            filters.opponent === g.homeTeamId.toString();

          return homeAway && winLoss && gameType && opp;
        })
        .map((g) => g.gameid);
      onChange("games", filteredGames);
    }
    setGameSelectorVisible(newVal);
  };

  // Only enable game selector toggle if the seasons match up.
  let gameSelectorToggle;
  if (shouldShowGameSelectorToggle) {
    gameSelectorToggle = (
      <Button onClick={toggleGameSelectorVisibility}>
        {gameSelectorVisible ? "Hide Games" : "Show Games"}
      </Button>
    );
  }

  let gameTypeControls;
  let gamePropertyControls;
  let gameSelectorElement;

  // When an individual game is clicked on, toggle its inclusion in the set.
  const handleGameToggle = (gameId: string) => {
    const gamesSet = new Set<string>(filters.games);
    if (gamesSet.has(gameId)) {
      gamesSet.delete(gameId);
    } else {
      gamesSet.add(gameId);
    }
    onChange("games", Array.from(gamesSet));
  };

  // Only show game selector if toggled on.
  if (gameSelectorVisible && shouldShowGameSelectorToggle) {
    gameSelectorElement = (
      <div className={classes.controlGroup}>
        <label>Game Selector</label>
        <GameSelector
          games={games}
          teamId={teamId}
          hideStatusMessage={hideStatusMessage}
          hideGameSelectorCategories={hideGameSelectorCategories}
          selectedGames={filters.games}
          onToggleGame={handleGameToggle}
          onSetSelection={(gameIds) => onChange("games", gameIds)}
        />
      </div>
    );
  } else {
    // Only show game property controls and game type controls if not showing
    // game selector.
    gameTypeControls = (
      <div className={classes.controlGroup}>
        <label>Game Type</label>
        <ul className={classes.checkBoxList}>
          <li className={classes.checkBoxListItem}>
            <Form.Check
              className={classes.checkbox}
              checked={!!filters.preseason}
              onChange={() => onChange("preseason", filters.preseason ? 0 : 1)}
            />
            Preseason
          </li>
          <li className={classes.checkBoxListItem}>
            <Form.Check
              className={classes.checkbox}
              checked={!!filters.regularSeason}
              onChange={() =>
                onChange("regularSeason", filters.regularSeason ? 0 : 1)
              }
            />
            Regular
          </li>
          <li className={classes.checkBoxListItem}>
            <Form.Check
              className={classes.checkbox}
              checked={!!filters.postseason}
              onChange={() =>
                onChange("postseason", filters.postseason ? 0 : 1)
              }
            />
            Playoffs
          </li>
        </ul>
      </div>
    );

    gamePropertyControls = (
      <div className={classes.controlGroup}>
        <label>Game Properties</label>
        <div className={classes.controlContainer}>
          <ul className={classes.checkBoxList}>
            <li className={classes.checkBoxListItem}>
              <Form.Check
                className={classes.checkbox}
                checked={!!filters.home}
                onChange={() => onChange("home", filters.home ? 0 : 1)}
              />
              Home
            </li>
            <li className={classes.checkBoxListItem}>
              <Form.Check
                className={classes.checkbox}
                checked={!!filters.away}
                onChange={() => onChange("away", filters.away ? 0 : 1)}
              />
              Away
            </li>
          </ul>
          <ul className={classes.checkBoxList}>
            <li className={classes.checkBoxListItem}>
              <Form.Check
                className={classes.checkbox}
                checked={!!filters.wins}
                onChange={() => onChange("wins", filters.wins ? 0 : 1)}
              />
              Wins
            </li>
            <li className={classes.checkBoxListItem}>
              <Form.Check
                className={classes.checkbox}
                checked={!!filters.losses}
                onChange={() => onChange("losses", filters.losses ? 0 : 1)}
              />
              Losses
            </li>
          </ul>
          {
            <Form.Select
              className={classes.inlineSelect}
              value={opponent}
              onChange={(evt: React.ChangeEvent<HTMLSelectElement>) =>
                onChange(
                  "opponent",
                  evt.target.value === "any" ? "" : evt.target.value
                )
              }
            >
              <option value={"any"}>Any Opponent</option>
              {teams.map((team, i) =>
                team.teamid === teamId ? null : (
                  <option key={i} value={team.teamid}>
                    {team.teamshortname}
                  </option>
                )
              )}
            </Form.Select>
          }
        </div>
      </div>
    );
  }

  return (
    <div>
      <Row>
        {!hideSeasonFilter && (
          <Col md={4}>
            <div className={classes.controlGroup}>
              <label>Seasons</label>
              <div className={classes.controlContainer}>
                <Form.Select
                  className={classes.inlineSelect}
                  value={seasonStart}
                  onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                    const newVal = evt.target.value;
                    if (newVal !== AppContext.currentSeason) {
                      onChange("games", []);
                    }
                    onChange("seasonStart", newVal);
                  }}
                >
                  {seasons.map((season) => (
                    <option key={season.value} value={season.value}>
                      {season.label}
                    </option>
                  ))}
                </Form.Select>
                to
                <Form.Select
                  className={classes.inlineSelect}
                  value={seasonEnd}
                  onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                    const newVal = evt.target.value;
                    if (newVal !== AppContext.currentSeason) {
                      onChange("games", []);
                    }
                    onChange("seasonEnd", evt.target.value);
                  }}
                >
                  {seasons.map((season) => (
                    <option key={season.value} value={season.value}>
                      {season.label}
                    </option>
                  ))}
                </Form.Select>
                {gameSelectorToggle}
              </div>
            </div>
          </Col>
        )}
        {!hideSeasonFilter && <Col md={4}>{gameTypeControls}</Col>}
        {!hideQuartersFilter && (
          <Col>
            <div className={classes.controlGroup}>
              <label>Quarters</label>
              <div className={classes.quartersContainer}>
                <ul className={classes.checkBoxList}>
                  <li className={classes.checkBoxListItem}>
                    <Form.Check
                      className={classes.checkbox}
                      checked={filters.quarters.includes("1")}
                      onChange={() => handleQuarterClick("1")}
                    />
                    1<sup>st</sup>
                  </li>
                  <li className={classes.checkBoxListItem}>
                    <Form.Check
                      className={classes.checkbox}
                      checked={filters.quarters.includes("2")}
                      onChange={() => handleQuarterClick("2")}
                    />
                    2<sup>nd</sup>
                  </li>
                  <li className={classes.checkBoxListItem}>
                    <Form.Check
                      className={classes.checkbox}
                      checked={filters.quarters.includes("3")}
                      onChange={() => handleQuarterClick("3")}
                    />
                    3<sup>rd</sup>
                  </li>
                  <li className={classes.checkBoxListItem}>
                    <Form.Check
                      className={classes.checkbox}
                      checked={filters.quarters.includes("4")}
                      onChange={() => handleQuarterClick("4")}
                    />
                    4<sup>th</sup>
                  </li>
                  <li className={classes.checkBoxListItem}>
                    <Form.Check
                      className={classes.checkbox}
                      checked={filters.quarters.includes("OT")}
                      onChange={() => handleQuarterClick("OT")}
                    />
                    OT
                  </li>
                </ul>
                {!hideResetButton && onReset && (
                  <Button onClick={onReset}>Reset</Button>
                )}
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={12}>
          {gamePropertyControls}
          {gameSelectorElement}
        </Col>
      </Row>
    </div>
  );
}
