import React from "react";
import { Link } from "react-router-dom";
import { rgb } from "d3";

import { DeleteButton } from "../core/DeleteButton";
import { MultiSelect } from "../core/MultiSelect";
import { PlayerPhoto } from "../player/PlayerPhoto";
import { trpc } from "../../util/tRPC";

export function SkillPlayerSelector(props: {
  label: string;
  color: string;
  players: Record<string, string>;
  selectedPlayerId: string | null;
  setSelectedPlayerId: (val: string | null) => void;
  showDelete: boolean;
  onDeleteClick: () => void;
}) {
  const {
    label,
    color,
    players,
    selectedPlayerId,
    setSelectedPlayerId,
    showDelete,
    onDeleteClick,
  } = props;
  const useBioData = trpc.player.getPlayerBios.useQuery(
    {
      playerIds: selectedPlayerId ? [selectedPlayerId] : [],
    },
    { enabled: selectedPlayerId !== null }
  );

  const bio = useBioData.data ? useBioData.data[0] : undefined;

  const backgroundColor = rgb(color);
  backgroundColor.opacity = 0.1;

  return (
    <div
      style={{
        marginTop: 8,
        width: 184,
      }}
    >
      <div style={{ display: "flex", gap: 8 }}>
        <div>{showDelete && <DeleteButton onClick={onDeleteClick} />}</div>
        <h4>{label}</h4>
      </div>
      <div style={{ display: "flex", gap: 8, marginTop: 8, marginBottom: 8 }}>
        <MultiSelect
          values={Object.keys(players)
            .map((p) => {
              return { label: players[p] || "Unknown", value: p };
            })
            .sort((a, b) => (a.label > b.label ? 1 : -1))}
          selected={selectedPlayerId ? [selectedPlayerId] : []}
          onChange={(vals) =>
            setSelectedPlayerId(vals.length ? vals[0] || null : null)
          }
          limit={1}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: 8,
          marginTop: 8,
          backgroundColor: backgroundColor.toString(),
          borderRadius: 4,
          padding: 8,
        }}
      >
        <Link to={bio ? `/player/${bio.playerId}` : "/player"}>
          <PlayerPhoto
            ids={(bio && bio.idIds) || undefined}
            playerId={bio && bio.playerId}
          />
        </Link>
      </div>
    </div>
  );
}
