import React, { useState } from "react";

import { MultiSelect } from "./MultiSelect";
import { trpc } from "../../util/tRPC";

export function MultiSelectSearch(props: {
  limit: number;
  values: { label: string; value: string }[];
  onChange: (vals: string[]) => void;
  selected: string[];
}) {
  const { selected, values, limit, onChange } = props;
  const [text, setText] = useState("");

  const { data } = trpc.search.getSearchResults.useQuery({
    searchQuery: selected.length ? selected[0] : text,
    type: "player",
  });

  const autocompleteValues =
    data === undefined
      ? []
      : data.map((d) => {
          return {
            value: d.Id.toString(),
            label: d.label,
            aux1: d.aux1,
            aux2: d.aux2 === null ? null : d.aux2.toString(),
          };
        });

  const augmentedValues = values.concat(autocompleteValues);

  return (
    <MultiSelect
      selected={selected}
      onChange={onChange}
      limit={limit}
      values={augmentedValues}
      autocompleteValues={autocompleteValues}
      onTextChange={setText}
    />
  );
}
