import React, { ReactNode, useRef, useState, useContext } from "react";
import cx from "classnames";
import { Button, Table as BootstrapTable } from "react-bootstrap";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  ColumnDef,
  flexRender,
  Row,
  SortingState,
  VisibilityState,
  Updater,
  RowData,
  ExpandedState,
} from "@tanstack/react-table";
import { useVirtual, VirtualItem } from "react-virtual";
import { createStyles, makeStyles } from "@material-ui/styles";
import { scaleLinear } from "d3";

import { UserPreferenceContext } from "../../UserContext";

export {
  type ColumnDef,
  type SortingState,
  type VisibilityState,
  createColumnHelper,
} from "@tanstack/react-table";

import {
  tableColorSchemes,
  sequentialNeutralColorScheme,
} from "../../constants/ChartConstants";
import { Highlights } from "../../constants/AppConstants";
import { useEventListener } from "../../util/Hooks";
import { generateDomain } from "../../util/Util";

declare module "@tanstack/table-core" {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  interface ColumnMeta<TData extends RowData, TValue> {
    highlights?: string;
    colorDomain?:
      | number[]
      | ((cellData: TData, colDef: ColumnDef<TData>) => number[]);
    group?: number;
    neutralColorScheme?: boolean;
    heatmap?: boolean;
    textAlign?: "center" | "left" | "right";
  }
}

interface TableProps<DataType> {
  title?: string;
  data: DataType[];
  columns: ColumnDef<DataType, any>[];
  hiddenColumns?: VisibilityState;
  sorting?: SortingState;
  setSorting?: (sorting: SortingState) => void;
  showRowIndex?: boolean;
  autoWidth?: boolean;
  center?: boolean;
  showColorOnHover?: boolean;
  // Applies background / text / border color to a cell by index.
  rowColorMap?: Record<
    number,
    { backgroundColor?: string; borderColor?: string; color?: string }
  >;
  virtualScroll?: boolean;
  processSelected?: (row: DataType[]) => ReactNode[];
  expandColumnId?: string;
  onHoveredCellChange?: (
    row: number | undefined,
    col: number | undefined,
    cellValue: unknown,
    hoveredRowValue: DataType | undefined
  ) => void;
  rowBelowThreshold?: (row: DataType) => boolean;
  disableStickyColumn?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    autoWidth: {
      width: "auto",
    },
    center: {
      margin: "auto",
    },
    header: {
      background: "white",
      position: "sticky",
      top: 0,
      zIndex: 1,
    },
    sorted: {
      background:
        "linear-gradient(to top,rgba(255,255,255,.8) 0,rgba(255,255,255,0) 100%) #d9edf7 !important",
      borderBottomColor: "#bce8f1",
      color: "#3a87ad",
    },
    virtualContainer: {
      maxHeight: 500,
      overflowY: "auto",
    },
    fixedContainer: {
      overflowY: "hidden",
    },
    container: {
      overflowX: "auto",
      // The 30px of padding/margin is to account for the row numbers. On mobile
      // this breaks our sticky column + scrolling behavior so we disable it.
      ["@media (min-width:768px)"]: {
        paddingLeft: 30,
        marginLeft: -30,
      },
    },
    table: {
      fontSize: 12,
      position: "relative",
      "& > tbody": {
        borderTop: "none",
      },
      "& td.highlight": {
        fontWeight: "bold",
      },
      "& td": {
        borderTop: "1px solid #dddddd",
        borderBottom: "1px solid #dddddd",
        whiteSpace: "nowrap",
      },
      "& tfoot th": {
        textAlign: "center",
        fontWeight: "unset",
        backgroundColor: "#eee",
      },
      "& thead th": {
        textAlign: "center",
        borderBottom: "none",
        whiteSpace: "nowrap",
      },
    },
    pagination: {
      display: "flex",
    },
    oddRow: {
      background: "white",
    },
    evenRow: {
      background: "#f5f5f5",
    },
    rowNumber: {
      lineHeight: "18px",
      marginTop: -2,
      padding: "2px 4px",
      left: -30,
      opacity: 0,
      position: "absolute",
      textAlign: "center",
      width: 30,
      background: "#f6f6f6",
      color: "#888",
    },
    selectedRow: {
      "& td": {
        backgroundColor: "#ffffe0 !important",
      },
      "& th": {
        color: "#58580A",
        backgroundColor: "#ffffe0 !important",
      },
    },
    rowExpandToggle: {
      padding: 3,
      fontSize: ".7em",
      marginLeft: 4,
      lineHeight: 0,
    },
    isExpanded: {
      borderBottom: "none",
      "& td": {
        border: "none",
      },
    },
    subRow: {
      border: "none",
      "& td": {
        border: "none",
      },
    },
    groupDivider: {
      borderLeft: "1px solid #dddddd",
    },
    belowThreshold: {
      opacity: 0.4,
    },
  })
);

export function Table<DataType>(
  props: TableProps<
    DataType & {
      subRows?: DataType[];
    }
  >
) {
  const {
    title,
    data,
    columns,
    hiddenColumns,
    sorting,
    setSorting,
    showColorOnHover,
    autoWidth,
    center,
    rowColorMap,
    virtualScroll,
    processSelected,
    expandColumnId,
    onHoveredCellChange,
    rowBelowThreshold,
    disableStickyColumn,
  } = props;
  const showRowIndex =
    props.showRowIndex === undefined ? true : props.showRowIndex;
  const classes = useStyles();
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [hoveredCell, setHoveredCell] = useState<HTMLTableCellElement>();
  const [tableHover, setTableHover] = useState(false);
  const [rowSelection, setRowSelection] = React.useState({});
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const [lastHoverValue, setLastHoverValue] = useState<unknown>();
  const [lastSelectedRowIdx, setLastSelectedRowIdx] = useState<number>();

  const colorPref = useContext(UserPreferenceContext)["Color Scheme"] || 0;

  const isFirstColSticky = !disableStickyColumn;

  useEventListener(
    "mouseover",
    (e: MouseEvent) => {
      if (!showColorOnHover) return;
      if (!showColorOnHover || !(e.target instanceof HTMLElement)) return;
      const cell = e.target.closest("td");
      if (cell) {
        setHoveredCell(cell);
      }
    },
    tableContainerRef
  );

  const handleRowClick = (
    evt: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: Row<
      DataType & {
        subRows?: DataType[];
      }
    >
  ) => {
    // If no processSelected fn is provided we don't need to track this.
    if (!processSelected) return;

    let rowToSelect = row;

    // If we are in a sub row then find the parent and call select on that.
    if (row.depth > 0) {
      const parentRowId = getParentRowId(row);
      if (parentRowId !== undefined) {
        const maybeRowToSelect = table.getRowModel().rowsById[parentRowId];
        if (maybeRowToSelect !== undefined) {
          rowToSelect = maybeRowToSelect;
        }
      }
    }

    if (evt.shiftKey && lastSelectedRowIdx !== undefined) {
      const minIdx = Math.min(rowToSelect.index, lastSelectedRowIdx);
      const maxIdx = Math.max(rowToSelect.index, lastSelectedRowIdx);

      for (let i = minIdx; i <= maxIdx; i++) {
        const row = rows[i];
        if (row && !row.getIsSelected()) {
          const handler = row.getToggleSelectedHandler();
          handler(evt);
        }
      }
      setLastSelectedRowIdx(maxIdx);
    } else {
      const isDeselecting = rowToSelect.getIsSelected();
      if (isDeselecting) {
        setLastSelectedRowIdx(undefined);
      } else {
        setLastSelectedRowIdx(rowToSelect.index);
      }

      const handler = rowToSelect.getToggleSelectedHandler();
      handler(evt);
    }
    // Clear selected text. Without this when you shift select a bunch of rows
    // in a table all the text will get selected too and it looks bad.
    if (window.getSelection && window.getSelection()?.empty) {
      window.getSelection()?.empty();
    }
  };

  useEventListener("mouseenter", () => setTableHover(true), tableContainerRef);

  useEventListener(
    "mouseleave",
    (e: any) => {
      // If the mouse is "leaving" to an element no longer in the body ignore.
      if (!document.body.contains(e.toElement)) return;

      setTableHover(false);
      setHoveredCell(undefined);
    },
    tableContainerRef
  );

  const onSortingChange = (updater: Updater<SortingState>) => {
    if (!setSorting) return;
    if (typeof updater === "function") {
      setSorting(updater(sorting as SortingState));
    } else {
      setSorting(updater);
    }
  };

  const table = useReactTable<DataType & { subRows?: DataType[] }>({
    data,
    columns,
    state: {
      expanded,
      rowSelection,
      // Ignore any sort provided to us that isn't actually a column. We have to
      // do some hacky stuff here (looking at ID AND accessorKey) as well as
      // check for nested columns.
      sorting:
        sorting &&
        sorting.filter((s) =>
          columns.some((c) =>
            (c as any).columns
              ? (c as any).columns.some(
                  (cc: any) =>
                    cc.id === s.id || (cc as any).accessorKey === s.id
                )
              : c.id === s.id || (c as any).accessorKey === s.id
          )
        ),
      columnVisibility: hiddenColumns,
    },
    isMultiSortEvent: (e) => {
      window?.getSelection()?.empty();
      return (e as any).shiftKey;
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows as any,
    onRowSelectionChange: setRowSelection,
    onSortingChange: onSortingChange,
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 200,
  });

  const hiddenColIdxs: number[] =
    (hiddenColumns &&
      Object.entries(hiddenColumns)
        .filter(([, v]) => v === false)
        .map(([k]) => {
          return columns.findIndex(
            (c: any) => c.id === k || c.accessorKey === k
          );
        })) ||
    [];

  const parseRowCol = (el: HTMLTableCellElement | undefined) => {
    if (!el) return { hoverRow: undefined, hoverCol: undefined };
    const row = el.closest("tr");
    if (!row) return { row: undefined, col: undefined };
    const offset =
      Math.max(...table.getAllFlatColumns().map((c) => c.depth)) + 1;

    return { hoverRowIdx: row.rowIndex - offset, hoverColIdx: el.cellIndex };
  };
  const { hoverRowIdx, hoverColIdx } = parseRowCol(hoveredCell);

  // If user cares about this alert them when the cell changes with the new
  // value.
  if (onHoveredCellChange) {
    let newValue = undefined;
    let hoveredRowValue = undefined;
    if (hoverColIdx !== undefined && hoverRowIdx !== undefined) {
      const hoveredRow = table.getSortedRowModel().rows[hoverRowIdx];
      if (hoveredRow) {
        const hovered = hoveredRow.getVisibleCells()[hoverColIdx];
        if (hovered) {
          newValue = hovered.getValue();
          hoveredRowValue = hovered.row.original;
        }
      }
    }
    if (
      newValue !== lastHoverValue &&
      JSON.stringify(newValue) !== JSON.stringify(lastHoverValue)
    ) {
      onHoveredCellChange(hoverRowIdx, hoverColIdx, newValue, hoveredRowValue);
      setLastHoverValue(newValue);
    }
  }

  const getParentRowId = (
    row: Row<
      DataType & {
        subRows?: DataType[];
      }
    >
  ) => {
    // A subrow will have an id of the form <parentRowId>.<subRowIndex> so we
    // can extract the parent row id by splitting on a period and grabbing the
    // first element.
    return row.id.split(".")[0];
  };

  // Returns the index the top level row that is hovered.
  const getHoveredRowId = () => {
    if (hoverRowIdx === undefined) return -1;
    const row = table.getRowModel().rows[hoverRowIdx];
    if (!row) return -1;
    if (row.depth === 0) return row.id;
    return getParentRowId(row);
  };

  const isCellHovered = (
    row: Row<
      DataType & {
        subRows?: DataType[];
      }
    >,
    rowIdx: number,
    colIdx: number
  ) => {
    if (hoverColIdx === colIdx || hoverRowIdx === rowIdx) return true;

    const hoverId = getHoveredRowId();

    // If we are checking a sub row, check if its parent is hovered.
    if (row.depth > 0) {
      return hoverId === getParentRowId(row);
    }

    // If we are checking a row with children, check if we are the hovered row.
    if (row.getLeafRows().length) {
      return hoverId === row.id;
    }

    return false;
  };

  const getColorForRow = (
    row: Row<
      DataType & {
        subRows?: DataType[];
      }
    >
  ): { backgroundColor?: string; borderColor?: string; color?: string } => {
    let rowIdx = row.index;
    if (row.depth > 0) {
      const parentRowId = getParentRowId(row);
      if (parentRowId) {
        const parentRow = table.getRowModel().rowsById[parentRowId];
        if (parentRow) {
          rowIdx = parentRow.index;
        }
      }
    }

    if (!rowColorMap) return {};
    return rowColorMap[rowIdx] || {};
  };

  // Key is a column id and number is value that should be highlighted.
  const highlights: Record<string, number> = {};

  const highlightColumns = table
    .getAllLeafColumns()
    .filter((c) => c.columnDef?.meta?.highlights);
  for (const hc of highlightColumns) {
    const id = hc.id;
    const vals = table
      .getRowModel()
      .rows.filter((r) => {
        if (!rowBelowThreshold) return true;
        return !rowBelowThreshold(r.original);
      })
      .map((row) => row.getValue(id)) as number[];
    const nonNullVals = vals.filter((v) => v !== undefined && v !== null);
    const target =
      hc.columnDef.meta?.highlights === Highlights.Max
        ? Math.max(...nonNullVals)
        : Math.min(...nonNullVals);
    highlights[id] = target;
  }

  const colorDomains = new Set(
    table
      .getAllLeafColumns()
      .filter((c) => c.columnDef?.meta?.colorDomain)
      .map((c) => c.id)
  );

  const classForRow = (
    row: Row<
      DataType & {
        subRows?: DataType[];
      }
    >,
    idx: number
  ) => {
    const isExpanded = {
      [classes.isExpanded]: row.getIsExpanded(),
    };
    const subRow = { [classes.subRow]: row.depth > 0 };
    const belowThreshold = {
      [classes.belowThreshold]:
        rowBelowThreshold && rowBelowThreshold(row.original),
    };
    if (row.getIsSelected()) {
      return cx(classes.selectedRow, subRow, isExpanded, belowThreshold);
    }
    // Can't just reference row.index b/c the rows might be sorted and the order
    // changed.
    return idx % 2 === 0
      ? cx(classes.evenRow, belowThreshold)
      : cx(classes.oddRow, belowThreshold);
  };

  const firstFooterGroup = table.getFooterGroups()[0];
  const showFooter =
    firstFooterGroup &&
    firstFooterGroup.headers.filter((h) => h.column.columnDef.footer).length >
      0;

  let paddingTop = 0;
  let paddingBottom = 0;
  let virtualRows: VirtualItem[] = [];
  if (virtualScroll) {
    virtualRows = rowVirtualizer.virtualItems;
    const { totalSize } = rowVirtualizer;

    paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    paddingBottom =
      virtualRows.length > 0
        ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
        : 0;
  }

  const rowIdxs = (virtualScroll ? virtualRows : rows).map((_, i) => i);

  let prevFooterGroup: undefined | number;

  return (
    <div>
      {title && (
        <span
          style={{
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {title}
        </span>
      )}
      <div
        ref={tableContainerRef}
        className={cx(classes.container, {
          [classes.virtualContainer]: virtualScroll,
          [classes.fixedContainer]: !virtualScroll,
        })}
      >
        <BootstrapTable
          className={cx(classes.table, {
            [classes.autoWidth]: autoWidth,
            [classes.center]: center,
          })}
          bordered={false}
          size="sm"
        >
          <thead
            className={classes.header}
            style={{ borderBottom: "2px solid #dee2e6", zIndex: 2 }}
          >
            {table.getHeaderGroups().map((headerGroup) => {
              let prevGroup: undefined | number = undefined;
              const sortableHeaderLevel =
                table.getHeaderGroups().length - 1 === headerGroup.depth;
              return (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, i) => {
                    const needsGroupDivider =
                      prevGroup !== undefined &&
                      header.column.columnDef.meta?.group !== prevGroup;
                    prevGroup = header.column.columnDef.meta?.group;
                    return (
                      <th
                        className={cx({
                          [classes.groupDivider]: needsGroupDivider,
                          [classes.sorted]:
                            sortableHeaderLevel && header.column.getIsSorted(),
                        })}
                        onClick={header.column.getToggleSortingHandler()}
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          cursor: sorting ? "pointer" : "default",
                          position:
                            isFirstColSticky && i === 0 ? "sticky" : undefined,
                          zIndex: isFirstColSticky && i === 0 ? 1 : undefined,
                          left: isFirstColSticky && i === 0 ? 0 : undefined,
                          background: "white",
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody style={{ borderTop: "none" }}>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {rowIdxs.map((i) => {
              const virtualRow = virtualScroll ? virtualRows[i] : undefined;

              const row =
                virtualScroll && virtualRow
                  ? (rows[virtualRow.index] as Row<
                      DataType & {
                        subRows?: DataType[];
                      }
                    >)
                  : rows[i];

              if (!row) return null;

              let prevGroup: undefined | number = undefined;
              return (
                <tr
                  key={row.id}
                  className={classForRow(row, i)}
                  onClick={(e) => handleRowClick(e, row)}
                >
                  {row.getVisibleCells().map((cell, j) => {
                    let backgroundColor = "";
                    const alwaysShow = cell.column.columnDef.meta?.heatmap;
                    const calculateColor =
                      (showColorOnHover && isCellHovered(row, i, j)) ||
                      alwaysShow;
                    const needsGroupDivider =
                      prevGroup !== undefined &&
                      cell.column.columnDef.meta?.group !== prevGroup;
                    prevGroup = cell.column.columnDef.meta?.group;
                    if (
                      calculateColor &&
                      colorDomains.has(cell.column.id) &&
                      cell &&
                      cell.getValue() !== null
                    ) {
                      const color = scaleLinear("linear");
                      color
                        .range(tableColorSchemes[colorPref] || [])
                        .clamp(true);
                      if (cell.column.columnDef.meta?.neutralColorScheme) {
                        color.range(sequentialNeutralColorScheme);
                      }
                      const cd = cell.column.columnDef.meta?.colorDomain;
                      if (typeof cd === "function") {
                        const colorDomain = cd(
                          cell.row.original,
                          cell.column.columnDef
                        );
                        const fullColorDomain = generateDomain(
                          colorDomain[0] || 0,
                          colorDomain[colorDomain.length - 1] || 0,
                          color.range().length
                        );
                        color.domain(fullColorDomain);
                      } else if (cd) {
                        const colorDomain = generateDomain(
                          cd[0] || 0,
                          cd[cd.length - 1] || 0,
                          color.range().length
                        );
                        color.domain(colorDomain);
                      }

                      backgroundColor = cell
                        ? color(cell.getValue() as number)
                        : "";
                    }

                    const textAlign =
                      cell.column.columnDef.meta?.textAlign || "center";

                    const customRowColors = getColorForRow(row);

                    // If no backgroundColor is set from hovering or heatmap,
                    // see if we have a custom row color and use that, otherwise
                    // use the default alternating row colors.
                    if (!backgroundColor) {
                      if (calculateColor) {
                        // If hovered - show hover color.
                        backgroundColor = "#f5f5f5";
                      } else {
                        // Else try and get color from custom color map.
                        backgroundColor =
                          customRowColors.backgroundColor ||
                          (isFirstColSticky && j === 0
                            ? i % 2
                              ? "white"
                              : "#f5f5f5"
                            : "");
                      }
                    }

                    const customCellTextColor = customRowColors.color;

                    const customCellBorderColor = customRowColors.borderColor;

                    return (
                      <td
                        style={{
                          textAlign,
                          position:
                            isFirstColSticky && j === 0 ? "sticky" : undefined,
                          zIndex: isFirstColSticky && j === 0 ? 1 : undefined,
                          left: isFirstColSticky && j === 0 ? 0 : undefined,
                          backgroundColor: backgroundColor,
                          color: customCellTextColor,
                          borderTop: customCellBorderColor,
                          borderBottom: customCellBorderColor,
                        }}
                        key={cell.id}
                        className={cx({
                          [classes.groupDivider]: needsGroupDivider,
                          hover: isCellHovered(row, i, j),
                          highlight:
                            highlights[cell.column.id] !== undefined &&
                            highlights[cell.column.id] === cell?.getValue(),
                        })}
                      >
                        {j === 0 && row.depth === 0 && (
                          // Show row numbers on table hover.
                          <div
                            className={classes.rowNumber}
                            style={{
                              opacity: tableHover && showRowIndex ? 1 : 0,
                            }}
                          >
                            {i + 1}
                          </div>
                        )}
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                        {cell.column.id === expandColumnId &&
                          row.getCanExpand() && (
                            <Button
                              className={classes.rowExpandToggle}
                              onClick={(e) => {
                                row.toggleExpanded();
                                e.stopPropagation();
                                (document.activeElement as any).blur();
                              }}
                            >
                              {row.getIsExpanded() ? (
                                <IoMdRemove />
                              ) : (
                                <IoMdAdd />
                              )}
                            </Button>
                          )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
          <tfoot style={{ borderTop: "2px solid #dee2e6" }}>
            {processSelected && table.getSelectedRowModel().rows.length > 0 && (
              <tr className={classes.selectedRow}>
                {processSelected(
                  table.getSelectedRowModel().rows.map((r) => r.original)
                )
                  .filter((v, i) => {
                    return !hiddenColIdxs.includes(i);
                  })
                  .map((val: ReactNode, i: number) => {
                    return (
                      <th
                        key={i}
                        style={{
                          position:
                            isFirstColSticky && i === 0 ? "sticky" : undefined,
                          zIndex: isFirstColSticky && i === 0 ? 1 : undefined,
                          left: isFirstColSticky && i === 0 ? 0 : undefined,
                        }}
                      >
                        {val}
                      </th>
                    );
                  })}
              </tr>
            )}
            {showFooter && (
              <tr>
                {firstFooterGroup &&
                  firstFooterGroup.headers.map((header, i) => {
                    const needsGroupDivider =
                      prevFooterGroup !== undefined &&
                      header.column.columnDef.meta?.group !== prevFooterGroup;
                    prevFooterGroup = header.column.columnDef.meta?.group;

                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className={cx({
                          [classes.groupDivider]: needsGroupDivider,
                        })}
                        style={{
                          position:
                            isFirstColSticky && i === 0 ? "sticky" : undefined,
                          zIndex: isFirstColSticky && i === 0 ? 1 : undefined,
                          left: isFirstColSticky && i === 0 ? 0 : undefined,
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.footer,
                              header.getContext()
                            )}
                      </th>
                    );
                  })}
              </tr>
            )}
          </tfoot>
        </BootstrapTable>
      </div>
    </div>
  );
}

// Used to create the look and feel of multiple footers with a single footer.
export function DoubleFooter(props: {
  primary: ReactNode;
  secondary: ReactNode;
  textAlign?: "center" | "left" | "right";
}) {
  const { primary, secondary, textAlign } = props;
  return (
    <div
      style={{ margin: "-.25rem -0.25rem", textAlign: textAlign || "center" }}
    >
      <div style={{ backgroundColor: "#E5E5E5", padding: ".25rem .25rem" }}>
        {primary === "" || primary === null || primary === undefined ? (
          <br />
        ) : (
          primary
        )}
      </div>
      <div style={{ padding: ".25rem .25rem", backgroundColor: "#EEEEEE" }}>
        {secondary === "" || secondary === null || secondary === undefined ? (
          <br />
        ) : (
          secondary
        )}
      </div>
    </div>
  );
}
