import React from "react";
import { Link } from "react-router-dom";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { PnrAggregate } from "../../../shared/routers/PnrRouter";
import { Highlights, Positions, pnrLabels } from "../../constants/AppConstants";
import {
  dateFormat,
  decFormat2,
  pctFormat,
  seFormat2,
} from "../../util/Format";
import { VideoControl } from "../query/VideoControl";
import { PlayerTableCell, TeamTableCell } from "../core/TableCell";

const columnHelper = createColumnHelper<PnrAggregate>();

export function PnrQueryResultsTable(props: {
  data: PnrAggregate[];
  groupBy: string[];
  sorting?: SortingState;
  setSorting?: (sorting: SortingState) => void;
  onVideo: (data: PnrAggregate, groupBy: string[]) => void;
  columns: (keyof PnrAggregate)[];
}) {
  const {
    data,
    groupBy,
    sorting,
    setSorting,
    onVideo,
    columns: selectedColumns,
  } = props;

  const columns = React.useMemo(() => {
    const minPicks = Math.min(...data.map((d) => d.numPicks));
    const maxPicks = Math.max(...data.map((d) => d.numPicks));

    let g = 0;
    return [
      columnHelper.accessor("season", {
        header: () => "Season",
        meta: { group: g++ },
      }),
      columnHelper.accessor("gameTypeId", {
        header: () => "Game Type",
        cell: (info) =>
          info.getValue() === "P" ? "Playoffs" : "Regular Season",
        meta: { group: g++ },
      }),
      columnHelper.accessor("oteam", {
        header: () => "Offense Team",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue()}
            ids={info.row.original.oteamId}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("dteam", {
        header: () => "Defense Team",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue()}
            ids={info.row.original.dteamId}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("ballhandler", {
        header: () => "Ballhandler",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.ballhandlerId}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("screener", {
        header: () => "Screener",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.screenerId}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("ballhandlerDefender", {
        header: () => "Ballhandler Defender",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.ballhandlerDefenderId}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("screenerDefender", {
        header: () => "Screener Defender",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.screenerDefenderId}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("positionBallhandler", {
        header: () => "Ballhandler Position",
        cell: (info) => {
          const val = info.getValue();
          return val ? Positions[val] : "";
        },
        meta: { group: g },
      }),
      columnHelper.accessor("positionScreener", {
        header: () => "Screener Position",
        cell: (info) => {
          const val = info.getValue();
          return val ? Positions[val] : "";
        },
        meta: { group: g++ },
      }),
      columnHelper.accessor("positionBallhandlerDefender", {
        header: () => "Ballhandler Defender Position",
        cell: (info) => {
          const val = info.getValue();
          return val ? Positions[val] : "";
        },
        meta: { group: g },
      }),
      columnHelper.accessor("positionScreenerDefender", {
        header: () => "Screener Defender Position",
        cell: (info) => {
          const val = info.getValue();
          return val ? Positions[val] : "";
        },
        meta: { group: g++ },
      }),
      columnHelper.accessor("coverage", {
        header: () => "Coverage",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.coverage[val] : "";
        },
        meta: { group: g, textAlign: "left" },
      }),
      columnHelper.accessor("scrDefenderLevel", {
        header: () => "Screener Defender Level",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.scrDefenderLevel[val] : "";
        },
        meta: { group: g, textAlign: "left" },
      }),
      columnHelper.accessor("defense", {
        header: () => "Defense",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.defense[val] : "";
        },
        meta: { group: g, textAlign: "left" },
      }),
      columnHelper.accessor("bhrDefTypeComplex", {
        header: () => "Ballhandler Defense",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.bhr_def_type_complex[val] : "";
        },
        meta: { group: g, textAlign: "left" },
      }),
      columnHelper.accessor("scrDefTypeComplex", {
        header: () => "Screener Defense",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.scr_def_type_complex[val] : "";
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("gameDate", {
        header: () => "Date",
        cell: (info) => {
          const val = info.getValue();
          return val ? dateFormat(new Date(val.substring(0, 10))) : "";
        },
        meta: { group: g },
      }),
      columnHelper.accessor("gameString", {
        header: () => "Game",
        cell: (info) => (
          <Link to={`/game/${info.row.original.gameId}`}>
            {info.getValue()}
          </Link>
        ),
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("period", {
        header: () => "Period",
        meta: { group: g++ },
      }),
      columnHelper.accessor("rescreen", {
        header: () => "Rescreen",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("direction", {
        header: () => "Direction",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.direction[val] : "";
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("rollPop", {
        header: () => "Roll / Pop",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.roll_pop[val] : "";
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("takeRejectSlip", {
        header: () => "Screen Type",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.scr_type[val] : "";
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("locationType", {
        header: () => "Location",
        cell: (info) => {
          const val = info.getValue();
          return val ? pnrLabels.location_type[val] : "";
        },
        meta: { group: g++, textAlign: "left" },
      }),
      columnHelper.accessor("l", {
        header: () => "L Screen",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("horns", {
        header: () => "Horns",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("double", {
        header: () => "Double Team",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("handoffPick", {
        header: () => "Handoff Pick",
        cell: (info) => (info.getValue() ? "Yes" : "No"),
        meta: { group: g++ },
      }),
      columnHelper.accessor("numPicks", {
        header: () => "# Picks",
        cell: (info) => info.getValue().toLocaleString(),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          neutralColorScheme: true,
          colorDomain: [minPicks, maxPicks],
        },
      }),
      columnHelper.accessor("xppp", {
        header: () => "xPPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [0.9, 1.2],
          heatmap: true,
        },
      }),
      columnHelper.accessor("stderr", {
        header: () => "SE",
        cell: (info) => seFormat2(info.getValue()),
        meta: {
          group: g,
        },
      }),
      columnHelper.accessor("xpps", {
        header: () => "xPPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: [0.9, 1.2],
        },
      }),
      columnHelper.accessor("layupPct", {
        header: () => "Layup %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("threePct", {
        header: () => "3PA %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("nonLayupTwoPct", {
        header: () => "NL2 %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
        },
      }),
      columnHelper.accessor("turnoverPct", {
        header: () => "TO %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
        },
      }),
      columnHelper.accessor("orbPct", {
        header: () => "ORB %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g++,
        },
      }),
      columnHelper.accessor("pnrPassPct", {
        header: () => "Pass %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
        },
      }),
      columnHelper.accessor("pnrPassScrPct", {
        header: () => "Pass Scr %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
        },
      }),
      columnHelper.accessor("pnrAstOppPct", {
        header: () => "Ast Opp %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
        },
      }),
      columnHelper.accessor("pnrAstScrPct", {
        header: () => "Ast Scr %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
        },
      }),
      columnHelper.accessor("pnrToPct", {
        header: () => "TO %",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g++,
        },
      }),
      columnHelper.accessor("ppp", {
        header: () => "PPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [0.9, 1.2],
        },
      }),
      columnHelper.accessor("pps", {
        header: () => "PPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [0.9, 1.2],
        },
      }),
      columnHelper.accessor("layupFgPct", {
        header: () => "Layup FG%",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [0.54, 0.63],
        },
      }),
      columnHelper.accessor("threeFgPct", {
        header: () => "Three FG%",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [0.3, 0.4],
        },
      }),
      columnHelper.accessor("nonLayupTwoFgPct", {
        header: () => "NL2 FG%",
        cell: (info) => pctFormat(info.getValue()),
        meta: {
          group: g++,
          highlights: Highlights.Max,
          colorDomain: [0.4, 0.47],
        },
      }),
      columnHelper.accessor("hasVideo", {
        header: () => "Video",
        cell: (info) => {
          return info.getValue() ? (
            <VideoControl
              data={info.row.original}
              onVideo={(d) => onVideo(d, groupBy)}
            />
          ) : (
            ""
          );
        },
        meta: { group: g++ },
      }),
    ];
  }, [data]);

  const hiddenColumns = {
    season: groupBy.includes("season"),
    gameTypeId: groupBy.includes("gameTypeId"),
    oteam: groupBy.includes("oteamId"),
    dteam: groupBy.includes("dteamId"),
    screener: groupBy.includes("screenerId"),
    ballhandler: groupBy.includes("ballhandlerId"),
    screenerDefender: groupBy.includes("screenerDefenderId"),
    ballhandlerDefender: groupBy.includes("ballhandlerDefenderId"),
    positionBallhandler: groupBy.includes("positionBallhandler"),
    positionScreener: groupBy.includes("positionScreener"),
    positionBallhandlerDefender: groupBy.includes(
      "positionBallhandlerDefender"
    ),
    positionScreenerDefender: groupBy.includes("positionScreenerDefender"),
    defense: groupBy.includes("defense"),
    bhrDefTypeComplex: groupBy.includes("bhrDefTypeComplex"),
    scrDefTypeComplex: groupBy.includes("scrDefTypeComplex"),
    gameDate: groupBy.includes("gameId"),
    gameString: groupBy.includes("gameId"),
    period: groupBy.includes("period"),
    rescreen: groupBy.includes("rescreen"),
    direction: groupBy.includes("direction"),
    rollPop: groupBy.includes("rollPop"),
    takeRejectSlip: groupBy.includes("takeRejectSlip"),
    locationType: groupBy.includes("locationType"),
    l: groupBy.includes("l"),
    horns: groupBy.includes("horns"),
    double: groupBy.includes("double"),
    handoffPick: groupBy.includes("handoffPick"),
    coverage: groupBy.includes("coverage"),
    scrDefenderLevel: groupBy.includes("scrDefenderLevel"),
    // Selectable columns:
    numPicks: selectedColumns.includes("numPicks"),
    xppp: selectedColumns.includes("xppp"),
    xpps: selectedColumns.includes("xpps"),
    stderr: selectedColumns.includes("stderr"),
    ppp: selectedColumns.includes("ppp"),
    pps: selectedColumns.includes("pps"),
    layupPct: selectedColumns.includes("layupPct"),
    threePct: selectedColumns.includes("threePct"),
    nonLayupTwoPct: selectedColumns.includes("nonLayupTwoPct"),
    turnoverPct: selectedColumns.includes("turnoverPct"),
    orbPct: selectedColumns.includes("orbPct"),
    pnrPassPct: selectedColumns.includes("pnrPassPct"),
    pnrPassScrPct: selectedColumns.includes("pnrPassScrPct"),
    pnrAstOppPct: selectedColumns.includes("pnrAstOppPct"),
    pnrAstScrPct: selectedColumns.includes("pnrAstScrPct"),
    pnrToPct: selectedColumns.includes("pnrToPct"),
    layupFgPct: selectedColumns.includes("layupFgPct"),
    threeFgPct: selectedColumns.includes("threeFgPct"),
    nonLayupTwoFgPct: selectedColumns.includes("nonLayupTwoFgPct"),
  };

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      hiddenColumns={hiddenColumns}
      setSorting={setSorting}
      virtualScroll={true}
      autoWidth={true}
      showColorOnHover={true}
    />
  );
}
