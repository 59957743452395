import React, { useState, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";

import { Spinner } from "../core/Spinner";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import {
  PlayerDraftModelYear,
  PlayerDraftModelBreakdown,
} from "../../../shared/routers/DraftRouter";
import { decFormat } from "../../util/Format";
import { groupBy } from "../../../shared/util/Collections";
import { sum } from "../../util/Util";
import { trpc } from "../../util/tRPC";

const columnHelper = createColumnHelper<PlayerDraftModelBreakdown>();

export function PlayerDraftModelBreakdownTables(props: {
  playerId: number;
  draftModelYears: PlayerDraftModelYear[];
}) {
  const { playerId, draftModelYears } = props;
  const [year, setYear] = useState(
    draftModelYears.find((y) => y.defaultSeason === 1)?.value.toString()
  );

  const { data: breakdown } = trpc.draft.getPlayerDraftModelBreakdown.useQuery({
    playerId,
    season: year,
  });

  return (
    <div>
      <div style={{ gap: 10, display: "flex", alignItems: "center" }}>
        <Form.Label>Season</Form.Label>
        <Form.Select
          value={year}
          style={{ width: "auto", display: "inline" }}
          onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
            setYear(evt.target.value);
          }}
        >
          {draftModelYears.map((y) => {
            return (
              <option key={y.value} value={y.value}>
                {y.label}
              </option>
            );
          })}
        </Form.Select>
      </div>
      {breakdown === undefined ? (
        <Spinner />
      ) : (
        <Row>
          {["Offense", "Defense"].map((offenseDefense) => (
            <Col key={offenseDefense}>
              <div style={{ textAlign: "center" }}>
                <b>{offenseDefense}</b>
              </div>
              <BreakdownTable
                data={breakdown.filter((b) => b.Role === offenseDefense)}
              />
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}

function BreakdownTable(props: { data: PlayerDraftModelBreakdown[] }) {
  {
    const { data } = props;
    const [sorting, setSorting] = useState<SortingState>();
    const dataBySkillGroup = groupBy(data, (b) => b.SkillGroup);

    const dataWithSubRows = Object.keys(dataBySkillGroup)
      .map((skillGroup) => {
        const skillsInGroup = dataBySkillGroup[skillGroup];
        if (skillsInGroup === undefined) return undefined;
        const firstRow = skillsInGroup[0];
        if (firstRow === undefined) return undefined;

        if (skillsInGroup.length === 1) {
          return firstRow;
        }
        return {
          Role: firstRow.Role,
          SkillGroup: skillGroup,
          Variable: firstRow.Variable,
          description: `${skillGroup} Total`,
          impact: sum("impact", skillsInGroup),
          longLabel: "",
          skill: "",
          subRows: skillsInGroup,
        };
      })
      .filter((d) => d !== undefined) as PlayerDraftModelBreakdown[];

    const columns = useMemo(
      () => [
        columnHelper.accessor("SkillGroup", {
          header: () => "Skill Group",
          cell: (info) => (info.row.depth === 0 ? info.getValue() : ""),
          meta: { textAlign: "left" },
        }),
        columnHelper.accessor("description", {
          header: () => "Description",
          cell: (info) => info.getValue(),
          meta: { textAlign: "left" },
        }),
        columnHelper.accessor("impact", {
          header: () => "Impact",
          cell: (info) =>
            info.row.depth === 0 ? (
              <b>{decFormat(info.getValue())}</b>
            ) : (
              decFormat(info.getValue())
            ),
        }),
      ],
      []
    );
    return (
      <Table
        data={dataWithSubRows.sort((a, b) => b.impact - a.impact)}
        columns={columns}
        autoWidth={true}
        expandColumnId={"SkillGroup"}
        sorting={sorting}
        setSorting={setSorting}
        disableStickyColumn={true}
      />
    );
  }
}
