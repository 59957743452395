import React from "react";
import { useParams } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import {
  useQueryParams,
  withDefault,
  StringParam,
  DelimitedArrayParam,
  QueryParamConfig,
} from "use-query-params";
import moment from "moment";

import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { Spinner } from "../components/core/Spinner";
import { GameFilters } from "../components/games/GameFilters";
import { TeamPicker } from "../components/team/TeamPicker";
import { TeamSubstitutionPatternChart } from "../components/team/TeamSubstitutionPatternChart";
import AppContext from "../../shared/AppContext";
import { trpc } from "../util/tRPC";

export function SubstitutionsPage() {
  const { id } = useParams();
  const [queryParams, setQueryParams] = useQueryParams({
    season: withDefault(StringParam, AppContext.currentSeason),
    games: withDefault(DelimitedArrayParam, []) as QueryParamConfig<string[]>,
  });
  const { season, games } = queryParams;

  const { data: teamSchedule } = trpc.team.getTeamSchedule.useQuery({
    teamId: id,
    seasonStart: season,
    seasonEnd: season,
  });

  const { data: teamSubData } = trpc.team.getTeamSubstitutionPatterns.useQuery({
    teamId: id,
    season,
    games: games.join(","),
  });

  const numGames = teamSubData
    ? [...new Set(teamSubData.map((t) => t.gameId))].length
    : 0;

  if (!id) return null;

  return (
    <Page header={{ text: "Substitutions" }} title="Substitutions">
      <div>
        <Row>
          <Col>
            <Panel header={"Game Filters"}>
              <div>
                <TeamPicker selectedTeamId={id} linkPrefix="substitutions" />
                <Form>
                  <Form.Label>Season</Form.Label>
                  <br />
                  <Form.Select
                    style={{
                      width: "auto",
                      display: "inline",
                      marginRight: 10,
                    }}
                    value={season}
                    onChange={(e) =>
                      setQueryParams({ season: e.target.value, games: [] })
                    }
                  >
                    {AppContext.seasons.map((s) => {
                      return (
                        <option key={s.value} value={s.value}>
                          {s.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {id && teamSchedule && (
                    <GameFilters
                      teamId={parseInt(id)}
                      onChange={(field, val) =>
                        setQueryParams({ [field]: val })
                      }
                      filters={{
                        seasonStart: "",
                        seasonEnd: "",
                        preseason: 0,
                        regularSeason: 0,
                        postseason: 0,
                        wins: 0,
                        losses: 0,
                        home: 0,
                        away: 0,
                        quarters: [],
                        games,
                      }}
                      hideResetButton={true}
                      hideSeasonFilter={true}
                      hideQuartersFilter={true}
                      hideStatusMessage={true}
                      hideGameSelectorCategories={true}
                      games={teamSchedule.filter(
                        (ts) =>
                          ts.gametypeid !== "X" &&
                          moment(ts.gameDateTime).isSameOrBefore(new Date())
                      )}
                      defaultGameSelectorVisible={true}
                    />
                  )}
                </Form>
              </div>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col>
            <Panel header={"Sub Pattern"}>
              {teamSubData ? (
                <div>
                  <TeamSubstitutionPatternChart
                    numGames={numGames}
                    data={teamSubData}
                  />
                  {games.length === 0 && <p>Default: Last 10 Games</p>}
                </div>
              ) : (
                <Spinner />
              )}
            </Panel>
          </Col>
        </Row>
      </div>
    </Page>
  );
}
