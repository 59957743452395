import React from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";

export function BooleanInput(props: {
  name: string;
  value?: string;
  onChange: (val?: string) => void;
}) {
  const { name, value, onChange } = props;
  return (
    <div>
      <ToggleButtonGroup
        type="checkbox"
        value={value === undefined ? [] : [value]}
        onChange={(vals: string[]) => {
          const newVal = vals.length === 1 ? vals[0] : undefined;
          onChange(newVal);
        }}
      >
        <ToggleButton
          id={`${name}Yes`}
          value={"1"}
          variant={value === "1" ? "primary" : "light"}
        >
          Yes
        </ToggleButton>
        <ToggleButton
          id={`${name}No`}
          value={"0"}
          variant={value === "0" ? "primary" : "light"}
        >
          No
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
