import React from "react";
import { Col, Row } from "react-bootstrap";

import { Panel } from "../../components/core/Panel";
import { TeamScheduleTable } from "../../components/team/TeamScheduleTable";
import { TeamSchedule } from "../../../shared/routers/TeamRouter";

export function TeamSubPageSchedule(props: {
  teamId: number;
  schedule?: TeamSchedule[];
  filtered: boolean;
}) {
  const { teamId, schedule, filtered } = props;

  return (
    <Row>
      <Col>
        <Panel
          header="Team Schedule"
          filtered={filtered}
          panelID="teamSchedule"
        >
          {schedule && <TeamScheduleTable data={schedule} teamId={teamId} />}
        </Panel>
      </Col>
    </Row>
  );
}
