import React from "react";
import cx from "classnames";
import { Col, Row } from "react-bootstrap";
import { createStyles, makeStyles, ClassNameMap } from "@material-ui/styles";

import { TeamStats } from "../../../shared/routers/TeamRouter";
import { LabelBarRenderer } from "../table/renderers/LabelBarRenderer";
import { StatRankRenderer } from "../table/renderers/StatRankRenderer";
import { decFormat, decFormat2, dec100Format } from "../../util/Format";

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      fontVariantNumeric: "tabular-nums",
      width: "100%",
      borderColor: "#dee2e6",
      "& td, & th": {
        padding: "0.5rem 0.5rem",
      },
      "&>:not(:first-child)": {
        borderTop: "2px solid #ddd",
      },
    },
    labelBar: {
      width: 10,
      display: "inline-block",
      verticalAlign: "middle",
      margin: "0 2px",
      height: 2,
      marginBottom: 1,
      transition: "width .2s",
      "&.offense": {
        backgroundColor: "#aee892",
      },
      "&.defense": {
        backgroundColor: "#d2aaed",
      },
    },
    textRight: {
      textAlign: "center",
    },
    title: {
      fontWeight: 600,
    },
  })
);

export function TeamStatRankingsOld(props: {
  leagueData: TeamStats[];
  teamId: number;
  orientation: "tall" | "wide";
}) {
  const classes = useStyles();
  const { leagueData, orientation, teamId } = props;

  const teamData = leagueData.find((t) => t.teamId === teamId);

  // If there was no row for this team it means that there was no data that
  // matched the specific team filters. Examples of this would be setting the
  // filters to be "playoff only" for a team with no playoff games or choosing
  // a specific opponent that this team hasn't played yet.
  if (teamData === undefined) {
    return (
      <div>No games match the currently selected filters for this team.</div>
    );
  }

  const renderData = prepareData(leagueData, teamId, teamData);
  if (orientation === "wide") {
    return renderWide(renderData, classes);
  } else {
    return renderTall(renderData, classes);
  }
}

interface DataWithRanks {
  label: string;
  fmt: (val: number | null) => string;
  offValue: number | null;
  offRank: number | null;
  defValue: number | null;
  defRank: number | null;
}

interface PreparedData {
  rowDataFourFactors: DataWithRanks[];
  rowDataRest: DataWithRanks[];
  rowDataContext: DataWithRanks[];
}

function renderWide(renderData: PreparedData, classes: ClassNameMap) {
  const { rowDataFourFactors, rowDataRest, rowDataContext } = renderData;
  return (
    <Row>
      <Col md={4}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Stat</th>
              <th className={classes.textRight}>
                OFF
                <div className={cx(classes.labelBar, "offense")} />
              </th>
              <th className={classes.textRight}>
                DEF
                <div className={cx(classes.labelBar, "defense")} />
              </th>
            </tr>
          </thead>
          <tbody>
            {rowDataFourFactors.map((d: DataWithRanks, i: number) => {
              return (
                <tr key={i}>
                  <td className={classes.title}>
                    {OffDefRankLabelBarRenderer(d)}
                  </td>
                  <td>{OffStatRankRenderer(d)}</td>
                  <td>{DefStatRankRenderer(d)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Col>
      <Col md={4}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Stat</th>
              <th className={classes.textRight}>
                OFF
                <div className={cx(classes.labelBar, "offense")} />
              </th>
              <th className={classes.textRight}>
                DEF
                <div className={cx(classes.labelBar, "defense")} />
              </th>
            </tr>
          </thead>
          <tbody>
            {rowDataRest.map((d: DataWithRanks, i: number) => {
              return (
                <tr key={i}>
                  <td className={classes.title}>
                    {OffDefRankLabelBarRenderer(d)}
                  </td>
                  <td>{OffStatRankRenderer(d)}</td>
                  <td>{DefStatRankRenderer(d)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Col>
      <Col md={4}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Stat</th>
              <th className={classes.textRight}>
                OFF
                <div className={cx(classes.labelBar, "offense")} />
              </th>
              <th className={classes.textRight}>
                DEF
                <div className={cx(classes.labelBar, "defense")} />
              </th>
            </tr>
          </thead>
          <tbody>
            {rowDataContext.map((d: DataWithRanks, i: number) => {
              return (
                <tr key={i}>
                  <td className={classes.title}>
                    {OffDefRankLabelBarRenderer(d)}
                  </td>
                  <td>{OffStatRankRenderer(d)}</td>
                  <td>{DefStatRankRenderer(d)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Col>
    </Row>
  );
}

function renderTall(renderData: PreparedData, classes: ClassNameMap) {
  const { rowDataFourFactors, rowDataRest, rowDataContext } = renderData;

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <th>Stat</th>
          <th className={classes.textRight}>
            OFF
            <div className={cx(classes.labelBar, "offense")} />
          </th>
          <th className={classes.textRight}>
            DEF
            <div className={cx(classes.labelBar, "defense")} />
          </th>
        </tr>
      </thead>
      <tbody>
        {rowDataFourFactors.map((d: DataWithRanks, i: number) => {
          return (
            <tr key={i}>
              <td className={classes.title}>{OffDefRankLabelBarRenderer(d)}</td>
              <td>{OffStatRankRenderer(d)}</td>
              <td>{DefStatRankRenderer(d)}</td>
            </tr>
          );
        })}
      </tbody>
      <tbody>
        {rowDataRest.map((d: DataWithRanks, i: number) => {
          return (
            <tr key={i}>
              <td className={classes.title}>{OffDefRankLabelBarRenderer(d)}</td>
              <td>{OffStatRankRenderer(d)}</td>
              <td>{DefStatRankRenderer(d)}</td>
            </tr>
          );
        })}
      </tbody>
      <tbody>
        {rowDataContext.map((d: DataWithRanks, i: number) => {
          return (
            <tr key={i}>
              <td className={classes.title}>{OffDefRankLabelBarRenderer(d)}</td>
              <td>{OffStatRankRenderer(d)}</td>
              <td>{DefStatRankRenderer(d)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function prepareData(
  leagueData: TeamStats[],
  teamId: number,
  teamData: TeamStats
) {
  const rowDataFourFactors = [
    {
      label: "xPPP",
      fmt: decFormat2,
      offValue: teamData.xPPP,
      offRank: rankForStat("xPPP", teamId, leagueData),
      defValue: teamData.xPPPOpp,
      defRank: rankForStat("xPPPOpp", teamId, leagueData, true),
    },
    {
      label: "PPP",
      fmt: decFormat2,
      offValue: teamData.PPP,
      offRank: rankForStat("PPP", teamId, leagueData),
      defValue: teamData.PPPOpp,
      defRank: rankForStat("PPPOpp", teamId, leagueData, true),
    },
    {
      label: "Shot Quality (xPPS)",
      fmt: decFormat2,
      offValue: teamData.xPPS,
      offRank: rankForStat("xPPS", teamId, leagueData),
      defValue: teamData.xPPSOpp,
      defRank: rankForStat("xPPSOpp", teamId, leagueData, true),
    },
    {
      label: "Shot Volume (Shots/Poss)",
      fmt: decFormat2,
      offValue: teamData.shotsPerPoss,
      offRank: rankForStat("shotsPerPoss", teamId, leagueData),
      defValue: teamData.shotsPerPossOpp,
      defRank: rankForStat("shotsPerPossOpp", teamId, leagueData, true),
    },
    {
      label: "xOR%",
      fmt: decFormat,
      offValue: teamData.xOrPct,
      offRank: rankForStat("xOrPct", teamId, leagueData),
      defValue: teamData.orPctOpp,
      defRank: rankForStat("xOrPctOpp", teamId, leagueData, true),
    },
    {
      label: "xTO%",
      fmt: decFormat,
      offValue: teamData.xTovPct,
      offRank: rankForStat("xTovPct", teamId, leagueData, true),
      defValue: teamData.xTovPctOpp,
      defRank: rankForStat("xTovPctOpp", teamId, leagueData),
    },
    {
      label: "eFG%",
      fmt: decFormat,
      offValue: teamData.efg,
      offRank: rankForStat("efg", teamId, leagueData),
      defValue: teamData.efgOpp,
      defRank: rankForStat("efgOpp", teamId, leagueData, true),
    },
    {
      label: "OR%",
      fmt: decFormat,
      offValue: teamData.orPct,
      offRank: rankForStat("orPct", teamId, leagueData),
      defValue: teamData.orPctOpp,
      defRank: rankForStat("orPctOpp", teamId, leagueData, true),
    },
    {
      label: "TO%",
      fmt: decFormat,
      offValue: teamData.tovPct,
      offRank: rankForStat("tovPct", teamId, leagueData, true),
      defValue: teamData.tovPctOpp,
      defRank: rankForStat("tovPctOpp", teamId, leagueData),
    },
    {
      label: "FTA Rate",
      fmt: decFormat,
      offValue: teamData.ftaPct,
      offRank: rankForStat("ftaPct", teamId, leagueData),
      defValue: teamData.ftaPctOpp,
      defRank: rankForStat("ftaPctOpp", teamId, leagueData, true),
    },
  ];

  const rowDataRest = [
    {
      label: "Shot Difficulty (xPPS Lg)",
      fmt: decFormat2,
      offValue: teamData.xPPSLg,
      offRank: rankForStat("xPPSLg", teamId, leagueData),
      defValue: teamData.xPPSLgOpp,
      defRank: rankForStat("xPPSLgOpp", teamId, leagueData, true),
    },
    {
      label: "Layup%",
      fmt: decFormat,
      offValue: teamData["layupPct"],
      offRank: rankForStat("layupPct", teamId, leagueData),
      defValue: teamData["layupPctOpp"],
      defRank: rankForStat("layupPctOpp", teamId, leagueData, true),
    },
    {
      label: "LayupA%",
      fmt: decFormat,
      offValue: teamData["layupAttPct"],
      offRank: rankForStat("layupAttPct", teamId, leagueData),
      defValue: teamData["layupAttPctOpp"],
      defRank: rankForStat("layupAttPctOpp", teamId, leagueData, true),
    },
    {
      label: "NL2%",
      fmt: decFormat,
      offValue: teamData["nl2Pct"],
      offRank: rankForStat("nl2Pct", teamId, leagueData),
      defValue: teamData["nl2PctOpp"],
      defRank: rankForStat("nl2PctOpp", teamId, leagueData, true),
    },
    {
      label: "NL2A%",
      fmt: decFormat,
      offValue: teamData["nl2AttPct"],
      offRank: rankForStat("nl2AttPct", teamId, leagueData, true),
      defValue: teamData["nl2AttPctOpp"],
      defRank: rankForStat("nl2AttPctOpp", teamId, leagueData),
    },
    {
      label: "3P%",
      fmt: decFormat,
      offValue: teamData["3pPct"],
      offRank: rankForStat("3pPct", teamId, leagueData),
      defValue: teamData["3pPctOpp"],
      defRank: rankForStat("3pPctOpp", teamId, leagueData, true),
    },
    {
      label: "3PA%",
      fmt: decFormat,
      offValue: teamData["3paPct"],
      offRank: rankForStat("3paPct", teamId, leagueData),
      defValue: teamData["3paPctOpp"],
      defRank: rankForStat("3paPctOpp", teamId, leagueData, true),
    },
    {
      label: "FT%",
      fmt: decFormat,
      offValue: teamData.ftPct,
      offRank: rankForStat("ftPct", teamId, leagueData),
      defValue: teamData.ftPctOpp,
      defRank: rankForStat("ftPctOpp", teamId, leagueData, true),
    },
    {
      label: "AST/FGM%",
      fmt: decFormat,
      offValue: teamData.astFgmPct,
      offRank: rankForStat("astFgmPct", teamId, leagueData),
      defValue: teamData.astFgmPctOpp,
      defRank: rankForStat("astFgmPctOpp", teamId, leagueData, true),
    },
  ];

  const rowDataContext = [
    {
      label: "Half-court PPP",
      fmt: decFormat2,
      offValue: teamData.hcPPP,
      offRank: rankForStat("hcPPP", teamId, leagueData),
      defValue: teamData.hcPPPOpp,
      defRank: rankForStat("hcPPPOpp", teamId, leagueData, true),
    },
    {
      label: "Transition PPP",
      fmt: decFormat2,
      offValue: teamData.transPPP,
      offRank: rankForStat("transPPP", teamId, leagueData),
      defValue: teamData.transPPPOpp,
      defRank: rankForStat("transPPPOpp", teamId, leagueData, true),
    },
    // TODO(chrisbu): Consider whether to fully delete this or uncomment it.
    // {
    //   label: "Transition PTS / All Poss",
    //   fmt: decFormat2,
    //   offValue: teamData.transPtsAllPoss,
    //   offRank: rankForStat("transPtsAllPoss", teamId, leagueData),
    //   defValue: teamData.transPtsAllPossOpp,
    //   defRank: rankForStat("transPtsAllPossOpp", teamId, leagueData, true),
    // },
    {
      label: "Transition Rate",
      fmt: dec100Format,
      offValue: teamData.transRate,
      offRank: rankForStat("transRate", teamId, leagueData),
      defValue: teamData.transRateOpp,
      defRank: rankForStat("transRateOpp", teamId, leagueData, true),
    },
    {
      label: "ATO PPP",
      fmt: decFormat2,
      offValue: teamData.atoPPP,
      offRank: rankForStat("atoPPP", teamId, leagueData),
      defValue: teamData.atoPPPOpp,
      defRank: rankForStat("atoPPPOpp", teamId, leagueData, true),
    },
    {
      label: "SOB PPP",
      fmt: decFormat2,
      offValue: teamData.sobPPP,
      offRank: rankForStat("sobPPP", teamId, leagueData),
      defValue: teamData.sobPPPOpp,
      defRank: rankForStat("sobPPPOpp", teamId, leagueData, true),
    },
    {
      label: "BOB PPP",
      fmt: decFormat2,
      offValue: teamData.bobPPP,
      offRank: rankForStat("bobPPP", teamId, leagueData),
      defValue: teamData.bobPPPOpp,
      defRank: rankForStat("bobPPPOpp", teamId, leagueData, true),
    },
    {
      label: "Zone xPPP",
      fmt: decFormat2,
      offValue: teamData.zonexPPP,
      offRank: rankForStat("zonexPPP", teamId, leagueData),
      defValue: teamData.zonexPPPOpp,
      defRank: rankForStat("zonexPPPOpp", teamId, leagueData, true),
    },
    // TODO(chrisbu): Consider whether to fully delete this or uncomment it.
    // {
    //   label: "PNR xPPP",
    //   fmt: decFormat2,
    //   offValue: teamData.pnrxPPP,
    //   offRank: rankForStat("pnrxPPP", teamId, leagueData),
    //   defValue: teamData.pnrxPPPOpp,
    //   defRank: rankForStat("pnrxPPPOpp", teamId, leagueData, true),
    // },
    {
      label: "Crashers/Shot",
      fmt: decFormat2,
      offValue: teamData.crashersPerShot,
      offRank: rankForStat("crashersPerShot", teamId, leagueData),
      defValue: teamData.crashersPerShotOpp,
      defRank: rankForStat("crashersPerShotOpp", teamId, leagueData, true),
    },
  ];

  return {
    rowDataFourFactors,
    rowDataRest,
    rowDataContext,
  };
}

function barOffRank(d: DataWithRanks) {
  if (!d.offRank) {
    return 0;
  }
  return (100 * (31 - d.offRank)) / 30 + "%";
}

function barDefRank(d: DataWithRanks) {
  if (!d.defRank) {
    return 0;
  }
  return (100 * (31 - d.defRank)) / 30 + "%";
}

function OffDefRankLabelBarRenderer(d: DataWithRanks) {
  return (
    <LabelBarRenderer data={d} config={{ bars: [barOffRank, barDefRank] }} />
  );
}

function OffStatRankRenderer(d: DataWithRanks) {
  return (
    <StatRankRenderer
      data={{ rank: d.offRank, stat: d.offValue }}
      config={{
        rendererOptions: {
          fmt: d.fmt,
          showStat: true,
          leftStat: true,
        },
      }}
    />
  );
}

function DefStatRankRenderer(d: DataWithRanks) {
  return (
    <StatRankRenderer
      data={{ rank: d.defRank, stat: d.defValue }}
      config={{
        rendererOptions: {
          fmt: d.fmt,
          showStat: true,
          leftStat: true,
        },
      }}
    />
  );
}

// By default higher is better but for def stats or certain off stats lower
// numbers are better.
function rankForStat(
  stat: keyof TeamStats,
  teamId: number,
  leagueData: TeamStats[],
  reverse = false
) {
  const teamData = leagueData.find((t) => t.teamId === teamId);
  if (teamData === undefined || teamData[stat] === null) return null;

  return (
    leagueData
      .filter((l) => l[stat] !== null)
      .sort((a, b) => {
        const aVal = a[stat];
        const bVal = b[stat];

        if (aVal === bVal) return 0;

        if (reverse) {
          if (aVal === null) return 1;
          else if (bVal === null) return -1;
          return aVal - bVal;
        } else {
          if (aVal === null) return -1;
          else if (bVal === null) return 1;
          return bVal - aVal;
        }
      })
      .findIndex((l) => l.teamId === teamId) + 1
  );
}
