import React from "react";
import { ParentSize } from "@visx/responsive";

import { GameWinProbability } from "../../../shared/routers/GameRouter";
import { LineChart } from "../chart/LineChart";
import { getContrastingTeamColor } from "../../util/Colors";
import { pctFormatRound } from "../../util/Format";

export function GameWinProbabilityChart(props: {
  home: { teamid: number; team: string };
  away: { teamid: number; team: string };
  gameWinProb: GameWinProbability[];
}) {
  const { gameWinProb, home, away } = props;

  // This data viz doesn't make sense for series page when multiple games are
  // selected so hide it if we have multiple game ids present.
  const hideGameWinProb = new Set(gameWinProb.map((p) => p.gameId)).size > 1;

  if (hideGameWinProb) {
    return (
      <div>
        To see game win probability visualization select a single game from the
        series.
      </div>
    );
  }

  const homeTeamColors = getContrastingTeamColor(home.teamid, away.teamid);
  const awayTeamColors = getContrastingTeamColor(away.teamid, home.teamid);

  const segments = gameWinProb
    .map((wp) => ({
      x: gameWinProbDataToTime(wp),
      y: wp.homeWinProb,
    }))
    .sort((b, a) => b.x - a.x);

  // Split segments into home and away segments depending on who has higher win prob.
  const homeSegments: { x: number; y: number }[][] = [];
  const awaySegments: { x: number; y: number }[][] = [];

  let curSegment: { x: number; y: number }[] = [];
  let curWinner = segments[0] && segments[0].y > 0.5 ? "home" : "away";
  for (let i = 0; i < segments.length; i++) {
    const point = segments[i];
    if (!point) break;
    const pointWinner = point.y > 0.5 ? "home" : "away";
    if (curWinner === pointWinner) {
      curSegment.push(point);
    } else {
      // If we are switching who is favored we need to add a point halfway
      // between the previous point and the current point with win prob of .5.
      const prevPoint = curSegment[curSegment.length - 1];
      if (prevPoint) {
        const x = (prevPoint.x + point.x) / 2;
        const y = 0.5;
        curSegment.push({ x, y });
      }
      if (curWinner === "home") {
        homeSegments.push(curSegment);
      } else {
        awaySegments.push(curSegment);
      }
      curSegment = [];
      // Similar to above, add an extra segment here from the point where win
      // prob flips over.
      if (prevPoint) {
        const x = (prevPoint.x + point.x) / 2;
        const y = 0.5;
        curSegment.push({ x, y });
      }
      curSegment.push(point);
      curWinner = pointWinner;
    }
  }
  if (curWinner === "home") {
    homeSegments.push(curSegment);
  } else {
    awaySegments.push(curSegment);
  }

  const lines = [
    {
      segments: homeSegments,
      color: homeTeamColors.primary,
      label: home.team,
    },
    {
      segments: awaySegments,
      color: awayTeamColors.primary,
      label: away.team,
    },
  ];

  const xTicks = [0, 720, 720 * 2, 720 * 3];

  if (gameWinProb.find((d) => d.period > 4)) {
    xTicks.push(720 * 4);
  }

  const formatTick = (x: number) => {
    if (x === 0) return "Q1";
    else if (x === 720) return "Q2";
    else if (x === 720 * 2) return "Q3";
    else if (x === 720 * 3) return "Q4";
    return "OT";
  };

  return (
    <div style={{ position: "relative" }}>
      <ParentSize>
        {({ width }) => (
          <LineChart
            yDomain={[0, 1]}
            lines={lines}
            referenceLines={[{ value: 0.5 }]}
            height={Math.max(width / 4, 200)}
            width={width}
            xTicks={xTicks}
            xTickFormat={(x) => formatTick(x as number)}
            yTickFormat={(y) => {
              const numY = y as number;
              const val = numY > 0.5 ? numY : 1 - numY;
              return pctFormatRound(val);
            }}
            showLegend={true}
          />
        )}
      </ParentSize>
    </div>
  );
}

// Return how many game seconds have elapsed by this point in game. 720 seconds per period, 300 seconds in OT. S
function gameWinProbDataToTime(d: GameWinProbability) {
  const totalTimeInPeriod = d.period < 5 ? 720 : 300;
  const elapsedTimeInPeriod = totalTimeInPeriod - d.startGameClock;
  // Get number of periods (before OT) that have elapsed.
  const regPeriodsBeforePeriod = Math.min(d.period - 1, 4);
  const otPeriodsBeforePeriod = Math.max(d.period - 5, 0);
  const elapsedTimeBeforePeriod =
    regPeriodsBeforePeriod * 720 + otPeriodsBeforePeriod * 300;
  return elapsedTimeBeforePeriod + elapsedTimeInPeriod;
}
