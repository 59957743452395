import React, { useMemo, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";

import {
  Table,
  createColumnHelper,
  SortingState,
} from "../components/core/Table";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { trpc } from "../util/tRPC";
import { Highlights } from "../constants/AppConstants";
import { dec100Format, plusMinusFormat2 } from "../util/Format";
import AppContext from "../../shared/AppContext";
import { TeamTableCell } from "../components/core/TableCell";
import { NBAStandings } from "../components/standings/NbaStandings";
import { groupBy } from "../../shared/util/Collections";

export function StandingsPage() {
  const { leagueKey } = useParams();
  const navigate = useNavigate();

  const season = AppContext.currentSeason;

  const { data: leagues } = trpc.league.getLeagues.useQuery({
    season,
  });

  const handleLeagueChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const leagueKey = evt.target.value;
    navigate(`/standings/${leagueKey}`);
  };

  const leagueArray = leagues || [];
  const popularLeagues = leagueArray.filter((l) => l.sortScore > 0);
  const otherLeagues = leagueArray.filter((l) => l.sortScore <= 0);

  const header = (
    <div>
      <h1>Standings</h1>
      <Form.Select
        value={leagueKey}
        onChange={handleLeagueChange}
        style={{
          width: "auto",
          marginRight: "1rem",
          display: "inline-block",
        }}
      >
        <optgroup label="Popular Leagues">
          {popularLeagues.map((l) => (
            <option key={l.leagueKey} value={l.leagueKey}>
              {l.league}
            </option>
          ))}
        </optgroup>
        <optgroup label="Other">
          {otherLeagues.map((l) => (
            <option key={l.leagueKey} value={l.leagueKey}>
              {l.league}
            </option>
          ))}
        </optgroup>
      </Form.Select>
    </div>
  );

  if (!leagueKey || !leagues) return null;

  return (
    <Page header={{ component: header }} title="Standings">
      {leagueKey === "nba" ? (
        <NBAStandings />
      ) : (
        <Standings leagueKey={leagueKey} season={season} />
      )}
    </Page>
  );
}

interface StandingRow {
  conference: string | null;
  teamId: number;
  teamName: string;
  homeWins: number;
  homeLosses: number;
  awayWins: number;
  awayLosses: number;
  ptsFor: number;
  ptsAgainst: number;
}

const columnHelper = createColumnHelper<StandingRow>();

function Standings(props: { season: string; leagueKey: string }) {
  const { season, leagueKey } = props;

  const { data } = trpc.league.getLeagueResults.useQuery({
    season,
    leagueKey,
  });

  const standingRows = useMemo(() => {
    if (!data) return [];

    const teamObj: Record<string, StandingRow> = {};
    for (const d of data) {
      if (!teamObj[d.homeTeamID]) {
        teamObj[d.homeTeamID] = {
          conference: d.homeConference,
          teamId: d.homeTeamID,
          teamName: d.homeTeam,
          homeWins: 0,
          homeLosses: 0,
          awayWins: 0,
          awayLosses: 0,
          ptsFor: 0,
          ptsAgainst: 0,
        };
      }
      if (!teamObj[d.awayTeamID]) {
        teamObj[d.awayTeamID] = {
          conference: d.awayConference,
          teamId: d.awayTeamID,
          teamName: d.awayTeam,
          homeWins: 0,
          homeLosses: 0,
          awayWins: 0,
          awayLosses: 0,
          ptsFor: 0,
          ptsAgainst: 0,
        };
      }

      const homeObj = teamObj[d.homeTeamID];
      if (homeObj) {
        homeObj.ptsFor += d.homePts;
        homeObj.ptsAgainst += d.awayPts;
        homeObj.homeWins += d.winningTeamId === d.homeTeamID ? 1 : 0;
        homeObj.homeLosses += d.winningTeamId !== d.homeTeamID ? 1 : 0;
      }

      const awayObj = teamObj[d.awayTeamID];
      if (awayObj) {
        awayObj.ptsFor += d.awayPts;
        awayObj.ptsAgainst += d.homePts;
        awayObj.awayWins += d.winningTeamId !== d.awayTeamID ? 1 : 0;
        awayObj.awayLosses += d.winningTeamId === d.awayTeamID ? 1 : 0;
      }
    }

    return Object.values(teamObj);
  }, [data]);

  if (!data) return null;

  const dataByConference = groupBy(standingRows, (row) => row.conference || "");

  return (
    <Row>
      <Col>
        <Panel header="Standings">
          <Row>
            {Object.keys(dataByConference)
              .sort((a, b) => {
                if (!a) return 1;
                if (!b) return -1;
                return a.localeCompare(b);
              })
              .map((conf) => (
                <Col key={conf}>
                  <b>
                    {conf
                      ? conf
                      : Object.keys(dataByConference).length > 1
                      ? "No Conference"
                      : ""}
                  </b>
                  <StandingsTable
                    leagueKey={leagueKey}
                    season={season}
                    data={(dataByConference[conf] || []).sort((a, b) => {
                      const aWins = a.homeWins + a.awayWins;
                      const bWins = b.homeWins + b.awayWins;
                      const aLosses = a.homeLosses + a.awayLosses;
                      const bLosses = b.homeLosses + b.awayLosses;
                      const aPct = aWins / (aWins + aLosses);
                      const bPct = bWins / (bWins + bLosses);
                      return bPct - aPct;
                    })}
                  />
                </Col>
              ))}
          </Row>
        </Panel>
      </Col>
    </Row>
  );
}

function StandingsTable(props: {
  data: StandingRow[];
  leagueKey: string;
  season: string;
}) {
  const { data, leagueKey, season } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("teamName", {
        header: () => "Team",
        cell: (info) => (
          <TeamTableCell
            id={info.row.original.teamId}
            name={info.getValue()}
            league={leagueKey}
            season={season}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor((row) => row.homeWins + row.awayWins, {
        id: "wins",
        header: () => "W",
        meta: { group: g, highlights: Highlights.Max },
      }),
      columnHelper.accessor((row) => row.homeLosses + row.awayLosses, {
        id: "losses",
        header: () => "L",
        meta: { group: g, highlights: Highlights.Min },
      }),
      columnHelper.accessor(
        (row) =>
          (row.homeWins + row.awayWins) /
          (row.homeLosses + row.awayLosses + row.homeWins + row.awayWins),
        {
          id: "pct",
          header: () => "%",
          cell: (info) => dec100Format(info.getValue()),
          meta: { group: g++, highlights: Highlights.Max },
        }
      ),
      columnHelper.accessor(
        (row) =>
          (row.ptsFor - row.ptsAgainst) /
          (row.awayLosses + row.awayWins + row.homeLosses + row.homeWins),
        {
          id: "plusMinus",
          header: () => "Diff",
          cell: (info) => plusMinusFormat2(info.getValue()),
          meta: { group: g++, highlights: Highlights.Max },
        }
      ),
      columnHelper.accessor(
        (row) => row.homeWins / (row.homeLosses + row.homeWins),
        {
          id: "home",
          header: () => "Home",
          cell: (info) =>
            info.row.original.homeWins + "-" + info.row.original.homeLosses,
          meta: { group: g, highlights: Highlights.Max },
        }
      ),
      columnHelper.accessor(
        (row) => row.awayWins / (row.awayLosses + row.awayWins),
        {
          id: "away",
          header: () => "Away",
          cell: (info) =>
            info.row.original.awayWins + "-" + info.row.original.awayLosses,
          meta: { group: g++, highlights: Highlights.Max },
        }
      ),
    ];
  }, [leagueKey, season]);

  return (
    <Table
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
    />
  );
}
