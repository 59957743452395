import React, { useState, useEffect } from "react";
import { Button, Form, Spinner, ProgressBar } from "react-bootstrap";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

import { Restrict } from "../core/Restrict";
import { decFormat, intToSortableCharacterString } from "../../util/Format";
import { Red1, Green1, redHigh } from "../../constants/ColorConstants";
import { sum } from "../../util/Util";

export function VideoDownloader(props: {
  clipChunkSize?: number;
  synergyEditor: boolean;
  defaultName: string;
  clips: { url: string; label: string; auxData?: Record<string, string> }[];
}) {
  const {
    synergyEditor,
    clips: unlabeledClips,
    defaultName,
    clipChunkSize,
  } = props;
  const clips = unlabeledClips.map((c, i) =>
    Object.assign({}, c, {
      label: `${intToSortableCharacterString(i, unlabeledClips.length)} - ${
        c.label
      }`,
    })
  );
  const [zipFileName, setZipFileName] = useState(`${defaultName}.zip`);
  const [completed, setCompleted] = useState<Record<string, number>>({});
  const [errors, setErrors] = useState(new Set<string>());
  const [notStarted, setNotStarted] = useState(
    new Set(clips.map((c) => c.url))
  );
  const [, setRefresh] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [obj, setObj] = useState({ status: "" });
  const [tooBigToZip, setTooBigToZip] = useState(false);

  useEffect(() => {
    return () => {
      // Set abort status to stop downloading more chunks.
      obj.status = "abort";
    };
  }, [obj]);

  const download = () => {
    const obj = { status: "" };
    setObj(obj);
    setIsDownloading(true);
    const zip = new JSZip();
    const completed: Record<string, number> = {};
    setCompleted(completed);
    const errors = new Set<string>();
    setErrors(errors);
    const notStarted = new Set(clips.map((c) => c.url));
    setNotStarted(notStarted);

    const downloadClip = (clip: {
      url: string;
      label: string;
      auxData?: Record<string, string>;
    }) => {
      const filename = `${synergyEditor ? "video/" : ""}${clip.label.replaceAll(
        "/",
        "-"
      )}.mp4`;
      notStarted.delete(clip.url);
      setRefresh({});

      // Loading a file and add it in a zip file.
      JSZipUtils.getBinaryContent(clip.url, (err: Error, data: ArrayBuffer) => {
        // Stop all progress if we've recieved the abort signal
        // (aka the overlay was closed).
        if (obj.status === "abort") {
          return;
        }

        if (err) {
          errors.add(clip.url);
        } else {
          zip.file(filename, data, { binary: true });
          completed[clip.url] = data.byteLength;
        }

        // If we've accounted for every clip in every chunk or recieved the
        // cancel signal, generate the zip.
        if (
          obj.status === "cancel" ||
          errors.size + Object.keys(completed).length === clips.length
        ) {
          obj.status = "canceled";
          // If synergy editor add the CSV to files:
          if (synergyEditor) {
            const csvHeader = `#,Title,Description,Period,Clock,Game,Date,Result,Synergy String\n`;
            const csvBody = [];
            for (let i = 0; i < clips.length; i++) {
              const clip = clips[i];
              if (!clip) continue;
              const auxData = clip.auxData;
              const title = defaultName;
              let csvRow;
              if (auxData) {
                const period = auxData.period && auxData.period.substring(1);
                csvRow = `${i + 1},${title},${auxData.game} - ${
                  auxData.dateStr
                } QTR: ${period} CLK: ${auxData.gameClockStr},${period},${
                  auxData.gameClockStr
                },${auxData.game},${auxData.dateStr},${
                  auxData.makeMiss || ""
                },${title} > > > > > > ${title} > > > > >\n`;
              } else {
                csvRow = ",,,,,,,,\n";
              }
              csvBody.push(csvRow);
            }
            zip.file("export.csv", csvHeader + csvBody.join("\n"));
          }

          // If less than 2GB just save it.
          if (sum((x: number) => x, Object.values(completed)) < 2_000_000_000) {
            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, zipFileName);
              setIsDownloading(false);
            });
          } else {
            setTooBigToZip(true);
            // If greater than 2GB save each file separately.
            zip.forEach(async (relativePath, file) => {
              // Read the file content as a blob.
              const content = await file.async("blob");
              saveAs(content, relativePath);
            });
            setIsDownloading(false);
          }
        } else {
          const nextClip = clips.find((c) => notStarted.has(c.url));
          // Don't download if we are canceling.
          if (nextClip && obj.status === "") {
            downloadClip(nextClip);
          }
        }
        setRefresh({});
      });
    };

    // Mostly arbitrary number for amount of clips to be fetching at once.
    for (let i = 0; i < (clipChunkSize || 5); i++) {
      const clip = clips[i];
      if (clip) {
        downloadClip(clip);
      }
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <div>
        <Form.Label>Zip File Name</Form.Label>
        <Form.Control
          disabled={tooBigToZip}
          value={zipFileName}
          onChange={(evt) => setZipFileName(evt.target.value)}
        ></Form.Control>
        {tooBigToZip && (
          <span style={{ color: redHigh }}>
            Files were too large to quickly combine into a single zip,
            downloading each file separately.
          </span>
        )}
      </div>
      {isDownloading && (
        <ProgressBar
          now={
            (100 * (Object.keys(completed).length + errors.size)) / clips.length
          }
        />
      )}
      {Object.keys(completed).length > 0 && (
        <Restrict roles={["bia"]}>
          <span>
            <b>[BIA ONLY DEBUG]: </b>
            Downloaded{" "}
            {decFormat(
              sum((x: number) => x / 1_000_000, Object.values(completed))
            )}{" "}
            MB
          </span>
        </Restrict>
      )}
      {errors.size > 0 && (
        <b style={{ color: "red" }}>
          Failed to download {errors.size} clip(s).
        </b>
      )}
      <div style={{ margin: 8, maxHeight: "40vh", overflowY: "auto" }}>
        {clips.map((c, i) => {
          const isDone = completed[c.url];
          const isError = errors.has(c.url);
          const isPending = !notStarted.has(c.url) && !isDone && !isError;
          return (
            <div key={i}>
              <a href={c.url} target="_blank" rel="noreferrer">
                {c.label}
              </a>{" "}
              {isDone && <BsFillCheckCircleFill color={Green1} />}
              {isError && <BsFillXCircleFill color={Red1} />}
              {isPending && (
                <Spinner animation="border" size="sm" variant="primary" />
              )}
            </div>
          );
        })}
      </div>
      <Button onClick={download} disabled={isDownloading}>
        {!isDownloading
          ? `Download ${clips.length} Clips`
          : "Downloading... closing this modal will cancel."}
      </Button>
      {isDownloading && (
        <Button onClick={() => (obj.status = "cancel")}>
          Cancel Full Download & Save Partial Zip
        </Button>
      )}
    </div>
  );
}
