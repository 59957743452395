import React, { useState, useRef, useEffect, useContext } from "react";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/styles";
import { FaSearch } from "react-icons/fa";
import { Form } from "react-bootstrap";

import { urlFromResult, getSecondaryText } from "../../util/Search";
import { SearchResult } from "../../../shared/routers/SearchRouter";
import { useEventListener, useDebounce } from "../../util/Hooks";
import { UserPreferenceContext } from "../../UserContext";
import { trpc } from "../../util/tRPC";

const SUGGESTION_LIMIT = 25;

const useStyles = makeStyles(() =>
  createStyles({
    searchBarContainer: {
      position: "relative",
    },
    nonMobile: {
      marginLeft: 15,
      marginRight: 15,
      width: 220,
    },
    mobile: {
      flexGrow: 1,
    },
    searchBarInput: {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      border: 0,
      borderRadius: 34,
      color: "rgba(255,255,255,.5)",
      fontSize: 14,
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        borderColor: "#66afe9",
        color: "rgba(255,255,255,.5)",
        boxShadow:
          "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)",
      },
    },
    searchIcon: {
      position: "absolute",
      top: 9,
      right: 10,
      color: "rgba(255,255,255,.3)",
    },
    hideResults: {
      display: "none",
    },
    searchResults: {
      position: "absolute",
      top: "100%",
      left: 0,
      zIndex: 1000,
      minWidth: 160,
      width: "100%",
      padding: "5px 0",
      margin: "2px 0 0",
      listStyle: "none",
      fontSize: 14,
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      borderRadius: 4,
      boxShadow: "0 6px 12px rgb(0 0 0 / 18%)",
      backgroundClip: "padding-box",
      overflowY: "scroll",
      maxHeight: 400,
    },
    searchSuggestion: {
      display: "block",
      padding: "3px 20px",
      clear: "both",
      fontWeight: 400,
      lineHeight: "1.42857",
      color: "#333",
    },
    searchSecondary: {
      color: "#888",
      fontSize: 10,
      height: 14,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    selectedSuggestion: {
      backgroundColor: "rgb(222 239 249)",
    },
  })
);

export function SearchBar(props: { className?: string; mobile?: boolean }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [results, setResults] = useState<SearchResult[]>([]);
  const target = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const mobile = props.mobile == null ? false : props.mobile;

  const searchPriorityPref = useContext(UserPreferenceContext)[
    "Search Priority"
  ];

  const resultPriority = (a: SearchResult, b: SearchResult) => {
    switch (searchPriorityPref) {
      case 0: {
        return b.score - a.score;
      }
      case 1: {
        const searchCountA = a.searchCount;
        const searchCountB = b.searchCount;
        // Next make sure NBA is above everything else.
        if (a.score !== b.score) {
          return b.score - a.score;
        } else if (searchCountA !== searchCountB) {
          return searchCountB - searchCountA;
        }
        // Otherwise alphabetically sort.
        return b.label > a.label ? 1 : 0;
      }
      default: {
        // Option 2 (which is now the default) is to use search count.
        if (b.searchCount !== a.searchCount) {
          return b.searchCount - a.searchCount;
        } else {
          return b.label > a.label ? 1 : 0;
        }
      }
    }
  };

  const debouncedSearchVal = useDebounce(searchVal, 300);

  trpc.search.getSearchResults.useQuery(
    { searchQuery: debouncedSearchVal },
    {
      onSuccess: (data) => {
        const results = data.sort(resultPriority).slice(0, SUGGESTION_LIMIT);
        setResults(results);
      },
    }
  );

  // Focuses the input on "/".
  const handleKeyDown = (event: KeyboardEvent) => {
    // Ignore if the user is typing in an input/textarea.
    if (
      document.activeElement &&
      ["INPUT", "TEXTAREA"].includes(document.activeElement.tagName)
    ) {
      return;
    }

    switch (event.key) {
      case "/":
      case "divide":
        if (target.current) {
          target.current.focus();
        }
        // Prevents the '/' from entering the input field.
        event.preventDefault();
    }
  };

  useEventListener("keydown", handleKeyDown);

  useEffect(() => {
    setSelectedIdx(-1);
  }, [inputValue]);

  const onSearchInput = (evt: React.FormEvent<HTMLInputElement>) => {
    const newQuery = evt.currentTarget.value;
    setInputValue(newQuery);
    // Make sure we are showing results.
    setShowResults(true);
    setSearchVal(newQuery);
  };

  useEffect(() => {
    // -1 means that no option should be highlighted / scrolled into view.
    if (selectedIdx === -1) return;

    const el = document.querySelectorAll(".search-option")[selectedIdx];
    if (el) {
      el.scrollIntoView({ block: "nearest" });
    }
  }, [selectedIdx]);

  const highlightIdx = (num: number) => {
    let newIdx = selectedIdx + num;
    if (newIdx < -1) {
      newIdx = results.length - 1;
    } else if (newIdx >= results.length) {
      newIdx = -1;
    }
    setSelectedIdx(newIdx);
  };

  const onSearchKeyPress = (evt: any) => {
    if (evt.code === "ArrowDown") {
      highlightIdx(1);
    } else if (evt.code === "ArrowUp") {
      highlightIdx(-1);
    } else if (evt.code === "Enter") {
      // If no option has the highlight, select the first result.
      const idx = selectedIdx === -1 ? 0 : selectedIdx;
      const result = results[idx];
      if (result) {
        selectResult(result);
      }
      // Prevents the form from submitting on enter.
      evt.preventDefault();
    }
  };

  const selectResult = (result: SearchResult) => {
    const url = urlFromResult(result);
    navigate(url);
    setInputValue(result.label);
    setResults([result]);
    setShowResults(false);
  };

  const renderResults = () => {
    if (results.length === 0 || searchVal === "") return;
    return (
      <div className={classes.searchResults}>
        <div>
          {results.slice(0, SUGGESTION_LIMIT).map((sr: SearchResult, i) => {
            return (
              <div
                key={i}
                className={
                  classes.searchSuggestion +
                  " search-option " +
                  (i === selectedIdx ? classes.selectedSuggestion : "")
                }
                onMouseDown={() => selectResult(sr)}
              >
                {highlightText(sr.label, searchVal)}
                <div className={classes.searchSecondary}>
                  {getSecondaryText(sr)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <Form className={cx(props.className, { [classes.mobile]: mobile })}>
        <Form.Group
          className={cx(classes.searchBarContainer, {
            [classes.nonMobile]: !mobile,
          })}
        >
          <Form.Control
            ref={target}
            className={classes.searchBarInput}
            placeholder="Search"
            value={inputValue}
            onInput={onSearchInput}
            onKeyDown={onSearchKeyPress}
            onBlur={() => setShowResults(false)}
            onFocus={() => {
              target.current?.select();
              setShowResults(true);
            }}
          />
          <FaSearch className={classes.searchIcon} />
          {showResults && renderResults()}
        </Form.Group>
      </Form>
    </>
  );
}

/**
 * Given a target string and the search val returns a JSX element that bolds
 * the matches of the search val in the target string.
 */
function highlightText(target: string, searchVal: string) {
  const targetTokens = target.split(" ");
  const searchValTokens = searchVal.toLowerCase().split(" ");

  return (
    <span>
      {targetTokens.map((tt, i) => {
        // If it's not the last token make sure we add a space.
        const space = i < targetTokens.length - 1 ? " " : "";
        const match = searchValTokens.find(
          (svt) => tt.toLowerCase().indexOf(svt) === 0
        );
        if (match) {
          return (
            <span key={i}>
              <b>{tt.substring(0, match.length)}</b>
              <span>{tt.substring(match.length, tt.length) + space}</span>
            </span>
          );
        } else {
          return <span key={i}>{tt + space}</span>;
        }
      })}
    </span>
  );
}
