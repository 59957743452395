import { RosterPlayer } from "../../shared/routers/RosterRouter";

const StatusValueMap: Record<string, number> = {
  STARTER: 1,
  BENCH: 0,
  INACTIVE: -1,
};

/**
 * Sorts players in descending status: STARTER -> BENCH -> INACTIVE.
 * Secondary sort criteria is MPG.
 */
export function playerByStatus(a: RosterPlayer, b: RosterPlayer) {
  if (b.Status !== a.Status) {
    const statusB = StatusValueMap[b.Status];
    const statusA = StatusValueMap[a.Status];
    if (statusB === statusA) return 0;
    if (statusB === undefined) return -1;
    if (statusA === undefined) return 1;
    return statusB - statusA;
  } else if (b.mpg === null && a.mpg !== null) {
    return -1;
  } else if (b.mpg !== null && a.mpg === null) {
    return 1;
  } else if (a.mpg === null && b.mpg === null) {
    return 0;
  } else if (a.mpg !== null && b.mpg !== null) {
    return b.mpg - a.mpg;
  }
  return 0;
}

/** Sorts league by priority. */
export function leagueSort(a: string, b: string) {
  const priority: Record<string, number> = {
    nba: 1000,
    nba_preseason: 800,
    "d-league": 600,
    ncaa: 400,
  };

  const aPriority = priority[a],
    bPriority = priority[b];
  if (aPriority && bPriority) {
    return bPriority - aPriority;
  } else if (aPriority) {
    return -1;
  } else if (bPriority) {
    return 1;
  }
  return String(a).toLowerCase().localeCompare(String(b).toLowerCase());
}

// Function that takes two items, a and b, and a key for that type and compares
// them such that nulls always sort to end.
export function sortNullsToEnd<T>(
  a: T,
  b: T,
  key: keyof T,
  smallerIsBetter?: boolean
) {
  const aValue = a[key];
  const bValue = b[key];

  const multiplier = smallerIsBetter ? -1 : 1;

  if (aValue === null || aValue === undefined) {
    return -1;
  }
  if (bValue === null || bValue === undefined) {
    return 1;
  }
  if (aValue < bValue) {
    return -1 * multiplier;
  }
  if (aValue > bValue) {
    return 1 * multiplier;
  }
  return 0;
}
