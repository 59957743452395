import React, { MouseEvent } from "react";
import { FaSearchPlus } from "react-icons/fa";
import { createStyles, makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      opacity: 0.5,
      cursor: "pointer",
      "&:hover": {
        opacity: 1,
      },
    },
  })
);

export function DetailsControl(props: {
  id: "left" | "right" | "";
  onDetails: (id: "left" | "right" | "", data: any) => void;
  data: any;
}) {
  const classes = useStyles();
  const { id, onDetails, data } = props;

  const handleDetails = (evt: MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (onDetails) {
      onDetails(id, data);
    }
  };

  return (
    <FaSearchPlus
      className={classes.icon}
      title={`Show Details (${id})`}
      onClick={handleDetails}
    />
  );
}
