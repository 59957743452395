import React, { useState } from "react";
import {
  Form,
  Col,
  Row,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";

import { TeamStats } from "../../../shared/routers/TeamRouter";
import { StatComparisonTableBars } from "../table/StatComparisonTableBars";
import {
  dec100Format,
  decFormat,
  decFormat2,
  makePlusMinus,
} from "../../util/Format";
import { TableNote } from "../core/TableNote";

export function GameTeamStats(props: {
  home: { teamabbreviation: string; teamid: number };
  away: { teamabbreviation: string; teamid: number };
  homeStats: TeamStats;
  awayStats: TeamStats;
  leagueStats: TeamStats[];
}) {
  const [compareTeam, setCompareTeam] = useState<"home" | "away">("away");
  const { home, away, homeStats, awayStats, leagueStats } = props;

  const homeTeamAbbreviation = home.teamabbreviation;
  const homeTeamId = home.teamid;
  const awayTeamAbbreviation = away.teamabbreviation;
  const awayTeamId = away.teamid;

  const homeLeagueTeamStats = leagueStats.find(
    (ls) => ls.teamId === homeTeamId
  );
  const awayLeagueTeamStats = leagueStats.find(
    (ls) => ls.teamId === awayTeamId
  );

  const compareTeamStats =
    compareTeam === "home" ? homeLeagueTeamStats : awayLeagueTeamStats;

  function compareTeamStat(stat: keyof TeamStats, factor = 1) {
    if (!compareTeamStats) {
      return undefined;
    }

    // Casting to any b/c so that we can access either the off or def version
    // of a prop (by adding 'Opp' as a suffix).
    return {
      home:
        factor *
        (compareTeamStats as any)[compareTeam === "home" ? stat : stat + "Opp"],
      away:
        factor *
        (compareTeamStats as any)[compareTeam === "away" ? stat : stat + "Opp"],
    };
  }

  // Taking chance PPP grouped by game, team, type and finding the 5th and 95th
  // (types used: transition, sob, bob).
  const pppDomain = [0.302, 1.8255];

  // Explanation of colors.

  // Home team perspective (compare value is home):
  // if home value > compare value then compare team winner = home
  //    home value < compare value then compare team winner = away

  // if away value > compare value then compare team winner = away
  // if away value < compare value then compare team winner = home

  // Away team perspective (compare value is away):
  // if home value > compare value then compare team winner = away
  //    home value < compare value then compare team winner = home

  // if away value > compare value then compare team winner = away
  // if away value < compare value then compare team winner = home

  // Implies:
  // if team === compareTeam and value > compare then color = team,
  //    otherwise color = the other team
  // if team !== compareTeam and value < compare then color = team,
  //    otherwise color = the other team

  function compareColorTeam(value: number, compare: number, key: string) {
    const isHome = key === "home";
    const team = isHome ? homeTeamId.toString() : awayTeamId.toString();
    const otherTeam = isHome ? awayTeamId.toString() : homeTeamId.toString();
    if (value >= compare) {
      return team;
    } else {
      return otherTeam;
    }
  }

  function compareColorTeamReverse(
    value: number,
    compare: number,
    key: string
  ) {
    const isHome = key === "home";
    const team = isHome ? homeTeamId.toString() : awayTeamId.toString();
    const otherTeam = isHome ? awayTeamId.toString() : homeTeamId.toString();
    if (value < compare) {
      return team;
    } else {
      return otherTeam;
    }
  }

  function compareOffFormat(
    compare: number,
    value: number,
    format?: (n: number | null) => string
  ) {
    const pmFormat = makePlusMinus(format || toString);
    const team =
      compareTeam === "home" ? homeTeamAbbreviation : awayTeamAbbreviation;
    return (
      <div>
        <div
          style={{
            fontWeight: 600,
            lineHeight: 1,
            marginBottom: 2,
          }}
        >
          {pmFormat(value - compare)}
        </div>
        <div
          style={{ fontSize: "0.9em", opacity: 0.8 }}
        >{`${team} OFF Avg`}</div>
      </div>
    );
  }
  function compareDefFormat(
    compare: number,
    value: number,
    format?: (n: number | null) => string
  ) {
    const pmFormat = makePlusMinus(format || toString);
    const team =
      compareTeam === "home" ? homeTeamAbbreviation : awayTeamAbbreviation;
    return (
      <div>
        <div style={{ fontWeight: 600, lineHeight: 1, marginBottom: 2 }}>
          {pmFormat(value - compare)}
        </div>
        <div
          style={{ fontSize: "0.9em", opacity: 0.8 }}
        >{`${team} DEF Avg`}</div>
      </div>
    );
  }

  const compareFormat = {
    home: compareTeam === "home" ? compareOffFormat : compareDefFormat,
    away: compareTeam === "away" ? compareOffFormat : compareDefFormat,
  };

  // Domains based on 5% and 95% percentiles across all games.
  const groups = [
    [
      {
        label: "xPPP",
        format: decFormat2,
        domain: [0.8723, 1.25],
        values: {
          home: homeStats.xPPP,
          away: awayStats.xPPP,
        },
        compare: compareTeamStat("xPPP"),
        compareColorTeam,
        compareFormat,
      },
      {
        label: "PPP",
        format: decFormat2,
        domain: [0.8723, 1.25],
        values: {
          home: homeStats.PPP,
          away: awayStats.PPP,
        },
        compare: compareTeamStat("PPP"),
        compareColorTeam,
        compareFormat,
      },
      {
        label: "eFG%",
        format: decFormat,
        domain: [39.29, 60.36],
        values: {
          home: homeStats.efg,
          away: awayStats.efg,
        },
        compare: compareTeamStat("efg"),
        compareColorTeam,
        compareFormat,
      },
      {
        label: "OR%",
        format: decFormat,
        domain: [12.5, 36.06],
        values: {
          home: homeStats.orPct,
          away: awayStats.orPct,
        },
        compare: compareTeamStat("orPct"),
        compareColorTeam,
        compareFormat,
      },
      {
        label: "TO%",
        format: decFormat,
        domain: [8.57, 22.1],
        lowerBetter: true,
        values: {
          home: homeStats.tovPct,
          away: awayStats.tovPct,
        },
        compare: compareTeamStat("tovPct"),
        compareColorTeam: compareColorTeamReverse,
        compareFormat,
      },
      {
        label: "FTA Rate",
        format: decFormat,
        domain: [12.63, 37.25],
        values: {
          home: homeStats.ftaPct,
          away: awayStats.ftaPct,
        },
        compare: compareTeamStat("ftaPct"),
        compareColorTeam,
        compareFormat,
      },
    ],
    [
      {
        label: "2P%",
        format: decFormat,
        domain: [37.88, 59.65],
        values: {
          home: homeStats["2pPct"],
          away: awayStats["2pPct"],
        },
        compare: compareTeamStat("2pPct"),
        compareColorTeam,
        compareFormat,
      },
      {
        label: "3P%",
        format: decFormat,
        domain: [17.86, 52.17],
        values: {
          home: homeStats["3pPct"],
          away: awayStats["3pPct"],
        },
        compare: compareTeamStat("3pPct"),
        compareColorTeam,
        compareFormat,
      },
      {
        label: "3PA%",
        format: decFormat,
        domain: [15.38, 39.77],
        values: {
          home: homeStats["3paPct"],
          away: awayStats["3paPct"],
        },
        compare: compareTeamStat("3paPct"),
        compareColorTeam,
        compareFormat,
      },
      {
        label: "FT%",
        format: decFormat,
        domain: [57.14, 90.9],
        lowerBetter: true,
        values: {
          home: homeStats.ftPct,
          away: awayStats.ftPct,
        },
        compare: compareTeamStat("ftPct"),
        compareColorTeam,
        compareFormat,
      },
      {
        label: "AST/FGM%",
        format: decFormat,
        domain: [41.66, 76.0],
        values: {
          home: homeStats.astFgmPct,
          away: awayStats.astFgmPct,
        },
        compare: compareTeamStat("astFgmPct"),
        compareColorTeam,
        compareFormat,
      },
      {
        label: "Crashers/Shot",
        format: decFormat2,
        domain: [1.15, 1.74],
        values: {
          home: homeStats.crashersPerShot,
          away: awayStats.crashersPerShot,
        },
        compare: compareTeamStat("crashersPerShot"),
        compareColorTeam,
        compareFormat,
      },
    ],
    [
      {
        label: "Half-court PPP",
        format: decFormat2,
        domain: pppDomain,
        values: {
          home: homeStats.hcPPP,
          away: awayStats.hcPPP,
        },
        compare: compareTeamStat("hcPPP"),
        compareColorTeam,
        compareFormat,
      },
      {
        label: "Transition PPP",
        format: decFormat2,
        domain: pppDomain,
        values: {
          home: homeStats.transPPP,
          away: awayStats.transPPP,
        },
        compare: compareTeamStat("transPPP"),
        compareColorTeam,
        compareFormat,
      },
      {
        label: "Transition PTS / All Poss",
        format: decFormat2,
        domain: [0.0751, 0.3028],
        values: {
          home: homeStats.transPtsAllPoss,
          away: awayStats.transPtsAllPoss,
        },
        compare: compareTeamStat("transPtsAllPoss"),
        compareColorTeam,
        compareFormat,
      },
      {
        label: "Transition Rate",
        format: dec100Format,
        domain: [0.0686, 0.2151],
        values: {
          home: homeStats.transRate,
          away: awayStats.transRate,
        },
        compare: compareTeamStat("transRate"),
        compareColorTeam,
        compareFormat,
      },
      {
        label: "SOB PPP",
        format: decFormat2,
        domain: pppDomain,
        values: {
          home: homeStats.sobPPP,
          away: awayStats.sobPPP,
        },
        compare: compareTeamStat("sobPPP"),
        compareColorTeam,
        compareFormat,
      },
      {
        label: "BOB PPP",
        format: decFormat2,
        domain: pppDomain,
        values: {
          home: homeStats.bobPPP,
          away: awayStats.bobPPP,
        },
        compare: compareTeamStat("bobPPP"),
        compareColorTeam,
        compareFormat,
      },
    ],
  ];

  return (
    <div>
      <Form.Label>Compare Against</Form.Label>
      <div>
        <ToggleButtonGroup
          name="game-team-stats-compare"
          type="radio"
          value={compareTeam}
          onChange={setCompareTeam}
        >
          <ToggleButton id="game-team-stats-away" value={"away"}>
            {`${awayTeamAbbreviation} Season Averages`}
          </ToggleButton>
          <ToggleButton id="game-team-stats-home" value={"home"}>
            {`${homeTeamAbbreviation} Season Averages`}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <Row>
        {groups.map((group, i) => {
          return (
            <Col key={i} md={4}>
              <StatComparisonTableBars
                labels={{
                  home: homeTeamAbbreviation,
                  away: awayTeamAbbreviation,
                }}
                data={group}
                teams={{
                  home: homeTeamId,
                  away: awayTeamId,
                }}
                groupColumnLabel={"Team"}
                valueColumnLabel={""}
                columnWidths={[100, 50, 55]}
              />
            </Col>
          );
        })}
      </Row>
      <TableNote
        note={`The tail of the comets represents the season average
          for the selected team (currently ${
            compareTeam === "home" ? homeTeamAbbreviation : awayTeamAbbreviation
          }), while the head represents
          the value in the game.
          The color shows which team did better compared to the season average.
          The difference between the game value and the season average is shown on hover.`}
      />
    </div>
  );
}
