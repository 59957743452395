import React, { useState, useMemo } from "react";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { TeamCharges } from "../../../shared/routers/TeamRouter";
import { decFormat2 } from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { PlayerTableCell } from "../core/TableCell";

const columnHelper = createColumnHelper<TeamCharges>();

export function TeamChargesTable(props: { charges?: TeamCharges[] }) {
  const { charges } = props;
  const [sorting, setSorting] = useState<SortingState>();

  const columns = useMemo(() => {
    if (!charges) return [];
    return [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.playerId}
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.accessor("numCharges", {
        header: () => "# Charges",
        cell: (info) => info.getValue(),
        meta: {
          group: 1,
          colorDomain: [
            Math.min(...charges.map((c) => c.numCharges)),
            Math.max(...charges.map((c) => c.numCharges)),
          ],
          highlights: Highlights.Max,
          neutralColorScheme: true,
        },
      }),
      columnHelper.accessor("chargesPer36", {
        header: () => "Per 36",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: 2,
          colorDomain: [
            Math.min(...charges.map((c) => c.chargesPer36)),
            Math.max(...charges.map((c) => c.chargesPer36)),
          ],
          highlights: Highlights.Max,
          neutralColorScheme: true,
        },
      }),
    ];
  }, [charges]);

  if (!charges) return null;

  return (
    <Table
      data={charges}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      autoWidth={true}
      showColorOnHover={true}
    />
  );
}
