import React, { useState } from "react";

import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { DxInserts } from "../components/mapping/DxInserts";
import { DxMaybes } from "../components/mapping/DxMaybes";
import { SynergyInserts } from "../components/mapping/SynergyInserts";
import { SynergyMaybes } from "../components/mapping/SynergyMaybes";
import { UnmappedRealGms } from "../components/mapping/UnmappedRealGms";
import { trpc } from "../util/tRPC";

export function MappingPage() {
  const [activeTab, setActiveTab] = useState("dxInserts");

  const { data: dxInserts } = trpc.mapping.getDxInserts.useQuery();
  const { data: dxMaybes } = trpc.mapping.getDxMaybes.useQuery();
  const { data: synergyInserts } = trpc.mapping.getSynergyInserts.useQuery();
  const { data: synergyMaybes } = trpc.mapping.getSynergyMaybes.useQuery();
  const { data: unmappedRealGms } =
    trpc.mapping.getUnMappedRealGmPlayers.useQuery();

  const insertsCount = dxInserts
    ? ` (${dxInserts.length.toLocaleString()})`
    : "";
  const maybesCount = dxMaybes ? ` (${dxMaybes.length.toLocaleString()})` : "";
  const synergyInsertsCount = synergyInserts
    ? ` (${synergyInserts.length.toLocaleString()})`
    : "";
  const synergyMaybesCount = synergyMaybes
    ? ` (${synergyMaybes.length.toLocaleString()})`
    : "";
  const unmappedRealGmCount = unmappedRealGms
    ? ` (${unmappedRealGms.length.toLocaleString()})`
    : "";

  const tabs = {
    dxInserts: {
      label: `DX Inserts ${insertsCount}`,
      content: <DxInserts inserts={dxInserts} />,
    },
    dxMaybes: {
      label: `DX Maybes ${maybesCount}`,
      content: <DxMaybes maybes={dxMaybes} />,
    },
    synergyInserts: {
      label: `Synergy Inserts ${synergyInsertsCount}`,
      content: <SynergyInserts inserts={synergyInserts} />,
    },
    synergyMaybes: {
      label: `Synergy Maybes ${synergyMaybesCount}`,
      content: <SynergyMaybes maybes={synergyMaybes} />,
    },
    unMappedRealGm: {
      label: `Unmapped RealGM ${unmappedRealGmCount}`,
      content: <UnmappedRealGms unmapped={unmappedRealGms} />,
    },
  };

  const mappingTabs = {
    onClick: (tabKey: string) => setActiveTab(tabKey),
    active: activeTab,
    tabs,
  };

  return (
    <Page title="Mapping" header={{ text: "Mapping" }}>
      <Panel tabs={mappingTabs}></Panel>
    </Page>
  );
}
