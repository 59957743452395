import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import {
  ArrayParam,
  encodeQueryParams,
  JsonParam,
  NumberParam,
} from "use-query-params";
import { stringify } from "query-string";

import { simpleShotTypeLabelMap } from "../../constants/AppConstants";

export function ShotModelLink(props: {
  children?: ReactNode;
  shotType: "all" | "three" | "layup" | "nl2";
  offTeams: string[];
  groupBy: string[];
  gameDate?: string[];
}) {
  const { children, offTeams, shotType, groupBy, gameDate } = props;

  let isThree = undefined;
  if (shotType === "three") {
    isThree = true;
  } else if (shotType === "nl2") {
    isThree = false;
  }

  let generalShotTypes: string[] | undefined = undefined;
  if (shotType === "layup") {
    generalShotTypes = ["layup"];
  } else if (shotType === "nl2") {
    generalShotTypes = Object.keys(simpleShotTypeLabelMap).filter(
      (k) => k !== "layup"
    );
  }

  const params = encodeQueryParams(
    { filters: JsonParam, groupBy: ArrayParam, shotThreshold: NumberParam },
    {
      filters: {
        offTeamIds: offTeams,
        isThree,
        generalShotTypes,
        fromDate: gameDate ? gameDate[0] : undefined,
        toDate: gameDate ? gameDate[1] : undefined,
      },
      groupBy,
      shotThreshold: 0,
    }
  );
  return (
    <Link to={`/shots?${stringify(params)}`} target="blank">
      {children}
    </Link>
  );
}
