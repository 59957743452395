import React, { useMemo, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";

import { Modal } from "../core/Modal";
import { Spinner } from "../core/Spinner";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import { DxInsert, MappingInsert } from "../../../shared/routers/MappingRouter";
import { trpc } from "../../util/tRPC";

export function DxInserts(props: { inserts?: DxInsert[] }) {
  const { inserts } = props;
  const [checkedRows, setCheckedRows] = useState<Set<number>>(new Set());
  const [sorting, setSorting] = useState<SortingState>();
  const [errMsg, setErrMsg] = useState<string>();
  const [showInsertModal, setShowInsertModal] = useState(false);

  const utils = trpc.useContext();

  const insertMutation = trpc.mapping.postDxInsert.useMutation({
    onSuccess: () => {
      setCheckedRows(new Set());
      setErrMsg("");
      utils.mapping.getDxInserts.invalidate();
      utils.mapping.getDxInserts.refetch();
    },
    onError: () => {
      setErrMsg("Unable to insert player into mapping table");
    },
  });

  const pending = insertMutation.isLoading;

  const columnHelper = createColumnHelper<DxInsert>();

  const handleCheck = (dxId: number) => {
    if (checkedRows.has(dxId)) {
      checkedRows.delete(dxId);
    } else {
      checkedRows.add(dxId);
    }
    setCheckedRows(new Set(checkedRows));
  };

  const handleCheckAll = (checkAll: boolean) => {
    if (!inserts) return;
    if (checkAll) {
      setCheckedRows(new Set(inserts.map((i) => i.dxId)));
    } else {
      setCheckedRows(new Set());
    }
  };

  const handleInserts = () => {
    if (!inserts) return;

    const playersToInsert: MappingInsert[] = inserts
      .filter((i) => checkedRows.has(i.dxId))
      .map((i) => {
        return {
          First: i.FirstName
            ? i.FirstName.replace("Jr.", "").replace("III", "")
            : null,
          Last: i.LastName,
          Ht: i.Inches,
          Wt: i.Weight,
          College: i.College,
          Hometown: i.HomeTown,
          State: i.HomeState,
          HSJC: i.HighSchool,
          DOB: i.BirthDate,
          Country: i.Nationality,
          DE: i.dxId.toString(),
          synergy: null,
        };
      });

    if (playersToInsert.length === 0) return;

    insertMutation.mutate({ players: playersToInsert, maybes: false });
  };

  const insertModal = (
    <Modal
      title={"Confirm DX Insert"}
      content={
        "Pressing confirm will create new player mapping entries for the selected player(s)."
      }
      show={showInsertModal}
      handleClose={() => setShowInsertModal(false)}
      handleConfirm={() => {
        setShowInsertModal(false);
        handleInserts();
      }}
    />
  );

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "check",
        header: () =>
          inserts &&
          inserts.length > 0 && (
            <Form.Check
              checked={checkedRows.size === inserts.length}
              onChange={() =>
                handleCheckAll(checkedRows.size !== inserts.length)
              }
            />
          ),
        cell: (info) => (
          <Form.Check
            checked={checkedRows.has(info.row.original.dxId)}
            onChange={() => handleCheck(info.row.original.dxId)}
          />
        ),
      }),
      columnHelper.accessor("dxId", {
        header: () => "DX Player",
        cell: (info) => (
          <a
            href={`http://www.draftexpress.com/blue/player.php?player=${info.getValue()}`}
          >
            {info.row.original.FirstName} {info.row.original.LastName}
          </a>
        ),
        meta: { group: 1 },
      }),
      columnHelper.accessor("BirthDate", {
        header: () => "BirthDate",
        meta: { group: 2 },
      }),
      columnHelper.accessor("Height", {
        header: () => "Height",
        meta: { group: 3 },
      }),
      columnHelper.accessor("Weight", {
        header: () => "Weight",
        meta: { group: 4 },
      }),
      columnHelper.accessor("Position", {
        header: () => "Position",
        meta: { group: 5 },
      }),
      columnHelper.accessor("HighSchool", {
        header: () => "HighSchool",
        meta: { group: 6 },
      }),
      columnHelper.accessor("CollegeTeamId", {
        header: () => "CollegeTeamId",
        meta: { group: 7 },
      }),
      columnHelper.accessor("College", {
        header: () => "College",
        meta: { group: 8 },
      }),
      columnHelper.accessor("HomeTown", {
        header: () => "HomeTown",
        meta: { group: 9 },
      }),
      columnHelper.accessor("HomeState", {
        header: () => "HomeState",
        meta: { group: 10 },
      }),
      columnHelper.accessor("Nationality", {
        header: () => "Nationality",
        meta: { group: 11 },
      }),
      columnHelper.accessor("Inches", {
        header: () => "Inches",
        meta: { group: 12 },
      }),
    ],
    [checkedRows, inserts]
  );

  if (!inserts || pending) return <Spinner />;

  return (
    <div>
      {errMsg && (
        <Alert variant="danger">
          {`Something went wrong: ${errMsg}! Please contact chrisbu@celtics.com`}
        </Alert>
      )}
      <Button
        onClick={() => setShowInsertModal(true)}
        disabled={checkedRows.size == 0}
      >
        Insert Checked Rows
      </Button>
      {insertModal}
      <Table
        data={inserts}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        virtualScroll={true}
        autoWidth={true}
        showRowIndex={false}
      />
    </div>
  );
}
