import React, { ReactNode, useMemo, useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { FaVideo } from "react-icons/fa";
import { IoMdAdd, IoMdRemove } from "react-icons/io";

import {
  Table,
  DoubleFooter,
  SortingState,
  createColumnHelper,
} from "../core/Table";
import { complexShotTypeMap, Highlights } from "../../constants/AppConstants";
import { PlayerShootingBoxPlot } from "./PlayerShootingBoxPlot";
import { PlayerShooting } from "../../../shared/routers/PlayerRouter";
import {
  decFormat,
  decFormat2,
  makePlusMinus,
  dec100Format,
  seasonString,
  makePercent,
} from "../../util/Format";
import AppContext from "../../../shared/AppContext";
import { sumFromField, weightedAverage } from "../../util/Util";
import { trpc } from "../../util/tRPC";

const columnHelper = createColumnHelper<PlayerShooting>();

export function PlayerShootingTable(props: { playerId: number }) {
  const [hoveredCell, setHoveredCell] = useState<{
    row: number | undefined;
    col: number | undefined;
    value: unknown;
  }>({ row: undefined, col: undefined, value: undefined });
  const [showAll, setShowAll] = useState(false);
  const [sorting, setSorting] = useState<SortingState>();

  const { playerId } = props;

  const { data: playerShootingSummary } =
    trpc.player.getPlayerShootingSummary.useQuery({
      playerId,
    });

  const { data: shootingData } = trpc.player.getPlayerShooting.useQuery({
    playerId,
  });

  const { data: threeShootingData } = trpc.player.getPlayer3PtShooting.useQuery(
    {
      playerId,
    }
  );

  const threeShooting = threeShootingData && threeShootingData[0];
  const shootingDataOffense =
    playerShootingSummary &&
    playerShootingSummary.find((s) => s.type === "overall");
  const shootingDataDefense =
    playerShootingSummary &&
    playerShootingSummary.find((s) => s.type === "defense");

  const toggleButton = useMemo(
    () => (
      <Button
        style={{
          lineHeight: 0,
          padding: 3,
          marginRight: 5,
          fontSize: ".7em",
          verticalAlign: "top",
        }}
        onClick={(e) => {
          setShowAll(!showAll);
          e.stopPropagation();
        }}
      >
        {showAll ? <IoMdRemove /> : <IoMdAdd />}
      </Button>
    ),
    [showAll]
  );

  const columns = useMemo(() => {
    if (shootingDataOffense === undefined || shootingDataDefense === undefined)
      return [];

    return [
      columnHelper.accessor("complexShotType", {
        header: () => <span>{toggleButton}Shot Type</span>,
        cell: (info) => {
          const val = info.getValue();
          if (val === "floater") {
            if (info.row.original.type === "cutting") {
              const cst = complexShotTypeMap["cutFloater"];
              return cst ? cst.label : "Unknown";
            } else if (info.row.original.type === "driving") {
              const cst = complexShotTypeMap["drivingFloater"];
              return cst ? cst.label : "Unknown";
            } else {
              return "Floater";
            }
          } else {
            const cst = complexShotTypeMap[val];
            return cst ? cst.label : val;
          }
        },
        meta: { group: 0, textAlign: "left" },
        footer: () => (
          <DoubleFooter
            primary={"Overall"}
            secondary={"Defense"}
            textAlign="left"
          />
        ),
      }),
      columnHelper.accessor("type", {
        header: () => "Subset",
        cell: (info) => {
          const d = info.row.original;
          if (d.type === "overall") {
            return "Overall";
          } else if (d.type === "2") {
            return "2pt";
          } else if (d.type === "3") {
            return "3pt";
          } else if (d.complexShotType === "post") {
            if (d.type === "left") {
              return "Left Shoulder";
            } else if (d.type === "right") {
              return "Right Shoulder";
            }
          } else if (d.complexShotType === "catchAndShootOnMove") {
            if (d.type === "left") {
              return "Moving Left";
            } else if (d.type === "right") {
              return "Moving Right";
            }
          } else if (d.type === "left") {
            return "Left";
          } else if (d.type === "right") {
            return "Right";
          }
          return d.type;
        },
        meta: { group: 0, textAlign: "left" },
        footer: () => (
          <DoubleFooter
            primary={"Overall"}
            secondary={"Defense"}
            textAlign="left"
          />
        ),
      }),
      columnHelper.display({
        id: "video",
        header: () => "",
        cell: (info) => (
          <a
            href={videoUrl(info.row.original, showAll)}
            target="_biaSecondSpectrum"
          >
            <FaVideo />
          </a>
        ),
        meta: { group: 0 },
        footer: () => <DoubleFooter primary={<br />} secondary={<br />} />,
      }),
      columnHelper.accessor("shots", {
        header: () => "# Shots",
        meta: {
          group: 1,
          colorDomain: [0, 150],
          highlights: Highlights.Max,
          neutralColorScheme: true,
        },
        footer: () => (
          <DoubleFooter
            primary={shootingDataOffense.shots}
            secondary={shootingDataDefense.shots}
          />
        ),
      }),
      columnHelper.group({
        meta: { group: 2 },
        id: "perGame",
        header: () => <span>Shots Per Game</span>,
        columns: [
          columnHelper.accessor("perGame", {
            header: () => "Overall",
            cell: (info) => decFormat(info.getValue()),
            meta: {
              group: 2,
              colorDomain: [0.018, 2.13],
              highlights: Highlights.Max,
              neutralColorScheme: true,
            },
            footer: () => (
              <DoubleFooter
                primary={
                  shootingData
                    ? decFormat(
                        sumFromField(
                          "perGame",
                          shootingData.filter((s) => s.type === "overall")
                        )
                      )
                    : ""
                }
                secondary={<br />}
              />
            ),
          }),
          columnHelper.accessor("perGameCurrent", {
            header: () => seasonString(AppContext.currentSeason),
            cell: (info) => decFormat(info.getValue()),
            meta: {
              group: 2,
              colorDomain: [0.018, 2.13],
              highlights: Highlights.Max,
              neutralColorScheme: true,
            },
            footer: () => (
              <DoubleFooter
                primary={
                  shootingData
                    ? decFormat(
                        sumFromField(
                          "perGameCurrent",
                          shootingData.filter((s) => s.type === "overall")
                        )
                      )
                    : ""
                }
                secondary={<br />}
              />
            ),
          }),
        ],
      }),
      columnHelper.group({
        meta: { group: 3 },
        id: "ability",
        header: () => <span>Player Ability</span>,
        columns: [
          columnHelper.accessor("EFGAbility", {
            header: () => "EFG%",
            cell: (info) => makePlusMinus(dec100Format)(info.getValue()),
            meta: {
              group: 3,
              colorDomain: [-0.1, 0.1],
              highlights: Highlights.Max,
            },
            footer: () => (
              <DoubleFooter
                primary={
                  shootingDataOffense
                    ? makePlusMinus(dec100Format)(
                        shootingDataOffense.EFGAbility
                      )
                    : ""
                }
                secondary={
                  shootingDataDefense
                    ? makePlusMinus(dec100Format)(
                        shootingDataDefense.EFGAbility
                      )
                    : ""
                }
              />
            ),
          }),
          columnHelper.accessor("FTRAbility", {
            header: () => "FTR",
            cell: (info) => makePlusMinus(dec100Format)(info.getValue()),
            meta: {
              group: 3,
              colorDomain: [-0.1, 0.1],
              highlights: Highlights.Max,
            },
            footer: () => (
              <DoubleFooter
                primary={
                  shootingDataOffense
                    ? makePlusMinus(dec100Format)(
                        shootingDataOffense.FTRAbility
                      )
                    : ""
                }
                secondary={
                  shootingDataDefense
                    ? makePlusMinus(dec100Format)(
                        shootingDataDefense.FTRAbility
                      )
                    : ""
                }
              />
            ),
          }),
          columnHelper.accessor("PPSAbility", {
            header: () => "∆",
            cell: (info) => makePlusMinus(decFormat2)(info.getValue()),
            meta: {
              group: 3,
              colorDomain: [-0.1, 0.1],
              highlights: Highlights.Max,
              heatmap: true,
            },
            footer: () => (
              <DoubleFooter
                primary={
                  shootingDataOffense
                    ? makePlusMinus(decFormat2)(shootingDataOffense.PPSAbility)
                    : ""
                }
                secondary={
                  shootingDataDefense
                    ? makePlusMinus(decFormat2)(shootingDataDefense.PPSAbility)
                    : ""
                }
              />
            ),
          }),
        ],
      }),
      columnHelper.accessor("ePPSPlayer", {
        header: () => "xPPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: 4, colorDomain: [0.7, 1.3], highlights: Highlights.Max },
        footer: () => (
          <DoubleFooter
            primary={
              shootingDataOffense
                ? decFormat2(shootingDataOffense.ePPSPlayer)
                : ""
            }
            secondary={
              shootingDataDefense
                ? decFormat2(shootingDataDefense.ePPSPlayer)
                : ""
            }
          />
        ),
      }),
      columnHelper.accessor(
        (d) => {
          return {
            player: d.ePPSPlayer,
            league: d.ePPSLeague,
            pct05: d.ePPSPlayer05pct,
            pct25: d.ePPSPlayer25pct,
            pct50: d.ePPSPlayer50pct,
            pct75: d.ePPSPlayer75pct,
            pct95: d.ePPSPlayer95pct,
            type: d.complexShotType,
          };
        },
        {
          id: "chart",
          header: () => {
            let content: ReactNode = "xPPS vs. League";
            const lastIdx = showAll ? 10 : 9;
            if (hoveredCell.col && hoveredCell.col === lastIdx) {
              const value = hoveredCell.value as {
                pct05: number;
                pct25: number;
                pct50: number;
                pct75: number;
                pct95: number;
              };
              content = (
                <div
                  style={{
                    fontWeight: "normal",
                    display: "flex",
                    gap: "8px",
                    justifyContent: "center",
                  }}
                >
                  <span>
                    <b>5%:</b> {decFormat2(value.pct05)}
                  </span>
                  <span>
                    <b>25%:</b> {decFormat2(value.pct25)}
                  </span>
                  <span>
                    <b>50%:</b> {decFormat2(value.pct50)}
                  </span>
                  <span>
                    <b>75%:</b> {decFormat2(value.pct75)}
                  </span>
                  <span>
                    <b>95%:</b> {decFormat2(value.pct95)}
                  </span>
                </div>
              );
            }
            return (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Popover style={{ maxWidth: "none" }}>
                    <img
                      style={{ width: 550 }}
                      src="/assets/img/shooting_details_pps_boxplot_help.png"
                    />
                  </Popover>
                }
              >
                <div style={{ textAlign: "center" }}>{content}</div>
              </OverlayTrigger>
            );
          },
          cell: (info) => {
            const hovered = info.row.index === hoveredCell.row;

            return (
              <PlayerShootingBoxPlot
                data={info.getValue()}
                domain={[0.5, 1.94]}
                height={14}
                width={400}
                name={info.getValue().type}
                isHovered={hovered}
              />
            );
          },
          meta: { group: 4 },
          footer: () => <DoubleFooter primary={<br />} secondary={<br />} />,
        }
      ),
    ];
  }, [
    shootingDataOffense,
    shootingDataDefense,
    toggleButton,
    showAll,
    shootingData,
    hoveredCell.col,
    hoveredCell.value,
    hoveredCell.row,
  ]);

  const hiddenColumns = { type: showAll };

  if (!shootingData || !shootingDataOffense || !shootingDataDefense)
    return null;

  const processSelected = (data: PlayerShooting[]) => {
    return [
      "Selected",
      "",
      "",
      sumFromField("shots", data),
      decFormat(weightedAverage("shots", "perGame", data)),
      decFormat(weightedAverage("shots", "perGameCurrent", data)),
      makePlusMinus(dec100Format)(weightedAverage("shots", "EFGAbility", data)),
      makePlusMinus(dec100Format)(weightedAverage("shots", "FTRAbility", data)),
      makePlusMinus(decFormat2)(weightedAverage("shots", "PPSAbility", data)),
      decFormat2(weightedAverage("shots", "ePPSPlayer", data)),
      "",
    ];
  };

  return (
    <div>
      <Table
        key={`showall-${showAll}`}
        data={shootingData
          .filter((d) => showAll || d.type === "overall")
          .sort((a, b) => b.shots - a.shots)}
        columns={columns}
        autoWidth={true}
        hiddenColumns={hiddenColumns}
        showColorOnHover={true}
        sorting={sorting}
        setSorting={setSorting}
        processSelected={processSelected}
        onHoveredCellChange={(row, col, value) =>
          // TODO(chrisbu): Figure out how to remove this settimeout without
          // triggering the react console error.
          setTimeout(() => setHoveredCell({ row, col, value }), 0)
        }
      />
      <div style={{ color: "#888" }}>
        {`Includes data from 2013-14 to ${seasonString(
          AppContext.currentSeason
        )}`}
      </div>
      {threeShooting && (
        <p style={{ paddingTop: 8 }}>
          {`Player's 3% on league average distribution of threes: `}
          {makePercent(decFormat)(threeShooting.avg_shots_3Pp)} (average player:{" "}
          {makePercent(decFormat)(threeShooting.avg_shots_avg_shooter_3Pp)}){" "}
          <br />
          {`League average 3% on player's distribution of threes: `}
          {makePercent(decFormat)(threeShooting.shot_difficulty)} (player makes:{" "}
          {makePercent(decFormat)(threeShooting.career_3Pp)})
        </p>
      )}
    </div>
  );
}

function videoUrl(data: PlayerShooting, showAllTypes: boolean) {
  const complexShotType = data.complexShotType;
  const type = data.type;
  const playerEagleId = data.ssId;

  const filters = {
    shooter: [String(playerEagleId).toLowerCase()],
    complexShotType: [] as string[],
  };

  if (complexShotType === "post" || complexShotType === "catchAndShootOnMove") {
    if ((showAllTypes && type !== "right") || !showAllTypes) {
      filters.complexShotType.push(complexShotType + "Left");
    }

    if ((showAllTypes && type !== "left") || !showAllTypes) {
      filters.complexShotType.push(complexShotType + "Right");
    }
  } else {
    filters.complexShotType.push(complexShotType);
  }

  const url = `https://www.secondspectrum.com/nba/insight/video?category=shot&filters=[{"table":"shot","name":"complex_shot_type","args":${JSON.stringify(
    filters.complexShotType
  )}},{"table":"shot","name":"shooter","args":${JSON.stringify(
    filters.shooter
  )}}]`;

  return url;
}
