import React, { useState, useMemo } from "react";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { Spinner } from "../core/Spinner";
import { TeamRunningTheFloor } from "../../../shared/routers/TeamRouter";
import { dec100Format, decFormat, pctFormat } from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { PlayerTableCell } from "../core/TableCell";

const columnHelper = createColumnHelper<TeamRunningTheFloor>();

export function TeamRunningTheFloorTable(props: {
  runs?: TeamRunningTheFloor[];
}) {
  const [sorting, setSorting] = useState<SortingState>();
  const { runs } = props;

  const columns = useMemo(() => {
    if (!runs) return [];

    const numRuns = runs.map((r) => r.runs).sort();
    const pct95 = runs.map((r) => (r.runs ? r.speedgt95 / r.runs : 0)).sort();
    const pct95To75 = runs
      .map((r) => (r.runs ? r.speed75_95 / r.runs : 0))
      .sort();
    const pct75to7 = runs
      .map((r) => (r.runs ? r.speed7mph_75 / r.runs : 0))
      .sort();
    const pct7 = runs.map((r) => (r.runs ? r.speedlt7mph / r.runs : 0)).sort();

    return [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            name={info.getValue()}
            id={info.row.original.playerId}
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.accessor("maxSpeed", {
        header: () => "Max Speed",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: 1,
          highlights: Highlights.Max,
          colorDomain: [11.29, 13.51],
        },
      }),
      columnHelper.accessor("runs", {
        header: () => "Runs",
        meta: {
          group: 2,
          highlights: Highlights.Max,
          colorDomain: [numRuns[numRuns.length - 1] || 0, numRuns[0] || 0],
          neutralColorScheme: true,
        },
      }),
      columnHelper.group({
        meta: { group: 3 },
        id: "runsByPercOfPlayerMax",
        header: () => "Runs by % of Player Max",
        columns: [
          columnHelper.accessor(
            (row) => (row.runs ? row.speedgt95 / row.runs : 0),
            {
              id: "speed95_pct",
              header: () => "95% + ",
              cell: (info) => pctFormat(info.getValue()),
              meta: {
                group: 3,
                highlights: Highlights.Max,
                colorDomain: [pct95[0] || 0, pct95[pct95.length - 1] || 0],
                neutralColorScheme: true,
              },
            }
          ),
          columnHelper.accessor(
            (row) => (row.runs ? row.speed75_95 / row.runs : 0),
            {
              id: "speed75_95_pct",
              header: () => "95% to 75%",
              cell: (info) => pctFormat(info.getValue()),
              meta: {
                group: 3,
                highlights: Highlights.Max,
                colorDomain: [
                  pct95To75[0] || 0,
                  pct95To75[pct95To75.length - 1] || 0,
                ],
                neutralColorScheme: true,
              },
            }
          ),
          columnHelper.accessor(
            (row) => (row.runs ? row.speed7mph_75 / row.runs : 0),
            {
              id: "speed7mph_75_pct",
              header: () => "75% to 5mph",
              cell: (info) => pctFormat(info.getValue()),
              meta: {
                group: 3,
                highlights: Highlights.Max,
                colorDomain: [
                  pct75to7[0] || 0,
                  pct75to7[pct75to7.length - 1] || 0,
                ],
                neutralColorScheme: true,
              },
            }
          ),
          columnHelper.accessor(
            (row) => (row.runs ? row.speedlt7mph / row.runs : 0),
            {
              id: "speed7mph_pct",
              header: () => "7mph or less",
              cell: (info) => pctFormat(info.getValue()),
              meta: {
                group: 3,
                highlights: Highlights.Max,
                colorDomain: [pct7[0] || 0, pct7[pct7.length - 1] || 0],
                neutralColorScheme: true,
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        meta: { group: 4 },
        id: "bars",
        columns: [
          columnHelper.accessor(
            (row) => (row.runs ? row.speedgt95 / row.runs : 0),
            {
              id: "speedgt95",
              header: () => "95%+",
              cell: (info) => <DivBar value={info.getValue()} />,
              meta: {
                group: 4,
              },
            }
          ),
          columnHelper.accessor(
            (row) => (row.runs ? row.speed75_95 / row.runs : 0),
            {
              id: "speed75_95",
              header: () => "95% to 75%",
              cell: (info) => <DivBar value={info.getValue()} />,
              meta: {
                group: 4,
              },
            }
          ),
          columnHelper.accessor(
            (row) => (row.runs ? row.speed7mph_75 / row.runs : 0),
            {
              id: "speed7mph_75",
              header: () => "75% to 7mph",
              cell: (info) => <DivBar value={info.getValue()} />,
              meta: {
                group: 4,
              },
            }
          ),
          columnHelper.accessor(
            (row) => (row.runs ? row.speedlt7mph / row.runs : 0),
            {
              id: "speedlt7mph",
              header: () => "7mph or less",
              cell: (info) => <DivBar value={info.getValue()} />,
              meta: {
                group: 4,
              },
            }
          ),
        ],
      }),
    ];
  }, [runs]);

  if (!runs) return <Spinner />;

  return (
    <Table
      data={runs}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      showColorOnHover={true}
      autoWidth={true}
    />
  );
}

function DivBar(props: { value: number }) {
  const { value } = props;
  const valueAsPct = `${dec100Format(value)}%`;
  return (
    <div
      title={valueAsPct}
      style={{
        width: "88px",
        display: "inline-block",
        background: "rgba(0,0,0,.06)",
        marginRight: 5,
      }}
    >
      <div
        style={{
          height: 14,
          backgroundColor: "#3B8CD8",
          width: valueAsPct,
        }}
      ></div>
    </div>
  );
}
