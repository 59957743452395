import React, { useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ParentSize } from "@visx/responsive";
import { scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import { Circle } from "@visx/shape";

import { Table, createColumnHelper } from "../components/core/Table";
import { Panel } from "../components/core/Panel";
import { Page } from "../components/core/Page";
import { VideoPlayer } from "../components/video/VideoPlayer";
import { trpc } from "../util/tRPC";
import { Court } from "../components/court/Court";
import {
  isJumper,
  pBehindBackboard,
  simplifyComplexShotType,
} from "../util/ShotModel";
import { ShotDetails } from "../../shared/routers/ShotRouter";
import {
  ShotModelCoeffecient,
  ShotModelPlayerIntercept,
} from "../../shared/routers/LiveGameRouter";
import { complexShotTypeMap } from "../constants/AppConstants";
import { sum } from "../util/Util";
import { decFormat2, pctFormat } from "../util/Format";

export function ShotDetailsPage() {
  const { id } = useParams();

  const { data } = trpc.shot.getShotDetails.useQuery({
    shotEagleId: id,
  });

  const { data: shotModelCoeffs } =
    trpc.liveGame.getShotModelCoeffecients.useQuery();

  // Fetch the lg median intercepts in case we need them.
  const { data: lgMedianShooterIntercepts } =
    trpc.liveGame.getShotModelPlayerIntercepts.useQuery({
      playerIds: [],
    });

  const shotDetails = data && data[0];

  const clips: { url: string; label: string }[] =
    shotDetails && shotDetails.SynergyURL !== null
      ? [{ url: shotDetails.SynergyURL, label: "Shot" }]
      : [];

  return (
    <Page header={{ text: "Shot Details" }} title={"Shot Details"}>
      {data && data.length === 0 ? (
        <div>{`No data found for shot ${id}.`}</div>
      ) : (
        <Row>
          <Col md={6}>
            <Panel header={"Court"}>
              {shotDetails && (
                <ParentSize>
                  {({ width }) => (
                    <PlayerPositions
                      width={Math.min(width, 700)}
                      shot={shotDetails}
                    />
                  )}
                </ParentSize>
              )}
            </Panel>
          </Col>
          <Col md={6}>
            <Panel header={"Video"}>
              <VideoPlayer
                clips={clips}
                upDownClipSkip={false}
                showSynergyEditor={false}
                hideClipButtons={true}
              />
            </Panel>
          </Col>
          <Col md={12}>
            <Panel header={"Details"}>
              {shotDetails && shotModelCoeffs && (
                <ShotBreakdown
                  shot={shotDetails}
                  shotModelCoeffs={shotModelCoeffs}
                  lgMedianShooterIntercepts={lgMedianShooterIntercepts || []}
                />
              )}
            </Panel>
          </Col>
        </Row>
      )}
    </Page>
  );
}

function PlayerPositions(props: { width: number; shot: ShotDetails }) {
  const { width, shot } = props;
  // Distance from basket.
  const maxDistance = 35;
  // Flip x and y to get the rim near the top.
  const xDomain = [-25, 25];
  // 5.25 is distance from middle of basket to baseline.
  const yDomain = [-47.25 + maxDistance + 5.25, -47.25];
  const margin = { top: 15, right: 0, bottom: 10, left: 0 };

  const height =
    width * ((maxDistance * 10 + 52.5) / 500) + margin.top + margin.bottom;

  const glyphSize = width / 20;
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const xMin = 0,
    xMax = innerWidth,
    yMin = innerHeight,
    yMax = 0;

  const xScale = scaleLinear().domain(xDomain).range([xMin, xMax]).clamp(true);
  const yScale = scaleLinear().domain(yDomain).range([yMin, yMax]).clamp(true);

  const players = [
    {
      offense: true,
      shotLocX: shot.off1LocX,
      shotLocY: shot.off1LocY,
      playerId: shot.off1PlayerId,
    },
    {
      offense: true,
      shotLocX: shot.off2LocX,
      shotLocY: shot.off2LocY,
      playerId: shot.off2PlayerId,
    },
    {
      offense: true,
      shotLocX: shot.off3LocX,
      shotLocY: shot.off3LocY,
      playerId: shot.off3PlayerId,
    },
    {
      offense: true,
      shotLocX: shot.off4LocX,
      shotLocY: shot.off4LocY,
      playerId: shot.off4PlayerId,
    },
    {
      offense: true,
      shotLocX: shot.off5LocX,
      shotLocY: shot.off5LocY,
      playerId: shot.off5PlayerId,
    },
    {
      offense: false,
      shotLocX: shot.def1LocX,
      shotLocY: shot.def1LocY,
      playerId: shot.def1PlayerId,
    },
    {
      offense: false,
      shotLocX: shot.def2LocX,
      shotLocY: shot.def2LocY,
      playerId: shot.def2PlayerId,
    },
    {
      offense: false,
      shotLocX: shot.def3LocX,
      shotLocY: shot.def3LocY,
      playerId: shot.def3PlayerId,
    },
    {
      offense: false,
      shotLocX: shot.def4LocX,
      shotLocY: shot.def4LocY,
      playerId: shot.def4PlayerId,
    },
    {
      offense: false,
      shotLocX: shot.def5LocX,
      shotLocY: shot.def5LocY,
      playerId: shot.def5PlayerId,
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: width,
          margin: "auto",
        }}
      >
        <Court
          width={width}
          maxDistance={35}
          hideBenchLines={true}
          absolute={true}
          hideBorder={true}
        />
        <svg width={width} height={height}>
          <Group left={margin.left} top={margin.top}>
            {players.map((player, i) => {
              if (player.shotLocX === null || player.shotLocY === null) {
                return null;
              }

              const x = xScale(player.shotLocY) as number;
              const y = yScale(player.shotLocX) as number;
              const color = player.offense ? "green" : "red";

              const url = `https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/${player.playerId}.png`;

              return (
                <Group key={i}>
                  <defs>
                    <clipPath id={`circleView${player.playerId}`}>
                      <circle
                        cx={x}
                        cy={y - glyphSize / 2 + 8}
                        r={glyphSize - 8}
                      />
                    </clipPath>
                  </defs>
                  <Circle
                    key={i}
                    cx={x}
                    cy={y}
                    r={glyphSize / 2}
                    fill={
                      player.playerId === shot.shooterIDS ? "orange" : color
                    }
                    fillOpacity={0.5}
                    stroke={"black"}
                  />
                  <image
                    fill="red"
                    fillOpacity={0.5}
                    href={url}
                    x={x - glyphSize}
                    y={y - glyphSize}
                    width={glyphSize * 2}
                    height={glyphSize * 2}
                    clipPath={`url(#circleView${player.playerId})`}
                  />
                </Group>
              );
            })}
          </Group>
        </svg>
      </div>
    </div>
  );
}

function ShotBreakdown(props: {
  shot: ShotDetails;
  shotModelCoeffs: ShotModelCoeffecient[];
  lgMedianShooterIntercepts: ShotModelPlayerIntercept[];
}) {
  const { shot, shotModelCoeffs, lgMedianShooterIntercepts } = props;
  return (
    <div>
      {isJumper(shot) ? (
        <ShotDetailsTable
          shot={shot}
          shotModelCoeffs={shotModelCoeffs.filter((sm) => sm.type === "shot")}
          lgMedianShooterIntercepts={lgMedianShooterIntercepts.filter(
            (si) => si.type === "shot"
          )}
        />
      ) : (
        <FinishDetailsTable
          shot={shot}
          shotModelCoeffs={shotModelCoeffs.filter((sm) => sm.type === "finish")}
          lgMedianShooterIntercepts={lgMedianShooterIntercepts.filter(
            (si) => si.type === "finish"
          )}
        />
      )}
    </div>
  );
}

interface ShotDetailsRow {
  factor: string;
  value: number | string | null;
  effectOnMake: number | null;
  effectOnFoul: number | null;
}

const shotDetailsColumnHelper = createColumnHelper<ShotDetailsRow>();

function ShotDetailsTable(props: {
  shot: ShotDetails;
  shotModelCoeffs: ShotModelCoeffecient[];
  lgMedianShooterIntercepts: ShotModelPlayerIntercept[];
}) {
  const { shot, shotModelCoeffs, lgMedianShooterIntercepts } = props;

  const getCoeff = useCallback(
    (variable: string, outcome: "make" | "foul") => {
      const coeff = shotModelCoeffs.find(
        (c) => c.variable === variable && c.outcome === outcome
      );
      return coeff ? coeff.coefficients : 0;
    },
    [shotModelCoeffs]
  );

  const locationX = shot.locationY * -1;
  const locationY = shot.locationX + 47 - 5.25;

  const behindBackboard = pBehindBackboard(locationX, locationY);

  const d1Proximity =
    1 - 1 / (1 + (Math.exp((-(shot.closestDefDist - 3) * 2) / 3) * 4) / 9);

  const totalDistanceToShooter =
    shot.def1DistanceToShooter +
    shot.def2DistanceToShooter +
    shot.def3DistanceToShooter +
    shot.def4DistanceToShooter +
    shot.def5DistanceToShooter;

  const towardBasketDegree =
    1 - 1 / (1 + Math.exp(-((Math.abs(shot.shooterVelAngle) - 45) / 9)));

  const lateClock =
    shot.shotClock === null || shot.shotClock > 6 ? 0 : 6 - shot.shotClock;

  const foulDrawn = shot.fouled ? 1 : 0;

  const isThree = shot.three ? 1 : 0;

  const isTransitionHeave =
    shot.complexShotType === "heave" && shot.isTransition ? 1 : 0;

  const zeroDefendersToRim = shot.n_defenders_to_rim === 0 ? 1 : 0;

  const extraDefenders = [
    shot.contesterId0,
    shot.contesterId1,
    shot.contesterId2,
    shot.contesterId3,
    shot.contesterId4,
  ].filter((id) => id !== "" && id !== null).length;

  const complexShotType = simplifyComplexShotType(shot.complexShotType);

  const cst = complexShotTypeMap[shot.complexShotType];

  const data = useMemo(
    () => [
      {
        factor: "Made",
        value: shot.outcome ? "Yes" : "No",
        effectOnMake: null,
        effectOnFoul: (shot.outcome ? 1 : 0) * getCoeff("outcome", "foul"),
      },
      {
        factor: "pnorm(season - 2016)",
        value: null,
        effectOnMake: getCoeff("pnorm(season - 2016)", "make"),
        effectOnFoul: getCoeff("pnorm(season - 2016)", "foul"),
      },
      {
        factor: "Made x pnorm(season - 2016)",
        value: null,
        effectOnMake: null,
        effectOnFoul:
          (shot.outcome ? 1 : 0) *
          getCoeff("outcome:pnorm(season - 2016)", "foul"),
      },
      {
        factor: "Cumulative Shot Type Count",
        value: shot.cumul_cst_count,
        effectOnMake:
          Math.log(shot.cumul_cst_count || 1) *
          getCoeff("log(cumul_cst_count)", "make"),
        effectOnFoul:
          Math.log(shot.cumul_cst_count || 1) *
          getCoeff("log(cumul_cst_count)", "foul"),
      },
      {
        factor: "Late Shot Clock",
        value: shot.shotClock,
        effectOnMake: lateClock * getCoeff("lateClock", "make"),
        effectOnFoul: lateClock * getCoeff("lateClock", "foul"),
      },
      {
        factor: "Closest Defender Distance",
        value: shot.closestDefDist,
        effectOnMake: d1Proximity * getCoeff("d1Proximity", "make"),
        effectOnFoul: d1Proximity * getCoeff("d1Proximity", "foul"),
      },
      {
        factor: "Late Shot Clock x Closest Defender Distance",
        value: null,
        effectOnMake:
          lateClock * d1Proximity * getCoeff("lateClock:d1Proximity", "make"),
        effectOnFoul: 0,
      },
      {
        factor: "Foul Drawn",
        value: shot.fouled ? "Yes" : "No",
        effectOnMake: foulDrawn * getCoeff("foulDrawn", "make"),
        effectOnFoul: 0,
      },
      {
        factor: "Shot Type",
        value: cst ? cst.label : "Unknown",
        effectOnMake: getCoeff(`complexShotType${complexShotType}`, "make"),
        effectOnFoul: getCoeff(`complexShotType${complexShotType}`, "foul"),
      },
      {
        factor: "Foul Drawn x Shot Type",
        value: null,
        effectOnMake:
          foulDrawn *
          getCoeff(`foulDrawn:complexShotType${complexShotType}`, "make"),
        effectOnFoul: 0,
      },
      {
        factor: "Probabaility Behind backboard",
        value: behindBackboard,
        effectOnMake: behindBackboard * getCoeff("behindBackboard", "make"),
        effectOnFoul: 0,
      },
      {
        factor: "Contest Level",
        value: shot.contestLevel,
        effectOnMake: getCoeff(
          `factor(contestLevel)${shot.contestLevel}`,
          "make"
        ),
        effectOnFoul: getCoeff(`contestLevel${shot.contestLevel}`, "foul"),
      },
      {
        factor: "Is Three",
        value: shot.three ? "Yes" : "No",
        effectOnMake: null,
        effectOnFoul: isThree * getCoeff("threeTRUE", "foul"),
      },
      {
        factor: "Is Transition Heave",
        value: isTransitionHeave === 1 ? "Yes" : "No",
        effectOnMake: null,
        effectOnFoul: isTransitionHeave * getCoeff("trans_heaveTRUE", "foul"),
      },
      {
        factor: "Is Transition",
        value: shot.isTransition ? "Yes" : "No",
        effectOnMake: null,
        effectOnFoul:
          (shot.isTransition ? 1 : 0) * getCoeff("isTransitionTRUE", "foul"),
      },
      {
        factor: "Is Transition x Made",
        value: null,
        effectOnMake: null,
        effectOnFoul:
          (shot.outcome && shot.isTransition ? 1 : 0) *
          getCoeff("outcome:isTransitionTRUE", "foul"),
      },
      {
        factor: "Shooter Velocity Toward Rim",
        value: shot.shooterVelTowardRim,
        effectOnMake: null,
        effectOnFoul:
          shot.shooterVelTowardRim * getCoeff("shooterVelTowardRim", "foul"),
      },
      {
        factor: "Shooter Velocity Toward Rim x Shot Type",
        value: null,
        effectOnMake: null,
        effectOnFoul:
          shot.shooterVelTowardRim *
          getCoeff(
            `complexShotType${complexShotType}:shooterVelTowardRim`,
            "foul"
          ),
      },
      {
        factor: "Closest Defender Distance x Shot Type",
        value: "",
        effectOnMake:
          d1Proximity *
          getCoeff(`d1Proximity:complexShotType${complexShotType}`, "make"),
        effectOnFoul:
          d1Proximity *
          getCoeff(`complexShotType${complexShotType}:d1Proximity`, "foul"),
      },
      {
        factor: "Total Defender Distance",
        value: totalDistanceToShooter,
        effectOnMake: shot.proximity_sum * getCoeff("proximity_sum", "make"),
        effectOnFoul: shot.proximity_sum * getCoeff("proximity_sum", "foul"),
      },
      {
        factor: "Total Defender Distance x Shot Type",
        value: totalDistanceToShooter,
        effectOnMake: null,
        effectOnFoul:
          shot.proximity_sum *
          getCoeff(`proximity_sum:complexShotType${complexShotType}`, "foul"),
      },
      {
        factor: "Contesting Defenders",
        value: extraDefenders,
        effectOnMake: null,
        effectOnFoul: getCoeff(
          `factor(extraDefenders)${extraDefenders}`,
          "foul"
        ),
      },
      {
        factor: "Zero Defenders To Rim",
        value: zeroDefendersToRim ? "Yes" : "No",
        effectOnMake: null,
        effectOnFoul:
          zeroDefendersToRim *
          getCoeff(`getCoeff("I(n_defenders_to_rim == 0)TRUE")`, "foul"),
      },
      {
        factor: "Shooter Speed",
        value: shot.shooterSpeed,
        effectOnMake: shot.shooterSpeed * getCoeff("shooterSpeed", "make"),
        effectOnFoul:
          (1 / (1 + Math.exp(-(shot.shooterSpeed - 3)) * 9)) *
          getCoeff("velocityTransform", "foul"),
      },
      {
        factor: "Angle To Basket",
        value: towardBasketDegree,
        effectOnMake:
          towardBasketDegree * getCoeff("towardBasketDegree", "make"),
        effectOnFoul: null,
      },
      {
        factor: "Shooter Speed x Angle To Basket",
        value: "",
        effectOnMake:
          shot.shooterSpeed *
          towardBasketDegree *
          getCoeff("shooterSpeed:towardBasketDegree", "make"),
        effectOnFoul: null,
      },
      {
        factor: "Distance",
        value: shot.distance,
        effectOnMake: shot.distance * getCoeff("Distance", "make"),
        effectOnFoul: null,
      },
      {
        factor: "Distance x Shot Type",
        value: "",
        effectOnMake:
          shot.distance *
          getCoeff(`complexShotType${complexShotType}:Distance`, "make"),
        effectOnFoul:
          Math.sqrt(shot.distance) *
          getCoeff(`complexShotType${complexShotType}:sqrt(Distance)`, "foul"),
      },
      {
        factor: "Defender Standing Reach",
        value: shot.defFoulShotSR,
        effectOnMake: null,
        effectOnFoul: (shot.defFoulShotSR || 0) * getCoeff("def_sr", "foul"),
      },
      {
        factor: "Defender Effect",
        value: shot.defender,
        effectOnMake: shot.defMakeShotCoeff,
        effectOnFoul: shot.defFoulShotCoeff,
      },
      {
        factor: "Shooter Effect",
        value: `${shot.shooter} ${cst ? cst.label : "Unknown"}`,
        effectOnMake:
          shot.makeShotCoeff ||
          lgMedianShooterIntercepts.find(
            (si) =>
              si.outcome === "make" &&
              si.complexShotType === shot.complexShotType
          )?.intercept ||
          0,
        effectOnFoul:
          shot.foulShotCoeff ||
          lgMedianShooterIntercepts.find(
            (si) =>
              si.outcome === "foul" &&
              si.complexShotType === shot.complexShotType
          )?.intercept ||
          0,
      },
      {
        factor: "Intercept",
        value: null,
        effectOnMake: -getCoeff("(Intercept)", "make") * 4,
        effectOnFoul: -getCoeff("(Intercept)", "foul") * 4,
      },
    ],
    [
      behindBackboard,
      complexShotType,
      cst,
      d1Proximity,
      extraDefenders,
      foulDrawn,
      getCoeff,
      isThree,
      isTransitionHeave,
      lateClock,
      lgMedianShooterIntercepts,
      shot.closestDefDist,
      shot.complexShotType,
      shot.contestLevel,
      shot.cumul_cst_count,
      shot.defFoulShotCoeff,
      shot.defFoulShotSR,
      shot.defMakeShotCoeff,
      shot.defender,
      shot.distance,
      shot.foulShotCoeff,
      shot.fouled,
      shot.isTransition,
      shot.makeShotCoeff,
      shot.outcome,
      shot.proximity_sum,
      shot.shooter,
      shot.shooterSpeed,
      shot.shooterVelTowardRim,
      shot.shotClock,
      shot.three,
      totalDistanceToShooter,
      towardBasketDegree,
      zeroDefendersToRim,
    ]
  );

  const usingLgAvgOff =
    shot.makeShotCoeff === null || shot.foulShotCoeff === null;
  const usingLgAvgDef =
    shot.defMakeShotCoeff === null || shot.defFoulShotCoeff === null;

  const columns = useMemo(() => {
    return [
      shotDetailsColumnHelper.accessor("factor", {
        header: "Factor",
        meta: { textAlign: "left" },
      }),
      shotDetailsColumnHelper.accessor("value", {
        header: "Value",
        meta: { textAlign: "left" },
      }),
      shotDetailsColumnHelper.accessor("effectOnMake", {
        header: "Effect on p(Make)",
        cell: (info) => {
          const val = info.getValue();
          const factor = info.row.original.factor;
          if (
            (factor === "Defender Effect" && usingLgAvgDef) ||
            (factor === "Shooter Effect" && usingLgAvgOff)
          ) {
            return `${val} *`;
          }
          return val;
        },
        footer: () => {
          const unscaled = sum("effectOnMake", data);
          const p = 1 / (1 + Math.exp(-unscaled));
          return `${unscaled} (${pctFormat(p)})`;
        },
      }),
      shotDetailsColumnHelper.accessor("effectOnFoul", {
        header: "Effect on p(Foul)",
        cell: (info) => {
          const val = info.getValue();
          const factor = info.row.original.factor;
          if (
            (factor === "Defender Effect" && usingLgAvgDef) ||
            (factor === "Shooter Effect" && usingLgAvgOff)
          ) {
            return `${val} *`;
          }
          return val;
        },
        footer: () => {
          const unscaled = sum("effectOnFoul", data);
          const p = 1 / (1 + Math.exp(-unscaled));
          return `${unscaled} (${pctFormat(p)})`;
        },
      }),
    ];
  }, [data, usingLgAvgOff, usingLgAvgDef]);

  const probMake = 1 / (1 + Math.exp(-sum("effectOnMake", data)));
  const probFoul = 1 / (1 + Math.exp(-sum("effectOnFoul", data)));

  const ftPct = shot.FTpct;
  const fgValue = shot.three ? 3 : 2;
  const xPts =
    fgValue * probMake +
    probFoul * ftPct * (probMake + (1 - probMake) * fgValue);
  const xPtsBlended =
    (xPts +
      (fgValue * probMake +
        foulDrawn * ftPct * (probMake + (1 - probMake) * fgValue))) /
    2;

  return (
    <div>
      <div>
        xPts: {decFormat2(xPts)} (web points: {decFormat2(xPtsBlended)})
      </div>
      <div>p(make): {pctFormat(probMake)}</div>
      <div>p(foul): {pctFormat(probFoul)}</div>
      <div>Player FT%: {pctFormat(ftPct)}</div>
      <Table
        data={data}
        columns={columns}
        autoWidth={true}
        showRowIndex={false}
      />
      {(usingLgAvgDef || usingLgAvgOff) && (
        <div>
          * Indicates that we are missing a player specific coefficient and are
          using the league average
        </div>
      )}
    </div>
  );
}

function FinishDetailsTable(props: {
  shot: ShotDetails;
  shotModelCoeffs: ShotModelCoeffecient[];
  lgMedianShooterIntercepts: ShotModelPlayerIntercept[];
}) {
  const { shot, shotModelCoeffs, lgMedianShooterIntercepts } = props;

  const getCoeff = useCallback(
    (variable: string, outcome: "make" | "foul") => {
      const coeff = shotModelCoeffs.find(
        (c) => c.variable === variable && c.outcome === outcome
      );
      return coeff ? coeff.coefficients : 0;
    },
    [shotModelCoeffs]
  );

  const towardBasketDegree =
    1 - 1 / (1 + Math.exp(-((Math.abs(shot.shooterVelAngle) - 45) / 9)));

  const zeroDefendersToRim = shot.n_defenders_to_rim === 0 ? 1 : 0;

  const lateClock =
    shot.shotClock === null || shot.shotClock > 6 ? 0 : 6 - shot.shotClock;

  const foulDrawn = shot.fouled ? 1 : 0;

  const atRimTransform = 1 - 1 / (1 + Math.exp(-(shot.distance - 4)));
  const d1DistTransform = 1 - 1 / (1 + Math.exp(-(shot.closestDefDist - 4)));
  const openLayupDegree = atRimTransform / (d1DistTransform + 1);

  const d1Proximity =
    1 - 1 / (1 + (Math.exp((-(shot.closestDefDist - 3) * 2) / 3) * 4) / 9);

  const locationX = shot.locationY * -1;
  const locationY = shot.locationX + 47 - 5.25;

  const behindBackboard = pBehindBackboard(locationX, locationY);

  const extraDefenders = [
    shot.contesterId0,
    shot.contesterId1,
    shot.contesterId2,
    shot.contesterId3,
    shot.contesterId4,
  ].filter((id) => id !== "" && id !== null).length;

  const totalDistanceToShooter =
    shot.def1DistanceToShooter +
    shot.def2DistanceToShooter +
    shot.def3DistanceToShooter +
    shot.def4DistanceToShooter +
    shot.def5DistanceToShooter;

  const complexShotType = simplifyComplexShotType(shot.complexShotType);

  const cst = complexShotTypeMap[shot.complexShotType];

  const data = useMemo(
    () => [
      {
        factor: "Made",
        value: shot.outcome ? "Yes" : "No",
        effectOnMake: null,
        effectOnFoul: (shot.outcome ? 1 : 0) * getCoeff("outcome", "foul"),
      },
      {
        factor: "pnorm(season - 2016)",
        value: null,
        effectOnMake: getCoeff("pnorm(season - 2016)", "make"),
        effectOnFoul: getCoeff("pnorm(season - 2016)", "foul"),
      },
      {
        factor: "Made x pnorm(season - 2016)",
        value: null,
        effectOnMake: null,
        effectOnFoul:
          (shot.outcome ? 1 : 0) *
          getCoeff("outcome:pnorm(season - 2016)", "foul"),
      },
      {
        factor: "Fouled",
        value: shot.fouled ? "Yes" : "No",
        effectOnMake: foulDrawn * getCoeff("foulDrawn", "make"),
        effectOnFoul: null,
      },
      {
        factor: "Fouled x pnorm(season - 2016)",
        value: null,
        effectOnMake:
          foulDrawn * getCoeff("foulDrawn:pnorm(season - 2016)", "make"),
        effectOnFoul: null,
      },
      {
        factor: "Shot Type",
        value: cst ? cst.label : "Unknown",
        effectOnMake: getCoeff(`complexShotType${complexShotType}`, "make"),
        effectOnFoul: getCoeff(`complexShotType${complexShotType}`, "foul"),
      },
      {
        factor: "Fouled x Shot Type",
        value: null,
        effectOnMake:
          foulDrawn *
          getCoeff(`foulDrawn:complexShotType${complexShotType}`, "make"),
        effectOnFoul: null,
      },
      {
        factor: "Cumulative Shot Type Count",
        value: shot.cumul_cst_count,
        effectOnMake:
          Math.log(shot.cumul_cst_count || 1) *
          getCoeff("log(cumul_cst_count)", "make"),
        effectOnFoul:
          Math.log(shot.cumul_cst_count || 1) *
          getCoeff("log(cumul_cst_count)", "foul"),
      },
      {
        factor: "Cumulative Shot Type Count x Shot Type",
        value: null,
        effectOnMake: null,
        effectOnFoul:
          Math.log(shot.cumul_cst_count || 1) *
          getCoeff(
            `complexShotType${complexShotType}:log(cumul_cst_count)`,
            "foul"
          ),
      },
      {
        factor: "Late Shot Clock",
        value: shot.shotClock,
        effectOnMake: lateClock * getCoeff("lateClock", "make"),
        effectOnFoul: lateClock * getCoeff("lateClock", "foul"),
      },
      {
        factor: "Contest Level",
        value: shot.contestLevel,
        effectOnMake: null,
        effectOnFoul: getCoeff(`contestLevel${shot.contestLevel}`, "foul"),
      },
      {
        factor: "Closest Time To Shooter",
        value: shot.closest_time_to_shooter,
        effectOnMake: null,
        effectOnFoul:
          shot.closest_time_to_shooter *
          getCoeff("closest_time_to_shooter", "foul"),
      },
      {
        factor: "Shooter Speed",
        value: shot.shooterSpeed,
        effectOnMake: shot.shooterSpeed * getCoeff("shooterSpeed", "make"),
        effectOnFoul:
          (1 / (1 + Math.exp(-(shot.shooterSpeed - 3)) * 9)) *
          getCoeff("velocityTransform", "foul"),
      },
      {
        factor: "Angle To Basket",
        value: towardBasketDegree,
        effectOnMake:
          towardBasketDegree * getCoeff("towardBasketDegree", "make"),
        effectOnFoul: null,
      },
      {
        factor: "Zero Defenders To Rim",
        value: zeroDefendersToRim ? "Yes" : "No",
        effectOnMake:
          zeroDefendersToRim *
          getCoeff("I(n_defenders_to_rim == 0)TRUE", "make"),
        effectOnFoul:
          zeroDefendersToRim *
          getCoeff("I(n_defenders_to_rim == 0)TRUE", "foul"),
      },
      {
        factor: "Shooter Speed x Angle To Basket",
        value: null,
        effectOnMake:
          shot.shooterSpeed *
          towardBasketDegree *
          getCoeff("shooterSpeed:towardBasketDegree", "make"),
        effectOnFoul: null,
      },
      {
        factor: "Zero Defenders To Rim x (Shooter Speed x Angle To Basket)",
        value: zeroDefendersToRim ? "Yes" : "No",
        effectOnMake:
          zeroDefendersToRim *
          shot.shooterSpeed *
          towardBasketDegree *
          getCoeff(
            "I((n_defenders_to_rim == 0) * (shooterSpeed * towardBasketDegree))",
            "make"
          ),
        effectOnFoul: null,
      },
      {
        factor: "Shot Type x Zero Defenders to Rim",
        value: cst ? cst.label : "Unknown",
        effectOnMake:
          zeroDefendersToRim *
          getCoeff(
            `complexShotType${complexShotType}:I(n_defenders_to_rim == 0)TRUE`,
            "make"
          ),
        effectOnFoul:
          zeroDefendersToRim *
          getCoeff(
            `complexShotType${complexShotType}:I(n_defenders_to_rim == 0)TRUE`,
            "foul"
          ),
      },
      {
        factor: "Defenders to Rim",
        value: shot.n_defenders_to_rim,
        effectOnMake:
          Math.sqrt(shot.n_defenders_to_rim) *
          getCoeff("sqrt(n_defenders_to_rim)", "make"),
        effectOnFoul: null,
      },
      {
        factor: "Open Layup Degree",
        value: openLayupDegree,
        effectOnMake: openLayupDegree * getCoeff("openLayupDegree", "make"),
        effectOnFoul: null,
      },
      {
        factor: "Defender Standing Reach",
        value: shot.defMakeFinishSR,
        effectOnMake: (shot.defMakeFinishSR || 0) * getCoeff("def_sr", "make"),
        effectOnFoul: null,
      },
      {
        factor: "Closest Defender Distance",
        value: shot.closestDefDist,
        effectOnMake: d1Proximity * getCoeff("d1Proximity", "make"),
        effectOnFoul: d1Proximity * getCoeff("d1Proximity", "foul"),
      },
      {
        factor: "Defender Standing Reach x Closest Defender Distance",
        value: null,
        effectOnMake:
          (shot.defMakeFinishSR || 0) *
          d1Proximity *
          getCoeff("def_sr:d1Proximity", "make"),
        effectOnFoul: null,
      },
      {
        factor: "Probabaility Behind backboard",
        value: behindBackboard,
        effectOnMake: behindBackboard * getCoeff("behindBackboard", "make"),
        effectOnFoul: null,
      },
      {
        factor: "Contesting Defenders",
        value: extraDefenders,
        effectOnMake: getCoeff(
          `factor(extraDefenders)${extraDefenders}`,
          "make"
        ),
        effectOnFoul: getCoeff(
          `factor(extraDefenders)${extraDefenders}`,
          "foul"
        ),
      },
      {
        factor: "Closest Defender Distance x Shot Type",
        value: null,
        effectOnMake:
          d1Proximity *
          getCoeff(`complexShotType${complexShotType}:d1Proximity`, "make"),
        effectOnFoul:
          d1Proximity *
          getCoeff(`complexShotType${complexShotType}:d1Proximity`, "foul"),
      },
      {
        factor: "Total Defender Distance",
        value: totalDistanceToShooter,
        effectOnMake: shot.proximity_sum * getCoeff("proximity_sum", "make"),
        effectOnFoul: null,
      },
      {
        factor: "Total Defender Distance x Shot Type",
        value: null,
        effectOnMake:
          shot.proximity_sum *
          getCoeff(`complexShotType${complexShotType}:proximity_sum`, "make"),
        effectOnFoul: null,
      },
      {
        factor: "Distance",
        value: shot.distance,
        effectOnMake: shot.distance * getCoeff("Distance", "make"),
        effectOnFoul:
          (1 / (shot.distance + 1)) * getCoeff("I(1/(Distance + 1))", "foul") +
          Math.sqrt(shot.distance) * getCoeff("sqrt(Distance)", "foul"),
      },
      {
        factor: "Distance x Shot Type",
        value: null,
        effectOnMake:
          shot.distance *
          getCoeff(`complexShotType${complexShotType}:Distance`, "make"),
        effectOnFoul:
          Math.sqrt(shot.distance) *
          getCoeff(`complexShotType${complexShotType}:sqrt(Distance)`, "foul"),
      },
      {
        factor: "Shooter Velocity Toward Rim",
        value: shot.shooterVelTowardRim,
        effectOnMake: null,
        effectOnFoul:
          shot.shooterVelTowardRim * getCoeff("shooterVelTowardRim", "foul"),
      },
      {
        factor: "Shooter Velocity Toward Rim x Shot Type",
        value: null,
        effectOnMake: null,
        effectOnFoul:
          shot.shooterVelTowardRim *
          getCoeff(
            `complexShotType${complexShotType}:shooterVelTowardRim`,
            "foul"
          ),
      },
      {
        factor: "In Transition",
        value: shot.isTransition ? "Yes" : "No",
        effectOnMake:
          (shot.isTransition ? 1 : 0) * getCoeff("isTransitionTRUE", "make"),
        effectOnFoul: null,
      },
      {
        factor: "In Transition x Made",
        value: null,
        effectOnMake: null,
        effectOnFoul:
          (shot.isTransition && shot.outcome ? 1 : 0) *
          getCoeff("outcome:isTransitionTRUE", "foul"),
      },
      {
        factor: "Defender Effect",
        value: shot.defender,
        effectOnMake: shot.defMakeFinishCoeff,
        effectOnFoul: shot.defFoulFinishCoeff,
      },
      {
        factor: "Shooter Effect",
        value: `${shot.shooter} ${cst ? cst.label : "Unknown"}`,
        effectOnMake:
          shot.makeFinishCoeff ||
          lgMedianShooterIntercepts.find(
            (si) =>
              si.outcome === "make" &&
              si.complexShotType === shot.complexShotType
          )?.intercept ||
          0,
        effectOnFoul:
          shot.foulFinishCoeff ||
          lgMedianShooterIntercepts.find(
            (si) =>
              si.outcome === "foul" &&
              si.complexShotType === shot.complexShotType
          )?.intercept ||
          0,
      },
      {
        factor: "Intercept",
        value: null,
        effectOnMake: -getCoeff("(Intercept)", "make") * 5,
        effectOnFoul: -getCoeff("(Intercept)", "foul") * 5,
      },
    ],
    [
      behindBackboard,
      complexShotType,
      cst,
      d1Proximity,
      extraDefenders,
      foulDrawn,
      getCoeff,
      lateClock,
      lgMedianShooterIntercepts,
      openLayupDegree,
      shot.closestDefDist,
      shot.closest_time_to_shooter,
      shot.complexShotType,
      shot.contestLevel,
      shot.cumul_cst_count,
      shot.defFoulFinishCoeff,
      shot.defMakeFinishCoeff,
      shot.defMakeFinishSR,
      shot.defender,
      shot.distance,
      shot.foulFinishCoeff,
      shot.fouled,
      shot.isTransition,
      shot.makeFinishCoeff,
      shot.n_defenders_to_rim,
      shot.outcome,
      shot.proximity_sum,
      shot.shooter,
      shot.shooterSpeed,
      shot.shooterVelTowardRim,
      shot.shotClock,
      totalDistanceToShooter,
      towardBasketDegree,
      zeroDefendersToRim,
    ]
  );

  const usingLgAvgOff =
    shot.makeFinishCoeff === null || shot.foulFinishCoeff === null;
  const usingLgAvgDef =
    shot.defMakeFinishCoeff === null || shot.defFoulFinishCoeff === null;

  const columns = useMemo(() => {
    return [
      shotDetailsColumnHelper.accessor("factor", {
        header: "Factor",
        meta: { textAlign: "left" },
      }),
      shotDetailsColumnHelper.accessor("value", {
        header: "Value",
        meta: { textAlign: "left" },
      }),
      shotDetailsColumnHelper.accessor("effectOnMake", {
        header: "Effect on p(Make)",
        cell: (info) => {
          const val = info.getValue();
          const factor = info.row.original.factor;
          if (
            (factor === "Defender Effect" && usingLgAvgDef) ||
            (factor === "Shooter Effect" && usingLgAvgOff)
          ) {
            return `*${val}`;
          }
          return val;
        },
        footer: () => {
          const unscaled = sum("effectOnMake", data);
          const p = 1 / (1 + Math.exp(-unscaled));
          return `${unscaled} (${pctFormat(p)})`;
        },
      }),
      shotDetailsColumnHelper.accessor("effectOnFoul", {
        header: "Effect on p(Foul)",
        cell: (info) => {
          const val = info.getValue();
          const factor = info.row.original.factor;
          if (
            (factor === "Defender Effect" && usingLgAvgDef) ||
            (factor === "Shooter Effect" && usingLgAvgOff)
          ) {
            return `*${val}`;
          }
          return val;
        },
        footer: () => {
          const unscaled = sum("effectOnFoul", data);
          const p = 1 / (1 + Math.exp(-unscaled));
          return `${unscaled} (${pctFormat(p)})`;
        },
      }),
    ];
  }, [data, usingLgAvgDef, usingLgAvgOff]);

  const probMake = 1 / (1 + Math.exp(-sum("effectOnMake", data)));
  const probFoul = 1 / (1 + Math.exp(-sum("effectOnFoul", data)));

  const ftPct = shot.FTpct;
  const fgValue = shot.three ? 3 : 2;
  const xPts =
    fgValue * probMake +
    probFoul * ftPct * (probMake + (1 - probMake) * fgValue);
  const xPtsBlended =
    (xPts +
      (fgValue * probMake +
        foulDrawn * ftPct * (probMake + (1 - probMake) * fgValue))) /
    2;

  return (
    <div>
      xPts: {decFormat2(xPts)} ({decFormat2(xPtsBlended)})
      <div>p(make): {pctFormat(probMake)}</div>
      <div>p(foul): {pctFormat(probFoul)}</div>
      <div>Player FT%: {pctFormat(ftPct)}</div>
      <Table
        data={data}
        columns={columns}
        autoWidth={true}
        showRowIndex={false}
      />
      {(usingLgAvgDef || usingLgAvgOff) && (
        <div>
          * Indicates that we are missing a player specific coefficient and are
          using the league average
        </div>
      )}
    </div>
  );
}
