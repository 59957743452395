import React, { useState } from "react";
import moment from "moment";
import { ParentSize } from "@visx/responsive";

import { LineChart } from "../chart/LineChart";
import { lineChartColors } from "../../constants/ColorConstants";
import { getSeasonFromDate } from "../../util/Util";
import { NbaDates } from "../../../shared/NbaDates";
import { groupBy } from "../../../shared/util/Collections";
import { decFormat } from "../../util/Format";
import { scoutSpeedForPos } from "../../constants/AppConstants";

export function PlayerSpeedByDateChart(props: {
  data: {
    gameDate: string;
    norm_max_speed: number | null;
    norm_max_power: number | null;
    norm_min_ke: number | null;
    norm_max_jump: number | null;
  }[];
}) {
  const { data } = props;
  const [compareMetric, setCompareMetric] = useState<
    "burst" | "speed" | "decel" | "jump"
  >("burst");

  const colors = {
    burst: lineChartColors.blue,
    speed: lineChartColors.red,
    decel: lineChartColors.yellow,
    jump: lineChartColors.green,
  };

  const referenceLines = scoutSpeedForPos[compareMetric].map((s) => {
    return {
      label: s.label,
      value: s.value,
      color: colors[compareMetric],
    };
  });

  const speed = {
    color: colors.speed,
    segments: Object.values(
      groupBy(
        data
          .filter((d) => d.norm_max_speed !== null)
          .map((d) => {
            return {
              x: moment(d.gameDate).valueOf(),
              y: d.norm_max_speed || 0,
            };
          }),
        (d) => getSeasonFromDate(moment(d.x).format("YYYY-MM-DD")) || ""
      )
    ),
    label: "Top Speed",
  };

  const burst = {
    color: colors.burst,
    segments: Object.values(
      groupBy(
        data
          .filter((d) => d.norm_max_power !== null)
          .map((d) => {
            return {
              x: moment(d.gameDate).valueOf(),
              y: d.norm_max_power || 0,
            };
          }),
        (d) => getSeasonFromDate(moment(d.x).format("YYYY-MM-DD")) || ""
      )
    ),
    label: "Burst",
  };

  const decel = {
    color: colors.decel,
    segments: Object.values(
      groupBy(
        data
          .filter((d) => d.norm_min_ke !== null)
          .map((d) => {
            return {
              x: moment(d.gameDate).valueOf(),
              y: d.norm_min_ke || 0,
            };
          }),
        (d) => getSeasonFromDate(moment(d.x).format("YYYY-MM-DD")) || ""
      )
    ),
    label: "Decel",
  };

  const jump = {
    color: colors.jump,
    segments: Object.values(
      groupBy(
        data
          .filter((d) => d.norm_max_jump !== null)
          .map((d) => {
            return {
              x: moment(d.gameDate).valueOf(),
              y: d.norm_max_jump || 0,
            };
          }),
        (d) => getSeasonFromDate(moment(d.x).format("YYYY-MM-DD")) || ""
      )
    ),
    label: "Jump",
  };

  const xTicks = Object.values(NbaDates)
    .filter((d) =>
      data.some(
        (data) =>
          moment(data.gameDate).valueOf() >= d.season.start &&
          moment(data.gameDate).valueOf() <= d.season.end
      )
    )
    .map((d) => d.season.start);

  const lines = [speed, burst, decel, jump];
  return (
    <ParentSize>
      {({ width }) => (
        <div style={{ position: "relative" }}>
          {"Reference Lines: "}
          <select
            onChange={(e) => {
              setCompareMetric(
                e.currentTarget.value as "speed" | "burst" | "decel"
              );
            }}
          >
            {[
              { value: "burst", label: "Burst" },
              { value: "speed", label: "Top Speed" },
              { value: "decel", label: "Decel" },
              { value: "jump", label: "Jump" },
            ].map((s) => (
              <option key={s.value} value={s.value}>
                {s.label}
              </option>
            ))}
          </select>
          <LineChart
            margin={{ right: 40 }}
            height={width / 2}
            width={width}
            referenceLines={referenceLines}
            lines={lines}
            showLegend={true}
            xTickFormat={(val) =>
              getSeasonFromDate(moment(val as number).format("YYYY-MM-DD")) ||
              ""
            }
            xTicks={xTicks}
            yTicks={[0, 20, 40, 60, 80, 100]}
            tooltip={(d) => {
              const data = d.data;
              if (!data) return;

              const firstData = data[0];
              if (!firstData) return;

              return (
                <div>
                  <div>
                    <b>{moment(firstData.x).format("YYYY-MM-DD")}</b>
                  </div>
                  {data.map((d, i) => (
                    <div
                      key={i}
                      style={{ display: "flex", gap: 8, alignItems: "center" }}
                    >
                      <div
                        style={{
                          background: d.color,
                          width: 10,
                          height: 10,
                        }}
                      ></div>
                      {decFormat(d.y)}
                    </div>
                  ))}
                </div>
              );
            }}
          />
          <div style={{ marginTop: 16, color: "#888" }}>
            Reference lines are based on minutes weighted positional medians.
          </div>
        </div>
      )}
    </ParentSize>
  );
}
