import React, { useState, useMemo } from "react";
import { Form } from "react-bootstrap";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import {
  dec100Format,
  decFormat,
  decFormat2,
  seasonString,
} from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { trpc } from "../../util/tRPC";
import {
  PlayerPnrScreener,
  PlayerPnrBallhandler,
} from "../../../shared/routers/PlayerRouter";

export function PlayerPnrOffense(props: { playerId: number }) {
  const { playerId } = props;
  const [showSecondaryRole, setShowSecondaryRole] = useState(false);

  const { data: pnrScreener } = trpc.player.getPlayerPnrScreener.useQuery({
    playerId,
  });
  const { data: pnrBallhandler } = trpc.player.getPlayerPnrBallhandler.useQuery(
    { playerId }
  );

  if (!pnrScreener || !pnrBallhandler) return null;

  const totalPicksScreener = pnrScreener.reduce(
    (prev, cur) => prev + cur.picks,
    0
  );

  const totalPicksBallhandler = pnrBallhandler.reduce(
    (prev, cur) => prev + cur.picks,
    0
  );

  return (
    <div>
      {totalPicksBallhandler >= totalPicksScreener ? (
        <div>
          <BallhandlerTable data={pnrBallhandler} />
          {showSecondaryRole && <ScreenerTable data={pnrScreener} />}
          <Form.Check
            type="checkbox"
            checked={showSecondaryRole}
            label="Show stats as screener"
            onChange={() => setShowSecondaryRole(!showSecondaryRole)}
          />
        </div>
      ) : (
        <div>
          <ScreenerTable data={pnrScreener} />
          {showSecondaryRole && <BallhandlerTable data={pnrBallhandler} />}
          <Form.Check
            type="checkbox"
            checked={showSecondaryRole}
            label="Show stats as ball handler"
            onChange={() => setShowSecondaryRole(!showSecondaryRole)}
          />
        </div>
      )}
    </div>
  );
}

const scrColumnHelper = createColumnHelper<PlayerPnrScreener>();

function ScreenerTable(props: { data: PlayerPnrScreener[] }) {
  const [sorting, setSorting] = useState<SortingState>();
  const { data } = props;

  const columns = useMemo(
    () => [
      scrColumnHelper.accessor("season", {
        header: () => "Season",
        cell: (info) => seasonString(info.getValue().toString()),
        meta: { group: 0 },
      }),
      scrColumnHelper.accessor("picks", {
        header: () => "Picks",
        cell: (info) => info.getValue(),
        meta: { group: 1 },
      }),
      scrColumnHelper.accessor("picksPerGame", {
        header: () => "Picks / G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: 1 },
      }),
      scrColumnHelper.accessor("picksPerPlay", {
        header: () => "Picks / Play",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: 1 },
      }),
      scrColumnHelper.accessor("ppp", {
        header: () => "xPPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: 2,
          highlights: Highlights.Max,
          colorDomain: [0.8, 1.2],
        },
      }),
      scrColumnHelper.accessor("slipRate", {
        header: () => "Slip %",
        cell: (info) => dec100Format(info.getValue()),
        meta: { group: 3 },
      }),
      scrColumnHelper.accessor("rollRate", {
        header: () => "Roll %",
        cell: (info) => dec100Format(info.getValue()),
        meta: { group: 3 },
      }),
      scrColumnHelper.accessor("popRate", {
        header: () => "Pop %",
        cell: (info) => dec100Format(info.getValue()),
        meta: { group: 3 },
      }),
      scrColumnHelper.accessor("popShotRate", {
        header: () => "Pop Shot %",
        cell: (info) => dec100Format(info.getValue()),
        meta: { group: 4 },
      }),
      scrColumnHelper.accessor("popxPps", {
        header: () => "Pop xPPS",
        cell: (info) => decFormat2(info.getValue()),
        meta: { group: 4, highlights: Highlights.Max },
      }),
      scrColumnHelper.accessor("popThreesPerGame", {
        header: () => "Pop 3s / G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: 4, highlights: Highlights.Max },
      }),
      scrColumnHelper.accessor("dhoPerGame", {
        header: () => "DHO / G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: 5 },
      }),
      scrColumnHelper.accessor("handbacksPerGame", {
        header: () => "Handbacks / G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: 5 },
      }),
    ],
    []
  );

  return (
    <div>
      <b>As Screener</b>
      <Table
        data={data.sort((a, b) => a.season - b.season)}
        columns={columns}
        autoWidth={true}
        showColorOnHover={true}
        sorting={sorting}
        setSorting={setSorting}
      />
    </div>
  );
}

const bhrColumnHelper = createColumnHelper<PlayerPnrBallhandler>();

function BallhandlerTable(props: { data: PlayerPnrBallhandler[] }) {
  const [sorting, setSorting] = useState<SortingState>();
  const { data } = props;

  const columns = useMemo(
    () => [
      bhrColumnHelper.accessor("season", {
        header: () => "Season",
        cell: (info) => seasonString(info.getValue().toString()),
        meta: { group: 0 },
      }),
      bhrColumnHelper.accessor("picks", {
        header: () => "Picks",
        cell: (info) => info.getValue(),
        meta: { group: 1 },
      }),
      bhrColumnHelper.accessor("picksPerGame", {
        header: () => "Picks / G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: 1 },
      }),
      bhrColumnHelper.accessor("picksPerPlay", {
        header: () => "Picks / Play",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: 1 },
      }),
      bhrColumnHelper.accessor("ppp", {
        header: () => "xPPP",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: 2,
          highlights: Highlights.Max,
          colorDomain: [0.8, 1.2],
        },
      }),
      bhrColumnHelper.accessor("rejectRate", {
        header: () => "Reject %",
        cell: (info) => dec100Format(info.getValue()),
        meta: { group: 3 },
      }),
      bhrColumnHelper.accessor("shootRate", {
        header: () => "Shoots %",
        cell: (info) => dec100Format(info.getValue()),
        meta: { group: 3 },
      }),
      bhrColumnHelper.accessor("dhoPerGame", {
        header: () => "DHO / G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: 4 },
      }),
      bhrColumnHelper.accessor("handbacksPerGame", {
        header: () => "Handbacks / G",
        cell: (info) => decFormat(info.getValue()),
        meta: { group: 4 },
      }),
    ],
    []
  );

  return (
    <div>
      <b>As Ball Handler</b>
      <Table
        data={data.sort((a, b) => a.season - b.season)}
        columns={columns}
        autoWidth={true}
        showColorOnHover={true}
        sorting={sorting}
        setSorting={setSorting}
      />
    </div>
  );
}
