import React, { useState, useCallback, useRef, useContext } from "react";
import { ParentSize } from "@visx/responsive";
import { localPoint } from "@visx/event";
import { scaleLinear, scaleSqrt, rgb, line, curveBasis, bisector } from "d3";
import { createStyles, makeStyles } from "@material-ui/styles";

import { UserContext } from "../../UserContext";
import { PlayerDriveChartData } from "../../../shared/routers/PlayerRouter";
import { Court } from "../court/Court";
import { GradientLegend } from "../chart/GradientLegend";
import { generateDomain, toDegrees } from "../../util/Util";
import { pctFormatRound } from "../../util/Format";
import { optionB } from "../../constants/ChartConstants";
import { trpc } from "../../util/tRPC";

const useStyles = makeStyles(() =>
  createStyles({
    leftRightProb: {
      "& line": {
        stroke: "#ccc",
      },
      "& .left": {
        stroke: "#3AD8BB",
        fill: "#3AD8BB",
      },
      "& .right": {
        stroke: "#5FD32E",
        fill: "#5FD32E",
      },
      "& .middle": {
        stroke: "rgb(131, 131, 131)",
        fill: "rgb(131, 131, 131",
      },
    },
  })
);

export function PlayerDriveChart(props: { playerIdEagle: string }) {
  const { playerIdEagle } = props;

  return (
    <div>
      <ParentSize parentSizeStyles={{ width: "100%" }}>
        {(parent) => (
          <PlayerDriveChartInner
            width={parent.width}
            playerIdEagle={playerIdEagle}
          />
        )}
      </ParentSize>
    </div>
  );
}

function PlayerDriveChartInner(props: {
  width: number;
  playerIdEagle: string;
}) {
  const classes = useStyles();
  const svgRef = useRef<SVGSVGElement>(null);
  const [highlightPoint, setHighlightPoint] =
    useState<PlayerDriveChartData | null>(null);
  const { width, playerIdEagle } = props;

  const { data: points } = trpc.player.getPlayerDriveChartData.useQuery({
    playerEagleId: playerIdEagle,
  });

  // TODO(chrisbu): Remove this temp hack for merg to make screenshots not have
  // the letters.
  const user = useContext(UserContext);
  const isMerg = user && user.email === "amerg@celtics.com";

  // Flip x and y to get the rim near the top.
  const xDomain = [25, -25];
  // Maps to visible court length.
  const yDomain = [37, -6];
  const height = width * (((yDomain[0] || 0) * 10 + 50) / 500) + 20;

  const innerMargin = { top: 0, right: 0, bottom: 20, left: 0 };
  const innerWidth = width - innerMargin.left - innerMargin.right;
  const innerHeight = height - innerMargin.top - innerMargin.bottom;

  const xMin = 0,
    xMax = innerWidth,
    yMin = innerHeight,
    yMax = 0;

  const x = scaleLinear().domain(xDomain).range([xMin, xMax]);
  const y = scaleLinear().domain(yDomain).range([yMin, yMax]);

  const magnitudeDomain = [0, 9];

  const colorDomain = [0.996, 1.2];
  const colorRange = optionB;
  const color = scaleLinear<string, string>()
    .domain(
      generateDomain(
        colorDomain[0] || 0,
        colorDomain[1] || 0,
        colorRange.length
      )
    )
    .range(colorRange)
    .clamp(true);

  const widthScale = scaleSqrt()
    .domain(magnitudeDomain)
    .range([0.001 * width, 0.02 * width])
    .clamp(true);

  const lengthScale = scaleLinear()
    .domain(magnitudeDomain)
    .range([0.04 * innerHeight, 0.4 * innerHeight])
    .clamp(true);

  const rightLeftDomain = [0, 1];
  const maxAngle = 90;
  const angleScale = scaleLinear()
    .domain(rightLeftDomain)
    .range([-maxAngle, maxAngle])
    .clamp(true);

  // Create polygon points from magnitude and pRightLeft.
  const makePolygonPoints = (magnitude: number) => {
    // Width of mark.
    const w = widthScale(magnitude);
    const l = lengthScale(magnitude);

    const x1 = w,
      y1 = 0;
    const x2 = -w,
      y2 = 0;
    const x3 = 0,
      y3 = -l;

    // -l/20 makes it have a notch at the bottom of the triangle to
    // make it an arrow head shape.
    return `${x1},${y1} 0,${-l / 20} ${x2},${y2} ${x3},${y3}`;
  };

  const renderHover = (d: PlayerDriveChartData) => {
    const middleRimX = -0.3,
      middleRimY = -0.8;
    const lrLineWidth = x(-12) - x(12);
    const lrLineTickHeight = 8;

    const middleEpsilon = 0.05;
    let lrColor;
    if (Math.abs(d.rightProp - 0.5) < middleEpsilon) {
      lrColor = "middle";
    } else if (d.rightProp < 0.5) {
      lrColor = "left";
    } else {
      lrColor = "right";
    }

    // Vector to rim.
    const xToRim = x(middleRimX) - x(d.impliedX);
    const yToRim = y(middleRimY) - y(d.impliedY);

    // Perpendicular vector.
    const xPerp = -yToRim;
    const yPerp = xToRim;

    const perpFactor = 0.3;

    const rightPoints = [
      { x: x(d.impliedX), y: y(d.impliedY) },
      {
        x: x(d.impliedX) + xPerp * perpFactor + xToRim * 0.5,
        y: y(d.impliedY) + yPerp * perpFactor + yToRim * 0.5,
      },
      { x: x(middleRimX), y: y(middleRimY) },
    ];

    const leftPoints = [
      { x: x(d.impliedX), y: y(d.impliedY) },
      {
        x: x(d.impliedX) + xPerp * -perpFactor + xToRim * 0.5,
        y: y(d.impliedY) + yPerp * -perpFactor + yToRim * 0.5,
      },
      { x: x(middleRimX), y: y(middleRimY) },
    ];

    const lineGenerator = line()
      .x((d: any) => d.x)
      .y((d: any) => d.y)
      .curve(curveBasis);

    const strokeWidth = scaleLinear().domain([0, 1]).range([1, 10]);

    return (
      <g className="highlight" key={"highlight"}>
        <line
          x1={x(d.impliedX)}
          y1={y(d.impliedY)}
          x2={x(middleRimX)}
          y2={y(middleRimY)}
          style={{
            strokeWidth: 1,
            stroke: "rgba(0, 0, 0, 0.3)",
            strokeDasharray: "3,3",
            pointerEvents: "none",
          }}
        />
        <circle cx={x(d.impliedX)} cy={y(d.impliedY)} r={3} fill="#888" />
        {/* Left/right arrows. */}
        <g className="left-right-arrows">
          <path
            d={lineGenerator(leftPoints as any) || ""}
            stroke={color(
              d.defenderAlpha * d.ptsLeft + (1 - d.defenderAlpha) * d.pts
            )}
            fill={"none"}
            strokeWidth={strokeWidth(1 - d.rightProp)}
          />
          <path
            d={lineGenerator(rightPoints as any) || ""}
            stroke={color(
              d.defenderAlpha * d.ptsRight + (1 - d.defenderAlpha) * d.pts
            )}
            fill={"none"}
            strokeWidth={strokeWidth(d.rightProp)}
          />
        </g>
        <g
          className={classes.leftRightProb}
          transform={`translate(${x(0) - lrLineWidth / 2} ${height - 38})`}
        >
          <line x1={0} y1={0} x2={lrLineWidth} y2={0} /* Main line. */ />
          <line
            x1={0}
            y1={-lrLineTickHeight / 2}
            x2={0}
            y2={lrLineTickHeight / 2} /* Left end. */
          />
          <line
            x1={lrLineWidth}
            y1={-lrLineTickHeight / 2}
            x2={lrLineWidth}
            y2={lrLineTickHeight / 2} /* Right end. */
          />
          <line
            x1={lrLineWidth / 2}
            y1={-lrLineTickHeight / 2}
            x2={lrLineWidth / 2}
            y2={lrLineTickHeight / 2} /* Mid line. */
          />

          <line
            x1={lrLineWidth * d.rightProp}
            y1={0}
            x2={lrLineWidth / 2}
            y2={0}
            className={lrColor} /* Color line to middle. */
          />
          <circle
            cx={lrLineWidth * d.rightProp}
            cy={0}
            className={lrColor}
            r={3}
            /* Color circle. */ strokeWidth={0}
          />
          <text
            style={{ dominantBaseline: "hanging" }}
            x={0}
            y={lrLineTickHeight / 2 + 2}
            textAnchor="start"
          >{`Left ${pctFormatRound(1 - d.rightProp)}`}</text>
          <text
            style={{ dominantBaseline: "hanging" }}
            x={lrLineWidth}
            y={lrLineTickHeight / 2 + 2}
            textAnchor="end"
          >{`Right ${pctFormatRound(d.rightProp)}`}</text>
        </g>
        {/* Left/right force text. */}
        <g transform={`translate(0 ${height - 15})`}>
          <text
            style={{
              dominantBaseline: "hanging",
              opacity: d.defenderAlpha,
            }}
            textAnchor="middle"
            x={x(0)}
          >{`Force Drive ${
            d.directionToForce === "R" ? "Right" : "Left"
          }`}</text>
        </g>
      </g>
    );
  };

  const handleMouseMove = useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      if (!svgRef.current || !points) return;
      const point = localPoint(svgRef.current, event);
      if (!point) return;
      const domainX = x.invert(point.x);
      const domainY = y.invert(point.y);

      let theta = toDegrees(Math.atan(domainY / domainX));

      // Account for shift in computed theta and actual angle on screen.
      if (domainX >= 0) {
        theta = -theta + 90;
      } else {
        theta = -theta - 90;
      }

      const closestAngle = bisector(getAngleToBasket).center;
      const idx = closestAngle(points, theta);
      const pointsAtIdx = points[idx];
      if (pointsAtIdx) {
        setHighlightPoint(pointsAtIdx);
      } else {
        setHighlightPoint(null);
      }
    },
    [points, x, y]
  );

  if (!points) return null;

  return (
    <div
      onMouseLeave={() => setHighlightPoint(null)}
      onMouseMove={handleMouseMove}
    >
      <div style={{ position: "absolute" }}>
        <Court
          width={width}
          maxDistance={(y.domain()[0] || 0) - 5}
          hideBorder={true}
          hideBenchLines={true}
        />
      </div>
      <svg width={width} height={height} ref={svgRef}>
        <GradientLegend
          key={"legend"}
          x={x}
          valueFunc={(h: any | null | undefined) => (h && h.pts) || 0}
          color={color}
          highlight={highlightPoint}
          label={"PTS"}
          id={new Date().getTime()} // Just need a unique #.
          width={Math.abs(x(37) - x(13))}
        />
        {points.map((d, i) => {
          const angleToBasket = getAngleToBasket(d);

          const theta = angleToBasket + angleScale(d.rightProp);

          return (
            <g
              key={i}
              style={{
                opacity: highlightPoint && highlightPoint !== d ? 0.3 : 1,
              }}
            >
              <polygon
                points={makePolygonPoints(d.arrowMagnitude)}
                fill={color(d.pts)}
                stroke={rgb(color(d.pts)).darker(0.3).toString()}
                strokeWidth={0.5}
                transform={`translate(${x(d.impliedX)} ${y(
                  d.impliedY
                )}) rotate(${theta})`}
              />
              {!isMerg && (
                <text
                  x={x(d.impliedX * 1.04)}
                  y={y(d.impliedY * 1.04)}
                  fill={`rgba(66, 66, 66, ${d.defenderAlpha})`}
                  textAnchor="middle"
                  style={{
                    dominantBaseline: "middle",
                    fontSize: width * 0.033,
                  }}
                >
                  {d.directionToForce}
                </text>
              )}
            </g>
          );
        })}
        {highlightPoint && renderHover(highlightPoint)}
      </svg>
    </div>
  );
}

function getAngleToBasket(d: PlayerDriveChartData) {
  let angleToBasket = toDegrees(Math.atan(d.impliedX / d.impliedY));
  // Handle rotations flipping when y is negative.
  if (d.impliedY < 0) {
    angleToBasket = angleToBasket - 180;
    // Prefer 95 over -265 so the points are in order for hover behaviour.
    if (d.impliedX > 0) {
      angleToBasket = (angleToBasket + 360) % 360;
    }
  }
  return angleToBasket;
}
