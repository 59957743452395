import React from "react";

const PRIMARY_BLUE = "#0d6efd";

export function RadioButtonGroup(props: {
  value: string;
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
}) {
  const { value, options, onChange } = props;
  return (
    <div style={{ display: "flex" }}>
      {options.map((o, i) => {
        return (
          <div
            key={i}
            style={{
              padding: "0 8px",
              borderLeft: i > 0 ? "1px solid #ddd" : undefined,
              color: o.value === value ? PRIMARY_BLUE : "rgba(0, 0, 0, 0.62)",
              textDecorationLine: o.value === value ? "underline" : "none",
              textDecorationThickness: "2px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => onChange(o.value)}
          >
            {o.label}
          </div>
        );
      })}
    </div>
  );
}
