import React from "react";
import Immutable from "immutable";
import CreateReactClass from "create-react-class";
import PropTypes from "prop-types";
import cx from "classnames";

import "./SelectableList.css";

const SelectableList = CreateReactClass({
  propTypes: {
    // items of form { value, label }
    items: PropTypes.instanceOf(Immutable.List),
    visible: PropTypes.bool,
    onSelect: PropTypes.func,
    wrapUpAround: PropTypes.bool,
    wrapDownAround: PropTypes.bool,
    onUpperEdge: PropTypes.func,
    defaultMaxItems: PropTypes.number,
    maxHeight: PropTypes.number,
    itemRender: PropTypes.func,
  },

  componentWillMount() {
    this.listRef = React.createRef();
  },

  getDefaultProps() {
    return {
      defaultMaxItems: 20,
    };
  },

  getInitialState() {
    return {
      maxItemsToShow: this.props.defaultMaxItems,
      activeItemIndex: null,
    };
  },

  // reset active index when hiding list
  componentWillReceiveProps(nextProps) {
    if (!nextProps.visible || nextProps.disableActive) {
      this.resetSelected();
    }
  },

  _handleSelect(index) {
    const { onSelect, items, disableSelect } = this.props;

    if (!disableSelect) {
      this._handleHighlight(index);

      if (onSelect) {
        onSelect(items.get(index));
      }
    }
  },

  _handleShowMore() {
    const { activeItemIndex } = this.state;

    // if we clicked the show more action, handle that
    this.setState(
      {
        maxItemsToShow: null,
      },
      () => {
        if (activeItemIndex != null && this._getLIs()[activeItemIndex]) {
          this._getLIs()[activeItemIndex].focus();
        }
      }
    );
  },

  _handleHighlight(index) {
    this.setState({
      activeItemIndex: index,
    });
  },

  _isShowMoreActive() {
    if (
      this._getNumHiddenItems() > 0 &&
      this.state.activeItemIndex === this._getLIs().length - 1
    ) {
      return true;
    }

    return false;
  },

  _handleKeyDown(evt) {
    switch (evt.keyCode) {
      case 13: // enter
        if (this._isShowMoreActive()) {
          this._handleShowMore();
        } else {
          this._handleSelect(this.state.activeItemIndex);
        }

        break;
      case 38: // up arrow
        evt.preventDefault(); // prevent scrolling up the page
        this._focusPrev();
        break;
      case 40: // down arrow
        evt.preventDefault(); // prevent scrolling down the page
        this._focusNext();
        break;
    }
  },

  _focusNext() {
    const { wrapDownAround } = this.props;
    let { activeItemIndex } = this.state;
    if (activeItemIndex == null) {
      activeItemIndex = -1;
    }

    // get next list item
    const lis = this._getLIs();
    let nextIndex = activeItemIndex + 1; // % lis.length;
    if (nextIndex >= lis.length) {
      nextIndex = wrapDownAround ? nextIndex % lis.length : lis.length - 1;
    }

    const nextLi = lis[nextIndex];

    this._handleHighlight(nextIndex);

    nextLi.focus();
  },

  _focusPrev() {
    const { wrapUpAround, onUpperEdge } = this.props;
    let { activeItemIndex } = this.state;
    if (activeItemIndex == null) {
      activeItemIndex = 0;
    }

    // get next list item
    const lis = this._getLIs();
    let prevIndex = (activeItemIndex - 1) % lis.length;
    if (prevIndex === -1) {
      // if we should wrap around, do it
      if (wrapUpAround) {
        prevIndex = lis.length - 1;

        // otherwise signal we reached the upper edge
      } else {
        if (onUpperEdge) {
          onUpperEdge();
        }

        return;
      }
    }

    const prevLi = lis[prevIndex];

    this._handleHighlight(prevIndex);

    prevLi.focus();
  },

  _getLIs() {
    return this.listRef.current.children;
  },

  _getNumHiddenItems() {
    let { items } = this.props;
    const { maxItemsToShow } = this.state;

    let numHidden;

    if (maxItemsToShow) {
      numHidden = items.size > maxItemsToShow ? items.size - maxItemsToShow : 0;
    }

    return numHidden;
  },

  resetSelected() {
    this.setState({
      activeItemIndex: null,
    });
  },

  getLabel(object) {
    if (object && object.get) {
      return object.get("label");
    } else if (object) {
      return object.label;
    }
    return null;
  },

  getAux1(object) {
    if (object && object.get) {
      return object.get("aux1");
    } else if (object) {
      return object.aux1;
    }
    return null;
  },

  getAux2(object) {
    if (object && object.get) {
      return object.get("aux2");
    } else if (object) {
      return object.aux2;
    }
    return null;
  },

  _renderListItem(item) {
    const aux1 = this.getAux1(item);
    const aux2 = this.getAux2(item);

    return (
      <div>
        {this.getLabel(item)}
        {aux1 || aux2 ? (
          <div className="aux">{`${aux1 || ""} ${aux2 || ""}`.trim()}</div>
        ) : null}
      </div>
    );
  },

  render() {
    let {
      items,
      visible,
      maxHeight,
      itemRender,
      disableSelect,
      disableFocusOnSelect,
    } = this.props;
    const { maxItemsToShow, activeItemIndex } = this.state;

    const numHidden = this._getNumHiddenItems();

    if (numHidden) {
      items = items.slice(0, maxItemsToShow);
    }

    if (items.isEmpty()) {
      return null;
    }

    let style;
    if (maxHeight) {
      style = { maxHeight };
    }

    return (
      <div className={cx("selectable-list", { open: visible })} style={style}>
        <ul
          onKeyDown={this._handleKeyDown}
          ref={this.listRef}
          onBlur={this._handleBlur}
        >
          {items.map((item, i) => {
            return (
              <li
                key={i}
                onClick={this._handleSelect.bind(this, i)}
                style={{ cursor: disableSelect ? "auto" : "pointer" }}
                className={cx({
                  active: item.selected || i === activeItemIndex,
                })}
                tabIndex={
                  disableFocusOnSelect ? null : "-1"
                } /* tabIndex makes it so it can be focused */
              >
                {itemRender ? itemRender(item) : this._renderListItem(item)}
              </li>
            );
          })}
          {numHidden && numHidden < 10000 ? (
            <li
              tabIndex="-1"
              onClick={this._handleShowMore}
              className={cx("action-item", {
                active: activeItemIndex === items.size,
              })}
            >
              {`Show ${numHidden} More`}
            </li>
          ) : null}
        </ul>
      </div>
    );
  },
});

export default SelectableList;
