import React, { useMemo, useState } from "react";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { GameLineupBreakdown } from "../../../shared/routers/GameRouter";
import {
  decFormat2,
  minutesFormat,
  plusMinusFormat2,
  plusMinusIntFormat,
  makePlusMinus,
} from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { groupBy } from "../../../shared/util/Collections";
import { sum, sumFromField, weightedAverage } from "../../util/Util";
import AppContext from "../../../shared/AppContext";
import { PlayerTableCell } from "../core/TableCell";

interface GameLineupSummary {
  minutes: number;
  p1: number;
  p2: number;
  p3: number;
  p4: number;
  p5: number;
  player1: string;
  player2: string;
  player3: string;
  player4: string;
  player5: string;
  offPoints: number | null;
  offPPP: number | null;
  defPoints: number | null;
  defPPP: number | null;
  numPossessions: number | null;
  predOE: number | null;
  predDE: number | null;
  offxPPP: number | null;
  defxPPP: number | null;
}

const columnHelper = createColumnHelper<GameLineupSummary>();

export function GameLineupsSummary(props: {
  data: GameLineupBreakdown[];
  hideXPts?: boolean;
}) {
  const { data, hideXPts } = props;
  const [sorting, setSorting] = useState<SortingState>();
  const firstData = data[0];

  const shouldShowPredColumns = firstData
    ? firstData.season === parseInt(AppContext.currentSeason)
    : true;

  const summaryData: GameLineupSummary[] = useMemo(() => {
    const rowsByLineup = groupBy(data, (l) =>
      [l.p1, l.p2, l.p3, l.p4, l.p5].sort().join("-")
    );
    return (
      Object.values(rowsByLineup)
        .map((l: GameLineupBreakdown[]) => {
          const numOffPoss = sumFromField("offPossessions", l) || 0;
          const numDefPoss = sumFromField("defPossessions", l) || 0;

          const offPts = sumFromField("offPoints", l) || 0;
          const defPts = sumFromField("defPoints", l) || 0;

          const firstRow = l[0];

          if (firstRow === undefined) return undefined;

          const xPPP =
            weightedAverage(
              "offPossessions",
              "xPPP",
              l.filter((l) => l.xPPP !== null)
            ) || null;
          const xPPPDef =
            weightedAverage(
              "defPossessions",
              "xPPPDef",
              l.filter((l) => l.xPPPDef !== null)
            ) || null;

          return {
            p1: firstRow.p1,
            p2: firstRow.p2,
            p3: firstRow.p3,
            p4: firstRow.p4,
            p5: firstRow.p5,
            player1: firstRow.player1,
            player2: firstRow.player2,
            player3: firstRow.player3,
            player4: firstRow.player4,
            player5: firstRow.player5,
            minutes: sum(
              (l: GameLineupBreakdown) => (l.timein - l.timeout) / 60,
              l
            ),
            offPoints: sumFromField("offPoints", l),
            defPoints: sumFromField("defPoints", l),
            offPPP: numOffPoss > 0 ? offPts / numOffPoss : null,
            defPPP: numDefPoss > 0 ? defPts / numDefPoss : null,
            numPossessions: (numOffPoss + numDefPoss) / 2,
            predOE: firstRow.predOE,
            predDE: firstRow.predDE,
            offxPPP: xPPP,
            defxPPP: xPPPDef,
            season: firstRow.season,
          };
        })
        .filter((d) => d !== undefined) as GameLineupSummary[]
    ).sort((a, b) => (b.numPossessions || 0) - (a.numPossessions || 0));
  }, [data]);

  const columns = useMemo(() => {
    let g = 0;
    return [
      columnHelper.accessor("p1", {
        header: () => "Player 1",
        cell: (info) => (
          <PlayerTableCell
            id={info.getValue()}
            name={info.row.original.player1}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("p2", {
        header: () => "Player 2",
        cell: (info) => (
          <PlayerTableCell
            id={info.getValue()}
            name={info.row.original.player2}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("p3", {
        header: () => "Player 3",
        cell: (info) => (
          <PlayerTableCell
            id={info.getValue()}
            name={info.row.original.player3}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("p4", {
        header: () => "Player 4",
        cell: (info) => (
          <PlayerTableCell
            id={info.getValue()}
            name={info.row.original.player4}
          />
        ),
        meta: { group: g },
      }),
      columnHelper.accessor("p5", {
        header: () => "Player 5",
        cell: (info) => (
          <PlayerTableCell
            id={info.getValue()}
            name={info.row.original.player5}
          />
        ),
        meta: { group: g++ },
      }),
      columnHelper.accessor("numPossessions", {
        header: () => "# Poss",
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [0, 20],
          neutralColorScheme: true,
        },
      }),
      columnHelper.accessor("minutes", {
        header: () => "Min",
        cell: (info) => minutesFormat(info.getValue()),
        meta: {
          group: g,
          highlights: Highlights.Max,
          colorDomain: [0, 20],
          neutralColorScheme: true,
        },
      }),
      columnHelper.accessor(
        (row) =>
          row.offPoints === null || row.defPoints === null
            ? null
            : row.offPoints - row.defPoints,
        {
          id: "plusMinus",
          header: () => "+/-",
          cell: (info) => (
            <span title={plusMinusFormat2(info.getValue())}>
              {plusMinusIntFormat(info.getValue())}
            </span>
          ),
          meta: {
            group: g++,
            highlights: Highlights.Max,
            colorDomain: [-10, 10],
          },
        }
      ),
      columnHelper.group({
        id: "ppp",
        meta: { group: g },
        header: "PPP",
        columns: [
          columnHelper.accessor("offPPP", {
            header: () => "Off",
            cell: (info) => decFormat2(info.getValue()),
            meta: { group: g, highlights: Highlights.Max, colorDomain: [0, 2] },
          }),
          columnHelper.accessor("defPPP", {
            header: () => "Def",
            cell: (info) => decFormat2(info.getValue()),
            meta: { group: g, highlights: Highlights.Min, colorDomain: [2, 0] },
          }),
          columnHelper.accessor(
            (row) =>
              row.offPPP === null || row.defPPP === null
                ? null
                : row.offPPP - row.defPPP,
            {
              id: "PPPNet",
              header: () => "Net",
              cell: (info) => makePlusMinus(decFormat2)(info.getValue()),
              meta: {
                group: g++,
                highlights: Highlights.Max,
                colorDomain: [-0.1, 0.1],
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "xPPP",
        meta: { group: g },
        header: "xPPP",
        columns: [
          columnHelper.accessor("offxPPP", {
            header: () => "Off",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: [1.04, 1.2],
            },
          }),
          columnHelper.accessor("defxPPP", {
            header: () => "Def",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: [1.2, 1.04],
            },
          }),
          columnHelper.accessor(
            (row) =>
              row.offxPPP === null || row.defxPPP === null
                ? null
                : row.offxPPP - row.defxPPP,
            {
              id: "xPPPNet",
              header: () => "Net",
              cell: (info) => makePlusMinus(decFormat2)(info.getValue()),
              meta: {
                group: g++,
                highlights: Highlights.Max,
                colorDomain: [-0.1, 0.1],
              },
            }
          ),
        ],
      }),
      columnHelper.group({
        id: "predPPP",
        meta: { group: g },
        header: "Predicted",
        columns: [
          columnHelper.accessor("predOE", {
            header: () => "Off",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Max,
              colorDomain: [1.04, 1.2],
            },
          }),
          columnHelper.accessor("predDE", {
            header: () => "Def",
            cell: (info) => decFormat2(info.getValue()),
            meta: {
              group: g,
              highlights: Highlights.Min,
              colorDomain: [1.2, 1.04],
            },
          }),
          columnHelper.accessor(
            (row) =>
              row.predOE === null || row.predDE === null
                ? null
                : row.predOE - row.predDE,
            {
              id: "predNet",
              header: () => "Net",
              cell: (info) => makePlusMinus(decFormat2)(info.getValue()),
              meta: {
                group: g,
                highlights: Highlights.Max,
                colorDomain: [-0.1, 0.1],
              },
            }
          ),
        ],
      }),
    ];
  }, []);

  const hiddenColumns = {
    predOE: shouldShowPredColumns,
    predDE: shouldShowPredColumns,
    predNet: shouldShowPredColumns,
    offxPPP: !hideXPts,
    defxPPP: !hideXPts,
    xPPPNet: !hideXPts,
  };

  return (
    <div
      style={{
        textAlign: "right",
      }}
    >
      <Table
        data={summaryData}
        columns={columns}
        hiddenColumns={hiddenColumns}
        autoWidth={true}
        showColorOnHover={true}
        sorting={sorting}
        setSorting={setSorting}
        disableStickyColumn={true}
      />
    </div>
  );
}
