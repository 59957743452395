import React, { useState, useMemo } from "react";
import { Text } from "@visx/text";
import { Bar, Line } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { AxisLeft } from "@visx/axis";
import { ParentSize } from "@visx/responsive";
import { defaultStyles } from "@visx/tooltip";

import {
  GameLineup,
  GamePlayer,
  GamePossession,
} from "../../../shared/routers/GameRouter";
import { gameClockFormat, period } from "../../util/Format";
import { getContrastingTeamColor } from "../../util/Colors";

const playerNameRowHeight = 20;
const playerNameWidth = 120;
const dashLineColor = "gray";
const lineColor = "#eee";
const highlightColor = "#54d6bc";

export function GameFlowPanel(props: {
  home: { teamid: number; teamCity: string; teamabbreviation: string };
  away: { teamid: number; teamCity: string; teamabbreviation: string };
  lineups: GameLineup[];
  players: GamePlayer[];
  possessions: GamePossession[];
}) {
  const { home, away, lineups, players, possessions } = props;

  // This data viz doesn't make sense for series page when multiple games are
  // selected so hide it if we have multiple game ids present.
  const hideGameFlowChart = new Set(possessions.map((p) => p.gameId)).size > 1;

  return (
    <div>
      {hideGameFlowChart ? (
        <div>
          To see game flow visualization select a single game from the series.
        </div>
      ) : (
        <ParentSize>
          {({ width }) => (
            <GameFlowChart
              width={width}
              home={home}
              away={away}
              players={players}
              possessions={possessions}
              lineups={lineups}
            />
          )}
        </ParentSize>
      )}
    </div>
  );
}

function GameFlowChart(props: {
  width: number;
  home: { teamid: number; teamCity: string; teamabbreviation: string };
  away: { teamid: number; teamCity: string; teamabbreviation: string };
  players: GamePlayer[];
  possessions: GamePossession[];
  lineups: GameLineup[];
}) {
  const { width, home, away, players, possessions, lineups } = props;
  const [hoveredPlayerId, setHoveredPlayerId] = useState<number>();
  const [hoveredPossIdx, setHoveredPossIdx] = useState<number>();

  const homeColors = getContrastingTeamColor(home.teamid, away.teamid);
  const awayColors = getContrastingTeamColor(away.teamid, home.teamid);

  const margin = { left: 20, top: 5, right: 0, bottom: 5 };
  const innerWidth = width - playerNameWidth;
  const height = 164;
  const innerHeight = height - margin.top - margin.bottom;

  const barWidth = Math.floor(innerWidth / possessions.length);

  const data = possessions.map((p) => p.homeScore - p.awayScore);
  const ticks = new Set([-10, -5, 0, 5, 10]);
  const max = Math.ceil(Math.max(...data, 10) / 5) * 5;
  const min = Math.floor(Math.min(...data, -10) / 5) * 5;
  for (let i = min; i < max; i += 5) {
    ticks.add(i);
  }
  const pointDiffTickValues = Array.from(ticks)
    .filter((t) => (ticks.size > 8 ? t % 10 === 0 : true))
    .sort();

  const yScale = useMemo(() => {
    return scaleLinear<number>({
      range: [innerHeight, 0],
      round: true,
      domain: [min, max],
    });
  }, [innerHeight, max, min]);

  const playerPossMap: Set<number>[] = useMemo(() => {
    if (!players || !possessions || !lineups) return [] as Set<number>[];

    const lineupMap: Record<number, Record<number, number[]>> = {};
    lineupMap[home.teamid] = {};
    lineupMap[away.teamid] = {};

    const homeLineups = lineups.filter((l) => l.teamid === home.teamid);
    const awayLineups = lineups.filter((l) => l.teamid === away.teamid);

    for (const l of homeLineups) {
      const homeLineups = lineupMap[home.teamid];
      if (homeLineups) {
        homeLineups[l.lineupid] = [
          l.playerId1,
          l.playerId2,
          l.playerId3,
          l.playerId4,
          l.playerId5,
        ];
      }
    }

    for (const l of awayLineups) {
      const awayLineups = lineupMap[away.teamid];
      if (awayLineups) {
        awayLineups[l.lineupid] = [
          l.playerId1,
          l.playerId2,
          l.playerId3,
          l.playerId4,
          l.playerId5,
        ];
      }
    }

    const playerPossMap: Set<number>[] = [];
    for (let i = 0; i < possessions.length; i++) {
      const p = possessions[i];
      if (p === undefined) continue;
      const offLineups = lineupMap[p.OTeamId];
      const defLineups = lineupMap[p.DTeamId];
      if (offLineups === undefined || defLineups === undefined) continue;
      const off = offLineups[p.olineupId] || [];
      const def = defLineups[p.dlineupId] || [];
      const setAtPoss = new Set(off.concat(def));
      playerPossMap.push(setAtPoss);
    }
    return playerPossMap;
  }, [players, possessions, lineups, home.teamid, away.teamid]);

  // For each player a set of players they played with.
  const playedWithMap: Record<number, Set<number>> = useMemo(() => {
    const retObj: Record<number, Set<number>> = {};
    if (playerPossMap.length === 0 || players === undefined) return {};

    for (const player of players) {
      retObj[player.playerId] = new Set<number>();
      const playedPoss = playerPossMap.filter((pp) => pp.has(player.playerId));
      for (const poss of playedPoss) {
        for (const p of poss) {
          const retObjForPlayer = retObj[player.playerId];
          if (retObjForPlayer) {
            retObjForPlayer.add(p);
          }
        }
      }
    }
    return retObj;
  }, [playerPossMap, players]);

  const homePlayers = players.filter((p) => p.teamid === home.teamid);
  const awayPlayers = players.filter((p) => p.teamid === away.teamid);

  const hoveredPossession =
    hoveredPossIdx === undefined ? undefined : possessions[hoveredPossIdx];

  return (
    <div>
      <GameFlowTeamSubs
        playerPossMap={playerPossMap}
        playedWithMap={playedWithMap}
        possessions={possessions}
        players={homePlayers}
        teamCity={home.teamCity}
        width={width}
        marginTop={margin.top}
        barWidth={barWidth}
        hoveredPlayerId={hoveredPlayerId}
        setHoveredPlayerId={setHoveredPlayerId}
        hoveredPossIdx={hoveredPossIdx}
        home={true}
        colors={homeColors}
      />
      <div
        style={{ position: "relative" }}
        onMouseLeave={() => setHoveredPossIdx(undefined)}
      >
        <svg width={width} height={height}>
          <Group left={playerNameWidth} top={margin.top}>
            {possessions.map((p, i) => {
              const possession = possessions[i];
              const lastPossession = possessions[i - 1];

              if (possession === undefined) return null;
              const isBarUp = p.homeScore - p.awayScore >= 0;
              // Reserve 2px for the secondary color of scoring team.
              const secondaryBufferSize = 2;

              const zero = yScale(0);
              const barX = i * barWidth;
              const barStart = zero;
              const barEnd = yScale(p.homeScore - p.awayScore);
              const barY =
                Math.min(barStart, barEnd) +
                ((isBarUp ? -1 : 1) * secondaryBufferSize) / 2;
              const barHeight = Math.abs(barEnd - barStart);

              const playerPossMapEntry = playerPossMap[i];

              if (!playerPossMapEntry) return null;

              const hoveredPlayerOff =
                hoveredPlayerId && !playerPossMapEntry.has(hoveredPlayerId);

              const isHighlighted = hoveredPossIdx === i;

              const drawPeriodLine =
                i > 0 &&
                possession.Period != (lastPossession && lastPossession.Period);

              const colors =
                p.OTeamId === home.teamid ? homeColors : awayColors;

              return (
                <Group
                  key={i}
                  opacity={hoveredPlayerOff ? 0.3 : ""}
                  onMouseEnter={() => setHoveredPossIdx(i)}
                >
                  {drawPeriodLine && (
                    <Line
                      stroke={lineColor}
                      from={{
                        x: barX,
                        y: 0 - margin.top,
                      }}
                      to={{
                        x: barX,
                        y: height + margin.bottom,
                      }}
                    />
                  )}
                  {(hoveredPlayerId === p.playerId || hoveredPossIdx === i) && (
                    <Line
                      stroke={dashLineColor}
                      strokeDasharray="3 3"
                      from={{
                        x: barX + barWidth / 2,
                        y: p.OTeamId === home.teamid ? 0 - margin.top : zero,
                      }}
                      to={{
                        x: barX + barWidth / 2,
                        y:
                          p.OTeamId === home.teamid
                            ? zero
                            : height + margin.bottom,
                      }}
                    />
                  )}
                  <Bar
                    x={barX}
                    y={barY}
                    width={barWidth}
                    height={barHeight}
                    fill={isHighlighted ? highlightColor : colors.primary}
                  />
                  <Bar
                    x={barX}
                    y={zero - secondaryBufferSize / 2}
                    width={barWidth}
                    height={secondaryBufferSize}
                    fill={isHighlighted ? highlightColor : colors.secondary}
                  />
                </Group>
              );
            })}
            <AxisLeft
              scale={yScale}
              label={"Point Differential"}
              labelProps={{ fontSize: "12", textAnchor: "middle" }}
              tickFormat={(val) =>
                (val as number) > 0 ? `+${val}` : val.toString()
              }
              tickValues={pointDiffTickValues}
              hideAxisLine={true}
            />
          </Group>
        </svg>
        {hoveredPossession !== undefined && hoveredPossIdx !== undefined && (
          <div
            style={{
              top: height,
              left: hoveredPossIdx * barWidth + playerNameWidth,
              position: "absolute",
              ...defaultStyles,
            }}
          >
            <div>
              <b>
                {`${hoveredPossession.action} by ${hoveredPossession.player}`}
              </b>
            </div>
            <div>
              {`${period(hoveredPossession.Period)} ${gameClockFormat(
                hoveredPossession.time
              )}`}
            </div>
            <div>
              <span
                style={{
                  fontWeight:
                    hoveredPossession.OTeamId === home.teamid ? "bold" : "",
                }}
              >{`${home.teamabbreviation} ${hoveredPossession.homeScore}`}</span>
              {" - "}
              <span
                style={{
                  fontWeight:
                    hoveredPossession.OTeamId === away.teamid ? "bold" : "",
                }}
              >{`${hoveredPossession.awayScore} ${away.teamabbreviation}`}</span>
            </div>
          </div>
        )}
      </div>
      <GameFlowTeamSubs
        playerPossMap={playerPossMap}
        playedWithMap={playedWithMap}
        possessions={possessions}
        players={awayPlayers}
        teamCity={away.teamCity}
        width={width}
        marginTop={margin.top}
        barWidth={barWidth}
        hoveredPlayerId={hoveredPlayerId}
        setHoveredPlayerId={setHoveredPlayerId}
        hoveredPossIdx={hoveredPossIdx}
        home={false}
        colors={awayColors}
      />
    </div>
  );
}

function GameFlowTeamSubs(props: {
  playerPossMap: Set<number>[];
  playedWithMap: Record<number, Set<number>>;
  possessions: GamePossession[];
  players: GamePlayer[];
  teamCity: string;
  width: number;
  marginTop: number;
  barWidth: number;
  hoveredPlayerId: number | undefined;
  setHoveredPlayerId: (id: number | undefined) => void;
  hoveredPossIdx: number | undefined;
  home: boolean;
  colors: { primary: string; secondary: string };
}) {
  const {
    playerPossMap,
    playedWithMap,
    possessions,
    players,
    teamCity,
    width,
    marginTop,
    barWidth,
    hoveredPlayerId,
    setHoveredPlayerId,
    hoveredPossIdx,
    home,
    colors,
  } = props;

  const height = playerNameRowHeight * (players.length + 1);

  return (
    <div onMouseLeave={() => setHoveredPlayerId(undefined)}>
      <svg width={width} height={height}>
        <Group top={marginTop}>
          <Text dx={0} verticalAnchor={"start"} style={{ fontWeight: "bold" }}>
            {teamCity}
          </Text>
          {players.map((p, i) => {
            let opacity = 1;
            const playerPossMapEntryHovered =
              hoveredPossIdx === undefined
                ? undefined
                : playerPossMap[hoveredPossIdx];
            if (
              playerPossMapEntryHovered &&
              !playerPossMapEntryHovered.has(p.playerId)
            ) {
              opacity = 0.3;
            }
            const playedWithMapHovered =
              hoveredPlayerId === undefined
                ? undefined
                : playedWithMap[hoveredPlayerId];
            let textOpacity = opacity;
            if (playedWithMapHovered && playedWithMapHovered.has(p.playerId)) {
              textOpacity = 1;
            } else if (
              playedWithMapHovered &&
              !playedWithMapHovered.has(p.playerId)
            ) {
              textOpacity = 0.3;
            }

            return (
              <Group
                key={i}
                top={(i + 1) * playerNameRowHeight}
                onMouseEnter={() => setHoveredPlayerId(p.playerId)}
              >
                <Text
                  dx={0}
                  verticalAnchor={"start"}
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontWeight: hoveredPlayerId === p.playerId ? "bold" : "",
                    width: playerNameWidth,
                    opacity: textOpacity,
                  }}
                >
                  {p.name}
                </Text>
                {possessions.map((_, i) => {
                  const playerPossMapEntry = playerPossMap[i];
                  const possession = possessions[i];
                  if (!playerPossMapEntry || !possession) return null;
                  const isOn = playerPossMapEntry.has(p.playerId);
                  const isShooter = possession.playerId === p.playerId;
                  const circleRadius = barWidth / 4;
                  let color = "white";
                  if (isOn && hoveredPossIdx === i) {
                    color = highlightColor;
                  } else if (isOn) {
                    color = colors.primary;
                  }

                  let opacity = 0;
                  const playerPossMapEntryHovered =
                    hoveredPossIdx === undefined
                      ? undefined
                      : playerPossMap[hoveredPossIdx];
                  if (
                    (hoveredPlayerId && hoveredPlayerId !== p.playerId) ||
                    (playerPossMapEntryHovered &&
                      !playerPossMapEntryHovered.has(p.playerId))
                  ) {
                    opacity = 0.3;
                  } else if (isOn) {
                    opacity = 1;
                  }

                  const prevPossession = possessions[i - 1];
                  if (possession === undefined) return null;
                  const drawPeriodLine =
                    prevPossession &&
                    possession.Period != prevPossession.Period;

                  return (
                    <Group key={i}>
                      {drawPeriodLine && (
                        <Line
                          stroke={lineColor}
                          from={{
                            x: playerNameWidth + i * barWidth,
                            y: -playerNameRowHeight - marginTop,
                          }}
                          to={{
                            x: playerNameWidth + i * barWidth,
                            y: players.length * playerNameRowHeight,
                          }}
                        />
                      )}
                      <rect
                        opacity={opacity}
                        x={playerNameWidth + i * barWidth}
                        y={0}
                        width={barWidth}
                        height={playerNameRowHeight / 2}
                        fill={color}
                      />
                      {isShooter && (
                        <circle
                          opacity={opacity}
                          cx={playerNameWidth + i * barWidth + barWidth / 2}
                          cy={playerNameRowHeight / 4}
                          r={circleRadius}
                          fill="rgba(255,255,255,0.5)"
                        ></circle>
                      )}
                      {isShooter &&
                        (hoveredPlayerId === p.playerId ||
                          hoveredPossIdx === i) && (
                          <Line
                            opacity={opacity}
                            stroke={dashLineColor}
                            strokeDasharray="3 3"
                            from={{
                              x: playerNameWidth + i * barWidth + barWidth / 2,
                              y: home ? playerNameRowHeight / 2 : 0,
                            }}
                            to={{
                              x: playerNameWidth + i * barWidth + barWidth / 2,
                              y: home ? height : -height,
                            }}
                          />
                        )}
                    </Group>
                  );
                })}
              </Group>
            );
          })}
        </Group>
      </svg>
    </div>
  );
}
