import React from "react";
import CreateReactClass from "create-react-class";

import "./Help.css";

export default CreateReactClass({
  render() {
    return (
      <div
        className="help-dialog"
        style={{ display: this.props.visible ? "block" : "none" }}
      >
        <h4>Keyboard Shortcuts</h4>
        <table className="kbd-table">
          <tbody>
            <tr>
              <td>
                <kbd>/</kbd>
              </td>
              <td>Focus on search bar</td>
            </tr>
            <tr>
              <td>
                <kbd>?</kbd>
              </td>
              <td>Show keyboard shortcuts</td>
            </tr>
            <tr>
              <td>
                <kbd>[</kbd>
              </td>
              <td>Go to previous page in context</td>
            </tr>
            <tr>
              <td>
                <kbd>]</kbd>
              </td>
              <td>Go to next page in context</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  },
});
