import React, { useState, useMemo } from "react";
import { Col, Row } from "react-bootstrap";

import { SortingState, Table, createColumnHelper } from "../core/Table";
import { PlayerSkillBreakdown } from "../../../shared/routers/ImpactRouter";
import { dec100Format } from "../../util/Format";
import { groupBy } from "../../../shared/util/Collections";

const columnHelper = createColumnHelper<PlayerSkillBreakdown>();

export function PlayerSkillBreakdownTables(props: {
  data: PlayerSkillBreakdown[];
}) {
  const { data } = props;
  const [offSorting, setOffSorting] = useState<SortingState>();
  const [defSorting, setDefSorting] = useState<SortingState>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("skillCategory", {
        header: "Category",
        cell: (info) => (info.row.depth === 0 ? info.getValue() : ""),
        meta: { textAlign: "left" },
      }),
      columnHelper.accessor("skill", {
        header: "Skill",
        cell: (info) => info.getValue(),
        meta: { textAlign: "left" },
      }),
      columnHelper.accessor("value", {
        header: "Impact",
        cell: (info) => {
          const value = dec100Format(info.getValue());
          return info.row.depth === 0 ? <b>{value}</b> : value;
        },
      }),
    ],
    []
  );

  return (
    <div>
      <Row>
        {["Offense", "Defense"].map((role) => {
          const dataForRole = data.filter((d) =>
            role === "Offense" ? d.offense : !d.offense
          );
          if (!dataForRole) return null;

          const groupedData = Object.entries(
            groupBy(dataForRole, (d) => d.skillCategory)
          ).map(([skillCategory, rows]) => {
            const totalImpact = rows.reduce((acc, val) => acc + val.value, 0);
            return {
              offense: role === "Offense" ? true : false,
              skillCategory: skillCategory,
              skill: `Total ${skillCategory}`,
              value: totalImpact,
              subRows: rows.sort((a, b) => b.value - a.value),
            };
          });

          return (
            <Col key={role}>
              <div style={{ textAlign: "center" }}>
                <b>{role}</b>
              </div>
              <Table
                data={groupedData.sort((a, b) => b.value - a.value)}
                columns={columns}
                showRowIndex={false}
                sorting={role === "Offense" ? offSorting : defSorting}
                setSorting={role === "Offense" ? setOffSorting : setDefSorting}
                expandColumnId="skillCategory"
                disableStickyColumn={true}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
