import React, { useState, useMemo } from "react";
import { ParentSize } from "@visx/responsive";
import { LinePath, Line, Stack, Polygon } from "@visx/shape";
import { scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { Text } from "@visx/text";
import { localPoint } from "@visx/event";
import { extent } from "d3";

import { LinearGradient } from "../chart/LinearGradient";
import { PlayerShotChart1d } from "../../../shared/routers/PlayerRouter";
import { optionB, viridis } from "../../constants/ChartConstants";
import { generateDomain } from "../../util/Util";
import { decFormat2 } from "../../util/Format";
import { EventType } from "@visx/event/lib/types";

export function ShotChart1D(props: {
  data: PlayerShotChart1d[];
  isSynergy: boolean;
  league: PlayerShotChart1d[];
  rug: { d: number; n: number }[];
}) {
  const { data, isSynergy, rug } = props;

  // If it is synergy then instead of using the league data from the API call
  // calculate it using the synergy data.
  const league = !isSynergy
    ? props.league
    : data.map((d) => {
        return {
          d: d.d,
          pps: d.leaguePPS,
          leaguePPS: d.leaguePPS,
          corner: d.corner,
          pt3: d.pt3,
          n: d.n,
        };
      });

  return (
    <ParentSize parentSizeStyles={{ width: "100%" }}>
      {(parent) => (
        <ShotChartInner
          height={parent.width}
          width={parent.width}
          data={data}
          isSynergy={isSynergy}
          league={league}
          rug={rug}
        />
      )}
    </ParentSize>
  );
}

function ShotChartInner(props: {
  height: number;
  width: number;
  data: PlayerShotChart1d[];
  isSynergy: boolean;
  league: PlayerShotChart1d[];
  rug: { d: number; n: number }[];
}) {
  const { height, width, data, isSynergy, league, rug } = props;
  const [highlight, setHighlight] = useState<{ x: number; y: number[] }>();

  const margin = { top: 5, bottom: 30, left: 30, right: 5 };
  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;

  const maxRugVal = Math.max(...rug.map((r) => r.n));
  const rugValMap = useMemo(() => {
    const obj: Record<number, number> = {};
    for (const r of rug) {
      obj[r.d] = r.n;
    }
    return obj;
  }, [rug]);

  const xScale = scaleLinear<number>({
    range: [0, innerWidth],
    domain: [0, 30],
  });

  const yScale = scaleLinear<number>({
    range: [innerHeight, 0],
    domain: [0.5, 1.8],
  });

  // The reverse of xScale, maps a screen coords to distance.
  const revXScale = scaleLinear<number>({
    domain: [0, innerWidth],
    range: [0, 30],
  });

  const ppsMap = useMemo(() => {
    const obj: Record<number, number[]> = {};
    for (const d of data) {
      const objAtD = obj[d.d];
      if (!objAtD) {
        obj[d.d] = [d.pps];
      } else {
        objAtD.push(d.pps);
      }
    }
    return obj;
  }, [data]);

  const handleMouseMove = (event: EventType) => {
    if (event.target === null) {
      setHighlight(undefined);
      return;
    }
    const coords = localPoint(event.target as Element, event);
    if (coords) {
      const nearestDist = Math.round(revXScale(coords.x - margin.left) * 5) / 5;
      setHighlight({ x: nearestDist, y: ppsMap[nearestDist] || [] });
    } else {
      setHighlight(undefined);
    }
  };

  const twoPointers = data.filter((l) => !l.pt3);
  const cornerThrees = data.filter((l) => l.pt3 && l.corner);
  const atbThrees = data.filter((l) => l.pt3 && !l.corner);

  const leagueTwoPointers = league.filter((l) => !l.pt3);
  const leagueCornerThrees = league.filter((l) => l.pt3 && l.corner);
  const leagueATBThrees = league.filter((l) => l.pt3 && !l.corner);

  // Scale for the width of the stack.
  const minWidth = 0;
  const maxWidth = (height - margin.top - margin.bottom) / 4;
  const widthScale = scaleLinear<number>({
    domain: [0, 20],
    range: [minWidth, maxWidth],
  });

  const colorDomain = [0.61, 1.45];
  const scheme = isSynergy ? viridis : optionB;

  // For gradients, offset needs % - so map x to 0-100%.
  const colorData = [
    generateGradientColorData(twoPointers, [0, 23.75], colorDomain, scheme),
    generateGradientColorData(cornerThrees, [22, 23.75], colorDomain, scheme),
    generateGradientColorData(atbThrees, [23.75, 30], colorDomain, scheme),
  ];

  // Simple color scale for drawing the legend.
  const color = scaleLinear()
    .domain(
      generateDomain(colorDomain[0] || 0, colorDomain[1] || 0, scheme.length)
    )
    .range(scheme)
    .clamp(true);

  const legendColorData: string[] = [];
  const [yDomainMin, yDomainMax] = extent<number>(yScale.domain()) as number[];

  // Generate the color data for many points in the y domain.
  // 30 is good enough to approximate the actual scheme with linear
  // interpolation.
  const numPoints = 30;
  for (let i = 0; i < numPoints; i++) {
    const colorY =
      (yDomainMin || 0) +
      (i / numPoints) * ((yDomainMax || 0) - (yDomainMin || 0));
    legendColorData.push(color(colorY) as string);
  }
  const gradientId = "axisGradient";

  if (width === 0 || height === 0) return null;

  // Create an array from 0 - 40 in increments of 0.1 to iterate over.
  const rugDists = Array.from({ length: 401 }, (_, i) => i / 10);

  return (
    <svg
      height={height}
      width={width}
      onMouseMoveCapture={handleMouseMove}
      onMouseOut={() => setHighlight(undefined)}
    >
      <Group
        left={margin.left}
        top={margin.top}
        height={height - margin.top - margin.bottom}
      >
        <AxisLeft
          hideAxisLine={true}
          label="PPS"
          labelOffset={16}
          scale={yScale}
          tickValues={[0.6, 0.8, 1, 1.2, 1.4, 1.6]}
          tickLength={4}
          tickStroke={"#999"}
        />
        <rect
          x={0}
          y={0}
          width={3}
          height={innerHeight}
          fill={`url(#${gradientId})`}
        />
        <LinearGradient
          id={gradientId}
          colorData={legendColorData}
          orientation="vertical"
          reverse={true}
        />
        <AxisBottom
          hideAxisLine={true}
          label="Distance"
          labelOffset={4}
          tickStroke={"#999"}
          scale={xScale}
          top={innerHeight}
          tickValues={[0, 5, 10, 15, 20, 25, 30]}
          tickLength={4}
        />
        {[twoPointers, cornerThrees, atbThrees].map((s, i) => (
          <Group key={i}>
            <LinearGradient
              id={`gradient-${i}`}
              colorData={(colorData[i] || []).map((c) => c.stopColor)}
              stopData={(colorData[i] || []).map((c) => c.offset)}
              orientation="horizontal"
              reverse={false}
            />
            <Stack
              data={s}
              keys={[1]}
              x={(d) => xScale(d.data.d)}
              y0={(d) => yScale(d.data.pps) - widthScale(d.data.n)}
              y1={(d) => yScale(d.data.pps) + widthScale(d.data.n)}
              style={{ fill: `url("#gradient-${i}")` }}
            />
            <LinePath
              data={s}
              x={(d) => xScale(d.d)}
              y={(d) => yScale(d.pps)}
              stroke="#fff"
              strokeWidth={1}
              strokeOpacity={1}
            />
          </Group>
        ))}
        {[leagueTwoPointers, leagueCornerThrees, leagueATBThrees].map(
          (s, i) => (
            <LinePath
              key={i}
              data={s}
              x={(d) => xScale(d.d)}
              y={(d) => yScale(d.pps)}
              stroke="#000"
              strokeWidth={1}
              strokeOpacity={0.4}
              strokeDasharray="1,2"
            />
          )
        )}
        {rugDists.map((r, i) => {
          // Calculate the opacity of the current point by looking at the point
          // in question and averaging with its neighbors to smooth things out.
          const neighbors = [
            -0.6, -0.5, -0.4, -0.3, -0.2, -0.1, 0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6,
          ];
          const avg =
            neighbors
              .map((n) => rugValMap[r + n] || 0)
              .reduce((a, b) => a + b, 0) / neighbors.length;
          return (
            <Line
              key={i}
              x1={xScale(r)}
              x2={xScale(r)}
              y1={innerHeight}
              y2={innerHeight - Math.ceil(Math.max(10, height / 30))}
              stroke={"#000000"}
              opacity={avg / maxRugVal}
              strokeWidth={(2 * innerWidth) / rugDists.length}
            />
          );
        })}
        {highlight && (
          <g>
            <Line
              from={{ x: xScale(highlight.x), y: margin.top }}
              to={{ x: xScale(highlight.x), y: innerHeight + margin.top }}
              stroke={"rgba(0,0,0,.15)"}
              pointerEvents="none"
            />
            <Polygon
              fill="white"
              points={[
                [xScale(highlight.x - 2), innerHeight],
                [xScale(highlight.x - 2), innerHeight + 20],
                [xScale(highlight.x + 2), innerHeight + 20],
                [xScale(highlight.x + 2), innerHeight],
              ]}
            />
            <Text
              x={xScale(highlight.x)}
              y={innerHeight + 16}
              textAnchor="middle"
              fontSize={10}
              style={{ fontWeight: 700 }}
            >
              {highlight.x}
            </Text>
            {highlight.y.map((e, j) => (
              <Group key={j}>
                <Line
                  from={{ x: 0, y: yScale(e) }}
                  to={{ x: innerWidth, y: yScale(e) }}
                  stroke={"rgba(0,0,0,.15)"}
                  pointerEvents="none"
                />
                <Polygon
                  fill="white"
                  points={[
                    [0, yScale(e) - 12],
                    [-margin.left, yScale(e) - 12],
                    [-margin.left, yScale(e) + 12],
                    [0, yScale(e) + 12],
                  ]}
                />
                <Text
                  x={-margin.left / 2}
                  y={yScale(e)}
                  textAnchor="middle"
                  verticalAnchor="middle"
                  fontSize={10}
                  style={{ fontWeight: 700 }}
                >
                  {decFormat2(e)}
                </Text>
              </Group>
            ))}
          </g>
        )}
      </Group>
    </svg>
  );
}

/**
 * Generates data points to be used in SVG linear gradients for each point in
 * `series` Maps `colorDomain` to `colorRange` for colors and uses `domain` to
 * generate offsets between 0% and 100% for each color stop.
 */
function generateGradientColorData(
  series: PlayerShotChart1d[],
  domain: number[],
  colorDomain: number[],
  colorRange: string[]
) {
  const colorScale = scaleLinear()
    .domain(
      generateDomain(
        colorDomain[0] || 0,
        colorDomain[1] || 0,
        colorRange.length
      )
    )
    .range(colorRange)
    .clamp(true);

  const stopColor = (d: PlayerShotChart1d) => colorScale(d.pps);

  const colorData: { offset: string; stopColor: string }[] = [];
  const offset = scaleLinear().domain(domain).range([0, 100]);

  for (let i = 0; series && i < series.length; i++) {
    const currData = series[i];
    if (currData) {
      colorData.push({
        offset: offset(currData.d) + "%",
        stopColor: stopColor(currData) + "",
      });
    }
  }

  return colorData;
}
