import React, { useState, useMemo, useContext } from "react";
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";

import { Table, createColumnHelper } from "../core/Table";
import { RugPlot } from "../chart/RugPlot";
import { decFormat2, pctFormat } from "../../util/Format";
import { LeagueShooting } from "../../../shared/routers/LeagueRouter";
import { sumFromField } from "../../util/Util";
import { Spinner } from "../core/Spinner";
import { TeamContext } from "../../TeamContext";
import { TeamShooting } from "../../../shared/routers/TeamRouter";

const columnHelper = createColumnHelper<LeagueShootingRow>();

export function TeamShootingTable(props: {
  teamId: number;
  statType: "OFF" | "DEF";
  leagueShooting?: LeagueShooting[];
  teamShooting?: TeamShooting[];
}) {
  const { teamId, statType, teamShooting, leagueShooting } = props;
  const [hoveredTeamId, setHoveredTeamId] = useState<number>();
  const teams = useContext(TeamContext).teams;
  const [expectedToggle, setExpectedToggle] = useState("xpps");
  const useExpected = expectedToggle === "xpps";

  const leagueShootingObj = useMemo(() => {
    if (!leagueShooting || !teamShooting) return {} as LeagueShootingObject;

    const rows = leagueShooting
      .filter((row) => row.teamId !== teamId)
      .concat(teamShooting);

    return rowsToObject(rows, statType);
  }, [leagueShooting, statType, teamId, teamShooting]);

  const hoveredTeamData = useMemo(() => {
    if (!hoveredTeamId || !leagueShootingObj) return undefined;
    return leagueShootingObj[hoveredTeamId.toString()];
  }, [hoveredTeamId, leagueShootingObj]);

  const hoveredTeamMsg = useMemo(() => {
    if (!hoveredTeamId) return "";
    const team = teams.find((t) => t.teamid === hoveredTeamId);
    if (!team) return "";
    return (
      <div>
        <span style={{ fontWeight: "normal" }}>Showing stats for </span>
        <span>{team.teamname}</span>
      </div>
    );
  }, [hoveredTeamId, teams]);

  const columns = useMemo(() => {
    const accessor = (row: LeagueShootingRow) =>
      useExpected ? row.xpps : row.pps;
    const rankAccessor = (row: LeagueShootingRow) =>
      useExpected ? row.rankxpps : row.rankpps;

    const maxPps = Math.max(
      ...Object.values(leagueShootingObj).map((x) =>
        Math.max(...[...Object.values(x).map(accessor)].map((v) => v || 0))
      )
    );
    const minPps = Math.min(
      ...Object.values(leagueShootingObj).map((x) =>
        Math.min(...[...Object.values(x).map(accessor)].map((v) => v || 0))
      )
    );
    const maxProp = Math.max(
      ...Object.values(leagueShootingObj).map((x) =>
        Math.max(...Object.values(x).map((y) => y.proportion))
      )
    );
    const minProp = Math.min(
      ...Object.values(leagueShootingObj).map((x) =>
        Math.min(...Object.values(x).map((y) => y.proportion))
      )
    );

    return [
      columnHelper.accessor("type", {
        header: () => "Shot",
        cell: (info) => (
          <b>{shootingLabels[info.getValue()] || info.getValue()}</b>
        ),
        meta: { group: 0, textAlign: "left" },
      }),
      columnHelper.accessor(useExpected ? "rankxpps" : "rankpps", {
        header: () => "",
        cell: (info) => {
          const hoveredTeamDataForType =
            hoveredTeamData && hoveredTeamData[info.row.original.type];
          const value = hoveredTeamDataForType
            ? rankAccessor(hoveredTeamDataForType)
            : info.getValue();
          return <div style={{ width: 30 }}>{value}</div>;
        },
        meta: { group: 1 },
      }),
      columnHelper.accessor(useExpected ? "xpps" : "pps", {
        header: () => (useExpected ? "xPPS" : "PPS"),
        cell: (info) => {
          const hoveredTeamDataForType =
            hoveredTeamData && hoveredTeamData[info.row.original.type];
          const value = hoveredTeamDataForType
            ? accessor(hoveredTeamDataForType)
            : info.getValue();
          return (
            <div style={{ opacity: 0.7, width: 30 }}>{decFormat2(value)}</div>
          );
        },
        meta: { group: 1 },
      }),
      columnHelper.display({
        id: "ppsChart",
        header: () => hoveredTeamMsg,
        cell: (info) => (
          <RugPlot
            teamId={teamId}
            domain={[minPps - 0.1, maxPps + 0.1]}
            colorDomain={[0.8, 1.2]}
            hoveredTeamId={hoveredTeamId}
            setHoveredTeamId={setHoveredTeamId}
            data={
              leagueShootingObj
                ? Object.values(leagueShootingObj).map((v) => {
                    const val = v[info.row.original.type];
                    return {
                      value: val ? accessor(val) : 0,
                      teamId: val ? val.teamId : 0,
                    };
                  })
                : []
            }
          />
        ),
        meta: { group: 1 },
      }),
      columnHelper.accessor("rankproportion", {
        header: () => "",
        cell: (info) => {
          const hoveredTeamDataForType =
            hoveredTeamData && hoveredTeamData[info.row.original.type];

          const value = hoveredTeamDataForType
            ? hoveredTeamDataForType.rankproportion
            : info.getValue();
          return <div style={{ width: 30 }}>{value}</div>;
        },
        meta: { group: 2 },
      }),
      columnHelper.accessor("proportion", {
        header: () => "Frequency",
        cell: (info) => {
          const hoveredTeamDataForType =
            hoveredTeamData && hoveredTeamData[info.row.original.type];

          const value = hoveredTeamDataForType
            ? hoveredTeamDataForType.proportion
            : info.getValue();
          return (
            <div style={{ opacity: 0.7, width: 30 }}>{pctFormat(value)}</div>
          );
        },
        meta: { group: 2 },
      }),
      columnHelper.display({
        id: "frequencyChart",
        header: () => hoveredTeamMsg,
        cell: (info) => (
          <RugPlot
            teamId={teamId}
            domain={[minProp - 0.01, maxProp + 0.01]}
            colorDomain={[0.1, 0.47]}
            hoveredTeamId={hoveredTeamId}
            setHoveredTeamId={setHoveredTeamId}
            data={
              leagueShootingObj
                ? Object.values(leagueShootingObj).map((v) => {
                    const val = v[info.row.original.type];
                    return {
                      value: val ? val.proportion : 0,
                      teamId: val ? val.teamId : 0,
                    };
                  })
                : []
            }
          />
        ),
        meta: { group: 2 },
      }),
    ];
  }, [
    leagueShootingObj,
    useExpected,
    hoveredTeamData,
    hoveredTeamMsg,
    teamId,
    hoveredTeamId,
  ]);

  if (!teamShooting || !leagueShooting) return <Spinner />;

  if (!leagueShootingObj[teamId])
    return (
      <div>
        No team shooting data exists that matches the set filters or this data
        is not currently available.
      </div>
    );

  const data = [
    "layup",
    "corner3pt",
    "aboveTheBreak3pt",
    "postAndFloaters",
    "midrange",
    "earlyClockLong2pt",
  ]
    .map((s) => {
      const teamData = leagueShootingObj[teamId];
      if (teamData) {
        return teamData[s];
      }
      return undefined;
    })
    .filter((d) => d !== undefined) as LeagueShootingRow[];

  return (
    <div>
      <Table data={data} columns={columns} autoWidth={true} />
      <div>
        <ToggleButtonGroup
          name="expected-points-shooting-toggle"
          type="radio"
          value={expectedToggle}
          onChange={setExpectedToggle}
        >
          <ToggleButton
            id="expected-points-shooting-toggle-xpps"
            value={"xpps"}
          >
            Expected Points
          </ToggleButton>
          <ToggleButton id="expected-points-shooting-toggle-pps" value={"pps"}>
            Actual Points
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
}

interface LeagueShootingRow {
  made: number;
  pps: number;
  xpps: number;
  proportion: number;
  rankpps: number;
  rankxpps: number;
  rankproportion: number;
  statType: "OFF" | "DEF";
  teamId: number;
  total: number;
  type: string;
}

type LeagueShootingObject = Record<string, Record<string, LeagueShootingRow>>;

const shootingLabels: Record<string, string> = {
  layup: "Layups",
  corner3pt: "Corner",
  aboveTheBreak3pt: "ATB",
  postAndFloaters: "Post/Float",
  midrange: "Midrange",
  earlyClockLong2pt: "ECL2",
};

function rowsToObject(
  rows: LeagueShooting[],
  statType: "OFF" | "DEF"
): LeagueShootingObject {
  const obj: LeagueShootingObject = {};
  const teamIds = [...new Set(rows.map((r) => r.teamId))];
  const shotTypes = Object.keys(shootingLabels).concat(
    "post",
    "floater",
    "jumper2pt",
    "earlyClockLong2pt"
  );

  for (const teamId of teamIds) {
    obj[teamId.toString()] = {};
    const rowsForTeam = rows.filter((r) => r.teamId === teamId);
    const teamObj = obj[teamId.toString()];
    if (!teamObj) continue;

    for (const shotType of shotTypes) {
      const rowOfShotType = rowsForTeam.find((r) => r.shotType === shotType);
      teamObj[shotType] = {
        made: rowOfShotType ? rowOfShotType.made : 0,
        pps: rowOfShotType ? rowOfShotType.pps : 0,
        xpps: rowOfShotType ? rowOfShotType.xpps : 0,
        proportion:
          (rowOfShotType ? rowOfShotType.total : 0) /
          (sumFromField("total", rowsForTeam) || 1),
        rankpps: 0,
        rankxpps: 0,
        rankproportion: 0,
        statType,
        teamId,
        total: rowOfShotType ? rowOfShotType.total : 0,
        type: shotType,
      };
    }

    const { post, floater, jumper2pt, earlyClockLong2pt } = teamObj;

    // Add in computation for postAndFloaters, midrange.
    if (post && floater) {
      teamObj.postAndFloaters = {
        type: "postAndFloaters",
        statType: statType,
        teamId: teamId,
        made: post.made + floater.made,
        total: post.total + floater.total,
        pps:
          (post.pps * post.proportion + floater.pps * floater.proportion) /
          (post.proportion + floater.proportion),
        xpps:
          (post.xpps * post.proportion + floater.xpps * floater.proportion) /
          (post.proportion + floater.proportion),
        proportion: post.proportion + floater.proportion,
        rankpps: 0,
        rankxpps: 0,
        rankproportion: 0,
      };
    }

    if (jumper2pt && earlyClockLong2pt) {
      teamObj.midrange = {
        type: "midrange",
        statType: statType,
        teamId: teamId,
        made: jumper2pt.made + earlyClockLong2pt.made,
        total: jumper2pt.total + earlyClockLong2pt.total,
        pps:
          (jumper2pt.pps * jumper2pt.proportion +
            earlyClockLong2pt.pps * earlyClockLong2pt.proportion) /
          (jumper2pt.proportion + earlyClockLong2pt.proportion),
        xpps:
          (jumper2pt.xpps * jumper2pt.proportion +
            earlyClockLong2pt.xpps * earlyClockLong2pt.proportion) /
          (jumper2pt.proportion + earlyClockLong2pt.proportion),
        proportion: jumper2pt.proportion + earlyClockLong2pt.proportion,
        rankpps: 0,
        rankxpps: 0,
        rankproportion: 0,
      };
    }
  }

  // After aggregating all of the rows go through and add the ranks.
  for (const teamId of teamIds) {
    for (const shotType of shotTypes) {
      const teamObj = obj[teamId];
      if (!teamObj) continue;
      const shotRow = teamObj[shotType];
      if (!shotRow) continue;

      const pps = shotRow.pps;
      const xpps = shotRow.xpps;
      const proportion = shotRow.proportion;
      const rankpps = Object.values(obj)
        .map((v) => {
          const ts = v[shotType];
          return ts ? ts.pps : 0;
        })
        .filter((p) => (statType === "OFF" ? p >= pps : p <= pps)).length;
      const rankxpps = Object.values(obj)
        .map((v) => {
          const ts = v[shotType];
          return ts ? ts.xpps : 0;
        })
        .filter((p) => (statType === "OFF" ? p >= xpps : p <= xpps)).length;
      const rankproportion = Object.values(obj)
        .map((v) => {
          const ts = v[shotType];
          return ts ? ts.proportion : 0;
        })
        .filter((p) =>
          statType === "OFF" ? p >= proportion : p <= proportion
        ).length;
      shotRow.rankpps = rankpps;
      shotRow.rankxpps = rankxpps;
      shotRow.rankproportion = rankproportion;
    }
  }

  return obj;
}
