import React from "react";
import { Button } from "react-bootstrap";

import { DynamicFilter, DynamicFilterType } from "./DynamicFilter";

export function DynamicFilterGroup(props: {
  allFilters: {
    value: string;
    label: string;
    type?: DynamicFilterType;
    group?: string;
  }[];
  filters: { key: string; value: unknown }[];
  setFilters: (filters: { key: string; value: unknown }[]) => void;
}) {
  const { allFilters, filters, setFilters } = props;

  const remainingFilters = allFilters.filter(
    (af) => !filters.some((f) => f.key === af.value)
  );

  const firstRemainingFilter = remainingFilters[0];

  return (
    <div
      style={{ display: "flex", flexWrap: "wrap", alignItems: "flex-start" }}
    >
      {filters.map((filter, i) => {
        return (
          <div
            key={i}
            style={{
              background: "#f4f4f4",
              borderRadius: 4,
              margin: 4,
              padding: 12,
            }}
          >
            <DynamicFilter
              options={allFilters}
              onChangeFilterKey={(newKey: string) => {
                // If we already have a filter for this key, remove this filter.
                if (filters.some((f) => f.key === newKey)) {
                  const copy = [...filters];
                  copy.splice(i, 1);
                  setFilters(copy);
                } else {
                  const copy = [...filters];
                  const copyi = copy[i];
                  if (copyi) {
                    copyi.key = newKey;
                    copyi.value = undefined;
                  }
                  setFilters(copy);
                }
              }}
              filterKey={filter.key}
              value={filter.value}
              onChange={(val: any) => {
                const copy = [...filters];
                const copyi = copy[i];
                if (copyi) {
                  copyi.value = val;
                }
                setFilters(copy);
              }}
              onRemove={() => {
                const copy = [...filters];
                copy.splice(i, 1);
                setFilters(copy);
              }}
              showRemove={filters.length > 1}
            />
          </div>
        );
      })}
      {firstRemainingFilter && (
        <Button
          style={{ margin: 4 }}
          onClick={() => {
            const copy = [...filters];
            copy.push({ key: firstRemainingFilter.value, value: "" });
            setFilters(copy);
          }}
        >
          AND
        </Button>
      )}
    </div>
  );
}
