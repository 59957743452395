import React, { useMemo, useState } from "react";
import { Form } from "react-bootstrap";

import { Table, SortingState, createColumnHelper } from "../core/Table";
import { Spinner } from "../core/Spinner";
import { TeamTouches } from "../../../shared/routers/TeamRouter";
import { dec100Format, decFormat, decFormat2 } from "../../util/Format";
import { Highlights } from "../../constants/AppConstants";
import { PlayerTableCell } from "../core/TableCell";

const columnHelper = createColumnHelper<TeamTouches>();

export function TeamTouchesTable(props: { touches?: TeamTouches[] }) {
  const [sorting, setSorting] = useState<SortingState>();
  const [showFullRoster, setShowFullRoster] = useState(false);

  const { touches } = props;

  const colorDomainForStat = (
    accessor: (data: TeamTouches) => number,
    data: TeamTouches[]
  ) => {
    const s = data.map((d) => accessor(d));
    return [Math.min(...s), Math.max(...s)];
  };

  const columns = useMemo(() => {
    if (!touches) return [];
    return [
      columnHelper.accessor("player", {
        header: () => "Player",
        cell: (info) => (
          <PlayerTableCell
            id={info.row.original.playerId}
            name={`${info.getValue()}${!info.row.original.onTeam ? "*" : ""}`}
          />
        ),
        meta: { group: 0 },
      }),
      columnHelper.accessor("touches", {
        header: () => "Touches",
        meta: {
          group: 1,
          highlights: Highlights.Max,
          neutralColorScheme: true,
          colorDomain: colorDomainForStat(
            (t: TeamTouches) => t.touches,
            touches
          ),
        },
      }),
      columnHelper.accessor((row) => row.touches / row.minutes, {
        id: "touchesPerMin",
        header: () => "Touches / Min",
        cell: (info) => decFormat2(info.getValue()),
        meta: {
          group: 1,
          highlights: Highlights.Max,
          neutralColorScheme: true,
          colorDomain: colorDomainForStat(
            (t: TeamTouches) => t.touches / t.minutes,
            touches
          ),
        },
      }),
      columnHelper.accessor((row) => row.shots / row.touches, {
        id: "shotPct",
        header: () => "Shot %",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: 1,
          highlights: Highlights.Max,
          neutralColorScheme: true,
          colorDomain: colorDomainForStat(
            (t: TeamTouches) => t.shots / t.touches,
            touches
          ),
        },
      }),
      columnHelper.accessor((row) => row.drives / row.touches, {
        id: "drivePct",
        header: () => "Drive %",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: 1,
          highlights: Highlights.Max,
          colorDomain: colorDomainForStat(
            (t: TeamTouches) => t.drives / t.touches,
            touches
          ),
        },
      }),
      columnHelper.accessor((row) => row.passes / row.touches, {
        id: "passPct",
        header: () => "Pass %",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: 1,
          highlights: Highlights.Max,
          neutralColorScheme: true,
          colorDomain: colorDomainForStat(
            (t: TeamTouches) => t.passes / t.touches,
            touches
          ),
        },
      }),
      columnHelper.accessor((row) => row.assists / row.touches, {
        id: "astPct",
        header: () => "Ast %",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: 1,
          highlights: Highlights.Max,
          neutralColorScheme: true,
          colorDomain: colorDomainForStat(
            (t: TeamTouches) => t.assists / t.touches,
            touches
          ),
        },
      }),
      columnHelper.accessor((row) => row.turnovers / row.touches, {
        id: "toPct",
        header: () => "TO %",
        cell: (info) => dec100Format(info.getValue()),
        meta: {
          group: 1,
          highlights: Highlights.Min,
          colorDomain: colorDomainForStat(
            (t: TeamTouches) => t.turnovers / t.touches,
            touches
          ).reverse(),
        },
      }),
      columnHelper.accessor((row) => row.dribbles / row.touches, {
        id: "dribblesPerTouch",
        header: () => "Drbs / Touch",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: 1,
          highlights: Highlights.Max,
          neutralColorScheme: true,
          colorDomain: colorDomainForStat(
            (t: TeamTouches) => t.dribbles / t.touches,
            touches
          ),
        },
      }),
      columnHelper.accessor((row) => row.dribbles / row.minutes, {
        id: "dribblesPerMin",
        header: () => "Drbs / Min",
        cell: (info) => decFormat(info.getValue()),
        meta: {
          group: 1,
          highlights: Highlights.Max,
          neutralColorScheme: true,
          colorDomain: colorDomainForStat(
            (t: TeamTouches) => t.dribbles / t.minutes,
            touches
          ),
        },
      }),
    ];
  }, [touches]);

  if (!touches) return <Spinner />;

  return (
    <div>
      <Form.Check
        label="Show Full Roster"
        checked={showFullRoster}
        onChange={() => setShowFullRoster(!showFullRoster)}
      />
      <Table
        data={touches.filter((d) => showFullRoster || d.onTeam)}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        showColorOnHover={true}
        autoWidth={true}
      />
    </div>
  );
}
