import React, { useState, ReactNode } from "react";
import cx from "classnames";
import { scaleLinear } from "d3";

import { CompareBar } from "../chart/CompareBar";
import { fitExtent as fitExtentFn } from "../../deprecated/util/Util";

interface AdditionalColData {
  format: (val: number | null) => string;
  values: Record<string, number | null>;
  lowerBetter?: boolean;
}

interface StatComparisonTableData {
  label: ReactNode;
  values: Record<string, number | null>;
  format: (val: number | null) => string;
  domain: number[];
  compare?: Record<string, number>;
  compareColorTeam?: (value: number, compare: number, key: string) => string;
  compareFormat?: Record<
    string,
    (
      compare: number,
      value: number,
      format?: (val: number | null) => string
    ) => ReactNode
  >;
  colorKey?: string;
  additionalColumns?: Record<string, AdditionalColData>;
  lowerBetter?: boolean;
  heights?: Record<string, number>;
}

export function StatComparisonTableBars(props: {
  labels: Record<string, string>;
  data: StatComparisonTableData[];
  teams: Record<string, number>; // Off and def team ids.
  groupColumnLabel: string;
  valueColumnLabel: string;
  columnWidths: number[];
  additionalColumns?: Record<string, { width: number; label: string }>;
  noBestColVal?: boolean;
  hideValuesColumn?: boolean;
  hideLabelsColumn?: boolean;
  statColumnLabel?: string;
  fitExtent?: boolean;
  hideBars?: boolean;
  barColumnLabel?: string;
}) {
  const {
    labels,
    data,
    teams,
    groupColumnLabel,
    valueColumnLabel,
    columnWidths,
    additionalColumns,
    noBestColVal,
    hideValuesColumn,
    hideLabelsColumn,
    statColumnLabel,
    fitExtent,
    hideBars,
    barColumnLabel,
  } = props;
  const [hover, setHover] = useState<null | string>(null);

  const hasAdditionalColumns =
    !!additionalColumns && Object.keys(additionalColumns).length;

  function renderLabels(
    statData: StatComparisonTableData,
    bestValueKey?: string
  ) {
    return (
      <td style={{ padding: 7, verticalAlign: "top" }}>
        {Object.keys(statData.values).map((key) => {
          return (
            <div
              key={key}
              className={cx("group-row", `${key}-row`, {
                "is-hovered": hover === key,
              })}
              style={{ fontWeight: bestValueKey === key ? "600" : undefined }}
            >
              {labels[key]}
            </div>
          );
        })}
      </td>
    );
  }

  function renderValues(
    statData: StatComparisonTableData,
    bestValueKey?: string,
    renderKey?: string
  ) {
    return (
      <td
        className="text-right"
        key={renderKey}
        style={{ padding: 7, verticalAlign: "top" }}
      >
        {Object.keys(statData.values).map((key) => {
          const format = statData.format;
          const value = statData.values[key];

          let formattedValue: string | number | null | undefined = value;
          if (format) {
            formattedValue = format(value === undefined ? null : value);
          }

          return (
            <div
              key={key}
              className={cx("group-row", `${key}-row`, {
                "is-hovered": hover === key,
              })}
              style={{ fontWeight: bestValueKey === key ? "600" : undefined }}
            >
              {formattedValue === "" || formattedValue === null ? (
                <br />
              ) : (
                formattedValue
              )}
            </div>
          );
        })}
      </td>
    );
  }

  const renderBars = (statData: StatComparisonTableData) => {
    const domain = statData.domain;
    const fitExtentArgs = [domain].concat(
      Object.values(statData.values).filter((v) => v !== null) as number[]
    );
    const scale = scaleLinear()
      .domain(fitExtent ? fitExtentFn(fitExtentArgs) : domain)
      .range([3, 100])
      .clamp(true);

    const defaultHeight = 14;
    return (
      <td style={{ padding: 7, verticalAlign: "top" }}>
        {Object.keys(statData.values).map((key) => {
          const useTeamColors = !!teams;
          const classNames = cx({
            "group-row": true,
            [`${key}-row`]: true,
            "is-hovered": hover === key,
            "compare-bar-row": true,
          });
          const value = statData.values[key];
          const compare = statData.compare && statData.compare[key];
          let compareColorTeam;
          const compareColorFunc = statData.compareColorTeam;
          const colorKey = statData.colorKey ? statData.colorKey : key;
          if (compareColorFunc && compare !== undefined) {
            compareColorTeam = compareColorFunc(value || 0, compare, colorKey);
          }
          const compareFormat =
            statData.compareFormat && statData.compareFormat[key];

          return (
            <div key={key} className={classNames}>
              {value === null || compare === undefined ? (
                <br />
              ) : (
                <CompareBar
                  format={statData.format}
                  value={value}
                  compare={compare}
                  compareTeam={compareColorTeam}
                  compareFormat={compareFormat}
                  scale={scale}
                  height={defaultHeight}
                  team={useTeamColors ? teams[key] : undefined}
                  teams={Object.values(teams)}
                  onMouseEnter={() => setHover(key)}
                  onMouseLeave={() => setHover(null)}
                />
              )}
            </div>
          );
        })}
      </td>
    );
  };

  return (
    <div
      style={{
        marginBottom: 20,
        overflowX: "auto",
        position: "relative",
      }}
    >
      <table>
        <thead>
          <tr style={{ borderBottom: "2px solid #ddd" }}>
            <th
              style={{
                width: columnWidths[0],
                padding: 7,
                verticalAlign: "top",
              }}
            >
              {statColumnLabel || "Stat"}
            </th>
            {!hideLabelsColumn && (
              <th
                style={{
                  width: columnWidths[1],
                  padding: 7,
                  verticalAlign: "top",
                }}
              >
                {groupColumnLabel || "Group"}
              </th>
            )}
            {!hideValuesColumn && (
              <th
                style={{
                  width: columnWidths[2],
                  padding: 7,
                  verticalAlign: "top",
                }}
                className="text-right"
              >
                {valueColumnLabel || "Value"}
              </th>
            )}
            {hasAdditionalColumns
              ? Object.values(additionalColumns).map((col, i) => {
                  return (
                    <th
                      key={i}
                      className="text-right"
                      style={{
                        width: col.width,
                        padding: 7,
                        verticalAlign: "top",
                      }}
                    >
                      {col.label}
                    </th>
                  );
                })
              : null}
            <th style={{ padding: 7, verticalAlign: "top" }}>
              {barColumnLabel}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((statData, i) => {
            let bestValueKey;
            if (!noBestColVal) {
              if (statData.lowerBetter) {
                bestValueKey = getMinFromRecord(statData.values);
              } else {
                bestValueKey = getMaxFromRecord(statData.values);
              }
            }
            return (
              <tr
                key={i}
                style={{
                  backgroundColor: i % 2 === 1 ? "white" : "rgba(0,0,0,.04)",
                }}
              >
                <td
                  className="label-col"
                  style={{ padding: 7, verticalAlign: "top" }}
                >
                  {statData.label}
                </td>
                {!hideLabelsColumn && renderLabels(statData, bestValueKey)}
                {!hideValuesColumn && renderValues(statData, bestValueKey)}
                {hasAdditionalColumns
                  ? Object.keys(additionalColumns).map((colKey) => {
                      const additionalColData = (
                        statData.additionalColumns as any
                      )[colKey];
                      let bestColValueKey;
                      if (!noBestColVal) {
                        if (additionalColData.lowerBetter) {
                          bestColValueKey = getMinFromRecord(
                            additionalColData.values
                          );
                        } else {
                          bestColValueKey = getMaxFromRecord(
                            additionalColData.values
                          );
                        }
                      }
                      return renderValues(
                        additionalColData,
                        bestColValueKey,
                        colKey
                      );
                    })
                  : null}
                {!hideBars && renderBars(statData)}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function getMinFromRecord(obj: Record<string, number | null>) {
  return Object.keys(obj).reduce((acc, key) => {
    const val = obj[key];
    if (val === null || val === undefined) {
      return key;
    }
    const accVal = obj[acc];
    if (accVal === null || accVal === undefined) {
      return acc;
    }
    return val < accVal ? key : acc;
  });
}

function getMaxFromRecord(obj: Record<string, number | null>) {
  return Object.keys(obj).reduce((acc, key) => {
    const val = obj[key];
    if (val === null || val === undefined) {
      return key;
    }
    const accVal = obj[acc];
    if (accVal === null || accVal === undefined) {
      return acc;
    }
    return val > accVal ? key : acc;
  });
}
