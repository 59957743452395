import React, { useState, useMemo } from "react";
import {
  FaChevronLeft,
  FaChevronRight,
  FaChartArea,
  FaInfoCircle,
} from "react-icons/fa";
import { Button } from "react-bootstrap";
import moment from "moment";

import { TooltipItem } from "../core/TooltipItem";
import { Table, SortingState, createColumnHelper } from "../core/Table";
import { trpc } from "../../util/tRPC";
import { groupBy } from "../../../shared/util/Collections";
import { sumFromField } from "../../util/Util";
import {
  decFormat,
  pctFormat,
  itemsAsList,
  TABLE_EMPTY_VALUE_STR,
} from "../../util/Format";
import { ActionLabelsMap } from "../../constants/AppConstants";
import { Spinner } from "../core/Spinner";
import { Restrict } from "../core/Restrict";

interface TableRow {
  actionRole: string;
  abilityA: number | null;
  rateA: number | null;
  impactA: number | null;
  roleRateAllocationA: number | null;
  abilityB: number | null;
  rateB: number | null;
  impactB: number | null;
  roleRateAllocationB: number | null;
  abilityDelta: number | null;
  rateDelta: number | null;
  impactDelta: number | null;
}

const columnHelper = createColumnHelper<TableRow>();

export function ImpactComparisonTable(props: {
  actionRole: string;
  setActionRole: (actionRole: string) => void;
  offDef: string;
  playerA: {
    playerId: string;
    playerName: string;
    date: string;
    role?: string;
    color: string;
  };
  playerB: {
    playerId: string;
    playerName: string;
    date: string;
    role?: string;
    color: string;
  };
}) {
  const { actionRole, setActionRole, offDef, playerA, playerB } = props;

  const [sorting, setSorting] = useState<SortingState>();

  const usePlayerData = trpc.impact.getPlayerActionModelComparison.useQuery({
    playerA: {
      date: playerA.date,
      playerId: playerA.playerId,
      role: playerA.role,
    },
    playerB: {
      date: playerB.date,
      playerId: playerB.playerId,
      role: playerB.role,
    },
  });

  const playerAAbility =
    usePlayerData.status === "success"
      ? usePlayerData.data.playerAAbility
      : undefined;

  const playerBAbility =
    usePlayerData.status === "success"
      ? usePlayerData.data.playerBAbility
      : undefined;

  const playerARates =
    usePlayerData.status === "success"
      ? usePlayerData.data.playerARates
      : undefined;

  const playerBRates =
    usePlayerData.status === "success"
      ? usePlayerData.data.playerBRates
      : undefined;

  const playerAHeader = `${playerA.playerName} (${playerA.date}) ${
    playerA.role ? `as a ${playerA.role}` : ""
  }`;

  const playerBHeader = `${playerB.playerName} (${playerB.date}) ${
    playerB.role ? `as a ${playerB.role}` : ""
  }`;

  const data = useMemo(() => {
    const roleFactorsByActionRoleA = playerAAbility
      ? groupBy(
          playerAAbility.filter((a) => offDef === "Net" || a.offDef === offDef),
          (a) => a.actionRole
        )
      : {};
    const roleFactorsByActionRoleB = playerBAbility
      ? groupBy(
          playerBAbility.filter((a) => offDef === "Net" || a.offDef === offDef),
          (b) => b.actionRole
        )
      : {};

    return usePlayerData.data &&
      Object.keys(roleFactorsByActionRoleA).length &&
      Object.keys(roleFactorsByActionRoleB).length
      ? Object.keys(roleFactorsByActionRoleA).map((actionRole) => {
          if (
            actionRole === "APM Adjustment (Offense)" ||
            actionRole === "APM Adjustment (Defense)"
          ) {
            return {
              actionRole,
              abilityA: null,
              rateA: null,
              impactA:
                100 *
                (sumFromField(
                  "value",
                  roleFactorsByActionRoleA[actionRole] || []
                ) || 0),
              roleRateAllocationA: null,
              abilityB: null,
              rateB: null,
              impactB:
                100 *
                (sumFromField(
                  "value",
                  roleFactorsByActionRoleB[actionRole] || []
                ) || 0),
              roleRateAllocationB: null,
              impactDelta: Math.abs(
                100 *
                  (sumFromField(
                    "value",
                    roleFactorsByActionRoleA[actionRole] || []
                  ) || 0) -
                  100 *
                    (sumFromField(
                      "value",
                      roleFactorsByActionRoleB[actionRole] || []
                    ) || 0)
              ),
              abilityDelta: null,
              rateDelta: null,
            };
          }
          const roleFactorsNoRoleRateAllocationA = (
            roleFactorsByActionRoleA[actionRole] || []
          ).filter((r) => r.roleFactor !== "role_allocation");
          const roleFactorsNoRoleRateAllocationB = (
            roleFactorsByActionRoleB[actionRole] || []
          ).filter((r) => r.roleFactor !== "role_allocation");
          const roleAllocationA = (
            roleFactorsByActionRoleA[actionRole] || []
          ).find((r) => r.roleFactor === "role_allocation");
          const roleAllocationB = (
            roleFactorsByActionRoleB[actionRole] || []
          ).find((r) => r.roleFactor === "role_allocation");
          const abilitySumA =
            sumFromField("value", roleFactorsNoRoleRateAllocationA) || 0;
          const abilitySumB =
            sumFromField("value", roleFactorsNoRoleRateAllocationB) || 0;
          const rateA = (playerARates && playerARates[actionRole]) || 0;
          const rateB = (playerBRates && playerBRates[actionRole]) || 0;
          const roleRateAllocationA = roleAllocationA
            ? roleAllocationA.value * 100
            : 0;
          const roleRateAllocationB = roleAllocationB
            ? roleAllocationB.value * 100
            : 0;
          const abilityA = 100 * abilitySumA;
          const abilityB = 100 * abilitySumB;
          const impactA = 100 * abilitySumA * rateA + roleRateAllocationA;
          const impactB = 100 * abilitySumB * rateB + roleRateAllocationB;

          const subRows: TableRow[] = (
            roleFactorsByActionRoleA[actionRole] || []
          )
            .map((rf) => {
              const playerBEntry = (
                roleFactorsByActionRoleB[actionRole] || []
              ).find((r) => r.roleFactor === rf.roleFactor);
              const abilityB = playerBEntry ? playerBEntry.value * 100 : 0;
              const isRoleAllocation = rf.roleFactor === "role_allocation";
              return {
                actionRole: rf.roleFactor,
                abilityA: isRoleAllocation ? null : 100 * rf.value,
                rateA: null,
                impactA: isRoleAllocation ? 100 * rf.value : null,
                roleRateAllocationA: null,
                abilityB: isRoleAllocation ? null : abilityB,
                rateB: null,
                impactB: isRoleAllocation ? abilityB : null,
                roleRateAllocationB: null,
                abilityDelta: isRoleAllocation
                  ? null
                  : Math.abs(100 * rf.value - abilityB),
                rateDelta: null,
                impactDelta: isRoleAllocation
                  ? Math.abs(100 * rf.value - abilityB)
                  : null,
              };
            })
            .sort((a, b) => {
              if (a.actionRole === "role_allocation") {
                return -1;
              } else if (b.actionRole === "role_allocation") {
                return 1;
              }
              return (b.abilityDelta || 0) - (a.abilityDelta || 0);
            });
          return {
            actionRole,
            abilityA,
            rateA,
            impactA,
            roleRateAllocationA,
            abilityB,
            rateB,
            impactB,
            roleRateAllocationB,
            abilityDelta: Math.abs(abilityA - abilityB),
            rateDelta: Math.abs(rateA - rateB),
            impactDelta: Math.abs(impactA - impactB),
            subRows,
          };
        })
      : [];
  }, [
    offDef,
    playerAAbility,
    playerARates,
    playerBAbility,
    playerBRates,
    usePlayerData.data,
  ]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("actionRole", {
        header: "Action Role",
        cell: (info) => {
          const alm = ActionLabelsMap[info.getValue()];
          const label = alm ? alm.label : info.getValue();
          if (info.row.depth === 0) {
            return (
              <span>
                <Button
                  onClick={() => setActionRole(label)}
                  style={{
                    padding: 3,
                    fontSize: ".7em",
                    marginRight: 4,
                    lineHeight: 0,
                  }}
                >
                  <FaChartArea />
                </Button>
                <b>{label}</b>
              </span>
            );
          }
          const tooltip = alm && alm.desc;
          return tooltip ? (
            <TooltipItem arrow={false} tooltip={tooltip} noListItem={true}>
              {label} <FaInfoCircle />
            </TooltipItem>
          ) : (
            <span>{label}</span>
          );
        },
        meta: { group: 0, textAlign: "left" },
      }),
      columnHelper.group({
        id: "playerA",
        header: playerAHeader,
        meta: { group: 1 },
        columns: [
          columnHelper.accessor("impactA", {
            header: "Impact",
            cell: (info) => decFormat(info.getValue()),
            footer: () => (
              <div style={{ textAlign: "center" }}>
                <b>{decFormat(sumFromField("impactA", data))}</b>
              </div>
            ),
            meta: { group: 1 },
          }),
          columnHelper.accessor("abilityA", {
            header: "Ability",
            cell: (info) => decFormat(info.getValue()),
            meta: { group: 1 },
          }),
          columnHelper.accessor("rateA", {
            header: "Rate",
            cell: (info) => pctFormat(info.getValue()),
            meta: { group: 1 },
          }),
        ],
      }),
      columnHelper.group({
        id: "playerDelta",
        header: "Delta",
        meta: { group: 2 },
        columns: [
          columnHelper.accessor("impactDelta", {
            header: "Impact",
            cell: (info) => {
              const val = decFormat(info.getValue());
              const diff =
                (info.row.original.impactA || 0) -
                (info.row.original.impactB || 0);
              let showLeft = false;
              let showRight = false;
              if (Math.abs(diff) > 0.1) {
                showLeft = diff > 0;
                showRight = diff < 0;
              }
              return (
                <div>
                  <FaChevronLeft
                    style={{ color: showLeft ? playerA.color : "transparent" }}
                  />
                  {info.row.depth === 0 ? <b>{val}</b> : <span>{val}</span>}
                  <FaChevronRight
                    style={{ color: showRight ? playerB.color : "transparent" }}
                  />
                </div>
              );
            },
            meta: { group: 2 },
          }),
          columnHelper.accessor("abilityDelta", {
            header: "Ability",
            cell: (info) => {
              const label = info.row.original.actionRole;
              if (
                label === "APM  Adjustment (Offense)" ||
                label === "APM  Adjustment (Defense)"
              )
                return TABLE_EMPTY_VALUE_STR;
              const val = decFormat(info.getValue());
              const diff =
                (info.row.original.abilityA || 0) -
                (info.row.original.abilityB || 0);

              let showLeft = false;
              let showRight = false;
              if (Math.abs(diff) > 0.5) {
                showLeft = diff > 0;
                showRight = diff < 0;
              }
              return (
                <div>
                  <FaChevronLeft
                    style={{ color: showLeft ? playerA.color : "transparent" }}
                  />
                  {val}
                  <FaChevronRight
                    style={{ color: showRight ? playerB.color : "transparent" }}
                  />
                </div>
              );
            },
            meta: { group: 2 },
          }),
          columnHelper.accessor("rateDelta", {
            header: "Rate",
            cell: (info) => {
              const label = info.row.original.actionRole;
              if (
                label === "APM  Adjustment (Offense)" ||
                label === "APM  Adjustment (Defense)"
              )
                return TABLE_EMPTY_VALUE_STR;
              const val = pctFormat(info.getValue());
              const diff =
                (info.row.original.rateA || 0) - (info.row.original.rateB || 0);
              let showLeft = false;
              let showRight = false;
              if (Math.abs(diff) > 0.05) {
                showLeft = diff > 0;
                showRight = diff < 0;
              }
              return (
                <div>
                  <FaChevronLeft
                    style={{ color: showLeft ? playerA.color : "transparent" }}
                  />
                  {val}
                  <FaChevronRight
                    style={{ color: showRight ? playerB.color : "transparent" }}
                  />
                </div>
              );
            },
            meta: { group: 2 },
          }),
        ],
      }),
      columnHelper.group({
        id: "playerB",
        header: playerBHeader,
        meta: { group: 3 },
        columns: [
          columnHelper.accessor("impactB", {
            header: "Impact",
            cell: (info) => decFormat(info.getValue()),
            footer: () => (
              <div style={{ textAlign: "center" }}>
                <b>{decFormat(sumFromField("impactB", data))}</b>
              </div>
            ),
            meta: { group: 3 },
          }),
          columnHelper.accessor("abilityB", {
            header: "Ability",
            cell: (info) => decFormat(info.getValue()),
            meta: { group: 3 },
          }),
          columnHelper.accessor("rateB", {
            header: "Rate",
            cell: (info) => pctFormat(info.getValue()),
            meta: { group: 3 },
          }),
        ],
      }),
      columnHelper.accessor("actionRole", {
        id: "actionRoleRepeat",
        header: "Action Role",
        cell: (info) => {
          const alm = ActionLabelsMap[info.getValue()];
          if (alm === undefined) return info.getValue();
          const label = alm.label;
          if (info.row.depth === 0) {
            return <b>{label}</b>;
          }
          return label;
        },
        meta: { group: 4, textAlign: "left" },
      }),
    ],
    [
      data,
      playerA.color,
      playerAHeader,
      playerB.color,
      playerBHeader,
      setActionRole,
    ]
  );

  if (usePlayerData.status === "loading") return <Spinner />;
  else if (usePlayerData.status === "error") return null;

  data.sort((a, b) => b.impactDelta - a.impactDelta);

  const highlightIdx = data.findIndex((d) => {
    const alm = ActionLabelsMap[d.actionRole];
    const label = alm && alm.label;
    return label === actionRole;
  });

  if (
    (playerAAbility && playerAAbility.length === 0) ||
    (playerARates && Object.keys(playerARates).length === 0)
  ) {
    return <div>No data for {playerAHeader} </div>;
  }

  if (
    (playerBAbility && playerBAbility.length === 0) ||
    (playerBRates && Object.keys(playerBRates).length === 0)
  ) {
    return <div>No data for {playerBHeader} </div>;
  }

  return (
    <div>
      <Restrict roles={["bia"]}>
        <b>Impact Diff Summary</b>
        <p>
          {impactDiffToText(
            offDef,
            data,
            playerA.playerId === playerB.playerId
              ? `${playerA.playerName} (${moment(playerA.date).format(
                  "MM/DD/YY"
                )})`
              : playerA.playerName,
            playerA.playerId === playerB.playerId
              ? `${playerB.playerName} (${moment(playerB.date).format(
                  "MM/DD/YY"
                )})`
              : playerB.playerName
          )}
        </p>
      </Restrict>
      <Table
        autoWidth={true}
        data={data}
        columns={columns}
        rowColorMap={{ [highlightIdx]: { backgroundColor: "#ffffe0" } }}
        expandColumnId={"actionRole"}
        sorting={sorting}
        setSorting={setSorting}
        showRowIndex={false}
        disableStickyColumn={true}
      />
    </div>
  );
}

type ImpactComparisonRow = TableRow & { subRows?: TableRow[] };
function impactDiffToText(
  offDef: string,
  data: ImpactComparisonRow[],
  playerA: string,
  playerB: string
) {
  const playerAImpactDiff =
    (sumFromField("impactA", data) || 0) - (sumFromField("impactB", data) || 0);

  const betterPlayerImpactAccessor = (row: ImpactComparisonRow) =>
    (playerAImpactDiff > 0 ? row.impactA : row.impactB) || 0;
  const worsePlayerImpactAccessor = (row: ImpactComparisonRow) =>
    (playerAImpactDiff < 0 ? row.impactA : row.impactB) || 0;

  const players =
    playerAImpactDiff > 0 ? [playerA, playerB] : [playerB, playerA];
  const modifier = modifierFromDiff(Math.abs(playerAImpactDiff));

  const biggestImpactDiffs = data
    .filter((d) => d.impactDelta !== null && d.impactDelta > 0.1)
    .sort((a, b) => Math.abs((a.impactDelta || 0) - (b.impactDelta || 0)));

  const biggestImpactDiffsForBetterPlayer = biggestImpactDiffs
    .filter((d) => betterPlayerImpactAccessor(d) > worsePlayerImpactAccessor(d))
    .slice(0, 3);

  const sentances = [
    `${players[0]} has ${modifier} ${impactStr(offDef)} than ${players[1]}.`,
  ];

  const singleVsMulti =
    biggestImpactDiffsForBetterPlayer.length === 1
      ? "The main reason for this is"
      : "The biggest reasons for this are";

  sentances.push(
    `${singleVsMulti} that ${players[0]} ${itemsAsList(
      biggestImpactDiffsForBetterPlayer.map(
        (d) => strForActionRole(d.actionRole) + impactDiffExplainer(d)
      )
    )}.`
  );

  return sentances.join(" ");
}

function impactDiffExplainer(diff: ImpactComparisonRow) {
  if (diff.actionRole.includes("APM")) return "";
  const reasons = [];

  const playerABetter = (diff.impactA || 0) > (diff.impactB || 0);
  const playerARateHigher = (diff.rateA || 0) > (diff.rateB || 0);
  const playerAAbilityHigher = (diff.abilityA || 0) > (diff.abilityB || 0);

  if (
    (playerABetter && playerARateHigher) ||
    (!playerABetter && !playerARateHigher)
  ) {
    // Lets say 10% diff is a lot, <10% is a little.
    reasons.push(
      `spends ${
        (diff.rateDelta || 0) > 0.1 ? "a lot" : "a little"
      } more time in this role`
    );
  }
  if (
    (playerABetter && playerAAbilityHigher) ||
    (!playerABetter && !playerAAbilityHigher)
  ) {
    const abilities = (diff.subRows || [])
      .filter(
        (d) =>
          (playerABetter && (d.abilityA || 0) > (d.abilityB || 0)) ||
          (!playerABetter && (d.abilityA || 0) < (d.abilityB || 0))
      )
      .sort((a, b) => (b.abilityDelta || 0) - (a.abilityDelta || 0));

    // If there are diffs  .5, use the biggest 3 of those. Else just mention the
    // single thing with biggest diff.
    const diffsWorthMentioning = abilities.some(
      (a) => (a.abilityDelta || 0) > 0.5
    )
      ? abilities.filter((a) => (a.abilityDelta || 0) > 0.5).slice(0, 3)
      : abilities.slice(0, 1);

    if (diffsWorthMentioning.length) {
      reasons.push(
        `has better ${itemsAsList(
          diffsWorthMentioning.map((a) => {
            const alm = ActionLabelsMap[a.actionRole];
            return alm ? alm.label : a.actionRole;
          })
        )}`
      );
    }
  }

  // If reasons is empty it means that the player has more impact despite
  // having MORE ability and MORE rate which means both players must be
  // negative and the player doing it more is hurting themselves more.
  if (reasons.length === 0) {
    return ` (both players have negative impact in this role but he spends less time doing it)`;
  }

  return ` (because he ${reasons.join(" and ")})`;
}

function strForActionRole(actionRole: string) {
  if (actionRole === "APM Adjustment (Defense)") {
    return `has a larger defensive APM adjustment`;
  } else if (actionRole === "APM Adjustment (Offense)") {
    return `has a larger offensive APM adjustment`;
  }
  const alm = ActionLabelsMap[actionRole];
  return `is a more impactful ${actionLabelPrettify(
    alm ? alm.label : "Unknown"
  )}`;
}

function modifierFromDiff(diff: number) {
  if (diff <= 0.5) {
    return "slightly more";
  } else if (diff <= 1) {
    return "more ";
  } else {
    return "substantially more";
  }
}

function impactStr(offDef: string) {
  if (offDef === "Defense") {
    return "defensive impact";
  } else if (offDef === "Offense") {
    return "offensive impact";
  }
  return "impact";
}

function actionLabelPrettify(label: string) {
  return actionLabelFixes[label] || label.toLocaleLowerCase();
}

const actionLabelFixes: Record<string, string> = {
  "Iso Rim Spacer Defender": "isolation rim spacer defender",
  "Transition Defense (Behind Play)": "behind the play transition defender",
  "Transition Defense (In Play)": "transition defender",
  "PNR Ballhandler Defender": "PNR ballhandler defender",
  "PNR Perimeter Spacer Defender": "PNR perimeter spacer defender",
  "PNR Rim Spacer Defender": "PNR rim spacer defender",
  "PNR Ballhandler": "PNR ballhandler",
  "PNR Perimeter Spacer": "PNR perimeter spacer",
  "PNR Popper": "PNR popper",
  "PNR Rim Spacer": "PNR rim spacer",
  "PNR Roller": "PNR roller",
  "Transition (Behind Play)": "player in transition behind the play",
  "Transition (In Play)": "player in transition",
  "PNR Screener Defender Traditional":
    "PNR screener defender in traditional coverage",
  "PNR Screener Defender High": "PNR screener defender in high coverage",
  "PNR Screener Defender Switch": "PNR screener defender in switch coverage",
};
