import React from "react";
import cx from "classnames";
import { createStyles, makeStyles } from "@material-ui/styles";

import { green, purple } from "../../../constants/ColorConstants";

const useStyles = makeStyles(() =>
  createStyles({
    labelBarLabel: {
      whiteSpace: "nowrap",
    },
    labelBar: {
      height: 2,
      marginBottom: 1,
      backgroundColor: "#ccc",
      transition: "width .2s",
      ":last-child": {
        marginBottom: 0,
      },
      ["@media print"]: {
        display: "none",
      },
    },
    labelBar0: {
      backgroundColor: green,
    },
    labelBar1: {
      backgroundColor: purple,
    },
  })
);

/** Render to deal with cells that render bar graphs in a cell. */
export function LabelBarRenderer(props: { data: any; config: { bars: any } }) {
  const classes = useStyles();
  const { bars } = props.config;
  return (
    <div>
      <span className={classes.labelBarLabel}>{props.data.label}</span>
      {bars.map((bar: any, i: number) => (
        <div
          key={i}
          className={cx(classes.labelBar, (classes as any)["labelBar" + i])}
          style={{ width: bar(props.data) }}
        />
      ))}
    </div>
  );
}
