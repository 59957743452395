import React, { useState } from "react";
import moment from "moment";

import { Modal } from "../core/Modal";
import { trpc } from "../../util/tRPC";
import { Spinner } from "../core/Spinner";

export function TeamScoutingReports(props: {
  teamId: number;
  teamName: string;
}) {
  const { teamId, teamName } = props;

  const [show, setShow] = useState(false);
  const { data: scoutingReports } = trpc.misc.getTeamScoutingReport.useQuery({
    teamId,
  });

  const content = (
    <div>
      {scoutingReports === undefined ? (
        <Spinner />
      ) : scoutingReports.urls.length === 0 ? (
        <div>No scouting reports found.</div>
      ) : (
        scoutingReports.urls.map((sr, i) => {
          return (
            <div key={i}>
              <a
                href="#"
                onClick={() => {
                  const originalUrl = sr.url;
                  if (!originalUrl) return;
                  const newFileName = `${teamName}_${
                    sr.label
                  }_${moment().format("MM-DD-YYYY")}.pdf`;
                  fetch(originalUrl)
                    .then((response) => response.blob())
                    .then((blob) => {
                      // Create a Blob URL
                      const blobUrl = URL.createObjectURL(blob);
                      // Create a link element
                      const link = document.createElement("a");
                      link.href = blobUrl;
                      link.download = newFileName;
                      // Simulate a click to trigger the download.
                      link.click();
                      // Clean up: Revoke the Blob URL
                      URL.revokeObjectURL(blobUrl);
                    })
                    .catch((error) => {
                      console.log(
                        `Unable to download scouting report: ${error}`
                      );
                    });
                }}
              >
                <b>Download {sr.label}</b>
              </a>
            </div>
          );
        })
      )}
    </div>
  );

  return (
    <>
      <a
        href="#"
        onClick={() => {
          setShow(true);
        }}
      >
        Scouting Report
      </a>
      <Modal
        title={`${teamName} Scouting Reports`}
        content={content}
        show={show}
        handleClose={() => setShow(false)}
      />
    </>
  );
}
