import React, { useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import moment from "moment";

import { TeamMultiLeagueSchedule } from "../components/team/TeamMultiLeagueSchedule";
import { Page } from "../components/core/Page";
import { Panel } from "../components/core/Panel";
import { VideoPlayer } from "../components/video/VideoPlayer";
import { Spinner } from "../components/core/Spinner";
import { GameMultiLeagueBoxscoreTeam } from "../../shared/routers/GameRouter";
import { Game } from "../../shared/routers/GameRouter";
import { LeagueSeasonTeamSchedule } from "../../shared/routers/LeagueRouter";
import { dateFormatLong } from "../util/Format";
import { Table, createColumnHelper } from "../components/core/Table";

import { getHoop1Urls } from "../util/VideoUtil";
import { gameClipToSynergyEditorClip } from "../components/video/utilities";
import { trpc } from "../util/tRPC";
import { TeamTableCell } from "../components/core/TableCell";
import { GameMultiLeagueBoxScoresTable } from "../components/games/GameMultiLeagueBoxScoresTable";
import { GameMultiLeaguePageHeader } from "../components/games/GameMultiLeaguePageHeader";

export function GameMultiLeaguePage() {
  const { league, id } = useParams();

  const [hoop1Urls, setHoop1Urls] =
    useState<{ label: string; url: string }[]>();
  const [source, setSource] = useState("synergy");

  const { data: playerBoxes } =
    trpc.game.getGameMultiLeagueBoxscorePlayer.useQuery({
      gameId: id ? parseInt(id) : undefined,
      league,
    });

  const { data: synergyPos } = trpc.synergy.getSynergyGameClips.useQuery({
    gameIds: id ? [parseInt(id)] : [],
  });

  const { data: synergySegments } =
    trpc.synergy.getSynergyGameSegments.useQuery({
      gameIds: id ? [parseInt(id)] : [],
    });

  const { data: detailsRows } = trpc.game.getGameDetails.useQuery(
    { gameIds: id ? [parseInt(id)] : [] },
    {
      onSuccess: (data) => {
        const game = data[0];
        if (game) {
          setHoop1Urls(
            getHoop1Urls(game).urls.map((u, i) => {
              return { url: u, label: `Period ${i + 1}` };
            })
          );
        } else {
          setHoop1Urls(undefined);
        }
      },
    }
  );

  const details = detailsRows && detailsRows[0];

  const { data: teamBoxes } = trpc.game.getGameMultiLeagueBoxScoreTeam.useQuery(
    { gameId: id, league }
  );

  const { data: homeSchedule } =
    trpc.league.getLeagueSeasonTeamSchedule.useQuery({
      leagueKey: league,
      season: details ? details.season.toString() : undefined,
      teamId:
        details && details.homeTeamID
          ? details.homeTeamID.toString()
          : undefined,
    });

  const { data: awaySchedule } =
    trpc.league.getLeagueSeasonTeamSchedule.useQuery({
      leagueKey: league,
      season: details ? details.season.toString() : undefined,
      teamId:
        details && details.awayTeamID
          ? details.awayTeamID.toString()
          : undefined,
    });

  const clipTitle = details
    ? `${details.awayTeam} @ ${details.homeTeam}`
    : "Unknown";

  const isLoading = !synergyPos || !synergySegments || !hoop1Urls;

  const clips = useMemo(() => {
    if (!synergyPos || !synergySegments || !hoop1Urls) return [];
    if (source === "synergy") {
      return synergySegments.map((url) => {
        return { label: clipTitle, url: url.videoUrl };
      });
    } else if (source === "synergyByPoss") {
      return synergyPos.map(gameClipToSynergyEditorClip);
    } else {
      return hoop1Urls;
    }
  }, [synergyPos, synergySegments, hoop1Urls, source, clipTitle]);

  const sources = [
    { value: "synergy", label: "Synergy" },
    { value: "synergyByPoss", label: "Synergy (by possession)" },
    { value: "hoop1", label: "Hoop 1" },
  ];

  if (
    !id ||
    !league ||
    !details ||
    !teamBoxes ||
    !playerBoxes ||
    !homeSchedule ||
    !awaySchedule
  )
    return null;

  const schedules: Record<string, LeagueSeasonTeamSchedule[]> = {
    home: homeSchedule,
    away: awaySchedule,
  };

  const homeBox = teamBoxes.find((d) => d.homeAway === "home");
  const awayBox = teamBoxes.find((d) => d.homeAway === "away");

  if (!homeBox || !awayBox) return null;

  return (
    <Page
      header={{
        component: (
          <GameMultiLeaguePageHeader
            homeTeamName={homeBox.TEAMLONG}
            awayTeamName={awayBox.TEAMLONG}
            homeTeamId={homeBox.teamId}
            awayTeamId={awayBox.teamId}
            homePts={homeBox.pts}
            awayPts={awayBox.pts}
            homeTeamIdsId={homeBox.teamIDSId}
            awayTeamIdsId={awayBox.teamIDSId}
            homeTeamEspnId={details.homeESPN || undefined}
            awayTeamEspnId={details.awayESPN || undefined}
            gameDateTimeStr={details.gameDate}
            league={league}
            season={details.season}
          />
        ),
      }}
      title="Game Multi League Page"
    >
      <div>
        <Row>
          <Col>
            <Panel header="Video">
              {isLoading ? (
                <Spinner />
              ) : (
                <div
                  style={{
                    marginBottom: 8,
                  }}
                >
                  <div style={{ marginBottom: 8 }}>
                    <Form.Select
                      style={{ width: "auto", display: "inline" }}
                      onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                        setSource(evt.target.value);
                      }}
                    >
                      {sources.map((s, i) => (
                        <option key={i} value={s.value}>
                          {s.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>

                  <VideoPlayer
                    title={`${details.awayTeam} @ ${details.homeTeam}`}
                    prefetchLength={source === "synergyByPoss" ? 5 : 1}
                    clips={clips}
                    upDownClipSkip={source === "synergyByPoss"}
                    showSynergyEditor={source === "synergyByPoss"}
                  />
                </div>
              )}
            </Panel>
          </Col>
        </Row>
        <Row>
          {["away", "home"].map((homeAway) => {
            const teamBox = teamBoxes.find(
              (d) => d.homeAway === homeAway
            ) as GameMultiLeagueBoxscoreTeam;

            if (!teamBox) return null;

            const team = teamBox.TEAMLONG;
            return (
              <Col key={homeAway}>
                <Panel header={`${team} Box Scores`}>
                  <GameMultiLeagueBoxScoresTable
                    data={playerBoxes.filter((p) => p.homeAway === homeAway)}
                  />
                </Panel>
              </Col>
            );
          })}
        </Row>
        <Row>
          {["away", "home"].map((homeAway) => {
            const teamBox = teamBoxes.find(
              (d) => d.homeAway === homeAway
            ) as GameMultiLeagueBoxscoreTeam;

            if (!teamBox) return null;
            const team = teamBox.TEAMLONG;

            return (
              <Col key={homeAway}>
                <Panel header={team}>
                  <TeamMultiLeagueSchedule
                    gameId={id}
                    data={schedules[homeAway] || []}
                  />
                </Panel>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col>
            <Panel
              header={`Other Games on ${dateFormatLong(
                new Date(details.gameDate)
              )}`}
            >
              <GamesOnDate
                league={league}
                gameDate={details.gameDate}
                gameId={id}
              />
            </Panel>
          </Col>
        </Row>
      </div>
    </Page>
  );
}

const gamesOnDateColumnHelper = createColumnHelper<Game>();

function GamesOnDate(props: {
  league: string;
  gameDate: string;
  gameId: string;
}) {
  const { league, gameDate, gameId } = props;

  const { data: games } = trpc.game.getGamesOnDate.useQuery({
    league,
    date: moment(gameDate).format("YYYY-MM-DD"),
  });

  const columns = useMemo(
    () => [
      gamesOnDateColumnHelper.accessor("leagueName", {
        header: () => "League",
        cell: (info) => info.getValue(),
        meta: { textAlign: "left" },
      }),
      gamesOnDateColumnHelper.accessor("gameDate", {
        header: () => "Date",
        cell: (info) => moment(info.getValue()).format("MM/DD/YYYY"),
      }),
      gamesOnDateColumnHelper.accessor("awayTeam", {
        header: () => "Away",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue()}
            ids={info.row.original.awayIDS || undefined}
            id={info.row.original.awayTeamID}
            season={info.row.original.season.toString()}
            league={info.row.original.leagueKey || undefined}
          />
        ),
      }),
      gamesOnDateColumnHelper.display({
        id: "result",
        header: () => "Result",
        cell: (info) => {
          const d = info.row.original;
          const away = d.awayPts > d.homePts ? <b>{d.awayPts}</b> : d.awayPts;
          const home = d.homePts > d.awayPts ? <b>{d.homePts}</b> : d.homePts;
          return (
            <Link
              title={"Go to game page"}
              to={`/game/ml/${d.leagueKey}/${d.alag}`}
            >
              {away} - {home}
            </Link>
          );
        },
      }),
      gamesOnDateColumnHelper.accessor("homeTeam", {
        header: () => "Home",
        cell: (info) => (
          <TeamTableCell
            name={info.getValue()}
            ids={info.row.original.homeIDS || undefined}
            id={info.row.original.homeTeamID}
            season={info.row.original.season.toString()}
            league={info.row.original.leagueKey || undefined}
          />
        ),
      }),
    ],
    []
  );

  if (!games) return null;

  const highlightIdx = games.findIndex((g) => g.alag.toString() === gameId);

  return (
    <Table
      data={games}
      columns={columns}
      autoWidth={true}
      showColorOnHover={true}
      rowColorMap={{ [highlightIdx]: { backgroundColor: "#ffffe0" } }}
      disableStickyColumn={true}
    />
  );
}
