import React, { Component, ReactNode } from "react";
import { Navigate } from "react-router-dom";

// This is largely borrowed from the following cheat sheet:
// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/error_boundaries/

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch() {
    // If we want to do something fancy with the error and error info we can do
    // it in this function.
  }

  public render() {
    if (this.state.hasError) {
      this.setState({ hasError: false });
      return <Navigate to={`/error`} replace={false} />;
    }

    return this.props.children;
  }
}
