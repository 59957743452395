import React from "react";

import { pctFormat } from "../../util/Format";
import { getContrastingTeamColor } from "../../util/Colors";

export function GameWinProbBar(props: {
  xWinPctHome: number;
  xWinPctAway: number;
  homeTeamId: number;
  awayTeamId: number;
}) {
  const { xWinPctHome, xWinPctAway, homeTeamId, awayTeamId } = props;
  const homeColor = getContrastingTeamColor(homeTeamId, awayTeamId);
  const awayColor = getContrastingTeamColor(awayTeamId, homeTeamId);

  const chartWidth = 240;
  const chartXPadding = 10;
  const startX = chartXPadding;
  const midX = startX + xWinPctAway * (chartWidth - chartXPadding * 2);
  const endX = chartWidth - chartXPadding;

  const midY = 8;

  const strokeWidth = 12;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: chartWidth, height: 16 }}
    >
      <line
        x1={startX}
        y1={midY}
        x2={endX}
        y2={midY}
        strokeWidth={strokeWidth + 4}
        stroke={"white"}
        strokeLinecap="round"
      />
      <circle
        cx={startX}
        cy={midY}
        r={strokeWidth / 2}
        fill={awayColor.primary}
        strokeWidth={1}
      />
      <line
        x1={startX}
        y1={midY}
        x2={midX}
        y2={midY}
        strokeWidth={strokeWidth}
        stroke={awayColor.primary}
      />
      {xWinPctAway > 0.5 && (
        <text
          x={(startX + midX) / 2}
          y={midY}
          dy="0.4em"
          textAnchor="middle"
          fill={contrastingTextColor(awayColor.primary)}
          fontSize="12"
          fontWeight={600}
        >
          xWin: {pctFormat(xWinPctAway, 0)}
        </text>
      )}
      <line
        x1={endX}
        y1={midY}
        x2={midX}
        y2={midY}
        strokeWidth={strokeWidth}
        stroke={homeColor.primary}
      />
      <circle
        cx={endX}
        cy={midY}
        r={strokeWidth / 2}
        fill={homeColor.primary}
        strokeWidth={1}
      />
      {xWinPctHome > 0.5 && (
        <text
          x={(endX + midX) / 2}
          y={midY}
          dy="0.36em"
          textAnchor="middle"
          fill={contrastingTextColor(homeColor.primary)}
          fontSize="12"
          fontWeight={600}
        >
          xWin: {pctFormat(xWinPctHome, 0)}
        </text>
      )}
      <line
        x1={midX - 1}
        y1={midY}
        x2={midX + 1}
        y2={midY}
        strokeWidth={strokeWidth}
        stroke={"white"}
      />
    </svg>
  );
}

function contrastingTextColor(colorStr: string): string {
  // Remove the hash symbol.
  const hexColor = colorStr.replace("#", "");

  // Convert hex to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return black or white based on luminance
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
}
